import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useVisitsDivergencesAssessments } from "../../../../providers/Visits/VisitsDivergencesAssessments/VisitsDivergencesAssessmentsProvider";
import { Col, FormGroup, Row } from "react-bootstrap";
import moment from "moment";
import SidebarFilter from "../../../SidebarFilter/SidebarFilter";
import "./SidebarFilterDivergenceDetail.scss";
import InputDateRange from "../../../InputDateRange/InputDateRange";
import ReactSelect from "react-select";
import { customStyles } from "../../../../models/SelectCustomStyles";
import { useCommercial } from "../../../../providers/Registrations/User/UserCommercialProvider";
import { status } from "../../../../enums/Visits/VisitsDivergencesAssessments/VisitsDivergencesAssessmentsEnum";
import { review } from "../../../../enums/Visits/VisitsDivergencesAssessments/VisitsDivergencesAssessmentsReview";
import { useVisitsTypesContacts } from "../../../../providers/Visits/VisitsTypesContacts/VisitsTypesContactsProvider";

interface SidebarFilterDivergenceDetailProps { }

export const defaultValues = {
    startDate: '',
    endDate: '',
    wasFinished: '',
    visitTypeContact: '',
    commercialId: ''
} as any;

const SidebarFilterDivergenceDetail: FC<SidebarFilterDivergenceDetailProps> = () => {

    const { handleSubmit, setValue, control } = useForm<any>({ defaultValues });
    const { params, setParams } = useVisitsDivergencesAssessments();
    const { commercialsOptions, handleList } = useCommercial();
    const { typesContactsOptions } = useVisitsTypesContacts();

    const [selectedCommercial, setSelectedCommercial] = useState<any>();
    const [selectedVisitTypeContact, setSelectedVisitTypeContact] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<any>(moment(new Date()).format('YYYY-MM-01 HH:mm:ss'));
    const [endDate, setEndDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));

    const onSubmit = async (data: any) => {

        data = {
            startDate: data?.startDate ? moment(data?.startDate).format('YYYY-MM-DD HH:mm:ss') : null,
            endDate: data?.endDate ? moment(data?.endDate).format('YYYY-MM-DD HH:mm:ss') : null,
            commercialId: data?.commercialId?.value || null,
            visitTypeContact: data?.visitTypeContact ? data.visitTypeContact.map((x: any) => x.value) : null,
            wasFinished: data?.wasFinished?.value || null,
            wasReview: typeof data?.wasReview?.value != 'undefined' ? data?.wasReview?.value : null
        }

        setParams({ ...params, ...data, ...{ page: 1 } });
    }

    useEffect(() => {
        handleList();
    }, [])

    useEffect(() => {
        setValue('visitTypeContact', selectedVisitTypeContact ?? null);
    }, [selectedVisitTypeContact]);

    return (
        <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
            <div className="SidebarFilterDivergenceDetail" data-testid="SidebarFilterDivergenceDetail">
                <Row>
                    <Col md={12} xs={12}>
                        <FormGroup className="form-group mb-0">
                            <InputDateRange
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                                setValue={setValue}
                                startLabelText={'DATA INICIAL'}
                                endLabelText={'DATA FINAL'}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <FormGroup className="form-group">
                            <label htmlFor="commercialId">COMERCIAL</label>
                            <Controller
                                name="commercialId"
                                control={control}
                                render={({ field: { onChange, name, ref } }) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isSearchable
                                        options={commercialsOptions}
                                        value={selectedCommercial}
                                        placeholder="Selecione..."
                                        className={`form-control p-0`}
                                        noOptionsMessage={() => 'Não há registros'}
                                        onChange={(val) => {
                                            onChange(!!val ? val : null);
                                            setSelectedCommercial(val);
                                        }}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={12} xs={12}>
                        <FormGroup className="form-group">
                            <label htmlFor="wasFinished"> VISITAS FINALIZADAS</label>
                            <Controller
                                name="wasFinished"
                                control={control}
                                render={({ field: { onChange, name, ref } }) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        isSearchable
                                        options={status}
                                        placeholder="Selecione..."
                                        className={`form-control p-0`}
                                        noOptionsMessage={() => 'Não há registros'}
                                        onChange={(val) => onChange(!!val ? val : null)}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={12} xs={12}>
                        <FormGroup className="form-group mb-1">
                            <label htmlFor="wasReview"> VER DIVERGÊNTES </label>
                            <Controller
                                name="wasReview"
                                control={control}
                                render={({ field: { onChange, name, ref } }) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        isSearchable
                                        options={review}
                                        placeholder="Selecione..."
                                        className={`form-control p-0`}
                                        noOptionsMessage={() => 'Não há registros'}
                                        onChange={(val) => onChange(!!val ? val : null)}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup className="form-group">
                            <label htmlFor="visitTypeContact ">TIPO DE CONTATO</label>
                            <ReactSelect
                                name="visitTypeContact "
                                isSearchable
                                isMulti
                                isClearable={typesContactsOptions?.length > 1}
                                options={typesContactsOptions}
                                placeholder="Selecione..."
                                className={`form-control p-0`}
                                value={selectedVisitTypeContact ?? []}
                                defaultValue={selectedVisitTypeContact}
                                noOptionsMessage={() => 'Não há registros'}
                                onChange={(val: any) => {
                                    setSelectedVisitTypeContact(val);
                                }}
                                styles={customStyles}
                            />
                        </FormGroup>
                    </Col>

                </Row>
            </div>
        </SidebarFilter>

    );
}

export default SidebarFilterDivergenceDetail;