import { FC, useEffect, useRef, useState } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { useCommercial } from '../../../../providers/Registrations/User/UserCommercialProvider';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { Controller, useForm } from 'react-hook-form';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import makeAnimated from 'react-select/animated';
import ReactSelect from 'react-select';
import moment from 'moment';
import { useMetricManagementProvider } from '../../../../providers/pgm/MetricManagement/MetricManagementProvider';

interface SidebarMetricFilterProps { };

export const defaultValues = {
    commercialId: '',
    productionMonth: ''
  } as any;

const SidebarMetricFilter:FC<SidebarMetricFilterProps> = () => {

    const [optionsMonths, setOptionsMonths]                         = useState<any>([]);
    const [selectedMonth, setSelectedMonth]                         = useState<any>();
    const [selectedCommercial, setSelectedCommercial]               = useState<any | null>(null);
    const { commercialsOptions, handleList: handleListCommercial }  = useCommercial();
    const {isLoading, metric, handleList }                          = useMetricManagementProvider();

    const animatedComponents                  = makeAnimated();
    const refSubmitButtom                     = useRef<HTMLButtonElement>(null);
    const { handleSubmit, setValue, control } = useForm<any>({ defaultValues });

    const onSubmit = async (data: any) => {
        await handleList(data);
    }

    useEffect(() => {
        if (commercialsOptions && commercialsOptions?.length > 1) {
          refSubmitButtom?.current?.click();
        }
        if (commercialsOptions && commercialsOptions?.length === 1) {
          setSelectedCommercial(commercialsOptions[0]);
        }
    }, [commercialsOptions]);
    
    useEffect(() => {
        setValue('commercialId', selectedCommercial)
    }, [selectedCommercial]);

    useEffect(() => {

        if (!commercialsOptions || commercialsOptions?.length === 0) {
            handleListCommercial();
        }

        const _months = [];
        let currentDate = moment();

        for (let i = 0; i < 12; i++) {
            _months.unshift({ label: currentDate.format('MMMM/YYYY'), value: currentDate.format('YYYY-MM-01') });
            currentDate = currentDate.subtract(1, 'months');
        }

        setOptionsMonths(_months);
        setSelectedMonth(_months.slice(-1)[0]);
    }, []);

    return (
        <SidebarFilter handleSubmit={handleSubmit(onSubmit)} buttonRef={refSubmitButtom}>
            <div className="SidebarMetricFilter" data-testid="SidebarMetricFilter">
                <Row className="mt-1">
                    <Col>
                        <FormGroup className="form-group">
                            <label htmlFor="monthProduction"> MÊS PRODUÇÃO </label>
                            <Controller
                                name="productionMonth"
                                control={control}
                                render={({ field: { onChange, name, ref } }) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        isSearchable
                                        options={optionsMonths}
                                        placeholder="Selecione..."
                                        className={`form-control p-0`}
                                        noOptionsMessage={() => 'Não há registros'}
                                        onChange={(val) => {
                                            onChange(val ? val : null);
                                            setSelectedMonth(val);
                                        }}
                                        styles={customStyles}
                                        value={selectedMonth}
                                    />
                                )}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col>
                        <FormGroup className="form-group">
                            <label htmlFor="commercialId"> COMERCIAL </label>
                            <Controller
                                name="commercialId"
                                control={control}
                                render={({ field: { onChange, name, ref } }) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        value={selectedCommercial ?? ''}
                                        isSearchable
                                        isClearable={commercialsOptions?.length > 1}
                                        options={commercialsOptions}
                                        placeholder="Selecione..."
                                        className={`form-control p-0`}
                                        noOptionsMessage={() => 'Não há registros'}
                                        components={animatedComponents}
                                        onChange={(val) => {
                                            onChange(val ? val : null);
                                            setSelectedCommercial(val);
                                        }}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </SidebarFilter>
    );
}

export default SidebarMetricFilter;