import { Dispatch, ReactNode, SetStateAction, useState, createContext, useContext } from "react";
import { UserDependentService } from "../../services/User/UserDependentService";

interface ContextProps {
    userDependent: any[],
    error: string,
    isLoading: boolean,
    setUserDependent: Dispatch<SetStateAction<any>>
    setError: Dispatch<SetStateAction<string>>,
    handleList: (id: number) => Promise<boolean>
}

export const UserDependentContext = createContext<ContextProps>({} as ContextProps);

interface UserDependentProviderProps {
    children: ReactNode;
}

export const UserDependentProvider = ({ children }: UserDependentProviderProps) => {

    const [ userDependent, setUserDependent ] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new UserDependentService();

    const handleList = async (userId:number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.perPartner(userId);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            setUserDependent(_Response?.data?.map((item: any) => {
                return {
                    value: item.id,
                    label: (item?.username || '') + ' - ' + item?.name
                }
            }) ?? [])
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao cancelar o termo');
            return false;
        }
    }

    return (
        <UserDependentContext.Provider value={{
            userDependent,
            error,
            isLoading,
            setError,
            setUserDependent,
            handleList
        }}>
            {children}
        </UserDependentContext.Provider> 
    );
};

export const useUserDependent = () => useContext(UserDependentContext);