import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";
import { ModuleModel } from "../../../models/Registrations/ModulesTool/ModuleModel";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { ModulesToolService } from "../../../services/Registrations/ModulesTool/ModulesToolService";
import { ToolModel } from "../../../models/Registrations/ModulesTool/ToolModel";

interface ContextProps {
    modules: ModuleModel[],
    moduleEdit: ModuleModel | null,
    setModuleEdit: Dispatch<SetStateAction<ModuleModel | null>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    isLoading: boolean,
    error: string | string[],
    setError: Dispatch<SetStateAction<string | string[]>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    handleList: () => Promise<any>,
    handleGetModule: (id: number) => Promise<any>,
    handleSaveModule: (payload: ModuleModel) => Promise<ModuleModel | null>,
    handleDeleteModule: (id: number) => Promise<boolean>,
    handleSaveTool: (payload: ToolModel) => Promise<ToolModel | null>,
    handleDeleteTool: (id: number) => Promise<boolean>
}

export const ModuleToolsContext = createContext<ContextProps>({} as ContextProps);

interface ModuleToolsProviderProps {
    children: ReactNode;
}

export const ModuleToolsProvider = ({ children }: ModuleToolsProviderProps) => {
    const [modules, setModules] = useState<ModuleModel[]>([]);
    const [moduleEdit, setModuleEdit] = useState<ModuleModel | null>(null);
    const [params, setParams] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | string[]>('');
    const [pages, setPages] = useState<PagesPaginateModel>({});

    const service = new ModulesToolService();

    const handleList = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Error);
                setModules([]);
                setPages({});
                return;
            }

            setModules(_Response?.data);
            setPages(_Response?.pages);
            setError('');
            return;

        } catch (e: any) {
            setIsLoading(false);
            setError(e);
            setModules([]);
            setPages({});
            return;
        }
    }

    const handleGetModule = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.getModule(id);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Error);
                return;
            }

            setModuleEdit(_Response?.data);
            setError('');
            return;

        } catch (e: any) {
            setIsLoading(false);
            setError(e);
            return;
        }
    }

    const handleSaveModule = async (payload: ModuleModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.fetchModule(payload);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Error);
                return null;
            }

            setError('');
            return _Response?.data;

        } catch (e: any) {
            setError(e);
            return null;
        }
    }

    const handleDeleteModule = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.deleteModule(id);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setError(e);
            return false;
        }
    }

    const handleSaveTool = async (payload: ToolModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error, _Code, _Errors] = await service.fetchTool(payload);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Errors?.length > 0 ? _Errors : _Error);
                return null;
            }

            setError('');
            return _Response?.data;

        } catch (e: any) {
            setError(e);
            return null;
        }
    }

    const handleDeleteTool = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.deleteTool(id);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setError(e);
            return false;
        }
    }

    return (
        <ModuleToolsContext.Provider value={{
            modules,
            moduleEdit,
            setModuleEdit,
            params,
            setParams,
            isLoading,
            error,
            setError,
            pages,
            setPages,
            handleList,
            handleGetModule,
            handleSaveModule,
            handleDeleteModule,
            handleSaveTool,
            handleDeleteTool
        }}>
            {children}
        </ModuleToolsContext.Provider>
    );
}

export const useModuleTools = () => useContext(ModuleToolsContext);