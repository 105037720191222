import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import './ModalCampaignFilters.scss';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import opportunities from "../../../../pages/Marketplace/Opportunities/Opportunities";
import {useOpportunity} from "../../../../providers/Marketplace/Opportunities/OpportunityProvider";
import {toast} from "react-toastify";
import {OpportunityModel} from "../../../../models/Marketplace/Opportunity/OpportunityModel";
import {CampaignModel} from "../../../../models/Marketplace/Campaign/CampaignModel";
import AppPagination from "../../../AppPagination/AppPagination";
import SkeletonTable from "../../../SkeletonTable/SkeletonTable";
import {CampaignStatusEnum} from "../../../../enums/Marktplace/Campaigns/CampaignStatusEnum";
import {dateFormat} from "../../../../utils/FormatDate";
import {Button, Card, Col, Row} from "react-bootstrap";
import { useCampaign } from '../../../../providers/Marketplace/Campaign/CampaignProvider';

interface ModalCampaignFiltersProps {
    show: boolean,
    onClose: any,
    campaignNumber?: number | null,
    setCampaignNumber:  Dispatch<SetStateAction<number | null>>
}

function capitalizeString(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const ModalCampaignFilters: FC<ModalCampaignFiltersProps> = ({ campaignNumber, setCampaignNumber, onClose, show }) => {
    const {
        handleGetFiltersByCampaign,
        filtersCampaign,
        error,
        setError,
        loading,
    } = useCampaign();

    useEffect(() => {
        if (!!error) {
            toast.error(error);
            setError('');
        }
    }, [error]);

    const isEmptyArray = (arr: any[]): boolean => arr.length === 0;

    const renderParam = (filtersCampaign: any[], filterName: string): JSX.Element => {
        if (filtersCampaign && !isEmptyArray(filtersCampaign)) {
            return (
                <a className="opacity-7" style={{ textTransform: 'capitalize' }}>
                    <b className="opacity-7">{filterName}</b>: &nbsp;
                    {filtersCampaign.map((param_value: any) => (
                        <div className={`badge badge-sm badge-primary m-1`} key={param_value}>
                            <small>{param_value}</small>
                        </div>
                    ))}
                </a>
            );
        } else {
            return <></>; 
        }
    }

    const renderContent = (): JSX.Element => {
        return (
            <>
                {!loading?.getFiltersCampaign ? (
                    <div >
                        <Row>
                            <Col md={7}>
                                <Card className="h-100">
                                    <Card.Body className="h-card">
                                        <div className="d-flex flex-column h-100">
                                            <b className="text-uppercase">Filtros</b>
                                            {renderParam(filtersCampaign?.financial_name || [], 'Financeira')}
                                            {renderParam(filtersCampaign?.productgroup_name || [], 'Produto')}
                                            {renderParam(filtersCampaign?.formcontract_name || [], 'Forma de Contrato')}
                                            {renderParam(filtersCampaign?.director_manager_name || [], 'Diretor')}
                                            {renderParam(filtersCampaign?.executive_manager_name || [], 'Executivo')}
                                            {renderParam(filtersCampaign?.regional_manager_name || [], 'Regional')}
                                            {renderParam(filtersCampaign?.commercial_manager_name || [], 'Comercial')}
                                            {renderParam(filtersCampaign?.typed_proposal_agent_partner_code || [], 'Agente Adicionados')}                                            
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={5}>
                                <Card className="h-50">
                                    <Card.Body className="h-card">
                                        <div className="d-flex flex-column h-100">
                                            <b className="text-uppercase">Cliente</b>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Idade</b>: {filtersCampaign?.client_age_min} - {filtersCampaign?.client_age_max}</a>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Cliente sem idade?</b> &nbsp;
                                                <div className={`badge badge-sm ${filtersCampaign?.client_without_age ? 'badge-success' : 'badge-danger'}`}>
                                                    {filtersCampaign?.client_without_age ? 'SIM' : 'NÃO'}
                                                </div>
                                            </a>

                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="h-50">
                                    <Card.Body className="h-card">
                                        <div className="d-flex flex-column h-100">
                                            <b className="text-uppercase">Proposta</b>
                                            {renderParam(filtersCampaign?.typed_proposal_id || [], 'Propostas Adicionadas')}
                                            {renderParam(filtersCampaign?.exception_proposal || [], 'Propostas Excluidas')}
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Faixa de valor</b>: {filtersCampaign?.typed_proposal_gross_value_min} - {filtersCampaign?.typed_proposal_gross_value_max}</a>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Proposta digitada a mais de: </b> {filtersCampaign?.typed_proposal_days} dias</a>

                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br/>
                        
                    </div>
                ) : (
                    <SkeletonTable />
                )}
            </>
        );
    }

    useEffect(() => {
        if (show) {
            handleGetFiltersByCampaign(campaignNumber || 0);
        }
    }, [show]);


    return (
        <ModalDefault
            title={`# ${campaignNumber} : Filtros`}
            sizeModal = 'xxl'
            show={show}
            onClose={onClose}
        >
            <div className="ModalCampaignFilters" data-testid="ModalCampaignFilters">
                {renderContent()}
            </div>
        </ModalDefault>
    )
};

export default ModalCampaignFilters;
