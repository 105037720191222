import api from "../Api";
import { BaseService } from "../BaseService";

const URL = 'api/log/screen';

export class LogService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async fetch(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.put(`${URL}/save`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async export(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/export`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}