import { FC, useEffect } from 'react';
import { TabEnumIndicatorManagement } from '../../../../pages/Visits/IndicatorManagement/IndicatorManagement';
import './FormTermVisits.scss';
import PageBase from '../../../PageBase/PageBase';
import { usePaginate } from '../../../../providers/PaginateProvider';
import { useVisitsTerm } from '../../../../providers/Visits/VisitsTerm/VisitsTermProvider'
import AppPagination from '../../../AppPagination/AppPagination';
import { VisitsTermModel } from '../../../../models/Visits/VisitsTerm/VisitsTermModel';
import moment from 'moment';
import { useStyle } from '../../../../providers/Style/StyleProvider';
import { useAuth } from '../../../../providers/AuthProvider';
import { toast } from 'react-toastify';
import { useIndicatorManagementWalletVisits } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementWalletProvider';

interface FormTermVisitsProps {
  currentActiveTab?: TabEnumIndicatorManagement;
}

const FormTermVisits: FC<FormTermVisitsProps> = ({ currentActiveTab }) => {
  const { urlDomus } = useStyle();
  const { accessToken } = useAuth();

  const { handlePaginate } = usePaginate();
  const { terms, isLoading, handleList, pages } = useVisitsTerm();

  const { params, setParams } = useIndicatorManagementWalletVisits();

  useEffect(() => {
    if (currentActiveTab === TabEnumIndicatorManagement.TERM) {
      if (params?.commercialId) {
        handleList(params).then();
      } else {
        toast.warning('Faça um filtro para listar o termo!');
      }
    }
  }, [currentActiveTab, params]);

  const renderList = (): JSX.Element => {

    return (
      <>
        {terms?.length > 0 ? (
          <>
            {terms.map((x: VisitsTermModel) => (
              <tr key={x.id} className="text-sm">
                <td className='text-wrap'>
                  <a
                    href={`${urlDomus}/app/api/v1/auth/authTokenSB?token=${accessToken}&redirect=${urlDomus}/vidadoagente.php?codigo=${x?.partnerId}`}
                    className="link-primary"
                    style={{ cursor: 'pointer' }}
                    target='_blank'
                  >
                    <b>{'[' + x.partnerCode + '] - '}</b>
                    {x.nameAgent}
                  </a>
                </td>
                <td className='text-wrap'>{x.descriptionTerm}</td>
                <td className='text-center'>{moment(x.dateCreatedAt).format('DD/MM/YYYY')}</td>
                <td className='text-center'>{x.hoursCreatedAt}</td>
                <td className='text-center'>
                  <span
                    className={`badge ${x.signatureAgent === 'NÃO' ? 'badge-danger' : 'badge-success'}`}>{x.signatureAgent === 'NÃO' ? 'NÃO' : 'SIM'}</span>
                </td>
                <td className='text-center'>
                  <span
                    className={`badge ${x.hasWitness === 'NÃO' ? 'badge-danger' : 'badge-success'}`}>{x.hasWitness === 'NÃO' ? 'NÃO' : 'SIM'}</span>
                </td>
                <td className='text-center'>
                  <span
                    className={`badge ${x.signatureWitness === 'NÃO' ? 'badge-danger' : 'badge-success'}`}>{x.signatureWitness === 'NÃO' ? 'NÃO' : 'SIM'}</span>
                </td>
                <td className='text-wrap'>{x.dateActingTerm}</td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>

    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {terms?.length > 0 && !isLoading ? (
          <>
            {/* <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button className="btn bg-success text-white mb-2 d-flex align-items-center" type="button">
                <i className="fa-regular fa-file-excel me-2"></i>
                Excel
              </button>
            </div> */}

            <div className='row'>
              <div className="table-responsive">
                <table className="table table-flush m-0 table-striped">
                  <thead className="thead-light">
                    <tr role="row">
                      <th className="text-uppercase opacity-7 text-wrap" scope="col">AGENTE</th>
                      <th className="text-uppercase opacity-7 text-wrap" scope="col">TERMO</th>
                      <th className="text-uppercase opacity-7" scope="col">DATA CRIAÇÃO</th>
                      <th className="text-uppercase opacity-7" scope="col">HORA CRIAÇÃO</th>
                      <th className="text-uppercase opacity-7" scope="col">ASSINADO AGENTE</th>
                      <th className="text-uppercase opacity-7" scope="col">POSSUI TESTEMUNHA</th>
                      <th className="text-uppercase opacity-7" scope="col">ASSINADO TESTEMUNHA</th>
                      <th className="text-uppercase opacity-7 text-wrap" scope="col" style={{ maxWidth: '200px' }}>TEMPO PARA CONCLUSÃO APÓS ASSINATURA PARCEIRO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderList()}
                  </tbody>
                </table>
              </div>

              {terms?.length > 0 && (
                <div>
                  <nav aria-label="...">
                    <AppPagination
                      pages={pages}
                      handlePaginate={handlePaginate}
                      params={params}
                      setParams={setParams}
                      style={{ backgroundColor: '#fff' }}
                    />
                  </nav>
                </div>
              )}
            </div >
          </ >
        ) : (
          <>
            <h4>TERMO</h4>
            <div className='text-center font-weight-bold'>
              Sem dados para gerar a tabela!
            </div>
          </>
        )}

      </>
    );
  }

  return (
    <div className="FormTermVisits" data-testid="FormTermVisits">
      <PageBase
        content={renderContent()}
        showCardHeader={false}
      />

    </div>
  );
}

export default FormTermVisits;
