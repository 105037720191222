import { MonthlyGoalAccessProfileEnum } from "../../../enums/PanelConnects/MonthlyGoalAccessProfileEnum";
import { MonthlyGoalImportParams } from "../../../models/PanelConnects/MonthlyGoalImportParams";
import { MonthlyGoalModel } from "../../../models/PanelConnects/MonthlyGoalModel";
import { MonthlyGoalParams } from "../../../models/PanelConnects/MonthlyGoalParams";
import api from "../../Api";
import { BaseService } from "../../BaseService";
import FileSaver from "file-saver";

const URL = 'api/connect';

export class MonthlyGoalService extends BaseService {

    async list(params: MonthlyGoalParams): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/goals`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getType(): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/types`, { withPaginate: false }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getDownloadPDF(closingDate: string, accessProfileId: MonthlyGoalAccessProfileEnum): Promise<any[]> {
        try {
            const formdata = new FormData();
            formdata.append("closingDate", closingDate);
            formdata.append("accessProfileId", accessProfileId?.toString());

            const response = await api.post(`${URL}/import/download`, formdata, { responseType: 'blob' });
            const filename = response.headers['content-disposition'].split('filename=')[1];
            FileSaver.saveAs(response.data, filename, { autoBom: false });
            return [true, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async delete(id: number): Promise<any> {
        try {
            return this.handleResponse(await api.delete(`${URL}/goals`, { data: { id } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async save(monthlyGoal: MonthlyGoalModel): Promise<any> {
        try {
            const response = await api.put(`${URL}/goals`, monthlyGoal);

            return [response.data, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async import(params: MonthlyGoalImportParams, file: File): Promise<any> {
        try {
            const formdata = new FormData();
            formdata.append("closingDate", params?.closingDate?.toString() ?? '');
            formdata.append("accessProfileId", params?.accessProfileId?.toString() ?? '');
            formdata.append("file", file);
            if (params?.connectGoalId) {
                formdata.append("connectGoalId", params?.connectGoalId?.toString());
            }

            return this.handleResponse(await api.post(`${URL}/global/import`, formdata, { headers: { 'Content-Type': 'multipart/form-data' } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async importPDF(file: File, closingDate: string, accessProfileId: MonthlyGoalAccessProfileEnum): Promise<any> {
        try {
            const formdata = new FormData();
            formdata.append("file", file);
            formdata.append("closingDate", closingDate);
            formdata.append("accessProfileId", accessProfileId?.toString());

            return this.handleResponse(await api.post(`${URL}/import/save`, formdata, { headers: { 'Content-Type': 'multipart/form-data' } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}