import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import {ToastSettings} from "../../../utils/ToastSettings";
import {toast} from "react-toastify";
import {useReportRequest} from "../../Reports/ReportRequest/ReportRequestProvider";
import {CampaignModel} from "../../../models/Marketplace/Campaign/CampaignModel";
import {EndpointModel} from "../../../models/Marketplace/Endpoint/EndpointModel";
import {EndpointUsersModel} from "../../../models/Marketplace/Endpoint/EndpointUsersModel";
import { EndpointService } from "../../../services/Marketplace/Endpoint/EndpointService";

interface ContextProps {
    endpoints: EndpointModel[],
    setEndpoints: Dispatch<SetStateAction<EndpointModel[]>>,
    endpointUsers: EndpointUsersModel[],
    setEndpointUsers: Dispatch<SetStateAction<EndpointUsersModel[]>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    updatedEndpoint: boolean,
    updatedEndpointUsers: boolean,
    isLoading: boolean,
    isLoadingFile: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    loading: Loading,
    onClickChangeEndpointUserStatus: (user: number) => void,
    onClickChangeEndpointStatus: (endpoint: number) => void,
    handleGetEndpoints: () => any,
    handleGetEndpointsUsers: (endpoint: number) => any,
}

export const EndpointContext = createContext<ContextProps>({} as ContextProps);

export interface Loading {
    listSimulation: boolean,
    createCampaign: boolean,
    listendpoints: boolean,
}

interface EndpointProviderProps {
    children: ReactNode;
}

export const EndpointProvider = ({ children }: EndpointProviderProps) => {
    const [endpoints, setEndpoints] = useState<any[]>([]);
    const [endpointUsers, setEndpointUsers] = useState<any[]>([]);
    const [params, setParams] = useState<any>({});
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [updatedEndpoint, setUpdatedEndpoint] = useState<boolean>(false);
    const [updatedEndpointUsers, setUpdatedEndpointUsers] = useState<boolean>(false);
    const {isLoadingFile, setIsLoadingFile } = useReportRequest();

    const [loading, setLoading] = useState<Loading>({
        listSimulation: false,
        createCampaign: false,
        listendpoints: false
    });

    const service = new EndpointService();

    const handleGetEndpoints = async () => {
        try {
            setIsLoading(true);
            setUpdatedEndpoint(false);
            const [_Response, _Error] = await service.getEndpoints(params);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Error);
                return;
            }

            setEndpoints(_Response?.data);
            setPages(_Response?.pages);
            setError('');

            return;

        } catch (error: any) {
            console.warn(error);
            setIsLoading(false);
            setEndpoints([]);
            setError(error);
            return;
        }
    }

    const handleGetEndpointsUsers = async (endpoint: number) => {
        try {
            setIsLoading(true);
            setUpdatedEndpointUsers(false);
            const [_Response, _Error] = await service.getEndpointsUsers(endpoint);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Error);
                return;
            }

            setEndpointUsers(_Response?.data);
            setPages(_Response?.pages);
            setError('');

            return;

        } catch (error: any) {
            console.warn(error);
            setIsLoading(false);
            setEndpointUsers([]);
            setError(error);
            return;
        }
    }

    const onClickChangeEndpointStatus = async (endpoint: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.postEndpointChangeStatus(endpoint);


            if (!!_Error) {
                setError(_Error);
                return;
            }

            setError('');
            setUpdatedEndpoint(true);

            return;

        } catch (error: any) {
            console.warn(error);
            setError(error);
            return;
        }
    }

    const onClickChangeEndpointUserStatus = async (user: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.postEndpointChangeUserStatus(user);


            if (!!_Error) {
                setError(_Error);
                return;
            }

            setError('');
            setUpdatedEndpointUsers(true);

            return;

        } catch (error: any) {
            console.warn(error);
            setError(error);
            return;
        }
    }


    return (
        <EndpointContext.Provider value={{
            endpoints,
            setEndpoints,
            endpointUsers,
            setEndpointUsers,
            isLoading,
            isLoadingFile,
            updatedEndpoint,
            updatedEndpointUsers,
            error,
            setError,
            params,
            setParams,
            pages,
            loading,
            handleGetEndpoints,
            handleGetEndpointsUsers,
            onClickChangeEndpointStatus,
            onClickChangeEndpointUserStatus
        }}>
            {children}
        </EndpointContext.Provider>
    )
}

export const useEndpoint = () => useContext(EndpointContext);