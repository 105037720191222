import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

interface SkeletonCircleProps {
  width?: number
}

const SkeletonCircle: FC<SkeletonCircleProps> = ({ width = 200 }) => {
  return (
    <div className="SkeletonCircle" data-testid="SkeletonCircle">
      <div className='row'>
        <div className='col-12 text-center'>
          <Skeleton circle width={width} height={width} />
        </div>
      </div>
    </div>
  )
};

export default SkeletonCircle;
