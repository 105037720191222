import React, { FC, useEffect, useRef, useState } from 'react';
import { Col, Row, FormCheck } from 'react-bootstrap';
import './ModalProposalConfigDetail.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { ProposalFinancialModel } from '../../../../models/Proposals/Process/ProposalFinancialModel';
import { useProposalConfigDetail } from '../../../../providers/Proposals/Config/ProposalConfigDetailProvider';
import Tagfy from '../../../Tagfy/Tagfy';

interface ModalProposalConfigDetailProps {
  show: boolean;
  onClose: () => void;
  financial: ProposalFinancialModel;
}

const ModalProposalConfigDetail: FC<ModalProposalConfigDetailProps> = ({ show, onClose, financial }) => {
  const handleClose = async () => {
    onClose();
  }

  const { config, configs, handleListFieldGroup, updateFieldGroup } = useProposalConfigDetail();
  const [tags, setTags]                                             = useState([]);
  const inputRef                                                    = useRef(null);

  useEffect(() => {
    if (show) {
      handleListFieldGroup({financialId: financial?.id});
    }
  }, [show]);

  return (
    <ModalDefault
      title={`Lista de Cabeçalhos - ${financial?.name}`}
      show={show}
      onClose={handleClose}
      sizeModal={'xl'}
      backdrop="static"
    >
      <div className="ModalProposalConfigDetail" data-testid="ModalProposalConfigDetail">
        <Row>
          <Col>
            <div>
                <table className='table table-striped m-0 w-100'>
                  <thead>
                    <tr>
                      <th className="text-uppercase opacity-7" scope="col"> Grupo do cabeçalho </th>
                      <th className="text-uppercase opacity-7" scope="col"> Items configurados </th>
                      <th className="text-uppercase opacity-7" scope="col"> Coluna é Consignado ? </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      configs?.map((item: any, key: number) => (
                        <tr>
                          <td> { item?.name } </td>
                          <td>
                            <Tagfy 
                              disabled={true}
                              defaultValue={item?.headersFields.map((tag:any, i:number) => tag.key)}
                            /> 
                          </td>
                          <td> 
                            <FormCheck className="form-check form-switch">
                              <input
                                type="checkbox"
                                className="form-check-input mleft-4"
                                defaultChecked={ item?.consignedLoan == 1 || false }
                                defaultValue={ item?.id }
                                onChange={(e: any) => {
                                  updateFieldGroup({
                                    id: parseInt(e?.target?.value),
                                    consignedLoan: e?.target?.checked ? 1:0,
                                    financialId: financial?.id
                                  })
                                }}
                              />
                            </FormCheck> 
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
            </div>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
}

export default ModalProposalConfigDetail;
