import React, { FC, useEffect, useMemo, useState } from "react";
import "./TableMonthlyProduction.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import FormatMoney from "../../../../../utils/FormatMoney";
import SkeletonTable from "../../../../SkeletonTable/SkeletonTable";
import { TabEnumGeneralProduction } from "../GeneralProductionByUf/GeneralProductionByUf";
import AppDataTable from "../../../../AppDataTable/AppDataTable";
import { useBiGeneralProduction } from "../../../../../providers/BI/Dashboard/BiGeneralProductionProvider";
import { useBiDashboard } from "../../../../../providers/BI/Dashboard/BiDashboardProvider";
import { BI_VIEWS_ENUM } from "../../../../../models/BI/Dashboard/BiProductionParamsModel";
import moment from "moment";

interface TableMonthlyProductionProps {
  currentActiveTab?: TabEnumGeneralProduction;
}

const TableMonthlyProduction: FC<TableMonthlyProductionProps> = ({
  currentActiveTab,
}) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [hideButtons, setHideButtons] = useState<any[]>([]);
  const [currentMonth, setCurrentMonth] = useState<boolean>(false);

  const { productionByUfs, setProductionByUfs } = useBiGeneralProduction();
  const {
    params,
    handleListMonthlyProduction,
    isLoading,
    setParams,
    setHandleListOnFilter,
    resultOnFilter,
    setResultOnFilter,
    generalErrorMsg,
    isParamsUpdated,
    setIsParamsUpdated,
    handleExcel, handleSort
  } = useBiDashboard();

  useEffect(() => {
    if (isParamsUpdated) {
      setHandleListOnFilter(() => handleListMonthlyProduction);
      setParams({
        ...params,
        view: BI_VIEWS_ENUM.STATE,
        group: {},
      });
      if (!productionByUfs) {
        handleFetch().then();
      }
      setIsParamsUpdated(false);
    }
  }, [isParamsUpdated]);

  useEffect(() => {
    if (resultOnFilter) {
      setProductionByUfs(resultOnFilter);
      setResultOnFilter(undefined);
    }
  }, [resultOnFilter]);

  const handleFetch = async () => {
    const result = await handleListMonthlyProduction({
      ...params,
      view: BI_VIEWS_ENUM.STATE,
      group: {},
    }).then();

    if (result) {
      setProductionByUfs(result);
    }
  };

  const handleSortValue = (columns: any, sortDirection: any, rows: any) => {
    const valueRows = handleSort(columns, sortDirection, rows);
    setRows(valueRows);
  }

  const handleExport = (rows: any) => {
    let periods: string[] = [];
    if (productionByUfs?.items && productionByUfs?.items?.length > 0) {
      periods =
        productionByUfs?.items[0]?.periods?.map((x) => x.month ?? "") ?? [];
    }
    const _data: any[] = [];
    rows.forEach((item: any) => {
      const _row: any = {
        UF: item.name,
      };

      periods?.forEach((period) => {
        const month = moment(period, "YYYY-MM").format("MMMM")?.toUpperCase();
        _row[month] = item[period];
        _row["C/Q " + month] = item[period + "cq"];
        _row["Participação " + month] = item[period + "participation"];
      });
      if (currentMonth) {
        _row["Projeção"] = item?.projection;
        _row["Projeção C/Q"] = item?.projectionGrowth;
      }
      _data.push(_row);
    });
    handleExcel(_data, "Producao mensal geral por UF");
  };

  const mountColumns = useMemo(() => {
    if (productionByUfs?.items && productionByUfs?.items?.length > 0) {
      setCurrentMonth(false);

      const currentMonthFilter = new Date().getMonth() + 1;

      productionByUfs?.months?.forEach((item) => {
        const result = item.split("-") || [0, 0];
        const isMonth = { month: Number(result[1]), year: Number(result[0]) };
        if (isMonth.month === currentMonthFilter) {
          setCurrentMonth(true);
        }
      });

      const _columns: any = [
        {
          id: "colIndex",
          name: <div className="bg-info text-table text-uppercase">UF</div>,
          selector: (data: any) => data?.name,
          cell: (data: any) => (
            <div className="text-wrap text-bold">{data?.name}</div>
          ),
          sortable: true,
          width: "200px",
        },
      ];

      productionByUfs?.months?.forEach((item) => {
        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">
              {moment(item, "YYYY-MM").format("MMMM")}
            </div>
          ),
          selector: (data: any) => data[item] ?? 0,
          right: true,
          cell: (data: any) => (
            <div className="text-nowrap">{FormatMoney(data[item ?? ""])}</div>
          ),
          style: {
            paddingLeft: '18px',
            paddingRight: '18px',
          },
          sortable: true,
          omit: !hideButtons?.find((x: any) => x.alias === item)?.show,
        });

        _columns.push({
          name: <div className="bg-info text-table text-uppercase">C/Q</div>,
          selector: (data: any) => data[`${item}cq`] ?? 0,
          right: true,
          cell: (data: any) => (
            <div
              className={`${data[`${item}cq`] < 0 ? "bg-negative" : "bg-positive"
                } text-nowrap`}
            >
              {FormatMoney(data[`${item}cq`], false)}%
              <FontAwesomeIcon
                icon={data[`${item}cq`] < 0 ? faArrowDown : faArrowUp}
                className="ms-2"
              />
            </div>
          ),
          sortable: true,
          omit:
            !hideButtons?.find((x: any) => x.alias === "cq")?.show ||
            !hideButtons?.find((x: any) => x.alias === item)?.show,
        });

        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">
              Participação
            </div>
          ),
          selector: (data: any) => data[`${item}participation`] ?? 0,
          right: true,
          cell: (data: any) => (
            <div>{FormatMoney(data[`${item}participation`], false)}%</div>
          ),
          sortable: true,
          compact: true,
          omit:
            !hideButtons?.find((x: any) => x.alias === "participation")?.show ||
            !hideButtons?.find((x: any) => x.alias === item)?.show,
        });
      });
      if (currentMonth) {
        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">PROJEÇÃO</div>
          ),
          selector: (data: any) => data?.projection ?? 0,
          right: true,
          cell: (data: any) => (
            <div className="text-nowrap">{FormatMoney(data?.projection)}</div>
          ),
          sortable: true,
          style: {
            paddingLeft: '18px',
            paddingRight: '18px',
          },
          omit: !hideButtons?.find((x: any) => x.alias === "projection")?.show,
        });

        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">
              PROJEÇÃO C/Q
            </div>
          ),
          selector: (data: any) => data?.projectionGrowth ?? 0,
          right: true,
          cell: (data: any) => (
            <div
              className={`${data?.projectionGrowth < 0 ? "bg-negative" : "bg-positive"
                }`}
            >
              {FormatMoney(data?.projectionGrowth, false)}%
              <FontAwesomeIcon
                icon={data?.projectionGrowth < 0 ? faArrowDown : faArrowUp}
                className="ms-2"
              />
            </div>
          ),
          sortable: true,
          omit: !hideButtons?.find((x: any) => x.alias === "projectionGrowth")
            ?.show,
        });
      }

      setColumns(_columns);
    }
  }, [productionByUfs, hideButtons]);

  const mountRows = () => {
    if (productionByUfs?.items && productionByUfs?.items?.length > 0) {
      const _rows: any = [];

      productionByUfs?.items?.forEach((item) => {
        const _row: any = {
          name: item.state,
          active: item.active,
        };

        item?.periods?.forEach((period) => {
          _row[period.month ?? ""] = period.totalProduction;
          _row[`${period.month}cq`] = period.totalProductionGrowth;
          _row[`${period.month}participation`] =
            period.totalProductionPercentage;
        });

        if (item?.periods && item?.periods?.length > 0) {
          _row.projection =
            item?.periods[item?.periods?.length - 1]?.totalProductionProjection;
          _row.projectionGrowth =
            item?.periods[
              item?.periods?.length - 1
            ].totalProductionProjectionGrowth;
        }

        _rows.push(_row);
      });

      const _rowTotal: any = {
        name: 'TOTAL',
        active: '',
      };
      productionByUfs?.months?.forEach((period, key) => {
        _rowTotal[period ?? ""] = productionByUfs?.total?.[key].totalProduction;
        _rowTotal[`${period}cq`] = productionByUfs?.total?.[key].totalProductionGrowth;
        _rowTotal[`${period}participation`] = 100;
      });

      if (productionByUfs?.total && productionByUfs?.total?.length > 0) {
        _rowTotal.projection = productionByUfs?.total[productionByUfs?.total?.length - 1].totalProductionProjection
        _rowTotal.projectionGrowth = productionByUfs?.total[productionByUfs?.total?.length - 1].totalProductionProjectionGrowth
      }
      _rows.push(_rowTotal);

      setRows(_rows);
    } else {
      setRows([]);
    }
  };

  const mountHideButtons = () => {
    if (productionByUfs?.items && productionByUfs?.items?.length > 0) {
      const _buttons: any = [];
      productionByUfs?.months?.forEach((item) => {
        _buttons.push({
          alias: item,
          label: moment(item, "YYYY-MM").format("MMMM"),
          show: true,
        });
      });

      _buttons.push({
        alias: `cq`,
        label: "Crescimento/Queda",
        show: true,
      });

      _buttons.push({
        alias: `participation`,
        label: "Participação",
        show: true,
      });
      if (currentMonth) {
        _buttons.push({
          alias: `projection`,
          label: "Projeção",
          show: true,
        });

        _buttons.push({
          alias: `projectionGrowth`,
          label: "Projeção C/Q",
          show: true,
        });
      }

      setHideButtons(_buttons);
    }
  };

  useEffect(() => {
    mountRows();
    mountHideButtons();
  }, [productionByUfs]);

  const onClickHideButton = (button: any, index: number) => {
    const _hideButtons = [...hideButtons];
    button.show = !button.show;
    _hideButtons[index] = button;
    setHideButtons(_hideButtons);
  };

  return (
    <div
      className="TableMonthlyProduction"
      data-testid="TableMonthlyProduction"
    >
      {!isLoading ? (
        <>
          {rows?.length > 0 ? (
            <>
              <Col md={12} className="d-flex justify-content-end">
                <Row className="display-button mb-3">
                  <div className="d-flex justify-content-between align-items-center flex-md-row">
                    <div className="d-flex gap-1 justify-content-center align-items-center flex-md-row">
                      {hideButtons?.map((x: any, index: number) => (
                        <button
                          type="button"
                          className="btn bg-primary text-white mb-0 ms-2 min-width-btn text-capitalize"
                          onClick={() => onClickHideButton(x, index)}
                        >
                          <FontAwesomeIcon
                            icon={x.show ? faEye : faEyeSlash}
                            className="me-1"
                          />
                          {x.label}
                        </button>
                      ))}
                      <button
                        className="btn bg-success text-white rounded m-0 ms-2"
                        onClick={() => {
                          handleExport(rows);
                        }}
                      >
                        <i className="fas fa-file-excel" /> Exportar
                      </button>
                    </div>
                  </div>
                </Row>
              </Col>

              <AppDataTable rows={rows} columns={columns} pagination={false} handleOrder={handleSortValue} />
            </>
          ) : (
            <div className="d-flex mt-9 justify-content-center general-error-msg">
              {generalErrorMsg}
            </div>
          )}
        </>
      ) : (
        <SkeletonTable />
      )}
    </div>
  );
};

export default TableMonthlyProduction;
