import api from "../../Api";
import { BaseService } from "../../BaseService";
import { PartnerParams } from "../../../models/Registrations/Partner/PartnerParams";
import { PartnerModel } from "../../../models/Registrations/Partner/PartnerModel";

const URL = 'api/user';

export class PartnerService extends BaseService {
    async list(params: PartnerParams): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/partnerByCommercial`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async fetch(company: PartnerModel): Promise<any> {
        try {
            return this.handleResponse(await api.put(`${URL}`, company));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async delete(id: number): Promise<any> {
        try {
            return this.handleResponse(await api.delete(`${URL}`, { data: { id } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listProspecting(commercialManagerId: number): Promise<any> {
        try {
            return this.handleResponse(await api.post(`api/app/prospecting/list`, { commercialManagerId }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async export(data:any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/export`, data));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async exportReactivatedAgent(data:any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/export/reactivated`, data));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}