import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react";
import { ContractFormConfigListModel } from "../../../models/Tools/Configurations/ContractForm/ContractFormConfigListModel";
import { ContractFormConfigParamsModel } from "../../../models/Tools/Configurations/ContractForm/ContractFormConfigParamsModel";
import { ContractFormConfigService } from "../../../services/Tools/Configurations/ContractFormConfigService";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";

interface ContextProps {
    contractFormsConfig: ContractFormConfigListModel[],
    params: ContractFormConfigParamsModel,
    setParams: Dispatch<SetStateAction<ContractFormConfigParamsModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    handleList: () => Promise<void>,
    handleSave: (payload: ContractFormConfigListModel) => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>
}

export const ContractFormConfigContext = createContext<ContextProps>({} as ContextProps);

interface ContractFormConfigProviderProps {
    children: ReactNode;
}

export const ContractFormConfigProvider = ({ children }: ContractFormConfigProviderProps) => {
    const [contractFormsConfig, setContractFormsConfig] = useState<ContractFormConfigListModel[]>([]);
    const [params, setParams] = useState<ContractFormConfigParamsModel>({});
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const service = new ContractFormConfigService();

    const handleList = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                setContractFormsConfig([]);
                setPages({});
                setError(_Error);
                return;
            }

            setContractFormsConfig(_Response?.data);
            setPages(_Response?.pages);
            setError('');
        } catch (e: any) {
            setIsLoading(false);
            setContractFormsConfig([]);
            setPages({});
            setError(e);
        }
    }

    const handleSave = async (payload: ContractFormConfigListModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.save(payload);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e);
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e);
            return false;
        }
    }

    return (
        <ContractFormConfigContext.Provider value={useMemo(() => ({
            contractFormsConfig,
            params,
            setParams,
            isLoading,
            error,
            setError,
            pages,
            setPages,
            handleList,
            handleSave,
            handleDelete,
        }), [
            contractFormsConfig,
            params,
            setParams,
            isLoading,
            error,
            setError,
            pages,
            setPages,
            handleList,
            handleSave,
            handleDelete
        ])}>
            {children}
        </ContractFormConfigContext.Provider>
    );
}

export const useContractFormConfig = () => useContext(ContractFormConfigContext);