export function nameAbbreviation(fullName: string, limit: number) {
    if (fullName?.length > limit) {
        return toAbridged(fullName);
    }
    return fullName;
}


function toAbridged(fullName: string) {
    const token = '.';
    const separator = ' ';
    const names = removePrepositions(fullName)?.split(separator);
    const firstName = names[0];
    let surnames = '';
    names
        ?.filter((name: string, index: number) => index)
        ?.map((name: string) => surnames += `${separator}${name?.charAt(0)}${token}`);
    return `${firstName}${surnames?.toUpperCase()}`;
}

function removePrepositions(fullName: string) {
    return fullName?.replace(/\ dos|\ das|\ dos|\ das|\ de|\ d\'/gi, '');
}