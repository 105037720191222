import { FC, useEffect, useMemo, useState } from "react";
import "./TableAnnualProduction.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import FormatMoney from "../../../../../../utils/FormatMoney";
import { useBiCovenant } from "../../../../../../providers/BI/Dashboard/Covenant/BiCovenantProvider";
import AppDataTable from "../../../../../AppDataTable/AppDataTable";
import { useBiDashboard } from "../../../../../../providers/BI/Dashboard/BiDashboardProvider";
import { BI_VIEWS_ENUM } from "../../../../../../models/BI/Dashboard/BiProductionParamsModel";
import SkeletonTable from "../../../../../SkeletonTable/SkeletonTable";

interface TableAnnualProductionProps {}

const TableAnnualProduction: FC<TableAnnualProductionProps> = () => {
  const [columns, setColumns] = useState<any[]>([]);
  const [colAnnualProduction, setColAnnualProduction] = useState<boolean>(true);
  const [colAnnualProjection, setColAnnualProjection] = useState<boolean>(true);
  const [colParticipation, setColParticipation] = useState<boolean>(true);
  const [colAnnualAverage, setColAnnualAverage] = useState<boolean>(true);

  const { covenants, setCovenants } = useBiCovenant();
  const {
    handleListAnnualProduction,
    params,
    isLoading,
    setParams,
    setHandleListOnFilter,
    resultOnFilter,
    setResultOnFilter,
    generalErrorMsg,
    handleExcel,
  } = useBiDashboard();

  useEffect(() => {
    setHandleListOnFilter(() => handleListAnnualProduction);
    setParams({
      ...params,
      view: BI_VIEWS_ENUM.COVENANT,
      group: {},
    });
    if (!covenants) {
      handleFetch().then();
    }
  }, []);

  useEffect(() => {
    if (resultOnFilter) {
      setCovenants(resultOnFilter);
      setResultOnFilter(undefined);
    }
  }, [resultOnFilter]);

  const handleFetch = async () => {
    const result = await handleListAnnualProduction({
      ...params,
      view: BI_VIEWS_ENUM.COVENANT,
      group: {},
    }).then();

    if (result) {
      setCovenants(result);
    }
  };

  const handleExport = (rows: any) => {
    const _data: any[] = [];
    rows.forEach((item: any) => {
      const _row: any = {
        Convenios: item.name,
      };

      item?.periods?.forEach((period: any) => {
        _row["Producao anual"] = period.totalProduction;
        _row["Producao anual D.U"] = period.totalProductionProjection;
        _row["Participacao"] = period.totalProductionPercentage;
        _row["Media D.U"] = period.totalProductionByWorkdays;
      });
      _data.push(_row);
    });
    handleExcel(_data, "Producao anual por convenio");
  };

  const mountColumns = useMemo(() => {
    if (covenants?.items && covenants?.items?.length > 0) {
      const _columns: any = [
        {
          id: "colIndex",
          name: (
            <div className="bg-info text-table text-uppercase">Convênios</div>
          ),
          selector: (data: any) => data?.name,
          cell: (data: any) => (
            <div className="text-wrap text-bold">{data?.name}</div>
          ),
          sortable: true,
          width: "250px",
        },
      ];

      covenants?.items[0].periods?.forEach((item: any, index: number) => {
        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">
              Produção Anual
            </div>
          ),
          selector: (data: any) => data?.periods[index]?.totalProduction,
          right: true,
          cell: (data: any) => (
            <div className="text-nowrap">
              {FormatMoney(data?.periods[index]?.totalProduction)}
            </div>
          ),
          sortable: true,
          omit: !colAnnualProduction,
        });

        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">
              Projeção Anual D.U
            </div>
          ),
          selector: (data: any) =>
            data?.periods[index]?.totalProductionProjection,
          right: true,
          cell: (data: any) => (
            <div className="text-nowrap">
              {FormatMoney(data?.periods[index]?.totalProductionProjection)}
            </div>
          ),
          sortable: true,
          omit: !colAnnualProjection,
        });

        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">
              {" "}
              Participação{" "}
            </div>
          ),
          selector: (data: any) =>
            data?.periods[index]?.totalProductionPercentage,
          right: true,
          cell: (data: any) => (
            <div>
              {FormatMoney(
                data?.periods[index]?.totalProductionPercentage,
                false
              )}
              %
            </div>
          ),
          sortable: true,
          omit: !colParticipation,
        });

        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">Média D.U</div>
          ),
          selector: (data: any) =>
            data?.periods[index]?.totalProductionByWorkdays,
          right: true,
          cell: (data: any) => (
            <div className="text-nowrap">
              {FormatMoney(data?.periods[index]?.totalProductionByWorkdays)}
            </div>
          ),
          sortable: true,
          omit: !colAnnualAverage,
        });
      });

      setColumns(_columns);
    }
  }, [
    covenants,
    colAnnualProduction,
    colAnnualProjection,
    colParticipation,
    colAnnualAverage,
  ]);

  return (
    <div className="TableAnnualProduction" data-testid="TableAnnualProduction">
      <>
        {!isLoading ? (
          <>
            {covenants?.items ? (
              <>
                <Col md={12} className="d-flex justify-content-end">
                  <Row className="display-button mb-3">
                    <div className="d-flex justify-content-between align-items-center flex-md-row">
                      <div className="d-flex gap-1 justify-content-center align-items-center flex-md-row">
                        <button
                          type="button"
                          className="btn bg-primary text-white mb-0 ms-2 min-width-btn"
                          onClick={() =>
                            setColAnnualProduction((current) => !current)
                          }
                        >
                          <FontAwesomeIcon
                            icon={colAnnualProduction ? faEye : faEyeSlash}
                            className="me-1"
                          />
                          Produção Anual
                        </button>

                        <button
                          type="button"
                          className="btn bg-primary text-white mb-0 ms-2 min-width-btn"
                          onClick={() =>
                            setColAnnualProjection((current) => !current)
                          }
                        >
                          <FontAwesomeIcon
                            icon={colAnnualProjection ? faEye : faEyeSlash}
                            className="me-1"
                          />
                          Projeção Anual D.U
                        </button>

                        <button
                          type="button"
                          className="btn bg-primary text-white mb-0 ms-2 min-width-btn"
                          onClick={() =>
                            setColParticipation((current) => !current)
                          }
                        >
                          <FontAwesomeIcon
                            icon={colParticipation ? faEye : faEyeSlash}
                            className="me-1"
                          />
                          Participação
                        </button>

                        <button
                          type="button"
                          className="btn bg-primary text-white mb-0 ms-2 min-width-btn"
                          onClick={() =>
                            setColAnnualAverage((current) => !current)
                          }
                        >
                          <FontAwesomeIcon
                            icon={colAnnualAverage ? faEye : faEyeSlash}
                            className="me-1"
                          />
                          Média D.U
                        </button>
                        <button
                          className="btn bg-success text-white rounded m-0 ms-2"
                          onClick={() => {
                            handleExport(covenants?.items);
                          }}
                        >
                          <i className="fas fa-file-excel" /> Exportar
                        </button>
                      </div>
                    </div>
                  </Row>
                </Col>

                <AppDataTable
                  columns={columns}
                  rows={covenants?.items}
                  pagination={false}
                />
              </>
            ) : (
              <div className="d-flex mt-9 justify-content-center general-error-msg">
                {generalErrorMsg}
              </div>
            )}
          </>
        ) : (
          <SkeletonTable />
        )}
      </>
    </div>
  );
};

export default TableAnnualProduction;
