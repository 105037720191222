import { FC, useEffect, useRef, useState } from 'react';
import './ProposalConfigExport.scss';
import MasterPage from '../../../../components/MasterPage/MasterPage';
import PageBase from '../../../../components/PageBase/PageBase';
import SkeletonTable from '../../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../../components/AppPagination/AppPagination';
import { useSidebarFilter } from '../../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../../providers/PaginateProvider';
import DropdownMenuTable from '../../../../components/DropdownMenuTable/DropdownMenuTable';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import ModalProposalConfigExportInsert from '../../../../components/Proposals/Config/ModalProposalConfigExportInsert/ModalProposalConfigExportInsert';
import moment from 'moment';
import { ProposalExportHeaderConfigurationModel, ProposalExportHeaderFieldsConfigurationModel } from '../../../../models/Proposals/Config/ProposalExportHeaderConfigurationModel';
import { useProposalConfig } from '../../../../providers/Proposals/Config/ProposalConfigProvider';
import { NormalizesFieldNames } from '../../../../utils/Proposals/NormalizesFieldNames';

interface ProposalConfigExportProps { }

const ProposalConfigExport: FC<ProposalConfigExportProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
  const [itemConfigure, setItemConfigure] = useState<ProposalExportHeaderConfigurationModel>();

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { handlePaginate } = usePaginate();
  const {
    handleListExportHeaderConfiguration,
    exportHeaderConfigurations,
    isLoading,
    pages,
    params,
    setParams } = useProposalConfig();

  const refs = useRef<any[]>([]);

  useEffect(() => {
    handleListExportHeaderConfiguration().then();
  }, [params]);

  useEffect(() => {
    if (!showModalInsert) {
      setItemConfigure(undefined);
    }
  }, [showModalInsert]);

  const handleConfigure = (item: ProposalExportHeaderConfigurationModel) => {
    setItemConfigure(item);
    setShowModalInsert(true);
  }

  const renderConfiguredFields = (fields: ProposalExportHeaderFieldsConfigurationModel) => {
    const fieldsToRender: string[] = [];
    Object.keys(fields).forEach((value: string, index: number) => {
      const _key = value as keyof ProposalExportHeaderFieldsConfigurationModel;
      if ((fields[_key]?.length ?? 0) > 0) {
        fieldsToRender.push(value);
      }
    });
    return (
      <>
        {fieldsToRender?.map((x: string) => <div className='badge bg-info me-1 mb-1'>{NormalizesFieldNames(x)}</div>)}
      </>
    );
  }

  const renderList = (): JSX.Element => {
    return (
      <>
        {exportHeaderConfigurations?.length > 0 ? (
          <>
            {exportHeaderConfigurations.map((x: ProposalExportHeaderConfigurationModel, key: number) => (
              <tr key={x.id} className="text-sm">
                <td id={`financialImage${x.financial?.id}`} ref={(el) => refs.current[key] = el}>
                  <img
                    src={`/assets/img/financials/${x.financial?.id}.svg`}
                    onError={(e: any) => refs.current[key].innerHTML = `<h6>${x.financial?.name ?? ''}</h6>`}
                  />
                </td>
                <td className='text-wrap'>
                  <div>
                    {renderConfiguredFields(x.config ?? {})}
                  </div>
                </td>
                <td>{x?.createdAt ? moment(x?.createdAt).format('DD/MM/YYYY HH:mm:ss') : 'Sem data informada'}</td>
                <td className="text-center">
                  <DropdownMenuTable key={key}>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleConfigure(x)}>
                      <FontAwesomeIcon icon={faCog} className="me-2" /> Configurar
                    </Dropdown.Item>
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={8} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <>
            <div className="table-responsive">
              <table className="table table-flush m-0 w-100">
                <thead className="thead-light">
                  <tr>
                    <th className="text-uppercase opacity-7" scope="col">Financeira</th>
                    <th className="text-uppercase opacity-7" scope="col">Campos Já Configurados</th>
                    <th className="text-uppercase opacity-7" scope="col">Data</th>
                    <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {renderList()}
                </tbody>
              </table>
            </div>
            {exportHeaderConfigurations?.length > 0 && (
              <div>
                <nav aria-label="...">
                  <AppPagination
                    pages={pages}
                    handlePaginate={handlePaginate}
                    params={params}
                    setParams={setParams}
                    style={{ backgroundColor: '#fff' }}
                  />
                </nav>
              </div>
            )}
          </>
        ) : (
          <SkeletonTable />
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="Configuração de Cabeçalhos para Exportação"
      icon="fas fa-table"
      footerFixed
      footerInverse
    >
      <div className="ProposalConfigExport" data-testid="ProposalConfigExport">
        <PageBase
          title="Configuração de Cabeçalhos para Exportação"
          subtitle="Gerenciamento de cabeçalhos para exportação de produção."
          content={renderContent()}
        />

        <ModalProposalConfigExportInsert
          show={showModalInsert}
          onClose={() => setShowModalInsert(false)}
          item={itemConfigure ?? {}}
        />
      </div>

      {/* <SidebarProposalConfigExportFilter /> */}
    </MasterPage>
  );
}

export default ProposalConfigExport;
