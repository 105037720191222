import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastSettings } from "../../../utils/ToastSettings";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { ReportRequestModel } from "../../../models/Reports/ReportRequest/ReportRequestModel";
import { ReportRequestParams } from "../../../models/Reports/ReportRequest/ReportRequestParams";
import { ReportRequestService } from "../../../services/Reports/ReportRequest/ReportRequestService";

interface ContextProps {
    reportRequest: ReportRequestModel,
    setReportRequest: Dispatch<SetStateAction<ReportRequestModel>>,
    reportRequests: ReportRequestModel[],
    setReportRequests: Dispatch<SetStateAction<ReportRequestModel[]>>,
    params: ReportRequestParams,
    setParams: Dispatch<SetStateAction<ReportRequestParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    isLoadingDownload: boolean,
    isLoadingFile: boolean,
    setIsLoadingFile: Dispatch<SetStateAction<boolean>>,
    showSidebar: boolean,
    setShowSidebar: Dispatch<SetStateAction<boolean>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleFetch: (data: ReportRequestModel) => Promise<boolean>,
    handleDownload: (id: number) => Promise<boolean>,
}

export const ReportRequestContext = createContext<ContextProps>({} as ContextProps);

interface ReportRequestProviderProps {
    children: ReactNode;
}

export const ReportRequestProvider = ({ children }: ReportRequestProviderProps) => {
    const [reportRequest, setReportRequest] = useState<ReportRequestModel>({} as ReportRequestModel);
    const [reportRequests, setReportRequests] = useState<ReportRequestModel[]>([]);
    const [params, setParams] = useState<ReportRequestParams>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [showSidebar, setShowSidebar] = useState<boolean>(false);

    const service = new ReportRequestService();
    const location = useLocation();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/report/request/list') && !showSidebar) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response?.message || _Error);
            }

            setReportRequests(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar os relatórios do sistema.');
        }
    }, [params, location.pathname, showSidebar]);

    const handleFetch = async (data: ReportRequestModel) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.fetch(data);
            if (!!_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setError('Houve um erro ao salvar o relatório');
            return false;
        }
    }

    const handleDownload = async (id: number) => {
        try {
            setIsLoadingDownload(true);

            const [_Response, _Error, _Code, _Errors] = await service.download(id);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                setIsLoadingDownload(false);
                return false;
            }

            setError('');
            setIsLoadingDownload(false);

            ToastSettings('Relatório baixado com sucesso!', 'bottom-center', 'success');

            return true;
        } catch (e) {
            setError('Houve um erro ao baixar o relatório');
            setIsLoadingDownload(false);
            return false;
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    useEffect(() => {
        setShowSidebar(false);
    }, []);

    return (
        <ReportRequestContext.Provider value={{
            reportRequest,
            setReportRequest,
            reportRequests,
            setReportRequests,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            isLoadingDownload,
            isLoadingFile,
            setIsLoadingFile,
            showSidebar,
            setShowSidebar,
            error,
            setError,
            handleList,
            handleFetch,
            handleDownload
        }}>
            {children}
        </ReportRequestContext.Provider>
    );
}

export const useReportRequest = () => useContext(ReportRequestContext);