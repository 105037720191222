import { FC, useEffect } from 'react';
import { TabEnumIndicatorManagement } from '../../../../pages/Visits/IndicatorManagement/IndicatorManagement';
import { Card } from 'react-bootstrap';
import { useIndicatorManagementProductionRange } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementProductionRangeProvider';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import ProductionChartPerMonth from './ProductionChartPerMonth/ProductionChartPerMonth';
import SkeletonBar from '../../../SkeletonBar/SkeletonBar';
import ProductionChartByRange from './ProductionChartByRange/ProductionChartByRange';
import ProductionChartByRangeCurrentMonth from './ProductionChartByRangeCurrentMonth/ProductionChartByRangeCurrentMonth';
import SkeletonCircle from '../../../SkeletonCircle/SkeletonCircle';
import ProductionTableByRange from './ProductionTableByRange/ProductionTableByRange';
import { useIndicatorManagementWalletVisits } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementWalletProvider';

interface FormProductionVisitsProps {
  currentActiveTab?: TabEnumIndicatorManagement;
}

const FormProductionVisits: FC<FormProductionVisitsProps> = ({ currentActiveTab }) => {

  const {
    handleGetProduction,
    error,
    setError,
    isLoading,
    productionsPerMonth,
    productionsRangeList,
    isFileLoading } = useIndicatorManagementProductionRange();
  const { params } = useIndicatorManagementWalletVisits();

  useEffect(() => {
    if (currentActiveTab === TabEnumIndicatorManagement.PRODUCTION_RANGE) {
      if (params?.commercialId) {
        handleGetProduction(params).then();
      } else {
        toast.warning('Faça um filtro para listar a Produção por Faixa!');
      }
    }
  }, [currentActiveTab, params]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError('');
    }
  }, [error]);

  useEffect(() => {
    if (isFileLoading) {
      Swal.fire({
        title: "<p class='title-swal m-0'>Solicitando Relatório<br/>Aguarde um momento!.</p>",
        allowOutsideClick: false
      });
      Swal.showLoading();
    } else {
      if (error) {
        Swal.fire(
          {
            title: `<p class='title-swal m-0'>${error}</p>`,
            icon: 'error',
          });
        Swal.hideLoading();
      } else {
        Swal.close();
      }
    }
  }, [isFileLoading]);

  return (
    <div className="FormProductionVisits d-flex flex-column gap-4 pb-4" data-testid="FormProductionVisits">
      <Card>
        <Card.Body>
          {(!!productionsRangeList && !!productionsRangeList?.lines && productionsRangeList?.lines?.length > 0) ?
            <>
              {!isLoading ?
                <ProductionTableByRange />
                :
                <SkeletonTable />
              }
            </>
            :
            <>
              <h4>PRODUÇÃO POR FAIXA - TABELA</h4>
              <div className='text-center font-weight-bold'>
                Sem dados para gerar a tabela!
              </div>
            </>
          }
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <h4 className='mb-5'>PRODUÇÃO POR MÊS</h4>
          {productionsPerMonth?.length > 0 ?
            <>
              {!isLoading ?
                <ProductionChartPerMonth />
                :
                <SkeletonBar
                  numberOfBars={12}
                  minHeight={70}
                  maxHeight={450}
                />
              }
            </>
            :
            <div className='text-center font-weight-bold'>
              Sem dados para gerar o gráfico!
            </div>
          }
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <h4 className='mb-5'>PRODUÇÃO POR FAIXA - GRÁFICO</h4>
          {productionsRangeList?.lines && productionsRangeList?.lines?.length > 0 ?
            <>
              {!isLoading ?
                <ProductionChartByRange />
                :
                <SkeletonBar
                  numberOfBars={12}
                  minHeight={70}
                  maxHeight={450}
                />
              }
            </>
            :
            <div className='text-center font-weight-bold'>
              Sem dados para gerar o gráfico!
            </div>
          }
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <h4 className='mb-5'>PRODUÇÃO POR FAIXA - MÊS ATUAL</h4>
          {productionsRangeList?.lines && productionsRangeList?.lines?.length > 0 ?
            <>
              {!isLoading ?
                <ProductionChartByRangeCurrentMonth />
                :
                <SkeletonCircle width={450} />
              }
            </>
            :
            <div className='text-center font-weight-bold'>
              Sem dados para gerar o gráfico!
            </div>
          }
        </Card.Body>
      </Card>

    </div>
  );
}

export default FormProductionVisits;
