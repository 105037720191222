import { BaseService } from "../BaseService";
import api from "../Api";

const URL = "api/user/api-key";

export class ApiKeyService extends BaseService {
  async createKey(): Promise<any[]> {
    try {
      return this.handleResponse(await api.post(`${URL}`));
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async listKey(): Promise<any[]> {
    try {
      return this.handleResponse(await api.get(`${URL}?withPaginate=0`));
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }
}
