import { FC, useEffect, useState } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import SelectCompany from '../../../Company/SelectCompany/SelectCompany';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validate';
import { useForm, Controller } from 'react-hook-form';
import { useCompany } from '../../../../../providers/Registrations/Company/CompanyProvider';
import { useAuth } from '../../../../../providers/AuthProvider';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../../models/SelectCustomStyles';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { useMultiSteps } from '../../../../../providers/MultiStepsProvider';

interface StepGeneralInformationProps {
  handleExtraAction?: any;
  textButtonExtraAction?: string;
}

const StepGeneralInformation: FC<StepGeneralInformationProps> = ({ handleExtraAction, textButtonExtraAction }) => {
  const [companySelected, setCompanySelected] = useState<any | null>(null);
  const [userTypeSelected, setUserTypeSelected] = useState<any | null>(null);
  const [accessLevelSelected, setAccessLevelSelected] = useState<any | null>(null);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [isLoadingSubmit, setisLoadingSubmit] = useState<boolean>(false);

  const { companysOptions } = useCompany();
  const { userLogged, onInvalid } = useAuth();
  const { currentStep, renderNav, handleNext } = useMultiSteps();

  const { handleSubmit, register, setValue, control, reset, formState: { errors } } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (companysOptions?.length > 0) {
      setCompanySelected(companysOptions.filter((item: any) => item.value === userLogged?.accessProfile?.companyId)?.shift());
    }
  }, [companysOptions, userLogged]);

  useEffect(() => {
    !!companySelected && setValue('company', companySelected);
    !!userTypeSelected && setValue('userType', userTypeSelected);
    !!accessLevelSelected && setValue('accessLevel', accessLevelSelected);
  }, [companySelected, userTypeSelected, accessLevelSelected]);

  const onSubmit = () => {

  }

  return (
    <div className="StepGeneralInformation" data-testid="StepGeneralInformation">
      <Row>
        <Col>
          <h5 className="text-start m-0">
            <div className="wrapper">
              <div className="wrapper-icon me-1"><FontAwesomeIcon icon={faSignInAlt} /></div>
              <span>Informações Gerais</span>
            </div>
          </h5>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={3}>
          <FormGroup className="form-group">
            <SelectCompany
              title="Promotora"
              inputName="company"
              control={control}
              setValue={setValue}
              register={register}
              errors={errors}
              valueSelected={companySelected}
              setValueSelected={setCompanySelected}
              onChangeSelect={() => { }}
            />
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup className="form-group">
            <label htmlFor="userType">TIPO DO AGENTE *</label>
            {control && (
              <Controller
                name="userType"
                control={control}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isClearable
                    isSearchable
                    options={[]}
                    placeholder="Selecione..."
                    className={`form-control p-0 ${onInvalid(errors?.userType)}`}
                    value={userTypeSelected || ''}
                    defaultValue={userTypeSelected}
                    onChange={val => {
                      onChange(val?.value || '');
                      setUserTypeSelected(val);
                    }}
                    noOptionsMessage={() => 'Não há registros'}
                    styles={customStyles}
                  />
                )}
              />
            )}
            <ErrorMessage name="Tipo do Agente" type={errors?.userType?.type} />
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup className="form-group">
            <label htmlFor="accessLevel">NÍVEL DE ACESSO *</label>
            {control && (
              <Controller
                name="accessLevel"
                control={control}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isClearable
                    isSearchable
                    options={[]}
                    placeholder="Selecione..."
                    className={`form-control p-0 ${onInvalid(errors?.accessLevel)}`}
                    value={accessLevelSelected || ''}
                    defaultValue={accessLevelSelected}
                    onChange={val => {
                      onChange(val?.value || '');
                      setAccessLevelSelected(val);
                    }}
                    noOptionsMessage={() => 'Não há registros'}
                    styles={customStyles}
                  />
                )}
              />
            )}
            <ErrorMessage name="Nível de Acesso" type={errors?.accessLevel?.type} />
          </FormGroup>
        </Col>
      </Row>

      {renderNav(handleSubmit(onSubmit), isLoading || isLoadingSubmit, handleExtraAction, textButtonExtraAction)}
    </div>
  );
}

export default StepGeneralInformation;
