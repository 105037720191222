import api from "../Api";
import { BaseService } from "../BaseService";

const URL = 'api/user/tag-type';

export class UserTagTypeService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}