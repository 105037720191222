import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import AuthenticatorValidationAlternativeService from "../../services/Authenticator/AuthenticatorValidationAlternativeService";
import { ToastSettings } from "../../utils/ToastSettings";
import { AuthenticatorValidationAlternativeFetchParams } from "../../models/Authenticator/AuthenticatorValidationAlternativeFetchParams";
import { useAuth } from "../AuthProvider";
import { useStyle } from "../Style/StyleProvider";

interface ContextProps {
    phoneSent: string,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleSend: () => Promise<boolean>,
    handleVerify: (code: string) => Promise<boolean>,
}

export const AuthenticatorValidationAlternativeContext = createContext<ContextProps>({} as ContextProps);

interface AuthenticatorValidationAlternativeProviderProps {
    children: ReactNode;
}

export const AuthenticatorValidationAlternativeProvider = ({ children }: AuthenticatorValidationAlternativeProviderProps) => {
    const [phoneSent, setPhoneSent] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const { urlDomus } = useStyle();
    const { accessToken } = useAuth();
    const service = new AuthenticatorValidationAlternativeService();

    const handleSend = async () => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.send();
            if (!!_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            if (_Code === 202) {
                window.location.href = `${urlDomus}/app/api/v1/auth/authTokenSB?token=${accessToken}`;
                return true;
            }

            setError('');
            toast.success(_Response?.message || 'Código validado com sucesso!');

            setPhoneSent(_Response?.data?.number);

            return true;
        } catch (e) {
            setError('Houve um erro ao validar o código');
            return false;
        }
    }

    const handleVerify = async (code: string) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.verify({ code });
            if (!!_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            toast.success(_Response?.message || 'Código validado com sucesso!');
            return true;
        } catch (e) {
            setError('Houve um erro ao validar o código');
            return false;
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        if (phoneSent) {
            localStorage.setItem('@lacquaGui:phoneSent', JSON.stringify(phoneSent));
        }
    }, [phoneSent]);

    useEffect(() => {
        const disposer = () => {
            const _phoneSent = localStorage.getItem(`@lacquaGui:phoneSent`);
            setPhoneSent(!!_phoneSent ? JSON.parse(_phoneSent) : null);
        }
        disposer();
    }, []);

    return (
        <AuthenticatorValidationAlternativeContext.Provider value={{
            phoneSent,
            isLoading,
            error,
            setError,
            handleSend,
            handleVerify
        }}>
            {children}
        </AuthenticatorValidationAlternativeContext.Provider>
    );
}

export const useAuthenticatorValidationAlternative = () => useContext(AuthenticatorValidationAlternativeContext);