export enum dateChar {
    year = 'Y',
    month = 'm',
    day = 'd',
    hour = 'H',
    minute = 'i',
    second = 's',
}

export enum dateFormats {
    databaseDate = 'Y-m-d', // `${dateChar.year}-${dateChar.month}-${dateChar.day}`,
    screenDate = 'd/m/Y', // `${dateChar.day}/${dateChar.month}/${dateChar.year}`,
    time = 'H:i:s', // `${dateChar.hour}:${dateChar.minute}:${dateChar.second}`,
    databaseDateTime = 'Y-m-d H:i:s', // `${databaseDate} ${time}`,
    screenDateTime = 'd/m/Y H:i:s', // `${screenDate} ${time}`,
}

const dateToDatetime = <dateFormats>(format: dateFormats) => {
    switch (format) {
        case dateFormats.databaseDate: return dateFormats.databaseDateTime;
        case dateFormats.screenDate: return dateFormats.screenDateTime;
        default: return format;
    }
}

interface DateFormatProps {
    date?: string,
    datetime?: boolean,
    inputFormat?: dateFormats,
    outputFormat?: string | dateFormats,
}

export const dateFormat = ({ date, datetime = true, inputFormat = dateFormats.databaseDate, outputFormat = dateFormats.screenDate }: DateFormatProps) => {

    let value = new Date(), time = date && date.search(':') === -1 ? ' 00:00:00' : '';

    if (datetime && outputFormat.search(':') === -1) {
        inputFormat = dateToDatetime(inputFormat);
        outputFormat = dateToDatetime(outputFormat);
    }

    if (date) {
        if (inputFormat === dateFormats.screenDate || inputFormat === dateFormats.screenDateTime || date.split('/').length === 3) {
            const [fullDate, fullTime] = date.split(' ');
            const [day, month, year] = fullDate.split('/');
            date = `${year}-${month}-${day} ${fullTime ?? ''}`;
        }

        if (inputFormat === dateFormats.time || (date.search(/[\/\-]/g) === -1 && date.split(':').length === 3)) {
            const now = new Date();
            date = `${now.getFullYear()}-${now.getMonth()}-${now.getDate()} ${date}`;
        }

        value = new Date(`${date + time}`);
    }

    if (value.toDateString() === 'Invalid Date') {

        return '';
    }

    const day = (value.getDate() < 10 ? '0' : '') + value.getDate();
    const month = (value.getMonth() < 9 ? '0' : '') + (value.getMonth() + 1);
    const year = '' + value.getFullYear();
    const hour = (value.getHours() < 10 ? '0' : '') + value.getHours();
    const minute = (value.getMinutes() < 10 ? '0' : '') + value.getMinutes();
    const second = (value.getSeconds() < 10 ? '0' : '') + value.getSeconds();

    return outputFormat
        .replace(dateChar.day, day)
        .replace(dateChar.month, month)
        .replace(dateChar.year, year)
        .replace(dateChar.hour, hour)
        .replace(dateChar.minute, minute)
        .replace(dateChar.second, second)
        ;
}