export class CompanyModel {
    id?: number;
    externalId?: number;
    name?: string;
    corporateName?: string;
    acronym?: string;
    cnpj?: string;
    phone?: string;
    hostname?: string;
    colorPrimary?: string;
    colorSecondary?: string;
    colorText?: string;
    logoDefault?: string;
    logoInverse?: string;
    favicon?: string;
    backgroundLogin?: string;
    email?: string;
    cep?: string;
    publicPlace?: string;
    numbers?: string;
    complement?: string;
    neighborhood?: string;
    city?: string;
    state?: string;
    address?: any;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}