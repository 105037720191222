import { FC, useEffect } from 'react';
import './VisitsRankingFormContract.scss';
import { Card } from 'react-bootstrap';
import { useVisitsRanking } from '../../../../../providers/Visits/VisitsRanking/VisitsRankingProvider';
import FormatMoney from '../../../../../utils/FormatMoney';
import { AgentFormOfContractModel } from '../../../../../models/Visits/VisitsRanking/VisitsRankingModel';
import AppDataTable from '../../../../AppDataTable/AppDataTable';
import SkeletonTable from '../../../../SkeletonTable/SkeletonTable';

interface VisitsRankingFormContractProps {
}

const VisitsRankingFormContract: FC<VisitsRankingFormContractProps> = () => {

  const { rankings, isLoading } = useVisitsRanking();

  const rows = rankings?.agentFormOfContract?.map((item: AgentFormOfContractModel) => {
    return {
      name: item?.name,
      last_month: item?.last_month,
      projection: item?.projection,
      ranking_last_month: item?.ranking_last_month,
      growth: item?.growth,
      month_before_last: item?.month_before_last,
      ranking_month_before_last: item?.ranking_month_before_last
    };
  })

  const columns = [
    {
      name: "NOME",
      selector: (row: any) => row.name,
      sortable: true
    },
    {
      name: "VALOR PRODUÇÃO ATUAL",
      selector: (row: any) => row.last_month,
      sortable: true,
      center: true,
      format: (row: any) => FormatMoney(row.last_month)
    },
    {
      name: "PROJEÇÃO",
      selector: (row: any) => row.projection,
      sortable: true,
      center: true,
      format: (row: any) => FormatMoney(row.projection)
    },
    {
      name: "RANKING ATUAL",
      selector: (row: any) => row.ranking_last_month,
      sortable: true,
      center: true
    },
    {
      name: "% CRESC/ QUEDA",
      selector: (row: any) => row.growth,
      sortable: true,
      center: true
    },
    {
      name: "VALOR PRODUÇÃO ANTERIOR",
      selector: (row: any) => row.month_before_last,
      sortable: true,
      center: true,
      format: (row: any) => FormatMoney(row.month_before_last)
    },
    {
      name: "RANKING ANTERIOR",
      selector: (row: any) => row.ranking_month_before_last,
      sortable: true,
      center: true
    }
  ];

  return (
    <div className="VisitsRankingFormContract" data-testid="VisitsRankingFormContract">
      <Card>
        <Card.Header className='pb-0'>
          <strong>FORMA CONTRATO</strong>
        </Card.Header>
        <Card.Body className='pt-0'>

          {!isLoading ? (
            <AppDataTable rows={rows} columns={columns} />
          ) : (
            <SkeletonTable />
          )}

        </Card.Body>
      </Card>
    </div>
  );

}

export default VisitsRankingFormContract;
