import RootRoute from './routes/RootRoute';
import { ToastContainer } from 'react-toastify';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import Chart from 'chart.js/auto';

function App() {

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    ChartDataLabels
  );

  return (

    <div className="App" data-testid="App">
      <RootRoute />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </div>
  );
}

export default App;
