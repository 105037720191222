import React, { FC } from 'react';
import './CurrentAccount.scss';

interface CurrentAccountProps {}

const CurrentAccount: FC<CurrentAccountProps> = () => (
  <div className="CurrentAccount" data-testid="CurrentAccount">
    CurrentAccount Component
  </div>
);

export default CurrentAccount;
