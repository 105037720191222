import React, { FC, useEffect, useState } from "react";
import "./GeneralProductionByUf.scss";
import PageBase from "../../../../PageBase/PageBase";
import { Col, Row } from "react-bootstrap";
import NavPills from "../../../../NavPills/NavPills";
import TableMonthlyProduction from "../TableMonthlyProduction/TableMonthlyProduction";
import TableAnnualProduction from "../TableAnnualProduction/TableAnnualProduction";
import ChartParticipation from "../ChartParticipation/ChartParticipation";
import { useAuth } from "../../../../../providers/AuthProvider";

interface GeneralProductionByUfProps {}

export enum TabEnumGeneralProduction {
  MONTHLY_PRODUCTION = "monthly_production",
  ANNUAL_PRODUCTION = "annual_production",
  IMPORTATION_OF_CURRENT_COMMISSION = "importation_of_current_commission",
  IMPORT_COMMISSION_PER_RESPONSIBLE = "import_commission_per_responsible",
  CHART_PARTICIPATION = "chart_participation",
}

const GeneralProductionByUf: FC<GeneralProductionByUfProps> = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState<
    TabEnumGeneralProduction | string
  >(TabEnumGeneralProduction.MONTHLY_PRODUCTION);

  const renderContent = (): JSX.Element => {
    return (
      <>
        <Row>
          <Col md={12}>
            <NavPills
              mdMenu={12}
              options={[
                {
                  acronym: TabEnumGeneralProduction.MONTHLY_PRODUCTION,
                  name: "Produção Mensal",
                  icon: <i className="fa-solid fa-table"></i>,
                  show: true,
                },
                {
                  acronym: TabEnumGeneralProduction.ANNUAL_PRODUCTION,
                  name: "Produção Anual",
                  icon: <i className="fa-solid fa-table"></i>,
                  show: true,
                },
                {
                  acronym: TabEnumGeneralProduction.CHART_PARTICIPATION,
                  name: "Gráfico de Participação",
                  icon: <i className="fa-solid fa-chart-pie"></i>,
                  show: true,
                },
              ]}
              current={currentActiveTab}
              setCurrent={setCurrentActiveTab}
            />
          </Col>
        </Row>
        {currentActiveTab === TabEnumGeneralProduction.MONTHLY_PRODUCTION && (
          <TableMonthlyProduction
            currentActiveTab={currentActiveTab as TabEnumGeneralProduction}
          />
        )}
        {currentActiveTab === TabEnumGeneralProduction.ANNUAL_PRODUCTION && (
          <TableAnnualProduction
            currentActiveTab={currentActiveTab as TabEnumGeneralProduction}
          />
        )}
        {currentActiveTab === TabEnumGeneralProduction.CHART_PARTICIPATION && (
          <ChartParticipation
            currentActiveTab={currentActiveTab as TabEnumGeneralProduction}
          />
        )}
      </>
    );
  };

  return (
    <div className="GeneralProductionByUf" data-testid="GeneralProductionByUf">
      <PageBase content={renderContent()} showCardHeader={false} />
    </div>
  );
};

export default GeneralProductionByUf;
