import React, { FC, useEffect, useState } from 'react';
import './RegionalPointsTab.scss';
import { MonitoringPointsHeaderModel } from '../../../../models/PanelConnects/MonitoringCommercialPoints/MonitoringPointsHeaderModel';
import { useMonitoringCommercialPoints } from '../../../../providers/PanelConnects/MonitoringCommercialPoints/MonitoringCommercialPointsProvider';
import DataTable from '../../../DataTable/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface RegionalPointsTabProps {
  itemsPerPage: number
}

const RegionalPointsTab: FC<RegionalPointsTabProps> = ({ itemsPerPage }) => {
  const [regionalHeader, setRegionalHeader] = useState<MonitoringPointsHeaderModel[]>([]);

  const { header, points, isLoading, regionalPoints, getNameCode } = useMonitoringCommercialPoints();

  useEffect(() => {
    if (header?.length > 0 && points?.regional && points?.regional?.length > 0) {
      setRegionalHeader([
        {
          field: 'regional',
          headerName: 'REGIONAL',
          pinned: 'left',
          minWidth: 250,
          cellRenderer: getNameCode,
          comparator: (valueA: any, valueB: any) =>
            valueA?.name < valueB?.name ? -1 : 1
        },
        ...header,
        {
          field: 'executive',
          headerName: 'EXECUTIVO',
          wrapText: false,
          minWidth: 250,
          cellRenderer: getNameCode,
          comparator: (valueA: any, valueB: any) =>
            valueA?.name < valueB?.name ? -1 : 1
        },
        {
          field: 'director',
          headerName: 'DIRETOR',
          wrapText: false,
          minWidth: 250,
          cellRenderer: getNameCode,
          comparator: (valueA: any, valueB: any) =>
            valueA?.name < valueB?.name ? -1 : 1
        },
      ]);
    } else {
      setRegionalHeader([]);
    }
  }, [header, points]);

  return (
    <div className="RegionalPointsTab" data-testid="RegionalPointsTab">
      {!isLoading ?
        <DataTable
          columns={regionalHeader}
          data={regionalPoints}
          rowHeight={50}
          rowPerPage={itemsPerPage}
        /> :
        <div className='d-flex justify-content-center align-items-center min-height-400'>
          <FontAwesomeIcon icon={faSpinner} className='text-2xl' spin />
        </div>
      }
    </div>
  )
};

export default RegionalPointsTab;
