import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { UserTagTypeModel } from "../../../models/User/UserTagTypeModel";
import { UserTagTypeService } from "../../../services/User/UserTagTypeService";
import { toast } from "react-toastify";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";

interface ContextProps {
    tagTypes: UserTagTypeModel[],
    tagTypesOptions: ReactSelectOptions[],
    handleList: () => Promise<boolean>,
}

export const TagTypeContext = createContext<ContextProps>({} as ContextProps);

interface TagTypeProviderProps {
    children: ReactNode;
}

export const TagTypeProvider = ({ children }: TagTypeProviderProps) => {
    const [tagTypes, setTagTypes] = useState<UserTagTypeModel[]>([]);
    const [tagTypesOptions, setTagTypesOptions] = useState<ReactSelectOptions[]>([]);
    const [error, setError] = useState<string>('');

    const service = new UserTagTypeService();

    const handleList = useCallback(async () => {
        try {
            const [_Response, _Error] = await service.list({ withPaginate: false });

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setTagTypes(_Response.data);

            setError('');
            return true;
        } catch (e) {
            setError('Não foi possível carregar as promotoras');
            return false;
        }
    }, []);

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        setTagTypesOptions(
            tagTypes
                ?.map((item: UserTagTypeModel) => {
                    return { label: item.name, value: item.id };
                })
        )
    }, [tagTypes]);

    return (
        <TagTypeContext.Provider value={{
            tagTypes,
            tagTypesOptions,
            handleList,
        }}>
            {children}
        </TagTypeContext.Provider>
    );
}

export const useTagType = () => useContext(TagTypeContext);