import { FC, useEffect, useState } from 'react';
import { ProposalRuleOperationEnum } from '../../../../enums/Proposals/Rule/Operation/ProposalRuleOperationEnum';
import { useProposalConfig } from '../../../../providers/Proposals/Config/ProposalConfigProvider';
import ReactSelect from 'react-select';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { customStyles } from '../../../../models/SelectCustomStyles';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { Controller } from 'react-hook-form';
import { ReactSelectOptions } from '../../../../models/ReactSelectOptions';
import makeAnimated from 'react-select/animated';
import { useAuth } from '../../../../providers/AuthProvider';
import Tagfy from '../../../Tagfy/Tagfy';
import { typeInsert } from '../../../../enums/Proposals/Rule/Operation/TypeInsertEnum';

interface ProposalRuleChooseOperationProps {
  operationId: number;
  register: any;
  control: any | null;
  errors: any;
  setValue: any;
}

const ProposalRuleChooseOperation: FC<ProposalRuleChooseOperationProps> = ({ operationId, register, control = null, errors, setValue }) => {
  const [selectedHeaderField, setSelectedHeaderField] = useState<ReactSelectOptions[]>([]);
  const [selectedTypeInsert, setSelectedTypeInsert] = useState<ReactSelectOptions | null>(null);
  const [selectedTableColumnsName, setSelectedTableColumnsName] = useState<ReactSelectOptions | null>(null);
  const [values, setValues] = useState<any>([]);

  const { onInvalid } = useAuth();
  const { fieldsOptions } = useProposalConfig();
  const { fieldsTablesColumnsNamesOptions } = useProposalConfig();

  const animatedComponents = makeAnimated();

  useEffect(() => {
    setSelectedHeaderField([]);
    setSelectedTypeInsert(null);
    setSelectedTableColumnsName(null);
    setValues([]);
  }, []);

  useEffect(() => {
    setValue('tableColumnsNameId', selectedTableColumnsName);
  }, [selectedTableColumnsName]);

  useEffect(() => {
    setValue('headerFieldId', selectedHeaderField);
  }, [selectedHeaderField]);

  useEffect(() => {
    setValue('headerFieldId', values);
  }, [values]);

  useEffect(() => {
    if (operationId) {
      setSelectedHeaderField([]);
      setSelectedTypeInsert(null);
      setSelectedTableColumnsName(null);
      setValues([]);
    }
  }, [operationId]);

  return (
    <Row className="ProposalRuleChooseOperation" data-testid="ProposalRuleChooseOperation">
      {operationId !== ProposalRuleOperationEnum.DO_NOT_PROCESS && (
        <>
          <Col md={4}>
            <FormGroup className="form-group m-0">
              <label htmlFor="typeInsertId">Tipo de Inserção *</label>
              {control && (
                <Controller
                  name="typeInsertId"
                  control={control}
                  render={({ field: { onChange, name, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      name={name}
                      isSearchable
                      options={typeInsert}
                      placeholder="Selecione..."
                      className={`form-control p-0 ${onInvalid(errors?.typeInsertId)}`}
                      value={selectedTypeInsert ?? ''}
                      defaultValue={selectedTypeInsert}
                      components={animatedComponents}
                      noOptionsMessage={() => 'Não há registros'}
                      onChange={(val: any) => {
                        onChange(val ?? null);
                        setSelectedTypeInsert(val);
                      }}
                      styles={customStyles}
                    />
                  )}
                />
              )}
              <ErrorMessage name={'Tipo de Inserção'} type={errors?.typeInsertId?.type?.toString()} />
            </FormGroup>
          </Col>
          <Col>
            {selectedTypeInsert?.value ? (
              <>
                {selectedTypeInsert?.value === 1 ? (
                  <FormGroup className="form-group m-0">
                    <label htmlFor="headerFieldId">Valores *</label>
                    <Tagfy
                      handleAdd={(e: any) => setValues((current: any) => current ? [...current, e] : [e])}
                      handleRemove={(e: any) => setValues((current: any) => current.filter((x: string) => x !== e))}
                    />
                    <ErrorMessage name='Valores' type={errors?.headerFieldId?.type?.toString()} />
                  </FormGroup>
                ) : (
                  <FormGroup className="form-group m-0">
                    <label htmlFor="headerFieldId">Campo da Planilha *</label>
                    {control && (
                      <Controller
                        name="headerFieldId"
                        control={control}
                        render={({ field: { onChange, name, ref } }) => (
                          <ReactSelect
                            ref={ref}
                            name={name}
                            isMulti
                            isClearable
                            isSearchable
                            options={fieldsTablesColumnsNamesOptions}
                            placeholder="Selecione..."
                            className={`form-control p-0 ${onInvalid(errors?.headerFieldId)}`}
                            value={selectedHeaderField ?? ''}
                            defaultValue={selectedHeaderField}
                            components={animatedComponents}
                            noOptionsMessage={() => 'Não há registros'}
                            onChange={(val: any) => {
                              onChange(val?.length > 0 ? val : null);
                              setSelectedHeaderField(val);
                            }}
                            styles={customStyles}
                          />
                        )}
                      />
                    )}
                    <ErrorMessage name='Campo da Planilha' type={errors?.headerFieldId?.type?.toString()} />
                  </FormGroup>
                )}
              </>
            ) : (
              <p className="message-info m-0 text-sm text-gray text-center">Selecione o tipo de inserção</p>
            )}
          </Col>
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="tableColumnsNameId">Campo Interno *</label>
              {control && (
                <Controller
                  name="tableColumnsNameId"
                  control={control}
                  render={({ field: { onChange, name, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      name={name}
                      isClearable
                      isSearchable
                      options={fieldsOptions}
                      placeholder="Selecione..."
                      className={`form-control p-0 ${onInvalid(errors?.tableColumnsNameId)}`}
                      value={selectedTableColumnsName ?? ''}
                      defaultValue={selectedTableColumnsName}
                      components={animatedComponents}
                      noOptionsMessage={() => 'Não há registros'}
                      onChange={(val: any) => {
                        onChange(val ?? null);
                        setSelectedTableColumnsName(val);
                      }}
                      styles={customStyles}
                    />
                  )}
                />
              )}
              <ErrorMessage name="Campo interno a ser atualizado"
                type={errors?.tableColumnsNameId?.type?.toString()} />
            </FormGroup>
          </Col>
        </>
      )}
    </Row>
  );
}

export default ProposalRuleChooseOperation;
