import React, { FC, useEffect, useState } from 'react';
import './FormQualityVisits.scss';
import { TabEnumIndicatorManagement } from '../../../../pages/Visits/IndicatorManagement/IndicatorManagement';
import { Card, FormGroup } from 'react-bootstrap';
import { useIndicatorManagementQuality } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementQualityProvider';
import { toast } from 'react-toastify';
import { IndicatorManagementQualityListModel, IndicatorManagementQualityModel } from '../../../../models/Visits/IndicatorManagement/IndicatorManagementQualityModel';
import AppPagination from '../../../AppPagination/AppPagination';
import { usePaginate } from '../../../../providers/PaginateProvider';
import moment from 'moment';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpDown } from '@fortawesome/free-solid-svg-icons';

interface FormQualityVisitsProps {
  currentActiveTab?: TabEnumIndicatorManagement;
}

const FormQualityVisits: FC<FormQualityVisitsProps> = ({ currentActiveTab }) => {
  const [showPromoter, setShowPromoter] = useState<boolean>(true);
  const [showQuality, setShowQuality] = useState<boolean>(true);
  const [ascDesc, setAscDesc] = useState<boolean>(false)
  const [qualitysFilter, setQualitysFilter] = useState<IndicatorManagementQualityModel>();

  const { handleGetQuality, handleGetExcel, qualityList, isLoading, params, setParams, pages, error, setError, setQualityList } = useIndicatorManagementQuality();
  const { handlePaginate } = usePaginate();

  useEffect(() => {
    setQualitysFilter(qualityList);
  }, [qualityList]);

  const sortQuality = (property: any) => {
    let key: keyof IndicatorManagementQualityListModel = property;
    const result = qualityList?.claims?.sort((a: IndicatorManagementQualityListModel, b: IndicatorManagementQualityListModel) => (a[key] || '') < (b[key] || '') ? (ascDesc ? -1 : 1) : (ascDesc ? 1 : -1)) ?? [];
    setAscDesc((current) => !current);
    setQualityList({ ...qualityList, claims: result })
  }

  const filterQuality = (property: string) => {
    const result = qualityList?.claims?.filter((item: IndicatorManagementQualityListModel) => (item?.partnerCode?.toUpperCase().includes(property?.toUpperCase()) || item?.partnerName?.toUpperCase().includes(property?.toUpperCase()))) ?? [];
    setQualitysFilter({ ...qualityList, claims: result })
  }

  useEffect(() => {
    if (currentActiveTab === TabEnumIndicatorManagement.QUALITY) {
      if (params?.commercialId) {
        handleGetQuality().then();
      } else {
        toast.warning('Faça um filtro para listar os dados de Qualidade!');
      }
    }
  }, [currentActiveTab, params]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError('');
    }
  }, [error]);

  return (
    <div className="FormQualityVisits mb-3" data-testid="FormQualityVisits">
      <Card>
        <Card.Body>
          {!!qualitysFilter?.claims && qualitysFilter?.claims?.length > 0 ?
            <>
              <div className='d-flex justify-content-between align-items-center flex-column flex-md-row'>
                <div className='d-flex gap-2 justify-content-center align-items-center flex-column flex-md-row'>
                  <strong>Exibir/Ocultar:</strong>
                  <button
                    className='btn btn-sm btn-primary m-0'
                    onClick={() => setShowPromoter((current: boolean) => !current)}
                  >
                    <i className='fa-regular fa-eye me-1' />
                    Promotora
                  </button>
                  <button
                    className='btn btn-sm btn-secondary text-white m-0'
                    onClick={() => setShowQuality((current: boolean) => !current)}
                  >
                    <i className='fa-regular fa-eye me-1' />
                    Qualidade 3 Meses
                  </button>
                  <button
                    className='btn btn-sm btn-success text-white m-0'
                    onClick={() => handleGetExcel(params)}
                  >
                    <i className='fa-regular fa-file-excel me-1' />
                    Excel
                  </button>
                </div>

                <div className='mt-2 mt-md-0'>
                  <FormGroup className="form-group">
                    <strong>Pesquisar Parceiro</strong>
                    <input
                      type='text'
                      className="form-control"
                      onChange={(e: any) => filterQuality(e.target.value)}
                    />
                  </FormGroup>
                  {/* <AppQuantityPerPage
                    params={params}
                    setParams={setParams}
                    pages={pages}
                  /> */}
                </div>
              </div>

              {!isLoading ?
                <div className='table-responsive mt-3'>
                  <table className='table table-striped'>
                    <thead>
                      <tr className='text-center'>
                        <th className='bg-primary' colSpan={showPromoter ? 5 : 4}>AGENTE</th>
                        <th className='bg-purple' colSpan={7}>QUALIDADE 12 MESES</th>
                        <th className='bg-secondary' colSpan={showQuality ? 9 : 3}>QUALIDADE 3 MESES</th>
                      </tr>

                      <tr>
                        <th className="text-center p-0 pb-2 m-0 px-2 bg-primary text-table text-wrap cursor-pointer" rowSpan={2} onClick={() => sortQuality('partnerCode')}>
                          <div className='d-flex  w-100 flex-column justify-content-between' style={{ height: '81px' }}>
                            <div>&nbsp;</div>
                            <div>ID</div>
                            <div className='text-end'>
                              <FontAwesomeIcon icon={faUpDown} />
                            </div>
                          </div>
                        </th>
                        <th className="text-center p-0 pb-2 m-0 px-2 bg-primary text-table text-wrap cursor-pointer" rowSpan={2} onClick={() => sortQuality('partnerName')}>
                          <div className='d-flex  w-100 flex-column justify-content-between' style={{ height: '81px' }}>
                            <div>&nbsp;</div>
                            <div>PARCEIRO</div>
                            <div className='text-end'>
                              <FontAwesomeIcon icon={faUpDown} />
                            </div>
                          </div>
                        </th>
                        {showPromoter &&
                          <th className="text-center p-0 pb-2 m-0 px-2 bg-primary text-table text-wrap cursor-pointer" rowSpan={2} onClick={() => sortQuality('promoterName')}>
                            <div className='d-flex  w-100 flex-column justify-content-between' style={{ height: '81px' }}>
                              <div>&nbsp;</div>
                              <div>PROMOTORA</div>
                              <div className='text-end'>
                                <FontAwesomeIcon icon={faUpDown} />
                              </div>
                            </div>
                          </th>
                        }

                        <th className="text-center p-0 pb-2 m-0 px-2 bg-primary text-wrap text-table text-wrap cursor-pointer" rowSpan={2} onClick={() => sortQuality('createdAt')}>
                          <div className='d-flex  w-100 flex-column justify-content-between' style={{ height: '81px' }}>
                            <div>&nbsp;</div>
                            <div>DATA CADASTRO</div>
                            <div className='text-end'>
                              <FontAwesomeIcon icon={faUpDown} />
                            </div>
                          </div>
                        </th>

                        <th className="text-center p-0 pb-2 m-0 px-2 bg-primary text-wrap text-table text-wrap cursor-pointer" rowSpan={2} onClick={() => sortQuality('checkLevel')}>
                          <div className='d-flex  w-100 flex-column justify-content-between' style={{ height: '81px' }}>
                            <div>&nbsp;</div>
                            <div>NÍVEL CHECAGEM</div>
                            <div className='text-end'>
                              <FontAwesomeIcon icon={faUpDown} />
                            </div>
                          </div>
                        </th>

                        <th className="text-center p-0 pb-2 m-0 px-2 bg-production text-wrap text-table text-wrap cursor-pointer" rowSpan={2} onClick={() => sortQuality('productionQuantity')}>
                          <div className='d-flex  w-100 flex-column justify-content-between' style={{ height: '81px' }}>
                            <div>&nbsp;</div>
                            <div>QTD PRODUÇÃO</div>
                            <div className='text-end'>
                              <FontAwesomeIcon icon={faUpDown} />
                            </div>
                          </div>
                        </th>

                        <th className="text-center p-0 pb-2 m-0 px-2 text-wrap bg-purple text-table text-wrap cursor-pointer" rowSpan={2} onClick={() => sortQuality('validComplaint')}>
                          <div className='d-flex  w-100 flex-column justify-content-between' style={{ height: '81px' }}>
                            <div>&nbsp;</div>
                            <div>RECLAMAÇÃO PROCEDENTE</div>
                            <div className='text-end'>
                              <FontAwesomeIcon icon={faUpDown} />
                            </div>
                          </div>
                        </th>
                        <th className="text-center p-0 pb-2 m-0 px-2 bg-purple text-table text-wrap cursor-pointer" rowSpan={2} onClick={() => sortQuality('validComplaintPercentage')}>
                          <div className='d-flex  w-100 flex-column justify-content-between' style={{ height: '81px' }}>
                            <div>&nbsp;</div>
                            <div>%</div>
                            <div className='text-end'>
                              <FontAwesomeIcon icon={faUpDown} />
                            </div>
                          </div>
                        </th>
                        <th className="text-center p-0 pb-2 m-0 px-2 bg-purple text-wrap text-table text-wrap cursor-pointer" rowSpan={2} onClick={() => sortQuality('entryComplaint')}>
                          <div className='d-flex  w-100 flex-column justify-content-between' style={{ height: '81px' }}>
                            <div>&nbsp;</div>
                            <div>RECLAMAÇÃO ENTRADA</div>
                            <div className='text-end'>
                              <FontAwesomeIcon icon={faUpDown} />
                            </div>
                          </div>
                        </th>
                        <th className="text-center p-0 pb-2 m-0 px-2 bg-purple text-table text-wrap cursor-pointer" rowSpan={2} onClick={() => sortQuality('entryComplaintPercentage')}>
                          <div className='d-flex  w-100 flex-column justify-content-between' style={{ height: '81px' }}>
                            <div>&nbsp;</div>
                            <div>%</div>
                            <div className='text-end'>
                              <FontAwesomeIcon icon={faUpDown} />
                            </div>
                          </div>
                        </th>
                        <th className="text-center p-0 pb-2 m-0 px-2 bg-purple text-wrap text-table text-wrap cursor-pointer" rowSpan={2} onClick={() => sortQuality('unansweredComplaint')}>
                          <div className='d-flex  w-100 flex-column justify-content-between' style={{ height: '81px' }}>
                            <div>&nbsp;</div>
                            <div>RECLAMAÇÃO NÃO RESPONDIDA</div>
                            <div className='text-end'>
                              <FontAwesomeIcon icon={faUpDown} />
                            </div>
                          </div>
                        </th>
                        <th className="text-center p-0 pb-2 m-0 px-2 bg-purple text-table text-wrap cursor-pointer" rowSpan={2} onClick={() => sortQuality('unansweredComplaintPercentage')}>
                          <div className='d-flex  w-100 flex-column justify-content-between' style={{ height: '81px' }}>
                            <div>&nbsp;</div>
                            <div>%</div>
                            <div className='text-end'>
                              <FontAwesomeIcon icon={faUpDown} />
                            </div>
                          </div>
                        </th>

                        {showQuality &&
                          <>
                            <th className='bg-secondary text-center header-sm' colSpan={3}>{moment(qualityList?.header?.month0).format('MM/YYYY')}</th>
                            <th className='bg-secondary text-center header-sm' colSpan={3}>{moment(qualityList?.header?.month1).format('MM/YYYY')}</th>
                          </>
                        }
                        <th className='bg-secondary text-center header-sm' colSpan={3}>{moment(qualityList?.header?.month2).format('MM/YYYY')}</th>
                      </tr>

                      <tr>
                        {showQuality &&
                          <>
                            <th className="text-center p-0 pb-2 m-0 px-2 bg-secondary text-center header-sm cursor-pointer" onClick={() => sortQuality('complaintEntry0')}>
                              <div className='d-flex  w-100 flex-column justify-content-between'>
                                <div>&nbsp;</div>
                                <div>Entrada</div>
                                <div className='text-end'>
                                  <FontAwesomeIcon icon={faUpDown} />
                                </div>
                              </div>
                            </th>
                            <th className="text-center p-0 pb-2 m-0 px-2 bg-secondary text-center header-sm cursor-pointer" onClick={() => sortQuality('complaintUnansweredEntry0')}>
                              <div className='d-flex  w-100 flex-column justify-content-between'>
                                <div>&nbsp;</div>
                                <div>Não Resp</div>
                                <div className='text-end'>
                                  <FontAwesomeIcon icon={faUpDown} />
                                </div>
                              </div>
                            </th>
                            <th className="text-center p-0 pb-2 m-0 px-2 bg-secondary text-center header-sm cursor-pointer" onClick={() => sortQuality('complaintUnansweredPercent0')}>
                              <div className='d-flex  w-100 flex-column justify-content-between'>
                                <div>&nbsp;</div>
                                <div>% Não Resp</div>
                                <div className='text-end'>
                                  <FontAwesomeIcon icon={faUpDown} />
                                </div>
                              </div>
                            </th>
                            <th className="text-center p-0 pb-2 m-0 px-2 bg-secondary text-center header-sm cursor-pointer" onClick={() => sortQuality('complaintEntry1')}>
                              <div className='d-flex  w-100 flex-column justify-content-between'>
                                <div>&nbsp;</div>
                                <div>Entrada</div>
                                <div className='text-end'>
                                  <FontAwesomeIcon icon={faUpDown} />
                                </div>
                              </div>
                            </th>
                            <th className="text-center p-0 pb-2 m-0 px-2 bg-secondary text-center header-sm cursor-pointer" onClick={() => sortQuality('complaintUnansweredEntry1')}>
                              <div className='d-flex  w-100 flex-column justify-content-between'>
                                <div>&nbsp;</div>
                                <div>Não Resp</div>
                                <div className='text-end'>
                                  <FontAwesomeIcon icon={faUpDown} />
                                </div>
                              </div>
                            </th>
                            <th className="text-center p-0 pb-2 m-0 px-2 bg-secondary text-center header-sm cursor-pointer" onClick={() => sortQuality('complaintUnansweredPercent1')}>
                              <div className='d-flex  w-100 flex-column justify-content-between'>
                                <div>&nbsp;</div>
                                <div>% Não Resp</div>
                                <div className='text-end'>
                                  <FontAwesomeIcon icon={faUpDown} />
                                </div>
                              </div>
                            </th>
                          </>
                        }
                        <th className="text-center p-0 pb-2 m-0 px-2 bg-secondary text-center header-sm cursor-pointer" onClick={() => sortQuality('complaintEntry2')}>
                          <div className='d-flex  w-100 flex-column justify-content-between'>
                            <div>&nbsp;</div>
                            <div>Entrada</div>
                            <div className='text-end'>
                              <FontAwesomeIcon icon={faUpDown} />
                            </div>
                          </div>
                        </th>
                        <th className="text-center p-0 pb-2 m-0 px-2 bg-secondary text-center header-sm cursor-pointer" onClick={() => sortQuality('complaintUnansweredEntry2')}>
                          <div className='d-flex  w-100 flex-column justify-content-between'>
                            <div>&nbsp;</div>
                            <div>Não Resp</div>
                            <div className='text-end'>
                              <FontAwesomeIcon icon={faUpDown} />
                            </div>
                          </div>
                        </th>
                        <th className="text-center p-0 pb-2 m-0 px-2 bg-secondary text-center header-sm cursor-pointer" onClick={() => sortQuality('complaintUnansweredPercent2')}>
                          <div className='d-flex  w-100 flex-column justify-content-between'>
                            <div>&nbsp;</div>
                            <div>% Não Resp</div>
                            <div className='text-end'>
                              <FontAwesomeIcon icon={faUpDown} />
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {qualitysFilter?.claims?.map((item: IndicatorManagementQualityListModel) => (
                        <tr key={item?.partnerCode} className='text-sm'>
                          <td className='text-bolder'>{item.partnerCode}</td>
                          <td className='text-wrap' style={{ minWidth: '250px' }}>{item.partnerName}</td>
                          {showPromoter &&
                            <td>{item.promoterName}</td>
                          }
                          <td>{moment(item.createdAt).format('DD/MM/YYYY')}</td>
                          <td className='text-wrap'>{item.checkLevel}</td>
                          <td className='text-end'>{item.productionQuantity}</td>
                          <td className='text-end'>{item.validComplaint}</td>
                          <td className='text-end'>{item.validComplaintPercentage + '%'}</td>
                          <td className='text-end'>{item.entryComplaint}</td>
                          <td className='text-end'>{item.entryComplaintPercentage + '%'}</td>
                          <td className='text-end'>{item.unansweredComplaint}</td>
                          <td className='text-end'>{item.unansweredComplaintPercentage + '%'}</td>
                          {showQuality &&
                            <>
                              <td className='text-end'>{item.complaintEntry0}</td>
                              <td className='text-end'>{item.complaintUnansweredEntry0}</td>
                              <td className='text-end'>{item.complaintUnansweredPercent0 + '%'}</td>
                              <td className='text-end'>{item.complaintEntry1}</td>
                              <td className='text-end'>{item.complaintUnansweredEntry1}</td>
                              <td className='text-end'>{item.complaintUnansweredPercent1 + '%'}</td>
                            </>
                          }
                          <td className='text-end'>{item.complaintEntry2}</td>
                          <td className='text-end'>{item.complaintUnansweredEntry2}</td>
                          <td className='text-end'>{item.complaintUnansweredPercent2 + '%'}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th className='text-start'>Total</th>
                        <th colSpan={showPromoter ? 5 : 4}>{qualityList?.totalProductionQuantity}</th>
                        <th>{qualityList?.validComplaintQuantity}</th>
                        <th colSpan={2}>{qualityList?.entryComplaintQuantity}</th>
                        <th colSpan={2}>{qualityList?.totalComplaintUnanswered}</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                :
                <SkeletonTable />
              }
              {qualitysFilter?.claims.length > 0 && (
                <div>
                  <nav aria-label="...">
                    <AppPagination
                      pages={pages}
                      handlePaginate={handlePaginate}
                      params={params}
                      setParams={setParams}
                      style={{ backgroundColor: '#fff' }}
                    />
                  </nav>
                </div>
              )}
            </>
            :
            <div className='text-center font-weight-bold'>
              Não existem informações para listar!
            </div>
          }
        </Card.Body>
      </Card>
    </div>
  )
};

export default FormQualityVisits;
