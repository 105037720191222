import React, { FC, useEffect, useRef, useState } from "react";
import "./TabDefault.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row } from "react-bootstrap";
import { useBiDashboard } from "../../../../providers/BI/Dashboard/BiDashboardProvider";

interface TabDefaultProps { }

const TabDefault: FC<TabDefaultProps> = () => {
  const tabListRef = useRef<HTMLDivElement>(null);
  const [scrollEnabled, setScrollEnabled] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(-1);

  const { tabs, setTabs, setCurrentTab } = useBiDashboard();

  useEffect(() => {
    const activeScroll = () => {
      if (tabListRef?.current) {
        setScrollEnabled(
          tabListRef?.current?.scrollWidth > tabListRef?.current?.clientWidth
        );
      }
    };

    if (tabs?.length > 0) {
      setActiveTab(tabs?.length - 1);
    }

    activeScroll();
    window.addEventListener("resize", activeScroll);
    return () => window.removeEventListener("resize", activeScroll);
  }, [tabs]);

  useEffect(() => {
    if (scrollEnabled && tabListRef?.current) {
      tabListRef?.current.scrollTo({
        left: tabListRef?.current?.scrollWidth,
        behavior: "smooth",
      });
    }
  }, [scrollEnabled, tabs]);

  useEffect(() => {
    setCurrentTab(tabs[activeTab]);
  }, [activeTab]);

  const handleScroll = (direction: "left" | "right") => {
    if (tabListRef?.current) {
      const scroll = direction === "left" ? -200 : 200;
      tabListRef?.current?.scrollBy({ left: scroll, behavior: "smooth" });
    }
  };

  const handleRemoveTab = (index: number) => {
    const removeTab = tabs?.filter((x: any, i: any) => i !== index);
    const redirectTab = Math.min(activeTab, removeTab?.length - 1);

    setTabs(removeTab);
    setActiveTab(redirectTab);
  };


  return (
    <>
      <Card className="mb-3">
        <Card.Body className="TabDefault p-0" data-testid="TabDefault">
          {scrollEnabled && (
            <Col xs="auto">
              <button
                className="btn-scroll btn-scroll-left"
                onClick={() => handleScroll("left")}
              >
                <FontAwesomeIcon
                  className="me-2"
                  icon={faChevronLeft}
                  size="sm"
                />
              </button>
            </Col>
          )}

          <Col className="tabs-container" ref={tabListRef}>
            {tabs?.map((tab: any, index: any) => (
              <div
                key={index}
                className={`tab ${activeTab === index ? "active" : ""}`}
                onClick={() => setActiveTab(index)}
              >
                <span>{tab?.title}</span>
                <button
                  className="btn-close"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveTab(index);
                  }}
                >
                  <span>&times;</span>
                </button>
              </div>
            ))}
          </Col>

          {scrollEnabled && (
            <Col xs="auto">
              <button
                className="btn-scroll btn-scroll-right"
                onClick={() => handleScroll("right")}
              >
                <FontAwesomeIcon
                  className="me-2"
                  icon={faChevronRight}
                  size="sm"
                />
              </button>
            </Col>
          )}
        </Card.Body>
        <Card.Body className="tab-content">
          <Row>
            {tabs?.length === 0 ? (
              <Col md={12}>
                <div className="welcome-container">
                  <FontAwesomeIcon
                    icon={faChartLine}
                    className="welcome-icon"
                  />
                  <h2 className="welcome-title">
                    Bem-vindo ao Dashboard BI
                  </h2>
                  <p className="welcome-text">
                    Para começar, selecione um relatório no menu para visualizar os dados.
                  </p>
                </div>
              </Col>
            ) : (
              <Col md={12}>{tabs[activeTab]?.content}</Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default TabDefault;
