import FileSaver from "file-saver";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/report/request';

export class ReportRequestService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async fetch(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.put(`${URL}/save`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async download(id: number): Promise<any> {
        try {
            const response = await api.post(`${URL}/download`, { id }, { responseType: 'blob' });
            const filename = response.headers['content-disposition'].split('filename=')[1];
            FileSaver.saveAs(response.data, filename, { autoBom: false });
            return [true, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}