import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { VisitsReportsService } from "../../../services/Visits/VisitsReports/VisitsReportsService";
import { toast } from "react-toastify";
import { VisitsReportsModel } from "../../../models/Visits/VisitsReports/VisitsReportsModel";
import { VisitsReportsParams } from "../../../models/Visits/VisitsReports/VisitsReportsParams";
import { VisitsReportsMonthsModel } from "../../../models/Visits/VisitsReports/VisitsReportMonthsModel";
import moment, { months } from "moment";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";

interface ContextProps {
    reports: VisitsReportsModel[],
    setReports: Dispatch<SetStateAction<VisitsReportsModel[]>>,
    report: VisitsReportsModel | null,
    setReport: Dispatch<SetStateAction<VisitsReportsModel | null>>,
    reportMonths: VisitsReportsMonthsModel[],
    setReportMonths: Dispatch<SetStateAction<VisitsReportsMonthsModel[]>>,
    reportAgents: any[],
    setReportAgents: Dispatch<SetStateAction<any[]>>,
    isLoading: boolean,
    isLoadingChart: boolean,
    isLoadingRanking: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    params?: VisitsReportsParams,
    setParams: Dispatch<SetStateAction<VisitsReportsParams | undefined>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    paramsAgents?: VisitsReportsParams,
    setParamsAgents: Dispatch<SetStateAction<VisitsReportsParams | undefined>>,
    handleListByMonths: (userVisitorId?: number | null) => void,
    handleListByAgents: (params: any) => void,
}

export const VisitsReportsContext = createContext<ContextProps>({} as ContextProps);

interface VisitsReportsProviderProps {
    children: ReactNode;
};

export const VisitsReportsProvider = ({ children }: VisitsReportsProviderProps) => {
    const [report, setReport] = useState<VisitsReportsModel | null>(null);
    const [reports, setReports] = useState<VisitsReportsModel[]>([]);
    const [reportMonths, setReportMonths] = useState<VisitsReportsMonthsModel[]>([]);
    const [reportAgents, setReportAgents] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingChart, setIsLoadingChart] = useState<boolean>(false);
    const [isLoadingRanking, setIsLoadingRanking] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [params, setParams] = useState<VisitsReportsParams>();
    const [paramsAgents, setParamsAgents] = useState<VisitsReportsParams>();

    const service = new VisitsReportsService();

    const handleListByMonths = async (userVisitorId: number | null = null) => {
        try {
            setIsLoadingChart(true);

            const [_response, _error] = await service.listByMonths({ months: 4, userVisitorId });

            setIsLoadingChart(false);

            if (!!_error) {
                return setError(_error?.message || _error);
            }

            setReportMonths(_response?.data || []);

            return setError('');
        }
        catch (e: any) {
            setError('Houve um erro ao listar o gráfico');
            return false;
        }
    }

    const handleListByAgents = async (params: any) => {
        try {
            setIsLoadingRanking(true);

            const [_response, _error] = await service.listByAgents(params);

            if (!!_error) {
                setIsLoadingRanking(false);
                return setError(_error?.message || _error);
            }

            setReportAgents(_response?.data || []);
            setIsLoadingRanking(false);

            return setError('');
        }
        catch (e: any) {
            setError('Houve um erro ao listar o ranking de visitas');
            return false;
        }
    }

    useEffect(() => { !!error && toast.error(error) }, [error]);
    // useEffect(() => { !!paramsAgents && handleListByAgents(paramsAgents); }, [paramsAgents]);

    return (
        <VisitsReportsContext.Provider value={{
            reports,
            setReports,
            report,
            setReport,
            reportMonths,
            setReportMonths,
            reportAgents,
            setReportAgents,
            isLoading,
            isLoadingChart,
            isLoadingRanking,
            error,
            setError,
            params,
            setParams,
            pages,
            setPages,
            paramsAgents,
            setParamsAgents,
            handleListByAgents,
            handleListByMonths
        }}>
            {children}
        </VisitsReportsContext.Provider>
    );
}

export const useVisitsReports = () => useContext(VisitsReportsContext);