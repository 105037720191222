import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserActiveTypeService } from "../../../services/User/UserActiveTypeService";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";
import { UserActiveTypeModel } from "../../../models/User/UserActiveTypeModel";

interface ContextProps {
    activeTypes: UserActiveTypeModel[],
    activeTypesOptions: ReactSelectOptions[],
    handleList: () => void,
}

export const ActiveTypeContext = createContext<ContextProps>({} as ContextProps);

interface ActiveTypeProviderProps {
    children: ReactNode;
}

export const ActiveTypeProvider = ({ children }: ActiveTypeProviderProps) => {
    const [activeTypes, setLevelchecks] = useState<UserActiveTypeModel[]>([]);
    const [error, setError] = useState<string>('');
    const [activeTypesOptions, setLevelchecksOptions] = useState<ReactSelectOptions[]>([]);

    const service = new UserActiveTypeService();

    const handleList = useCallback(async () => {
        try {
            const [_Response, _Error] = await service.list({ withPaginate: false });

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setLevelchecks(_Response.data);
            return setError('');
        } catch (e) {
            return setError('Não foi possível carregar os tipos de agentes');
        }
    }, []);

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        setLevelchecksOptions(
            activeTypes
                ?.map((item: UserActiveTypeModel) => {
                    return { label: item.name, value: item.id };
                })
        )
    }, [activeTypes]);

    return (
        <ActiveTypeContext.Provider value={{
            activeTypes,
            activeTypesOptions,
            handleList,
        }}>
            {children}
        </ActiveTypeContext.Provider>
    );
}

export const useActiveType = () => useContext(ActiveTypeContext);