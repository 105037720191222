import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './FormCondition.scss';
import { Button, Col, FormGroup, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import ProposalRuleChooseCondition from '../../ProposalRuleChooseCondition/ProposalRuleChooseCondition';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { customStyles } from '../../../../../models/SelectCustomStyles';
import { ReactSelectOptions } from '../../../../../models/ReactSelectOptions';
import { useProposalRuleCondition } from '../../../../../providers/Proposals/Rule/ProposalRuleConditionProvider';
import makeAnimated from 'react-select/animated';
import { useAuth } from '../../../../../providers/AuthProvider';
import { useProposalConfig } from '../../../../../providers/Proposals/Config/ProposalConfigProvider';
import { schema } from './Validate';
import { ProposalRuleConditionEnum } from '../../../../../enums/Proposals/Rule/Condition/ProposalRuleConditionEnum';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import { conditionType } from '../../../../../enums/Proposals/Rule/Condition/ConditionType';
import { useProposalRule } from '../../../../../providers/Proposals/Rule/ProposalRuleProvider';
import { ProposalRuleConditionItemsModel } from '../../../../../models/Proposals/Rule/ProposalRuleModel';
import moment from 'moment';
import { TablesColumnsNamesProposalsDataTypesEnum } from '../../../../../enums/Proposals/Config/TablesColumnsNamesProposalsDataTypesEnum';
import { FormataStringCurrencyToNumber } from '../../../../../utils/FormataStringCurrencyToNumber';
import FormatNumber from '../../../../../utils/FormatNumber';
import FormatMoney from '../../../../../utils/FormatMoney';

interface IForm {
  headerFieldId: any;
  conditionId: any;
  value1?: string;
  value2?: string;
}

interface FormConditionProps {
  financialId: number;
  type: string;
  conditionsQuery: IForm[],
  setConditionsQuery: Dispatch<SetStateAction<IForm[]>>,
  selectedType: any;
  setSelectedType: Dispatch<SetStateAction<any>>
}

const FormCondition: FC<FormConditionProps> = ({
  financialId,
  type,
  conditionsQuery,
  setConditionsQuery,
  selectedType,
  setSelectedType
}) => {
  const [selectedHeaderField, setSelectedHeaderField] = useState<any | null>(null);
  const [selectedCondition, setSelectedCondition] = useState<any | null>(null);

  const { onInvalid } = useAuth();
  const { rule } = useProposalRule();
  const { conditionsOptions } = useProposalRuleCondition();
  const { fieldsTablesColumnsNamesOptions } = useProposalConfig();

  const animatedComponents = makeAnimated();

  const {
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<IForm>({
    mode: "onChange",
    resolver: yupResolver(schema)
  });

  const onSubmit = (data: any) => {
    setConditionsQuery((current: any[]) => [...current, ...[{ ...data }]]);

    setSelectedHeaderField(null);
    setSelectedCondition(null);
    reset();
  };

  const handleRemove = (index: number) => {
    const array = [...conditionsQuery];

    if (index !== -1) {
      array.splice(index, 1);
      setConditionsQuery(array);
    }
  }

  const renderQueryOperator = (condition: any): JSX.Element => {
    return (
      <>
        {[
          ProposalRuleConditionEnum.BETWEEN,
          ProposalRuleConditionEnum.CONTAINED_IN,
          ProposalRuleConditionEnum.CONTAINS,
          ProposalRuleConditionEnum.EMPTY,
          ProposalRuleConditionEnum.NOT_EMPTY,
        ].includes(condition?.value) ? (
          <>
            {condition?.value === ProposalRuleConditionEnum.BETWEEN && (<>ENTRE</>)}
            {condition?.value === ProposalRuleConditionEnum.CONTAINED_IN && (<>CONTIDO EM</>)}
            {condition?.value === ProposalRuleConditionEnum.CONTAINS && (<>CONTÉM</>)}
            {condition?.value === ProposalRuleConditionEnum.EMPTY && (<>É VAZIO</>)}
            {condition?.value === ProposalRuleConditionEnum.NOT_EMPTY && (<>NÃO É VAZIO</>)}
          </>
        ) : (
          <>{condition?.operator}</>
        )}
      </>
    );
  }

  const renderQuery = (items: any[]): JSX.Element => {
    return (
      <>
        {items?.length > 0 && (
          <div className="badge badge-info w-100 my-3 p-3 text-start d-flex align-items-center">
            {items?.map((item: any, key: number) => (
              <div className="item" key={key}>
                <div className="bg-item position-relative">
                  <span>
                    {item?.headerFieldId?.label}
                  </span>
                  <span>
                    &nbsp;{renderQueryOperator(item?.conditionId)}
                  </span>
                  {!!item?.value1 && (
                    <span>
                      &nbsp;{item?.value1}
                    </span>
                  )}
                  {item?.conditionId?.value === ProposalRuleConditionEnum.BETWEEN && (
                    <span>
                      &nbsp;E {item?.value2}
                    </span>
                  )}

                  <button className="btn btn-icon btn-remove" onClick={() => handleRemove(key)}></button>
                </div>

                {key < (items?.length - 1) && (
                  <span>{type === 'AND' ? 'E' : 'OU'}</span>
                )}
              </div>
            ))}
          </div>
        )}
      </>
    );
  }

  useEffect(() => {
    setValue('headerFieldId', selectedHeaderField ?? null);
  }, [selectedHeaderField]);

  useEffect(() => {
    setValue('conditionId', selectedCondition ?? null);
  }, [selectedCondition]);

  useEffect(() => {
    (financialId > 0) && setSelectedHeaderField(null);
  }, [financialId]);

  useEffect(() => {
    setConditionsQuery([]);
  }, []);

  useEffect(() => {
    setConditionsQuery([]);

    if (rule?.conditions && fieldsTablesColumnsNamesOptions?.length > 0) {
      let _type = rule?.conditions[0]?.type;

      setSelectedType(conditionType?.find((item: any) => item?.value === _type));

      setConditionsQuery(
        [...rule?.conditions]?.map((item: ProposalRuleConditionItemsModel) => {
          let _value1 = item?.value1;
          let _value2 = item?.value2;

          if (item?.headersFields?.headersFieldsTablesColumnsNamesProposals[0]?.tablesColumnsNamesProposals?.dataType === TablesColumnsNamesProposalsDataTypesEnum.DATE) {
            _value1 = item?.value1 ? moment(item?.value1, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
            _value2 = item?.value2 ? moment(item?.value2, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
          }

          if (item?.headersFields?.headersFieldsTablesColumnsNamesProposals[0]?.tablesColumnsNamesProposals?.dataType === TablesColumnsNamesProposalsDataTypesEnum.DOUBLE) {
            _value1 = item?.value1 ? (FormatNumber(Number(item?.value1))).toString() : '';
            _value2 = item?.value2 ? (FormatMoney(Number(item?.value2))).toString() : '';
          }

          return {
            headerFieldId: fieldsTablesColumnsNamesOptions?.find((x: ReactSelectOptions) => x?.value === item?.headersFieldsId),
            conditionId: conditionsOptions?.find((x: ReactSelectOptions) => x?.value === item?.rulesConditionsId),
            value1: _value1,
            value2: _value2,
          };
        })
      );
    }
  }, [rule, fieldsTablesColumnsNamesOptions]);

  return (
    <div className="FormCondition" data-testid="FormCondition">
      <Row>
        <Col sm={12} md={12} lg={12} xl={4}>
          <FormGroup className="form-group">
            <label htmlFor="headerFieldId">Tipo da Condição *</label>
            <ReactSelect
              name="typeId"
              isClearable
              isSearchable
              options={conditionType}
              placeholder="Selecione..."
              className={`form-control p-0`}
              value={selectedType ?? ''}
              defaultValue={selectedType}
              components={animatedComponents}
              noOptionsMessage={() => 'Não há registros'}
              onChange={(val: any) => {
                setSelectedType(val);
              }}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
        <Col sm={12} md={12} lg={12} xl={4}>
          <FormGroup className="form-group">
            <label htmlFor="headerFieldId">Campo da Planilha *</label>
            <Controller
              name="headerFieldId"
              control={control}
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  isSearchable
                  options={fieldsTablesColumnsNamesOptions}
                  placeholder="Selecione..."
                  className={`form-control p-0 ${onInvalid(errors?.headerFieldId)}`}
                  value={selectedHeaderField ?? ''}
                  defaultValue={selectedHeaderField}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  onChange={(val: any) => {
                    onChange(val ?? null);
                    setSelectedHeaderField(val);
                    setSelectedCondition(null);
                  }}
                  isDisabled={!selectedType}
                  styles={customStyles}
                />
              )}
            />
            <ErrorMessage name={'Campo da Planilha'} type={errors?.headerFieldId?.type?.toString()} />
          </FormGroup>
        </Col>
        <Col sm={12} md={12} lg={12} xl={4}>
          <FormGroup className="form-group">
            <label htmlFor="conditionId">Condição</label>
            <Controller
              name="conditionId"
              control={control}
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  isSearchable
                  options={conditionsOptions}
                  placeholder="Selecione..."
                  className={`form-control p-0 ${onInvalid(errors?.conditionId)}`}
                  value={selectedCondition ?? ''}
                  defaultValue={selectedCondition}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  onChange={(val: any) => {
                    onChange(val ?? null);
                    setSelectedCondition(val);
                  }}
                  isDisabled={!selectedType}
                  styles={customStyles}
                />
              )}
            />
            <ErrorMessage name={'Condição'} type={errors?.conditionId?.type?.toString()} />
          </FormGroup>
        </Col>
      </Row>

      {(selectedCondition?.value && selectedCondition?.value > 0) && (
        <Row>
          <Col md={12}>
            <FormGroup className="form-group">
              <ProposalRuleChooseCondition
                conditionId={selectedCondition?.value ?? 0}
                dataType={selectedHeaderField?.dataType ?? 0}
                register={register}
                errors={errors}
                setValue={setValue}
                control={control}
              />
            </FormGroup>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <div className="d-flex align-items-center">
            {renderQuery([...conditionsQuery])}
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12} className="text-end position-relative">
          <Button type="button" className={`btn-sm m-0`} onClick={handleSubmit(onSubmit)}>Adicionar</Button>
        </Col>
      </Row>
    </div>
  );
}

export default FormCondition;
