import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { ProposalRuleService } from "../../../services/Proposals/Rule/ProposalRuleService";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { ToastSettings } from "../../../utils/ToastSettings";
import { ProposalRuleFetchParams } from "../../../models/Proposals/Rule/ProposalRuleFetchParams";
import { ProposalRuleListParams } from "../../../models/Proposals/Rule/ProposalRuleListParams";
import { ProposalRuleModel } from "../../../models/Proposals/Rule/ProposalRuleModel";

interface ContextProps {
    rule: ProposalRuleModel | null,
    setRule: Dispatch<SetStateAction<ProposalRuleModel | null>>,
    rules: ProposalRuleModel[],
    params: ProposalRuleListParams,
    setParams: Dispatch<SetStateAction<ProposalRuleListParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => Promise<boolean>,
    handleFetch: (data: ProposalRuleFetchParams) => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>,
}

export const ProposalRuleContext = createContext<ContextProps>({} as ContextProps);

interface ProposalRuleProviderProps {
    children: ReactNode;
}

export const ProposalRuleProvider = ({ children }: ProposalRuleProviderProps) => {
    const [rule, setRule] = useState<ProposalRuleModel | null>(null);
    const [rules, setRules] = useState<ProposalRuleModel[]>([]);
    const [params, setParams] = useState<ProposalRuleListParams>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new ProposalRuleService();
    const location = useLocation();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/proposal/rule/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setRules(_Response.data);
            setPages(_Response?.pages);
            setError('');
            return true;
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar as regras');
            return false;
        }
    }, [params, location.pathname]);

    const handleFetch = async (data: ProposalRuleFetchParams) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.fetch(data);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');

            ToastSettings('Regra inserida com sucesso!', 'bottom-center', 'success');
            handleList();

            return true;
        } catch (e) {
            setError('Houve um erro ao salvar a regra');
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar a regra');
            return false;
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    useEffect(() => {
        setError('');
    }, [])

    return (
        <ProposalRuleContext.Provider value={{
            rule,
            setRule,
            rules,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleFetch,
            handleDelete
        }}>
            {children}
        </ProposalRuleContext.Provider>
    );
}

export const useProposalRule = () => useContext(ProposalRuleContext);