import { ModuleModel } from "../../../models/Registrations/ModulesTool/ModuleModel";
import { ToolModel } from "../../../models/Registrations/ModulesTool/ToolModel";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/config/modules'

export class ModulesToolService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getModule(id: number): Promise<any> {
        try {
            return this.handleResponse(await api.get(`${URL}/${id}`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async fetchModule(payload: ModuleModel): Promise<any> {
        try {
            return this.handleResponse(await api.put(`${URL}`, payload));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async deleteModule(id: number): Promise<any> {
        try {
            return this.handleResponse(await api.delete(`${URL}`, { data: { id } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async fetchTool(payload: ToolModel): Promise<any> {
        try {
            return this.handleResponse(await api.put(`${URL}/tools`, payload));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async deleteTool(id: number): Promise<any> {
        try {
            return this.handleResponse(await api.delete(`${URL}/tools`, { data: { id } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}