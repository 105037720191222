import { FC, useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'react-bootstrap';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import AppPagination from '../../../AppPagination/AppPagination';
import { usePaginate } from '../../../../providers/PaginateProvider';
import './CommercialMetric.scss';
import { useMetricManagementProvider } from '../../../../providers/pgm/MetricManagement/MetricManagementProvider';
import FormatMoney from '../../../../utils/FormatMoney';

interface CommercialMetricProps { };

const CommercialMetric: FC<CommercialMetricProps> = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { handlePaginate }        = usePaginate();
    const { metric }                = useMetricManagementProvider();

    const tableList = () => {
        return (
            <>
                {
                    metric?.metricaComercial && metric?.metricaComercial?.length > 0 ? (
                        <>
                            {
                                metric.metricaComercial.map((item:any, index:number) => (
                                    <tr className={`tr-${index}`}>
                                        <td> {item?.nomeComercial} </td>
                                        <td className="text-center"> {item?.qtParceiroAgrupado} </td>
                                        <td className="text-center"> {item?.qtAtivoProducente25} </td>
                                        <td className="text-center"> {item?.efetividade} </td>
                                        <td className="text-center"> {item?.digitacaoSemDigitacao} </td>
                                        <td className="text-center"> {item?.digitacaoComCresc50} </td>
                                        <td className="text-center"> {item?.digitacaoQueda20} </td>
                                        <td className="text-center"> {item?.metricaCresc20} </td>
                                        <td className="text-center"> {item?.metricaCrescAcimaBevi} </td>
                                        <td className="text-center"> {item?.metrica3milhoes} </td>
                                        <td className="text-center"> {FormatMoney(item?.producaoProjecao)} </td>
                                        <td className="text-center"> {item?.crescimentoQueda}% </td>
                                        <td className="text-center"> {item?.crescimentoQuedaBevi}% </td>
                                        <td className="text-center"> {item?.rentaBruta} </td>
                                        <td className="text-center"> {FormatMoney(item?.rentabilidadeBrutaProjetada)} </td>
                                        <td className="text-center"> {FormatMoney(item?.producaoRegraVariavel)} </td>
                                        <td className="text-center"> {FormatMoney(item?.rentabilidadeBruta)} </td>
                                        <td className="text-center"> {item?.qtdadeVisita}% </td>
                                        <td className="text-center"> {item?.qtdadeVisitaPresencial} </td>
                                        <td className="text-center"> {item?.indiceComercial}% </td>
                                        <td className="text-center"> {item?.indiceBevi}% </td>
                                        <td className="text-center"> {item?.nomeRegional} </td>
                                        <td className="text-center"> {item?.nomeExecutivo} </td>
                                        <td className="text-center"> {item?.nomeDiretor} </td>
                                    </tr>
                                ))
                            }
                        </>
                    ) : (
                        <tr>
                          <td colSpan={24} className="text-center text-sm">Não há registros</td>
                        </tr>
                    )
                }
            </>
        );
    }

    useEffect(() => {
        setIsLoading(false);
    });

    return(
        <>
            { !isLoading ? (
                <div className="CommercialMetric" data-testid="CommercialMetric">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={12} xs={12}>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-flush m-0 w-100">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col" className="text-left">COMERCIAL</th>
                                                    <th scope="col" className="text-left">QTDE CÓDIGOS AGRUPADOS</th>
                                                    <th scope="col" className="text-left">QTDE ATIVO PRODUCENTE (MAIOR $25k)</th>
                                                    <th scope="col" className="text-left">EFETIVIDADE NOVOS CADASTROS   BEVI (MAIOR/IGUAL 50%)</th>
                                                    <th scope="col" className="text-left">MÉTRICA QTDE PARC BEVI SEM DGT <br/> (31 - 90 DD) </th>
                                                    <th scope="col" className="text-left">MÉTRICA QTDE PARC COM CRESC   +50% </th>
                                                    <th scope="col" className="text-left">MÉTRICA QTDE PARC QUEDA  MAIOR 20%</th>
                                                    <th scope="col" className="text-left">MÉTRICA   CRESC/QUED 20% </th>
                                                    <th scope="col" className="text-left">MÉTRICA DE % MELHOR QUE DA BEVI</th>
                                                    <th scope="col" className="text-left">MÉTRICA MAIOR 3MM</th>
                                                    <th scope="col" className="text-left">PROJEÇÃO MÊS ATUAL</th>
                                                    <th scope="col" className="text-left">PROJEÇÃO C/Q</th>
                                                    <th scope="col" className="text-left">BEVI</th>
                                                    <th scope="col" className="text-left">RENTABILIDADE   PROJETADA MAIOR 40K</th>
                                                    <th scope="col" className="text-left">RENTABILIDADE   FECHAMENTO PROJETADA </th>
                                                    <th scope="col" className="text-left">PRODUÇÃO   REGRA VARIÁVEL (R$)</th>
                                                    <th scope="col" className="text-left">RENTABILIDADE   FECHAMENTO  (R$)</th>
                                                    <th scope="col" className="text-left">QTDE DE   VISITAS TOTAIS  (MAIOR 40)</th>
                                                    <th scope="col" className="text-left">QTDE DE   VISITAS PRESENCIAS  (MAIOR 32)</th>
                                                    <th scope="col" className="text-left">ÍNDICE DO   COMERCIAL</th>
                                                    <th scope="col" className="text-left">ÍNDICE   BEVI</th>
                                                    <th scope="col" className="text-left">REGIONAL</th>
                                                    <th scope="col" className="text-left">EXECUTIVO</th>
                                                    <th scope="col" className="text-left">DIRETOR</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tableList()
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>

                            {/* {true && (
                                <div>
                                    <nav aria-label="...">
                                        <AppPagination
                                            pages={pages}
                                            handlePaginate={handlePaginate}
                                            params={params}
                                            setParams={setParams}
                                            style={{ backgroundColor: '#fff' }}
                                        />
                                    </nav>
                                </div>
                            )} */}
                        </CardBody>
                    </Card>
                </div>     
              ) : (<SkeletonTable />)
            }
        </>
    );
}

export default CommercialMetric;