import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './AppQuantityPerPage.scss';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { customStyles } from '../../models/SelectCustomStyles';
import { ReactSelectOptions } from '../../models/ReactSelectOptions';

interface AppQuantityPerPageProps extends React.HTMLAttributes<HTMLInputElement> {
  params: any,
  setParams: Dispatch<SetStateAction<any>>,
  pages: any,
  handleQuantityPerPage?: any,
  position?: 'start' | 'end',
  labelText?: string,
  options?: ReactSelectOptions[]
}

const quantityOptions: ReactSelectOptions[] = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 15, label: '15' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
]

const AppQuantityPerPage: FC<AppQuantityPerPageProps> = ({
  params,
  setParams,
  pages,
  handleQuantityPerPage,
  position = 'start',
  labelText = 'Exibir',
  options = quantityOptions,
  ...rest
}) => {
  const [selectedItem, setSelectedItem] = useState<ReactSelectOptions>();
  const [optionsQuantity, setOptionsQuantity] = useState<ReactSelectOptions[]>(options);

  const animatedComponents = makeAnimated();

  const handleOnChange = (value: any) => {
    setSelectedItem(value);
    if (!!value) {
      setParams({ ...params, page: 1, perPage: value.value });
      !!handleQuantityPerPage && handleQuantityPerPage();
    }
  }

  useEffect(() => {
    !!pages && setSelectedItem({ value: pages?.perPage, label: pages?.perPage });
  }, [pages]);

  useEffect(() => {
    if (options?.length > 0 && !!pages?.perPage) {
      const _option = options?.find((x: ReactSelectOptions) => x.value === pages?.perPage);
      if (!_option) {
        options.push({ value: pages?.perPage, label: pages?.perPage });
      }
      options.sort((a: any, b: any) => a?.value < b?.value ? -1 : 1);
      setOptionsQuantity(options);
    }
  }, [options, pages]);

  return (
    <div className={`AppQuantityPerPage d-flex align-items-center gap-2`} data-testid="AppQuantityPerPage" {...rest}>
      {labelText}:
      <ReactSelect
        options={optionsQuantity}
        placeholder=" "
        className={`form-control p-0`}
        components={animatedComponents}
        value={selectedItem}
        onChange={handleOnChange}
        noOptionsMessage={() => 'Não há registros'}
        styles={customStyles}
      />
    </div>
  )
};

export default AppQuantityPerPage;

