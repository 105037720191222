import React, { FC, useEffect, useState } from 'react';
import './ChartParticipation.scss';
import { Card, Col, Row } from 'react-bootstrap';
import PieChart from '../../PieChart/PieChart';
import { nameAbbreviation } from '../../../../../utils/NameAbbreviation';
import { TabEnumGeneralProduction } from '../GeneralProductionByUf/GeneralProductionByUf';
import { useBiGeneralProduction } from '../../../../../providers/BI/Dashboard/BiGeneralProductionProvider';
import { useBiDashboard } from '../../../../../providers/BI/Dashboard/BiDashboardProvider';
import { BI_VIEWS_ENUM } from '../../../../../models/BI/Dashboard/BiProductionParamsModel';
import SkeletonCircle from '../../../../SkeletonCircle/SkeletonCircle';

interface ChartParticipationProps {
  currentActiveTab?: TabEnumGeneralProduction;
}

const ChartParticipation: FC<ChartParticipationProps> = ({ currentActiveTab }) => {
  const [monthData, setMonthData] = useState<any[]>([]);
  const [annualData, setAnnualData] = useState<any[]>([]);
  const [isMonthLoading, setIsMonthLoading] = useState<boolean>(false);
  const [isAnnualLoading, setIsAnnualLoading] = useState<boolean>(false);

  const {
    productionByUfs,
    setProductionByUfs,
    annualProductionByUfs,
    setAnnualProductionByUfs
  } = useBiGeneralProduction();

  const { handleListMonthlyProduction, handleListAnnualProduction, isLoading, params } = useBiDashboard();

  useEffect(() => {
    setIsMonthLoading(true);
    setIsAnnualLoading(true);
    handleListMonthlyProduction({
      ...params,
      view: BI_VIEWS_ENUM.STATE,
      group: {}
    }).then((x: any) => {
      if (x) {
        setProductionByUfs(x);
      }
    }).finally(() => setIsMonthLoading(false));

    handleListAnnualProduction({
      ...params,
      view: BI_VIEWS_ENUM.STATE,
      group: {}
    }).then((x: any) => {
      if (x) {
        setAnnualProductionByUfs(x);
      }
    }).finally(() => setIsAnnualLoading(false));
  }, [params]);

  useEffect(() => {
    if (productionByUfs?.items &&
      productionByUfs?.items?.length > 0 &&
      productionByUfs?.items[0]?.periods &&
      productionByUfs?.items[0]?.periods?.length > 0
    ) {
      let _data = productionByUfs?.items?.map((item) => item?.periods ? { value: Number(item?.periods[item?.periods?.length - 1].totalProductionPercentage), label: nameAbbreviation(item?.state ?? '', 13) } : {}) ?? [];
      _data = _data?.sort((a: any, b: any) => a?.value > b?.value ? -1 : 1)?.filter((x: any, index: number) => index <= 9);
      setMonthData(_data);
    }
  }, [productionByUfs]);

  useEffect(() => {
    if (annualProductionByUfs?.items &&
      annualProductionByUfs?.items?.length > 0 &&
      annualProductionByUfs?.items[0]?.periods &&
      annualProductionByUfs?.items[0]?.periods?.length > 0
    ) {
      let _data = annualProductionByUfs?.items?.map((item) => item?.periods ? { value: Number(item?.periods[item?.periods?.length - 1].totalProductionPercentage), label: nameAbbreviation(item?.state ?? '', 13) } : {}) ?? [];
      _data = _data?.sort((a: any, b: any) => a?.value > b?.value ? -1 : 1)?.filter((x: any, index: number) => index <= 9);
      setAnnualData(_data);
    }
  }, [annualProductionByUfs]);

  return (
    <div className="ChartParticipation" data-testid="ChartParticipation">
      <Row>
        <Col xl="6" className="mb-4">
          <Card className="h-100 shandow-xs-none">
            <Card.Header className="pb-0">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h6 className="surtitle text-gray m-0 text-start">PARTICIPAÇÃO MENSAL</h6>
                </div>
                <div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              {!isMonthLoading ?
                <PieChart
                  labels={monthData?.map((item) => item?.label)}
                  data={monthData?.map((item) => item?.value?.toFixed(2))}
                  // max={Math.trunc(Math.max.apply(Math, mock?.producoes?.map(o => o?.crescimento)))}
                  height={450}
                /> :
                <SkeletonCircle width={300} />}
            </Card.Body>
          </Card>
        </Col>

        <Col xl="6" className="mb-4">
          <Card className="h-100 shandow-xs-none">
            <Card.Header className="pb-0">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h6 className="surtitle text-gray m-0 text-start">PARTICIPAÇÃO ANUAL</h6>
                </div>
                <div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              {!isAnnualLoading ?
                <PieChart
                  labels={annualData?.map((item) => item?.label)}
                  data={annualData?.map((item) => item?.value?.toFixed(2))}
                  // max={Math.trunc(Math.max.apply(Math, mock?.producoes?.map(o => o?.crescimento)))}
                  height={450}
                /> :
                <SkeletonCircle width={300} />}
            </Card.Body>
          </Card>
        </Col>


      </Row>
    </div>
  );
};

export default ChartParticipation;
