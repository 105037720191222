import { FC } from 'react';
import { Col, CardLink, Row, Image } from 'react-bootstrap';

interface StoreLinksProps {}

const StoreLinks: FC<StoreLinksProps> = () => {
    return (
        <Row className="StoreLinks" data-testid="StoreLinks">
            <Col md={12} xs={12} className="align-items-end text-md-start text-center">
                <Image src={require('./../../assets/img/logo-bevi.svg').default} alt="lock" fluid className="mb-0" />
            </Col>
            <Col md={12} xs={12} className="d-md-block d-none">
                <p className="my-3"> © Bevi - Todos os direitos reservados. </p>
                <hr />
            </Col>
            <Col md={12} xs={12} className="d-flex flex-flow-row justify-content-md-start align-items-md-start justify-content-center align-items-center mt-md-1 mt-4 mb-md-0 mb-4">
                <CardLink href="https://play.google.com/store/apps/details?id=br.com.bevicred.bevicredmobiletoken" className="app-button">
                    <img src={require('./../../assets/img/gplay-violet.svg').default} alt="Play Store" />
                </CardLink>
                <CardLink href="https://apps.apple.com/us/app/bevi/id1495034890" className="app-button mx-2">
                    <img src={require('./../../assets/img/astore-violet.svg').default} alt="Apple Store" />
                </CardLink>
            </Col>
      </Row> 
    );
};

export default StoreLinks;