import { CovenantTableConfigParamsModel } from "../../../models/Tools/Configurations/CovenantTable/CovenantTableConfigParamsModel";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/services/proposals/proposal/tableImport';

export class CovenantTableConfigService extends BaseService {
    async list(params: CovenantTableConfigParamsModel): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async save(payload: any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/save`, payload));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async delete(id: number): Promise<any> {
        try {
            return this.handleResponse(await api.delete(`${URL}/delete`, { data: { id } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}