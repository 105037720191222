import { FC, useEffect, useState } from 'react';
import './PreVisitsAnalysis.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import SidebarFilter from '../../../components/SidebarFilter/SidebarFilter';
import { usePaginate } from '../../../providers/PaginateProvider';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCircleCheck, faEye, faPrint } from '@fortawesome/free-solid-svg-icons';
import SidebarPreVisitsAnalysisFilter from '../../../components/Visits/PreVisitsAnalysis/SidebarPreVisitsAnalysisFilter/SidebarPreVisitsAnalysisFilter';
import { useIndicatorManagementPreVisitVisits } from '../../../providers/Visits/IndicatorManagement/IndicatorManagementPreVisitProvider';
import moment from 'moment';
import { PreVisitModel } from '../../../models/Visits/IndicatorManagement/PreVisitModel';
import ModalPreVisits from '../../../components/Visits/IndicatorManagement/ModalPreVisits/ModalPreVisits';

interface PreVisitsAnalysisProps { }

const PreVisitsAnalysis: FC<PreVisitsAnalysisProps> = () => {

  const { params, setParams, pages, preVisits, error, isLoading, handleList, handlePrintPreVisits } = useIndicatorManagementPreVisitVisits();
  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { handlePaginate } = usePaginate();

  const [showFormPreVisit, setShowFormPreVisit] = useState(false);
  const [preVisitEdit, setPreVisitEdit] = useState<PreVisitModel>();
  const [partner, setPartner] = useState<any>();

  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      params.startDate = params?.startDate ? moment(params?.startDate).format('YYYY-MM-DD') : '';
      params.endDate = params?.endDate ? moment(params?.endDate).format('YYYY-MM-DD') : '';

      handleList(params).then();
    }
  }, [params]);

  const handleShowModal = (item: PreVisitModel | undefined = undefined) => {
    setPartner({ id: item?.userVisit?.id, name: item?.userVisit?.name });
    setShowFormPreVisit(true);
    setPreVisitEdit(item);
  }

  const handlePrintPreVisit = (idPreVisit: number) => {
    handlePrintPreVisits(idPreVisit);
  }

  const renderList = (): JSX.Element => {

    return (
      <>
        {preVisits?.length > 0 ? (
          <>
            {preVisits.map((item: PreVisitModel, key: number) => (
              <tr key={'item.id'} className="text-sm">
                <td>{moment(item.scheduledDate).format('DD/MM/YYYY')}</td>
                <td className="text-wrap">
                  {item.userVisit && (<span className='font-weight-bolder'>{item.userVisit.partnerCode}</span>)}
                  {item.userVisit && (<span><br />{item.userVisit.name}</span>)}
                </td>
                <td className="text-wrap">
                  {item.userVisitor && (<span className='font-weight-bolder'>{item.userVisitor.partnerCode}</span>)}
                  {item.userVisitor && (<span><br />{item.userVisitor.name}</span>)}
                </td>
                <td>
                  {item?.visitsPreActions && item?.visitsPreActions?.length > 0 ? (
                    <div className="d-flex align-items-start flex-column gap-1">
                      {item?.visitsPreActions?.map((x: any) => (
                        <div key={x?.id ?? 0} className="badge badge-sm badge-primary">
                          {x?.name}
                        </div>
                      ))}
                    </div>
                  ) : (<></>)}
                </td>
                <td className='text-wrap'>
                  {item?.visitsPreActivities && item?.visitsPreActivities?.length > 0 ? (
                    <div className="d-flex align-items-start flex-column gap-1">
                      {item?.visitsPreActivities?.map((x: any) => (
                        <div key={x?.id ?? 0} className="badge badge-sm badge-info">
                          {x?.name}
                        </div>
                      ))}
                    </div>
                  ) : (<></>)}
                </td>
                <td className="text-wrap">{item.observation}</td>
                <td className="text-wrap">
                  {item.userAnalysis && (<span className='font-weight-bolder'>{item.userAnalysis.partnerCode}</span>)}
                  {item.userAnalysis && (<span><br />{item.userAnalysis.name}</span>)}
                </td>
                <td className="text-wrap">{item.observationAnalysis}</td>
                <td>
                  <span className={`badge ${(item.wasApproved === 0 || item.wasApproved === null) && item.userAnalysis === null ? 'badge-dark' : item.wasApproved ? 'badge-success' : 'badge-danger'}`}>
                    {(item.wasApproved === 0 || item.wasApproved === null) && item.userAnalysis === null ? 'NÃO ANALISADO' : item.wasApproved ? 'APROVADO' : 'REPROVADO'}</span>
                </td>
                <td>
                  <DropdownMenuTable key={1}>
                    {(item.wasApproved === 0 || item.wasApproved === null) && item.userAnalysis === null ? (
                      <Dropdown.Item className="border-radius-md py-2" onClick={() => handleShowModal(item)}>
                        <FontAwesomeIcon icon={faFileCircleCheck} className="me-2" /> Analisar
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item className="border-radius-md py-2" onClick={() => handleShowModal(item)}>
                        <FontAwesomeIcon icon={faEye} className="me-2" /> Visualizar
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handlePrintPreVisit(item?.id ?? 0)}>
                      <FontAwesomeIcon icon={faPrint} className="me-2" /> Imprimir
                    </Dropdown.Item>
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}

          </>
        ) : (
          <tr>
            <td colSpan={10} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <>
            {/* <div className="row mb-2">
              <div className="text-left">
                <button type="button" className="btn bg-success text-white btn-excel-pre-visits-analysis">
                  <i className="fa-regular fa-file-excel me-1"></i> Excel
                </button>
              </div>
            </div> */}

            <div className="row">
              <div className="table-responsive">
                <table className="table table-flush table-striped table-pre-visits-analysis">
                  <thead className="thead-light">
                    <tr role="row">
                      <th className="text-center bg-info" colSpan={6} rowSpan={1} scope="col">AGENDAMENTO</th>
                      <th className="text-center bg-success" colSpan={3} rowSpan={1} scope="col">VALIDADOR</th>
                      <th className="text-center bg-info" colSpan={1} rowSpan={2} scope="col" style={{ width: '80.4px' }}>AÇÕES</th>
                    </tr>
                    <tr role="row">
                      <th className="bg-info" colSpan={1} rowSpan={1} scope="col" style={{ width: '80.4px' }}>DATA AGENDA</th>
                      <th className="bg-info" colSpan={1} rowSpan={1} scope="col" style={{ width: '258.4px' }}>PARCEIRO</th>
                      <th className="bg-info" colSpan={1} rowSpan={1} scope="col" style={{ width: '258.4px' }}>COMERCIAL</th>
                      <th className="bg-info" colSpan={1} rowSpan={1} scope="col">AÇÃO</th>
                      <th className="bg-info" colSpan={1} rowSpan={1} scope="col">FOCO</th>
                      <th className="bg-info" colSpan={1} rowSpan={1} scope="col" style={{ width: '258.4px' }}>OBSERVAÇÃO</th>

                      <th className="bg-success" colSpan={1} rowSpan={1} scope="col" style={{ width: '258.4px' }}>NOME</th>
                      <th className="bg-success" colSpan={1} rowSpan={1} scope="col" style={{ width: '258.4px' }}>OBSERVAÇÃO</th>
                      <th className="bg-success" colSpan={1} rowSpan={1} scope="col" style={{ width: '82.4px' }}>APROVADO</th>
                    </tr>

                  </thead>
                  <tbody>
                    {renderList()}
                  </tbody>
                </table>

                {preVisits?.length > 0 && (
                  <div>
                    <nav aria-label="...">
                      <AppPagination
                        pages={pages}
                        handlePaginate={handlePaginate}
                        params={params}
                        setParams={setParams}
                        style={{ backgroundColor: '#fff' }} />
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <SkeletonTable />
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="Análise Pré-Visitas"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="PreVisitsAnalysis" data-testid="PreVisitsAnalysis">
        <PageBase
          title="Painel Análise de Pré-Visitas"
          subtitle="Painel para análise de pré-visitas."
          error={error}
          content={renderContent()}
          hasFilter
          handleFilter={() => showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
        />

        {(isLoading)}

        <SidebarFilter>
          <SidebarPreVisitsAnalysisFilter />
        </SidebarFilter>

        <ModalPreVisits
          show={showFormPreVisit}
          onClose={() => setShowFormPreVisit(false)}
          action={'analysis'}
          preVisitEdit={preVisitEdit}
          setPreVisitEdit={setPreVisitEdit}
          hasAnalysis
          partner={partner}
        />

      </div>

    </MasterPage>
  );
}

export default PreVisitsAnalysis;