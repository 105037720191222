import { FC, useEffect, useState } from 'react';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { useMonthlyGoal } from '../../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalProvider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validate';
import InputDropzone from '../../../InputDropzone/InputDropzone';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import Swal from 'sweetalert2';
import ReactSelect from 'react-select';
import moment from 'moment';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { monthlyGoalAccessProfileOptions } from '../../../../models/PanelConnects/MonthlyGoalAccessProfileOptions';

interface ModalMonthlyGoalImportPDFProps {
  show: boolean;
  onClose: () => void;
}

const ModalMonthlyGoalImportPDF: FC<ModalMonthlyGoalImportPDFProps> = ({ show, onClose }) => {

  const [selectedPeriod, setSelectedPeriod] = useState<any>();
  const [selectedAccessProfileId, setSelectedAccessProfileId] = useState<any>();
  const [periodOptions, setPeriodOptions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleImportPDF } = useMonthlyGoal();

  const [files, setFiles] = useState<any[]>([]);
  const [fileTypes] = useState<any>({
    'application/vnd.ms-excel': ['.pdf'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.pdf'],
  });

  const { handleSubmit, setValue, reset, formState: { errors } } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (periodOptions.length === 0) {
      getPeriodOptions();
    }
  }, []);

  useEffect(() => {
    setValue('period', selectedPeriod);
  }, [selectedPeriod]);

  useEffect(() => {
    setValue('accessProfileId', selectedAccessProfileId);
  }, [selectedAccessProfileId]);

  useEffect(() => {
    if (files?.length > 0) {
      if (files[0]?.path) {
        if (files[0]?.path.split('.')[1] === 'pdf') {
          setValue('file', files[0]?.path);
        }
        else {
          Swal.fire('Apenas arquivos do tipo .pdf serão aceitos', '', 'warning')
          setValue('file', '');
          setFiles([]);
        }
      }
    } else {
      setValue('file', '');
    }
  }, [files]);

  const handleClose = async () => {
    setSelectedPeriod(null);
    setSelectedAccessProfileId(null);
    setFiles([]);
    reset();
    onClose();
  }

  const getPeriodOptions = () => {
    Array.from(Array(5)).forEach((x, i) => {
      periodOptions?.push({
        label: moment(new Date()).add(i - 1, 'M').format('MMMM/YY')?.toUpperCase(),
        value: moment(new Date()).add(i - 1, 'M').format('YYYY-MM')
      });
    });
    setSelectedPeriod(periodOptions[0]);
  }

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const result = await handleImportPDF(files, moment(selectedPeriod?.value, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'), selectedAccessProfileId?.value).then();
    setIsLoading(false);

    if (result) {
      handleClose();
    }
  }

  return (
    <ModalDefault
      title={'Importar PDF Conecta'}
      show={show}
      onClose={handleClose}
      sizeModal={'lg'}
      showFooter={true}
      buttonText={isLoading ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoading}
      disabledSubmit={isLoading}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
    >
      <div className="ModalMonthlyGoalImportPDF" data-testid="ModalMonthlyGoalImportPDF">
        <Row>
          <Col md={6}>
            <FormGroup className="form-group">
              <label htmlFor='period'>PERÍODO *</label>
              <ReactSelect
                name='period'
                value={selectedPeriod}
                isSearchable
                isClearable={false}
                options={periodOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedPeriod(val);
                }}
                styles={customStyles}
              />
              <ErrorMessage name='Período' type={errors?.period?.type?.toString()} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="form-group">
              <label>TIPO COMERCIAL *</label>
              <ReactSelect
                name='commercialType'
                value={selectedAccessProfileId}
                isClearable={false}
                options={monthlyGoalAccessProfileOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedAccessProfileId(val);
                }}
                styles={customStyles}
              />
              <ErrorMessage name='Tipo Comercial' type={errors?.accessProfileId?.type?.toString()} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup className="form-group">
              <InputDropzone
                label="Arquivo"
                name="file"
                files={files}
                setFiles={setFiles}
                types={fileTypes}
                maxSize={157286400}
                error={!!errors?.file}
              />
              <ErrorMessage name="Arquivo" type={errors?.file?.type?.toString()} />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
}

export default ModalMonthlyGoalImportPDF;
