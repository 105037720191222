import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { Controller } from 'react-hook-form';
import { useAuth } from '../../../../providers/AuthProvider';
import { useCompany } from '../../../../providers/Registrations/Company/CompanyProvider';
import { customStyles } from '../../../../models/SelectCustomStyles';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';

interface SelectCompanyProps {
  inputName: string;
  errors: any;
  control: any;
  setValue: any;
  register?: any;
  valueSelected: any;
  setValueSelected: Dispatch<SetStateAction<any>>;
  title?: string;
  min?: number;
  max?: number;
  onChangeSelect: (id: number) => void,
}

const SelectCompany: FC<SelectCompanyProps> = ({
  inputName,
  errors,
  control,
  setValue,
  register = null,
  valueSelected,
  setValueSelected,
  title = '',
  min = 0,
  max = 255,
  onChangeSelect
}) => {
  const { companysOptions, selectedCompany, setSelectedCompany, handleGetAll } = useCompany();
  const { onInvalid } = useAuth();
  const animatedComponents = makeAnimated();

  useEffect(() => {
    if (selectedCompany && !control) {
      onChangeSelect(selectedCompany?.value);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (valueSelected && control) {
      setValue(inputName, valueSelected);
    }
  }, [valueSelected]);

  useEffect(() => {
    handleGetAll();
  }, []);

  return (
    <div className="SelectCompany" data-testid="SelectCompany">
      {control ? (
        <>
          <label className='form-control-label text-uppercase'>{title} *</label>
          <Controller
            control={control}
            {...register(inputName, { required: true })}
            render={({ field: { onChange, name, ref } }) => (
              <ReactSelect
                ref={ref}
                name={name}
                isClearable
                isSearchable
                options={companysOptions}
                placeholder="Selecione..."
                className={`form-control p-0 ${onInvalid(errors?.[inputName])}`}
                value={valueSelected || ''}
                defaultValue={valueSelected}
                components={animatedComponents}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  onChange(val || null);
                  setValueSelected(val);
                }}
                styles={customStyles}
              />
            )}
          />
          <ErrorMessage name={title} type={errors?.[inputName]?.type} min={min} max={max} />
        </>
      ) : (
        <ReactSelect
          name={inputName}
          isClearable={false}
          isSearchable
          options={companysOptions}
          placeholder="Selecione..."
          className={`form-control p-0`}
          value={selectedCompany || ''}
          defaultValue={selectedCompany}
          components={animatedComponents}
          noOptionsMessage={() => 'Não há registros'}
          onChange={(val: any) => {
            setSelectedCompany(val);
          }}
          styles={customStyles}
        />
      )}
    </div>
  );
}

export default SelectCompany;
