import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { getToken } from "firebase/messaging";
import { messaging } from "../firebase";
import { FirebaseMessagingService } from "../services/FirebaseMessagingService";
import Swal from "sweetalert2";
import { isChrome, isFirefox, isEdge, isSafari, isOpera, isMobile } from 'react-device-detect';

interface ContextProps {
    handleTokenFcm: () => Promise<any>,
}

export const FirebaseMessagingContext = createContext<ContextProps>({} as ContextProps);

interface FirebaseMessagingProviderProps {
    children: ReactNode,
}

export const FirebaseMessagingProvider = ({ children }: FirebaseMessagingProviderProps) => {
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const service = new FirebaseMessagingService();

    const alertPermission = () => {
        Swal.fire({
            position: "top-start",
            icon: "warning",
            title: "Atenção",
            html: "É necessário ativar os recursos de <b>geolocalização</b> e <b>notificações</b> do seu navegador para continuar acessando o sistema. Para mais informações de como habilitar essas funções, fale com seu assessor comercial, chat ou utilize o beviajuda.",
            showConfirmButton: true,
            confirmButtonText: "Ok, estou ciente",
            customClass: {
                confirmButton: "btn btn-primary"
            },
            buttonsStyling: false,
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    position: "top-start",
                    icon: "info",
                    title: "Habilitando as permissões",
                    html: `
                        Para permitir, clique no ícone localizado no topo da página ao lado da url. 
                        Logo em seguida será possível visualizar as informações de todas as permissões necessárias (<b>Local</b> e <b>Notificações</b>). 
                        Para finalizar, check cada opção para habilitar a função. 
                        ${(isChrome || isSafari || isOpera) ? "<img src='/assets/img/tutorial-permissions-chrome.gif' alt='Exemplo' class='img-tutorial mt-3' />" : ""} 
                        ${(isFirefox) ? "<img src='/assets/img/tutorial-permissions-firefox.gif' alt='Exemplo' class='img-tutorial mt-3' />" : ""} 
                        ${(isEdge) ? "<img src='/assets/img/tutorial-permissions-edge.gif' alt='Exemplo' class='img-tutorial mt-3' />" : ""} 
                        <p class='mt-3 mb-0'>Pronto, você já pode usar o sistema normalmente.</p>
                    `,
                    showConfirmButton: true,
                    confirmButtonText: "Ok, já habilitei",
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: false,
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {

                    }
                });
            }
        });
    }

    const requestPermission = () => {
        navigator.geolocation.getCurrentPosition(() => { }, (error: any) => {
            if (error?.code && !isMobile) {
                setShowAlert(true);
            }
        });
    }

    const getOrRegisterServiceWorker = async () => {
        if ('serviceWorker' in navigator) {
            const serviceWorker = await window.navigator.serviceWorker
                .getRegistration('/firebase-push-notification-scope');
            if (serviceWorker) return serviceWorker;
            return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
                scope: '/firebase-push-notification-scope',
            });
        }
        throw new Error('The browser doesn`t support service worker.');
    };

    const handleTokenFcm = async (): Promise<any> => {
        await getOrRegisterServiceWorker()
            .then(async (serviceWorkerRegistration) => {
                const _messaging = await messaging();

                if (!_messaging) {
                    return false;
                }

                return getToken(_messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY, serviceWorkerRegistration }).then(async (currentToken) => {
                    if (currentToken) {
                        const [_Response, _Error, _Code, _Errors] = await service.fetch({ tokenFcm: currentToken, applicationType: "web" });
                        if (!!_Error) {
                            return false;
                        }

                        return true;
                    } else {
                        return false;
                    }
                })
            }).catch((err) => {
                return false;
            });

    }

    useEffect(() => {
        setShowAlert(false);
        requestPermission();
    }, []);

    useEffect(() => {
        showAlert && alertPermission();
    }, [showAlert]);

    return (
        <FirebaseMessagingContext.Provider value={{
            handleTokenFcm
        }}>
            {children}
        </FirebaseMessagingContext.Provider>
    );
};

export const useFirebaseMessaging = () => useContext(FirebaseMessagingContext);