import { ProposalImportFetchParams } from "../../../models/Proposals/Import/ProposalImportFetchParams";
import { ProposalImportListParams } from "../../../models/Proposals/Import/ProposalImportListParams";
import api from "../../Api";
import { BaseService } from "../../BaseService";
import FileSaver from 'file-saver';

const URL = 'api/services/proposals/import';

export class ProposalImportService extends BaseService {
    async list(params: ProposalImportListParams): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async fetch(params: ProposalImportFetchParams, file: File): Promise<any> {
        try {
            const formdata = new FormData();
            formdata.append("financialId", params?.financialId?.toString() ?? '');
            formdata.append("file", file);

            return this.handleResponse(await api.post(`${URL}`, formdata, { headers: { 'Content-Type': 'multipart/form-data' } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async delete(id: number): Promise<any> {
        try {
            return this.handleResponse(await api.delete(`${URL}`, { data: { id } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async download(id: number, isBBmais: boolean): Promise<any> {
        try {
            const response = await api.post(`${URL}/download`, { id, isBBmais }, { responseType: 'blob' });
            const filename = response.headers['content-disposition'].split('filename=')[1];
            FileSaver.saveAs(response.data, filename, { autoBom: false });
            return [true, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async reprocess(id: number): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/reprocess`, { id }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}