import { Dispatch, FC, SetStateAction } from 'react';
import { Badge, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import { AccessProfileModel } from '../../../../models/Registrations/Access/AccessProfileModel';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useAccessProfile } from '../../../../providers/Registrations/Access/AccessProfileProvider';
import { usePaginate } from '../../../../providers/PaginateProvider';
import AppPagination from '../../../AppPagination/AppPagination';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import DropdownMenuTable from '../../../DropdownMenuTable/DropdownMenuTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';

interface AccessLevelListProps {
  setShowEdit: Dispatch<SetStateAction<boolean>>;
  setTypeAccess: Dispatch<SetStateAction<string>>;
}

const AccessLevelList: FC<AccessLevelListProps> = ({ setShowEdit, setTypeAccess }) => {
  const {
    profiles,
    params,
    setParams,
    error,
    isLoading,
    handleDelete,
    handleList,
    handleDetails,
    handleSave,
    pagesPaginate
  } = useAccessProfile();
  const { handlePaginate } = usePaginate();

  const deleteProfile = async (profile: AccessProfileModel) => {
    if (profile?.active) {
      const [, erro] = await handleDelete(profile?.id ?? 0);
      if (!erro) {
        handleList();
        toast.success('Nível de Acesso inativado com sucesso!');
      } else {
        await Swal.fire('Ops!', `Não foi possível inativar o nível de acesso.<br>${error}`, 'error');
      }
    } else {
      const [, erro] = await handleSave({ id: profile?.id, active: true, name: profile?.name });
      if (!erro) {
        handleList();
        toast.success('Nível de Acesso ativado com sucesso!');
      } else {
        await Swal.fire('Ops!', `Não foi possível ativar o nível de acesso.<br>${error}`, 'error');
      }
    }
  }

  const renderList = () => {
    return (
      <>
        {profiles?.length > 0 ? (
          <>
            {profiles.map((item: AccessProfileModel) => (
              <tr key={item.id} className="text-sm">
                <td className="text-sm font-weight-normal">{item.id}</td>
                <td className="text-sm font-weight-normal">{item.name}</td>
                <td>{item?.company?.name || '-'}</td>
                <td>{item.isLevelDependent ? 'DEPENDENTE' : 'MASTER' }</td>
                <td className="text-center">{moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td className="text-center" scope="col">
                  <Badge bg={item.active ? 'success' : 'danger'}>
                    {item.active ? 'ATIVO' : 'INATIVO'}
                  </Badge>
                </td>
                <td className="d-flex justify-content-center gap-3" scope="col">
                  <DropdownMenuTable key={1}>
                    {item.active && (
                      <>
                        <Dropdown.Item 
                          className="border-radius-md py-2" 
                          onClick={async () => {
                            await handleDetails(item.id ?? 0);
                            setShowEdit(true);
                            setTypeAccess("desktop");
                          }}
                          >
                          <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar Nível de Acesso Desktop
                        </Dropdown.Item> 

                        <Dropdown.Item 
                          className="border-radius-md py-2" 
                          onClick={async () => {
                            await handleDetails(item.id ?? 0, "mobile");
                            setShowEdit(true);
                            setTypeAccess("mobile");
                          }}
                          >
                          <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar Nível de Acesso Mobile
                        </Dropdown.Item>
                      </>
                    )}
                    
                    <Dropdown.Item 
                      className="border-radius-md py-2" 
                      onClick={() => deleteProfile(item)}
                      >
                      <FontAwesomeIcon icon={item.active ? faLockOpen : faLock} className="me-2" />
                      {item.active ? 'Inativar Nível de Acesso' : 'Ativar Nível de Acesso'}
                    </Dropdown.Item>
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <>
            <tr>
              <td className='text-center text-sm' colSpan={6}>Não há registros</td>
            </tr>
          </>
        )}
      </>
    );
  }

  return (
    <div className="AccessLevelList" data-testid="AccessLevelList">
      {!isLoading ? (
        <div className="table-responsive">
          <table className="table table-flush m-0 w-100">
            <thead className="thead-light">
              <tr>
                <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">#Id</th>
                <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">Nome</th>
                <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">Empresa</th>
                <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">Tipo Acesso</th>
                <th className="text-center text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">Data de Cadastro</th>
                <th className="text-center text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">Situação</th>
                <th className="text-center text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">Ações</th>
              </tr>
            </thead>
            <tbody>
              {renderList()}
            </tbody>
          </table>
        </div>
      ) : (
        <SkeletonTable />
      )}

      {(profiles?.length > 0 && !!pagesPaginate) && (
        <div>
          <nav aria-label="...">
            <AppPagination
              pages={pagesPaginate}
              handlePaginate={handlePaginate}
              params={params}
              setParams={setParams}
            />
          </nav>
        </div>
      )}
    </div>
  );
}

export default AccessLevelList;
