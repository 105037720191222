import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ToastSettings } from "../../utils/ToastSettings";
import AuthenticatorValidationEmailService from "../../services/Authenticator/AuthenticatorValidationEmailService";

interface ContextProps {
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleFetch: (token: string, response: number) => Promise<boolean>,
}

export const AuthenticatorValidationEmailContext = createContext<ContextProps>({} as ContextProps);

interface AuthenticatorValidationEmailProviderProps {
    children: ReactNode;
}

export const AuthenticatorValidationEmailProvider = ({ children }: AuthenticatorValidationEmailProviderProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new AuthenticatorValidationEmailService();

    const handleFetch = async (token: string, response: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error, _Code, _Errors] = await service.fetch({ token, response });
            if (!!_Error) {
                setError(_Response?.message || _Error);
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                setIsLoading(false);
                return false;
            }

            setError('');
            toast.success('Resposta enviada com sucesso!');
            setIsLoading(false);
            return true;
        } catch (e) {
            setError('Houve um erro ao enviar a resposta');
            setIsLoading(false);
            return false;
        }
    }

    return (
        <AuthenticatorValidationEmailContext.Provider value={{
            isLoading,
            error,
            setError,
            handleFetch
        }}>
            {children}
        </AuthenticatorValidationEmailContext.Provider>
    );
}

export const useAuthenticatorValidationEmail = () => useContext(AuthenticatorValidationEmailContext);