import React, { FC } from 'react';
import './RankingPointsTab.scss';
import { useMonitoringCommercialPoints } from '../../../../providers/PanelConnects/MonitoringCommercialPoints/MonitoringCommercialPointsProvider';
import DataTable from '../../../DataTable/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface RankingPointsTabProps {
  itemsPerPage: number
}

const RankingPointsTab: FC<RankingPointsTabProps> = ({ itemsPerPage }) => {
  const { rankingHeader, rankingPoints, isLoading } = useMonitoringCommercialPoints();

  return (
    <div className="RankingPointsTab" data-testid="RankingPointsTab">
      {!isLoading ?
        <DataTable
          columns={rankingHeader}
          data={rankingPoints}
          rowHeight={50}
          rowPerPage={itemsPerPage}
        /> :
        <div className='d-flex justify-content-center align-items-center min-height-400'>
          <FontAwesomeIcon icon={faSpinner} className='text-2xl' spin />
        </div>
      }
    </div>
  )
};

export default RankingPointsTab;
