import { Dispatch, FC, SetStateAction } from 'react';
import { Card, Col, FormGroup, Row } from 'react-bootstrap';
import { TwitterPicker } from 'react-color';
import { CompanyModel } from '../../../../../models/Registrations/Company/CompanyModel';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import InputDropzone from '../../../../InputDropzone/InputDropzone';

interface FormLayoutInfoProps {
  control: any;
  errors: any;
  register: any;
  setValue: any;
  company: CompanyModel;
  setCompany: Dispatch<SetStateAction<CompanyModel>>;
  logoPrimary: any[];
  setLogoPrimary: Dispatch<SetStateAction<any[]>>;
  logoSecondary: any[];
  setLogoSecondary: Dispatch<SetStateAction<any[]>>;
  favicon: any[];
  setFavicon: Dispatch<SetStateAction<any[]>>;
  backgroundLogin: any[];
  setBackgroundLogin: Dispatch<SetStateAction<any[]>>;
}

const FormLayoutInfo: FC<FormLayoutInfoProps> = ({
  errors,
  control,
  setValue,
  company,
  setCompany,
  logoPrimary,
  setLogoPrimary,
  logoSecondary,
  setLogoSecondary,
  favicon,
  setFavicon,
  backgroundLogin,
  setBackgroundLogin,
}) => {
  return (
    <Row className="FormLayoutInfo mt-4" data-testid="FormLayoutInfo">
      <Card>
        <Card.Header className="pb-1 px-2">
          <h5>Layout</h5>
        </Card.Header>
        <Card.Body className="p-2">
          <Row>
            <Col md={6} xxl={4}>
              <FormGroup className="form-group">
                <label htmlFor="colorPrimary">Cor Principal *</label>
                <TwitterPicker
                  className="mt-1"
                  color={company?.colorPrimary}
                  colors={['#222342', '#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF', '#FFFFFF', '#333333', '#000000']}
                  onChangeComplete={(color: any) => {
                    setValue('colorPrimary', color?.hex || '');
                    setCompany({ ...company, colorPrimary: color?.hex || '' });
                  }}
                />
                <ErrorMessage name={'Cor Principal'} type={errors?.colorPrimary?.type} />
              </FormGroup>
            </Col>
            <Col md={6} xxl={4}>
              <FormGroup className="form-group">
                <label htmlFor="colorSecondary">Cor Secundária *</label>
                <TwitterPicker
                  className="mt-1"
                  color={company?.colorSecondary}
                  colors={['#222342', '#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF', '#FFFFFF', '#333333', '#000000']}
                  onChangeComplete={(color: any) => {
                    setValue('colorSecondary', color?.hex || '');
                    setCompany({ ...company, colorSecondary: color?.hex || '' })
                  }}
                />
                <ErrorMessage name={'Cor Secundária'} type={errors?.colorPrimary?.type} />
              </FormGroup>
            </Col>
            <Col md={7} xxl={4}>
              <FormGroup className="form-group">
                <label htmlFor="colorText">Cor Texto *</label>
                <TwitterPicker
                  className="mt-1"
                  color={company?.colorText}
                  colors={['#222342', '#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF', '#FFFFFF', '#333333', '#000000']}
                  onChangeComplete={(color: any) => {
                    setValue('colorText', color?.hex || '');
                    setCompany({ ...company, colorText: color?.hex || '' })
                  }}
                />
                <ErrorMessage name={'Cor Texto'} type={errors?.colorText?.type} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="form-group">
                {!!company?.id && !!company?.logoDefault ? (
                  <div className='logo-container'>
                    <label>LOGO PRINCIPAL *</label>
                    <span className='text-center p-2 mt-3'>
                      <img className='logo max-height-50' src={company?.logoDefault} />
                    </span>
                    <span className='text-end'>
                      <button
                        className='btn btn-sm btn-outline-primary mb-0 mt-3'
                        onClick={() => setCompany({ ...company, logoDefault: '' })}
                      >
                        Alterar Logo
                      </button>
                    </span>
                  </div>
                ) : (
                  <InputDropzone
                    files={logoPrimary}
                    setFiles={setLogoPrimary}
                    label={'LOGO PRINCIPAL *'}
                    name={'logoPrimary'}
                    types={
                      {
                        'image/png': ['.png', '.jpg', '.jpeg']
                      }
                    }
                    maxSize={2100000}
                    classNamePreview="max-height-50"
                  />
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="form-group">
                {!!company?.id && !!company?.logoInverse ? (
                  <div className='logo-container'>
                    <label>LOGO SECUNDÁRIA *</label>
                    <span className='text-center p-2 mt-3' style={{ backgroundColor: '#c9c9c9' }}>
                      <img className='logo max-height-50' src={company?.logoInverse} />
                    </span>
                    <span className='text-end'>
                      <button
                        className='btn btn-sm btn-outline-primary mb-0 mt-3'
                        onClick={() => setCompany({ ...company, logoInverse: '' })}
                      >
                        Alterar Logo
                      </button>
                    </span>
                  </div>
                ) : (
                  <InputDropzone
                    files={logoSecondary}
                    setFiles={setLogoSecondary}
                    label={'LOGO SECUNDÁRIA *'}
                    name={'logoSecondary'}
                    types={
                      {
                        'image/png': ['.png', '.jpg', '.jpeg']
                      }
                    }
                    maxSize={2100000}
                    classNamePreview="max-height-50"
                    style={{ backgroundColor: '#c9c9c9' }}
                  />
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="form-group">
                {!!company?.id && !!company?.favicon ? (
                  <div className='logo-container'>
                    <label>ÍCONE</label>
                    <span className='text-center p-2 mt-3'>
                      <img className='logo max-height-50' src={company?.favicon} />
                    </span>
                    <span className='text-end'>
                      <button
                        className='btn btn-sm btn-outline-primary mb-0 mt-3'
                        onClick={() => setCompany({ ...company, favicon: '' })}
                      >
                        Alterar Favicon
                      </button>
                    </span>
                  </div>
                ) : (
                  <InputDropzone
                    files={favicon}
                    setFiles={setFavicon}
                    label={'ÍCONE'}
                    name={'favicon'}
                    types={
                      {
                        'image/png': ['.png', '.jpg', '.jpeg', '.ico']
                      }
                    }
                    maxSize={2100000}
                    classNamePreview="max-height-50"
                  />
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="form-group">
                {!!company?.id && !!company?.backgroundLogin ? (
                  <div className='logo-container'>
                    <label>BACKGROUND LOGIN</label>
                    <span className='text-center p-2 mt-3'>
                      <img className='logo max-height-50' src={company?.backgroundLogin} />
                    </span>
                    <span className='text-end'>
                      <button
                        className='btn btn-sm btn-outline-primary mb-0 mt-3'
                        onClick={() => setCompany({ ...company, backgroundLogin: '' })}
                      >
                        Alterar Background
                      </button>
                    </span>
                  </div>
                ) : (
                  <InputDropzone
                    files={backgroundLogin}
                    setFiles={setBackgroundLogin}
                    label={'BACKGROUND LOGIN *'}
                    name={'backgroundLogin'}
                    types={
                      {
                        'image/png': ['.png', '.jpg', '.jpeg', '.ico']
                      }
                    }
                    maxSize={2100000}
                  />
                )}
              </FormGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
}

export default FormLayoutInfo;
