import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { BiMonthlyProductionModel } from "../../../../models/BI/Dashboard/BiMonthlyProductionModel";
import { BiAnnualProductionModel } from "../../../../models/BI/Dashboard/BiAnnualProductionModel";

interface ContextProps {
  monthlySupervisor: BiMonthlyProductionModel | undefined;
  setMonthlySupervisor: Dispatch<
    SetStateAction<BiMonthlyProductionModel | undefined>
  >;
  annualSupervisor: BiAnnualProductionModel | undefined;
  setAnnualSupervisor: Dispatch<
    SetStateAction<BiAnnualProductionModel | undefined>
  >;
  supervisorFinancial: BiMonthlyProductionModel | undefined;
  setSupervisorFinancial: Dispatch<
    SetStateAction<BiMonthlyProductionModel | undefined>
  >;
}

export const BiSupervisorContext = createContext<ContextProps>(
  {} as ContextProps
);

interface BiSupervisorProviderProps {
  children: ReactNode;
}

export const BiSupervisorProvider = ({
  children,
}: BiSupervisorProviderProps) => {
  const [monthlySupervisor, setMonthlySupervisor] =
    useState<BiMonthlyProductionModel>();
  const [supervisorFinancial, setSupervisorFinancial] =
    useState<BiMonthlyProductionModel>();
  const [annualSupervisor, setAnnualSupervisor] =
    useState<BiAnnualProductionModel>();

  return (
    <BiSupervisorContext.Provider
      value={useMemo(
        () => ({
          monthlySupervisor,
          setMonthlySupervisor,
          annualSupervisor,
          setAnnualSupervisor,
          supervisorFinancial,
          setSupervisorFinancial,
        }),
        [
          monthlySupervisor,
          setMonthlySupervisor,
          annualSupervisor,
          setAnnualSupervisor,
          supervisorFinancial,
          setSupervisorFinancial,
        ]
      )}
    >
      {children}
    </BiSupervisorContext.Provider>
  );
};

export const useBiSupervisor = () => useContext(BiSupervisorContext);
