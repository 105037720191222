import api from "../Api";
import { BaseService } from "../BaseService";



const URL = 'api/security/form/cyberIncident';

export class CyberIncidentService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}