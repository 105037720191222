import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { BiCovenantParams } from "../../../../models/BI/Dashboard/Covenant/BiCovenantParams";
import { BiCovenantChartParticipationModel } from "../../../../models/BI/Dashboard/Covenant/BiCovenantChartParticipationModel";
import { BiMonthlyProductionModel } from "../../../../models/BI/Dashboard/BiMonthlyProductionModel";
import { BiAnnualProductionModel } from "../../../../models/BI/Dashboard/BiAnnualProductionModel";

interface ContextProps {
  covenantProductions: BiMonthlyProductionModel | undefined;
  covenants: BiAnnualProductionModel | undefined;
  setCovenantProductions: Dispatch<
    SetStateAction<BiMonthlyProductionModel | undefined>
  >;
  setCovenants: Dispatch<SetStateAction<BiAnnualProductionModel | undefined>>;

  monthlyGroupAgreements: BiMonthlyProductionModel | undefined;
  setMonthlyGroupAgreements: Dispatch<SetStateAction<BiMonthlyProductionModel | undefined>>;
  annualGroupAgreements: BiAnnualProductionModel | undefined;
  setAnnualGroupAgreements: Dispatch<SetStateAction<BiAnnualProductionModel | undefined>>;

  monthlyParticipation: BiCovenantChartParticipationModel[];
  annualParticipation: BiCovenantChartParticipationModel[];
  covenantsFinancial: BiMonthlyProductionModel | undefined;
  setCovenantsFinancial: Dispatch<
    SetStateAction<BiMonthlyProductionModel | undefined>
  >;
  covenantsBranchSupervisor: BiMonthlyProductionModel | undefined;
  setCovenantsBranchSupervisor: Dispatch<
    SetStateAction<BiMonthlyProductionModel | undefined>
  >;
  covenantsFinanceSupervisor: BiMonthlyProductionModel | undefined;
  setCovenantsFinanceSupervisor: Dispatch<
    SetStateAction<BiMonthlyProductionModel | undefined>
  >;
  isLoading: boolean;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  params: BiCovenantParams;
  setParams: Dispatch<SetStateAction<BiCovenantParams>>;
}

export const BiCovenantContext = createContext<ContextProps>(
  {} as ContextProps
);

interface BiCovenantProviderProps {
  children: ReactNode;
}

export const BiCovenantProvider = ({ children }: BiCovenantProviderProps) => {
  const [covenantProductions, setCovenantProductions] =
    useState<BiMonthlyProductionModel>();
  const [covenants, setCovenants] = useState<BiAnnualProductionModel>();
  const [covenantsFinancial, setCovenantsFinancial] =
    useState<BiMonthlyProductionModel>();
  const [covenantsBranchSupervisor, setCovenantsBranchSupervisor] =
    useState<BiMonthlyProductionModel>();
  const [covenantsFinanceSupervisor, setCovenantsFinanceSupervisor] =
    useState<BiMonthlyProductionModel>();
  const [monthlyParticipation, setMonthlyParticipation] = useState<
    BiCovenantChartParticipationModel[]
  >([]);
  const [annualParticipation, setAnnualParticipation] = useState<
    BiCovenantChartParticipationModel[]
  >([]);

  const [monthlyGroupAgreements, setMonthlyGroupAgreements] = useState<BiMonthlyProductionModel>();
  const [annualGroupAgreements, setAnnualGroupAgreements] = useState<BiAnnualProductionModel>();

  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [params, setParams] = useState<BiCovenantParams>({});

  return (
    <BiCovenantContext.Provider
      value={useMemo(
        () => ({
          covenantProductions,
          covenants,
          setCovenantProductions,
          setCovenants,
          monthlyParticipation,
          annualParticipation,
          covenantsFinancial,
          setCovenantsFinancial,
          covenantsBranchSupervisor,
          setCovenantsBranchSupervisor,
          covenantsFinanceSupervisor,
          setCovenantsFinanceSupervisor,
          isLoading,
          error,
          setError,
          params,
          setParams,
          annualGroupAgreements,
          monthlyGroupAgreements,
          setAnnualGroupAgreements,
          setMonthlyGroupAgreements,
        }),
        [
          covenantProductions,
          covenants,
          monthlyParticipation,
          annualParticipation,
          covenantsFinancial,
          covenantsBranchSupervisor,
          setCovenantsBranchSupervisor,
          covenantsFinanceSupervisor,
          setCovenantsFinanceSupervisor,
          isLoading,
          error,
          setError,
          params,
          setParams,
          annualGroupAgreements,
          monthlyGroupAgreements,
          setAnnualGroupAgreements,
          setMonthlyGroupAgreements,
        ]
      )}
    >
      {children}
    </BiCovenantContext.Provider>
  );
};

export const useBiCovenant = () => useContext(BiCovenantContext);
