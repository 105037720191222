import api from "../../../Api";
import { BaseService } from "../../../BaseService";

const URL = 'api/company/subsidiary';

export class SubsidiaryService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}