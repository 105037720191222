import * as yup from "yup";

export const schema = yup.object().shape({
    visitsPreActions: yup
        .array()
        .required()
        .min(1),
    visitsPreActivities: yup
        .array()
        .required()
        .min(1),
    observation: yup
        .string()
        .required()
        .max(500),
});