import api from "../Api";
import { AuthenticatorValidationAlternativeFetchParams } from "../../models/Authenticator/AuthenticatorValidationAlternativeFetchParams";
import { BaseService } from "../BaseService";

const URL = 'api/user/verification-sms';

class AuthenticatorValidationAlternativeService extends BaseService {
    async send(): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/sendCode`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async verify(params: AuthenticatorValidationAlternativeFetchParams): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/verifyCode`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}

export default AuthenticatorValidationAlternativeService;