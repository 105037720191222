import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastSettings } from "../../../utils/ToastSettings";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { ReportModel } from "../../../models/Reports/Report/ReportModel";
import { ReportParams } from "../../../models/Reports/Report/ReportParams";
import { ReportService } from "../../../services/Reports/Report/ReportService";

interface ContextProps {
    report: ReportModel,
    setReport: Dispatch<SetStateAction<ReportModel>>,
    reports: ReportModel[],
    params: ReportParams,
    setParams: Dispatch<SetStateAction<ReportParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleFetch: (data: ReportModel) => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>,
}

export const ReportContext = createContext<ContextProps>({} as ContextProps);

interface ReportProviderProps {
    children: ReactNode;
}

export const ReportProvider = ({ children }: ReportProviderProps) => {
    const [report, setReport] = useState<ReportModel>({} as ReportModel);
    const [reports, setReports] = useState<ReportModel[]>([]);
    const [params, setParams] = useState<ReportParams>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new ReportService();
    const location = useLocation();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/report/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response?.message || _Error);
            }

            setReports(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar os relatórios do sistema.');
        }
    }, [params, location.pathname]);

    const handleFetch = async (data: ReportModel) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.fetch(data);
            if (!!_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setError('Houve um erro ao salvar o relatório');
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar a financeira');
            return false;
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <ReportContext.Provider value={{
            report,
            setReport,
            reports,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleFetch,
            handleDelete,
        }}>
            {children}
        </ReportContext.Provider>
    );
}

export const useReport = () => useContext(ReportContext);