import { FC, useEffect, useState } from 'react';
import { useAuth } from '../../../../providers/AuthProvider';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { ProposalRuleConditionEnum } from '../../../../enums/Proposals/Rule/Condition/ProposalRuleConditionEnum';
import { Col, FormGroup, Row } from 'react-bootstrap';
import Tagfy from '../../../Tagfy/Tagfy';
import { TablesColumnsNamesProposalsDataTypesEnum } from '../../../../enums/Proposals/Config/TablesColumnsNamesProposalsDataTypesEnum';
import { Controller } from 'react-hook-form';
import InputCurrencyDecimal from '../../../InputCurrencyDecimal/InputCurrencyDecimal';
import moment from 'moment';
import InputDate from '../../../InputDate/InputDate';

interface ProposalRuleChooseConditionProps {
  conditionId: number;
  control: any;
  register: any;
  errors: any;
  setValue: any;
  dataType?: string;
}

const ProposalRuleChooseCondition: FC<ProposalRuleChooseConditionProps> = ({
  conditionId,
  control,
  register,
  errors,
  setValue,
  dataType = ''
}) => {
  const [value1, setValue1] = useState<any[] | string | number>([]);
  const [date1, setDate1] = useState<any | null>(null);
  const [date2, setDate2] = useState<any | null>(null);

  const { onInvalid } = useAuth();

  useEffect(() => {
    setValue('value1', Array.isArray(value1) ? value1?.join(", ") : value1);
  }, [value1]);

  useEffect(() => {
    setValue('value1', date1 ? moment(date1).format('DD/MM/YYYY') : '');
    setValue('value2', date2 ? moment(date2).format('DD/MM/YYYY') : '');
  }, [date1, date2]);

  useEffect(() => {
    if (conditionId) {
      setValue1([]);
      setValue('value1', '');
      setValue('value2', '');
    }
  }, [conditionId]);

  useEffect(() => {
    setValue1([]);
    setDate1(null);
  }, []);

  return (
    <div className="ProposalRuleChooseCondition" data-testid="ProposalRuleChooseCondition">
      <Row>
        {(![ProposalRuleConditionEnum.EMPTY, ProposalRuleConditionEnum.NOT_EMPTY].includes(conditionId)) && (
          <Col>
            <FormGroup className="form-group m-0">
              <label htmlFor="value1">{conditionId === ProposalRuleConditionEnum.BETWEEN ? 'De' : 'Valor'} *</label>
              {conditionId === ProposalRuleConditionEnum.CONTAINED_IN ? (
                <Tagfy
                  handleAdd={(e: any) => setValue1((current: any) => current ? [...current, e] : [e])}
                  handleRemove={(e: any) => setValue1((current: any) => current.filter((x: string) => x !== e))}
                />
              ) : (
                <>
                  {dataType === TablesColumnsNamesProposalsDataTypesEnum.DATE && (
                    <InputDate
                      value={date1}
                      onChange={setDate1}
                      classInvalid={onInvalid(errors?.value1)}
                    />
                  )}

                  {(!dataType || TablesColumnsNamesProposalsDataTypesEnum.STRING === dataType) && (
                    <input
                      type="text"
                      className={`form-control ${onInvalid(errors?.value1)}`}
                      {...register('value1')}
                    />
                  )}

                  {(
                    TablesColumnsNamesProposalsDataTypesEnum.INT === dataType ||
                    TablesColumnsNamesProposalsDataTypesEnum.DOUBLE === dataType
                  ) && (
                      <>
                        {control && (
                          <Controller
                            name="value1"
                            control={control}
                            render={({ field: { onChange, name, ref } }) => (
                              <InputCurrencyDecimal
                                ref={ref}
                                name={name}
                                prefix=""
                                placeholder="0"
                                className={`form-control ${onInvalid(errors?.value1)}`}
                                value={!Array.isArray(value1) ? value1 : ''}
                                max={9999999}
                                decimalScale={TablesColumnsNamesProposalsDataTypesEnum.DOUBLE === dataType ? 2 : 0}
                                fixedDecimalScale={TablesColumnsNamesProposalsDataTypesEnum.DOUBLE === dataType}
                                thousandSeparator={TablesColumnsNamesProposalsDataTypesEnum.DOUBLE === dataType ? '.' : ''}
                                onChange={(e: any) => {
                                  onChange(e.target.value);
                                  setValue1(TablesColumnsNamesProposalsDataTypesEnum.INT === dataType ? parseInt(e.target.value) : e.target.value);
                                }}
                                onFocus={(e: any) => {
                                  e.target.select();
                                }}
                              />
                            )}
                          />
                        )}
                      </>
                    )}
                </>
              )}
              <ErrorMessage name={'Valor'} type={errors?.value1?.type} />
            </FormGroup>
          </Col>
        )}

        {(conditionId === ProposalRuleConditionEnum.BETWEEN) && (
          <Col>
            <FormGroup className="form-group m-0">
              <label htmlFor="value2">Até *</label>
              {dataType === TablesColumnsNamesProposalsDataTypesEnum.DATE && (
                <InputDate
                  value={date2}
                  onChange={setDate2}
                  classInvalid={onInvalid(errors?.value2)}
                  minDate={date1}
                />
              )}

              {(
                !dataType ||
                TablesColumnsNamesProposalsDataTypesEnum.INT === dataType ||
                TablesColumnsNamesProposalsDataTypesEnum.STRING === dataType
              ) && (
                  <input
                    className={`form-control ${onInvalid(errors?.value2)}`}
                    {...register('value2')}
                  />
                )}

              <ErrorMessage name={'Valor'} type={errors?.value2?.type} />
            </FormGroup>
          </Col>
        )}
      </Row>
    </div>
  );
}

export default ProposalRuleChooseCondition;
