import { Dispatch, FC, SetStateAction } from 'react';
import { Card, Col, FormGroup, Row } from 'react-bootstrap';
import { useAuth } from '../../../../../providers/AuthProvider';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import ReactInputMask from 'react-input-mask';
import { CompanyModel } from '../../../../../models/Registrations/Company/CompanyModel';

interface FormGeneralInfoProps {
  errors: any;
  register: any;
  company: CompanyModel;
  setCompany: Dispatch<SetStateAction<CompanyModel>>;
}

const FormGeneralInfo: FC<FormGeneralInfoProps> = ({ errors, register, company, setCompany }) => {
  const { onInvalid } = useAuth();

  return (
    <Row className="FormGeneralInfo" data-testid="FormGeneralInfo">
      <Card>
        <Card.Header className="pb-1 px-2">
          <h5>Geral</h5>
        </Card.Header>
        <Card.Body className="p-2">
          <Row>
            <Col md={4}>
              <FormGroup className="form-group">
                <label htmlFor="name">NOME *</label>
                <input
                  className={`form-control ${onInvalid(errors?.name)}`}
                  type='text'
                  {...register('name')}
                  onChange={(e: any) => setCompany({ ...company, name: e?.target?.value })}
                />
                <ErrorMessage name={'Nome'} type={errors?.name?.type} min={3} max={100} />
              </FormGroup>
            </Col>
            <Col md={8}>
              <FormGroup className="form-group">
                <label htmlFor="corporateName">RAZÃO SOCIAL *</label>
                <input
                  className={`form-control ${onInvalid(errors?.corporateName)}`}
                  type='text'
                  {...register('corporateName')}
                  onChange={(e: any) => setCompany({ ...company, corporateName: e?.target?.value })}
                />
                <ErrorMessage name={'Razão Social'} type={errors?.corporateName?.type} min={3} max={150} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="form-group">
                <label htmlFor="acronym">SIGLA *</label>
                <input
                  className={`form-control ${onInvalid(errors?.acronym)}`}
                  type='text'
                  {...register('acronym')}
                  disabled={!!company?.id}
                />
                <ErrorMessage name={'Sigla'} type={errors?.acronym?.type} min={2} max={50} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="form-group">
                <label htmlFor="cnpj">CNPJ *</label>
                <ReactInputMask
                  className={`form-control ${onInvalid(errors?.cnpj)}`}
                  mask='99.999.999/9999-99'
                  {...register('cnpj')}
                  defaultValue={company?.cnpj}
                  onChange={(e: any) => setCompany({ ...company, cnpj: e?.target?.value })}
                  disabled={!!company?.id && !!company?.cnpj}
                />
                <ErrorMessage name={'CNPJ'} type={errors?.cnpj?.type} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="form-group">
                <label htmlFor="phone">TELEFONE *</label>
                <ReactInputMask
                  className={`form-control ${onInvalid(errors?.phone)}`}
                  mask='(99) 99999-9999'
                  {...register('phone')}
                  defaultValue={company?.phone}
                />
                <ErrorMessage name={'Telefone'} type={errors?.phone?.type} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="form-group">
                <label htmlFor="email">E-MAIL *</label>
                <input
                  className={`form-control ${onInvalid(errors?.email)}`}
                  type='text'
                  {...register('email')}
                />
                <ErrorMessage name={'E-mail'} type={errors?.email?.type} min={3} max={255} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="form-group">
                <label htmlFor="hostname">URL *</label>
                <input
                  className={`form-control ${onInvalid(errors?.hostname)}`}
                  type='text'
                  {...register('hostname')}
                  disabled={!!company?.id}
                />
                <ErrorMessage name={'URL'} type={errors?.hostname?.type} min={3} max={100} />
              </FormGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
}

export default FormGeneralInfo;
