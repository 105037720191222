import { FC, useEffect, useState } from 'react';
import './ModalVisitsTypesContactsInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useVisitsTypesContacts } from '../../../../providers/Visits/VisitsTypesContacts/VisitsTypesContactsProvider';
import { useForm } from 'react-hook-form';
import { VisitsTypesContactsModel } from '../../../../models/Visits/VisitsTypesContacts/VisitsTypesContactsModel';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useAuth } from '../../../../providers/AuthProvider';
import { toast } from 'react-toastify';

interface ModalVisitsTypesContactsInsertProps {
  show: boolean;
  onClose: () => void;
}

type FormValues = {
  id?: number;
  name?: string;
  description?: number;
}

const ModalVisitsTypesContactsInsert: FC<ModalVisitsTypesContactsInsertProps> = ({ show, onClose }) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const { typesContact, setTypesContact, setError, handleList, handleFetch } = useVisitsTypesContacts();
  const { onInvalid } = useAuth();

  const { handleSubmit, register, reset, formState: { errors } } = useForm({
    mode: "onChange",
  });

  const handleClose = () => {
    setError('');
    setTypesContact({} as VisitsTypesContactsModel);
    onClose();
  }

  const onSubmit = async (data: any) => {
    setIsLoadingSubmit(true);
    setError('');

    const ret = await handleFetch(data as VisitsTypesContactsModel);

    if (ret) {
      toast.success('Tipo de contato salva com sucesso!');
      handleList();
      onClose();
    }

    setIsLoadingSubmit(false);
  }

  useEffect(() => {
    if (show) {
      if (!!typesContact?.id) {
        reset(typesContact as FormValues);
      } else {
        reset(new VisitsTypesContactsModel());
        setTypesContact({} as VisitsTypesContactsModel);
      }
    }
  }, [show]);

  return (
    <ModalDefault
      title={!!typesContact?.id ? 'Editar Tipo de Contato' : 'Adicionar Tipo de Contato'}
      show={show}
      onClose={handleClose}
      sizeModal={'lg'}
      showFooter={true}
      buttonText={isLoadingSubmit ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoadingSubmit}
      disabledSubmit={isLoadingSubmit}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalVisitsTypesContactsInsert" data-testid="ModalVisitsTypesContactsInsert">
        <Row>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="name">NOME *</label>
              <input
                className={`form-control ${onInvalid(errors?.name)}`}
                type='text'
                {...register('name')}
                onChange={(e: any) => setTypesContact({ ...typesContact, name: e?.target?.value })}
              />
              <ErrorMessage name={'Nome'} />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="description">DESCRIÇÃO *</label>
              <textarea rows={3} className='form-control'
                {...register('description')}
                onChange={(e: any) => setTypesContact({ ...typesContact, description: e?.target?.value })}
              ></textarea>
              <ErrorMessage name={'Descrição'} />
            </FormGroup>
          </Col>

        </Row>
      </div>
    </ModalDefault >
  );
}

export default ModalVisitsTypesContactsInsert;