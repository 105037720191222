import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from "react";
import { MetricManagementService } from "../../../services/pgm/MetricManagement/MetricManagementService";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";

interface ContextProps {
    isLoading: boolean,
    metric: any,
    setMetric: Dispatch<SetStateAction<any>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: (params: any) => Promise<any>
};

export const MetricManagementContext = createContext<ContextProps>({} as ContextProps);

interface MetricManagementProps {
    children: ReactNode
};

export const MetricManagementProvider = ({ children }: MetricManagementProps)  => {

    const [metric, setMetric]       = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pages, setPages]         = useState<PagesPaginateModel>({});
    const [error, setError]         = useState<string>('');

    const service = new MetricManagementService();

    const handleList = async (params: any) => {
        try {

            setIsLoading(true); setError('');

            // const [_Response, _Error] = await service.list(params);
            await fetch('http://localhost:3000/mock/metric.json')
            .then(res => res.json())
            .then(res => setMetric(res.data));

            // if (_Error) {
            //     setMetric([]);
            //     setError(_Response?.message || _Error);
            // }

            setIsLoading(false);
            // setMetric(_Response.data);
            // setPages(_Response?.pages);
        }
        catch (e) {
            setIsLoading(false);
            setMetric([]);
            setError('Não foi possível carregar a listagem de metricas.');
        }
    }; 

    return (
        <MetricManagementContext.Provider value={useMemo(() => ({
            isLoading,
            metric,
            setMetric,
            handleList,
            error,
            setError
        }), [
            isLoading,
            metric,
            setMetric,
            handleList,
            error,
            setError
        ])}
        >
            {children}
        </MetricManagementContext.Provider>
    )
};

export const useMetricManagementProvider = () => useContext(MetricManagementContext);