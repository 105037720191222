import React, { FC, useEffect, useState } from 'react';
import './Campaign.scss';
import { useCampaign } from "../../../providers/Marketplace/Campaign/CampaignProvider";
import MasterPage from "../../../components/MasterPage/MasterPage";
import PageBase from "../../../components/PageBase/PageBase";
import { CampaignModel } from "../../../models/Marketplace/Campaign/CampaignModel";
import AppPagination from "../../../components/AppPagination/AppPagination";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";
import { dateFormat } from "../../../utils/FormatDate";
import ModalCampaignInsert from "../../../components/Marketplace/Campaign/ModalCampaignInsert/ModalCampaignInsert";
import ModalCampaignImport from "../../../components/Marketplace/Campaign/ModalCampaignImport/ModalCampaignImport";
import { faBan, faDownload, faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import Spinner from "../../../components/Spinner/Spinner";
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import { CampaignStatusEnum } from '../../../enums/Marktplace/Campaigns/CampaignStatusEnum';
import Swal, { SweetAlertResult } from 'sweetalert2';
import ModalOpportunityView from '../../../components/Marketplace/Opportunity/ModalOpportunityView/ModalOpportunityView';
import ModalCampaignImportedList from '../../../components/Marketplace/Campaign/ModalCampaignImportedList/ModalCampaignImportedList';
import ModalCampaignFilters from '../../../components/Marketplace/Campaign/ModalCampaignFilters/ModalCampaignFilters';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import SidebarFilter from '../../../components/SidebarFilter/SidebarFilter';

interface CampaignProps { }

export interface Show {
  simulation: boolean,
  campaign: boolean,
}

const Campaign: FC<CampaignProps> = () => {

  const [show, setShow] = useState<Show>({ campaign: false, simulation: false });
  const [showModalFilters, setShowModalFilters] = useState<boolean>(false);
  const [showImport, setShowImport] = useState<boolean>(false);
  const [showImportedExcel, setShowImportedExcel] = useState<boolean>(false);
  const [showOpportunities, setShowOpportunities] = useState<boolean>(false);
  const [campaignView, setCampaignView] = useState<CampaignModel | null>(null);
  const [campaignFilterView, setCampaignFilterView] = useState<number | null>(null);

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();

  const {
    campaigns,
    campaignFilter, setCampaignFilter,
    campaignPages,
    error,
    handleLoadFilters,
    handleCampaign,
    handleDisableCampaign,
    handleDownloadPathfile,
    loading, isLoadingFile
  } = useCampaign();

  useEffect(() => {
    if (showSidebarFilter) {
      setShowSidebarFilter(false)
    }
  }, [showSidebarFilter]);

  useEffect(() => {
    handleLoadFilters().then();
  }, []);

  const onSubmitDisableCampaign = (line: CampaignModel) => {
    Swal.fire({
      icon: 'question',
      title: 'Atenção!',
      html: `Deseja realmente desabilitar a campanha <b>${line.campaign_title}</b>`,
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        handleDisableCampaign(line.campaign_id ?? 0).then();
      }
    })
  }

  const renderList = () => {
    return (
      <>
        {campaigns?.length > 0 ? (
          <>
            {campaigns.map((line: CampaignModel) => (
              <tr key={line.campaign_id} className="text-sm">
                <td>{line.campaign_id}</td>
                <td>{dateFormat({ date: line.campaign_date, datetime: false })}</td>
                <td>{line.campaign_title}</td>
                <td>{line.campaign_obs}</td>
                <td>{line.campaign_pathfile ? 'Importado Excel' : 'Gerado Produção'}</td>
                <td>
                  <div className={`badge badge-sm ${line.campaign_status_id === CampaignStatusEnum.ATIVA ? 'badge-success' : 'badge-danger'}`}>
                    {line.campaign_status_name}
                  </div>
                </td>
                <td className='text-center'>{line.campaign_deadline}</td>
                <td className='text-center'>
                  <DropdownMenuTable key={line.campaign_id ?? 0}>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => { setCampaignView(line); setShowOpportunities(true); }}>
                      <FontAwesomeIcon icon={faEye} className="me-2" /> Ver Oportunidades
                    </Dropdown.Item>

                    {line?.campaign_pathfile ?
                      (
                        <Dropdown.Item className="border-radius-md py-2" onClick={() => { handleDownloadPathfile(line?.campaign_pathfile ?? ''); }}>
                          <FontAwesomeIcon icon={faDownload} className="me-2" /> Baixar Excel Importado
                        </Dropdown.Item>)
                      :
                      (
                        <Dropdown.Item className="border-radius-md py-2" onClick={() => { setShowModalFilters(true); setCampaignFilterView(line?.campaign_id ?? 0); }} >
                          <FontAwesomeIcon icon={faSearch} className="me-2" /> Ver filtro
                        </Dropdown.Item>
                      )
                    }

                    <Dropdown.Item className="border-radius-md py-2" onClick={() => onSubmitDisableCampaign(line)}>
                      <FontAwesomeIcon icon={faBan} className="me-2" /> Desabilitar Campanha
                    </Dropdown.Item>
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={6} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!loading?.listCampaign ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7" scope="col">Código</th>
                  <th className="text-uppercase opacity-7" scope="col">Data Cadastro</th>
                  <th className="text-uppercase opacity-7" scope="col">Titulo</th>
                  <th className="text-uppercase opacity-7" scope="col">Observação</th>
                  <th className="text-uppercase opacity-7" scope="col">Origem</th>
                  <th className="text-uppercase opacity-7" scope="col">Situação</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Deadline</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}

        {campaigns?.length > 0 && (
          <div>
            <nav aria-label="...">
              <AppPagination
                pages={campaignPages}
                handlePaginate={handleCampaign}
                params={campaignFilter}
                setParams={setCampaignFilter}
                style={{ backgroundColor: '#fff' }}
              />
            </nav>
          </div>
        )}
      </>
    );
  }

  const renderContentButton = (): JSX.Element => {
    return (
      <>
        <button type="button" className="btn bg-primary text-white btn-sm mb-0" onClick={() => setShowImportedExcel(true)}>
          Excel importados
        </button>
      </>
    );
  }

  useEffect(() => {
    handleCampaign();
  }, []);

  return (

    <MasterPage
      title="Campanhas"
      icon="fa-solid fa-cake-candles"
      footerFixed
      footerInverse
    >
      <div className="Campaign" data-testid="Campaign">
        <PageBase
          title="Campanhas"
          subtitle="Gerenciamento de campanhas."
          hasFilter={false}
          textButtonFilter="Filtros"
          textButtonAdd="Gerar campanha"
          textButtonAddtional="Importar campanha"
          showAddtionalButton={true}
          handleAddtional={setShowImport}
          handleInsert={() => { setShow({ ...show, simulation: true }) }}
          error={error}
          content={renderContent()}
          contentButtonLine={renderContentButton()}
        />
      </div>

      {(isLoadingFile || loading.createCampaign) && <Spinner />}

      <ModalCampaignInsert
        show={show}
        setShow={setShow}
      />

      <ModalCampaignImport
        show={showImport}
        onClose={setShowImport}
      />

      <ModalCampaignImportedList
        show={showImportedExcel}
        onClose={setShowImportedExcel}
      />

      <ModalOpportunityView
        show={showOpportunities}
        onClose={setShowOpportunities}
        campaign={campaignView}
        setCampaign={setCampaignView}
      />

      <ModalCampaignFilters
        show={showModalFilters}
        onClose={setShowModalFilters}
        campaignNumber={campaignFilterView}
        setCampaignNumber={setCampaignFilterView}
      />

      <SidebarFilter />
    </MasterPage>
  );
};

export default Campaign;
