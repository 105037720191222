import React, { FC, useEffect, useState } from 'react';
import './ChartGroupParticipation.scss';
import { useBiDashboard } from '../../../../../../providers/BI/Dashboard/BiDashboardProvider';
import { BiMonthlyProductionModel } from '../../../../../../models/BI/Dashboard/BiMonthlyProductionModel';
import { BiAnnualProductionModel } from '../../../../../../models/BI/Dashboard/BiAnnualProductionModel';
import { BI_VIEWS_ENUM } from '../../../../../../models/BI/Dashboard/BiProductionParamsModel';
import { Card, Col, Row } from 'react-bootstrap';
import PieChart from '../../../PieChart/PieChart';
import SkeletonCircle from '../../../../../SkeletonCircle/SkeletonCircle';
import { nameAbbreviation } from '../../../../../../utils/NameAbbreviation';
import { useBiGroup } from '../../../../../../providers/BI/Dashboard/BiGroupProvider';

interface ChartGroupParticipationProps { }

const ChartGroupParticipation: FC<ChartGroupParticipationProps> = () => {

  const [monthData, setMonthData] = useState<any[]>([]);
  const [annualData, setAnnualData] = useState<any[]>([]);
  const [isMonthLoading, setIsMonthLoading] = useState<boolean>(false);
  const [isAnnualLoading, setIsAnnualLoading] = useState<boolean>(false);

  const {
    monthlyGroupProduction,
    setMonthlyGroupProduction,
    annualGroupProduction,
    setAnnualGroupProduction } = useBiGroup();

  const {
    handleListMonthlyProduction,
    handleListAnnualProduction,
    params } = useBiDashboard();

  useEffect(() => {
    setIsMonthLoading(true);
    setIsAnnualLoading(true);
    handleListMonthlyProduction({
      ...params,
      view: BI_VIEWS_ENUM.MASTER_GROUP
    }).then((x: any) => {
      if (x) {
        setMonthlyGroupProduction(x);
      }
    }).finally(() => setIsMonthLoading(false));

    handleListAnnualProduction({
      ...params,
      view: BI_VIEWS_ENUM.MASTER_GROUP
    }).then((x: any) => {
      if (x) {
        setAnnualGroupProduction(x);
      }
    }).finally(() => setIsAnnualLoading(false));
  }, [params]);


  useEffect(() => {
    if (monthlyGroupProduction?.items &&
      monthlyGroupProduction?.items?.length > 0 &&
      monthlyGroupProduction?.items[0]?.periods &&
      monthlyGroupProduction?.items[0]?.periods?.length > 0
    ) {
      let _data = monthlyGroupProduction?.items?.map((item) => item?.periods ?
        { value: Number(item?.periods[item?.periods?.length - 1].totalProductionPercentage), label: nameAbbreviation(item?.name ?? '', 13) } :
        {}) ?? [];
      _data = _data?.sort((a: any, b: any) => a?.value > b?.value ? -1 : 1)?.filter((x: any, index: number) => index <= 9);
      setMonthData(_data);
    }
  }, [monthlyGroupProduction]);

  useEffect(() => {
    if (annualGroupProduction?.items &&
      annualGroupProduction?.items?.length > 0 &&
      annualGroupProduction?.items[0]?.periods &&
      annualGroupProduction?.items[0]?.periods?.length > 0
    ) {
      let _data = annualGroupProduction?.items?.map((item) => item?.periods ?
        { value: Number(item?.periods[item?.periods?.length - 1].totalProductionPercentage), label: nameAbbreviation(item?.name ?? '', 13) } :
        {}) ?? [];
      _data = _data?.sort((a: any, b: any) => a?.value > b?.value ? -1 : 1)?.filter((x: any, index: number) => index <= 9);
      setAnnualData(_data);
    }
  }, [annualGroupProduction]);

  return (
    <div className="ChartGroupParticipation" data-testid="ChartGroupParticipation">
      <Row>
        <Col xl="6" className="mb-4">
          <Card className="h-100 shandow-xs-none">
            <Card.Header className="pb-0">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h6 className="surtitle text-gray m-0 text-start">PARTICIPAÇÃO MENSAL</h6>
                </div>
                <div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              {!isMonthLoading ?
                <PieChart
                  labels={monthData?.map((item) => item?.label)}
                  data={monthData?.map((item) => item?.value?.toFixed(2))}
                  // max={Math.trunc(Math.max.apply(Math, mock?.producoes?.map(o => o?.crescimento)))}
                  height={450}
                /> :
                <SkeletonCircle width={300} />}
            </Card.Body>
          </Card>
        </Col>

        <Col xl="6" className="mb-4">
          <Card className="h-100 shandow-xs-none">
            <Card.Header className="pb-0">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h6 className="surtitle text-gray m-0 text-start">PARTICIPAÇÃO ANUAL</h6>
                </div>
                <div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              {!isAnnualLoading ?
                <PieChart
                  labels={annualData?.map((item) => item?.label)}
                  data={annualData?.map((item) => item?.value?.toFixed(2))}
                  // max={Math.trunc(Math.max.apply(Math, mock?.producoes?.map(o => o?.crescimento)))}
                  height={450}
                /> :
                <SkeletonCircle width={300} />}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ChartGroupParticipation;
