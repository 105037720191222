import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/domus/pgi/bankLogin';

export class IndicatorManagementPasswordService extends BaseService {
    async listCreatedConcluded(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/protocols/concluded/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listCreatedInProgress(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/protocols/progress/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listResetConcluded(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/reset/concluded/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listResetInProgress(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/reset/progress/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listRequest(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/protocols/waiting/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}