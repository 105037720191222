import { FC, useEffect, useState } from 'react';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import { Col, FormCheck, FormGroup, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { ProposalConfigFormFilterParams } from '../../../../models/Proposals/Config/ProposalConfigFormFilterParams';
import { useForm } from 'react-hook-form';
import { ReactSelectOptions } from '../../../../models/ReactSelectOptions';
import { useFinancialProcess } from '../../../../providers/Proposals/Process/FinancialProcessProvider';
import makeAnimated from 'react-select/animated';
import { useSidebarFilter } from '../../../../providers/SidebarFilterProvider';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import moment from 'moment';
import { useProposalConfig } from '../../../../providers/Proposals/Config/ProposalConfigProvider';
import { headersTypes } from '../../../../models/Proposals/Config/HeadersTypes';

interface SidebarProposalConfigFilterProps { }

// @ts-ignore
export const defaultValues = {
  financials: [],
  types: [],
  startDate: '',
  endDate: '',
} as ProposalConfigFormFilterParams;

const SidebarProposalConfigFilter: FC<SidebarProposalConfigFilterProps> = () => {
  const [selectedFinancial, setSelectedFinancial] = useState<ReactSelectOptions[]>([]);
  const [selectedType, setSelectedType] = useState<ReactSelectOptions[]>([]);
  const [startDate, setStartDate] = useState<any>(moment(new Date()).format('YYYY-MM-01 HH:mm:ss'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));

  const { handleList: handleListFinancials, financialsOptions } = useFinancialProcess();
  const { showSidebarFilter } = useSidebarFilter();
  const { params, setParams } = useProposalConfig();

  const animatedComponents = makeAnimated();

  const { handleSubmit, setValue } = useForm<any>({ defaultValues });

  const onSubmit = async (data: ProposalConfigFormFilterParams) => {
    data.startDate = data?.startDate ? moment(data?.startDate).format('YYYY-MM-DD') : '';
    data.endDate = data?.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : '';
    data.financials = data?.financials?.map((item: any) => item.value) ?? [];
    data.types = data?.types?.map((item: any) => item.value) ?? [];

    setParams({ ...params, ...data, ...{ page: 1 } });
  }

  useEffect(() => {
    if (showSidebarFilter) {
      handleListFinancials();
    }
  }, [showSidebarFilter]);

  useEffect(() => {
    setValue('financials', selectedFinancial);
    setValue('types', selectedType);
  }, [selectedFinancial, selectedType]);

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarProposalConfigFilter" data-testid="SidebarProposalConfigFilter">
        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="financials">FINANCEIRA</label>
              <ReactSelect
                name="financials"
                isMulti
                isClearable
                isSearchable
                options={financialsOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                value={selectedFinancial ?? ''}
                defaultValue={selectedFinancial}
                components={animatedComponents}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedFinancial(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="types">TIPO DE CABEÇALHO</label>
              <ReactSelect
                name="types"
                isMulti
                isClearable
                isSearchable
                options={headersTypes}
                placeholder="Selecione..."
                className={`form-control p-0`}
                value={selectedType ?? ''}
                defaultValue={selectedType}
                components={animatedComponents}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedType(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <div className="d-flex wrapper">
                <label htmlFor="wasSet" className="mb-0">ESTÁ CONFIGURADO?</label>
                <FormCheck className="form-check form-switch p-0 m-0 form-input-check">
                  <input
                    name="wasSet"
                    type="checkbox"
                    className='form-check-input'
                  />
                </FormCheck>
              </div>
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              startLabelText={'DATA INICIAL'}
              endLabelText={'DATA FINAL'}
            />
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  );
}

export default SidebarProposalConfigFilter;

