import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { AccessControlService } from "../../../services/Marketplace/AccessControl/AccessControlService";
import { ToastSettings } from "../../../utils/ToastSettings";
import { AccessControlModel } from "../../../models/Marketplace/AccessControl/AccessControlModel";


interface ContextProps {
    accessControl: AccessControlModel | undefined,
    setAccessControl: Dispatch<SetStateAction<AccessControlModel | undefined>>,
    accessControls: AccessControlModel[],
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleFetch: (data: any) => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>,
}

export const AccessControlContext = createContext<ContextProps>({} as ContextProps);

interface AccessControlProviderProps {
    children: ReactNode;
}

export const AccessControlProvider = ({ children }: AccessControlProviderProps) => {
    const [accessControl, setAccessControl] = useState<any>();
    const [accessControls, setAccessControls] = useState<AccessControlModel[]>([]);
    const [params, setParams] = useState<any>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new AccessControlService();

    const handleList = useCallback(async () => {

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response?.message || _Error);
            }

            setAccessControls(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar os controle de acesso do sistema.');
        }
    }, [params]);

    const handleFetch = async (data: any) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.fetch(data);
            if (!!_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setError('Houve um erro ao salvar o controle de acesso');
            return false;
        }
    }

    const handleDelete = async (uid: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(uid);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar o controle de acesso');
            return false;
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    return (
        <AccessControlContext.Provider value={{
            accessControl,
            setAccessControl,
            accessControls,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleFetch,
            handleDelete
        }}>
            {children}
        </AccessControlContext.Provider>
    );
}

export const useAccessControl = () => useContext(AccessControlContext);