import { FC, useEffect, useState } from 'react';
import './VisitsAnalysis.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import SidebarVisitsAnalysisFilter from '../../../components/Visits/VisitsAnalysis/SidebarVisitsAnalysisFilter/SidebarVisitsAnalysisFilter';
import { Card, CardBody, CardHeader, Col, Dropdown, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useVisitsAnalysis } from '../../../providers/Visits/VisitsAnalysis/VisitsAnalysisProvider';
import AppPagination from '../../../components/AppPagination/AppPagination';
import { usePaginate } from '../../../providers/PaginateProvider';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faFileExcel, faList } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import ModalVisitsList from '../../../components/Visits/VisitsAnalysis/ModalVisitsList/ModalVisitsList';
import ModalVisitsAnalysisDetails from '../../../components/Visits/VisitsAnalysis/ModalVisitsAnalysisDetails/ModalVisitsAnalysisDetails';
import SkeletonBar from '../../../components/SkeletonBar/SkeletonBar';
import { Carousel } from 'react-responsive-carousel';
import { nameAbbreviation } from '../../../utils/NameAbbreviation';
import ExportButtons from '../../../components/ExportButtons/ExportButtons';
import VisistsAnalysisBarChart from '../../../components/Visits/VisitsAnalysis/VisistsAnalysisBarChart/VisistsAnalysisBarChart';
import { useReportRequest } from '../../../providers/Reports/ReportRequest/ReportRequestProvider';
import { useAuth } from '../../../providers/AuthProvider';


interface VisitsAnalysisProps { }

const VisitsAnalysis: FC<VisitsAnalysisProps> = () => {
  const [showModalList, setShowModalList] = useState<boolean>(false);
  const [showModalDetail, setShowModalDetail] = useState<boolean>(false);
  const [userVisitor, setUserVisitor] = useState<string>("");
  const [userVisitorId, setUserVisitorId] = useState<number>(0);

  const {
    isLoading,
    isLoadingChartByRegional,
    isLoadingChartByUf,
    isLoadingChartByFocus,
    isLoadingChartByOtherActions,
    visitsAnalysis,
    visitsChartByRegional,
    visitsChartByUf,
    visitsChartByFocus,
    visitsChartByOtherActions,
    params,
    setParams,
    pages,
    handleExcel,
    handleExcelCommercialWallet,
    handleExcelRegionalWallet
  } = useVisitsAnalysis();
  const { handlePaginate } = usePaginate();
  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { isLoadingFile } = useReportRequest();

  const { userLogged } = useAuth();
  const handleModalDetail = (item: any) => {
    setShowModalDetail(true);
    setUserVisitorId(item?.id);
    setUserVisitor(`${item?.name || ''} - ${item?.partnerCode || ''}`);
  }

  const handleModalList = (item: any) => {
    setShowModalList(true);
    setUserVisitorId(item?.id);
    setUserVisitor(`${item?.name || ''} - ${item?.partnerCode || ''}`);
  }

  const renderClassStatus = (acronym: string): string => {
    switch (acronym) {
      case 'S':
        return 'badge-success';
      case 'I':
        return 'badge-warning';
      case 'N':
        return 'badge-danger';
      case 'P':
        return 'badge-info';
      case 'E':
        return 'badge-secondary';
      default:
        return 'badge-success';
    }
  }

  const renderList = (): JSX.Element => {
    return (
      <>
        {visitsAnalysis?.length > 0 ? (
          <>
            {visitsAnalysis.map((item: any, key: number) => (
              <tr key={item?.id || key} className="text-sm">
                <td className="text-center">
                  {(pages?.position?.first ?? 0) + key}
                </td>
                <td className="text-wrap">{item?.name || ''} - {item?.partnerCode || ''}</td>
                <td className="text-center">{moment(item?.createdAt).format('DD/MM/YYYY')}</td>
                <td className="text-center">
                  <div className={`badge ${renderClassStatus(item?.activeType?.acronym)}`}>{item?.activeType?.name}</div>
                </td>
                <td className="text-center">
                  {item?.visitsMadeCount > 0 ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          Detalhamento de visitas
                        </Tooltip>
                      }
                    >
                      <button type="button" className="btn btn-link m-0" onClick={() => handleModalDetail(item)}>
                        {item?.visitsMadeCount}
                      </button>
                    </OverlayTrigger>
                  ) : (
                    <>{item?.visitsMadeCount}</>
                  )}
                </td>
                <td className="text-wrap">{item?.parents?.name || ''} - {item?.parents?.partnerCode || ''}</td>
                <td className="text-center">
                  <DropdownMenuTable key={item?.id ?? 0}>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleModalList(item)}>
                      <FontAwesomeIcon icon={faList} className="me-2" /> Listar visitas
                    </Dropdown.Item>
                    {item?.visitsMadeCount > 0 && (
                      <Dropdown.Item className="border-radius-md py-2" onClick={() => handleModalDetail(item)}>
                        <FontAwesomeIcon icon={faChartBar} className="me-2" /> Detalhamento de visitas
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleExcelCommercialWallet(item?.id)}>
                      <FontAwesomeIcon icon={faFileExcel} className="me-2" /> Exportar Carteira de Agentes do Comercial
                    </Dropdown.Item>
                    {/* <Dropdown.Item className="border-radius-md py-2" onClick={() => handleExcelRegionalWallet(item?.parents?.id)}>
                      <FontAwesomeIcon icon={faFileExcel} className="me-2" /> Exportar Carteira de Agentes do Regional
                    </Dropdown.Item> */}
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={7} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        <Carousel
          interval={10000}
          statusFormatter={(currentItem: number, total: number) => `${currentItem} de ${total}`}
          showThumbs={false}
          autoPlay
          infiniteLoop
        >
          <div>
            <Row>
              <Col xl="6" className="mb-4">
                <Card className="h-100 shandow-xs-none">
                  <CardHeader className="pb-0">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h6 className="surtitle text-gray m-0 text-start">POR REGIONAL</h6>
                      </div>
                      <div>
                        <ExportButtons
                          getExcel={() => handleExcel('perRegional')}
                          isLoading={isLoadingFile}
                        />
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    {!isLoadingChartByRegional ? (
                      <VisistsAnalysisBarChart
                        labels={visitsChartByRegional?.map((item: any) => nameAbbreviation(item?.name ?? '', 13))}
                        data={visitsChartByRegional?.map((item: any) => item?.qtd)}
                        max={Math.trunc(Math.max.apply(Math, visitsChartByRegional?.map(o => o.qtd)))}
                      />
                    ) : (
                      <SkeletonBar
                        numberOfBars={14}
                        minHeight={70}
                        maxHeight={450}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>

              <Col xl="6" className="mb-4">
                <Card className="h-100 shandow-xs-none">
                  <CardHeader className="pb-0">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h6 className="surtitle text-gray m-0 text-start">POR UF <b className='text-sm'>(Visitas de Prospecção não são contabilizadas)</b></h6>
                      </div>
                      <div>
                        <ExportButtons
                          getExcel={() => handleExcel('perUf')}
                          isLoading={isLoadingFile}
                        />
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    {!isLoadingChartByUf ? (
                      <VisistsAnalysisBarChart
                        labels={visitsChartByUf?.map((item: any) => item?.description)}
                        data={visitsChartByUf?.map((item: any) => item?.qtd)}
                        max={Math.trunc(Math.max.apply(Math, visitsChartByUf?.map(o => o.qtd)) || 0)}
                      />
                    ) : (
                      <SkeletonBar
                        numberOfBars={14}
                        minHeight={70}
                        maxHeight={450}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col xl="6" className="mb-4">
                <Card className="h-100 shandow-xs-none">
                  <CardHeader className="pb-0">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h6 className="surtitle text-gray m-0 text-start">POR FOCO</h6>
                      </div>
                      <div>
                        <ExportButtons
                          getExcel={() => handleExcel('perFocus')}
                          isLoading={isLoadingFile}
                        />
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    {!isLoadingChartByFocus ? (
                      <VisistsAnalysisBarChart
                        labels={visitsChartByFocus?.map((item: any) => item?.description)}
                        data={visitsChartByFocus?.map((item: any) => item?.qtd)}
                        max={Math.trunc(Math.max.apply(Math, visitsChartByFocus?.map(o => o.qtd)))}
                      />
                    ) : (
                      <SkeletonBar
                        numberOfBars={14}
                        minHeight={70}
                        maxHeight={450}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>

              <Col xl="6" className="mb-4">
                <Card className="h-100 shandow-xs-none">
                  <CardHeader className="pb-0">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h6 className="surtitle text-gray m-0 text-start">POR DEMAIS AÇÕES</h6>
                      </div>
                      <div>
                        <ExportButtons
                          getExcel={() => handleExcel('perActivities')}
                          isLoading={isLoadingFile}
                        />
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    {!isLoadingChartByOtherActions ? (
                      <VisistsAnalysisBarChart
                        labels={visitsChartByOtherActions?.map((item: any) => item?.description)}
                        data={visitsChartByOtherActions?.map((item: any) => item?.qtd)}
                        max={Math.trunc(Math.max.apply(Math, visitsChartByOtherActions?.map(o => o.qtd)))}
                      />
                    ) : (
                      <SkeletonBar
                        numberOfBars={14}
                        minHeight={70}
                        maxHeight={450}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Carousel>

        {!isLoading ? (
          <Card >
            <Card.Header className="pb-0 px-2">
              <div className="d-grid d-md-flex justify-content-md-end mb-0">
                <button type="button" className="btn bg-success text-white mb-0"
                  onClick={() => handleExcelRegionalWallet(userLogged?.id ?? 0)}
                >
                  <i className="fa-regular fa-file-excel me-2"></i>
                  Excel
                </button>
              </div>
            </Card.Header>
            <CardBody className="pt-2 px-2 mt-0">
              <div className="table-responsive">
                <table className="table table-flush table-striped m-0 w-100">
                  <thead className="thead-light">
                    <tr role="row">
                      <th className="bg-primary text-center text-uppercase" scope="col" rowSpan={2}>RANK</th>
                      <th className="bg-primary text-center text-uppercase" scope="col" colSpan={3}>COMERCIAL</th>
                      <th className="bg-primary text-center text-uppercase" scope="col" rowSpan={2}>PARCEIROS VISITADOS</th>
                      <th className="bg-primary text-uppercase" scope="col" rowSpan={2}>REGIONAL</th>
                      <th className="bg-primary text-center text-uppercase" scope="col" rowSpan={2}>AÇÃO</th>
                    </tr>
                    <tr>
                      <th className="bg-primary text-uppercase" scope="col">NOME</th>
                      <th className="bg-primary text-center text-uppercase" scope="col">DATA DE CADASTRO</th>
                      <th className="bg-primary text-center text-uppercase" scope="col">STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderList()}
                  </tbody>
                </table>
              </div>

              {(visitsAnalysis?.length > 0) && (
                <div>
                  <nav aria-label="...">
                    <AppPagination
                      pages={pages}
                      handlePaginate={handlePaginate}
                      params={params}
                      setParams={setParams}
                      style={{ backgroundColor: '#fff' }}
                    />
                  </nav>
                </div>
              )}
            </CardBody>
          </Card >
        ) : (
          <SkeletonTable />
        )}
      </>
    );
  };

  useEffect(() => {
    setUserVisitor("");
    setUserVisitorId(0);
  }, []);

  return (
    <MasterPage
      title={'ANÁLISE DE VISITAS'}
      icon={'fas fa-unlock'}
      footerFixed
      footerInverse
    >
      <div className="VisitsAnalysis" data-testid="VisitsAnalysis">
        <PageBase
          title={'Análise de Visitas'}
          subtitle={'Painel para análise de visitas de comerciais.'}
          content={
            renderContent()
          }
          hasFilter
          hasExcelPdf
          handleFilter={() => showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          handleExcel={() => handleExcel('geral')}
          isLoadingFile={isLoadingFile}
        />
      </div>

      <SidebarVisitsAnalysisFilter />

      <ModalVisitsList
        show={showModalList}
        onClose={() => setShowModalList(false)}
        userVisitor={userVisitor}
        userVisitorId={userVisitorId}
        startDate={params?.startDate}
        endDate={params?.endDate}
      />
      <ModalVisitsAnalysisDetails
        show={showModalDetail}
        onClose={() => setShowModalDetail(false)}
        userVisitor={userVisitor}
        userVisitorId={userVisitorId}
        startDate={params?.startDate}
        endDate={params?.endDate}
      />
    </MasterPage>

  );
}

export default VisitsAnalysis;
