import React, { FC, useEffect, useMemo, useState } from "react";
import "./GeneralCommercialProductionBySupervisor.scss";
import { useBiCommercial } from "../../../../../providers/BI/Dashboard/BiCommercialProvider";
import { Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import FormatMoney from "../../../../../utils/FormatMoney";
import AppDataTable from "../../../../AppDataTable/AppDataTable";
import { useBiDashboard } from "../../../../../providers/BI/Dashboard/BiDashboardProvider";
import { BI_VIEWS_ENUM } from "../../../../../models/BI/Dashboard/BiProductionParamsModel";
import SkeletonTable from "../../../../SkeletonTable/SkeletonTable";

interface GeneralCommercialProductionBySupervisorProps { }

const BUTTONS: any = {
  monthsAmount: "Valor Meses",
  admissionDate: "Data Admissão",
  partners: "Parceiros",
  multiStores: "Multi Lojas",
  storesBelow: "Lojas Abaixo",
  average: "Média",
  partnersAverage: "Média Parceiros",
};

const GeneralCommercialProductionBySupervisor: FC<
  GeneralCommercialProductionBySupervisorProps
> = () => {
  const [columns, setColumns] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [hideButtons, setHideButtons] = useState<any[]>([]);

  const {
    generalCommercialProductionBySupervisor,
    setGeneralCommercialProductionBySupervisor,
  } = useBiCommercial();

  const {
    handleListBroadProduction,
    isLoading,
    params,
    setParams,
    setHandleListOnFilter,
    resultOnFilter,
    setResultOnFilter,
    generalErrorMsg,
    isParamsUpdated,
    setIsParamsUpdated,
    handleExcel,
    handleSort
  } = useBiDashboard();

  useEffect(() => {
    if (isParamsUpdated) {
      setHandleListOnFilter(() => handleListBroadProduction);
      setParams({
        ...params,
        view: BI_VIEWS_ENUM.REGIONAL,
        group: {
          commercial: true,
        },
      });
      if (!generalCommercialProductionBySupervisor) {
        handleFetch().then();
      }
      setIsParamsUpdated(false);
    }
  }, [isParamsUpdated]);

  useEffect(() => {
    if (resultOnFilter) {
      setGeneralCommercialProductionBySupervisor(resultOnFilter);
      setResultOnFilter(undefined);
    }
  }, [resultOnFilter]);

  const handleFetch = async () => {
    const result = await handleListBroadProduction({
      ...params,
      view: BI_VIEWS_ENUM.REGIONAL,
      group: {
        commercial: true,
      },
    }).then();

    if (result) {
      setGeneralCommercialProductionBySupervisor(result);
    }
  };

  useEffect(() => {
    mountHideButtons();
    setRows(generalCommercialProductionBySupervisor?.items ?? []);
  }, [generalCommercialProductionBySupervisor]);

  const hasData = (): boolean => {
    if (generalCommercialProductionBySupervisor) {
      return true;
    }
    return false;
  };

  const handleExport = (rows: any) => {
    const _data: any[] = [];
    rows.forEach((item: any) => {
      const _row: any = {
        "Comercial": `${item.name} - ${item.partnerCode}`,
        Regional: item.regional,
        Filial: item.branchName,
        Total: item.totalProduction,
        "Data Admissao": item.registeredAt,
        "Parceiros ativos": item.activePartners,
        "Parceiros Inativos": item.inactivePartners,
        "Total de parceiros": item.totalPartners,
        "Multi lojas ativas": item.activeMultiStore,
        "Multi lojas Inativas": item.inactiveMultiStore,
        "Total de multi lojas": item.totalMultiStore,
        "Lojas abaixo ativas": item.activeFranchises,
        "Lojas abaixo Inativas": item.inactiveFranchises,
        "Total de lojas abaixo": item.totalFranchises,
        Media: item.productionAverage,
        "Media de parceiros": item.productionAveragePerPartner,
      };

      _data.push(_row);
    });
    handleExcel(_data, "Producao geral de comercial por supervisor");
  };

  const mountHideButtons = () => {
    if (hasData()) {
      const _buttons: any = [];

      Object.keys(BUTTONS).forEach((key) => {
        _buttons.push({
          alias: key,
          label: BUTTONS[key],
          show: true,
        });
      });

      setHideButtons(_buttons);
    }
  };

  const mountColumns = useMemo(() => {
    if (hasData()) {
      const _columns: any[] = [
        {
          id: "colIndex",
          name: (
            <div className="bg-info text-table text-uppercase">
              Comercial
            </div>
          ),
          selector: (data: any) => data?.name,
          cell: (data: any) => (
            <div className="text-wrap">
              {data?.name === 'TOTAL' ? data?.name : `${data?.name} - ${data?.partnerCode}`}
            </div>
          ),
          sortable: true,
          minWidth: '230px'
        },
        {
          name: <div className="bg-info text-table text-uppercase">Filial</div>,
          selector: (data: any) => data?.branchName,
          cell: (data: any) => (
            <div className="text-wrap text-bold">{data?.branchName}</div>
          ),
          sortable: true,
          width: "250px",
        },
        {
          name: <div className="bg-info text-table text-uppercase">Total</div>,
          selector: (data: any) => data.totalProduction,
          right: true,
          cell: (data: any) => (
            <div className="text-nowrap">
              {FormatMoney(data.totalProduction)}
            </div>
          ),
          sortable: true,
          omit: !hideButtons?.find((x: any) => x.alias === "monthsAmount")
            ?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Data Admissão
            </div>
          ),
          selector: (data: any) => data.registeredAt,
          right: true,
          cell: (data: any) => (
            <div className="text-nowrap">
              {data?.name === 'TOTAL' ? '' : moment(data.registeredAt).format("DD/MM/YYYY")}
            </div>
          ),
          sortable: true,
          omit: !hideButtons?.find((x: any) => x.alias === "admissionDate")
            ?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Parceiros
              <br /> Ativos
            </div>
          ),
          selector: (data: any) => data.activePartners,
          sortable: true,
          right: true,
          omit: !hideButtons?.find((x: any) => x.alias === "partners")?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Parceiros <br />
              Inativos
            </div>
          ),
          selector: (data: any) => data.inactivePartners,
          sortable: true,
          right: true,
          omit: !hideButtons?.find((x: any) => x.alias === "partners")?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Total de <br />
              Parceiros
            </div>
          ),
          selector: (data: any) => data.totalPartners,
          sortable: true,
          right: true,
          omit: !hideButtons?.find((x: any) => x.alias === "partners")?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Multi Lojas
              <br /> Ativas
            </div>
          ),
          selector: (data: any) => data.activeMultiStore,
          sortable: true,
          right: true,
          omit: !hideButtons?.find((x: any) => x.alias === "multiStores")?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Multi Lojas
              <br /> Inativas
            </div>
          ),
          selector: (data: any) => data.inactiveMultiStore,
          sortable: true,
          right: true,
          omit: !hideButtons?.find((x: any) => x.alias === "multiStores")?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Total de
              <br /> Multi Lojas
            </div>
          ),
          selector: (data: any) => data.totalMultiStore,
          sortable: true,
          right: true,
          omit: !hideButtons?.find((x: any) => x.alias === "multiStores")?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Lojas Abaixo
              <br /> Ativas
            </div>
          ),
          selector: (data: any) => data.activeFranchises,
          sortable: true,
          right: true,
          omit: !hideButtons?.find((x: any) => x.alias === "storesBelow")?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Lojas Abaixo
              <br /> Inativas
            </div>
          ),
          selector: (data: any) => data.inactiveFranchises,
          sortable: true,
          right: true,
          omit: !hideButtons?.find((x: any) => x.alias === "storesBelow")?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Total de
              <br />
              Lojas Abaixo
            </div>
          ),
          selector: (data: any) => data.totalFranchises,
          sortable: true,
          right: true,
          omit: !hideButtons?.find((x: any) => x.alias === "storesBelow")?.show,
        },
        {
          name: <div className="bg-info text-table text-uppercase">Média</div>,
          selector: (data: any) => data.productionAverage,
          right: true,
          cell: (data: any) => (
            <div className="text-nowrap">
              {FormatMoney(data.productionAverage)}
            </div>
          ),
          sortable: true,
          omit: !hideButtons?.find((x: any) => x.alias === "average")?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Média de
              <br /> Parceiros
            </div>
          ),
          selector: (data: any) => data.productionAveragePerPartner,
          right: true,
          cell: (data: any) => (
            <div className="text-nowrap">
              {FormatMoney(data.productionAveragePerPartner)}
            </div>
          ),
          sortable: true,
          omit: !hideButtons?.find((x: any) => x.alias === "partnersAverage")
            ?.show,
        },
      ];

      setColumns(_columns);
    }
  }, [generalCommercialProductionBySupervisor, hideButtons]);

  const onClickHideButton = (button: any, index: number) => {
    const _hideButtons = [...hideButtons];
    button.show = !button.show;
    _hideButtons[index] = button;
    setHideButtons(_hideButtons);
  };

  const handleSortValue = (columns: any, sortDirection: any, regionalRows: any, index: number) => {
    const sorted = handleSort(columns, sortDirection, regionalRows);
    const _rows: any[] = [...rows];
    _rows[index].group = sorted;
    setRows(_rows);
  }

  const renderExecutive = (item: any, index: number) => {
    const _rows = rows[index]?.group?.find((x: any) => x.name === 'TOTAL') ?
      rows[index]?.group :
      [
        ...rows[index]?.group,
        {
          ...item?.total,
          name: 'TOTAL',
          branchName: ''
        }
      ];
    return (
      <Card className="mt-4">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center w-100">
            <span className="text-start text-info text-sm text-bolder">{`${item?.name} - ${item?.partnerCode}`}</span>
            <button
              className="btn bg-success text-white rounded m-0 ms-5"
              onClick={() => {
                handleExport(item?.group?.map((commercial: any) => ({
                  ...commercial,
                  regional: `${item?.name} - ${item?.partnerCode}`
                })));
              }}
            >
              <i className="fas fa-file-excel" /> Exportar
            </button>
          </div>
          <div className="mt-3">
            <AppDataTable
              columns={columns}
              rows={_rows}
              pagination={false}
              handleOrder={(columns: any, sortDirection: any, rows: any) => handleSortValue(columns, sortDirection, rows, index)}
            />
          </div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <div
      className="GeneralCommercialProductionBySupervisor"
      data-testid="GeneralCommercialProductionBySupervisor"
    >
      {!isLoading ? (
        <>
          <Col md={12} className="d-flex justify-content-end">
            <Row className="display-button mb-3">
              <div className="d-flex justify-content-between align-items-center flex-md-row">
                <div className="d-flex gap-1 justify-content-center align-items-center flex-md-row">
                  {hideButtons?.map((x: any, index: number) => (
                    <button
                      type="button"
                      className="btn bg-primary text-white mb-0 ms-2 min-width-btn text-capitalize"
                      onClick={() => onClickHideButton(x, index)}
                    >
                      <FontAwesomeIcon
                        icon={x.show ? faEye : faEyeSlash}
                        className="me-1"
                      />
                      {x.label}
                    </button>
                  ))}
                </div>
              </div>
            </Row>
          </Col>

          {rows?.length > 0 ? (
            rows?.map((item: any, index: number) =>
              renderExecutive(item, index)
            )
          ) : (
            <div className="d-flex mt-9 justify-content-center general-error-msg">
              {generalErrorMsg}
            </div>
          )}
        </>
      ) : (
        <SkeletonTable />
      )}
    </div>
  );
};

export default GeneralCommercialProductionBySupervisor;
