import React, { FC, useState } from 'react';
import './SidebarMonthlyGoalPeriodConfigurationFilter.scss';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import moment from 'moment';
import { Col, FormGroup, Row } from 'react-bootstrap';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import { useForm } from 'react-hook-form';
import { useMonthlyGoalPeriodConfiguration } from '../../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalPeriodConfigurationProvider';
import ReactSelect from 'react-select';
import { monthlyGoalAccessProfileOptions } from '../../../../models/PanelConnects/MonthlyGoalAccessProfileOptions';
import { customStyles } from '../../../../models/SelectCustomStyles';

interface SidebarMonthlyGoalPeriodConfigurationFilterProps { }

const SidebarMonthlyGoalPeriodConfigurationFilter: FC<SidebarMonthlyGoalPeriodConfigurationFilterProps> = () => {
  const [startDate, setStartDate] = useState<any>(moment(new Date).subtract(30, 'd').format('YYYY-MM-DD HH:mm:ss'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
  const [selectedAccessProfile, setSelectedAccessProfile] = useState<any>();

  const { setParams } = useMonthlyGoalPeriodConfiguration();
  const { setValue } = useForm();

  const onSubmit = () => {
    setParams({
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      accessProfileId: selectedAccessProfile?.value
    })
  }

  return (
    <SidebarFilter handleSubmit={onSubmit} >
      <div className="SidebarMonthlyGoalPeriodConfigurationFilter" data-testid="SidebarMonthlyGoalPeriodConfigurationFilter">
        <Row>
          <Col>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              startLabelText={'DATA INICIAL'}
              endLabelText={'DATA FINAL'}
              maxEndDate={moment(new Date()).add(3, 'month').format('YYYY-MM-DD')}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="form-group">
              <label>TIPO COMERCIAL *</label>
              <ReactSelect
                name='accessProfileId'
                value={selectedAccessProfile}
                isClearable
                options={monthlyGoalAccessProfileOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedAccessProfile(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  )
};

export default SidebarMonthlyGoalPeriodConfigurationFilter;
