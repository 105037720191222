import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { PartnerProvider } from '../../providers/Registrations/Partner/PartnerProvider';
import { TypeProvider } from '../../providers/Registrations/Partner/PartnerTypeProvider';
import { LevelCheckProvider } from '../../providers/Registrations/Partner/PartnerLevelCheckProvider';
import { AccessProfileProvider } from '../../providers/Registrations/Access/AccessProfileProvider';
import { ActiveTypeProvider } from '../../providers/Registrations/User/UserActiveTypeProvider';
import { TagTypeProvider } from '../../providers/Registrations/User/UserTagTypeProvider';
import { OriginTypeProvider } from '../../providers/Registrations/User/UserOriginTypeProvider';
import { CommercialProvider } from '../../providers/Registrations/User/UserCommercialProvider';
import { PartnerRegistrationUpdateProvider } from '../../providers/Registrations/PartnerRegistrationUpdate/PartnerRegistrationUpdateProvider';

interface Props {
    element?: any;
    path?: string;
}

export const PartnerRoute: FC<Props> = () => {
    return (
        <PartnerProvider>
            <PartnerRegistrationUpdateProvider>
                <TypeProvider>
                    <LevelCheckProvider>
                        <AccessProfileProvider>
                            <ActiveTypeProvider>
                                <TagTypeProvider>
                                    <OriginTypeProvider>
                                        <CommercialProvider>
                                            <Outlet />
                                        </CommercialProvider>
                                    </OriginTypeProvider>
                                </TagTypeProvider>
                            </ActiveTypeProvider>
                        </AccessProfileProvider>
                    </LevelCheckProvider>
                </TypeProvider>
            </PartnerRegistrationUpdateProvider>
        </PartnerProvider>
    );
}
