import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ReactSelectOptions } from "../../models/ReactSelectOptions";
import { toast } from "react-toastify";
import { TermsDocumentsSectorModel } from "../../models/Terms/TermsDocumentsSectorModel";
import { TermsDocumentsService } from "../../services/Terms/TermsDocumentsService";
import { PagesPaginateModel } from "../../models/Paginate/PagesPaginate";
import { TermsDocumentsParams } from "../../models/Terms/TermsDocumentsParams";
import { TermsDocumentsTypePersonModel } from "../../models/Terms/TermsDocumentsTypePersonModel";
import { TermsDocumentsStatusModel } from "../../models/Terms/TermsDocumentsStatusModel";
import {
  GetDocumentsModel,
  TermsDocumentsModel,
  TermsDocumentsUpdateModel,
} from "../../models/Terms/TermsDocumentsModel";
import { ToastSettings } from "../../utils/ToastSettings";
import { TermsDocumentsTagsModel } from "../../models/Terms/TermsDocumentsTagsModel";
import { TermsDocumentsTagsDescModel } from "../../models/Terms/TermsDocumentsTagsDescModel";

interface ContextProps {
  terms: TermsDocumentsModel[];
  tags: TermsDocumentsTagsModel[];
  documents: string | undefined;
  tagsDesc: TermsDocumentsTagsDescModel[];
  term: TermsDocumentsModel;
  setTerm: Dispatch<SetStateAction<TermsDocumentsModel>>;
  termsSector: TermsDocumentsSectorModel[];
  termsSectorOptions: ReactSelectOptions[];
  handleTermsSector: () => void;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  params: TermsDocumentsParams;
  setParams: Dispatch<SetStateAction<TermsDocumentsParams>>;
  termsStatus: TermsDocumentsStatusModel[];
  termsStatusOptions: ReactSelectOptions[];
  handleTermsStatus: () => void;
  handleList: (params: TermsDocumentsParams) => void;
  isLoading: boolean;
  pages: PagesPaginateModel;
  handleSave: (data: TermsDocumentsModel) => Promise<boolean>;
  handleUpdate: (
    data: TermsDocumentsUpdateModel,
    id: number
  ) => Promise<boolean>;
  handleListTag: () => void;
  handleDelete: (id: number) => Promise<boolean>;
  handleListTagDesc: () => void;
  handleListGetDocument: (id: number) => void;
}

export const TermsDocumentsContext = createContext<ContextProps>(
  {} as ContextProps
);

interface TermsDocumentsProviderProps {
  children: ReactNode;
}

export const TermsDocumentsProvider = ({
  children,
}: TermsDocumentsProviderProps) => {
  const [terms, setTerms] = useState<TermsDocumentsModel[]>([]);
  const [tags, setTags] = useState<TermsDocumentsTagsModel[]>([]);
  const [documents, setDocuments] = useState<string>();
  const [tagsDesc, setTagsDesc] = useState<TermsDocumentsTagsDescModel[]>([]);
  const [term, setTerm] = useState<TermsDocumentsModel>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pages, setPages] = useState<PagesPaginateModel>({});
  const [params, setParams] = useState<TermsDocumentsParams>({});
  const [error, setError] = useState<string>("");
  const [termsSector, setTermsSector] = useState<TermsDocumentsSectorModel[]>(
    []
  );
  const [termsSectorOptions, setTermsSectorOptions] = useState<
    ReactSelectOptions[]
  >([]);
  const [termsStatus, setTermsStatus] = useState<TermsDocumentsStatusModel[]>(
    []
  );
  const [termsStatusOptions, setTermsStatusOptions] = useState<
    ReactSelectOptions[]
  >([]);

  const service = new TermsDocumentsService();

  const handleSave = async (data: TermsDocumentsModel) => {
    try {
      const [_Response, _Error, _Code, _Errors] = await service.save(data);
      if (!!_Error) {
        ToastSettings(
          _Response?.message || _Error,
          "bottom-center",
          "error",
          () => {},
          _Errors
        );
        return false;
      }
      handleSaveTag(data, _Response?.data.id);
      setError("");
      return true;
    } catch (e) {
      setError("Houve um erro ao salvar o termo");
      return false;
    }
  };

  const handleUpdate = async (data: TermsDocumentsModel, id: number) => {
    try {
      const [_Response, _Error, _Code, _Errors] = await service.update(
        data,
        id
      );
      if (!!_Error) {
        ToastSettings(
          _Response?.message || _Error,
          "bottom-center",
          "error",
          () => {},
          _Errors
        );
        return false;
      }
      handleSaveTag(data, _Response?.data.id);
      setError("");
      return true;
    } catch (e) {
      setError("Houve um erro ao salvar o termo");
      return false;
    }
  };

  const handleSaveTag = async (
    data: TermsDocumentsModel,
    id: number | undefined
  ) => {
    try {
      const [_Response, _Error, _Code, _Errors] = await service.saveTag(
        data,
        id
      );
      if (!!_Error) {
        ToastSettings(
          _Response?.message || _Error,
          "bottom-center",
          "error",
          () => {},
          _Errors
        );
        return false;
      }

      setError("");
      return true;
    } catch (e) {
      setError("Houve um erro ao salvar o termo");
      return false;
    }
  };

  const handleList = useCallback(
    async (params: TermsDocumentsParams) => {
      try {
        const [_Response, _Error] = await service.list(params);

        if (_Error) {
          return setError(_Response?.message || _Error);
        }

        setTerms(_Response.data);
        setPages(_Response.pages);
        return setError("");
      } catch (e) {
        return setError("Não foi possível carregar os termos");
      }
    },
    [params]
  );

  const handleTermsSector = useCallback(async () => { return setError(''); }, []);

  const handleTermsStatus = useCallback(async () => {
    try {
      await fetch("http://localhost:3000/mock/status.json")
        .then((res) => res.json())
        .then((res) => setTermsStatus(res.data));
      // const [_Response, _Error] = await service.getTermsStatus();

      // if (_Error) {
      //     return setError(_Response?.message || _Error);
      // }

      // setTermsStatus(_Response.data);
      // return setError('');
    } catch (e) {
      return setError("Não foi possível carregar os status");
    }
  }, []);

  const handleListGetDocument = useCallback(
    async (id: number) => {
      try {
        const [_Response, _Error] = await service.listDocument(id);

        if (_Error) {
          return setError(_Response?.message || _Error);
        }

        setDocuments(_Response.data.htmlContent);
        return setError("");
      } catch (e) {
        return setError("Não foi possível carregar os documentos");
      }
    },
    [params]
  );

  const handleListTag = useCallback(async () => {
    try {
      const [_Response, _Error] = await service.listTag();

      if (_Error) {
        return setError(_Response?.message || _Error);
      }

      setTags(_Response.data);
      setPages(_Response.pages);
      return setError("");
    } catch (e) {
      return setError("Não foi possível carregar as tags");
    }
  }, [params]);

  const handleListTagDesc = useCallback(async () => {
    try {
      const [_Response, _Error] = await service.listTags();

      if (_Error) {
        return setError(_Response?.message || _Error);
      }

      setTagsDesc(_Response.data);
      setPages(_Response.pages);
      return setError("");
    } catch (e) {
      return setError("Não foi possível carregar as tags");
    }
  }, [params]);

  const handleDelete = async (id: number) => {
    try {
      setIsLoading(true);
      const [_Response, _Error] = await service.delete(id);
      setIsLoading(false);

      if (!!_Error) {
        setError(_Response?.message || _Error);
        return false;
      }

      setError("");
      return true;
    } catch (e: any) {
      setIsLoading(false);
      setError(e.response?.data?.message || "Houve um erro ao deletar o termo");
      return false;
    }
  };

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  useEffect(() => {
    handleTermsSector();
    handleTermsStatus();
    handleList(params);
    handleListTag();
    handleListTagDesc();
  }, []);

  useEffect(() => {
    setTermsSectorOptions(
      termsSector?.map((item: TermsDocumentsSectorModel) => {
        return { label: item.name, value: item.id };
      })
    );
  }, [termsSector]);

  useEffect(() => {
    setTermsStatusOptions(
      termsStatus?.map((item: TermsDocumentsStatusModel) => {
        return { label: item.name, value: item.id };
      })
    );
  }, [termsStatus]);

  return (
    <TermsDocumentsContext.Provider
      value={useMemo(
        () => ({
          terms,
          tags,
          documents,
          tagsDesc,
          term,
          setTerm,
          termsSector,
          termsSectorOptions,
          handleTermsSector,
          error,
          setError,
          params,
          setParams,
          termsStatus,
          termsStatusOptions,
          handleTermsStatus,
          handleList,
          isLoading,
          pages,
          handleSave,
          handleUpdate,
          handleListTag,
          handleDelete,
          handleListTagDesc,
          handleListGetDocument,
        }),
        [
          terms,
          tags,
          documents,
          tagsDesc,
          term,
          setTerm,
          termsSector,
          termsSectorOptions,
          handleTermsSector,
          error,
          setError,
          params,
          setParams,
          termsStatus,
          termsStatusOptions,
          handleTermsStatus,
          handleList,
          isLoading,
          pages,
          handleSave,
          handleUpdate,
          handleListTag,
          handleDelete,
          handleListTagDesc,
          handleListGetDocument,
        ]
      )}
    >
      {children}
    </TermsDocumentsContext.Provider>
  );
};

export const useTermsDocuments = () => useContext(TermsDocumentsContext);
