import * as yup from 'yup';

export const schema = yup.object().shape({
    promoter: yup.number().nullable(),
    agent_type: yup.number().nullable(),
    level: yup.number().nullable(),
    user_partner_id: yup.mixed().nullable(), 
    positive: yup
    .boolean()
    .required()
    .nullable()
    .oneOf([true, false], ''),
}).test(
    'typeError',
    'Pelo menos um dos campos deve estar preenchido',
    function (values) {
        const { promoter, agent_type, level, user_partner_id } = values;

        const hasAtLeastOne = (
            promoter !== null ||
            agent_type !== null ||
            level !== null ||
            (user_partner_id !== null && user_partner_id !== '')
        );

        if (!hasAtLeastOne) {
            return this.createError({
                path: 'promoter',
                message: 'Pelo menos um dos campos deve estar preenchido',
            });
        }


        return true;
    }
);

// import * as yup from 'yup';

// export const schema = yup.object().shape({
//     promoter: yup.number().nullable(),
//     agent_type: yup.number().nullable(),
//     level: yup.number().nullable(),
//     user_partner_id: yup.mixed().nullable(),  // Permite valores nulos ou mistos
//     positive: yup.boolean().required('O campo "Exceção" é obrigatório'),
// }).test(
//     'atLeastOneField',
//     'Pelo menos um dos campos deve estar preenchido',
//     function (values) {
//         const { promoter, agent_type, level, user_partner_id } = values;

//         // Verifica se algum dos campos possui valor
//         const hasAtLeastOne = (
//             promoter !== null ||
//             agent_type !== null ||
//             level !== null ||
//             (user_partner_id !== null && user_partner_id !== '')
//         );
        

//         return hasAtLeastOne;  // Retorna true se ao menos um campo estiver preenchido, caso contrário, retorna false
//     }
// );


