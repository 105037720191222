import React, { FC, useEffect, useState } from 'react';
import './SidebarReceiptFilter.scss';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { ReceiptFilterParams } from '../../../../models/Commissioning/Receipt/ReceiptFilterParams';
import { useReceipt } from '../../../../providers/Commissioning/Receipt/ReceiptProvider';
import { ReactSelectOptions } from '../../../../models/ReactSelectOptions';
import { customStyles } from '../../../../models/SelectCustomStyles';
import makeAnimated from 'react-select/animated';
import { useSidebarFilter } from '../../../../providers/SidebarFilterProvider';
import { useEnterprise } from '../../../../providers/Registrations/Company/EnterpriseProvider';
import { useFinancial } from '../../../../providers/Registrations/Financial/FinancialProvider';
import { useCampaign } from '../../../../providers/Commissioning/Campaign/CampaignProvider';
import { status } from '../../../../enums/Commissioning/Receipt/EnumReceiptStatus';

interface SidebarReceiptFilterProps { }

export const defaultValues = {
  financials: [],
  enterprises: [],
  receiptTypes: [],
  campaigns: []
} as ReceiptFilterParams;

const SidebarReceiptFilter: FC<SidebarReceiptFilterProps> = () => {

  const [selectedFinancial, setSelectedFinancial] = useState<ReactSelectOptions[]>([]);
  const [selectedEnterprise, setSelectedEnterprise] = useState<ReactSelectOptions[]>([]);
  const [selectedReceiptType, setSelectedReceiptType] = useState<ReactSelectOptions[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<ReactSelectOptions[]>([]);

  const [showSelectCampaign, setShowSelectCampaign] = useState<boolean>(false);
  const { showSidebarFilter } = useSidebarFilter();

  const { financialsOptions } = useFinancial();
  const { enterprisesOptions } = useEnterprise();
  const { receiptsTypeOptions } = useReceipt();
  const { campaignsOptions, handleListCampaignsOptions: handleListCampaigns } = useCampaign();

  const { handleSubmit, control, setValue } = useForm<any>({ defaultValues });
  const { params, setParams } = useReceipt();

  const animatedComponents = makeAnimated();

  const onSubmit = async (data: ReceiptFilterParams) => {
    data.financials = data?.financials && data.financials?.map((item: any) => item.value);
    data.enterprises = data?.enterprises && data.enterprises?.map((item: any) => item.value);
    data.receiptTypes = data?.receiptTypes && data.receiptTypes?.map((item: any) => item.label);
    data.campaigns = data?.campaigns && data.campaigns?.map((item: any) => item.label);

    setParams({ ...params, ...data, ...{ page: 1 } });
  }

  useEffect(() => {
    if (!!showSidebarFilter) {
      handleListCampaigns();
    }
  }, [showSidebarFilter]);

  useEffect(() => {

    setValue('financials', selectedFinancial);
    setValue('enterprises', selectedEnterprise);
    setValue('campaigns', selectedCampaign);
    setValue('receiptTypes', selectedReceiptType);

    let hasCampaign = Object.keys(selectedReceiptType.filter(item => item.value === 4)).length > 0;
    setShowSelectCampaign(hasCampaign);
  }, [selectedFinancial, selectedEnterprise, selectedCampaign, selectedReceiptType]);

  useEffect(() => {
    setSelectedCampaign(!!showSelectCampaign ? selectedCampaign : []);
    setValue('campaigns', !!showSelectCampaign ? selectedCampaign : []);
  }, [showSelectCampaign]);

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarReceiptFilter" data-testid="SidebarReceiptFilter">

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="financials">FINANCEIRA</label>
              <ReactSelect
                name="financials"
                isMulti
                isClearable
                isSearchable
                options={financialsOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                value={selectedFinancial ?? ''}
                defaultValue={selectedFinancial}
                components={animatedComponents}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedFinancial(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="enterprises">EMPRESA</label>
              <ReactSelect
                name="enterprises"
                isMulti
                isClearable
                isSearchable
                options={enterprisesOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                value={selectedEnterprise ?? ''}
                defaultValue={selectedEnterprise}
                components={animatedComponents}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedEnterprise(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="receiptTypes">TIPO RECEBIMENTO</label>
              <ReactSelect
                name="receiptTypes"
                isMulti
                isClearable
                isSearchable
                options={receiptsTypeOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                value={selectedReceiptType ?? ''}
                defaultValue={selectedReceiptType}
                components={animatedComponents}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedReceiptType(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        {showSelectCampaign && (
          <>
            <Row className="mt-1">
              <Col>
                <FormGroup className="form-group">
                  <label htmlFor="campaigns">CAMPANHA</label>
                  <ReactSelect
                    name="campaigns"
                    isMulti
                    isClearable
                    isSearchable
                    options={campaignsOptions}
                    placeholder="Selecione..."
                    className={`form-control p-0`}
                    value={selectedCampaign ?? ''}
                    defaultValue={selectedCampaign}
                    components={animatedComponents}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={(val: any) => {
                      setSelectedCampaign(val);
                    }}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        )}

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="status">STATUS</label>
              <Controller
                name="status"
                control={control}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isClearable
                    options={status}
                    placeholder="Selecione..."
                    className={`form-control p-0`}
                    components={animatedComponents}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={(val: any) => {
                      onChange(val?.value ?? null);
                    }}
                    styles={customStyles}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

      </div>
    </SidebarFilter>
  );
}

export default SidebarReceiptFilter;