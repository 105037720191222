import React, { FC, useEffect, useState } from 'react';
import './ModalDefault.scss';
import { Col, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface ModalDefaultProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
    show: boolean;
    sizeModal?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    onClose?: any;
    handleSubmit?: () => void;
    handleAdditionalSubmit?: () => void;
    buttonText?: string;
    buttonTextAdditional?: string;
    buttonBackgroundColor?: string;
    buttonAdditionalBackgroundColor?: string;
    buttonTextColor?: string;
    buttonAdditionalTextColor?: string;
    disabledSubmit?: boolean;
    disableAdditionalSubmit?: boolean;
    buttonIcon?: string;
    buttonAdditionalIcon?: string;
    showButtonSpinner?: boolean;
    showButtonAdditionalSpinner?: boolean;
    showFooter?: boolean;
    scrollable?: boolean;
    backdrop?: boolean | "static";
    backgroundColor?: string;
    classNameBody?: string;
    disableEsc?: boolean;
}

const ModalDefault: FC<ModalDefaultProps> = ({
    children,
    title,
    sizeModal = 'xl',
    show,
    onClose,
    handleSubmit,
    handleAdditionalSubmit,
    buttonText,
    buttonTextAdditional,
    buttonBackgroundColor,
    buttonAdditionalBackgroundColor,
    buttonTextColor,
    buttonAdditionalTextColor,
    buttonIcon,
    buttonAdditionalIcon,
    disabledSubmit = false,
    disableAdditionalSubmit = false,
    showButtonSpinner = true,
    showButtonAdditionalSpinner = true,
    showFooter = true,
    scrollable = false,
    backdrop = true,
    backgroundColor = "#fff",
    classNameBody = "",
    onScroll,
    disableEsc = false,
    ...rest
}) => {
    const [modal, setModal] = useState<boolean>(false);

    const handleOnClose = () => onClose(false);

    useEffect(() => {
        setModal(show);
    }, [show]);

    return (
        <Modal
            show={modal}
            className={`ModalDefault ${rest?.className}`}
            data-testid="ModalDefault"
            onHide={handleOnClose}
            size={sizeModal !== 'xxl' && sizeModal !== 'md' ? sizeModal : undefined}
            centered
            scrollable={scrollable}
            dialogClassName={`modal-${sizeModal}`}
            backdrop={backdrop}
            keyboard={!disableEsc}
        >
            <Modal.Header className="titleModal" style={{ backgroundImage: "url('/assets/img/bg.jpg')" }}>
                <Modal.Title><span dangerouslySetInnerHTML={{ __html: title }}></span></Modal.Title>
                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"
                    onClick={handleOnClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
                <div className="mask bg-primary opacity-9"></div>
            </Modal.Header>
            <Modal.Body className={`${!showFooter ? 'radius-bottom' : ''} ${classNameBody}`} style={{ padding: 30, backgroundColor: backgroundColor }}
                onScroll={onScroll}>
                <Row>
                    <Col md={12}>{children}</Col>
                </Row>
            </Modal.Body>
            {showFooter && (
                <Modal.Footer>
                    {handleAdditionalSubmit && (
                        <button type="button"
                            className={`btn ${!!buttonAdditionalBackgroundColor ? buttonAdditionalBackgroundColor : 'btn-primary'} ${!!buttonAdditionalTextColor ? buttonAdditionalTextColor : ''}`}
                            onClick={handleAdditionalSubmit}
                            disabled={disableAdditionalSubmit}>
                            {disableAdditionalSubmit && showButtonAdditionalSpinner && (
                                <FontAwesomeIcon className="me-2" icon={faSpinner} spin />
                            )}

                            <i className={`${!!buttonAdditionalIcon ? buttonAdditionalIcon : ''}`}></i>
                            <>{!!buttonTextAdditional ? buttonTextAdditional : 'Salvar'}</>
                        </button>
                    )}

                    {handleSubmit && (
                        <button type="button"
                            className={`btn ${!!buttonBackgroundColor ? buttonBackgroundColor : 'btn-primary'} ${!!buttonTextColor ? buttonTextColor : ''}`}
                            onClick={handleSubmit}
                            disabled={disabledSubmit}>
                            {disabledSubmit && showButtonSpinner && (
                                <FontAwesomeIcon className="me-2" icon={faSpinner} spin />
                            )}

                            <i className={`${!!buttonIcon ? buttonIcon : ''}`}></i>
                            <>{!!buttonText ? buttonText : 'Salvar'}</>
                        </button>
                    )}

                    <button type="button" className="btn btn-default" onClick={handleOnClose}>
                        Fechar
                    </button>
                </Modal.Footer>
            )}
        </Modal>
    );
}

export default ModalDefault;
