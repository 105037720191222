import { FC, useEffect, useState } from 'react';
import './ModalAccessProfileInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useAccessPage } from '../../../../providers/Registrations/Access/AccessPageProvider';
import { useAccessProfile } from '../../../../providers/Registrations/Access/AccessProfileProvider';
import { AccessProfileModel } from '../../../../models/Registrations/Access/AccessProfileModel';
import { AccessPageModel } from '../../../../models/Registrations/Access/AccessPageModel';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { Accordion, Card, Col, FormCheck, FormGroup, Row } from 'react-bootstrap';
import { useStyle } from '../../../../providers/Style/StyleProvider';
import { CompanyEnum } from '../../../../enums/Company/CompanyEnum';
import { toast } from "react-toastify";
import { AccessActionModel } from '../../../../models/Registrations/Access/AccessActionModel';
import moment from 'moment';
import { useCompany } from '../../../../providers/Registrations/Company/CompanyProvider';
import PopoverHelp from '../../../Home/PopoverHelp/PopoverHelp';
import { BvIcon } from 'bevi-icon';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import CustomButtonCollapse from '../../../CustomButtonCollapse/CustomButtonCollapse';
import SelectCompany from '../../Company/SelectCompany/SelectCompany';
import { useAccessApplication } from '../../../../providers/Registrations/Access/AccessApplicationProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faClose } from '@fortawesome/free-solid-svg-icons';
import TooltipItem from '../../../TooltipItem/TooltipItem';

interface ModalAccessProfileInsertProps {
    show: boolean;
    onClose?: any;
    typeAccess?: string;
}

interface FormValues {
    id?: string;
    name?: string;
    companyId: string;
    createdAt?: string;
}

const defaultValues = {
    financialId: '',
    productId: '',
    companyId: '',
} as FormValues;

const ModalAccessProfileInsert: FC<ModalAccessProfileInsertProps> = ({ show, onClose, typeAccess }) => {
    const [selectedPage, setSelectedPage] = useState<AccessPageModel>();

    const [companyId, setCompanyId] = useState<any | null>(null);
    const [itemToExpand, setItemToExpand] = useState<string | null>(null);
    const [clickPosition, setClickPosition] = useState<{ x: number, y: number }>();
    const [countScroll, setCountScroll] = useState<number>(0);

    const [selectedPages, setSelectedPages] = useState<AccessPageModel[]>([]);
    const [selectedActions, setSelectedActions] = useState<AccessActionModel[]>([]);

    const { style } = useStyle();
    const { profile, setProfile, handleSave, error, isLoading } = useAccessProfile();
    const { pages: pagesApplication } = useAccessApplication();
    const { pages, handleList: handleListPages } = useAccessPage();
    const { companysOptions } = useCompany();

    const {
        control,
        reset,
        setValue,
        handleSubmit,
        register,
        formState: { errors }
    } = useForm<FormValues>({ mode: 'onChange', defaultValues });

    const handleScroll = (event: any) => {
        if (selectedPage) {
            if (countScroll === 40) {
                setSelectedPage(undefined);
                setCountScroll(0);
            } else {
                setCountScroll((current: number) => current + 1);
            }
        }
    }

    useEffect(() => {
        !!error && Swal.fire('Ops!', `Houve ou erro ao salvar o nível de acesso.<br>${error}`, 'error');
    }, [error]);

    useEffect(() => {
        if (show) {
            handleListPages();
            if (profile?.id) {
                reset(profile as FormValues);
                setValue('createdAt', moment(profile?.createdAt).format('DD/MM/YYYY'));
                fillPagesActions(profile?.pages || []);
                setSelectedPages([...selectedPages]);
                setSelectedActions([...selectedActions]);
            } else {
                reset();
                setProfile(new AccessProfileModel());
                setValue('id', '');
                setValue('name', '');
                setValue('createdAt', '');
            }
        } else {
            setSelectedPages([]);
            setSelectedActions([]);
            setSelectedPage(undefined);
            setCountScroll(0);
        }
    }, [show]);

    useEffect(() => {
        if (profile?.id && companysOptions?.length > 0) {
            setCompanyId(companysOptions.filter((item: any) => item.value === profile.companyId)?.shift());
        }
    }, [companysOptions, profile]);

    useEffect(() => {
        setValue('companyId', companyId);
    }, [companyId]);

    const fillPagesActions = (pages: AccessPageModel[]) => {
        pages.forEach((p: AccessPageModel) => {
            selectedPages.push(p);
            p.actions?.forEach((a: AccessActionModel) => selectedActions.push(a));
            if (p.views && p.views?.length > 0) {
                fillPagesActions(p.views);
            }
        });
    }

    const clearUncheckPage = (page: AccessPageModel) => {
        setSelectedPages((current: AccessPageModel[]) => [...current.filter((x: AccessPageModel) => x.id !== page.id)]);
        setSelectedActions((current) => current.filter((x: AccessActionModel) => x.accessPageId !== page.id));
        if (page.views && page.views?.length > 0) {
            page.views.forEach((x: AccessPageModel) => clearUncheckPage(x));
        }
    }

    const onCheckPage = (checked: boolean, page: AccessPageModel) => {
        if (checked) {
            setSelectedPages((current: AccessPageModel[]) => [...current, page]);
        } else {
            clearUncheckPage(page);
        }
    }

    const onCheckAction = (checked: boolean, action: AccessActionModel) => {
        if (checked) {
            setSelectedActions((current: AccessActionModel[]) => [...current, action]);
        } else {
            setSelectedActions((current: AccessActionModel[]) => [...current.filter((x: AccessActionModel) => x.id !== action.id)]);
        }
    }

    const onCheckAllPages = (checked: boolean) => {
        if (checked) {

            let listPages = typeAccess === "desktop" ? pages : pagesApplication;

            selectedPages.length = 0;
            selectedActions.length = 0;
            fillPagesActions(listPages);
            setSelectedPages(JSON.parse(JSON.stringify(selectedPages)));
            setSelectedActions([...selectedActions]);
        } else {
            setSelectedPages([]);
            setSelectedActions([]);
        }
    }

    const onCheckAllActions = (checked: boolean) => {
        if (checked) {
            let listPages = typeAccess === "desktop" ? pages : pagesApplication;

            const allActionsPage: AccessActionModel[] = [];
            getActionsByPage(selectedPage?.id || 0, allActionsPage, listPages);
            const actionsAux = selectedActions?.filter((x: AccessActionModel) => x.accessPageId !== selectedPage?.id);
            setSelectedActions([...actionsAux, ...allActionsPage]);
        } else {
            setSelectedActions((current: AccessActionModel[]) => current?.filter((x: AccessActionModel) => x.accessPageId !== selectedPage?.id));
        }
    }

    const getActionsByPage = (pageId: number, acc: AccessActionModel[], pages: AccessPageModel[]) => {
        pages?.forEach((p: AccessPageModel) => {
            if (p?.actionsPage && p.id === pageId) {
                p?.actionsPage?.forEach((x: AccessActionModel) => acc.push(x));
            }
            if (p?.views && p?.views?.length > 0) {
                getActionsByPage(pageId, acc, p.views);
            }
        });
    }


    const mountProfile = (page: AccessPageModel) => {
        page.views = [];
        page.actions = [];
        page.views = selectedPages?.filter((x: AccessPageModel) => x.parentId === page.id);
        if (page.views?.length > 0) {
            page?.views?.forEach((x: AccessPageModel) => mountProfile(x));
        } else {
            page.actions = selectedActions?.filter((x: AccessActionModel) => x.accessPageId === page.id);
        }
    }

    const submit = async (data: any) => {
        profile.name = data.name;
        profile.companyId = data?.companyId?.value;
        profile.pages = [];
        profile.type = typeAccess;

        const _pages = selectedPages?.filter((x: AccessPageModel) => !x.parentId);
        _pages?.forEach((x: AccessPageModel) => {
            mountProfile(x);
            profile?.pages?.push(x);
        });

        const [, erro] = await handleSave(profile);
        if (!erro) {
            toast.success('Perfil de Acesso salvo!');
            onClose();
        }
    }

    const renderPages = () => {

        let listPages = typeAccess === "desktop" ? pages : pagesApplication;

        return (
            <>
                {listPages?.map((x: AccessPageModel) => (
                    <>
                        <CustomButtonCollapse key={x.id} eventKey={x?.id?.toString() || ''}
                            setItemToExpand={setItemToExpand} isExpand={!!x?.collapse}>
                            <td className="align-middle">
                                <div className="d-flex justify-content-center">
                                    <FormCheck className="form-check form-switch">
                                        <input
                                            type="checkbox"
                                            className='form-check-input'
                                            defaultChecked={!!selectedPages.find((p: AccessPageModel) => p.id === x.id)}
                                            checked={!!selectedPages.find((p: AccessPageModel) => p.id === x.id)}
                                            onChange={(e) => onCheckPage(e.target.checked, x)}
                                        />
                                    </FormCheck>
                                </div>
                            </td>
                            <td>{x.name}</td>
                            <td>{x.layout ?? x.path}</td>
                            {!x?.collapse && (
                                <td className="text-end">
                                    <button type="button" className="btn btn-outline-primary m-0"
                                        onClick={(e: any) => {
                                            setSelectedPage(x);
                                            setCountScroll(0);
                                            setClickPosition({ x: e.clientX, y: e.clientY });
                                        }}>
                                        <i className={`fas fa-cubes me-1`} />
                                        Ver ações
                                    </button>
                                </td>
                            )}
                        </CustomButtonCollapse>

                        {x?.collapse && (
                            <Accordion.Collapse eventKey={x?.id?.toString() || ''} as="tr">
                                <td colSpan={4} className="bg-dest py-3 px-4">
                                    <Card>
                                        <Card.Body>
                                            <table className="table table-flush m-0 w-100">
                                                <thead>
                                                    <tr>
                                                        <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7 text-center"
                                                            scope="col" style={{ width: 80 }}></th>
                                                        <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7"
                                                            scope="col">NOME
                                                        </th>
                                                        <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7"
                                                            scope="col">URL
                                                        </th>
                                                        <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7 text-center"
                                                            scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {renderViews(x?.views || [])}
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>
                                </td>
                            </Accordion.Collapse>
                        )}
                    </>
                ))}
            </>
        )
    }

    const renderViews = (items: AccessPageModel[]) => {
        return (
            <>
                {items?.map((x: AccessPageModel) => (
                    <>
                        <CustomButtonCollapse key={x.id} eventKey={x?.id?.toString() || ''}
                            setItemToExpand={setItemToExpand} isExpand={!!x?.collapse}>
                            <td className="align-middle" style={{ width: 80 }}>
                                <div className="d-flex justify-content-center">
                                    <FormCheck className="form-check form-switch">
                                        <input
                                            type="checkbox"
                                            className='form-check-input'
                                            value={x.id || ''}
                                            defaultChecked={!!selectedPages.find((p: AccessPageModel) => p?.id === x?.id)}
                                            checked={!!selectedPages.find((p: AccessPageModel) => p.id === x.id)}
                                            onChange={(e) => onCheckPage(e.target.checked, x)}
                                            disabled={!selectedPages.find((p: AccessPageModel) => p?.id === x.parentId)}
                                        />
                                    </FormCheck>
                                </div>
                            </td>
                            <td>{x.name}</td>
                            <td>{x?.collapse ? x?.path : x.layout}</td>

                            {!x?.collapse && (
                                <td className="text-end">
                                    <button type="button" className="btn btn-outline-primary m-0"
                                        onClick={(e) => {
                                            setSelectedPage(x);
                                            setCountScroll(0);
                                            setClickPosition({ x: e.clientX, y: e.clientY });
                                        }}>
                                        <i className={`fas fa-cubes me-1`} />
                                        Ver ações
                                    </button>
                                </td>
                            )}
                        </CustomButtonCollapse>

                        {x?.collapse && (
                            <Accordion.Collapse eventKey={x?.id?.toString() || ''} as="tr">
                                <td colSpan={4} className="bg-dest py-3 px-4">
                                    <Card>
                                        <Card.Body className="pb-0">
                                            <table className="table table-flush m-0 w-100">
                                                <thead>
                                                    <tr>
                                                        <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7 text-center"
                                                            scope="col" style={{ width: 80 }}></th>
                                                        <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7"
                                                            scope="col">NOME
                                                        </th>
                                                        <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7"
                                                            scope="col">URL
                                                        </th>
                                                        <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7 text-center"
                                                            scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {renderViews(x?.views || [])}
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>
                                </td>
                            </Accordion.Collapse>
                        )}
                    </>
                ))}
            </>
        )
    }

    const renderActions = () => {
        return (
            <>
                {selectedPage?.actionsPage?.map((x: AccessActionModel) => (
                    <tr key={x.id}>
                        <td>
                            <div className="d-flex align-items-center text-wrap gap-1">
                                <span>{x.name}</span>

                                <PopoverHelp
                                    placement="bottom"
                                    header={<>Ação: {x.name}</>}
                                    body={<>{x.description}</>}
                                >
                                    <button type="button" id="btn-help"
                                        className="btn btn-sm text-violet-60 text-sm m-0 px-2">
                                        <BvIcon name='help' className='text-violet-60' width={16} height={16} />
                                    </button>
                                </PopoverHelp>
                            </div>
                        </td>
                        <td>{x.accessActionType?.name}</td>
                        <td className="text-center align-middle">
                            <FormCheck className="form-check form-switch">
                                <input
                                    type="checkbox"
                                    className='form-check-input'
                                    onChange={(e) => onCheckAction(e.target.checked, x)}
                                    checked={!!selectedActions?.find((action: AccessActionModel) => action.id === x.id)}
                                    disabled={!selectedPages?.find((page: AccessPageModel) => page.id === x.accessPageId)}
                                />
                            </FormCheck>
                        </td>
                    </tr>
                ))}
            </>
        )
    }

    return (
        <ModalDefault
            title={profile?.id ? 'Editar nível de acesso' : 'Cadastrar um novo nível de acesso'}
            show={show}
            onClose={() => {
                setProfile(new AccessProfileModel());
                setSelectedPage(undefined);
                setCountScroll(0);
                onClose();
            }}
            sizeModal='xl'
            showFooter={true}
            handleSubmit={handleSubmit(submit)}
            buttonText={isLoading ? 'Salvando Perfil' : 'Salvar'}
            disabledSubmit={isLoading}
            scrollable={true}
            className="ModalAccessProfileInsert"
            onScroll={handleScroll}
        >
            <div data-testid="ModalAccessProfileInsert">
                <Row>
                    <Col sm={12} md={12} lg={4} xl={1}>
                        <FormGroup className="form-group">
                            <label>ID</label>
                            <input
                                type='text'
                                className="form-control"
                                disabled
                                {...register('id')}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={12} lg={8} xl={3}>
                        <FormGroup className="form-group">
                            <label>DATA DE CADASTRO *</label>
                            <input
                                className="form-control"
                                type='text'
                                disabled
                                {...register('createdAt')}
                            />
                            <ErrorMessage name={'Data de Cadastro'} type={errors?.createdAt?.type} />
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={12} lg={7} xl={5}>
                        <FormGroup className="form-group">
                            <label>NOME *</label>
                            <input
                                className="form-control"
                                type='text'
                                {...register('name', { required: true })}
                            />
                            <ErrorMessage name={'Nome'} type={errors?.name?.type} />
                        </FormGroup>
                    </Col>

                    {style?.id === CompanyEnum.BEVI && (
                        <Col sm={12} md={12} lg={5} xl={3}>
                            <FormGroup className="form-group">
                                <SelectCompany
                                    title="Promotora"
                                    inputName="companyId"
                                    control={control}
                                    setValue={setValue}
                                    register={register}
                                    errors={errors}
                                    valueSelected={companyId}
                                    setValueSelected={setCompanyId}
                                    onChangeSelect={() => {
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    )}
                </Row>

                <Row className="mt-4">
                    <Col sm={12} md={12} lg={12} xl={8}>
                        <Card>
                            <Card.Header>
                                <div className="ms-2">
                                    Páginas
                                </div>
                                <div>
                                    Todos
                                    <FormCheck className="form-check">
                                        <input
                                            type="checkbox"
                                            className='form-check-input'
                                            onChange={(e) => onCheckAllPages(e.target.checked)}
                                        />
                                    </FormCheck>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Accordion alwaysOpen>
                                    <table className="table table-responsive table-sm pointer">
                                        <thead>
                                            <tr>
                                                <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7 text-center"
                                                    scope="col" style={{ width: 80 }}></th>
                                                <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7"
                                                    scope="col">NOME
                                                </th>
                                                <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7"
                                                    scope="col">URL
                                                </th>
                                                <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7 text-center"
                                                    scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-sm">
                                            {renderPages()}
                                        </tbody>
                                    </table>
                                </Accordion>
                            </Card.Body>
                        </Card>
                    </Col>
                    {selectedPage?.actionsPage && selectedPage?.actionsPage?.length > 0 && (
                        <Col md={4}>
                            <div style={{ position: 'fixed', top: `${(clickPosition?.y || 0) - 15}px` }}>
                                <Card id="card-actions">
                                    <Card.Header>
                                        <div className="ms-2">
                                            <div>
                                                Ações
                                            </div>
                                            <TooltipItem position='top' text='Fechar'>
                                                <FontAwesomeIcon
                                                    icon={faClose}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => setSelectedPage(undefined)}
                                                />
                                            </TooltipItem>
                                        </div>
                                        <div>
                                            Todos
                                            <FormCheck className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className='form-check-input'
                                                    onClick={(e: any) => onCheckAllActions(e.target.checked)}
                                                    checked={selectedPage?.actionsPage?.map((x: AccessActionModel) => x.id)
                                                        ?.every((x: any) => selectedActions?.map((y: AccessActionModel) => y.id)?.includes(x))}

                                                    disabled={!selectedPages?.find((x: AccessPageModel) => x.id === selectedPage?.id)}
                                                />
                                            </FormCheck>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className="pb-0">
                                        <table className="table table-responsive table-sm m-0">
                                            <thead>
                                                <tr>
                                                    <th>NOME</th>
                                                    <th>TIPO</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-sm">
                                                {renderActions()}
                                            </tbody>
                                        </table>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
        </ModalDefault>
    );
}

export default ModalAccessProfileInsert;
