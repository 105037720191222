import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IndicatorManagementWalletModel } from "../../../models/Visits/IndicatorManagement/IndicatorManagementWalletModel";
import { IndicatorManagementWalletParams } from "../../../models/Visits/IndicatorManagement/IndicatorManagementWalletParams";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { IndicatorManagementWalletService } from "../../../services/Visits/IndicatorManagement/IndicatorManagementWalletService";
import { toast } from "react-toastify";
import { ToastSettings } from "../../../utils/ToastSettings";
import { useReportRequest } from "../../Reports/ReportRequest/ReportRequestProvider";

interface ContextProps {
    wallet: IndicatorManagementWalletModel,
    setWallet: Dispatch<SetStateAction<IndicatorManagementWalletModel>>,
    wallets: IndicatorManagementWalletModel | undefined,
    setWallets: Dispatch<SetStateAction<IndicatorManagementWalletModel | undefined>>,
    params: IndicatorManagementWalletParams,
    setParams: Dispatch<SetStateAction<IndicatorManagementWalletParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleDownload: (params: IndicatorManagementWalletParams) => void,
    handleGetWallet: (params: IndicatorManagementWalletParams) => Promise<any>,
    handleDownloadSub: (id: number | undefined) => Promise<boolean>,
}

export const IndicatorManagementWalletContext = createContext<ContextProps>({} as ContextProps);

interface IndicatorManagementWalletProviderProps {
    children: ReactNode;
}

export const IndicatorManagementWalletProvider = ({ children }: IndicatorManagementWalletProviderProps) => {
    const [wallet, setWallet] = useState<IndicatorManagementWalletModel>({} as IndicatorManagementWalletModel);
    const [wallets, setWallets] = useState<IndicatorManagementWalletModel>();
    const [params, setParams] = useState<IndicatorManagementWalletParams>({ withPaginate: false, page: 1, perPage: 10 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const service = new IndicatorManagementWalletService();
    const { setIsLoadingFile } = useReportRequest();

    const handleGetWallet = useCallback(async () => {
        try {
            if (params?.commercialId) {
                setIsLoading(true);

                const [_Response, _Error] = await service.list(params);

                if (_Error) {
                    setIsLoading(false);
                    setError(_Error);
                    setWallets(undefined);
                    return;
                }

                setWallets(_Response.data?.results ? _Response.data : undefined);
                setPages(_Response.pages)
                setIsLoading(false);
                setError('');

                return;
            }

        } catch (error: any) {
            setIsLoading(false);
            setError('Houve um erro ao listar a Produção por Faixa');
            return;
        }
    }, [params]);


    const handleDownload = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.export(params);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error, 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    }

    const handleDownloadSub = async (id: number | undefined) => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.exportSub({ partner: id });

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error, 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
        return true;
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        handleGetWallet().then();
    }, [handleGetWallet]);

    return (
        <IndicatorManagementWalletContext.Provider value={useMemo(() => ({
            wallet,
            setWallet,
            wallets,
            setWallets,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleDownload,
            handleGetWallet,
            handleDownloadSub,
        }), [
            wallet,
            setWallet,
            wallets,
            setWallets,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleDownload,
            handleGetWallet,
            handleDownloadSub,
        ])
        }>
            {children}
        </IndicatorManagementWalletContext.Provider>
    );
}

export const useIndicatorManagementWalletVisits = () => useContext(IndicatorManagementWalletContext);