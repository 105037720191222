import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { StyleService } from "../../services/Style/StyleService";
import { StyleModel } from "../../models/StyleModel";
import { useLocation } from "react-router-dom";

interface ContextProps {
    style: StyleModel | undefined,
    isLoading: boolean,
    error: string,
    handleStyle: () => Promise<any>,
    urlDomus: string,
}

export const StyleContext = createContext<ContextProps>({} as ContextProps);

interface StyleProviderProps {
    children: ReactNode,
}

export const StyleProvider = ({ children }: StyleProviderProps) => {
    const [style, setStyle] = useState<StyleModel>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [urlDomus, setUrlDomus] = useState<string>('');

    const service = new StyleService();

    const location = useLocation();

    useEffect(() => {
        handleStyle().then();
    }, []);

    useEffect(() => {
        if (!!style) {
            const root = document.documentElement;
            root.style.setProperty('--primary', style?.colorPrimary || '');
            root.style.setProperty('--secondary', style?.colorSecondary || '');
            root.style.setProperty('--progress-circle', style?.colorPrimary || '');
            document.getElementById('favicon')?.setAttribute('href', style?.favicon || '');
            document.title = `Sistema ${style?.name}` || '';

            if (style?.id) {
                localStorage.setItem('@lacquaGui:company', style?.id.toString());
            }

            const _url         = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? process.env.REACT_APP_DOMUS_DEV : `https://${style?.hostnameDomus}`;
            const originAccess = localStorage.getItem('@lacquaGui:origin');
            
            setUrlDomus(originAccess || (_url || ''));
        }
    }, [style]);

    const handleStyle = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.getStyle(window.location.hostname);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setStyle(_Response.data);
            setError('');
            return true;

        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar o estilo.');
            return false;
        }
    }

    return (
        <StyleContext.Provider value={{
            style,
            isLoading,
            error,
            handleStyle,
            urlDomus
        }}>
            {children}
        </StyleContext.Provider>
    );
};

export const useStyle = () => useContext(StyleContext);