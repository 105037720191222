import axios from "axios";
import Cookies from "js-cookie";

const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
};

const responseErrorHandler = async (error: any) => {
    if (error.response.status === 401) {
        if (localStorage !== null) {
            localStorage.removeItem('@lacquaGui:user');
        }
        return (
            window.location.pathname !== `/` && (window.location.href = `/`)
        );
    }

    if (error.response.status === 403) {
        return (
            window.location.pathname !== `/unauthorized` && (window.location.href = `/unauthorized`)
        );
    }

    return Promise.reject(error);
};

const api = axios.create({
    baseURL: process.env.REACT_APP_LACQUA_API,
    headers: headers,
    withCredentials: true,
});

api.interceptors.request.use(
    async (request) => {
        let token;
        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
            token = (await localStorage.getItem("@lacquaGui:auth")) || "";
        } else {
            token = (await Cookies.get("@lacquaGui:auth")) || "";
        }

        if (token && token !== 'undefined') {
            if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
                token = JSON.parse(token);
            }

            if (request.headers && !request.headers?.Authorization) {
                request.headers.Authorization = `Bearer ${token}`;
            }
        }

        if (request.headers) {
            request.headers["company"] = localStorage.getItem("@lacquaGui:company") || '';
        }

        return request;
    },
    (error) => console.log(error)
);

api.interceptors.response.use(
    async (request) => {
        return request;
    },
    (error) => responseErrorHandler(error)
);

export default api;
