import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { toast } from "react-toastify";
import { VisitsRankingService } from "../../../services/Visits/VisitsRanking/VisitsRankingService";
import { VisitsRankingParams } from "../../../models/Visits/VisitsRanking/VisitsRankingParams";
import { VisitsRankingModel } from "../../../models/Visits/VisitsRanking/VisitsRankingModel";

interface ContextProps {
    ranking: VisitsRankingModel,
    setRanking: Dispatch<SetStateAction<VisitsRankingModel>>,
    rankings: VisitsRankingModel | undefined,
    params: VisitsRankingParams,
    setParams: Dispatch<SetStateAction<VisitsRankingParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: (params: VisitsRankingParams) => Promise<any>,
}

export const VisitsRankingContext = createContext<ContextProps>({} as ContextProps);

interface VisitsRankingProviderProps {
    children: ReactNode;
}

export const VisitsRankingProvider = ({ children }: VisitsRankingProviderProps) => {

    const [ranking, setRanking] = useState<VisitsRankingModel>({} as VisitsRankingModel);
    const [rankings, setRankings] = useState<VisitsRankingModel>();

    const [params, setParams] = useState<VisitsRankingParams>({} as VisitsRankingParams);
    const [pages, setPages] = useState<PagesPaginateModel>({});

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new VisitsRankingService();

    const handleList = async (params: VisitsRankingParams) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            const agentHealthInsurance = _Response?.data?.map((x: any) => x?.agentHealthInsurance)?.flat(1);
            const agentFinancial = _Response?.data?.map((x: any) => x?.agentFinancial)?.flat(1);
            const agentFormOfContract = _Response?.data?.map((x: any) => x?.agentFormOfContract)?.flat(1);
            const agentRanking = _Response?.data?.map((x: any) => x?.agentRanking)?.flat(1);

            setRankings({ agentHealthInsurance, agentFinancial, agentFormOfContract, agentRanking });
            setPages(_Response?.pages);
            return true;
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar o ranking');
            return false;
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    return (
        <VisitsRankingContext.Provider value={useMemo(() => ({
            ranking,
            setRanking,
            rankings,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList
        }), [ranking,
            setRanking,
            rankings,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList])}>
            {children}
        </VisitsRankingContext.Provider>
    );
}

export const useVisitsRanking = () => useContext(VisitsRankingContext);