import { ContractFormConfigListModel } from "../../../models/Tools/Configurations/ContractForm/ContractFormConfigListModel";
import { ContractFormConfigParamsModel } from "../../../models/Tools/Configurations/ContractForm/ContractFormConfigParamsModel";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/services/proposals/proposal/contractForm/type';

export class ContractFormConfigService extends BaseService {

    async list(params: ContractFormConfigParamsModel): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async save(params: ContractFormConfigListModel): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/save`, {
                id: params.id,
                contractFormId: params.contractFormId
            }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async delete(id: number): Promise<any> {
        try {
            return this.handleResponse(await api.delete(`${URL}/delete`, { data: { id } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

}