import React, { FC } from 'react';
import './VisitsRegisterBarChart.scss';
import { Bar } from 'react-chartjs-2';

interface VisitsRegisterBarChartProps {
  labels: string[],
  data: any,
  max: number,
  height?: number,
}

const VisitsRegisterBarChart: FC<VisitsRegisterBarChartProps> = ({ labels, data, max, height }) => {
  return (
    <div className="VisitsRegisterBarChart" data-testid="VisitsRegisterBarChart">
      <Bar
        data={{
          labels: labels,
          datasets: data,
        }}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
            datalabels: {
              color: '#333',
              backgroundColor: '#fff',
              font: {
                weight: 'bold',
                size: 10,
              },
              borderRadius: 3,
              anchor: 'end',
              align: 'top',
              clamp: true
            },
            tooltip: {
              enabled: true,
              callbacks: {
                label: (data) => {
                  return 'Quantidade: ' + data.raw;
                }
              }
            }
          },
          scales: {
            x: {
              grid: {
                display: false,
              },

              ticks: {
                font: {
                  weight: 'bold'
                }
              }
            },
            y: {
              grid: {
                display: false,
              },
              min: 0,
              max: max,
              ticks: {
                stepSize: 5
              },
              border: {
                display: true
              }
            },
          },
          layout: {
            padding: {
              top: 25
            }
          }
        }}
        height={height}
      />
    </div>
  )
};

export default VisitsRegisterBarChart;
