import React, { Dispatch, FC, SetStateAction } from 'react';
import './ConditionSelectMonthlyGoal.scss';
import { useMonthlyGoalCondition } from '../../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalConditionProvider';
import { FormGroup } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';

interface ConditionSelectMonthlyGoalProps {
  label?: string,
  selectedCondition: any,
  setSelectedCondition: Dispatch<SetStateAction<any>>,
  isMult?: boolean,
  isClearable?: boolean
}

const ConditionSelectMonthlyGoal: FC<ConditionSelectMonthlyGoalProps> = ({
  label = 'CONDIÇÃO',
  selectedCondition,
  setSelectedCondition,
  isClearable = false,
  isMult = false }) => {
  const { conditionsOptions } = useMonthlyGoalCondition();

  return (
    <div className="ConditionSelectMonthlyGoal" data-testid="ConditionSelectMonthlyGoal">
      <FormGroup className="form-group">
        <label htmlFor='condition'>{label}</label>
        <ReactSelect
          name='condition'
          value={selectedCondition}
          isSearchable
          isClearable={isClearable}
          isMulti={isMult}
          options={conditionsOptions}
          placeholder="Selecione..."
          className={`form-control p-0`}
          noOptionsMessage={() => 'Não há registros'}
          onChange={(val: any) => {
            setSelectedCondition(val);
          }}
          styles={customStyles}
        />
      </FormGroup>
    </div>
  )
};

export default ConditionSelectMonthlyGoal;
