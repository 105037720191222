import { FC, useEffect, useState } from 'react';
import './VisitsTypesActivities.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import { useVisitsTypesActivities } from '../../../providers/Visits/VisitsTypesActivities/VisitsTypesActivitiesProvider';
import { usePaginate } from '../../../providers/PaginateProvider';
import AppPagination from '../../../components/AppPagination/AppPagination';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import { VisitsTypesActivitiesModel } from '../../../models/Visits/VisitsTypesActivities/VisitsTypesActivitiesModel';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import ModalVisitsTypesActivitiesInsert from '../../../components/Visits/VisitTypesActivities/ModalVisitsTypesActivitiesInsert/ModalVisitsTypesActivitiesInsert';
import SidebarVisitsTypesActivitiesFilter from '../../../components/Visits/VisitTypesActivities/SidebarVisitsTypesActivitiesFilter/SidebarVisitsTypesActivitiesFilter';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import moment from 'moment';

interface VisitsTypesActivitiesProps { }

const VisitsTypesActivities: FC<VisitsTypesActivitiesProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { params, setParams, pages, typesActivities, setTypesActivitie, handleList, handleDelete, error, isLoading } = useVisitsTypesActivities();
  const { handlePaginate } = usePaginate();

  useEffect(() => {
    handleList();
  }, []);

  const handleEdit = (item: VisitsTypesActivitiesModel) => {
    setTypesActivitie(item);
    setShowModalInsert(true);
  }

  const handleDeleteVisitsTypesActivities = (item: VisitsTypesActivitiesModel) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja excluir o foco ${item.name}?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleDelete(item.id || 0).then();
        if (ret) {
          toast.success('Foco inativado com sucesso!');
          handleList();
        } else {
          await Swal.fire('Ops!', `Não foi possível inativar o foco.<br>${error}`, 'error');
        }
      }
    });
  }

  const renderList = (): JSX.Element => {
    return (
      <>
        {typesActivities?.length > 0 ? (
          <>
            {typesActivities.map((x: VisitsTypesActivitiesModel, key: number) => (
              <tr key={x.id} className="text-sm">
                <td className="text-center">{x.id}</td>
                <td>{x.name}</td>
                <td>{x.description}</td>
                <td className="text-center">{moment(x.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td className="text-center">
                  <span className={`badge ${!!x.deletedAt ? 'badge-danger' : 'badge-success'}`}>{!!x.deletedAt ? 'Inativo' : 'Ativo'}</span>
                </td>
                <td>
                  <DropdownMenuTable key={key}>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleEdit(x)}>
                      <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
                    </Dropdown.Item>
                    {!x?.deletedAt && (
                      <Dropdown.Item className="border-radius-md py-2" onClick={() => handleDeleteVisitsTypesActivities(x)}>
                        <FontAwesomeIcon icon={faTrash} className="me-2" /> Remover
                      </Dropdown.Item>
                    )}
                  </DropdownMenuTable>
                </td>

              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={6} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive" style={{ minHeight: '200px' }}>
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7 text-center" scope="col">#</th>
                  <th className="text-uppercase opacity-7" scope="col">NOME</th>
                  <th className="text-uppercase opacity-7" scope="col">DESCRIÇÃO</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">DATA DE CADASTRO</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">STATUS</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">AÇÃO</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}

        {typesActivities?.length > 0 && (
          <div>
            <nav aria-label="...">
              <AppPagination
                pages={pages}
                handlePaginate={handlePaginate}
                params={params}
                setParams={setParams}
                style={{ backgroundColor: '#fff' }}
              />
            </nav>
          </div>
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="Foco"
      icon="fas fa-building-columns"
      footerFixed
      footerInverse
    >
      <div className="VisitsTypesActivities" data-testid="VisitsTypesActivities">
        <PageBase
          title="Foco"
          subtitle="Gerenciamento de focos de visitas."
          handleInsert={() => setShowModalInsert(true)}
          handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          error={error}
          content={renderContent()}
          hasFilter
        />

        <ModalVisitsTypesActivitiesInsert
          show={showModalInsert}
          onClose={() => setShowModalInsert(false)}
        />
      </div>

      <SidebarVisitsTypesActivitiesFilter />
    </MasterPage>
  );
}

export default VisitsTypesActivities;
