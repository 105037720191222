import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './ModalMonthlyGoalInsertRule.scss';
import { MonthlyGoalOperationCondition, MonthlyGoalScore, MonthlyGoalScoreCondition } from '../../../../models/PanelConnects/MonthlyGoalModel';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { Card, Col, FormGroup, Row } from 'react-bootstrap';
import PopoverHelp from '../../../Home/PopoverHelp/PopoverHelp';
import { BvIcon } from 'bevi-icon';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus, faCirclePlus, faPlus } from '@fortawesome/free-solid-svg-icons';
import ConditionSelectMonthlyGoal from '../ConditionSelectMonthlyGoal/ConditionSelectMonthlyGoal';
import { MonthlyGoalConditionsEnum } from '../../../../enums/PanelConnects/MonthlyGoalConditionsEnum';
import { useMonthlyGoalCondition } from '../../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalConditionProvider';
import TooltipItem from '../../../TooltipItem/TooltipItem';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validate';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import ModalMonthlyGoalInsertOperation from '../ModalMonthlyGoalInsertOperation/ModalMonthlyGoalInsertOperation';
import Swal from 'sweetalert2';

interface ModalMonthlyGoalInsertRuleProps {
  show: boolean,
  onClose: any,
  rules: MonthlyGoalScore[],
  setRules: Dispatch<SetStateAction<MonthlyGoalScore[]>>,
  indexRuleEdit?: number
}

const andOrOptions: any[] = [
  { value: 'or', label: 'OU - Se encaixa ao menos em uma condição' },
  { value: 'and', label: 'E - Deve se encaixar em todas as condições' }
];

const ModalMonthlyGoalInsertRule: FC<ModalMonthlyGoalInsertRuleProps> = ({ show, onClose, rules, setRules, indexRuleEdit }) => {
  const [conditionsAdd, setConditionsAdd] = useState<MonthlyGoalScoreCondition[]>([]);
  const [selectedAndOr, setSelectedAndOr] = useState<any>();

  const [hasOperations, setHasOperations] = useState<boolean>(false);
  const [showOperations, setShowOperations] = useState<boolean>(false);
  const [operations, setOperations] = useState<any[]>([]);

  const [isProportional, setIsProportional] = useState<boolean>(false);

  const [selectedCondition, setSelectedCondition] = useState<any>();
  const [selectedTag, setSelectedTag] = useState<any>();
  const [tagObservation, setTagObservation] = useState<string>('');

  const [optionsValue, setOptionsValue] = useState<any[]>([]);
  const [value1, setValue1] = useState<any>();
  const [value2, setValue2] = useState<any>();
  const [selectedTagValue, setSelectedTagValue] = useState<any>();
  const [tagValueObservation, setTagValueObservation] = useState<string>('');
  const [valueType, setValueType] = useState<'value' | 'tag'>('value');

  const {
    handleScoreTag,
    calculableScoreTagsOptions,
    conditionsOptions,
    operationsOptions
  } = useMonthlyGoalCondition();
  const {
    setValue,
    handleSubmit,
    formState: { errors },
    clearErrors
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  useEffect(() => {
    handleScoreTag();
  }, []);

  useEffect(() => {
    if (show) {
      setHasOperations(false);
      setValueType('value');
      setIsProportional(false);
    }
  }, [show]);

  useEffect(() => {
    if (indexRuleEdit !== undefined) {
      const _rule = { ...rules[indexRuleEdit] };
      if (_rule?.conditions && _rule?.conditions?.length > 0) {
        setSelectedAndOr(andOrOptions?.find((x: any) => x.value === _rule?.conditions![0]?.type));
        setConditionsAdd(_rule.conditions);
      }
    } else {
      setConditionsAdd([]);
    }
  }, [indexRuleEdit]);

  useEffect(() => {
    setValue('logicCondition', selectedAndOr);
  }, [selectedAndOr]);

  useEffect(() => {
    setValue('hasOperation', hasOperations);
    if (hasOperations) {
      setSelectedTag(null);
    } else {
      setOperations([]);
    }
  }, [hasOperations]);

  useEffect(() => {
    setValue('proportional', isProportional);
  }, [isProportional]);

  useEffect(() => {
    setValue('valueType', valueType);
    if (valueType === 'tag') {
      setValue1(null);
      setValue2(null);
    } else {
      setSelectedTagValue(null);
    }
  }, [valueType]);

  useEffect(() => {
    setValue('tag', selectedTag);
    if (selectedTag) {
      setTagObservation(selectedTag?.observation);
    } else {
      setTagObservation('');
    }
  }, [selectedTag]);

  useEffect(() => {
    setValue('condition', selectedCondition);
  }, [selectedCondition]);

  useEffect(() => {
    setValue('value1', value1);
  }, [value1]);

  useEffect(() => {
    setValue('value2', value2);
  }, [value2]);

  useEffect(() => {
    setValue('tagValue', selectedTagValue);
    if (selectedTagValue) {
      setTagValueObservation(selectedTagValue?.observation);
    } else {
      setTagValueObservation('');
    }
  }, [selectedTagValue]);

  const handleClose = () => {
    clearForm();
    setSelectedAndOr(null);
    setConditionsAdd([]);
    onClose();
  }

  const deleteCondition = (index: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Atenção!',
      html: `Deseja realmente remover a condição?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((value) => {
      if (value.isConfirmed) {
        setConditionsAdd((current) => current?.filter((x, i) => i !== index));
        toast.success('Condição removida com sucesso!');
      }
    });
  }

  const renderValue = () => {
    switch (selectedCondition?.value as MonthlyGoalConditionsEnum) {
      case MonthlyGoalConditionsEnum.DIFERENTE_DE:
      case MonthlyGoalConditionsEnum.IGUAL:
      case MonthlyGoalConditionsEnum.MAIOR_IGUAL_A:
      case MonthlyGoalConditionsEnum.MENOR_QUE:
        return (
          <input
            name='value1'
            className='form-control'
            value={value1}
            onChange={(value) => setValue1(value.target.value)}
          />);
      case MonthlyGoalConditionsEnum.ENTRE:
        return (
          <div className='d-flex align-items-center gap-1'>
            <input
              name='value1'
              className='form-control'
              value={value1}
              onChange={(value) => setValue1(value.target.value)}
            />
            <span> e </span>
            <input
              name='value2'
              className='form-control'
              value={value2}
              onChange={(value) => setValue2(value.target.value)}
            />
          </div>
        );
      case MonthlyGoalConditionsEnum.CONTIDO_EM:
        return (
          <ReactSelect
            name='value1'
            isClearable
            isSearchable
            isMulti
            placeholder="Selecione..."
            options={optionsValue}
            className={`form-control p-0`}
            noOptionsMessage={() => 'Não há registros'}
            value={value1}
            onChange={(val: any) => {
              setValue1(val);
            }}
            styles={customStyles}
          />
        );
      default:
        break;
    }
  }

  const renderOperations = (operationsRender: MonthlyGoalOperationCondition[]) => {
    if (operationsRender?.length > 0) {
      return (
        <div>
          {operationsRender?.map((x, index) => (
            <span key={index}>
              <span className='badge bg-success mb-1'>
                {calculableScoreTagsOptions?.find((y: any) => y.value === x.scoreTagId)?.label}
              </span>
              <span className='ps-1 pe-1 text-lg font-weight-bolder'>
                {(index + 1) < operationsRender?.length && operationsOptions?.find((x: any) => x.value === operationsRender[0].operationId)?.operator}
              </span>
            </span>
          ))}
        </div>
      );
    } else {
      return (<></>);
    }
  }

  const renderListConditions = () => {
    return (
      <Card>
        <Card.Body>
          <div className='table-responsive'>
            <table className='table table-striped text-sm'>
              <thead>
                <tr>
                  <th>Tag</th>
                  <th>Operações</th>
                  <th>Condição</th>
                  <th>Valor</th>
                  <th>Proporcional</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody>
                {conditionsAdd && conditionsAdd?.length > 0 ?

                  conditionsAdd?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {calculableScoreTagsOptions?.find((x) => x.value === item.scoreTagId)?.label}
                      </td>
                      <td className='text-wrap'>
                        {renderOperations(item.operations ?? [])}
                      </td>
                      <td>
                        {conditionsOptions?.find((x) => x.value === item.connectGoalConditionId)?.label}
                      </td>
                      <td>
                        <div className='d-flex flex-column'>
                          {item.value1 ? <span><b>Valor 1 =</b> {item.value1}</span> : null}
                          {item.value2 ? <span><b>Valor 2 =</b> {item.value2}</span> : null}
                          {item.valueTag ? <span><b>Tag:</b> {calculableScoreTagsOptions?.find((x) => x.value === item.valueTag)?.label}</span> : null}
                        </div>
                      </td>
                      <td>
                        <span className={`badge ${item.proportional ? 'bg-info' : 'bg-secondary'}`}>
                          {item.proportional ? 'Sim' : 'Não'}
                        </span>
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faCircleMinus}
                          className='cursor-pointer'
                          onClick={() => deleteCondition(index)}
                        />
                      </td>
                    </tr>
                  )) :
                  <tr>
                    <td colSpan={8} className='text-center'>Não existem condições para listar!</td>
                  </tr>
                }
              </tbody>
            </table>
          </div >
        </Card.Body>
      </Card>
    );
  }

  const clearForm = () => {
    setHasOperations(false);
    setIsProportional(false);
    setValueType('value');
    setSelectedTag(null);
    setSelectedCondition(null);
    setValue1(undefined);
    setValue2(undefined);
    setSelectedTagValue(null);
    setOperations([]);
    clearErrors();
  }

  const insertCondition = (data: any) => {
    if (!hasOperations || (hasOperations && operations?.length > 0)) {
      const _data: MonthlyGoalScoreCondition = {};
      _data.type = selectedAndOr?.value;
      _data.operations = operations;
      _data.proportional = isProportional;
      _data.scoreTagId = selectedTag?.value;
      _data.connectGoalConditionId = selectedCondition?.value;
      _data.value1 = value1;
      _data.value2 = value2;
      _data.valueTag = selectedTagValue?.value;
      _data.result = null;

      setConditionsAdd((current) => [...current ?? [], _data])
      clearForm();
    } else {
      toast.warning('Informe uma Operação!');
    }
  }

  const onSubmit = () => {
    if (conditionsAdd?.length > 0) {
      const _conditionsAdd = conditionsAdd?.map((x) => (
        { ...x, type: selectedAndOr?.value }
      ));

      if (indexRuleEdit === undefined) {
        setRules([...rules, {
          conditions: _conditionsAdd
        }]);
        toast.success('Regra adicionada!');
      } else {
        const _rules = [...rules];
        _rules[indexRuleEdit] = {
          id: _rules[indexRuleEdit]?.id,
          conditions: _conditionsAdd
        };
        setRules(_rules);
        toast.success('Regra alterada!');
      }
      handleClose();
    } else {
      toast.warning('É necessário adicionar ao menos uma condição!');
    }
  }

  return (
    <ModalDefault
      title={'Manutenção de Regra'}
      show={show}
      onClose={handleClose}
      sizeModal={'xl'}
      showFooter={true}
      buttonText={indexRuleEdit !== undefined ? 'Alterar Regra' : 'Adicionar Regra'}
      handleSubmit={onSubmit}
      backdrop="static"
      backgroundColor="#f8f9fa"
      disableEsc={true}
    >
      <div className="ModalMonthlyGoalInsertRule" data-testid="ModalMonthlyGoalInsertRule">
        <Row className='mt-2'>
          <Col md={6}>
            <FormGroup className="form-group">
              <label htmlFor='andOr'>
                <div className="d-flex align-items-center text-wrap">
                  <span>CONDIÇÃO LÓGICA DA REGRA *</span>

                  <PopoverHelp
                    placement="bottom"
                    header={<>Atenção!</>}
                    body={
                      <>
                        * Opção <b>E</b>: Todas as condições configuradas nessa regra devem ser verdadeiras
                        para que a meta seja atingida.<br />
                        * Opção <b>OU</b>: Se ao menos uma das condições configuradas nessa regra for verdadeira,
                        a meta é considerada como atingida.
                      </>
                    }
                  >
                    <button type="button" id="btn-help"
                      className="btn btn-sm text-violet-60 text-sm m-0 px-2">
                      <BvIcon name='help' className='text-violet-60' width={16} height={16} />
                    </button>
                  </PopoverHelp>
                </div>
              </label>
              <ReactSelect
                name='logicCondition'
                value={selectedAndOr}
                isSearchable
                isClearable={false}
                options={andOrOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedAndOr(val);
                }}
                styles={customStyles}
              />
              <ErrorMessage name={'Condição lógica'} type={errors?.logicCondition?.type?.toString()} />
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <label htmlFor="hasOperation">
              <div className="d-flex align-items-center text-wrap gap-1">
                <span>TEM OPERAÇÕES? </span>

                <PopoverHelp
                  placement="bottom"
                  header={<>Atenção!</>}
                  body={
                    <>
                      Operações são cálculos matemáticos (adição, subtração, multiplicação e divisão)
                      entre as tags, para que esse cálculo seja considerado no momento de atingir a meta.
                    </>
                  }
                >
                  <button type="button" id="btn-help"
                    className="btn btn-sm text-violet-60 text-sm m-0 px-1 py-0">
                    <BvIcon name='help' className='text-violet-60' width={16} height={16} />
                  </button>
                </PopoverHelp>
              </div>
            </label>
            <br />
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="hasOperation"
                id="hasOperation1"
                checked={hasOperations}
                onClick={() => setHasOperations(true)}
              />
              <label className="form-check-label" htmlFor="hasOperation1">
                Sim
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="hasOperation"
                id="hasOperation2"
                checked={!hasOperations}
                onChange={() => setHasOperations(false)}
              />
              <label className="form-check-label" htmlFor="hasOperation2">
                Não
              </label>
            </div>
          </Col>
          <Col md={4}>
            <label>
              <div className="d-flex align-items-center text-wrap gap-1">
                <span>CONDIÇÃO PARA PROPORCIONAL?</span>
              </div>
            </label>
            <br />
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="proportional"
                id="proportional1"
                checked={isProportional}
                onClick={() => setIsProportional(true)}
              />
              <label className="form-check-label" htmlFor="proportional1">
                Sim
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="proportional"
                id="proportional2"
                checked={!isProportional}
                onChange={() => setIsProportional(false)}
              />
              <label className="form-check-label" htmlFor="proportional2">
                Não
              </label>
            </div>
          </Col>
          {selectedCondition &&
            <Col md={4}>
              <FormGroup className='form-group'>
                <label>TIPO VALOR</label>
                <div className="d-flex align-items-center gap-1 ms-1 mt-2">
                  <label className='form-check-label m-0'>Tag</label>
                  <label className="custom-toggle">
                    <input
                      type="checkbox"
                      defaultChecked={valueType === 'value'}
                      onClick={() => setValueType((current: any) => current === 'tag' ? 'value' : 'tag')}
                    />
                    <span className="custom-toggle-slider" />
                  </label>
                  <label className='form-check-label m-0'>Valor</label>
                </div>
              </FormGroup>
            </Col>}
        </Row>
        <Row className='mt-2'>
          {hasOperations ?
            <Col md={5}>
              <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <span className="mb-0"><b>Operações</b></span>
                  <TooltipItem position='top' text='Adicionar Operação'>
                    <FontAwesomeIcon
                      className={`text-primary text-2xl cursor-pointer ${operations?.length > 0 && 'disabled'}`}
                      icon={faCirclePlus}
                      onClick={() => setShowOperations(true)}
                    />
                  </TooltipItem>
                </Card.Header>
                <Card.Body className='p-2 pb-3'>
                  {operations?.length > 0 ?
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='text-sm'>
                        {operations?.map((x, index) => (
                          <span key={index}>
                            <span className='badge bg-success mb-1'>
                              {calculableScoreTagsOptions?.find((y: any) => y.value === x.scoreTagId)?.label}
                            </span>
                            <span className='ps-1 pe-1 text-lg font-weight-bolder'>
                              {(index + 1) < operations?.length && operationsOptions?.find((x: any) => x.value === operations[0].operationId)?.operator}
                            </span>
                          </span>
                        ))}
                      </div>
                      <FontAwesomeIcon
                        icon={faCircleMinus}
                        className='cursor-pointer'
                        onClick={() => setOperations([])}
                      />
                    </div>
                    :
                    <div className='d-flex justify-content-center align-items-center text-sm'>
                      Configure uma operação!
                    </div>
                  }
                </Card.Body>
              </Card>
            </Col>
            :
            <Col md={4}>
              <FormGroup className='form-group'>
                <label>
                  <div className="d-flex align-items-center text-wrap">
                    <span>TAG</span>
                    {tagObservation &&
                      <PopoverHelp
                        placement="bottom"
                        header={<>Atenção!</>}
                        body={
                          <>
                            {tagObservation}
                          </>
                        }
                      >
                        <button type="button" id="btn-help"
                          className="btn btn-sm text-violet-60 text-sm m-0 px-2 py-0">
                          <BvIcon name='help' className='text-violet-60' width={16} height={16} />
                        </button>
                      </PopoverHelp>}
                  </div>
                </label>
                <ReactSelect
                  name='tag'
                  isSearchable
                  isClearable
                  placeholder="Selecione..."
                  options={calculableScoreTagsOptions}
                  className={`form-control p-0`}
                  noOptionsMessage={() => 'Não há registros'}
                  value={selectedTag}
                  onChange={(val: any) => {
                    setSelectedTag(val);
                  }}
                  styles={customStyles}
                />
                <ErrorMessage name={'Tag'} type={errors?.tag?.type?.toString()} />
              </FormGroup>
            </Col>
          }
          <Col md={hasOperations ? 3 : 4}>
            <ConditionSelectMonthlyGoal
              selectedCondition={selectedCondition}
              setSelectedCondition={setSelectedCondition}
              isClearable
            />
            <ErrorMessage name={'Condição'} type={errors?.condition?.type?.toString()} />
          </Col>
          {selectedCondition &&
            <Col md={4}>
              <FormGroup className='form-group'>
                <label>
                  <div className="d-flex align-items-center text-wrap">
                    <span>VALOR</span>
                    {tagValueObservation &&
                      <PopoverHelp
                        placement="bottom"
                        header={<>Atenção!</>}
                        body={
                          <>
                            {tagValueObservation}
                          </>
                        }
                      >
                        <button type="button" id="btn-help"
                          className="btn btn-sm text-violet-60 text-sm m-0 px-2 py-0">
                          <BvIcon name='help' className='text-violet-60' width={16} height={16} />
                        </button>
                      </PopoverHelp>}
                  </div>
                </label>
                {valueType === 'value' ?
                  renderValue() :
                  <ReactSelect
                    name='tagValue'
                    isSearchable
                    placeholder="Selecione..."
                    options={calculableScoreTagsOptions}
                    className={`form-control p-0`}
                    noOptionsMessage={() => 'Não há registros'}
                    value={selectedTagValue}
                    onChange={(val: any) => {
                      setSelectedTagValue(val);
                    }}
                    styles={customStyles}
                  />
                }
                <ErrorMessage name={'Valor 1'} type={errors?.value1?.type?.toString()} />
                <ErrorMessage name={'Valor 2'} type={errors?.value2?.type?.toString()} />
                <ErrorMessage name={'Tag'} type={errors?.tagValue?.type?.toString()} />
              </FormGroup>
            </Col>}
        </Row>
        <Row>
          <Col className='text-end'>
            <button
              className='btn btn-info text-white'
              onClick={handleSubmit(insertCondition, (data) => { console.log(data) })}
            >
              Adicionar Condição
            </button>
          </Col>
        </Row>
        {renderListConditions()}
      </div>

      <ModalMonthlyGoalInsertOperation
        operations={operations}
        setOperations={setOperations}
        show={showOperations}
        onClose={setShowOperations}
      />
    </ModalDefault >
  )
};

export default ModalMonthlyGoalInsertRule;
