import { ProposalRuleConditionListParams } from "../../../models/Proposals/Rule/Condition/ProposalRuleConditionListParams";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/services/proposals/rule/condition';

export class ProposalRuleConditionService extends BaseService {
    async list(params: ProposalRuleConditionListParams): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}