import { FC, useEffect } from 'react';
import './ModalVisitsList.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useVisits } from '../../../../providers/Visits/VisitsProvider';
import moment from 'moment';
import VisitsTableList from '../VisitsTableList/VisitsTableList';

interface ModalVisitsListProps {
  show: boolean;
  onClose: () => void;
  userVisitor: string;
  userVisitorId: number;
  startDate: string;
  endDate: string;
}

const ModalVisitsList: FC<ModalVisitsListProps> = ({ show, onClose, userVisitor, userVisitorId, startDate, endDate }) => {

  const { params, setParams, handleList } = useVisits();

  const handleClose = async () => {
    onClose();
  }

  useEffect(() => {
    if (show && userVisitorId > 0) {
      setParams({ ...params, ...{ page: 1, userVisitorId, startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') } });
    }
  }, [show, userVisitorId]);

  useEffect(() => { !!params && handleList(); }, [params]);

  return (
    <ModalDefault
      title={`Lista de Visitas - ${userVisitor}`}
      show={show}
      onClose={handleClose}
      sizeModal={'xxl'}
      backdrop="static"
    >
      <div className="ModalVisitsList" data-testid="ModalVisitsList">
        <VisitsTableList resume showExport />
      </div>
    </ModalDefault>
  );
}

export default ModalVisitsList;
