import { FC } from 'react';
import './CardReportRequest.scss';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReportRequestModel } from '../../../../models/Reports/ReportRequest/ReportRequestModel';
import { ReportStatusEnum } from '../../../../enums/Reports/Report/ReportStatusEnum';
import moment from 'moment';
import FileDownloadButton from '../../../FileDownloadButton/FileDownloadButton';
import { useReportRequest } from '../../../../providers/Reports/ReportRequest/ReportRequestProvider';
import { BvIcon } from 'bevi-icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFileExport, faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import PopoverHelp from '../../../Home/PopoverHelp/PopoverHelp';
import { isDesktop } from 'react-device-detect';

interface CardReportRequestProps {
  item: ReportRequestModel;
}

const CardReportRequest: FC<CardReportRequestProps> = ({ item }) => {
  const { isLoadingDownload, error, handleDownload } = useReportRequest();

  return (
    <Card className="CardReportRequest mb-3 p-0" data-testid="CardReportRequest">
      <div className="card-body p-3 position-relative">
        <div className="d-flex align-items-center justify-content-between w-100 gap-3">
          <div className='pt-4'>
            <div className="bg-dest text-wrap mb-2" style={{ backgroundColor: item?.reportStatus?.bgColor }}>{item?.reportStatus?.name}</div>
            <div className="d-flex align-items-center gap-1">
              <div className="avatar">
                <FontAwesomeIcon icon={faFileExcel} className="text-violet-60" />
              </div>
              <div>
                <h6 className={`d-flex m-0 ${[ReportStatusEnum.PROCESSED, ReportStatusEnum.SENT].includes(item?.reportStatus?.id || 0) ? 'text-white' : 'text-violet-60'} text-wrap`}>
                  Relatório de {item?.reports?.name}
                  <PopoverHelp
                    placement="bottom"
                    header={<>Relatório de {item?.reports?.name}</>}
                    body={<>{item?.reports?.description}</>}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                  </PopoverHelp>
                </h6>
                <p className="text-xs mb-0 text-violet-40">
                  {moment(item?.updatedAt).fromNow()}
                </p>
              </div>
            </div>
            <div>
            </div>
          </div>
          <div>
            <FileDownloadButton
              handleDownload={() => handleDownload(item.id || 0)}
              isLoading={isLoadingDownload}
              error={error}
              textButton={isDesktop ? "Baixar relatório" : ""}
              typeButton='button'
              isDisabled={![ReportStatusEnum.PROCESSED, ReportStatusEnum.SENT].includes(item?.reportStatus?.id || 0)}
            />
          </div>
        </div>
      </div>
    </Card>
  );
}

export default CardReportRequest;
