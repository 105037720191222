import * as yup from "yup";

export const schema = yup.object().shape({
    id: yup
        .number(),
    name: yup
        .string()
        .required(),
    description: yup
        .string()
        .required()
});