import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './ModalCompanyInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useCompany } from '../../../../providers/Registrations/Company/CompanyProvider';
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from './Validate';
import { useForm } from 'react-hook-form';
import getBase64 from '../../../../utils/ConvertFiletoBase64';
import { Col, Row } from 'react-bootstrap';
import HomeThumb from './HomeThumb/HomeThumb';
import { CompanyModel } from '../../../../models/Registrations/Company/CompanyModel';
import NavPills from '../../../NavPills/NavPills';
import LoginThumb from './LoginThumb/LoginThumb';
import { toast } from 'react-toastify';
import FormGeneralInfo from './FormGeneralInfo/FormGeneralInfo';
import FormAddressInfo from './FormAddressInfo/FormAddressInfo';
import FormLayoutInfo from './FormLayoutInfo/FormLayoutInfo';
import FormatCnpj from '../../../../utils/FormatCnpj';
import FormatPhone from '../../../../utils/FormatPhone';

interface ModalCompanyInsertProps {
  show: boolean;
  onClose: () => void;
}

type FormValues = {
  id?: string;
  acronym?: string;
  corporateName?: string;
  name?: string;
  cnpj?: string;
  phone?: string;
  email?: string;
  hostname?: string;
  colorPrimary?: string;
  colorSecondary?: string;
  colorText?: string;
  logoDefault?: string;
  logoInverse?: string;
  favicon?: string;
  backgroundLogin?: string;
  cep?: string;
  publicPlace?: string;
  numbers?: string;
  complement?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
}

const TabEnum = {
  HOME: 'home',
  LOGIN: 'login',
}

const ModalCompanyInsert: FC<ModalCompanyInsertProps> = ({ show, onClose }) => {
  const [logoPrimary, setLogoPrimary] = useState<any[]>([]);
  const [logoSecondary, setLogoSecondary] = useState<any[]>([]);
  const [favicon, setFavicon] = useState<any[]>([]);
  const [backgroundLogin, setBackgroundLogin] = useState<any[]>([]);
  const [companyPreview, setCompanyPreview] = useState<CompanyModel>({});
  const [currentActiveTab, setCurrentActiveTab] = useState<string>(TabEnum.HOME);

  const { company, setCompany, isLoading, handleFetch, handleList, setError } = useCompany();

  const { handleSubmit, register, setValue, control, reset, formState: { errors } } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setError('');
    setCompany({});
    onClose();
  }

  const onSubmit = async (data: any) => {
    setError('');

    const _data = { ...data, addressId: data?.address?.id };
    if (logoPrimary?.length === 0) {
      delete _data.logoDefault;
    }
    if (logoSecondary?.length === 0) {
      delete _data.logoInverse;
    }
    if (favicon?.length === 0) {
      delete _data.favicon;
    }
    if (backgroundLogin?.length === 0) {
      delete _data.backgroundLogin;
    }

    _data.cnpj = _data?.cnpj?.replace(/[^\w\s]/gi, '');
    _data.phone = _data?.phone?.replace(/\D+/g, '');
    _data.cep = _data?.cep?.replace(/\D+/g, '');

    const ret = await handleFetch(_data as CompanyModel);

    if (ret) {
      toast.success('Promotora salva com sucesso!');
      handleList();
      onClose();
    }
  }

  useEffect(() => {
    if (show) {
      setCurrentActiveTab(TabEnum.HOME);

      if (!!company?.id) {
        reset(company as FormValues);
        setValue('colorPrimary', company?.colorPrimary || '');
        setValue('colorSecondary', company?.colorSecondary || '');
        setValue('colorText', company?.colorText || '');
        setValue('cnpj', FormatCnpj(company?.cnpj) || '');
        setValue('phone', FormatPhone(company?.phone) || '');
        setCompanyPreview(company);
      } else {
        reset(new CompanyModel());
        setValue('colorPrimary', '');
        setValue('colorSecondary', '');
        setValue('colorText', '');
        setLogoPrimary([]);
        setLogoSecondary([]);
        setFavicon([]);
        setBackgroundLogin([]);
        setCompanyPreview(new CompanyModel());
      }
    }
  }, [show]);

  useEffect(() => {
    if (logoPrimary[0]?.preview) {
      setCompanyPreview({ ...companyPreview, logoDefault: logoPrimary[0]?.preview });

      getBase64(logoPrimary[0]).then((file: any) => {
        setValue('logoDefault', file);
      });
    }
  }, [logoPrimary]);

  useEffect(() => {
    if (logoSecondary[0]?.preview) {
      setCompanyPreview({ ...companyPreview, logoInverse: logoSecondary[0]?.preview });

      getBase64(logoSecondary[0]).then((file: any) => setValue('logoInverse', file));
    }
  }, [logoSecondary]);

  useEffect(() => {
    if (favicon[0]?.preview) {
      setCompanyPreview({ ...companyPreview, favicon: favicon[0]?.preview });
      getBase64(favicon[0]).then((file: any) => setValue('favicon', file));
    }
  }, [favicon]);

  useEffect(() => {
    if (backgroundLogin[0]?.preview) {
      setCompanyPreview({ ...companyPreview, backgroundLogin: backgroundLogin[0]?.preview });
      getBase64(backgroundLogin[0]).then((file: any) => setValue('backgroundLogin', file));
    }
  }, [backgroundLogin]);

  return (
    <ModalDefault
      title={!!company?.id ? 'Editar Promotora' : 'Adicionar Promotora'}
      show={show}
      onClose={handleClose}
      sizeModal={'xxl'}
      showFooter={true}
      buttonText={isLoading ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoading}
      disabledSubmit={isLoading}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalCompanyInsert" data-testid="ModalCompanyInsert">
        <Row>
          <Col sm={12} md={12} lg={12} xl={7}>
            <FormGeneralInfo
              company={companyPreview}
              setCompany={setCompanyPreview}
              errors={errors}
              register={register}
            />

            <FormAddressInfo
              company={companyPreview}
              errors={errors}
              register={register}
              setValue={setValue}
              control={control}
            />

            <FormLayoutInfo
              company={companyPreview}
              setCompany={setCompanyPreview}
              errors={errors}
              register={register}
              setValue={setValue}
              control={control}
              logoPrimary={logoPrimary}
              setLogoPrimary={setLogoPrimary}
              logoSecondary={logoSecondary}
              setLogoSecondary={setLogoSecondary}
              favicon={favicon}
              setFavicon={setFavicon}
              backgroundLogin={backgroundLogin}
              setBackgroundLogin={setBackgroundLogin}
            />
          </Col>
          <Col sm={12} md={12} lg={12} xl={5}>
            <Row className="h-100 mb-5">
              <Col md={12} style={{ height: 600 }}>
                <NavPills
                  options={[
                    {
                      acronym: TabEnum.HOME,
                      name: 'Homepage',
                      element: <div className="style-container">
                        <HomeThumb
                          name={companyPreview?.name}
                          corporateName={companyPreview?.corporateName}
                          cnpj={companyPreview?.cnpj}
                          logoInverse={companyPreview?.logoInverse}
                          colorPrimary={companyPreview?.colorPrimary}
                          colorSecondary={companyPreview?.colorSecondary}
                          colorText={companyPreview?.colorText}
                        />
                      </div>,
                      icon: <i className="fa-solid fa-desktop"></i>,
                      show: true
                    },
                    {
                      acronym: TabEnum.LOGIN,
                      name: 'Login',
                      element: <div className="style-container">
                        <LoginThumb
                          backgroundLogin={companyPreview?.backgroundLogin}
                          logoDefault={companyPreview?.logoDefault}
                          colorPrimary={companyPreview?.colorPrimary}
                        />
                      </div>,
                      icon: <i className="fa-solid fa-unlock"></i>,
                      show: true
                    },
                  ]}
                  current={currentActiveTab}
                  setCurrent={setCurrentActiveTab}
                  className="h-100"
                  mdMenu={6}
                  classNameMenu="mx-auto"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
}

export default ModalCompanyInsert;
