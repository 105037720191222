import React, { FC } from 'react';
import './ReceiptTypeDaily.scss';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

interface ReceiptTypeDailyProps {}

const ReceiptTypeDaily: FC<ReceiptTypeDailyProps> = () => (
  <div className="ReceiptTypeDaily" data-testid="ReceiptTypeDaily">
    <Card>
      <Card.Body>
      <span>
        <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
          Diário - Verificado todos os dias úteis
      </span>
      </Card.Body>
    </Card>
  </div>
);

export default ReceiptTypeDaily;
