import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { LevelCheckService } from "../../../services/Registrations/Partner/LevelCheck/LevelCheckService";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";
import { LevelCheckModel } from "../../../models/Partner/Type/LevelCheckModel";

interface ContextProps {
    levelchecks: LevelCheckModel[],
    levelchecksOptions: ReactSelectOptions[],
    handleList: () => void,
}

export const LevelCheckContext = createContext<ContextProps>({} as ContextProps);

interface LevelCheckProviderProps {
    children: ReactNode;
}

export const LevelCheckProvider = ({ children }: LevelCheckProviderProps) => {
    const [levelchecks, setLevelchecks] = useState<LevelCheckModel[]>([]);
    const [error, setError] = useState<string>('');
    const [levelchecksOptions, setLevelchecksOptions] = useState<ReactSelectOptions[]>([]);

    const service = new LevelCheckService();

    const handleList = useCallback(async () => {
        try {
            const [_Response, _Error] = await service.list({ withPaginate: false });

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setLevelchecks(_Response.data);
            return setError('');
        } catch (e) {
            return setError('Não foi possível carregar os niveis');
        }
    }, []);

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        setLevelchecksOptions(
            levelchecks
                ?.map((item: LevelCheckModel) => {
                    return { label: item.name, value: item.id };
                })
        )
    }, [levelchecks]);

    return (
        <LevelCheckContext.Provider value={{
            levelchecks,
            levelchecksOptions,
            handleList,
        }}>
            {children}
        </LevelCheckContext.Provider>
    );
}

export const useLevelCheck = () => useContext(LevelCheckContext);