import { FC, useEffect } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { useOriginType } from '../../../../providers/Registrations/User/UserOriginTypeProvider';
import { typePerson } from '../../../../enums/Registrations/Partner/RegistrationPartnerTypePerson';
import { useType } from '../../../../providers/Registrations/Partner/PartnerTypeProvider';
import { useActiveType } from '../../../../providers/Registrations/User/UserActiveTypeProvider';
import { useLevelCheck } from '../../../../providers/Registrations/Partner/PartnerLevelCheckProvider';
import { useSubsidiary } from '../../../../providers/Registrations/Company/SubsidiaryProvider';
import { useCompany } from '../../../../providers/Registrations/Company/CompanyProvider';
import { useAccessProfile } from '../../../../providers/Registrations/Access/AccessProfileProvider';
import { useTagType } from '../../../../providers/Registrations/User/UserTagTypeProvider';

interface SidebarPartnerFilterProps { }

const SidebarPartnerFilter: FC<SidebarPartnerFilterProps> = () => {

  const { handleList: handleListTypes, typesOptions } = useType();
  const { handleList: handleListLevelCheck, levelchecksOptions } = useLevelCheck();
  const { handleList: handleListSubsidiary, subsidiarysOptions } = useSubsidiary();
  const { handleList: handleListCompany, companysOptions } = useCompany();
  const { handleList: handleListProfile, profilesOptions } = useAccessProfile();
  const { handleList: handleListTagType, tagTypesOptions } = useTagType();
  const { handleList: handleListOriginType, originTypesOptions } = useOriginType();
  const { handleList: handleListActiveType, activeTypesOptions } = useActiveType();

  useEffect(() => {
    handleListTypes();
    handleListLevelCheck();
    handleListSubsidiary();
    handleListCompany();
    handleListProfile();
    handleListTagType();
    handleListOriginType();
    handleListActiveType();
  }, [])

  return (
    <div className="SidebarPartnerFilter" data-testid="SidebarPartnerFilter">
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">STATUS</label>
            <ReactSelect
              isSearchable
              options={activeTypesOptions}
              placeholder="Selecione..."
              className={`form-control p-0`}
              noOptionsMessage={() => 'Não há registros'}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">TIPO AGENTE</label>
            <ReactSelect
              isSearchable
              isClearable
              options={typesOptions}
              placeholder="Selecione..."
              className={`form-control p-0`}
              noOptionsMessage={() => 'Não há registros'}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">NÍVEL ACESSO</label>
            <ReactSelect
              isSearchable
              options={profilesOptions}
              placeholder="Selecione..."
              className={`form-control p-0`}
              noOptionsMessage={() => 'Não há registros'}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">FILIAL</label>
            <ReactSelect
              isSearchable
              options={subsidiarysOptions}
              placeholder="Selecione..."
              className={`form-control p-0`}
              noOptionsMessage={() => 'Não há registros'}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">PARCEIRO MULTILOJA</label>
            <input
              className={`form-control`}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">CÓDIGO PARCEIRO</label>
            <input
              className={`form-control`}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">CPF/CNPJ</label>
            <input
              className={`form-control`}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">NOME</label>
            <input
              className={`form-control`}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">EMAIL</label>
            <input
              className={`form-control`}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">FONE</label>
            <input
              className={`form-control`}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">CIDADE</label>
            <input
              className={`form-control`}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">ASSESSOR COMERCIAL</label>
            <ReactSelect
              isSearchable
              // options={}
              placeholder="Selecione..."
              className={`form-control p-0`}
              noOptionsMessage={() => 'Não há registros'}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">NÍVEL CHECAGEM</label>
            <ReactSelect
              isSearchable
              options={levelchecksOptions}
              placeholder="Selecione..."
              className={`form-control p-0`}
              noOptionsMessage={() => 'Não há registros'}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">LOGIN ACESSO AGENTE</label>
            <input
              className={`form-control`}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">LOGIN ACESSO MULTILOJA</label>
            <input
              className={`form-control`}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">LOGIN BANCO</label>
            <input
              className={`form-control`}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">CPF LOGIN BANCO</label>
            <input
              className={`form-control`}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">PROMOTORA</label>
            <ReactSelect
              isSearchable
              options={companysOptions}
              placeholder="Selecione..."
              className={`form-control p-0`}
              noOptionsMessage={() => 'Não há registros'}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">COMERCIAL PROMOTORA</label>
            <ReactSelect
              isSearchable
              // options={}
              placeholder="Selecione..."
              className={`form-control p-0`}
              noOptionsMessage={() => 'Não há registros'}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">ASSESSOR REGIONAL</label>
            <ReactSelect
              isSearchable
              // options={}
              placeholder="Selecione..."
              className={`form-control p-0`}
              noOptionsMessage={() => 'Não há registros'}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">ORIGEM DO CADASTRO</label>
            <ReactSelect
              isSearchable
              options={originTypesOptions}
              placeholder="Selecione..."
              className={`form-control p-0`}
              noOptionsMessage={() => 'Não há registros'}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">DATA DE CADASTRO</label>
            <input
              className={`form-control`}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">MARCAÇÕES</label>
            <ReactSelect
              isSearchable
              options={tagTypesOptions}
              placeholder="Selecione..."
              className={`form-control p-0`}
              noOptionsMessage={() => 'Não há registros'}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">TIPO PESSOA</label>
            <ReactSelect
              isSearchable
              options={typePerson}
              placeholder="Selecione..."
              className={`form-control p-0`}
              noOptionsMessage={() => 'Não há registros'}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

export default SidebarPartnerFilter;
