import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ProposalExportService } from "../../../services/Proposals/Export/ProposalExportService";
import { ProposalExportFormFilterParams } from "../../../models/Proposals/Export/ProposalExportFormFilterParams";
import {ToastSettings} from "../../../utils/ToastSettings";

interface ContextProps {
    isLoadingFile: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleExport: (params: ProposalExportFormFilterParams) => Promise<boolean>,
    handleExportSynthetic: (params: ProposalExportFormFilterParams) => Promise<boolean>,
    handleExportResume: (params: ProposalExportFormFilterParams) => Promise<boolean>,
    handleExportResume2: (params: ProposalExportFormFilterParams) => Promise<boolean>,
    handleExportTyping: (params: ProposalExportFormFilterParams) => Promise<boolean>,
}

export const ProposalExportContext = createContext<ContextProps>({} as ContextProps);

interface ProposalExportProviderProps {
    children: ReactNode;
}

export const ProposalExportProvider = ({ children }: ProposalExportProviderProps) => {
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const service = new ProposalExportService();

    const handleExport = async (params: ProposalExportFormFilterParams) => {
        try {
            setError('');
            setIsLoadingFile(true);

            const [_Response, _Error] = await service.export(params);

            if (_Error) {
                setIsLoadingFile(false);
                ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
                return false;
            }

            toast.success(_Response?.message || 'Relatório gerado com sucesso!');
            setIsLoadingFile(false);

            return true;
        } catch (e) {
            setIsLoadingFile(false);
            setError('Não foi possivel solicitar o relatório.');
            return false;
        }
    };

    const handleExportSynthetic = async (params: ProposalExportFormFilterParams) => {
        try {
            setError('');
            setIsLoadingFile(true);

            const [_Response, _Error] = await service.exportSynthetic(params);

            if (_Error) {
                setIsLoadingFile(false);
                ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
                return false;
            }

            toast.success(_Response?.message || 'Relatório gerado com sucesso!');
            setIsLoadingFile(false);

            return true;
        } catch (e) {
            setIsLoadingFile(false);
            setError('Não foi possivel solicitar o relatório.');
            return false;
        }
    };

    const handleExportResume = async (params: ProposalExportFormFilterParams) => {
        try {
            setError('');
            setIsLoadingFile(true);

            const [_Response, _Error] = await service.exportResume(params);

            if (_Error) {
                setIsLoadingFile(false);
                ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
                return false;
            }

            toast.success(_Response?.message || 'Relatório gerado com sucesso!');
            setIsLoadingFile(false);

            return true;
        } catch (e) {
            setIsLoadingFile(false);
            setError('Não foi possivel solicitar o relatório.');
            return false;
        }
    };

    const handleExportResume2 = async (params: ProposalExportFormFilterParams) => {
        try {
            setError('');
            setIsLoadingFile(true);

            const [_Response, _Error] = await service.exportResume2(params);

            if (_Error) {
                setIsLoadingFile(false);
                ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
                return false;
            }

            toast.success(_Response?.message || 'Relatório gerado com sucesso!');
            setIsLoadingFile(false);

            return true;
        } catch (e) {
            setIsLoadingFile(false);
            setError('Não foi possivel solicitar o relatório.');
            return false;
        }
    };

    const handleExportTyping = async (params: ProposalExportFormFilterParams) => {
        try {
            setError('');
            setIsLoadingFile(true);

            const [_Response, _Error] = await service.exportTyping(params);

            if (_Error) {
                setIsLoadingFile(false);
                ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
                return false;
            }

            toast.success(_Response?.message || 'Relatório gerado com sucesso!');
            setIsLoadingFile(false);

            return true;
        } catch (e) {
            setIsLoadingFile(false);
            setError('Não foi possivel solicitar o relatório.');
            return false;
        }
    };

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        setError('');
    }, [])

    return (
        <ProposalExportContext.Provider value={{
            isLoadingFile,
            error,
            setError,
            handleExport,
            handleExportSynthetic,
            handleExportResume,
            handleExportResume2,
            handleExportTyping,
        }}>
            {children}
        </ProposalExportContext.Provider>
    );
}

export const useProposalExport = () => useContext(ProposalExportContext);