import React, { FC, useEffect } from 'react';
import './MonitoringPointsObservation.scss';
import PopoverHelp from '../../../Home/PopoverHelp/PopoverHelp';
import { BvIcon } from 'bevi-icon';

interface MonitoringPointsObservationProps { }

const MonitoringPointsObservation: FC<MonitoringPointsObservationProps> = (props: any) => {

  const { value } = props;

  return (
    <div className="MonitoringPointsObservation" data-testid="MonitoringPointsObservation">
      <div className="d-flex justify-content-center align-items-center text-wrap">
        <span>{value?.score?.toFixed(2)}</span>
        {value?.observation &&
          <PopoverHelp
            placement="right"
            header={<>Observação</>}
            body={
              <span dangerouslySetInnerHTML={{ __html: value?.observation }}>
              </span>
            }
          >
            <button type="button" id="btn-help"
              className="btn btn-sm text-violet-60 text-sm m-0 px-1 pt-1">
              <BvIcon name='help' className='text-violet-60' width={16} height={16} />
            </button>
          </PopoverHelp>}
      </div>
    </div>
  )
};

export default MonitoringPointsObservation;
