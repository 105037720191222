import { MasterCovenantGroupModel } from "../../../models/Registrations/Covenant/MasterCovenantGroupModel";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/services/proposals/proposal/group/master';

export class MasterCovenantGroupService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async save(payload: MasterCovenantGroupModel): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/save`, payload));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}