import { FC, useEffect, useState } from 'react';
import './IndicatorManagement.scss';
import FormWalletVisits from '../../../components/Visits/IndicatorManagement/FormWalletVisits/FormWalletVisits';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import NavPills from '../../../components/NavPills/NavPills';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import { useAccessPage } from '../../../providers/Registrations/Access/AccessPageProvider';
import FormProductionVisits from '../../../components/Visits/IndicatorManagement/FormProductionVisits/FormProductionVisits';
import FormRegisterVisits from '../../../components/Visits/IndicatorManagement/FormRegisterVisits/FormRegisterVisits';
import FormListVisits from '../../../components/Visits/IndicatorManagement/FormListVisits/FormListVisits';
import FormListPreVisits from '../../../components/Visits/IndicatorManagement/FormListPreVisits/FormListPreVisits';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import SidebarFilter from '../../../components/SidebarFilter/SidebarFilter';
import SidebarIndicatorManagementFilter from '../../../components/Visits/IndicatorManagement/SidebarIndicatorManagementFilter/SidebarIndicatorManagementFilter';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { useIndicatorManagementPreVisitVisits } from '../../../providers/Visits/IndicatorManagement/IndicatorManagementPreVisitProvider';
import moment from 'moment';
import { EventClickArg, formatDate } from '@fullcalendar/core';
import { PreVisitModel } from '../../../models/Visits/IndicatorManagement/PreVisitModel';
import FormQualityVisits from '../../../components/Visits/IndicatorManagement/FormQualityVisits/FormQualityVisits';
import { useIndicatorManagementWalletVisits } from '../../../providers/Visits/IndicatorManagement/IndicatorManagementWalletProvider';
import FormAgentsLifeVisits from '../../../components/Visits/IndicatorManagement/FormAgentsLifeVisits/FormAgentsLifeVisits';
import FormTermVisits from '../../../components/Visits/IndicatorManagement/FormTermVisits/FormTermVisits';
import FormRankingVisits from '../../../components/Visits/IndicatorManagement/FormRankingVisits/FormRankingVisits';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import FormPasswordVisits from '../../../components/Visits/IndicatorManagement/FormPasswordVisits/FormPasswordVisits';
import { useReportRequest } from '../../../providers/Reports/ReportRequest/ReportRequestProvider';

interface IndicatorManagementProps { }

export enum TabEnumIndicatorManagement {
  WALLET = 'wallet',
  PRODUCTION_RANGE = 'production_range',
  QUALITY = 'quality',
  REGISTER = 'register',
  AGENTS_LIFE = 'agents_life',
  RANKING = 'ranking',
  TERM = 'term',
  PASSWORD = 'password',
  PRE_VISIT = 'pre-visit',
  VISIT = 'visit',
  NONE = 'none'
}

const IndicatorManagement: FC<IndicatorManagementProps> = () => {
  const [futurePreVisits, setFuturePreVisits] = useState<PreVisitModel[]>([]);

  const { preVisits } = useIndicatorManagementPreVisitVisits();
  const [currentActiveTab, setCurrentActiveTab] = useState<TabEnumIndicatorManagement | string>(TabEnumIndicatorManagement.WALLET);
  const { isLoading: isLoadingPage } = useAccessPage();
  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { wallets, setWallets, isLoading } = useIndicatorManagementWalletVisits();
  const { isLoadingFile } = useReportRequest();

  useEffect(() => {
    return () => {
      setWallets(undefined);
    }
  }, []);

  useEffect(() => {
    if (preVisits && preVisits.length > 0) {
      setFuturePreVisits(preVisits?.filter((e: PreVisitModel) =>
        moment(new Date()).format('YYYY-MM-DD') <= moment(e.scheduledDate).format('YYYY-MM-DD')) || []);
    } else {
      setFuturePreVisits([]);
    }
  }, [preVisits]);

  const events = preVisits?.map((e: PreVisitModel) => (
    {
      // title: e.observation,
      // title: e.id?.toString(),
      start: moment(e.scheduledDate).format('YYYY-MM-DD'),
      display: 'background',
      color: 'blue',
    }
  ));

  const renderContent = (): JSX.Element => {

    return (
      <>

        <Row md={12}>
          <Col md={8} sm={12}>
            <Card style={{ maxHeight: '400px' }}>
              <Card.Body>
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={window.innerWidth >= 765 ? {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                  } : {}}
                  initialView={'dayGridMonth'}
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={true}
                  weekends={false}
                  height={350}
                  events={events}
                  locale={'pt-br'}
                  buttonText={{
                    prev: '<',
                    next: '>',
                    today: 'Hoje',
                    day: 'Dia',
                    week: 'Semana',
                    month: 'Mês'
                  }}
                />
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} sm={12} className='mt-3 mt-md-0'>
            <Card style={{ maxHeight: '400px', minHeight: '400px' }}>
              <Card.Header className="p-3 pb-0 mb-0">
                <h6 className="mb-0 ">Próximos eventos</h6>
              </Card.Header>
              <Card.Body className="border-radius-lg p-3 mt-0 ">
                {futurePreVisits?.length > 0 ? (
                  <>
                    {futurePreVisits.map((x: PreVisitModel, key: number) => (
                      <div key={x.id} className="d-flex mt-4">
                        <div>
                          <div className="icon icon-shape bg-primary-soft shadow text-center border-radius-md shadow-none">
                            <i className="ni ni-bell-55 text-lg text-primary text-gradient opacity-10" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div className="ms-3">
                          <div className="numbers">
                            <h6 className="mb-1 text-dark text-sm">{x.observation}</h6>
                            <span className="text-sm">{moment(x.scheduledDate).format('DD/MM/YYYY HH:MM:ss')}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center text-sm">Não há registros</td>
                  </tr>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <div className="d-md-flex d-none flex-md-row flex-column gap-2 gap-md-0 mt-2">
            <span className='me-2 badge' style={{ backgroundColor: '#B2B2FF' }}>
              {'EVENTOS ANTERIORES'}
            </span>
            <span className='me-2 badge' style={{ backgroundColor: '#7C7AF0' }}>
              {'EVENTOS DO DIA'}
            </span>
            <span className='me-2 badge' style={{ backgroundColor: '#B1B1FF' }}>
              {'PRÓXIMOS EVENTOS'}
            </span>
          </div>
        </Row>

        <br />

        {!wallets && isLoading &&
          <Row>
            <Col className='text-center'>
              <FontAwesomeIcon icon={faSpinner} spin />
              <span className='ms-2'>Carregando...</span>
            </Col>
          </Row>}

        {!!wallets &&
          <Row md={12}>
            <Col >
              <NavPills
                options={[
                  {
                    acronym: TabEnumIndicatorManagement.WALLET,
                    name: 'CARTEIRA',
                    element: <FormWalletVisits currentActiveTab={currentActiveTab as TabEnumIndicatorManagement} />,
                    show: true
                  },
                  {
                    acronym: TabEnumIndicatorManagement.PRODUCTION_RANGE,
                    name: 'PRODUÇÃO POR FAIXA',
                    element: <FormProductionVisits currentActiveTab={currentActiveTab as TabEnumIndicatorManagement} />,
                    show: true
                  },
                  {
                    acronym: TabEnumIndicatorManagement.QUALITY,
                    name: 'QUALIDADE',
                    element: <FormQualityVisits currentActiveTab={currentActiveTab as TabEnumIndicatorManagement} />,
                    show: true
                  },
                  {
                    acronym: TabEnumIndicatorManagement.REGISTER,
                    name: 'CADASTRO',
                    element: <FormRegisterVisits currentActiveTab={currentActiveTab as TabEnumIndicatorManagement} />,
                    show: true
                  },
                  {
                    acronym: TabEnumIndicatorManagement.AGENTS_LIFE,
                    name: 'VIDA DO AGENTE',
                    element: <FormAgentsLifeVisits currentActiveTab={currentActiveTab as TabEnumIndicatorManagement} />,
                    show: true
                  },
                  {
                    acronym: TabEnumIndicatorManagement.RANKING,
                    name: 'RANKING',
                    element: <FormRankingVisits currentActiveTab={currentActiveTab as TabEnumIndicatorManagement} />,
                    show: true
                  },
                  {
                    acronym: TabEnumIndicatorManagement.TERM,
                    name: 'TERMO',
                    element: <FormTermVisits currentActiveTab={currentActiveTab as TabEnumIndicatorManagement} />,
                    show: true
                  },
                  {
                    acronym: TabEnumIndicatorManagement.PASSWORD,
                    name: 'SENHA',
                    element: <FormPasswordVisits currentActiveTab={currentActiveTab as TabEnumIndicatorManagement} />,
                    show: true
                  },
                  {
                    acronym: TabEnumIndicatorManagement.PRE_VISIT,
                    name: 'PRÉ-VISITA',
                    element: <FormListPreVisits currentActiveTab={currentActiveTab as TabEnumIndicatorManagement} />,
                    show: true
                  },
                  {
                    acronym: TabEnumIndicatorManagement.VISIT,
                    name: 'VISITA',
                    element: <FormListVisits currentActiveTab={currentActiveTab as TabEnumIndicatorManagement} />,
                    show: true
                  }

                ]}
                current={currentActiveTab}
                setCurrent={setCurrentActiveTab}
                mdMenu={12}
              />
            </Col>
          </Row>}
      </>
    );
  }

  return (
    <MasterPage
      title={'Pré Visitas'}
      icon={'fas fa-unlock'}
      footerFixed
      footerInverse
    >
      <div className="IndicatorManagement" data-testid="IndicatorManagement">
        <PageBase
          title={'Painel de Gestão de Indicadores'}
          subtitle={'Gerenciamento de indicadores comercial.'}
          content={
            renderContent()
          }
          hasFilter
          handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          isLoadingFile={isLoadingFile}
        />

        {(isLoadingPage)}

        <SidebarFilter>
          <SidebarIndicatorManagementFilter activeTab={currentActiveTab as TabEnumIndicatorManagement} />
        </SidebarFilter>

      </div>
    </MasterPage>

  );
}

export default IndicatorManagement;
