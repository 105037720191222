import { FC, useEffect, useState } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { useMonthlyGoal } from '../../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalProvider';
import { MonthlyGoalParams } from '../../../../models/PanelConnects/MonthlyGoalParams';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import moment from 'moment';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import { useForm } from 'react-hook-form';
import 'moment/locale/pt-br';
import { monthlyGoalAccessProfileOptions } from '../../../../models/PanelConnects/MonthlyGoalAccessProfileOptions';

interface SidebarMonthlyGoalFilterProps { }

export const defaultValues: MonthlyGoalParams = {
  startDate: '',
  endDate: '',
  connectTypeId: ''
};

const SidebarMonthlyGoalFilter: FC<SidebarMonthlyGoalFilterProps> = () => {

  const { typesOptions, params, setParams } = useMonthlyGoal();
  const { handleSubmit, setValue } = useForm<MonthlyGoalParams>({ defaultValues });
  const [selectedType, setSelectedType] = useState<any>();
  const [selectedAccessProfile, setSelectedAccessProfile] = useState<any>();

  const [startDate, setStartDate] = useState<any>(moment(new Date()).format('YYYY-MM-01 HH:mm:ss'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).endOf('month').format('YYYY-MM-DD HH:mm:ss'));

  useEffect(() => {
    setValue('connectTypeId', selectedType?.value);
  }, [selectedType]);

  useEffect(() => {
    setValue('accessProfileId', selectedAccessProfile?.value);
  }, [selectedAccessProfile]);

  const onSubmit = async (data: any) => {
    data.endDate = data.endDate == '' ? '' : moment(data.endDate).format('YYYY-MM-DD');
    data.startDate = data.startDate == '' ? '' : moment(data.startDate).format('YYYY-MM-DD');

    setParams({ ...params, ...data });
  }

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)} >
      <div className="SidebarMonthlyGoalFilter" data-testid="SidebarMonthlyGoalFilter">
        <Row className="mt-1">
          <Col>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              maxEndDate={moment(new Date()).add(3, 'month').format('YYYY-MM-DD')}
              startLabelText={'DATA INICIAL'}
              endLabelText={'DATA FINAL'}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="connectTypeId">TIPO</label>
              <ReactSelect
                isClearable
                isSearchable
                options={typesOptions}
                value={selectedType ?? ''}
                defaultValue={selectedType}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => 'Não há registros'}
                styles={customStyles}
                onChange={(val: any) => {
                  setSelectedType(val);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup className="form-group">
              <label>TIPO COMERCIAL</label>
              <ReactSelect
                name='accessProfileId'
                value={selectedAccessProfile}
                isClearable
                options={monthlyGoalAccessProfileOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedAccessProfile(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  )
}

export default SidebarMonthlyGoalFilter;
