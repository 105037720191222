function FormatCpfCnpj(value: string | undefined) {

    value = value?.replace(/[^\d]/g, "");
    if (!value) {
        return '';
    }

    return value?.length === 14
        ? value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5")
        : value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
}

export default FormatCpfCnpj;
