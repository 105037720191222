import { FC, useEffect, useState } from 'react';
import './ModalProposalConfigExportInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { Col, Row } from 'react-bootstrap';
import { useProposalConfig } from '../../../../providers/Proposals/Config/ProposalConfigProvider';
import { ProposalExportHeaderConfigurationModel, ProposalExportHeaderFieldsConfigurationModel } from '../../../../models/Proposals/Config/ProposalExportHeaderConfigurationModel';
import { NormalizesFieldNames } from '../../../../utils/Proposals/NormalizesFieldNames';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';

interface ModalProposalConfigExportInsertProps {
  show: boolean;
  onClose: () => void;
  item: ProposalExportHeaderConfigurationModel;
}

const ModalProposalConfigExportInsert: FC<ModalProposalConfigExportInsertProps> = ({ show, onClose, item }) => {
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [fieldsOption, setFieldsOption] = useState<any[]>([]);
  const { handleListExportHeaderConfiguration, handleSaveExportHeaderConfiguration, isLoading } = useProposalConfig();

  const animatedComponents = makeAnimated();

  useEffect(() => {
    if (item) {
      const _selected: any[] = [];
      Object.keys(item?.config ?? {}).forEach((value: string) => {
        const _key = value as keyof ProposalExportHeaderFieldsConfigurationModel;
        _selected.push({
          field: value,
          content: item?.config ? item?.config[_key]?.map((x: string) => (
            { label: x, value: x }
          )) : []
        })
      });
      setSelectedItems(_selected);
      setFieldsOption(item?.headerField?.map((x) => ({
        label: x,
        value: x
      }))?.sort((a: any, b: any) => a.label > b.label ? 1 : -1) ?? []);
    }
  }, [item]);

  const hasSelectedFields = (): boolean => {
    let has: boolean = false;
    selectedItems?.forEach((x: any) => {
      if (x.content?.length > 0) {
        has = true;
      }
    });
    return has;
  }

  const handleChangeOption = (value: any, index: number, action: any) => {
    const _selectedItems = JSON.parse(JSON.stringify(selectedItems));
    _selectedItems[index].content = value;
    setSelectedItems(_selectedItems);
  }

  const onSubmit = async () => {
    if (hasSelectedFields()) {
      const config: any = {};
      selectedItems?.forEach((x: any) => {
        config[x.field] = x.content?.map((y: any) => y.value);
      })
      const result = await handleSaveExportHeaderConfiguration(item.id ?? '', config).then();
      if (result) {
        handleListExportHeaderConfiguration().then();
        toast.success('Cabeçalho configurado com sucesso!');
        onClose();
      }
    } else {
      toast.warning('Nenhuma coluna de planilha foi selecionada!');
    }
  }

  return (
    <ModalDefault
      title={`Configurar cabeçalho de exportação da financeira ${item?.financial?.name}`}
      show={show}
      onClose={onClose}
      sizeModal={'xl'}
      showFooter={true}
      buttonText={isLoading ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoading}
      disabledSubmit={isLoading}
      handleSubmit={onSubmit}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalProposalConfigExportInsert" data-testid="ModalProposalConfigExportInsert">
        <Row className='text-bolder text-primary mb-2'>
          <Col md={4}>
            Colunas
          </Col>
          <Col md={8}>
            Colunas Planilha
          </Col>
        </Row>
        {selectedItems?.map((x: any, index: number) => (
          <>
            <Row className=''>
              <Col md={4} className='text-sm align-items-center d-flex'>
                {NormalizesFieldNames(x.field)}
              </Col>
              <Col md={8}>
                <ReactSelect
                  name={`column${x.field}`}
                  isSearchable
                  isClearable={false}
                  isMulti
                  options={fieldsOption}
                  placeholder="Selecione..."
                  className={`form-control p-0`}
                  value={x.content}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  onChange={(val: any, action: any) => handleChangeOption(val, index, action)}
                  styles={customStyles}
                />
              </Col>
            </Row>
            <hr />
          </>
        ))}
      </div>
    </ModalDefault>
  );
}

export default ModalProposalConfigExportInsert;
