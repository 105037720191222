import { FC, useEffect, useState } from 'react';
import './ModalBoardReceiptInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../../../../providers/AuthProvider';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { schema } from './Validate';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { useEnterprise } from '../../../../providers/Registrations/Company/EnterpriseProvider';
import { useFinancial } from '../../../../providers/Registrations/Financial/FinancialProvider';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import moment from 'moment';
import { useReceiptUnknown } from '../../../../providers/Commissioning/Receipt/ReceiptUnknown/ReceiptUnknownProvider';
import { useReceipt } from '../../../../providers/Commissioning/Receipt/ReceiptProvider';
import FormatMoney from '../../../../utils/FormatMoney';
import { ReceiptEnterpriseIds } from '../../../../models/Commissioning/Receipt/ReceiptEnterpriseIds';

interface ModalBoardReceiptInsertProps {
  show: boolean;
  onClose: () => void;
}

const ModalBoardReceiptInsert: FC<ModalBoardReceiptInsertProps> = ({ show, onClose }) => {

  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [selectedEnterprise, setSelectedEnterprise] = useState<any>(null)
  const [selectedFinancial, setSelectedFinancial] = useState<any>(null)
  const [amount, setAmount] = useState<any>('');
  const [description, setDescription] = useState<any>('');
  const [startDate, setStartDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));

  const { receiptUnknown, setReceiptUnknown, setError, handleList, handleFetch } = useReceiptUnknown();
  const { handleListTotals } = useReceipt();
  const { enterprisesOptions, setParams: setParamsEnterprise } = useEnterprise();
  const { financialsOptions, setParams } = useFinancial();
  const { onInvalid } = useAuth();
  const animatedComponents = makeAnimated();

  const { handleSubmit, register, setValue, reset, control, formState: { errors } } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setError('');
    setSelectedEnterprise(null);
    setSelectedFinancial(null);
    setAmount('');
    setDescription('');
    setReceiptUnknown(undefined);
    reset();
    onClose();
  }

  useEffect(() => {
    setParams({ withPaginate: false });
    setParamsEnterprise({
      withPaginate: false,
      id: ReceiptEnterpriseIds
    });
  }, []);

  useEffect(() => {
    setValue('paymentDate', startDate)
  }, [startDate])

  useEffect(() => {
    setValue('amount', amount)
  }, [amount])

  useEffect(() => {
    setValue('description', description)
  }, [description])

  useEffect(() => {
    if (show) {
      if (receiptUnknown?.id) {
        reset();
        setSelectedEnterprise({ value: receiptUnknown?.enterprise?.id, label: receiptUnknown?.enterprise?.name });
        setValue('enterpriseId', receiptUnknown?.enterprise?.id ?? 0)

        setSelectedFinancial({ value: receiptUnknown?.financial?.id, label: receiptUnknown?.financial?.name });
        setValue('financialId', receiptUnknown?.financial?.id ?? 0)

        setAmount(receiptUnknown?.amount ?? '');
        setStartDate(moment(receiptUnknown?.paymentDate).format('YYYY-MM-DD HH:mm:ss'));
        setDescription(receiptUnknown.description ?? '');
      }
    }
  }, [show]);

  const onSubmit = async () => {
    setIsLoadingSubmit(true);
    setError('');

    let dataReceiptUnknown = {};

    if (receiptUnknown?.id) {
      dataReceiptUnknown = {
        id: receiptUnknown?.id,
        enterpriseId: selectedEnterprise?.value,
        financialId: selectedFinancial?.value,
        amount: amount,
        paymentDate: moment(startDate).format('YYYY-MM-DD'),
        description: description
      };
    } else {
      dataReceiptUnknown = {
        enterpriseId: selectedEnterprise?.value,
        financialId: selectedFinancial?.value,
        amount: amount,
        paymentDate: moment(startDate).format('YYYY-MM-DD'),
        description: description
      };
    }

    const ret = await handleFetch(dataReceiptUnknown);

    if (ret) {
      toast.success('Recebimento salvo com sucesso!');
      onClose();
      handleList();
      handleListTotals();
      setSelectedEnterprise(null);
      setSelectedFinancial(null);
      setAmount('');
      setStartDate(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
      setDescription('');
      setReceiptUnknown(undefined);
    }
    setIsLoadingSubmit(false);
  }


  const handleAmount = (e: any) => {
    const inputValue: string = e.target.value.replace(/\D/g, '');
    const numericValue: number = parseFloat(inputValue) / 100;
    setAmount(numericValue);
  };

  return (
    <ModalDefault
      title={'Adicionar recebimento desconhecido'}
      show={show}
      onClose={handleClose}
      sizeModal={'lg'}
      showFooter={true}
      buttonText={isLoadingSubmit ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoadingSubmit}
      disabledSubmit={isLoadingSubmit}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalBoardReceiptInsert" data-testid="ModalBoardReceiptInsert">
        <Row>
          <Col md={6}>
            <FormGroup className="form-group">
              <label>Empresa *</label>
              <Controller
                control={control}
                name={'enterpriseId'}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isClearable
                    isSearchable
                    placeholder="Selecione..."
                    options={enterprisesOptions}
                    className={`form-control p-0 ${onInvalid(errors?.enterpriseId)}`}
                    components={animatedComponents}
                    noOptionsMessage={() => 'Não há registros'}
                    value={selectedEnterprise || ''}
                    defaultValue={selectedEnterprise}
                    styles={customStyles}
                    onChange={(val: any) => {
                      onChange(val?.value ?? null);
                      setSelectedEnterprise(val);
                    }}
                  />
                )}
              />
              <ErrorMessage name={'Empresa'} type={errors?.enterpriseId?.type} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="form-group">
              <label>Financeira *</label>
              <Controller
                control={control}
                name={'financialId'}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isClearable
                    isSearchable
                    placeholder="Selecione..."
                    options={financialsOptions}
                    className={`form-control p-0 ${onInvalid(errors?.financialId)}`}
                    components={animatedComponents}
                    noOptionsMessage={() => 'Não há registros'}
                    value={selectedFinancial || ''}
                    defaultValue={selectedFinancial}
                    onChange={(val: any) => {
                      onChange(val?.value ?? null);
                      setSelectedFinancial(val);
                    }}
                    styles={customStyles}
                  />
                )}
              />
              <ErrorMessage name={'Financeira'} type={errors?.financialId?.type?.toString()} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="form-group">
              <label>Valor *</label>
              <input
                className={`form-control ${onInvalid(errors?.amount)}`}
                placeholder="Valor Recebido"
                value={FormatMoney(amount || '')}
                type="text"
                onChange={(e: any) => handleAmount(e)}
              />
              <ErrorMessage name={'Valor'} type={errors?.amount?.type} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              showStartDateClearButton={false}
              viewEndDate={false}
              startLabelText={'Data *'}
              nameStartDate=''
              nameEndDate=''
            />
            <ErrorMessage name={'Data'} type={errors?.paymentDate?.type?.toString()} />
          </Col>

          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="description">Descrição *</label>
              <textarea
                className={`form-control ${onInvalid(errors?.description)}`}
                rows={3}
                {...register('description')}
                onChange={(e: any) => setDescription(e?.target?.value)}
              ></textarea>
              <ErrorMessage name={'Descrição'} type={errors?.description?.type} min={3} max={255} />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
}

export default ModalBoardReceiptInsert;
