import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { ProposalImportService } from "../../../services/Proposals/Import/ProposalImportService";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { ToastSettings } from "../../../utils/ToastSettings";
import { ProposalImportListParams } from "../../../models/Proposals/Import/ProposalImportListParams";
import { ProposalImportModel } from "../../../models/Proposals/Import/ProposalImportModel";
import { ProposalImportFetchParams } from "../../../models/Proposals/Import/ProposalImportFetchParams";
import { ProposalConfigService } from "../../../services/Proposals/Config/ProposalConfigService";
import { ProposalConfigHeadersDataGetParams } from "../../../models/Proposals/Config/ProposalConfigHeadersDataGetParams";

interface ContextProps {
    imports: ProposalImportModel[],
    importData: any[],
    params: ProposalImportListParams,
    setParams: Dispatch<SetStateAction<ProposalImportListParams>>,
    paramsDetails: ProposalConfigHeadersDataGetParams | null,
    setParamsDetails: Dispatch<SetStateAction<ProposalConfigHeadersDataGetParams | null>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    pagesDetails: PagesPaginateModel,
    setPagesDetails: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    isLoadingsDetails: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => Promise<boolean>,
    handleFetch: (data: ProposalImportFetchParams, file: File[]) => Promise<boolean>,
    handleDownload: (id: number, isBBmais: boolean) => Promise<boolean>,
    handleReprocess: (id: number) => Promise<boolean>,
    handleListImportData: () => Promise<boolean>,
}

export const ProposalImportContext = createContext<ContextProps>({} as ContextProps);

interface ProposalImportProviderProps {
    children: ReactNode;
}

export const ProposalImportProvider = ({ children }: ProposalImportProviderProps) => {
    const [imports, setImports] = useState<ProposalImportModel[]>([]);
    const [importData, setImportData] = useState<any[]>([]);
    const [params, setParams] = useState<ProposalImportListParams>({ page: 1 });
    const [paramsDetails, setParamsDetails] = useState<ProposalConfigHeadersDataGetParams | null>(null);
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [pagesDetails, setPagesDetails] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingsDetails, setIsLoadingDetails] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new ProposalImportService();
    const configService = new ProposalConfigService();
    const location = useLocation();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/proposal/import/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setImports(_Response.data);
            setPages(_Response?.pages);
            setError('');
            return true;
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar as importações');
            return false;
        }
    }, [params, location.pathname]);

    const handleFetch = async (data: ProposalImportFetchParams, file: File[]) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.fetch(data, file[0]);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');

            ToastSettings('Importação criada com sucesso!', 'bottom-center', 'success');
            handleList();

            return true;
        } catch (e) {
            setError('Houve um erro ao salvar a importação');
            return false;
        }
    }

    const handleDownload = async (id: number, isBBmais: boolean) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.download(id, isBBmais);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');

            ToastSettings('Arquivo baixado com sucesso!', 'bottom-center', 'success');

            return true;
        } catch (e) {
            setError('Houve um erro ao baixar o arquivo');
            return false;
        }
    }

    const handleReprocess = async (id: number) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.reprocess(id);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');

            ToastSettings(_Response?.message, 'bottom-center', 'success');

            return true;
        } catch (e) {
            setError('Não foi possível reprocessar a importação');
            return false;
        }
    }

    const handleListImportData = async () => {
        try {
            setIsLoadingDetails(true);
            const [_Response, _Error] = await configService.listImportData(paramsDetails);
            setIsLoadingDetails(false);

            if (_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setImportData(_Response.data);
            setPagesDetails(_Response?.pages);
            setError('');
            return true;
        } catch (e) {
            setIsLoadingDetails(false);
            setError('Não foi possível carregar os dados da planilha');
            return false;
        }
    };

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    useEffect(() => {
        setError('');
    }, []);

    useEffect(() => {
        paramsDetails && handleListImportData();
    }, [paramsDetails]);

    return (
        <ProposalImportContext.Provider value={{
            imports,
            importData,
            params,
            setParams,
            paramsDetails,
            setParamsDetails,
            pages,
            setPages,
            pagesDetails,
            setPagesDetails,
            isLoading,
            isLoadingsDetails,
            error,
            setError,
            handleList,
            handleFetch,
            handleDownload,
            handleReprocess,
            handleListImportData
        }}>
            {children}
        </ProposalImportContext.Provider>
    );
}

export const useProposalImport = () => useContext(ProposalImportContext);