import React, { FC, useEffect, useState } from 'react';
import './ModalGoalsClone.scss';
import { useMonthlyGoal } from '../../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalProvider';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { MonthlyGoalGroup, MonthlyGoalGroupTagCondition, MonthlyGoalModel, MonthlyGoalOperationCondition, MonthlyGoalScore, MonthlyGoalScoreCondition } from '../../../../models/PanelConnects/MonthlyGoalModel';
import moment from 'moment';
import { Card, Col, FormCheck, FormGroup, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useMonthlyGoalPeriodConfiguration } from '../../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalPeriodConfigurationProvider';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import TooltipItem from '../../../TooltipItem/TooltipItem';
import { monthlyGoalAccessProfileOptions } from '../../../../models/PanelConnects/MonthlyGoalAccessProfileOptions';

interface ModalGoalsCloneProps {
  show: boolean,
  onClose: any
}

const ModalGoalsClone: FC<ModalGoalsCloneProps> = ({ show, onClose }) => {
  const [goalsToClone, setGoalsToClone] = useState<MonthlyGoalModel[]>([]);
  const [selectedGoalsToClone, setSelectedGoalsToClone] = useState<MonthlyGoalModel[]>([]);
  const [cloneErrorGoals, setCloneErrorGoals] = useState<any[]>([]);
  const [cloneSuccessGoals, setCloneSuccessGoals] = useState<MonthlyGoalModel[]>([]);
  const [originPeriodOptions, setOriginPeriodOptions] = useState<any[]>([]);
  const [destinationPeriodOptions, setDestinationPeriodOptions] = useState<any[]>([]);
  const [selectedOriginPeriod, setSelectedOriginPeriod] = useState<any>();
  const [selectedDestinationPeriod, setSelectedDestinationPeriod] = useState<any>();
  const [selectedOriginAccessProfile, setSelectedOriginAccessProfile] = useState<any>();
  const [selectedDestinationAccessProfile, setSelectedDestinationAccessProfile] = useState<any>();
  const [isCloning, setIsCloning] = useState<boolean>(false);
  const [wasCloned, setWasCloned] = useState<boolean>(false);

  const { isLoading, handleListToClone, handleSave, handleList } = useMonthlyGoal();
  const { listGoals, goals, setGoals } = useMonthlyGoalPeriodConfiguration();

  useEffect(() => {
    if (originPeriodOptions.length === 0) {
      getPeriodOptions('origin');
    }

    if (destinationPeriodOptions.length === 0) {
      getPeriodOptions('destination');
    }
  }, []);

  useEffect(() => {
    if (selectedOriginPeriod && selectedOriginAccessProfile) {
      getGoals();
    } else {
      setGoalsToClone([]);
      setSelectedGoalsToClone([]);
    }
  }, [selectedOriginPeriod, selectedOriginAccessProfile]);

  useEffect(() => {
    if (selectedDestinationPeriod && selectedDestinationAccessProfile) {
      destinationHasGoals().then();
    } else {
      setGoals([]);
    }
  }, [selectedDestinationPeriod, selectedDestinationAccessProfile]);

  const destinationHasGoals = async () => {
    const hasGoals = await listGoals(moment(selectedDestinationPeriod?.value, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'), selectedDestinationAccessProfile?.value);
    if (hasGoals) {
      Swal.fire({
        title: 'Atenção!',
        html: 'Esse período já possui <b>Metas</b>.',
        icon: 'warning',
        confirmButtonText: 'OK'
      });
    }
  }

  const getPeriodOptions = (type: 'origin' | 'destination') => {
    Array.from(Array(12)).forEach((x, i) => {
      if (type === 'origin') {
        originPeriodOptions?.push({
          label: moment(new Date()).subtract(i, 'M').format('MMMM/YY')?.toUpperCase(),
          value: moment(new Date()).subtract(i, 'M').format('YYYY-MM')
        });
      } else {
        destinationPeriodOptions?.push({
          label: moment(new Date()).add(i, 'M').format('MMMM/YY')?.toUpperCase(),
          value: moment(new Date()).add(i, 'M').format('YYYY-MM')
        });
      }
    });
  }

  const getGoals = async () => {
    const result = await handleListToClone(moment(selectedOriginPeriod?.value, 'YYYY-MM').format('YYYY-MM-01'), selectedOriginAccessProfile?.value);
    if (result?.length > 0) {
      setGoalsToClone(result);
      setSelectedGoalsToClone(result);
    } else {
      setGoalsToClone([]);
      setSelectedGoalsToClone([]);
    }
  }

  const onCheckGoal = (checked: boolean, goal: MonthlyGoalModel) => {
    if (checked) {
      setSelectedGoalsToClone((current) => [...current, goal]);
    } else {
      setSelectedGoalsToClone((current) => [...current.filter((x) => x.id !== goal.id)]);
    }
  }

  const onCheckAll = (checked: boolean) => {
    if (checked) {
      setSelectedGoalsToClone(goalsToClone);
    } else {
      setSelectedGoalsToClone([]);
    }
  }

  const resetCloning = () => {
    setGoalsToClone([]);
    setSelectedGoalsToClone([]);
    setCloneErrorGoals([]);
    setCloneSuccessGoals([]);
    setSelectedOriginPeriod(null);
    setSelectedDestinationPeriod(null);
    setGoals([]);
    setWasCloned(false);
  }

  const renderGoalsToClone = () => {
    return (
      goalsToClone?.map((goal) => (
        <tr key={goal.id}>
          <td className='text-wrap'>
            {goal?.connectType?.name}
            {(goal?.groups && goal?.groups?.length > 0) && ` - ${goal?.groups[0]?.name}`}
          </td>
          <td>{goal?.score}</td>
          <td className="align-middle">
            <div className="d-flex justify-content-end">
              <FormCheck className="form-check form-switch">
                <input
                  type="checkbox"
                  className='form-check-input'
                  defaultChecked={!!selectedGoalsToClone.find((p) => p.id === goal.id)}
                  checked={!!selectedGoalsToClone.find((p) => p.id === goal.id)}
                  onChange={(e) => onCheckGoal(e.target.checked, goal)}
                  disabled={wasCloned}
                />
              </FormCheck>
            </div>
          </td>
        </tr>
      ))
    );
  }

  const handleClose = () => {
    resetCloning();
    onClose();
  }

  const saveCloneGoal = async () => {
    setIsCloning(true);

    for (const goal of selectedGoalsToClone) {

      const _groups = goal?.groups?.map((_group: MonthlyGoalGroup) => ({
        ..._group,
        tags: _group?.tags?.map((_tag: MonthlyGoalGroupTagCondition) => ({
          ..._tag,
          connectGoalConditionId: _tag?.connectGoalCondition?.id,
          connectGoalTagId: _tag?.connectGoalTag?.id
        }))
      }));

      let _scores: MonthlyGoalScore[] = [];

      if (goal?.scores && goal?.scores?.length > 0) {
        _scores = goal?.scores?.map((_score: MonthlyGoalScore) => ({
          ..._score,
          id: undefined,
          conditions: _score?.conditions?.map((_condition: MonthlyGoalScoreCondition) => ({
            ..._condition,
            connectGoalConditionId: _condition?.connectGoalCondition?.id,
            scoreTagId: _condition?.scoreTag?.id,
            valueTagObj: _condition?.valueTag,
            valueTag: _condition?.valueTag?.id,
            operations: _condition?.operations?.map((_operation: MonthlyGoalOperationCondition) => ({
              ..._operation,
              operationId: _operation?.operation?.id,
              scoreTagId: _operation?.scoreTag?.id
            }))
          }))
        }));
      }

      const _goal: any = {
        ...goal,
        id: null,
        startDate: moment(selectedDestinationPeriod?.value, 'YYYY-MM').format('YYYY-MM-01'),
        endDate: moment(selectedDestinationPeriod?.value, 'YYYY-MM').endOf('month')?.format('YYYY-MM-DD'),
        groups: _groups,
        scores: _scores,
        connectTypeId: goal?.connectType?.id,
        accessProfileId: selectedDestinationAccessProfile?.value
      };
      const error = await handleSave(_goal, true);
      if (error) {
        setCloneErrorGoals((current) => [...current, { ..._goal, cloneError: error }]);
      } else {
        setCloneSuccessGoals((current) => [...current, _goal]);
      }
    }
    setIsCloning(false);
    setWasCloned(true);
    handleList();
    toast.success('A clonagem foi finalizada!');
  }

  const onSubmit = async () => {
    if (selectedDestinationPeriod && selectedOriginPeriod && selectedDestinationAccessProfile && selectedGoalsToClone?.length > 0) {
      Swal.fire({
        title: 'Atenção!',
        html: `Confirma a clonagem das metas selecionadas do período 
    <b>${moment(selectedOriginPeriod?.value, 'YYYY-MM').format('MMMM/YY')?.toUpperCase()}</b> para o período 
    <b>${moment(selectedDestinationPeriod?.value, 'YYYY-MM').format('MMMM/YY')?.toUpperCase()}</b>?`,
        confirmButtonText: "Sim",
        cancelButtonText: 'Não',
        showCancelButton: true,
        icon: 'question'
      }).then((value) => {
        if (value.isConfirmed) {
          saveCloneGoal().then();
        }
      });
    } else {
      toast.warning('É necessário selecionar o período a ser clonado, o período de destino, o tipo comercial de destino e selecionar ao menos uma meta para ser clonada!')
    }
  }

  return (
    <ModalDefault
      title={'Clonar Metas de Período'}
      show={show}
      onClose={handleClose}
      sizeModal={'xxl'}
      showFooter={true}
      buttonText={wasCloned ? 'Iniciar Nova Clonagem' : isCloning ? 'Clonando...' : 'Clonar'}
      showButtonSpinner={isCloning}
      disabledSubmit={isCloning}
      handleSubmit={wasCloned ? resetCloning : onSubmit}
      backdrop="static"
      disableEsc={true}
    >
      <div className="ModalGoalsClone" data-testid="ModalGoalsClone">
        <Row className='h-100'>
          <Col sm={6}>
            <Card>
              <Card.Header>Período a Ser Clonado</Card.Header>
              <Card.Body>
                <Row>
                  <Col md={6}>
                    <FormGroup className="form-group">
                      <label>PERÍODO</label>
                      <ReactSelect
                        name='originPeriod'
                        value={selectedOriginPeriod}
                        isSearchable
                        isClearable
                        options={originPeriodOptions}
                        placeholder="Selecione..."
                        className={`form-control p-0`}
                        noOptionsMessage={() => 'Não há registros'}
                        onChange={(val: any) => {
                          setSelectedOriginPeriod(val);
                        }}
                        styles={customStyles}
                        isDisabled={wasCloned}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="form-group">
                      <label>TIPO COMERCIAL</label>
                      <ReactSelect
                        name='originAccessProfileId'
                        value={selectedOriginAccessProfile}
                        isClearable={false}
                        options={monthlyGoalAccessProfileOptions}
                        placeholder="Selecione..."
                        className={`form-control p-0`}
                        noOptionsMessage={() => 'Não há registros'}
                        onChange={(val: any) => {
                          setSelectedOriginAccessProfile(val);
                        }}
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className='d-flex justify-content-between align-items-center'>
                    <label>METAS</label>
                    <div className="d-flex justify-content-center pe-1">
                      <TooltipItem position='top' text='Selecionar tudo'>
                        <FormCheck className="form-check form-switch">
                          <input
                            type="checkbox"
                            className='form-check-input'
                            checked={selectedGoalsToClone?.length === goalsToClone?.length}
                            onChange={(e) => onCheckAll(e.target.checked)}
                            disabled={wasCloned || goalsToClone?.length === 0}
                          />
                        </FormCheck>
                      </TooltipItem>
                    </div>
                  </Col>
                </Row>
                <div className='table-responsive'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th>Descrição</th>
                        <th>Score</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {goalsToClone?.length > 0 && !isLoading ?
                        renderGoalsToClone()
                        :
                        <tr>
                          <td colSpan={3} className='text-center td-info-msg text-sm'>
                            {!isLoading ?
                              <>
                                {selectedOriginPeriod && selectedOriginAccessProfile ?
                                  <>Não existem <b>Metas</b> para o <br />período e tipo selecionados!</>
                                  :
                                  <>Selecione um período e tipo para <br />listar as <b>Metas</b>.</>
                                }
                              </>
                              :
                              <FontAwesomeIcon icon={faSpinner} spin />
                            }
                          </td>
                        </tr>}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6}>
            <Row className='h-100'>
              <Col>
                <Card className='h-100'>
                  <Card.Header>Período de Destino</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="form-group">
                          <label>PERÍODO</label>
                          <ReactSelect
                            name='destinationPeriod'
                            value={selectedDestinationPeriod}
                            isSearchable
                            isClearable
                            options={destinationPeriodOptions}
                            placeholder="Selecione..."
                            className={`form-control p-0`}
                            noOptionsMessage={() => 'Não há registros'}
                            onChange={(val: any) => {
                              setSelectedDestinationPeriod(val);
                            }}
                            styles={customStyles}
                            isDisabled={wasCloned}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-group">
                          <label>TIPO COMERCIAL</label>
                          <ReactSelect
                            name='destinationAccessProfileId'
                            value={selectedDestinationAccessProfile}
                            isClearable={false}
                            options={monthlyGoalAccessProfileOptions}
                            placeholder="Selecione..."
                            className={`form-control p-0`}
                            noOptionsMessage={() => 'Não há registros'}
                            onChange={(val: any) => {
                              setSelectedDestinationAccessProfile(val);
                            }}
                            styles={customStyles}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className='gap-2 h-75'>
                      {goals?.length > 0 &&
                        <Col md={12}>
                          <Card className='h-100 shadow-sm'>
                            <Card.Header>Metas Já Configuradas</Card.Header>
                            <Card.Body>
                              <div className='table-responsive' style={{ maxHeight: '200px', minHeight: '0' }}>
                                <table className='table'>
                                  <thead>
                                    <tr>
                                      <th>Descrição</th>
                                      <th>Score</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {goals?.map((item) => (
                                      <tr key={item?.id}>
                                        <td>{item?.description}</td>
                                        <td>{item?.score}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>}

                      <Col md={12}>
                        <Card className='h-100 shadow-sm'>
                          <Card.Header>Metas Clonadas</Card.Header>
                          <Card.Body>
                            {cloneSuccessGoals?.length > 0 ?
                              <div className='table-responsive' style={{ maxHeight: '200px', minHeight: '0' }}>
                                <table className='table'>
                                  <thead>
                                    <tr>
                                      <th>Descrição</th>
                                      <th>Score</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {cloneSuccessGoals?.map((item) => (
                                      <tr key={item?.id}>
                                        <td className='text-wrap'>
                                          {item?.connectType?.name}
                                          {(item?.groups && item?.groups?.length > 0) && ` - ${item?.groups[0]?.name}`}
                                        </td>
                                        <td>{item?.score}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              :
                              <div className='d-flex justify-content-center align-items-center flex-column mt-2'>
                                <span>Aqui serão listadas as metas</span>
                                <span>clonadas com sucesso.</span>
                              </div>}
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col md={12}>
                        <Card className='h-100 shadow-sm'>
                          <Card.Header>Metas Erro ao Clonar</Card.Header>
                          <Card.Body>
                            {cloneErrorGoals?.length > 0 ?
                              <div className='table-responsive' style={{ maxHeight: '200px', minHeight: '0' }}>
                                <table className='table'>
                                  <thead>
                                    <tr>
                                      <th>Descrição</th>
                                      <th>Erro</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {cloneErrorGoals?.map((item) => (
                                      <tr key={item?.id}>
                                        <td className='text-wrap'>
                                          {item?.connectType?.name}
                                          {(item?.groups && item?.groups?.length > 0) && ` - ${item?.groups[0]?.name}`}
                                        </td>
                                        <td className='text-wrap'>{item?.cloneError}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              :
                              <div className='d-flex justify-content-center align-items-center flex-column mt-2'>
                                <span>Aqui serão listadas as metas que não</span>
                                <span>foram possíveis clonar.</span>
                              </div>}
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  )
};

export default ModalGoalsClone;
