import React, { FC } from 'react';
import './CommunicationContent.scss';
import { Card, Col, FormGroup } from 'react-bootstrap';
import { dateFormat } from '../../../../utils/FormatDate';

interface CommunicationContentProps {
  cyberIncident: any;
}

const CommunicationContent: FC<CommunicationContentProps> = ({ cyberIncident }) => {


  return (
    <div className="CommunicationContent" data-testid="CommunicationContent">
      <>
        <Col md={12}>
          <Card>
            <Card.Body>
              <Col>
                <FormGroup className="form-group" >
                  <label>NOTIFICAÇÕES INTERNAS REALIZADAS</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.internalNotifications}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>EQUIPES E DEPARTAMENTOS NOTIFICADOS</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.notifiedTeamsDepartments}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>MÉTODOS DE COMUNICAÇÃO UTILIZADOS</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.communicationMethodsUsed}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>RELATÓRIO ENVIADO AO CONTROLADOR</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.reportSentToController}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>DATA DO ENVIO</label>
                  <input
                    type="text"
                    className="form-control"
                    value={dateFormat({ date: cyberIncident?.communicationStage3?.submissionDate, datetime: true })}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>RESPONSÁVEL PELO ENVIO</label>
                  <input
                    type="text"
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.submissionResponsible}
                    disabled
                  />
                </FormGroup>
              </Col>
              <label>DETALHAMENTO AO CONTROLADOR</label>
              <Col>
                <FormGroup className="form-group" >
                  <label>Ações Imediatas Tomadas</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.controllerDetails?.immediateActionsTaken}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>{'Fonte do Incidente (se identificada)'}</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.controllerDetails?.incidentSourceIfIdentified}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Mudanças Implementadas para Prevenção Futura</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.controllerDetails?.futurePreventionChanges}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Impacto e Consequências</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.controllerDetails?.impactConsequences}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Impacto na Organização</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.controllerDetails?.organizationImpact}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Estimativa de Prejuízos</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.controllerDetails?.lossEstimation}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Recomendações</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.controllerDetails?.recommendations}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Sugestões para Melhorias na Segurança</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.controllerDetails?.securityImprovementSuggestions}
                    disabled
                  />
                </FormGroup>
              </Col>
              <label>REVISÃO PÓS-INCIDENTE</label>
              <Col>
                <FormGroup className="form-group" >
                  <label>Análise e Documentação</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.postIncidentReview?.analysisDocumentation}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Análise Detalhada do Incidente</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.postIncidentReview?.detailedIncidentAnalysis}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Documentação de Lições Aprendidas</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.postIncidentReview?.lessonsLearnedDocumentation}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Aprimoramento de Políticas</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.postIncidentReview?.policyEnhancements}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Atualizações em Políticas e Procedimentos</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.postIncidentReview?.policiesProceduresUpdates}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Implementação de Novas Medidas de Prevenção e Detecção</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.postIncidentReview?.newPreventionDetectionMeasuresImplementation}
                    disabled
                  />
                </FormGroup>
              </Col>
              <label>TREINAMENTO E CAPACITAÇÃO</label>
              <Col>
                <FormGroup className="form-group" >
                  <label>Treinamentos Realizados</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.trainingAndCapacityBuilding?.trainingsConducted}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Treinamentos de Segurança Cibernética</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.trainingAndCapacityBuilding?.cyberSecurityTrainings}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Simulações de Incidentes</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.trainingAndCapacityBuilding?.incidentSimulations}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Detalhes das Simulações</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.trainingAndCapacityBuilding?.simulationDetails}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Resultados e Melhorias Propostas</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.communicationStage3?.trainingAndCapacityBuilding?.resultsImprovementProposals}
                    disabled
                  />
                </FormGroup>
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </>
    </div>
  )
};

export default CommunicationContent;
