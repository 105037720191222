import * as yup from "yup";

export const schema = yup.object().shape({
    receiptTypeId: yup
        .number()
        .required(),
    campaignId: yup
        .number()
        .test('required', 'O campo de campanha é obrigatório.', function (value) {
            const { receiptTypeId } = this.parent;
            if (receiptTypeId === 4) {
                return !!value;
            }
            return true;
        })
});