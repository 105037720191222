import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import FormatArrayString from '../../utils/FormatArrayString';
import FormatBytes from '../../utils/FormatBytes';
import './InputDropzone.scss';

interface InputDropzoneProps extends React.HTMLAttributes<HTMLDivElement> {
    files: any[],
    setFiles: Dispatch<SetStateAction<any[]>>,
    label: string,
    name: string,
    types: any,
    maxSize: number,
    error?: boolean,
    modelFile?: string,
    classNamePreview?: string,
}

const InputDropzone: FC<InputDropzoneProps> = ({
    files,
    setFiles,
    label,
    name,
    types,
    maxSize = 500000,
    error = false,
    modelFile = '',
    classNamePreview = '',
    ...rest
}) => {
    const [typesStr, setTypesStr] = useState<string[]>([]);
    const [typesUpdate, setTypesUpdate] = useState<boolean>(false);

    const { getRootProps, getInputProps } = useDropzone({
        accept: types,
        maxSize: maxSize,
        maxFiles: 1,
        onDrop: (acceptedFiles: any[]) => {
            setFiles(acceptedFiles.map((file: any) => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const filesPreview = () => {
        return files.map((file: any, key: number) => (
            <div className="thumb" key={file?.path} {...rest}>
                <div className="thumb-inner">
                    {(['png', 'jpg', 'jpeg', 'ico'].indexOf(file.name.split('.').pop()) > -1) ? (
                        <img
                            src={file.preview}
                            className={`img-preview ${classNamePreview}`}
                            onLoad={() => {
                                URL.revokeObjectURL(file.preview)
                            }}
                            alt={file?.path}
                        />
                    ) : (
                        <p className="file-name m-0">
                            {(['xls', 'xlsx'].indexOf(file.name.split('.').pop()) > -1) && (
                                <i className="fa-solid fa-file-excel me-2"></i>
                            )}
                            {(['pdf'].indexOf(file.name.split('.').pop()) > -1) && (
                                <i className="fa-solid fa-file-pdf me-2"></i>
                            )}
                            {file?.path}
                        </p>
                    )}

                </div>

                <button className="btn btn-secondary" onClick={removeFile(key)}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        ));
    }

    const removeFile = (file: number) => () => {
        const newFiles = [...files];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFiles(newFiles);
    }

    useEffect(() => {
        setFiles([]);
    }, []);

    useEffect(() => {
        for (const key in types) {
            let listSort: any[] = typesStr;

            types[key]?.map((type: string) => {
                listSort.push(type);
            });

            setTypesStr(
                listSort.filter((value, index, self) =>
                    index === self.findIndex((t) => (t === value))
                )
            );
        }
    }, [typesUpdate]);

    useEffect(() => {
        setTypesStr([]);
        setTypesUpdate(!typesUpdate);
    }, [types])

    return (
        <div className="InputDropzone" data-testid="InputDropzone">
            <label className="title" htmlFor={name}>
                {label}

                {modelFile && (
                    <div className="text-sm">
                        <a target="_blank" href={modelFile} className="btn btn-sm btn-outline-primary m-0">
                            Arquivo Modelo
                        </a>
                    </div>
                )}
            </label>
            <FormGroup>
                {files.length > 0 ? (
                    <div className="dropzone">
                        {filesPreview()}
                    </div>
                ) : (
                    <div {...getRootProps({ className: `dropzone ${error ? 'error' : ''}` })}>
                        <input name={name} {...getInputProps()} />
                        <h5 className="mb-2">Arraste e solte o arquivo aqui<br />ou clique para selecioná-los</h5>
                        <p>*Tamanho máximo permitido é de <b>{FormatBytes(maxSize)}</b>.</p>
                        <p>*Apenas arquivos do tipo <b>{FormatArrayString(typesStr)}</b> serão aceitos.</p>
                    </div>
                )}
            </FormGroup>
        </div>
    );
}

export default InputDropzone;
