import { FC, useEffect, useState } from 'react';
import './MonthlyGoalPeriodConfiguration.scss';
import { toast } from 'react-toastify';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../providers/PaginateProvider';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import ModalMonthlyGoalPeriodConfigurationInsert from '../../../components/PanelConnects/PeriodConfiguration/ModalMonthlyGoalPeriodConfigurationInsert/ModalMonthlyGoalPeriodConfigurationInsert';
import moment from 'moment';
import { useMonthlyGoalPeriodConfiguration } from '../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalPeriodConfigurationProvider';
import SidebarMonthlyGoalPeriodConfigurationFilter from '../../../components/PanelConnects/PeriodConfiguration/SidebarMonthlyGoalPeriodConfigurationFilter/SidebarMonthlyGoalPeriodConfigurationFilter';
import { MonthlyGoalPeriodConfigListModel } from '../../../models/PanelConnects/MonthlyGoalPeriodConfigListModel';

interface MonthlyGoalPeriodConfigurationProps { }

const MonthlyGoalPeriodConfiguration: FC<MonthlyGoalPeriodConfigurationProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
  const [periodEdit, setPeriodEdit] = useState<MonthlyGoalPeriodConfigListModel>();

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { handlePaginate } = usePaginate();
  const { handleList, configurations, params, setParams, isLoading, pages } = useMonthlyGoalPeriodConfiguration();

  useEffect(() => {
    handleList();
  }, [params]);

  useEffect(() => {
    if (!showModalInsert) {
      setPeriodEdit(undefined);
    }
  }, [showModalInsert]);

  const handleEdit = (item: MonthlyGoalPeriodConfigListModel) => {
    setPeriodEdit(item);
    setShowModalInsert(true);
  }

  const canEdit = (date: string) => {
    return moment(new Date())?.subtract(1, 'month')?.startOf('month')?.format('YYYY-MM-DD') <= date;
  }

  const renderList = (): JSX.Element => {
    return (
      <>
        {configurations.length > 0 ? (
          <>
            {configurations.map((x: MonthlyGoalPeriodConfigListModel, key: number) => (
              <tr key={x.id} className="text-sm">
                <td>{moment(x?.closingDate, 'YYYY-MM-DD').format('MMMM/YY')?.toUpperCase()}</td>
                <td>{x?.accessProfile?.name}</td>
                <td>{moment(x?.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td>{moment(x?.updatedAt).format('DD/MM/YYYY HH:mm:ss')?.toUpperCase()}</td>
                <td className="text-center">
                  <DropdownMenuTable key={key}>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleEdit(x)}>
                      <FontAwesomeIcon icon={faEdit} className="me-2" />
                      {canEdit(x?.closingDate ?? '') ? 'Editar' : 'Visualizar'}
                    </Dropdown.Item>
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={5} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <>
            <div className="table-responsive">
              <table className="table table-flush m-0 w-100">
                <thead className="thead-light">
                  <tr>
                    <th className="text-uppercase opacity-7" scope="col">Período</th>
                    <th className="text-uppercase opacity-7" scope="col">Tipo Comercial</th>
                    <th className="text-uppercase opacity-7" scope="col">Data Criação</th>
                    <th className="text-uppercase opacity-7" scope="col">Última Alteração</th>
                    <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {renderList()}
                </tbody>
              </table>
            </div>
            {configurations.length > 0 && (
              <div>
                <nav aria-label="...">
                  <AppPagination
                    pages={pages}
                    handlePaginate={handlePaginate}
                    params={params}
                    setParams={setParams}
                    style={{ backgroundColor: '#fff' }}
                  />
                </nav>
              </div>
            )}
          </>
        ) : (
          <SkeletonTable />
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="Configuração de Período"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="MonthlyGoalPeriodConfiguration" data-testid="MonthlyGoalPeriodConfiguration">
        <PageBase
          title="Configuração de Período"
          subtitle="Configuração de categorias e tipos dos períodos."
          handleInsert={() => setShowModalInsert(true)}
          handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          content={renderContent()}
          hasFilter
        />

        <ModalMonthlyGoalPeriodConfigurationInsert
          show={showModalInsert}
          onClose={() => setShowModalInsert(false)}
          periodEdit={periodEdit}
          setPeriodEdit={setPeriodEdit}
        />
      </div>

      <SidebarMonthlyGoalPeriodConfigurationFilter />
    </MasterPage>
  );
}

export default MonthlyGoalPeriodConfiguration;
