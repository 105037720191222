import { ProposalRuleOperationListParams } from "../../../models/Proposals/Rule/Operation/ProposalRuleOperationListParams";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/services/proposals/rule/operation';

export class ProposalRuleOperationService extends BaseService {
    async list(params: ProposalRuleOperationListParams): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}