import { FC, useEffect } from 'react';
import { TabEnumIndicatorManagement } from '../../../../pages/Visits/IndicatorManagement/IndicatorManagement';
import './FormRankingVisits.scss';
import VisitsRankingFormContract from './VisitsRankingFormContract/VisitsRankingFormContract';
import VisitsRankingFinancial from './VisitsRankingFinancial/VisitsRankingFinancial';
import VisitsRankingCovenant from './VisitsRankingCovenant/VisitsRankingCovenant';
import VisitsRankingAgent from './VisitsRankingAgent/VisitsRankingAgent';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useVisitsRanking } from '../../../../providers/Visits/VisitsRanking/VisitsRankingProvider';
import { toast } from 'react-toastify';
import { useIndicatorManagementWalletVisits } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementWalletProvider';

interface FormRankingVisitsProps {
  currentActiveTab?: TabEnumIndicatorManagement;
}

const FormRankingVisits: FC<FormRankingVisitsProps> = ({ currentActiveTab }) => {

  const { handleList } = useVisitsRanking();

  const { params } = useIndicatorManagementWalletVisits();

  useEffect(() => {
    if (currentActiveTab === TabEnumIndicatorManagement.RANKING) {
      if (params?.commercialId) {
        handleList(params).then();
      } else {
        toast.warning('Faça um filtro para listar o Ranking!');
      }
    }
  }, [currentActiveTab, params]);

  return (
    <div className="FormRankingVisits" data-testid="FormRankingVisits">

      <Row md={12}>
        <Col >
          <Tabs
            defaultActiveKey="form_contract"
            className="mb-3"
            variant='pills'
            fill
          >
            <Tab eventKey="form_contract" title="FORMA CONTRATO">
              <VisitsRankingFormContract />
            </Tab>
            <Tab eventKey="financial" title="FINANCEIRA">
              <VisitsRankingFinancial />
            </Tab>
            <Tab eventKey="covenant" title="CONVÊNIO">
              <VisitsRankingCovenant />
            </Tab>
            <Tab eventKey="agent" title="AGENTE">
              <VisitsRankingAgent />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
}

export default FormRankingVisits;
