import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { ToastSettings } from "../../../utils/ToastSettings";
import { EnterpriseService } from "../../../services/Registrations/Company/EnterpriseService/EnterpriseService";
import { EnterpriseParams } from "../../../models/Registrations/Company/Enterprise/EnterpriseParams";
import { EnterpriseModel } from "../../../models/Registrations/Company/Enterprise/EnterpriseModel";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";


interface ContextProps {
    enterprise: EnterpriseModel,
    setEnterprise: Dispatch<SetStateAction<EnterpriseModel>>,
    enterprises: EnterpriseModel[],
    enterprisesOptions: ReactSelectOptions[],
    params: EnterpriseParams,
    setParams: Dispatch<SetStateAction<EnterpriseParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleFetch: (data: EnterpriseModel) => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>,
    handleListEnterprisesOptions: (withPaginate: boolean) => Promise<boolean | void>
}

export const EnterpriseContext = createContext<ContextProps>({} as ContextProps);

interface EnterpriseProviderProps {
    children: ReactNode;
}

export const EnterpriseProvider = ({ children }: EnterpriseProviderProps) => {
    const [enterprise, setEnterprise] = useState<EnterpriseModel>({} as EnterpriseModel);
    const [enterprises, setEnterprises] = useState<EnterpriseModel[]>([]);
    const [params, setParams] = useState<EnterpriseParams>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [enterprisesOptions, setEnterprisesOptions] = useState<ReactSelectOptions[]>([]);


    const service = new EnterpriseService();
    const location = useLocation();

    const handleList = useCallback(async () => {

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response?.message || _Error);
            }

            setEnterprises(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar as empresas do sistema.');
        }
    }, [params]);

    const handleListEnterprisesOptions = async (withPaginate: boolean) => {

        try {
            setParams({ withPaginate });
            setIsLoading(true);

            const [_Response, _Error] = await service.list(params);

            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response?.message || _Error);
            }

            setEnterprises(_Response.data);
            setPages(_Response?.pages);
            setError('');
            return true;
        }
        catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar as financeiras');
            return false;
        }
    }

    const handleFetch = async (data: EnterpriseModel) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.fetch(data);
            if (!!_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setError('Houve um erro ao salvar a empresa');
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar a empresa');
            return false;
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        !!params?.id && handleList().then();
    }, [handleList]);

    useEffect(() => {
        setEnterprisesOptions(
            enterprises?.filter(item => item?.active === 1)
                ?.map((item: EnterpriseModel) => {
                    return { label: item.name, value: item.id };
                })
        )
    }, [enterprises]);


    return (
        <EnterpriseContext.Provider value={{
            enterprise,
            setEnterprise,
            enterprises,
            enterprisesOptions,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleFetch,
            handleDelete,
            handleListEnterprisesOptions
        }}>
            {children}
        </EnterpriseContext.Provider>
    );
}

export const useEnterprise = () => useContext(EnterpriseContext);