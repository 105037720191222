import { FC } from 'react';
import './ReportRequest.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import { usePaginate } from '../../../providers/PaginateProvider';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import { useReportRequest } from '../../../providers/Reports/ReportRequest/ReportRequestProvider';
import AppPagination from '../../../components/AppPagination/AppPagination';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import moment from 'moment';
import { ReportRequestModel } from '../../../models/Reports/ReportRequest/ReportRequestModel';
import SidebarReportRequestFilter from '../../../components/Reports/ReportRequest/SidebarReportRequestFilter/SidebarReportRequestFilter';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import FileDownloadButton from '../../../components/FileDownloadButton/FileDownloadButton';

interface ReportRequestProps { }

const ReportRequest: FC<ReportRequestProps> = () => {
  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { pages, params, setParams, isLoading, error, reportRequests, isLoadingDownload, handleDownload } = useReportRequest();
  const { handlePaginate } = usePaginate();

  const renderList = (): JSX.Element => {
    return (
      <>
        {reportRequests?.length > 0 ? (
          <>
            {reportRequests.map((x: ReportRequestModel, key: number) => (
              <tr key={x.id} className="text-sm">
                <td className="text-center">{x.id}</td>
                <td>{x?.user?.name}</td>
                <td>{x?.reports?.name}</td>
                <td className="text-center">
                  <div className="badge badge-sm" style={{ backgroundColor: x?.reportStatus?.bgColor }}>{x?.reportStatus?.name}</div>
                </td>
                <td className="text-center">{moment(x.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td className="text-center">
                  {x?.reportStatus?.id === 3 && (
                    <DropdownMenuTable key={key}>
                      <FileDownloadButton
                        handleDownload={() => handleDownload(x.id || 0)}
                        isLoading={isLoadingDownload}
                        error={error}
                        textButton="Baixar relatório"
                        typeButton='dropdown'
                      />
                    </DropdownMenuTable>
                  )}
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={6} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7 text-center" scope="col">#</th>
                  <th className="text-uppercase opacity-7" scope="col">Usuário Solicitante</th>
                  <th className="text-uppercase opacity-7" scope="col">Relatório</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Status</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Data da Solicitação</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}

        {reportRequests?.length > 0 && (
          <div>
            <nav aria-label="...">
              <AppPagination
                pages={pages}
                handlePaginate={handlePaginate}
                params={params}
                setParams={setParams}
                style={{ backgroundColor: '#fff' }}
              />
            </nav>
          </div>
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="Solicitações de Relatórios"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="ReportRequest" data-testid="ReportRequest">
        <PageBase
          title="Solicitações de Relatórios"
          subtitle="Listagem de solicitações de relatórios do sistema."
          handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          error={error}
          content={renderContent()}
          hasFilter
        />
      </div>

      <SidebarReportRequestFilter />
    </MasterPage>
  );
}

export default ReportRequest;
