import { BiProductionParamsModel } from "../../../models/BI/Dashboard/BiProductionParamsModel";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = "api/bi";
export class BiDashboardService extends BaseService {
  async listGroupReport(params: any): Promise<any[]> {
    try {
      return this.handleResponse(
        await api.post(`${URL}/report/group/list`, params)
      );
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async listMonthlyProduction(params: BiProductionParamsModel): Promise<any[]> {
    try {
      return this.handleResponse(
        await api.post(`api/services/bi/bi/data/month`, params)
      );
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async listAnnualProduction(params: BiProductionParamsModel): Promise<any[]> {
    try {
      return this.handleResponse(
        await api.post(`api/services/bi/bi/data/year`, params)
      );
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async listStatusProduction(params: BiProductionParamsModel): Promise<any[]> {
    try {
      return this.handleResponse(
        await api.post(`api/services/bi/bi/data/status`, params)
      );
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async listBroadProduction(params: BiProductionParamsModel): Promise<any[]> {
    try {
      return this.handleResponse(
        await api.post(`api/services/bi/bi/data/broad`, params)
      );
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async listDebtor(params: BiProductionParamsModel): Promise<any[]> {
    try {
      return this.handleResponse(
        await api.post(`api/services/bi/bi/data/debtor`, params)
      );
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async listRangeProduction(params: BiProductionParamsModel): Promise<any[]> {
    try {
      return this.handleResponse(
        await api.post(`api/services/bi/bi/data/range`, params)
      );
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }
}
