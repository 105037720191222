import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { DashboardService } from "../../../services/Marketplace/Dashboard/DashboardService";
import {ToastSettings} from "../../../utils/ToastSettings";
import {toast} from "react-toastify";
import {useReportRequest} from "../../Reports/ReportRequest/ReportRequestProvider";
import {CampaignModel} from "../../../models/Marketplace/Campaign/CampaignModel";
import { DashboardEnum } from "../../../enums/Superset/DashboardEnum";

interface ContextProps {
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    isLoading: boolean,
    hasElem: boolean,
    setHasElem: Dispatch<SetStateAction<boolean>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
}

export const DashboardContext = createContext<ContextProps>({} as ContextProps);

interface DashboardProviderProps {
    children: ReactNode;
}

export const DashboardProvider = ({ children }: DashboardProviderProps) => {
    const [params, setParams] = useState<any>({});
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasElem, setHasElem] = useState<boolean>(false);

    const service = new DashboardService();

    return (
        <DashboardContext.Provider value={{
            isLoading,
            error,
            setError,
            params,
            setParams,
            hasElem,
            setHasElem,
        }}>
            {children}
        </DashboardContext.Provider>
    )
}

export const useDashboard = () => useContext(DashboardContext);

export async function fetchGuestTokenFromBackend(){
    try {
        const service = new DashboardService();
        const [_Response, _Error] = await service.getGuestToken(DashboardEnum.MARKETPLACE);
        if (!!_Error) {
            return "";
        }
        
        return _Response?.data?.token;

    } catch (error: any) {
        console.warn(error);
        return "";
    }
};
