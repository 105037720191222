import { FC, useState } from 'react';
import './CyberIncidentResponseForm.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../providers/PaginateProvider';
import { useExample } from '../../../providers/ExampleProvider';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import ModalCyberIncidentResponseForm from '../../../components/Security/ModalCyberIncidentResponseForm/ModalCyberIncidentResponseForm';
import { useCyberIncident } from '../../../providers/Security/CyberIncidentProvider';
import { dateFormat } from '../../../utils/FormatDate';
import SidebarCyberIncidentResponseFormFilter from '../../../components/Security/SidebarCyberIncidentResponseFormFilter/SidebarCyberIncidentResponseFormFilter';

interface CyberIncidentResponseFormProps { }

const CyberIncidentResponseForm: FC<CyberIncidentResponseFormProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { cyberIncidents, setCyberIncident, pages, params, setParams, isLoading, error } = useCyberIncident();
  const { handlePaginate } = usePaginate();

  const handleViewForm = (item: any) => {
    setCyberIncident(item)
    setShowModalInsert(true);
  }


  const renderList = (): JSX.Element => {
    return (
      <>
        {cyberIncidents?.length > 0 ? (
          <>
            {cyberIncidents.map((x: any, key: number) => (
              <tr key={x._id} className="text-sm">
                <td>{x?.incidentStage1?.reportResponsibleName}</td>
                <td className="text-center">{x?.incidentStage1?.department}</td>
                <td className="text-center">{dateFormat({ date: x?.incidentStage1?.detectionDateTime, datetime: true })}</td>
                <td>
                  <DropdownMenuTable key={key}>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleViewForm(x)}>
                      <FontAwesomeIcon icon={faEye} className="me-2" /> Visualizar Formulário
                    </Dropdown.Item>
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={4} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7" scope="col">Nome</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Departamento</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Data e Hora <br /> da Detecção</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>

            {cyberIncidents?.length > 0 && (
              <div>
                <nav aria-label="...">
                  <AppPagination
                    pages={pages}
                    handlePaginate={handlePaginate}
                    params={params}
                    setParams={setParams}
                    style={{ backgroundColor: '#fff' }}
                  />
                </nav>
              </div>
            )}
          </div>
        ) : (
          <SkeletonTable />
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="Formulário de Resposta a Incidentes Cibernéticos"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="CyberIncidentResponseForm" data-testid="CyberIncidentResponseForm">
        <PageBase
          title="Formulário a Incidentes Cibernéticos"
          subtitle="Gerenciamento de formulário de resposta a Incidentes Cibernéticos."
          handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          error={error}
          content={renderContent()}
          hasFilter
        />

        <ModalCyberIncidentResponseForm
          show={showModalInsert}
          onClose={() => setShowModalInsert(false)}
        />
      </div>

      <SidebarCyberIncidentResponseFormFilter />
    </MasterPage>
  );
}

export default CyberIncidentResponseForm;
