import * as yup from "yup";

export const schema = yup.object().shape({
    name: yup
        .string()
        .required()
});

export const schemaCondition = yup.object().shape({
    tag: yup
        .object()
        .required(),
    condition: yup
        .object()
        .required(),
    value: yup
        .array()
        .min(1)
        .required()
});