import React, { FC, useEffect, useMemo, useState } from "react";
import "./PartnerSituationExecutive.scss";
import { Card, Col, Row } from "react-bootstrap";
import { useBiExecutive } from "../../../../../providers/BI/Dashboard/Executive/BiExecutiveProvider";
import AppDataTable from "../../../../AppDataTable/AppDataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { useBiDashboard } from "../../../../../providers/BI/Dashboard/BiDashboardProvider";
import { BI_VIEWS_ENUM } from "../../../../../models/BI/Dashboard/BiProductionParamsModel";
import SkeletonTable from "../../../../SkeletonTable/SkeletonTable";
import FormatMoney from "../../../../../utils/FormatMoney";

interface PartnerSituationExecutiveProps {}

const PartnerSituationExecutive: FC<PartnerSituationExecutiveProps> = () => {
  const [columns, setColumns] = useState<any[]>([]);
  const [hideButtons, setHideButtons] = useState<any[]>([]);

  const { executivesPartner, setExecutivesPartner } = useBiExecutive();
  const {
    handleListStatusProduction,
    isLoading,
    params,
    setParams,
    setHandleListOnFilter,
    resultOnFilter,
    setResultOnFilter,
    generalErrorMsg,
    isParamsUpdated,
    setIsParamsUpdated,
    handleExcel,
  } = useBiDashboard();

  useEffect(() => {
    if (isParamsUpdated) {
      setHandleListOnFilter(() => handleListStatusProduction);
      setParams({
        ...params,
        view: BI_VIEWS_ENUM.EXECUTIVE,
        group: {
          financial: true,
        },
      });
      if (executivesPartner.length === 0) {
        handleFetch().then();
      }
      setIsParamsUpdated(false);
    }
  }, [isParamsUpdated]);

  useEffect(() => {
    if (resultOnFilter) {
      setExecutivesPartner(resultOnFilter);
      setResultOnFilter(undefined);
    }
  }, [resultOnFilter]);

  const handleFetch = async () => {
    const result = await handleListStatusProduction({
      ...params,
      view: BI_VIEWS_ENUM.EXECUTIVE,
      group: {
        financial: true,
      },
    }).then();

    if (result) {
      setExecutivesPartner(result);
    }
  };

  const handleExport = (rows: any) => {
    const _data: any[] = [];
    rows.forEach((item: any) => {
      const _row: any = {
        Filial: item.branchName,
        Total: item.total,
        Ativos: item.active,
        Inativos: item.inactive,
        "Participacao Ativos": item.activePercent,
        "Participacao inativos": item.inactivePercent,
      };
      _data.push(_row);
    });
    handleExcel(_data, "Situação de parceiro por executivo");
  };

  useEffect(() => {
    mountHideButtons();
  }, [executivesPartner]);

  const hasData = (): boolean => {
    if (executivesPartner && executivesPartner?.length > 0) {
      return true;
    }
    return false;
  };

  const mountColumns = useMemo(() => {
    if (hasData()) {
      const _columns: any = [
        {
          id: "colIndex",
          name: <div className="bg-info text-table text-uppercase">Filial</div>,
          selector: (data: any) => data.branchName,
          cell: (data: any) => (
            <div className="text-wrap text-bold">{data?.branchName}</div>
          ),
          sortable: true,
          width: "250px",
        },
        {
          name: <div className="bg-info text-table text-uppercase">Total</div>,
          selector: (data: any) => data.total,
          sortable: true,
          center: true,
          omit: !hideButtons?.find((x: any) => x.alias === "total")?.show,
        },
        {
          name: <div className="bg-info text-table text-uppercase">Ativos</div>,
          selector: (data: any) => data.active,
          sortable: true,
          center: true,
          omit: !hideButtons?.find((x: any) => x.alias === "active")?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">Inativos</div>
          ),
          selector: (data: any) => data.inactive,
          sortable: true,
          center: true,
          omit: !hideButtons?.find((x: any) => x.alias === "inactive")?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Participação <br />
              Ativos
            </div>
          ),
          selector: (data: any) => data.activePercent,
          right: true,
          cell: (data: any) => (
            <div>{FormatMoney(data.activePercent, false)}%</div>
          ),
          sortable: true,
          omit: !hideButtons?.find((x: any) => x.alias === "activePercent")
            ?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Participação <br />
              Inativos
            </div>
          ),
          selector: (data: any) => data.inactivePercent,
          right: true,
          cell: (data: any) => (
            <div>{FormatMoney(data.inactivePercent, false)}%</div>
          ),
          sortable: true,
          omit: !hideButtons?.find((x: any) => x.alias === "inactivePercent")
            ?.show,
        },
      ];
      setColumns(_columns);
    }
  }, [executivesPartner, hideButtons]);

  const mountRows = (items: any[]) => {
    const _rows: any = [];

    items.forEach((item: any) => {
      const _row: any = {
        branchName: item?.branchName,
        total: item.total,
        active: item?.active,
        inactive: item?.inactive,
        activePercent: item?.activePercent,
        inactivePercent: item?.inactivePercent,
      };

      _rows.push(_row);
    });

    return _rows;
  };

  const mountHideButtons = () => {
    if (hasData()) {
      const _buttons: any = [];

      _buttons.push({
        alias: `total`,
        label: "Total",
        show: true,
      });

      _buttons.push({
        alias: `active`,
        label: "Ativo",
        show: true,
      });

      _buttons.push({
        alias: `inactive`,
        label: "Inativo",
        show: true,
      });

      _buttons.push({
        alias: `activePercent`,
        label: "Participação Ativo",
        show: true,
      });

      _buttons.push({
        alias: `inactivePercent`,
        label: "Participação Inativo",
        show: true,
      });

      setHideButtons(_buttons);
    }
  };

  const onClickHideButton = (button: any, index: number) => {
    const _hideButtons = [...hideButtons];
    button.show = !button.show;
    _hideButtons[index] = button;
    setHideButtons(_hideButtons);
  };

  const renderTable = (x: any) => {
    const rows = mountRows(x?.items);

    return (
      <Card className="mt-4" key={x?.executiveName}>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center w-100 mb-2">
            <span className="text-start text-primary pb-1">{`${x?.executiveName} - ${x?.executivePartnerCode}`}</span>
            <button
              className="btn bg-success text-white rounded m-0 ms-5"
              onClick={() => {
                handleExport(rows);
              }}
            >
              <i className="fas fa-file-excel" /> Exportar
            </button>
          </div>
          <AppDataTable columns={columns} rows={rows} pagination={false} />
        </Card.Body>
      </Card>
    );
  };

  return (
    <div
      className="PartnerSituationExecutive"
      data-testid="PartnerSituationExecutive"
    >
      <Col md={12} className="d-flex justify-content-end">
        <Row className="display-button mb-3">
          <div className="d-flex justify-content-between align-items-center flex-md-row">
            <div className="d-flex gap-1 justify-content-center align-items-center flex-md-row">
              {hideButtons?.map((x: any, index: number) => (
                <button
                  type="button"
                  className="btn bg-primary text-white mb-0 ms-2 min-width-btn text-capitalize"
                  onClick={() => onClickHideButton(x, index)}
                >
                  <FontAwesomeIcon
                    icon={x.show ? faEye : faEyeSlash}
                    className="me-1"
                  />
                  {x.label}
                </button>
              ))}
            </div>
          </div>
        </Row>
      </Col>

      <Col sm={12}>
        <div
          style={{ zIndex: "auto" }}
          className="d-flex justify-content-center align-items-center alert alert-primary text-table mt-3"
        >
          <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
          Atenção: Este relatório apresenta estatísticas atualizadas e não
          possui filtros aplicáveis.
        </div>
      </Col>

      {!isLoading ? (
        <>
          {executivesPartner?.length > 0 ? (
            executivesPartner?.map((item: any) => renderTable(item))
          ) : (
            <div className="d-flex mt-9 justify-content-center general-error-msg">
              {generalErrorMsg}
            </div>
          )}
        </>
      ) : (
        <SkeletonTable />
      )}
    </div>
  );
};

export default PartnerSituationExecutive;
