import { FC } from "react";
import "./PopoverHelp.scss";
import { OverlayTrigger, Popover } from "react-bootstrap";

interface PopoverHelpProps {
  placement: "top" | "bottom" | "left" | "right";
  header?: React.JSX.Element;
  body?: React.JSX.Element;
  children: React.JSX.Element;
}

const PopoverHelp: FC<PopoverHelpProps> = ({
  placement,
  header,
  body,
  children,
}) => {
  return (
    <OverlayTrigger
      trigger="click"
      placement={placement}
      overlay={
        <Popover className="PopoverHelp">
          <Popover.Header as="h3">{header}</Popover.Header>
          <Popover.Body className="pt-0">{body}</Popover.Body>
        </Popover>
      }
      rootClose
    >
      <div className="PopoverHelp" data-testid="PopoverHelp">
        {children}
      </div>
    </OverlayTrigger>
  );
};

export default PopoverHelp;
