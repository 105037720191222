import { FC, useState } from 'react';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FinancialFormFilterParams } from '../../../../models/Registrations/Financial/FinancialFormFilterParams';
import moment from 'moment';
import { useFinancial } from '../../../../providers/Registrations/Financial/FinancialProvider';
import InputDateRange from '../../../InputDateRange/InputDateRange';

interface SidebarFinancialFilterProps { }

// @ts-ignore
export const defaultValues = {
  name: '',
  startDate: '',
  endDate: '',
} as FinancialFormFilterParams;

const SidebarFinancialFilter: FC<SidebarFinancialFilterProps> = () => {
  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));

  const { handleSubmit, register, setValue } = useForm<any>({ defaultValues });

  const { params, setParams } = useFinancial();

  const onSubmit = async (data: FinancialFormFilterParams) => {
    data.startDate = data?.startDate ? moment(data?.startDate).format('YYYY-MM-DD') : '';
    data.endDate = data?.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : '';

    setParams({ ...params, ...data, ...{ page: 1 } });
  }

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarFinancialFilter" data-testid="SidebarFinancialFilter">
        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="name">NOME</label>
              <input
                className="form-control"
                {...register('name')}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              startLabelText={'DATA INICIAL'}
              endLabelText={'DATA FINAL'}
            />
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  );
}

export default SidebarFinancialFilter;
