
export function CustomHeaderTemplateDataTable(html: string, style: string = 'd-flex flex-column justify-content-center align-items-center'): string {
  return `
              <div class="ag-cell-label-container" role="presentation">
                  <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                  <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
                  <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">                    
                    <div class="d-flex">                        
                        <span class="${style}">                    
                            <span data-ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                            ${html}
                        </span>
                        <div>
                            <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
                            <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
                            <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
                            <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
                        </div>
                    </div>                    
                    <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                  </div>
                </div>
              `;
}