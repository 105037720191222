import FileSaver from "file-saver";
import api from "../../Api";
import { BaseService } from "../../BaseService";
import { VisitsActionsParams } from "../../../models/Visits/VisitsActions/VisitsActionsParams";

const URL = 'api/domus/pgi/quality';
export class IndicatorManagementQualityService extends BaseService {

    async getQualityList(params: VisitsActionsParams): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getExcel(data:any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/export`, data));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

}