import { FC, useEffect, useMemo, useState } from 'react';
import './WalletVisitsList.scss';
import { Card, Dropdown } from 'react-bootstrap';
import FormatMoney from '../../../../../utils/FormatMoney';
import AppDataTable from '../../../../AppDataTable/AppDataTable';
import SkeletonTable from '../../../../SkeletonTable/SkeletonTable';
import { useIndicatorManagementPreVisitVisits } from '../../../../../providers/Visits/IndicatorManagement/IndicatorManagementPreVisitProvider';
import { useIndicatorManagementWalletVisits } from '../../../../../providers/Visits/IndicatorManagement/IndicatorManagementWalletProvider';
import { NextVisitModel, WalletResultModel } from '../../../../../models/Visits/IndicatorManagement/IndicatorManagementWalletModel';
import { dateFormat } from '../../../../../utils/FormatDate';
import { FormataStringCurrencyToNumber } from '../../../../../utils/FormataStringCurrencyToNumber';
import moment from 'moment';
import DropdownMenuTable from '../../../../DropdownMenuTable/DropdownMenuTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { PreVisitModel } from '../../../../../models/Visits/IndicatorManagement/PreVisitModel';
import { toast } from 'react-toastify';
import ModalPreVisits from '../../ModalPreVisits/ModalPreVisits';
import ModalFraming from '../../ModalFraming/ModalFraming';

interface WalletVisitsListProps {
}

const WalletVisitsList: FC<WalletVisitsListProps> = () => {

  const [colSpanDig, setColSpanDig] = useState(true);
  const [colSpanProd, setColSpanProd] = useState(true);
  const [colSpanFin, setColSpanFin] = useState(true);
  const [colSpanQual, setColSpanQual] = useState(true);

  const [framing, setFraming] = useState<any[]>([])
  const [showFormPreVisit, setShowFormPreVisit] = useState(false);
  const [showFormFraming, setShowFormFraming] = useState(false);
  const [action, setAction] = useState<'add' | 'edit' | 'view'>('view');
  const [partner, setPartner] = useState<any>();
  const [preVisitEdit, setPreVisitEdit] = useState<PreVisitModel | undefined>();

  const { handleDelete, handleGetPreVisits } = useIndicatorManagementPreVisitVisits();
  const { wallets, error, handleDownload, setParams, params, pages, isLoading, handleGetWallet, handleDownloadSub } = useIndicatorManagementWalletVisits();

  const rows = wallets?.results;

  const [filterText, setFilterText] = useState('');
  const filteredItems = rows?.filter(
    item => item?.partner?.name && item?.partner?.name.toLowerCase().includes(filterText.toLowerCase()) ||
      item?.partner?.partnerCode && item?.partner?.partnerCode.toLowerCase().includes(filterText.toLowerCase()),
  );

  useEffect(() => {
    const filteredItems = rows;
  }, []);

  const subHeaderComponentMemo = useMemo(() => {

    return (
      <div className='d-flex justify-content-start align-items-center flex-column flex-md-row mt-2'>
        <div className='d-flex justify-content-start align-items-center flex-column flex-md-row me-5'>
          <strong>Exibir/Ocultar:</strong>
          <button type="button" className="btn bg-success text-white mb-0 ms-2" onClick={() => setColSpanDig((current) => !current)}>
            <i className="fa-regular fa-eye me-1"></i>
            Digitação
          </button>
          <button type="button" className="btn bg-warning text-white mb-0 ms-2" onClick={() => setColSpanProd((current) => !current)}>
            <i className="fa-regular fa-eye me-1"></i>
            Produção
          </button>

          <button type="button" className="btn bg-primary text-white mb-0 ms-2" onClick={() => setColSpanFin((current) => !current)}>
            <i className="fa-regular fa-eye me-1"></i>
            Financeiro
          </button>

          <button type="button" className="btn quality text-white mb-2 mb-md-0 ms-2" onClick={() => setColSpanQual((current) => !current)}>
            <i className="fa-regular fa-eye me-1"></i>
            Qualidade
          </button>

        </div>
        <div className="p-2 d-flex gap-2 justify-content-end align-items-center mt-0 ms-10 me-0">
          <div className='d-md-flex d-none'>
            <input type="text" className="form-control mt-0 me-1" placeholder="Pesquisar"
              style={{ width: '200px', textAlign: 'left' }}
              onChange={(e: any) => setFilterText(e.target.value)}
            />
            <div className='me-2'>
              <button type="button" className="btn bg-success text-white mb-0"
                onClick={() => handleDownload(params)}
              >
                <i className="fa-regular fa-file-excel me-2"></i>
                Excel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }, [filterText]);

  const handleShowModal = async (action: 'add' | 'edit' | 'view', item: WalletResultModel | undefined = undefined) => {
    if (action === 'edit' || action === 'view') {
      const dataVisit = await handleGetPreVisits(Number(item?.next_visit?.id)).then();
      setPreVisitEdit(dataVisit);
    }
    setPartner({ id: item?.partner?.id, name: item?.partner?.name });
    setAction(action);
    setShowFormPreVisit(true);
  }

  const handleShowModalFraming = (item: any[]) => {
    setShowFormFraming(true);
    setFraming(item);
  }

  const handleDeleteVisit = (item: NextVisitModel) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja excluir a pré visita?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleDelete(item.id || 0).then();
        if (ret) {
          toast.success('Pré visita inativada com sucesso!');
          handleGetWallet(params).then();
        } else {
          await Swal.fire('Ops!', `Não foi possível inativar a pré visita.<br>${error}`, 'error');
        }
      }
    });
  }

  const columns = useMemo(
    () => [
      {
        id: 'colPartnerIndex',
        name:
          <div className='text-center p-2 pb-2 m-0 px-2 text-table cursor-pointer d-flex w-100'>
            CÓDIGO PARCEIRO
          </div >,
        selector: (row: any) => row?.partner?.name,
        cell: (row: any) =>
          <div>
            <div>{row?.partner?.name} </div>
            <div ><b>{row?.partner?.partnerCode}</b></div>
          </div>,
        sortable: true,
        footer: 'Total'
      },
      {
        id: 'colPartner',
        name: <div className='text-center p-2 pb-2 m-0 px-2 text-table cursor-pointer d-flex w-100 '>STATUS</div>,
        selector: (row: any) => row?.agent_activity,
        cell: (row: any) =>
          <div>
            {row?.agent_activity === 'S' ? 'ATIVO' :
              row?.agent_activity === 'N' ?
                <div style={{ color: 'red' }}>
                  {'INATIVO'}
                </div>
                : ''

            }
          </div>,
        sortable: true,
        center: true,
      },
      {
        id: 'colPartner',
        name: <div className='text-center p-2 pb-2 m-0 px-2 text-table cursor-pointer d-flex w-100'>QTDE SUB</div>,
        selector: (row: any) => row?.subfinancials_count,
        cell:
          (row: any) =>
            <span className='justify-content-between'>
              {row.subfinancials_count === 0 ? '' : row.subfinancials_count && (
                <a onClick={() => handleDownloadSub(row?.partner?.id)} className='cursor-pointer me-2'>
                  <i className="fa-solid fa-file-excel text-success" ></i>
                </a>)
              }
              {row.subfinancials_count === 0 ? '' : row.subfinancials_count}
            </span>
        ,
        center: true,
        sortable: true,
      },
      {
        id: 'colPartner',
        name: <div className='text-center p-2 pb-2 m-0 px-2 text-table text-wrap cursor-pointer d-flex w-100'>ÚLTIMA VISITA</div>,
        selector: (row: any) => row?.last_visit?.visitDate,
        sortable: true,
        center: true,
        format: (row: any) => (row === null || row?.last_visit === null) ? '' : dateFormat({ date: row?.last_visit?.visitDate, datetime: false }),
      },
      {
        id: 'colPartner',
        name: <div className='text-center p-2 pb-2 m-0 px-2 text-table text-wrap cursor-pointer d-flex w-100'>QTE VISITA</div>,
        selector: (row: any) => row?.quantity_visit,
        sortable: true,
        center: true,
        // format: (row: any) => FormataStringCurrencyToNumber(row?.quantity_visit)
      },
      {
        id: 'colPartner',
        name: <div className='text-center p-2 pb-2 m-0 px-2 text-table text-wrap cursor-pointer d-flex w-100'>PRÓXIMA VISITA</div>,
        cell: (row: any) => row?.next_visit?.scheduledDate,
        sortable: true,
        center: true,
        format: (row: any) => dateFormat({ date: row?.next_visit?.scheduledDate, datetime: false })
      },
      {
        id: 'colDig',
        name: <div className='text-center p-2 pb-2 m-0 px-2 text-table text-wrap cursor-pointer d-flex w-100'>VALOR BRUTO DIGITAÇÃO</div>,
        selector: (row: any) => row?.typed_value,
        sortable: true,
        center: true,
        format: (row: any) => FormatMoney(row?.typed_value),
        cell: (row: any) => row.id === 'total' ? <strong>{FormatMoney(wallets?.typed_total_value)}</strong> : FormatMoney(row.typed_value),
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 text-table text-wrap cursor-pointer d-flex w-100'>PROJEÇÃO DIGITAÇÃO</div>,
        selector: (row: any) => row?.typed_projection,
        sortable: true,
        center: true,
        format: (row: any) => row?.typed_projection ?? FormatMoney(row?.typed_projection),
        omit: colSpanDig,
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 text-table text-wrap cursor-pointer d-flex w-100'>CRESC/ QUEDA DIGITAÇÃO</div>,
        selector: (row: any) => row?.typed_growth,
        sortable: true,
        center: true,
        // format: (row: any) => FormataStringCurrencyToNumber(row?.typed_growth),
        conditionalCellStyles: [
          {
            when: (row: any) => row?.typed_growth < 0,
            style: { color: 'red' },
          }
        ],
        omit: colSpanDig,
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>PRODUÇÃO {moment(wallets?.header?.['3_month_ago']).format('MM/YYYY')}</div>,
        selector: (row: any) => row?.paid_0_paid_contracts,
        sortable: true,
        omit: colSpanProd,
        center: true,
        format: (row: any) => FormatMoney(row?.paid_0_paid_contracts)
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>PRODUÇÃO {moment(wallets?.header?.['2_month_ago']).format('MM/YYYY')}</div>,
        selector: (row: any) => row?.paid_1_paid_contracts,
        sortable: true,
        omit: colSpanProd,
        center: true,
        format: (row: any) => FormatMoney(row?.paid_1_paid_contracts)
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>PRODUÇÃO {moment(wallets?.header?.['1_month_ago']).format('MM/YYYY')}</div>,
        selector: (row: any) => row?.paid_2_paid_contracts,
        sortable: true,
        omit: colSpanProd,
        center: true,
        format: (row: any) => FormatMoney(row?.paid_2_paid_contracts)
      },
      {
        id: 'colProd',
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>VALOR ATUAL PRODUÇÃO</div>,
        selector: (row: any) => row?.paid_3_paid_contracts,
        sortable: true,
        center: true,
        format: (row: any) => FormatMoney(row?.paid_3_paid_contracts),
        cell: (row: any) => row.id === 'total' ? <strong>{FormatMoney(wallets?.production_total_value)}</strong> : FormatMoney(row.paid_3_paid_contracts),
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>PROJEÇÃO</div>,
        selector: (row: any) => row?.paid_3_projection,
        sortable: true,
        omit: colSpanProd,
        center: true,
        format: (row: any) => FormatMoney(row?.paid_3_projection)
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>CRESC/QUEDA</div>,
        selector: (row: any) => row?.paid_3_growth,
        sortable: true,
        center: true,
        omit: colSpanProd,
        conditionalCellStyles: [
          {
            when: (row: any) => row?.paid_3_growth < 0,
            style: { color: 'red' },
          }
        ]
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>FAIXA</div>,
        selector: (row: any) => row?.paid_3_range,
        sortable: true,
        center: true,
        omit: colSpanProd
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>VLR ACORDO FINANCEIRO</div>,
        selector: (row: any) => row?.deal_value,
        sortable: true,
        omit: colSpanFin,
        center: true,
        format: (row: any) => FormatMoney(row?.deal_value)
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>SALDO C/C FINANCEIRO</div>,
        selector: (row: any) => row?.current_account_balance,
        sortable: true,
        omit: colSpanFin,
        center: true,
        format: (row: any) => FormatMoney(row?.current_account_balance)
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>ENQUAD. FINANCEIRO</div>,
        sortable: true,
        center: true,
        omit: colSpanFin,
        cell: (row: any) =>
          <span className={`badge ${row.framing_quantity === 0 ? '' : 'bg-danger'}`}>
            {row.framing_quantity ? row.framing_quantity : ''}
            {row.framing_quantity ?
              <i className="fa-solid fa-circle-info ms-1" style={{ cursor: 'pointer' }} onClick={() => handleShowModalFraming(row.framing || [])} ></i> : ''}
          </span>,
      },
      {
        id: 'colFin',
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table cursor-pointer d-flex w-100'>DÉBITOS FINANCEIRO</div>,
        selector: (row: any) => row?.debit_exist,
        sortable: true,
        center: true
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>RECLAMAÇÃO {moment(wallets?.header?.['3_month_ago']).format('MM/YYYY')} ENTRADA</div>,
        selector: (row: any) => row?.complaint_0_entry,
        sortable: true,
        omit: colSpanQual,
        center: true,
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>RECLAMAÇÃO {moment(wallets?.header?.['3_month_ago']).format('MM/YYYY')} NÃO RESP</div>,
        selector: (row: any) => row?.complaint_0_unanswered_entry,
        sortable: true,
        omit: colSpanQual,
        center: true,
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>RECLAMAÇÃO {moment(wallets?.header?.['3_month_ago']).format('MM/YYYY')} % NÃO RESP</div>,
        selector: (row: any) => row?.complaint_0_unanswered_percent,
        cell: (row: any) => <div>{row?.complaint_0_unanswered_percent ? row?.complaint_0_unanswered_percent.toFixed(2) + '%' : 0}</div>,
        sortable: true,
        omit: colSpanQual,
        center: true
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>RECLAMAÇÃO {moment(wallets?.header?.['2_month_ago']).format('MM/YYYY')} ENTRADA</div>,
        selector: (row: any) => row?.complaint_1_entry,
        sortable: true,
        omit: colSpanQual,
        center: true,
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>RECLAMAÇÃO {moment(wallets?.header?.['2_month_ago']).format('MM/YYYY')} NÃO RESP</div>,
        selector: (row: any) => row?.complaint_1_unanswered_entry,
        sortable: true,
        omit: colSpanQual,
        center: true,
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>RECLAMAÇÃO {moment(wallets?.header?.['2_month_ago']).format('MM/YYYY')} % NÃO RESP</div>,
        selector: (row: any) => row?.complaint_1_unanswered_percent,
        cell: (row: any) => <div>{row?.complaint_1_unanswered_percent ? row?.complaint_1_unanswered_percent.toFixed(2) + '%' : 0}</div>,
        sortable: true,
        omit: colSpanQual,
        center: true
      },
      {
        id: 'colQual',
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>RECLAMAÇÃO {moment(wallets?.header?.['1_month_ago']).format('MM/YYYY')} ENTRADA</div>,
        selector: (row: any) => row?.complaint_2_entry,
        sortable: true,
        center: true,
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>RECLAMAÇÃO {moment(wallets?.header?.['1_month_ago']).format('MM/YYYY')} NÃO RESP</div>,
        selector: (row: any) => row?.complaint_2_unanswered_entry,
        sortable: true,
        omit: colSpanQual,
        center: true,
      },
      {
        name: <div className='text-center p-2 pb-2 m-0 px-2 col2 text-table text-wrap cursor-pointer d-flex w-100'>RECLAMAÇÃO {moment(wallets?.header?.['1_month_ago']).format('MM/YYYY')} % NÃO RESP</div>,
        selector: (row: any) => row?.complaint_2_unanswered_percent,
        cell: (row: any) => <div>{row?.complaint_2_unanswered_percent ? row?.complaint_2_unanswered_percent.toFixed(2) + '%' : 0}</div>,
        sortable: true,
        omit: colSpanQual,
        center: true,
      },
      {
        id: 'colPartner',
        name: "AÇÕES",
        center: true,
        cell: (row: any) =>
          <div>
            <DropdownMenuTable key={1}>
              {!row?.next_visit?.scheduledDate &&
                <Dropdown.Item className="border-radius-md py-2" onClick={() => handleShowModal('add', row)}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" /> Adicionar Pré Visita
                </Dropdown.Item>
              }
              {row?.next_visit?.scheduledDate && row?.next_visit?.wasApproved == null &&
                <Dropdown.Item className="border-radius-md py-2" onClick={() => handleShowModal('edit', row)}>
                  <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
                </Dropdown.Item>
              }
              {row?.next_visit?.scheduledDate && (row?.next_visit?.wasApproved === null || !row?.next_visit?.wasApproved) &&
                <Dropdown.Item className="border-radius-md py-2" onClick={() => handleDeleteVisit(row?.next_visit || {})}>
                  <FontAwesomeIcon icon={faTrash} className="me-2" /> Remover
                </Dropdown.Item>
              }
              {row?.next_visit?.scheduledDate && (row?.next_visit?.wasApproved || !row?.next_visit?.wasApproved) &&
                <Dropdown.Item className="border-radius-md py-2" onClick={() => handleShowModal('view', row)}>
                  <FontAwesomeIcon icon={faEye} className="me-2" /> Visualizar
                </Dropdown.Item>
              }
            </DropdownMenuTable>
          </div>,

      }
    ],
    [colSpanDig, colSpanProd, colSpanFin, colSpanQual]);

  return (
    <div className="WalletVisitsList" data-testid="WalletVisitsList">
      <Card>
        <Card.Body className='pt-0 mt-0'>
          {!isLoading ? (
            <AppDataTable
              rows={filteredItems}
              columns={columns}
              subHeader={subHeaderComponentMemo}
            />
          ) : (
            <SkeletonTable />
          )}

          <div>
            <div className='typed_total'>VALOR BRUTO DIGITAÇÃO : {FormatMoney(wallets?.typed_total_value)}</div>
            <div className='production_total mt-1'>VALOR ATUAL PRODUÇÃO : {FormatMoney(wallets?.production_total_value)}</div>
          </div>
        </Card.Body>
      </Card>

      <ModalPreVisits
        show={showFormPreVisit}
        onClose={() => setShowFormPreVisit(false)}
        action={action}
        preVisitEdit={preVisitEdit}
        hasAnalysis={false}
        partner={partner}
        setPreVisitEdit={setPreVisitEdit}
      />

      <ModalFraming
        show={showFormFraming}
        onClose={() => setShowFormFraming(false)}
        framing={framing}
      />
    </div>
  );

}

export default WalletVisitsList;
