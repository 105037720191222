import * as yup from "yup";
import { ProposalRuleConditionEnum } from "../../../../../enums/Proposals/Rule/Condition/ProposalRuleConditionEnum";

export const schema = yup.object().shape({
    headerFieldId: yup
        .object()
        .required()
        .test('typeError', 'Campo da Planilha é obrigatório', (item: any) => item?.value > 0),
    conditionId: yup
        .object()
        .required()
        .test('typeError', 'Condição é obrigatório', (item: any) => item?.value > 0),
    value1: yup
        .string()
        .when('conditionId', {
            is: (item: any) => item?.value > 0 && ![ProposalRuleConditionEnum.EMPTY, ProposalRuleConditionEnum.NOT_EMPTY].includes(item?.value),
            then: (schema) => schema.test('typeError', 'Condição é obrigatório', (value: any) => !!value).required(),
            otherwise: (schema) => schema.notRequired(),
        }),
    value2: yup
        .string()
        .when('conditionId', {
            is: (item: any) => item?.value > 0 && item?.value === ProposalRuleConditionEnum.BETWEEN,
            then: (schema) => schema.test('typeError', 'Condição é obrigatório', (value: any) => !!value).required(),
            otherwise: (schema) => schema.notRequired()
        }),
});