import { FC, useState } from 'react';
import './ModalHelpVisits.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useIndicatorManagementPreVisitVisits } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementPreVisitProvider';
import { useForm } from 'react-hook-form';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';

interface ModalHelpVisitsProps {
  show: boolean;
  onClose: () => void;
}

const ModalHelpVisits: FC<ModalHelpVisitsProps> = ({ show, onClose }) => {

  const { setError } = useIndicatorManagementPreVisitVisits();

  const { handleSubmit } = useForm<any>();
  const [showFormHelpVisits, setShowFormHelpVisits] = useState(false);

  const handleClose = () => {
    setError('');
    onClose();
  }

  return (
    <>
      <ModalDefault
        title={'AJUDA PRÉ-VISITA'}
        show={show}
        onClose={handleClose}
        sizeModal={'xl'}
        showFooter={true}
        backdrop="static"
        backgroundColor="#f8f9fa"
      >
        <div className="ModalHelpVisits" data-testid="ModalHelpVisits">

          <h4>O que é a exportação da Pré-Visita?</h4>
          <p>Permite exportar suas pré-visitas para um arquivo de calendário/evento. Com isso, as pré-visitas poderão ser importadas para sua agenda/calendário de forma que ao sincronizar sua agenda entre seus dispositivos, permita-lhe melhor planejamento e organização.</p>
          <hr />
          <h4>Como Exporta?</h4>
          <p>Após ter filtrado e listado a Carteira de parceiros, vá na aba Pré-Visita. Selecione o botão “Exportar Agenda”. Defina o período a ser exportado e baixe o arquivo de agenda (.ICS) no botão “Exporta Agenda”.

            Para a sincronia, o meio mais fácil é dispondo de uma conta/email vinculado ao seu app ou serviço de agenda/calendário.

            Abaixo você confere links dos principais apps e serviços de agenda/calendário para importar suas pré-visitas. Caso faça uso de um app ou serviço que não consta abaixo, recomendamos averiguar as sessões ou páginas de ajuda e suporte do desenvolvedor do seu app ou serviço de agenda/calendário.</p>
          <hr />
          <h4>Google Agenda</h4>
          <p>Verifique a Etapa Nº 2: “importar os eventos para o Google Agenda”.
            <a href="https://support.google.com/calendar/answer/37118?hl=pt-BR&ref_topic=10510645" style={{ color: "blue" }} className='fw-bold'> Ajuda Google Agenda</a></p>
          <hr />
          <h4>Apple Calendário</h4>
          <p>Caso ele seja o programa padrão em seu computador, ao tentar abrir o arquivo de calendário gerado, o processo de importação será iniciado pelo Apple Calendário. Caso não, verifique o item: “Importe eventos de um arquivo de calendário” no suporte da Apple.
            <br />
            <a href="https://support.apple.com/pt-br/guide/calendar/icl1023/mac" style={{ color: "blue" }} className='fw-bold'>Ajuda Apple Calendário</a></p>
          <hr />
          <h4>Microsoft Office Outlook</h4>
          <p>Caso ele seja o programa padrão em seu computador, ao tentar abrir o arquivo de calendário gerado, o processo de importação será iniciado pelo Microsoft Outlook. Caso não, verifique o item: “Importar um arquivo .ics ou .vcs” no suporte do Microsoft Office.
            <br />
            <a href="https://support.microsoft.com/pt-br/office/importar-calend%C3%A1rios-para-o-outlook-8e8364e1-400e-4c0f-a573-fe76b5a2d379" style={{ color: "blue" }} className='fw-bold'>Microsoft Office Outlook</a></p>
          <hr />
          <h4>Calendário do Windows (nativo do Windows)</h4>
          <p>Caso ele seja o programa padrão em seu computador, ao tentar abrir o arquivo de calendário gerado, o processo de importação será iniciado. Selecione o calendário que vai receber os eventos e clique em “Salvar” ou “Adicionar ao calendário”.
            <br />
            <a href="https://support.microsoft.com/pt-br/windows/configure-o-email-e-o-calend%C3%A1rio-f5291e2e-4ffc-6c90-2145-acc6840b29ff" style={{ color: "blue" }} className='fw-bold'>Ajuda Microsoft Calendário do Windows</a>
            |
            <a href="https://support.microsoft.com/pt-br/office/perguntas-frequentes-do-email-e-calend%C3%A1rio-para-windows-10-4ebe0864-260f-4d3a-a607-7b9899a98edc" style={{ color: "blue" }} className='fw-bold'>Perguntas frequentes do Email e Calendário para Windows</a></p>
        </div >

      </ModalDefault >

    </>
  );

}

export default ModalHelpVisits;
