import { CampaignCreateModel } from "../../../models/Marketplace/Campaign/CampaignCreateModel";
import { CampaignModel } from "../../../models/Marketplace/Campaign/CampaignModel";
import { CampaignParams } from "../../../models/Marketplace/Campaign/CampaignParams";
import { CampaignSimulationFilter } from "../../../models/Marketplace/Campaign/CampaignSilmulaitonFilter";
import api from "../../Api";
import { BaseService } from "../../BaseService";
import FileSaver from 'file-saver';

const URL = "api/services/marketplace";

export class CampaignService extends BaseService {

    async downloadModel(): Promise<any[]> {
        try {
            const response = await api.get(`${URL}/admin/campaign/import/model`, { responseType: 'blob' });
            FileSaver.saveAs(response.data, "marketplace-model", { autoBom: false });
            return [true, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async handleDownloadPathfile(filePath: string): Promise<any[]> {
        try {
            const formdata = new FormData();
            formdata.append("pathfile", filePath);
            const response = await api.post(`${URL}/admin/campaign/import/download`, formdata, { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' });
            if (response.status === 200) {
                FileSaver.saveAs(response.data, "marketplace-excel-imported.xlsx", { autoBom: false });
                return [true, undefined];
            } else {
                console.error('Erro na solicitação:', response.status, response.statusText);
                return [false, undefined];
            }
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async filters(): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/admin/campaign/params`));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async simulate(params: CampaignSimulationFilter): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/admin/campaign/simulate?page=${params.page || 1}`, params));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async save(params: CampaignCreateModel): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/admin/campaign/save`, params));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async saveExcel(params: CampaignCreateModel, file: File): Promise<any[]> {
        try {

            const formdata = new FormData();
            formdata.append("campaign_name", params?.campaign_name?.toString() ?? '');
            formdata.append("campaign_obs", params?.campaign_obs?.toString() ?? '');
            formdata.append("campaign_deadline", params?.campaign_deadline?.toString() ?? '');
            formdata.append("campaign_origin", params?.campaign_origin ?? '');
            formdata.append("campaign_sanitize", params?.campaign_sanitize?.toString() ?? '');
            formdata.append("campaign_sanitize_type", params?.campaign_sanitize_type?.toString() ?? '');
            formdata.append("file", file);

            return this.handleResponse(await api.post(`${URL}/admin/campaign/import/save`, formdata, { headers: { 'Content-Type': 'multipart/form-data' } }));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async disabled(params: number[]): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/admin/campaign/disable`, params));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async disabledAutoGen(params: number[]): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/admin/campaign/gen/disable`, params));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async list(params: CampaignParams): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/admin/campaign?page=${params.page || 1}`));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async listGen(params: CampaignParams): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/admin/campaign/gen?page=${params.page || 1}`));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async importedList(params: CampaignParams): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/admin/campaign/import/list?page=${params.page || 1}`));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async getFiltersByCampaign(campaignId: number): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/admin/campaign/filters/${campaignId}`));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }
}