import React, { FC } from 'react';
import './PieChart.scss';
import { Doughnut, Pie } from 'react-chartjs-2';

interface PieChartProps {
  labels: string[],
  data: number[],
  max?: number,
  height?: number,
}

const chartColors = ["#7779BB", "#222343", "#25CBDB", "#454787", "#787ABA", "#AF7AC5", "#AED6F1", "#3498DB", "#633974", "#D2B4DE", "#D987FA", "#1F9BA7"];

const PieChart: FC<PieChartProps> = ({ labels, data, max, height }) => {

  return (
    <div className="PieChart" data-testid="PieChart">
      <Doughnut
        data={{
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: chartColors,
              borderRadius: 5,
              hoverOffset: 15,
            }
          ],
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          cutout: '0',
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
            },
            title: {
              display: false,
            },
            datalabels: {
              display: true,
              color: '#333',
              backgroundColor: '#fff',
              font: {
                weight: 'bold',
                size: 11,
              },
              borderRadius: 3,
              anchor: 'end',
              align: 'top',
              clamp: true,
              formatter: (value, context) => {
                const label = context?.chart?.data?.labels ? context?.chart?.data?.labels[context?.dataIndex] : '';
                return `${label}: ${value}%`;
              }
            },
            tooltip: {
              enabled: true,
            }
          },
          layout: {
            padding: {
              top: 25,
            },
          },
          aspectRatio: 1.5
        }}
        height={height}
      />
    </div>
  );
}

export default PieChart;
