import api from "../Api";
import { BaseService } from "../BaseService";

const URL = 'api/integrations';

export class IntegrationsService extends BaseService {

    async cancelTerm(data: any): Promise<any> {
        try {
            return this.handleResponse(await api.patch(`${URL}/document/cancel`, data));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async resendTerm(data: any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/document/save`, data));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}