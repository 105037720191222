import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { PreVisitModel } from "../../../models/Visits/IndicatorManagement/PreVisitModel";
import { PreVisitsParams } from "../../../models/Visits/IndicatorManagement/PreVisitsParams";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { IndicatorManagementPreVisitService } from "../../../services/Visits/IndicatorManagement/IndicatorManagementPreVisitService";
import { toast } from "react-toastify";
import { ToastSettings } from "../../../utils/ToastSettings";
import { PreVisitsCalendarParams } from "../../../models/Visits/IndicatorManagement/PreVisitsCalendarParams";
import { useReportRequest } from "../../Reports/ReportRequest/ReportRequestProvider";

interface ContextProps {
    preVisit: PreVisitModel,
    setPreVisit: Dispatch<SetStateAction<PreVisitModel>>,
    preVisits: PreVisitModel[],
    params: PreVisitsParams,
    setParams: Dispatch<SetStateAction<PreVisitsParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: (params: PreVisitsParams) => Promise<any>,
    handleFetch: (data: PreVisitModel) => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>,
    handleDownload: (id: number) => Promise<boolean>,
    handlePrintPreVisits: (id: number) => Promise<boolean>,
    handleDownloadSchedule: (params: PreVisitsCalendarParams) => Promise<any>,
    handleGetPreVisits: (id: number) => Promise<any>,
    handleExcel: (params: PreVisitsParams) => Promise<any>,
}

export const IndicatorManagementPreVisitContext = createContext<ContextProps>({} as ContextProps);

interface IndicatorManagementPreVisitProviderProps {
    children: ReactNode;
}

export const IndicatorManagementPreVisitProvider = ({ children }: IndicatorManagementPreVisitProviderProps) => {
    const [preVisit, setPreVisit] = useState<PreVisitModel>({} as PreVisitModel);
    const [preVisits, setPreVisits] = useState<PreVisitModel[]>([]);
    const [params, setParams] = useState<PreVisitsCalendarParams>({});
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const { setIsLoadingFile } = useReportRequest();

    const service = new IndicatorManagementPreVisitService();

    const handleList = async (params: PreVisitsParams) => {

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                setPreVisits([]);
                return setError(_Response?.message || _Error);
            }

            setPreVisits(_Response.data);
            setPages(_Response?.pages);
            setError('');
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setPreVisits([]);
            setError('Não foi possível carregar a pré visita');
        }
    }

    const handleGetPreVisits = async (id: number) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.get(id);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }
            setError('');

            return _Response?.data;
        } catch (e) {
            setError('Erro ao pesquisar a visita.');
            return false;
        }
    }

    const handleFetch = async (data: PreVisitModel) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.fetch(data);
            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setError('Houve um erro ao salvar a pré visita');
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar a pré visita');
            return false;
        }
    }

    const handleDownload = async (id: number) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.download(id);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');

            ToastSettings('Arquivo baixado com sucesso!', 'bottom-center', 'success');

            return true;
        } catch (e) {
            setError('Houve um erro ao baixar o arquivo');
            return false;
        }
    }

    const handleDownloadSchedule = async (params: PreVisitsCalendarParams) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.downloadSchedule(params);
            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');

            ToastSettings('Agenda baixada com sucesso!', 'bottom-center', 'success');

            return true;
        } catch (e) {
            setError('Houve um erro ao baixar a agenda');
            return false;
        }
    }

    const handlePrintPreVisits = async (id: number) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.exportPdf(id);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');

            ToastSettings('Arquivo exportado com sucesso!', 'bottom-center', 'success');

            return true;
        } catch (e) {
            setError('Houve um erro ao exportar o arquivo');
            return false;
        }
    }

    const handleExcel = async (params: PreVisitsParams) => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.export({ ...params });

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error, 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    return (
        <IndicatorManagementPreVisitContext.Provider value={useMemo(() => ({
            preVisit,
            setPreVisit,
            preVisits,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleFetch,
            handleDelete,
            handleDownload,
            handleDownloadSchedule,
            handleGetPreVisits,
            handlePrintPreVisits,
            handleExcel
        }), [
            preVisit,
            setPreVisit,
            preVisits,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleFetch,
            handleDelete,
            handleDownload,
            handleDownloadSchedule,
            handleGetPreVisits,
            handlePrintPreVisits,
            handleExcel
        ])}>
            {children}
        </IndicatorManagementPreVisitContext.Provider>
    );
}

export const useIndicatorManagementPreVisitVisits = () => useContext(IndicatorManagementPreVisitContext);