import { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import './CustomButtonCollapse.scss';
import { useAccordionButton } from 'react-bootstrap';

interface CustomButtonCollapseProps {
  children: ReactNode;
  eventKey: string;
  setItemToExpand: Dispatch<SetStateAction<string | null>>;
  isExpand?: boolean;
}

const CustomButtonCollapse: FC<CustomButtonCollapseProps> = ({ children, eventKey, setItemToExpand, isExpand = true }) => {
  const decoratedOnClick = useAccordionButton(eventKey, (e: any) => {
    if (e.target.className === 'form-check-input') {
      return;
    }

    setItemToExpand(eventKey);
  });

  return (
    <tr className="CustomButtonCollapse" data-testid="CustomButtonCollapse">
      {children}

      {isExpand && (
        <td className="text-end">
          <button type="button" className="btn btn-link m-0" onClick={decoratedOnClick}>
            <i className="fa-solid fa-chevron-down text-sm"></i>
          </button>
        </td>
      )}
    </tr>
  );
}

export default CustomButtonCollapse;
