import { AccessActionParams } from "../../../models/Registrations/Access/AccessActionParams";
import { AccessActionModel } from "../../../models/Registrations/Access/AccessActionModel";
import api from "../../Api";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/access/action';

export class AccessActionService {

    async details(id: number): Promise<any> {
        try {
            const response = await api.post(`${URL}/details/${id}`);

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async list(params: AccessActionParams): Promise<any[]> {
        try {
            const _page = params.pageNumber || 1;
            const response = await api.post(`${URL}`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async search(description: string, pageId: number): Promise<any[]> {
        try {
            const response = await api.post(`${URL}/search`, { description, pageId });
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async getAll(): Promise<any> {
        try {
            const response = await api.post(`${URL}`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async getByPage(pageId: number): Promise<any> {
        try {
            const response = await api.post(`${URL}`, { accessPageId: pageId });

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async create(payload: AccessActionModel): Promise<any> {
        try {
            const response = await api.put(`${URL}`, payload);

            return [response.data, undefined];
        } catch (e: any) {
            let message: any = [DEFAULT_MESSAGE];

            switch (typeof e?.response?.data?.message) {
                case 'string':
                    message = [e?.response?.data?.message] || DEFAULT_MESSAGE;
                    break
                case 'object':
                    message = Object.values(e?.response?.data?.message)?.pop() || DEFAULT_MESSAGE;
                    break
            }

            return [undefined, message];
        }
    }

    async delete(id: number): Promise<any> {
        try {
            const response = await api.delete(`${URL}`, { data: { id } });

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async getTypes(): Promise<any> {
        try {
            const response = await api.post(`${URL}/type`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

}