import { FC, ReactNode } from 'react';
import './ProductionTableByRange.scss';
import { useIndicatorManagementProductionRange } from '../../../../../providers/Visits/IndicatorManagement/IndicatorManagementProductionRangeProvider';
import { ProductionRangeListModel } from '../../../../../models/Visits/IndicatorManagement/ProductionRangeModel';
import FormatMoney from '../../../../../utils/FormatMoney';
import { useIndicatorManagementWalletVisits } from '../../../../../providers/Visits/IndicatorManagement/IndicatorManagementWalletProvider';

interface ProductionTableByRangeProps { }

const ProductionTableByRange: FC<ProductionTableByRangeProps> = () => {
  const { productionsRangeList, handleGetExcel } = useIndicatorManagementProductionRange();
  const { params } = useIndicatorManagementWalletVisits();

  const getMonthYear = (period: string = ''): { month: number, year: number } => {
    const result = period.split('-') || [0, 0];
    return { month: Number(result[1]), year: Number(result[0]) };
  }

  const getHeaderBgColor = (index: number): string => {
    return !(index % 2) ? 'bg-secondary' : 'bg-primary';
  }

  const hasValue = (value: number = 0, range: string = ''): boolean => {
    return range === 'total' ? false : value > 0;
  }

  const isTotal = (range: string = ''): boolean => {
    return range === 'total';
  }

  const onHandleExcel = async (range: number = -1, month: string = '', type: 'export' | 'exportAnalytic' | 'exportAnalyticComplete' = 'export') => {
    await handleGetExcel({
      range,
      month,
      type,
      commercialId: params?.commercialId,
      userId: params?.userId
    }).then();
  }

  const renderTableHeader = (): ReactNode => {
    return (
      <>
        <tr>
          <th className='bg-primary' rowSpan={3}>FAIXA DE PRODUÇÃO</th>
          {productionsRangeList?.months?.map((month: string, index: number) => (
            <th key={month} className={`${getHeaderBgColor(index)}`} colSpan={4}>
              {('00' + getMonthYear(month).month).slice(-2)}/{getMonthYear(month).year}
            </th>
          )
          )}
        </tr>
        <tr>
          {productionsRangeList?.months?.map((item: string, index: number) => (
            <>
              <th className={`${getHeaderBgColor(index)}`} colSpan={2}>QUANTIDADE</th>
              <th className={`${getHeaderBgColor(index)}`} colSpan={2}>PRODUÇÃO</th>
            </>
          ))}
        </tr>
        <tr>
          {productionsRangeList?.months?.map((item: string, index: number) => (
            <>
              <th className={`${getHeaderBgColor(index)}`}>QTDE</th>
              <th className={`${getHeaderBgColor(index)}`}>%</th>
              <th className={`${getHeaderBgColor(index)}`}>QTDE</th>
              <th className={`${getHeaderBgColor(index)}`}>%</th>
            </>
          ))}
        </tr>
      </>
    )
  }

  const renderTableBody = (): ReactNode => {
    return (<>
      {productionsRangeList?.lines?.map((line: ProductionRangeListModel) => (
        <tr key={line?.rangeId}>
          <td className={`${isTotal(line.rangeAcronym) && 'fw-bolder'}`}>{line.range}</td>
          <td>
            <span
              className={
                `${hasValue(line.quantityM1?.value, line.rangeAcronym) && 'justify-content-end'} 
                 ${isTotal(line.rangeAcronym) && 'fw-bolder'}`}>
              {line.quantityM1?.value}
            </span>
          </td>
          <td>
            <span
              className={`${hasValue(line.quantityPercentM1?.value, line.rangeAcronym) && 'justify-content-end'}
              ${isTotal(line.rangeAcronym) && 'fw-bolder'}`}>
              {line.quantityPercentM1?.value}%
            </span>
          </td>
          <td>
            <span
              className={`${hasValue(line.productionM1?.value, line.rangeAcronym) && 'justify-content-end'}
              ${isTotal(line.rangeAcronym) && 'fw-bolder'}`}>
              {FormatMoney(line.productionM1?.value)}
            </span>
          </td>
          <td>
            <span
              className={`${hasValue(line.productionPercentM1?.value, line.rangeAcronym) && 'justify-content-end'}
            ${isTotal(line.rangeAcronym) && 'fw-bolder'}`}>
              {line.productionPercentM1?.value}%
            </span>
          </td>

          <td>
            <span
              className={`${hasValue(line.quantityM2?.value, line.rangeAcronym) && 'justify-content-end'}
            ${isTotal(line.rangeAcronym) && 'fw-bolder'}`}>
              {line.quantityM2?.value}
            </span>
          </td>
          <td>
            <span
              className={`${hasValue(line.quantityPercentM2?.value, line.rangeAcronym) && 'justify-content-end'}
            ${isTotal(line.rangeAcronym) && 'fw-bolder'}`}>
              {line.quantityPercentM2?.value}%
            </span>
          </td>
          <td>
            <span
              className={`${hasValue(line.productionM2?.value, line.rangeAcronym) && 'justify-content-end'}
            ${isTotal(line.rangeAcronym) && 'fw-bolder'}`}>
              {FormatMoney(line.productionM2?.value)}
            </span>
          </td>
          <td>
            <span
              className={`${hasValue(line.productionPercentM2?.value, line.rangeAcronym) && 'justify-content-end'}
            ${isTotal(line.rangeAcronym) && 'fw-bolder'}`}>
              {line.productionPercentM2?.value}%
            </span>
          </td>

          <td>
            <span
              className={`${hasValue(line.quantityM3?.value, line.rangeAcronym) && 'justify-content-end'}
            ${isTotal(line.rangeAcronym) && 'fw-bolder'}`}>
              {line.quantityM3?.value}
            </span>
          </td>
          <td>
            <span
              className={`${hasValue(line.quantityPercentM3?.value, line.rangeAcronym) && 'justify-content-end'}
            ${isTotal(line.rangeAcronym) && 'fw-bolder'}`}>
              {line.quantityPercentM3?.value}%
            </span>
          </td>
          <td>
            <span
              className={`${hasValue(line.productionM3?.value, line.rangeAcronym) && 'justify-content-end'}
            ${isTotal(line.rangeAcronym) && 'fw-bolder'}`}>
              {FormatMoney(line.productionM3?.value)}
            </span>
          </td>
          <td>
            <span
              className={`${hasValue(line.productionPercentM3?.value, line.rangeAcronym) && 'justify-content-end'}
            ${isTotal(line.rangeAcronym) && 'fw-bolder'}`}>
              {line.productionPercentM3?.value}%
            </span>
          </td>
        </tr>
      ))}
    </>
    );
  }

  return (
    <div className="ProductionTableByRange" data-testid="ProductionTableByRange">
      <div className='d-flex justify-content-between flex-column flex-md-row'>
        <h4>PRODUÇÃO POR FAIXA - TABELA</h4>
        {(!!productionsRangeList && !!productionsRangeList?.lines && productionsRangeList?.lines?.length > 0) &&
          <div className='d-flex gap-2'>
            <button className='btn btn-secondary text-white'
              onClick={() => onHandleExcel(-1, '', 'export')}
            >Tabela</button>
          </div>}
      </div>
      <div className='table-responsive mt-3'>
        <table className='table table-striped'>
          <thead>
            {(!!productionsRangeList && !!productionsRangeList?.lines && productionsRangeList?.lines?.length > 0) ?
              (renderTableHeader())
              :
              <tr className='bg-primary'>
                <th>
                  Não existem itens para serem listados
                </th>
              </tr>
            }
          </thead>
          {(!!productionsRangeList && !!productionsRangeList?.lines && productionsRangeList?.lines?.length > 0) &&
            <tbody>
              {renderTableBody()}
            </tbody>
          }
        </table>
      </div>
    </div>
  )
};

export default ProductionTableByRange;
