import { useQuery } from "react-query";
import { ReportRequestService } from "../../../services/Reports/ReportRequest/ReportRequestService";

/**
 * Hook para obter as solicitações de relatórios
 */
export const useReportRequestListHook = (params: any, showSidebar: boolean) => useQuery<any>(
    'report-request-list',
    () => (new ReportRequestService()).list(params),
    { refetchInterval: 50000, staleTime: 15000, enabled: showSidebar },
);