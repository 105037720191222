import { FC, useEffect, useMemo, useState } from "react";
import "./TableAnnualProduction.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import FormatMoney from "../../../../../../utils/FormatMoney";
import { useBiSupervisor } from "../../../../../../providers/BI/Dashboard/Supervisor/BiSupervisorProvider";
import AppDataTable from "../../../../../AppDataTable/AppDataTable";
import { useBiDashboard } from "../../../../../../providers/BI/Dashboard/BiDashboardProvider";
import { BI_VIEWS_ENUM } from "../../../../../../models/BI/Dashboard/BiProductionParamsModel";
import SkeletonTable from "../../../../../SkeletonTable/SkeletonTable";

interface TableAnnualProductionProps {}

const TableAnnualProduction: FC<TableAnnualProductionProps> = () => {
  const [colAnnualProduction, setColAnnualProduction] = useState<boolean>(true);
  const [colAnnualProjection, setColAnnualProjection] = useState<boolean>(true);
  const [colParticipation, setColParticipation] = useState<boolean>(true);
  const [colAnnualAverage, setColAnnualAverage] = useState<boolean>(true);
  const [columns, setColumns] = useState<any[]>([]);

  const { annualSupervisor, setAnnualSupervisor } = useBiSupervisor();
  const {
    handleListAnnualProduction,
    isLoading,
    params,
    setParams,
    setHandleListOnFilter,
    resultOnFilter,
    setResultOnFilter,
    generalErrorMsg,
    handleExcel,
  } = useBiDashboard();

  useEffect(() => {
    setHandleListOnFilter(() => handleListAnnualProduction);
    setParams({
      ...params,
      view: BI_VIEWS_ENUM.REGIONAL,
      group: {},
    });
    if (!annualSupervisor) {
      handleFetch().then();
    }
  }, []);

  useEffect(() => {
    if (resultOnFilter) {
      setAnnualSupervisor(resultOnFilter);
      setResultOnFilter(undefined);
    }
  }, [resultOnFilter]);

  const handleFetch = async () => {
    const result = await handleListAnnualProduction({
      ...params,
      view: BI_VIEWS_ENUM.REGIONAL,
      group: {},
    }).then();

    if (result) {
      setAnnualSupervisor(result);
    }
  };

  const handleExport = (rows: any) => {
    const _data: any[] = [];
    rows.forEach((item: any) => {
      const _row: any = {
        Supervisor: `${item?.name} - ${item?.partnerCode}`
      };

      item?.periods?.forEach((period: any) => {
        _row["Producao anual"] = period.totalProduction;
        _row["Producao anual D.U"] = period.totalProductionProjection;
        _row["Participacao"] = period.totalProductionPercentage;
        _row["Media D.U"] = period.totalProductionByWorkdays;
      });
      _data.push(_row);
    });
    handleExcel(_data, "Producao anual por supervisor ");
  };

  const mountColumns = useMemo(() => {
    if (annualSupervisor?.items && annualSupervisor?.items?.length > 0) {
      const _columns: any = [
        {
          id: "colIndex",
          name: (
            <div className="bg-info text-table text-uppercase">Supervisor</div>
          ),
          selector: (data: any) => data?.name,
          cell: (data: any) => (
            <div className="text-wrap text-bold">
              {`${data?.name} - ${data?.partnerCode}`}
            </div>
          ),
          sortable: true,
          width: "250px",
        },
      ];

      annualSupervisor?.items[0].periods?.forEach(
        (item: any, index: number) => {
          _columns.push({
            name: (
              <div className="bg-info text-table text-uppercase">
                Produção Anual
              </div>
            ),
            selector: (data: any) => data?.periods[index]?.totalProduction,
            right: true,
            cell: (data: any) => (
              <div className="text-nowrap">
                {FormatMoney(data?.periods[index]?.totalProduction)}
              </div>
            ),
            sortable: true,
            omit: !colAnnualProduction,
          });

          _columns.push({
            name: (
              <div className="bg-info text-table text-uppercase">
                Projeção Anual D.U
              </div>
            ),
            selector: (data: any) =>
              data?.periods[index]?.totalProductionProjection,
            right: true,
            cell: (data: any) => (
              <div className="text-nowrap">
                {FormatMoney(data?.periods[index]?.totalProductionProjection)}
              </div>
            ),
            sortable: true,
            omit: !colAnnualProjection,
          });

          _columns.push({
            name: (
              <div className="bg-info text-table text-uppercase">
                {" "}
                Participação{" "}
              </div>
            ),
            selector: (data: any) =>
              data?.periods[index]?.totalProductionPercentage,
            right: true,
            cell: (data: any) => (
              <div>
                {FormatMoney(
                  data?.periods[index]?.totalProductionPercentage,
                  false
                )}
                %
              </div>
            ),
            sortable: true,
            omit: !colParticipation,
          });

          _columns.push({
            name: (
              <div className="bg-info text-table text-uppercase">Média D.U</div>
            ),
            selector: (data: any) =>
              data?.periods[index]?.totalProductionByWorkdays,
            right: true,
            cell: (data: any) => (
              <div className="text-nowrap">
                {FormatMoney(data?.periods[index]?.totalProductionByWorkdays)}
              </div>
            ),
            sortable: true,
            omit: !colAnnualAverage,
          });
        }
      );

      setColumns(_columns);
    }
  }, [
    annualSupervisor,
    colAnnualProduction,
    colAnnualProjection,
    colParticipation,
    colAnnualAverage,
  ]);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <Row md={12} className="display-button">
        <div className="d-flex justify-content-between align-items-center flex-md-row">
          <div className="d-flex gap-1 justify-content-center align-items-center flex-md-row">
            <button
              type="button"
              className="btn bg-primary text-white mb-0 ms-2 min-width-btn"
              onClick={() => setColAnnualProduction((current) => !current)}
            >
              <FontAwesomeIcon
                icon={colAnnualProduction ? faEye : faEyeSlash}
                className="me-1"
              />
              Produção Anual
            </button>

            <button
              type="button"
              className="btn bg-primary text-white mb-0 ms-2 min-width-btn"
              onClick={() => setColAnnualProjection((current) => !current)}
            >
              <FontAwesomeIcon
                icon={colAnnualProjection ? faEye : faEyeSlash}
                className="me-1"
              />
              Projeção Anual D.U
            </button>

            <button
              type="button"
              className="btn bg-primary text-white mb-0 ms-2 min-width-btn"
              onClick={() => setColParticipation((current) => !current)}
            >
              <FontAwesomeIcon
                icon={colParticipation ? faEye : faEyeSlash}
                className="me-1"
              />
              Participação
            </button>

            <button
              type="button"
              className="btn bg-primary text-white mb-0 ms-2 min-width-btn"
              onClick={() => setColAnnualAverage((current) => !current)}
            >
              <FontAwesomeIcon
                icon={colAnnualAverage ? faEye : faEyeSlash}
                className="me-1"
              />
              Média D.U
            </button>
            <button
              className="btn bg-success text-white rounded m-0 ms-2"
              onClick={() => {
                handleExport(annualSupervisor?.items);
              }}
            >
              <i className="fas fa-file-excel" /> Exportar
            </button>
          </div>
        </div>
      </Row>
    );
  }, [
    colAnnualProduction,
    colAnnualProjection,
    colParticipation,
    colAnnualAverage,
  ]);

  return (
    <div className="TableAnnualProduction" data-testid="TableAnnualProduction">
      <>
        {!isLoading ? (
          annualSupervisor?.items ? (
            <AppDataTable
              rows={annualSupervisor?.items}
              columns={columns}
              subHeader={subHeaderComponentMemo}
              pagination={false}
            />
          ) : (
            <div className="d-flex mt-9 justify-content-center general-error-msg">
              {generalErrorMsg}
            </div>
          )
        ) : (
          <SkeletonTable />
        )}
      </>
    </div>
  );
};

export default TableAnnualProduction;
