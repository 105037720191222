import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";

interface ContextProps {
    selectedItem: any,
    setSelectItem: Dispatch<SetStateAction<any>>,
    options: any,
    setOptions: Dispatch<SetStateAction<any>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleGetAll: () => void,
}

export const SelectFinancialContext = createContext<ContextProps>({} as ContextProps);

interface SelectFinancialProviderProps {
    children: ReactNode;
}

interface SelectOption {
    id: number;
    name: string;
}

export const SelectFinancialProvider = ({ children }: SelectFinancialProviderProps) => {
    const [selectedItem, setSelectItem] = useState<any>(null);
    const [options, setOptions] = useState<any>(null);
    const [error, setError] = useState<string>('');

    const handleGetAll = async () => {
        try {
            // const [_Response, _Error] = await service.list({ ...params, withPaginate: false });

            // if (!!_Error) {
            //     return setError(_Response?.message || _Error);
            // }

            const _Response = {
                data: [
                    { id: 29, name: 'BMG' },
                    { id: 30, name: 'DAYCOVAL' },
                    { id: 34, name: 'OLE CONSIGNADO' },
                    { id: 35, name: 'CETELEM' },
                    { id: 43, name: 'BRADESCO FINANCIAMENTOS' },
                    { id: 49, name: 'ITAU CONSIGNADO' },
                    { id: 51, name: 'MERCANTIL' },
                    { id: 61, name: 'PAN' },
                    { id: 72, name: 'SAFRA' },
                    { id: 4365, name: 'ANCORA CONSORCIO' },
                    { id: 4384, name: 'C6 BANK' },
                    { id: 4389, name: 'COIMEX CONSORCIO' },
                    { id: 4394, name: 'BB MAIS' },
                    { id: 4395, name: 'TOO SEGUROS' },
                    { id: 4397, name: 'FACTA FINANCEIRA' },
                    { id: 4401, name: 'ITAU CONSORCIO' },
                    { id: 4406, name: 'RODOBENS' },
                    { id: 4414, name: 'MASTER' },
                    { id: 4417, name: 'QUERO +' },
                    { id: 4419, name: 'RODOBENS PRIVADO' },
                    { id: 4420, name: 'CANOPUS CONSORCIO' },
                    { id: 4422, name: 'SANTANDER CONSORCIO' },
                    { id: 4423, name: 'C6 AUTO' },
                ]
            };

            setOptions(
                _Response.data.map((item: SelectOption) => ({ label: item.name, value: item.id }))
            );

            return setError('');
        } catch (e) {
            setError('Não foi possível carregar o conteúdo do campo');
        }
    };

    return (
        <SelectFinancialContext.Provider value={{
            selectedItem,
            setSelectItem,
            options,
            setOptions,
            error,
            setError,
            handleGetAll,
        }}>{children}</SelectFinancialContext.Provider>
    )
}

export const useSelectFinancial = () => useContext(SelectFinancialContext);