import api from "../../Api";
import { BaseService } from "../../BaseService";
import FileSaver from 'file-saver';

const URL = "api/services/marketplace";

export class EndpointService extends BaseService {

    async getEndpoints(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/public/sanitize/endpoints`));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async getEndpointsUsers(endpoint: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/public/sanitize/users_by_sanitize/${endpoint}`));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async postEndpointChangeStatus(endpoint: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/public/sanitize/endpoint/status/${endpoint}`));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async postEndpointChangeUserStatus(user: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/public/sanitize/user/status/${user}`));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

}