import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { toast } from "react-toastify";
import { VisitsTermService } from "../../../services/Visits/VisitsTerm/VisitsTermService";
import { VisitsTermParams } from "../../../models/Visits/VisitsTerm/VisitsTermParams";
import { VisitsTermModel } from "../../../models/Visits/VisitsTerm/VisitsTermModel";
import moment from "moment";

interface ContextProps {
    term: VisitsTermModel,
    setTerm: Dispatch<SetStateAction<VisitsTermModel>>,
    terms: VisitsTermModel[],
    params: VisitsTermParams,
    setParams: Dispatch<SetStateAction<VisitsTermParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: (params: VisitsTermParams) => Promise<any>,
}

export const VisitsTermContext = createContext<ContextProps>({} as ContextProps);

interface VisitsTermProviderProps {
    children: ReactNode;
}

export const VisitsTermProvider = ({ children }: VisitsTermProviderProps) => {
    const [term, setTerm] = useState<VisitsTermModel>({} as VisitsTermModel);
    const [terms, setTerms] = useState<VisitsTermModel[]>([]);
    const [params, setParams] = useState<VisitsTermParams>({ active: "S" } as VisitsTermParams);
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new VisitsTermService();

    const handleList = async (params: VisitsTermParams) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list({ ...params, withPaginate: true, active: 'S' });
            setIsLoading(false);
            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setTerms(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível os termos');
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    return (
        <VisitsTermContext.Provider value={useMemo(() => ({
            term,
            setTerm,
            terms,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList
        }), [term,
            setTerm,
            terms,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList])}>
            {children}
        </VisitsTermContext.Provider>
    );
}

export const useVisitsTerm = () => useContext(VisitsTermContext);