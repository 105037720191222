import { FC } from 'react';
import './SidebarVisitsTypesContactsFilter.scss';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import { useForm } from 'react-hook-form';
import { useVisitsTypesContacts } from '../../../../providers/Visits/VisitsTypesContacts/VisitsTypesContactsProvider';
import { Col, FormGroup, Row } from 'react-bootstrap';

interface SidebarVisitsTypesContactsFilterProps { }

export const defaultValues = {
  name: ''
} as any;

const SidebarVisitsTypesContactsFilter: FC<SidebarVisitsTypesContactsFilterProps> = () => {

  const { handleSubmit, register } = useForm<any>({ defaultValues });

  const { params, setParams } = useVisitsTypesContacts();

  const onSubmit = async (data: any) => {
    setParams({ ...params, ...data, ...{ page: 1 } });
  }

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarVisitsTypesContactsFilter" data-testid="SidebarVisitsTypesContactsFilter">
        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="name">NOME</label>
              <input
                className="form-control"
                {...register('name')}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  );
}

export default SidebarVisitsTypesContactsFilter;