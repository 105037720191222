import React, { FC } from 'react';
import './VisistsAnalysisBarChart.scss';
import { Bar } from 'react-chartjs-2';

interface VisistsAnalysisBarChartProps {
  labels: string[],
  data: number[],
  max: number,
  height?: number,
}

const chartColors = ["#7779BB", "#222343", "#25CBDB", "#454787", "#787ABA", "#AF7AC5", "#AED6F1", "#3498DB", "#633974", "#D2B4DE", "#D987FA", "#1F9BA7"];

const VisistsAnalysisBarChart: FC<VisistsAnalysisBarChartProps> = ({ labels, data, max, height }) => {
  return (
    <div className="VisistsAnalysisBarChart" data-testid="VisistsAnalysisBarChart">
      <Bar
        data={{
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: chartColors,
              borderRadius: 5,
            }
          ],
        }}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
            datalabels: {
              color: '#333',
              backgroundColor: '#fff',
              font: {
                weight: 'bold',
                size: 10,
              },
              borderRadius: 3,
              anchor: 'end',
              align: 'top',
              clamp: true
            },
            tooltip: {
              enabled: false,
            }
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              grid: {
                display: false,
              },
              min: 0,
              max: max,
              ticks: {
                stepSize: 20
              },
              border: {
                display: false
              }
            },
          },
          layout: {
            padding: {
              top: 30
            }
          }
        }}
        height={height}
      />
    </div>
  );
}

export default VisistsAnalysisBarChart;
