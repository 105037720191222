import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FinancialProcessService } from "../../../services/Proposals/Process/FinancialProcessService";
import { ProposalFinancialModel } from "../../../models/Proposals/Process/ProposalFinancialModel";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";

interface ContextProps {
    financials: ProposalFinancialModel[],
    financialsOptions: ReactSelectOptions[],
    handleList: () => void,
}

export const FinancialProcessContext = createContext<ContextProps>({} as ContextProps);

interface FinancialProcessProviderProps {
    children: ReactNode;
}

export const FinancialProcessProvider = ({ children }: FinancialProcessProviderProps) => {
    const [financials, setFinancials] = useState<ProposalFinancialModel[]>([]);
    const [error, setError] = useState<string>('');
    const [financialsOptions, setFinancialsOptions] = useState<ReactSelectOptions[]>([]);

    const service = new FinancialProcessService();

    const handleList = useCallback(async () => {
        try {
            const [_Response, _Error] = await service.list({ withPaginate: false });

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setFinancials(_Response.data);
            return setError('');
        } catch (e) {
            return setError('Não foi possível carregar as financeiras');
        }
    }, []);

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        setFinancialsOptions(
            financials
                ?.map((item: ProposalFinancialModel) => {
                    return {
                        label: item.name,
                        value: item.id,
                        externalId: item.externalId
                    };
                })
        )
    }, [financials]);

    return (
        <FinancialProcessContext.Provider value={{
            financials,
            financialsOptions,
            handleList
        }}>
            {children}
        </FinancialProcessContext.Provider>
    );
}

export const useFinancialProcess = () => useContext(FinancialProcessContext);