import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { VisitsTypesContactsProvider } from '../../providers/Visits/VisitsTypesContacts/VisitsTypesContactsProvider';
import { VisitsTypesActivitiesProvider } from '../../providers/Visits/VisitsTypesActivities/VisitsTypesActivitiesProvider';
import { VisitsActionsProvider } from '../../providers/Visits/VisitsActions/VisitsActionsProvider';
import { VisitsReportsProvider } from '../../providers/Visits/VisitsReports/VisitsReportsProvider';
import { IndicatorManagementWalletProvider } from '../../providers/Visits/IndicatorManagement/IndicatorManagementWalletProvider';
import { CommercialProvider } from '../../providers/Registrations/User/UserCommercialProvider';
import { IndicatorManagementProductionRangeProvider } from '../../providers/Visits/IndicatorManagement/IndicatorManagementProductionRangeProvider';
import { IndicatorManagementPreVisitProvider } from '../../providers/Visits/IndicatorManagement/IndicatorManagementPreVisitProvider';
import { IndicatorManagementQualityProvider } from '../../providers/Visits/IndicatorManagement/IndicatorManagementQualityProvider';
import { VisitsAnalysisProvider } from '../../providers/Visits/VisitsAnalysis/VisitsAnalysisProvider';
import { VisitsProvider } from '../../providers/Visits/VisitsProvider';
import { VisitsAnalysisDetailsProvider } from '../../providers/Visits/VisitsAnalysis/VisitsAnalysisDetailsProvider';
import { VisitsAgentsLifeProvider } from '../../providers/Visits/VisitsAgentsLife/VisitsAgentsLifeProvider';
import { VisitsTermProvider } from '../../providers/Visits/VisitsTerm/VisitsTermProvider';
import { PartnerProvider } from '../../providers/Registrations/Partner/PartnerProvider';
import { IndicatorManagementRegistrationProvider } from '../../providers/Visits/IndicatorManagement/IndicatorManagementRegistrationProvider';
import { VisitsRankingProvider } from '../../providers/Visits/VisitsRanking/VisitsRankingProvider';
import { IndicatorManagementPasswordProvider } from '../../providers/Visits/IndicatorManagement/IndicatorManagementPasswordProvider';
import { VisitsDivergencesAssessmentsProvider } from '../../providers/Visits/VisitsDivergencesAssessments/VisitsDivergencesAssessmentsProvider';

interface Props {
    element?: any;
    path?: string;
}

export const VisitRoute: FC<Props> = () => {
    return (
        <VisitsTypesContactsProvider>
            <VisitsTypesActivitiesProvider>
                <VisitsActionsProvider>
                    <VisitsReportsProvider>
                        <IndicatorManagementWalletProvider>
                            <VisitsProvider>
                                <IndicatorManagementProductionRangeProvider>
                                    <CommercialProvider>
                                        <IndicatorManagementPreVisitProvider>
                                            <IndicatorManagementQualityProvider>
                                                <VisitsAnalysisProvider>
                                                    <VisitsAnalysisDetailsProvider>
                                                        <VisitsAgentsLifeProvider>
                                                            <IndicatorManagementRegistrationProvider>
                                                                <VisitsTermProvider>
                                                                    <PartnerProvider>
                                                                        <VisitsRankingProvider>
                                                                            <IndicatorManagementPasswordProvider>
                                                                                <VisitsDivergencesAssessmentsProvider>
                                                                                    <VisitsTypesContactsProvider>
                                                                                        <Outlet />
                                                                                    </VisitsTypesContactsProvider>
                                                                                </VisitsDivergencesAssessmentsProvider>
                                                                            </IndicatorManagementPasswordProvider>
                                                                        </VisitsRankingProvider>
                                                                    </PartnerProvider>
                                                                </VisitsTermProvider>
                                                            </IndicatorManagementRegistrationProvider>
                                                        </VisitsAgentsLifeProvider>
                                                    </VisitsAnalysisDetailsProvider>
                                                </VisitsAnalysisProvider>
                                            </IndicatorManagementQualityProvider>
                                        </IndicatorManagementPreVisitProvider>
                                    </CommercialProvider>
                                </IndicatorManagementProductionRangeProvider>
                            </VisitsProvider>
                        </IndicatorManagementWalletProvider>
                    </VisitsReportsProvider>
                </VisitsActionsProvider>
            </VisitsTypesActivitiesProvider>
        </VisitsTypesContactsProvider>
    );
}