import React, { FC, useEffect, useState } from 'react';
import './VisitsReportsChart.scss';
import { Bar } from 'react-chartjs-2';
import { useVisitsReports } from '../../../../providers/Visits/VisitsReports/VisitsReportsProvider';
import moment from 'moment';
import { isDesktop } from 'react-device-detect';

interface VisitsReportsChartProps { }

const VisitsReportsChart: FC<VisitsReportsChartProps> = () => {
  const [labels, setLabels] = useState<string[]>([]);
  const [data, setData] = useState<number[]>([]);

  const { reportMonths } = useVisitsReports();

  useEffect(() => {
    if (reportMonths) {
      let _labels = [];
      for (let i = 4; i >= 0; i--) {
        _labels.push(moment(new Date()).subtract(i, 'months').format('MMM/YYYY').toUpperCase())
      }
      setLabels(_labels);

      let _data: any[] = [];
      _data = reportMonths?.map((item: any) => item.visits)
      setData(_data || []);
    }
  }, [reportMonths]);

  return (
    <div className="VisitsReportsChart chart" data-testid="VisitsReportsChart">
      <Bar
        data={{
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: ["#7779BB", "#222343", "#25CBDB", "#454787", "#787ABA"],
              borderRadius: isDesktop ? 8 : 5,
            }
          ],
        }}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
            datalabels: {
              color: '#333',
              backgroundColor: '#fff',
              font: {
                weight: 'bold',
                size: isDesktop ? 10 : 9,
              },
              borderRadius: isDesktop ? 5 : 3
            },
            tooltip: {
              enabled: false,
            }
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              grid: {
                display: false,
              },
              min: 0,
              ticks: {
                stepSize: 20
              },
              border: {
                display: false
              }
            },
          }
        }}
        className="chart-canvas"
        id="chart-bars"
        height={isDesktop ? 70 : 200}
      />
    </div>
  );
}

export default VisitsReportsChart;
