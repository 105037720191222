import moment from "moment";

export class BiProductionParamsModel {
  filter?: {
    startDate?: string;
    endDate?: string;
    regional?: number[];
    commercial?: number[];
    branch?: number[];
    director?: number[];
    formcontract?: number[];
    state?: string[];
    agent?: number[];
    covenant?: number[];
    mastergroup?: number[];
    executive?: number[];
    covenantgroup?: number[];
    financial?: number[];
  };
  group?: {
    regional?: boolean;
    commercial?: boolean;
    branch?: boolean;
    director?: boolean;
    formcontract?: boolean;
    state?: boolean;
    agent?: boolean;
    covenant?: boolean;
    mastergroup?: boolean;
    executive?: boolean;
    covenantgroup?: boolean;
    financial?: boolean;
  };
  view?: BI_VIEWS_ENUM = BI_VIEWS_ENUM.COMMERCIAL;
  subview?: BI_VIEWS_ENUM = BI_VIEWS_ENUM.COMMERCIAL;
  sundrycovenant?: boolean;
}

export enum BI_VIEWS_ENUM {
  REGIONAL = "regional",
  COMMERCIAL = "commercial",
  BRANCH = "branch",
  DIRECTOR = "director",
  CONTRACT_FORM = "formcontract",
  STATE = "state",
  AGENT = "agent",
  COVENANT = "covenant",
  MASTER_GROUP = "mastergroup",
  EXECUTIVE = "executive",
  COVENANT_GROUP = "covenantgroup",
  FINANCIAL = "financial",
}
