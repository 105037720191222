import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { MonthlyGoalPeriodConfigurationService } from "../../../services/PanelConnects/MonthlyGoal/MonthlyGoalPeriodConfigurationService";
import { MonthlyGoalPeriodConfigListModel } from "../../../models/PanelConnects/MonthlyGoalPeriodConfigListModel";
import { MonthlyGoalAccessProfileEnum } from "../../../enums/PanelConnects/MonthlyGoalAccessProfileEnum";

interface ContextProps {
    configurations: MonthlyGoalPeriodConfigListModel[],
    goals: any[],
    goalsOptions: any[],
    setGoals: Dispatch<SetStateAction<any[]>>,
    isLoadingGoals: boolean,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    handleList: () => void,
    handleSave: (payload: any) => Promise<boolean>,
    listGoals: (closingDate: string, accessProfileId: MonthlyGoalAccessProfileEnum, manual?: any, global?: any) => Promise<boolean>,
    getPeriod: (period: string, accessProfileId: MonthlyGoalAccessProfileEnum) => Promise<MonthlyGoalPeriodConfigListModel | undefined>
}

export const MonthlyGoalPeriodConfigurationContext = createContext<ContextProps>({} as ContextProps);

interface MonthlyGoalPeriodConfigurationProviderProps {
    children: ReactNode;
}

export const MonthlyGoalPeriodConfigurationProvider = ({ children }: MonthlyGoalPeriodConfigurationProviderProps) => {
    const [configurations, setConfigurations] = useState<MonthlyGoalPeriodConfigListModel[]>([]);
    const [goals, setGoals] = useState<any[]>([]);
    const [goalsOptions, setGoalsOptions] = useState<any[]>([]);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingGoals, setIsLoadingGoals] = useState<boolean>(false);
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [params, setParams] = useState<any>({});

    const service = new MonthlyGoalPeriodConfigurationService();

    const handleList = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                setConfigurations([]);
                return setError(_Error);
            }

            setConfigurations(_Response.data);
            setPages(_Response.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setConfigurations([]);
            return setError('Não foi possível carregar as configurações');
        }
    }

    const getPeriod = async (period: string, accessProfileId: MonthlyGoalAccessProfileEnum): Promise<MonthlyGoalPeriodConfigListModel | undefined> => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list({
                startDate: period,
                endDate: period,
                accessProfileId: accessProfileId
            });
            setIsLoading(false);

            if (_Error) {
                return undefined;
            }

            return (_Response?.data && _Response?.data?.length > 0) ? _Response.data[0] : undefined;
        } catch (e) {
            setIsLoading(false);
            return undefined;
        }
    }

    const handleSave = async (payload: any) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.save(payload);
            setIsLoading(false);

            if (_Error) {
                setIsLoading(false);
                setError(_Error);
                return false
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e);
            return false;
        }
    };

    const listGoals = async (closingDate: string, accessProfileId: MonthlyGoalAccessProfileEnum, manual: any = null, global: any = null) => {
        try {
            setIsLoadingGoals(true);
            const [_Response, _Error] = await service.listGoals(closingDate, accessProfileId, manual, global);
            setIsLoadingGoals(false);

            if (_Error) {
                setIsLoadingGoals(false);
                setGoals([]);
                setError(_Error);
                return false;
            }

            setGoals(_Response.data?.sort((a: any, b: any) => a.description < b.description ? -1 : 1));
            setError('');
            return _Response?.data?.length > 0;
        } catch (e) {
            setIsLoadingGoals(false);
            setGoals([]);
            setError('Não foi possível carregar as configurações');
            return false;
        }
    }

    useEffect(() => {
        setGoalsOptions(goals?.map((goal) => ({
            value: goal?.id,
            label: goal?.description
        })))
    }, [goals]);

    return (
        <MonthlyGoalPeriodConfigurationContext.Provider value={useMemo(() => ({
            configurations,
            goals,
            goalsOptions,
            setGoals,
            isLoadingGoals,
            isLoading,
            error,
            setError,
            params,
            setParams,
            pages,
            handleList,
            handleSave,
            listGoals,
            getPeriod
        }), [
            configurations,
            goals,
            goalsOptions,
            setGoals,
            isLoadingGoals,
            isLoading,
            error,
            setError,
            params,
            setParams,
            pages,
            handleList,
            handleSave,
            listGoals,
            getPeriod
        ])}>
            {children}
        </MonthlyGoalPeriodConfigurationContext.Provider>
    );
}

export const useMonthlyGoalPeriodConfiguration = () => useContext(MonthlyGoalPeriodConfigurationContext);