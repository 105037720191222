import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './CommercialPointsTab.scss';
import DataTable from '../../../DataTable/DataTable';
import { CustomHeaderTemplateDataTable } from '../../../../utils/CustomHeaderTemplateDataTable';
import { useMonitoringCommercialPoints } from '../../../../providers/PanelConnects/MonitoringCommercialPoints/MonitoringCommercialPointsProvider';
import { MonitoringPointsHeaderModel } from '../../../../models/PanelConnects/MonitoringCommercialPoints/MonitoringPointsHeaderModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface CommercialPointsTabProps {
  itemsPerPage: number
}

const CommercialPointsTab: FC<CommercialPointsTabProps> = ({ itemsPerPage }) => {
  const [commercialHeader, setCommercialHeader] = useState<MonitoringPointsHeaderModel[]>([]);

  const { header, points, isLoading, commercialPoints, getNameCode } = useMonitoringCommercialPoints();

  useEffect(() => {
    if (header?.length > 0 && points?.commercial && points?.commercial?.length > 0) {
      setCommercialHeader([
        {
          field: 'commercial',
          headerName: 'COMERCIAL',
          pinned: 'left',
          minWidth: 250,
          cellRenderer: getNameCode,
          comparator: (valueA: any, valueB: any) =>
            valueA?.name < valueB?.name ? -1 : 1
        },
        ...header,
        {
          field: 'rank',
          headerName: 'RANKING BEVI',
          cellClass: 'center-row'
        },
        {
          field: 'regional',
          headerName: 'REGIONAL',
          wrapText: false,
          minWidth: 250,
          cellRenderer: getNameCode,
          comparator: (valueA: any, valueB: any) =>
            valueA?.name < valueB?.name ? -1 : 1
        },
        {
          field: 'executive',
          headerName: 'EXECUTIVO',
          wrapText: false,
          minWidth: 250,
          cellRenderer: getNameCode,
          comparator: (valueA: any, valueB: any) =>
            valueA?.name < valueB?.name ? -1 : 1
        },
        {
          field: 'director',
          headerName: 'DIRETOR',
          wrapText: false,
          minWidth: 250,
          cellRenderer: getNameCode,
          comparator: (valueA: any, valueB: any) =>
            valueA?.name < valueB?.name ? -1 : 1
        }
      ]);
    } else {
      setCommercialHeader([]);
    }
  }, [header, points]);


  return (
    <div className="CommercialPointsTab" data-testid="CommercialPointsTab">
      {!isLoading ?
        <DataTable
          columns={commercialHeader}
          data={commercialPoints}
          rowHeight={50}
          rowPerPage={itemsPerPage}
        /> :
        <div className='d-flex justify-content-center align-items-center min-height-400'>
          <FontAwesomeIcon icon={faSpinner} className='text-2xl' spin />
        </div>
      }
    </div>
  )
};

export default CommercialPointsTab;
