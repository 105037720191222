import { FC, useEffect } from 'react';
import './FormRegisterVisits.scss';
import { Carousel } from 'react-responsive-carousel';
import { Card, CardBody, CardHeader, Col, Row } from 'react-bootstrap';
import ExportButtons from '../../../ExportButtons/ExportButtons';
import SkeletonBar from '../../../SkeletonBar/SkeletonBar';
import { useVisitsRegistration } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementRegistrationProvider';
import { TabEnumIndicatorManagement } from '../../../../pages/Visits/IndicatorManagement/IndicatorManagement';
import VisitsRegisterBarChart from './VisitsRegisterBarChart/VisitsRegisterBarChart';
import { VisitsRegisterUnsubscribeModel } from '../../../../models/Visits/VisitsRegister/VisitsRegisterUnsubscribeModel';
import { VisitsRegisterInactiveModel } from '../../../../models/Visits/VisitsRegister/VisitsRegisterInactiveModel';
import { VisitsRegisterReactivatedModel } from '../../../../models/Visits/VisitsRegister/VisitsRegisterReactivatedModel';
import VisitsRegisterTwoBarChart from './VisitsRegisterTwoBarChart/VisitsRegisterTwoBarChart';
import moment from 'moment';
import { VisitsRegisterNewActiveModel } from '../../../../models/Visits/VisitsRegister/VisitsRegisterNewActiveModel';
import { toast } from 'react-toastify';
import { usePartner } from '../../../../providers/Registrations/Partner/PartnerProvider';
import { TypeReportAgent } from '../../../../enums/Visits/IndicatorManagement/EnumTypeReportAgent';
import { useReportRequest } from '../../../../providers/Reports/ReportRequest/ReportRequestProvider';

interface FormRegisterVisitsProps {
  currentActiveTab?: TabEnumIndicatorManagement;
}

const chartColors = ["#7779BB", "#222343", "#25CBDB", "#454787", "#787ABA", "#AF7AC5", "#AED6F1", "#3498DB", "#633974", "#D2B4DE", "#D987FA", "#1F9BA7"];

const FormRegisterVisits: FC<FormRegisterVisitsProps> = ({ currentActiveTab }) => {
  const { handleList, inactives, newActives, reactivateds, unsubscribes, params, error, setError, isLoading } = useVisitsRegistration();
  const { handleAgentExcel, handleAgentReactivatedExcel } = usePartner();
  const { isLoadingFile } = useReportRequest();

  const onSubmitExcel = async (data: any) => {
    handleAgentExcel({ ...params, ...data });
  }

  const onSubmitReactivatedExcel = async (data: any) => {
    handleAgentReactivatedExcel({ ...params, ...data });
  }

  useEffect(() => {
    if (currentActiveTab === TabEnumIndicatorManagement.REGISTER) {
      handleList(params).then();
    }
  }, [currentActiveTab]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError('');
    }
  }, [error]);

  return (
    <div className="FormRegisterVisits" data-testid="FormRegisterVisits">
      <Carousel
        interval={10000}
        statusFormatter={(currentItem: number, total: number) => `${currentItem} de ${total}`}
        showThumbs={false}
        autoPlay
        infiniteLoop
      >
        <div>
          <Row>
            <Col xl="6" className="mb-4">
              <Card className="h-100 shandow-xs-none">
                <CardHeader className="pb-0">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h5 className="surtitle text-gray m-0 text-start">Descadastrados - 4 Meses</h5>
                    </div>
                    <div>
                      <ExportButtons
                        getExcel={() => onSubmitExcel({ status: TypeReportAgent.DESCADASTRADO })}
                        isLoading={isLoadingFile}
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  {!isLoading ? (
                    <VisitsRegisterBarChart
                      labels={unsubscribes?.map((item: VisitsRegisterUnsubscribeModel) => moment(item?.period).format('MMM/YYYY')?.toUpperCase() || '')}
                      data={[
                        {
                          data: unsubscribes?.map((item: VisitsRegisterUnsubscribeModel) => item?.amount ?? 0),
                          backgroundColor: chartColors,
                          borderRadius: 5,
                        }
                      ]}
                      max={Math.trunc(Math.max.apply(Math, unsubscribes?.map((o: VisitsRegisterUnsubscribeModel) => o.amount ?? 0)))}
                    />
                  ) : (
                    <SkeletonBar
                      numberOfBars={14}
                      minHeight={70}
                      maxHeight={450}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col xl="6" className="mb-4">
              <Card className="h-100 shandow-xs-none">
                <CardHeader className="pb-0">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h5 className="surtitle text-gray m-0 text-start">Inativo - 4 Meses</h5>
                    </div>
                    <div>
                      <ExportButtons
                        getExcel={() => onSubmitExcel({ status: TypeReportAgent.INATIVO })}
                        isLoading={isLoadingFile}
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  {!isLoading ? (
                    <VisitsRegisterBarChart
                      labels={inactives?.map((item: VisitsRegisterInactiveModel) => moment(item?.period).format('MMM/YYYY')?.toUpperCase() || '')}
                      data={[
                        {
                          data: inactives?.map((item: VisitsRegisterInactiveModel) => item?.amount ?? 0),
                          backgroundColor: chartColors,
                          borderRadius: 5,
                        }
                      ]}
                      max={Math.trunc(Math.max.apply(Math, inactives?.map((o: VisitsRegisterInactiveModel) => o.amount ?? 0)))}
                    />
                  ) : (
                    <SkeletonBar
                      numberOfBars={14}
                      minHeight={70}
                      maxHeight={450}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <div>
          <Row>
            <Col xl="6" className="mb-4">
              <Card className="h-100 shandow-xs-none">
                <CardHeader className="pb-0">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h5 className="surtitle text-gray m-0 text-start">Reativado - 4 Meses</h5>
                    </div>
                    <div>
                      <ExportButtons
                        getExcel={() => onSubmitReactivatedExcel({ status: TypeReportAgent.REATIVADO })}
                        isLoading={isLoadingFile}
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  {!isLoading ? (
                    <VisitsRegisterTwoBarChart
                      labels={reactivateds?.map((item: VisitsRegisterReactivatedModel) => moment(item?.period).format('MMM/YYYY')?.toUpperCase() || '')}
                      data={[
                        {
                          data: reactivateds?.map((item: VisitsRegisterReactivatedModel) => item?.amount ?? 0),
                          backgroundColor: '#7779BB',
                          borderRadius: 5,
                          yAxisID: 'y'
                        },
                        {
                          data: reactivateds?.map((item: VisitsRegisterReactivatedModel) => item?.production ?? 0),
                          backgroundColor: '#222343',
                          borderRadius: 5,
                          yAxisID: 'secondYAxis'
                        }
                      ]}
                      maxY1={Math.trunc(Math.max.apply(Math, reactivateds?.map((o: VisitsRegisterReactivatedModel) => o.amount ?? 0)))}
                      maxY2={Math.trunc(Math.max.apply(Math, reactivateds?.map((o: VisitsRegisterReactivatedModel) => o.production ?? 0)))}
                    />
                  ) : (
                    <SkeletonBar
                      numberOfBars={14}
                      minHeight={70}
                      maxHeight={450}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col xl="6" className="mb-4">
              <Card className="h-100 shandow-xs-none">
                <CardHeader className="pb-0">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h5 className="surtitle text-gray m-0 text-start">Ativo Novo - 4 Meses</h5>
                    </div>
                    <div>
                      <ExportButtons
                        getExcel={() => onSubmitExcel({ status: TypeReportAgent.ATIVO_NOVO })}
                        isLoading={isLoadingFile}
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  {!isLoading ? (
                    <VisitsRegisterTwoBarChart
                      labels={newActives?.map((item: VisitsRegisterNewActiveModel) => moment(item?.period).format('MMM/YYYY')?.toUpperCase() || '')}
                      data={[
                        {
                          data: newActives?.map((item: VisitsRegisterNewActiveModel) => item?.amount ?? 0),
                          backgroundColor: '#7779BB',
                          borderRadius: 5,
                          yAxisID: 'y'
                        },
                        {
                          data: newActives?.map((item: VisitsRegisterNewActiveModel) => item?.production ?? 0),
                          backgroundColor: '#222343',
                          borderRadius: 5,
                          yAxisID: 'secondYAxis'
                        }
                      ]}
                      maxY1={Math.trunc(Math.max.apply(Math, newActives?.map((o: VisitsRegisterNewActiveModel) => o.amount ?? 0)))}
                      maxY2={Math.trunc(Math.max.apply(Math, newActives?.map((o: VisitsRegisterNewActiveModel) => o.production ?? 0)))}
                    />
                  ) : (
                    <SkeletonBar
                      numberOfBars={14}
                      minHeight={70}
                      maxHeight={450}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Carousel>
    </div>
  );
}

export default FormRegisterVisits;
