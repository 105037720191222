import React, { FC, useState } from 'react';
import './Report.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../providers/PaginateProvider';
import { useReport } from '../../../providers/Reports/Report/ReportProvider';
import { ReportModel } from '../../../models/Reports/Report/ReportModel';
import moment from 'moment';
import AppPagination from '../../../components/AppPagination/AppPagination';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import SidebarReportFilter from '../../../components/Reports/Report/SidebarReportFilter/SidebarReportFilter';
import ModalReportInsert from '../../../components/Reports/Report/ModalReportInsert/ModalReportInsert';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

interface ReportProps { }

const Report: FC<ReportProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { pages, params, setParams, isLoading, error, reports, setReport, handleDelete, handleList } = useReport();
  const { handlePaginate } = usePaginate();

  const handleEdit = (item: ReportModel) => {
    setReport(item);
    setShowModalInsert(true);
  }

  const handleDeleteReport = (item: ReportModel) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja excluir a financeira ${item.name}?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleDelete(item.id || 0).then();
        if (ret) {
          toast.success('Relatório inativado com sucesso!');
          handleList();
        } else {
          await Swal.fire('Ops!', `Não foi possível inativar o relatório.<br>${error}`, 'error');
        }
      }
    });
  }

  const renderList = (): JSX.Element => {
    return (
      <>
        {reports?.length > 0 ? (
          <>
            {reports.map((x: ReportModel, key: number) => (
              <tr key={x.id} className="text-sm">
                <td className="text-center">{x.id}</td>
                <td>{x?.name}</td>
                <td>{x?.description}</td>
                <td className="text-center">{x?.extension}</td>
                <td className="text-center">
                  <span
                    className={`badge ${!!x.deletedAt ? 'badge-danger' : 'badge-success'}`}>{!!x.deletedAt ? 'Inativo' : 'Ativo'}</span>
                </td>
                <td className="text-center">{moment(x.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td>
                  <DropdownMenuTable key={key}>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleEdit(x)}>
                      <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
                    </Dropdown.Item>
                    {!x?.deletedAt && (
                      <Dropdown.Item className="border-radius-md py-2" onClick={() => handleDeleteReport(x)}>
                        <FontAwesomeIcon icon={faTrash} className="me-2" /> Remover
                      </Dropdown.Item>
                    )}
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={7} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7 text-center" scope="col">#</th>
                  <th className="text-uppercase opacity-7" scope="col">Nome</th>
                  <th className="text-uppercase opacity-7" scope="col">Descrição</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Tipo do Arquivo</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Situação</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Data de Acesso</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}

        {reports?.length > 0 && (
          <div>
            <nav aria-label="...">
              <AppPagination
                pages={pages}
                handlePaginate={handlePaginate}
                params={params}
                setParams={setParams}
                style={{ backgroundColor: '#fff' }}
              />
            </nav>
          </div>
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="Relatórios"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="Report" data-testid="Report">
        <PageBase
          title="Relatórios"
          subtitle="Listagem de relatórios do sistema."
          handleInsert={() => setShowModalInsert(true)}
          handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          error={error}
          content={renderContent()}
          hasFilter
        />

        <ModalReportInsert
          show={showModalInsert}
          onClose={() => setShowModalInsert(false)}
        />
      </div>

      <SidebarReportFilter />
    </MasterPage>
  );
}

export default Report;
