import { FC, useEffect } from 'react';
import './PanelImport.scss';
import MasterPage from '../../../../components/MasterPage/MasterPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardImport from '../../../../components/Proposals/Panel/CardImport/CardImport';
import { Card, Col, Row } from 'react-bootstrap';
import ImportToolsWrapper from '../../../../components/Proposals/Import/ImportTools/ImportToolsWrapper/ImportToolsWrapper';
import ImportToolsProcessed from '../../../../components/Proposals/Import/ImportTools/ImportToolsProcessed/ImportToolsProcessed';
import { faChartLine, faChartSimple } from '@fortawesome/free-solid-svg-icons';
import CardQuality from '../../../../components/Proposals/Panel/CardQuality/CardQuality';
import CardQualityProcess from '../../../../components/Proposals/Panel/CardQualityProcess/CardQualityProcess';
import { useFinancial } from '../../../../providers/Registrations/Financial/FinancialProvider';
import { useSidebarFilter } from '../../../../providers/SidebarFilterProvider';
import SidebarFilter from '../../../../components/SidebarFilter/SidebarFilter';

interface PanelImportProps { }

const PanelImport: FC<PanelImportProps> = () => {

    const { setParams: setParamsFin } = useFinancial();
    const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();

    useEffect(() => {
        setParamsFin((current: any) => { return { ...current, withPaginate: false } });
    }, []);

    useEffect(() => {
        if (showSidebarFilter) {
            setShowSidebarFilter(false)
        }
    }, [showSidebarFilter]);


    const renderContent = (): JSX.Element => {
        return (
            <>
                <Col md={12}>
                    <Card className="h-100" data-testid="PanelImport">
                        <Card.Body>

                            <CardImport
                                title="<b>PAINEL DE ACOMPANHAMENTO</b>"
                                icon={< FontAwesomeIcon icon={faChartLine} />}
                                classNameHeader="p-0"
                                classNameBody="p-0"
                                isDetail={true}
                                isTransparent
                            >
                                &nbsp;
                            </CardImport>

                            <Row className="mt-4">
                                <Col md={6}>
                                    <Card className="h-100">
                                        <Card.Body>
                                            <ImportToolsWrapper />
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={6}>
                                    <Card className="h-100">
                                        <Card.Body>
                                            <ImportToolsProcessed />
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>

                            <Row className="mt-4">
                                <Col md={6}>
                                    <Card>
                                        <Card.Body>
                                            <CardImport
                                                title="IMPORTAÇÃO POR BANCO"
                                                icon={<FontAwesomeIcon icon={faChartSimple} />}
                                                classNameHeader="p-0"
                                                classNameBody="p-0"
                                                isTransparent
                                                isDetail={false}
                                            >
                                                <CardQuality />
                                            </CardImport>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={6}>
                                    <Card>
                                        <Card.Body>
                                            <CardImport
                                                title="PROCESSADOS POR BANCO"
                                                icon={<FontAwesomeIcon icon={faChartSimple} />}
                                                classNameHeader="p-0"
                                                classNameBody="p-0"
                                                isDetail={false}
                                                isTransparent
                                            >
                                                <CardQualityProcess />
                                            </CardImport>
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>

                        </Card.Body>
                    </Card>
                </Col >
            </>
        );
    }

    return (
        <MasterPage
            title="Painel de Acompanhamento"
            icon="fas fa-line-chart"
            footerFixed
            footerInverse
        >
            {renderContent()}

            <SidebarFilter />
        </MasterPage>

    );
}

export default PanelImport;
