import { FC, useState } from 'react';
import './ProposalImport.scss';
import MasterPage from '../../../../components/MasterPage/MasterPage';
import PageBase from '../../../../components/PageBase/PageBase';
import { usePaginate } from '../../../../providers/PaginateProvider';
import { useProposalImport } from '../../../../providers/Proposals/Import/ProposalImportProvider';
import AppPagination from '../../../../components/AppPagination/AppPagination';
import SkeletonTable from '../../../../components/SkeletonTable/SkeletonTable';
import moment from 'moment';
import ModalProposalImportInsert from '../../../../components/Proposals/Import/ModalProposalImportInsert/ModalProposalImportInsert';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload,faRefresh } from '@fortawesome/free-solid-svg-icons';
import DropdownMenuTable from '../../../../components/DropdownMenuTable/DropdownMenuTable';
import { ProposalFinancialEnum } from '../../../../enums/Proposals/Process/ProposalFinancialEnum';
import SidebarProposalImportFilter from '../../../../components/Proposals/Import/SidebarProposalImportFilter/SidebarProposalImportFilter';
import { useSidebarFilter } from '../../../../providers/SidebarFilterProvider';
import { ProposalImportModel } from '../../../../models/Proposals/Import/ProposalImportModel';
import { ImportStatusEnum } from '../../../../enums/Proposals/Import/ImportStatusEnum';
import { ImportChannelsEnum } from '../../../../enums/Proposals/Import/ImportChannelsEnum';

interface ProposalImportProps { }

const ProposalImport: FC<ProposalImportProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { params, setParams, pages, imports, handleDownload, handleReprocess , error, isLoading } = useProposalImport();
  const { handlePaginate } = usePaginate();

  const renderList = (): JSX.Element => {
    return (
      <>
        {imports?.length > 0 ? (
          <>
            {imports.map((x: ProposalImportModel, key: number) => (
              <tr key={x.id} className="text-sm">
                <td className="text-center">{x.id}</td>
                <td>{x?.financial?.name}</td>
                <td>{x?.user?.name}</td>
                <td className="text-center">
                  {x?.message ? (
                    <OverlayTrigger
                      placement={'top'}
                      overlay={
                        <Tooltip>
                          <p className="m-0 text-sm" dangerouslySetInnerHTML={{ __html: x?.message?.replace('\n', "<br />") }}></p>
                        </Tooltip>
                      }
                    >
                      <div className="badge badge-sm" style={{ backgroundColor: x?.importStatus?.bgColor, color: x?.importStatus?.textColor }}>{x?.importStatus?.name}</div>
                    </OverlayTrigger>
                  ) : (
                      <OverlayTrigger
                          placement={'top'}
                          overlay={
                            <Tooltip>
                              <p className="m-0 text-sm">Período de processamento: <br />{moment(x.createdAt).format('DD/MM/YYYY HH:mm:ss')} à {moment(x.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</p>
                            </Tooltip>
                          }
                      >
                        <div className="badge badge-sm" style={{ backgroundColor: x?.importStatus?.bgColor, color: x?.importStatus?.textColor }}>{x?.importStatus?.name}</div>
                      </OverlayTrigger>
                  )}
                </td>
                <td className="text-center">
                  {(() => {
                    if (x.processProduction?.startDate && x.processProduction?.endDate) {
                      return (
                        <OverlayTrigger
                          placement={'top'}
                          overlay={
                            <Tooltip>
                              <p className="m-0 text-sm">Período de processamento: <br />{moment(x.processProduction?.startDate).format('DD/MM/YYYY HH:mm:ss')} à {moment(x.processProduction?.endDate).format('DD/MM/YYYY HH:mm:ss')}</p>
                            </Tooltip>
                          }
                        >
                          <div className="badge badge-sm" style={{ backgroundColor: x.processProduction?.bgColor, color: x?.processProduction?.textColor }}>{x?.processProduction?.name}</div>
                        </OverlayTrigger>
                      )
                    } else if (x.processProduction?.startDate) {
                      return (
                        <OverlayTrigger
                          placement={'top'}
                          overlay={
                            <Tooltip>
                              <p className="m-0 text-sm">Inicio do processamento: <br />{moment(x.processProduction?.startDate).format('DD/MM/YYYY HH:mm:ss')}</p>
                            </Tooltip>
                          }
                        >
                          <div className="badge badge-sm" style={{ backgroundColor: x.processProduction?.bgColor, color: x?.processProduction?.textColor }}>{x?.processProduction?.name}</div>
                        </OverlayTrigger>
                      )
                    } else {
                      return <div className="badge badge-sm" style={{ backgroundColor: x.processProduction?.bgColor, color: x?.processProduction?.textColor }}>{x?.processProduction?.name}</div>
                    }
                  })()}
                </td>
                <td className="text-center">
                  {(() => {
                    if (x.reprocessProduction?.startDate && x.reprocessProduction?.endDate && !x.reprocessProduction?.waitingReprocess) {
                      return (
                          <OverlayTrigger
                              placement={'top'}
                              overlay={
                                <Tooltip>
                                  <p className="m-0 text-sm">Período do Reprocessamento: <br />{moment(x.reprocessProduction?.startDate).format('DD/MM/YYYY HH:mm:ss')} à {moment(x.reprocessProduction?.endDate).format('DD/MM/YYYY HH:mm:ss')}</p>
                                </Tooltip>
                              }
                          >
                            <div className="badge badge-sm" style={{ backgroundColor: x.reprocessProduction?.bgColor, color: x?.reprocessProduction?.textColor }}>{x?.reprocessProduction?.name}</div>
                          </OverlayTrigger>
                      )
                    }else if (x.reprocessProduction?.startDate && !x.reprocessProduction?.waitingReprocess) {
                      return (
                          <OverlayTrigger
                              placement={'top'}
                              overlay={
                                <Tooltip>
                                  <p className="m-0 text-sm">Inicio do Reprocessamento: <br />{moment(x.reprocessProduction?.startDate).format('DD/MM/YYYY HH:mm:ss')}</p>
                                </Tooltip>
                              }
                          >
                            <div className="badge badge-sm" style={{ backgroundColor: x.reprocessProduction?.bgColor, color: x?.reprocessProduction?.textColor }}>{x?.reprocessProduction?.name}</div>
                          </OverlayTrigger>
                      )
                    } else if (x.reprocessProduction?.waitingReprocess) {
                      return <div className="badge badge-sm" style={{ backgroundColor: x.reprocessProduction?.bgColor, color: x?.reprocessProduction?.textColor }}>{x?.reprocessProduction?.name}</div>
                    }
                  })()}
                </td>
                <td className="text-center">{x?.channel?.name || '-'}</td>
                <td className="text-center">{x.quantityTotal || '-'}</td>
                <td className="text-center">{x.quantityProcessed || '-'}</td>
                <td className="text-sm text-center">
                  {x?.channel?.id === ImportChannelsEnum.MANUAL && x?.importStatus?.id === ImportStatusEnum.PROCESSED_IMPORT && (
                    <DropdownMenuTable key={key}>
                      <Dropdown.Item className="border-radius-md py-2" onClick={() => handleDownload(x?.id || 0, false)}>
                        <FontAwesomeIcon icon={faDownload} className="me-2" /> Baixar arquivo importado
                      </Dropdown.Item>
                      {x?.processProduction?.startDate && x?.processProduction?.endDate && (
                        <Dropdown.Item className="border-radius-md py-2" onClick={() => handleReprocess(x?.id || 0)}>
                          <FontAwesomeIcon icon={faRefresh} className="me-2" /> Reprocessar
                        </Dropdown.Item>
                      )}
                      {x?.financial?.id === ProposalFinancialEnum.BBMAIS && (
                        <Dropdown.Item className="border-radius-md py-2" onClick={() => handleDownload(x?.id || 0, true)}>
                          <FontAwesomeIcon icon={faDownload} className="me-2" /> Baixar arquivo original BB MAIS
                        </Dropdown.Item>
                      )}
                    </DropdownMenuTable>
                  )}
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={9} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7 text-center" scope="col">ID</th>
                  <th className="text-uppercase opacity-7" scope="col">Financeira</th>
                  <th className="text-uppercase opacity-7" scope="col">Usuário Cadastro</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Status Importação</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Status Processamento</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Reprocessamento</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Canal</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Qtd. Registros<br />Total</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Qtd. Registros<br />Importados</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}
        
        {imports?.length > 0 && (
              <div>
                <nav aria-label="...">
                  <AppPagination
                    pages={pages}
                    handlePaginate={handlePaginate}
                    params={params}
                    setParams={setParams}
                    style={{ backgroundColor: '#fff' }}
                  />
                </nav>
              </div>
            )}
      </>
    );
  }

  return (
    <MasterPage
      title="Importação de Produção"
      icon="fas fa-line-chart"
      footerFixed
      footerInverse
    >
      <div className="ProposalImport" data-testid="ProposalImport">
        <PageBase
          title="Importação de Produção"
          subtitle="Gerenciamento de importações manuais de produção."
          handleInsert={() => setShowModalInsert(true)}
          handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          error={error}
          content={renderContent()}
          textButtonAdd="Importar"
          hasFilter
        />

        <ModalProposalImportInsert
          show={showModalInsert}
          onClose={() => setShowModalInsert(false)}
        />
      </div>

      <SidebarProposalImportFilter />
    </MasterPage>
  );
}

export default ProposalImport;
