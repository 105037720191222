import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import './ApplicationList.scss';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import AppPagination from '../../../AppPagination/AppPagination';
import { useAccessPage } from '../../../../providers/Registrations/Access/AccessPageProvider';
import { AccessPageModel } from '../../../../models/Registrations/Access/AccessPageModel';
import Swal from 'sweetalert2';
import { toast } from "react-toastify";
import { usePaginate } from '../../../../providers/PaginateProvider';
import ButtonEdit from '../../../ButtonEdit/ButtonEdit';
import ButtonDelete from '../../../ButtonDelete/ButtonDelete';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PopoverOrderPage from '../PopoverOrderPage/PopoverOrderPage';
import { useAccessApplication } from '../../../../providers/Registrations/Access/AccessApplicationProvider';

interface ApplicationListProps {
  setShowEdit: Dispatch<SetStateAction<boolean>>;
}

const ApplicationList: FC<ApplicationListProps> = ({ setShowEdit }) => {
  const {
    pages,
    setPages,
    params,
    setParams,
    setPage,
    handleDelete,
    error,
    updateOrders,
    isLoading,
    pagesPaginate
  } = useAccessApplication();
  
  const { handlePaginate } = usePaginate();

  const deletePage = async (page: AccessPageModel) => {
    Swal.fire({
      title: 'Atenção',
      html: `Deseja realmente excluir a página ${page?.name}?`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await handleDelete(page?.id);
        if (!error) {
          toast.success('Registro inativado com sucesso!');
        } else {
          await Swal.fire('Ops!', `Não foi possível inativar a página.<br>${error}`, 'error');
        }
      }
    });
  }

  const handleOnDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    const items: AccessPageModel[] = Array.from(pages) as AccessPageModel[];
    await updateOrders(items, result);

    setPages(items);
  }

  const renderList = () => {
    return (
      <>
        {pages?.length > 0 ? (
          <>
            {pages.map((item: AccessPageModel, key: number) => (
              <Draggable key={item.id} draggableId={item.id?.toString() || ''} index={key}>
                {(provided, snapshot) => (
                  <tr className={`text-sm ${snapshot.isDragging ? 'background' : ''}`}
                    ref={provided.innerRef} {...provided.draggableProps}>
                    <td {...provided.dragHandleProps}>
                      <i className='fas fa-grip-vertical'></i>
                    </td>
                    <td>
                      <PopoverOrderPage page={item} />
                    </td>
                    <td>{item.name}</td>
                    <td>{item.path}</td>
                    <td>{item.showOnMenu ? 'Sim' : 'Não'}</td>
                    <td className="d-flex justify-content-center gap-3" scope="col">
                      <ButtonEdit
                        tooltipText='Editar Página'
                        onClick={() => {
                          setPage(item);
                          setShowEdit(true);
                        }}
                      />
                      <ButtonDelete
                        tooltipText='Remover Página'
                        onClick={() => deletePage(item)}
                      />
                    </td>
                  </tr>
                )}
              </Draggable>
            ))}
          </>
        ) : (
          <tr>
            <td className='text-center' colSpan={5}>Não há registros</td>
          </tr>
        )}
      </>
    )
  }

  return (
    <div className="ApplicationList" data-testid="ApplicationList">
      {!isLoading ? (
        <div className="table-responsive">
          <table className="table table-flush m-0 w-100">
            <thead className="thead-light">
              <tr>
                <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">#Id</th>
                <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">Ordem</th>
                <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">Nome</th>
                <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">Url</th>
                <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">Menu</th>
                <th className="text-center text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">Ações</th>
              </tr>
            </thead>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="pages">
                {(provided) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {renderList()}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </table>
        </div>
      ) : (
        <SkeletonTable />
      )}
      
      {(pages?.length > 0 && !!pagesPaginate) && (
            <div>
              <nav aria-label="...">
                <AppPagination
                  pages={pagesPaginate}
                  handlePaginate={handlePaginate}
                  params={params}
                  setParams={setParams}
                />
              </nav>
            </div>
          )}
    </div>
  );
}

export default ApplicationList;
