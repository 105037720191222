import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { FinancialProvider } from '../../providers/Registrations/Financial/FinancialProvider';
import { ReceiptProvider } from '../../providers/Commissioning/Receipt/ReceiptProvider';
import { EnterpriseProvider } from '../../providers/Registrations/Company/EnterpriseProvider';
import { CampaignProvider } from '../../providers/Commissioning/Campaign/CampaignProvider';
import { ReceiptWriteOffProvider } from '../../providers/Commissioning/Receipt/ReceiptWriteOff/ReceiptWriteOffProvider';
import { ReceiptUnknownProvider } from '../../providers/Commissioning/Receipt/ReceiptUnknown/ReceiptUnknownProvider';

interface Props {
    element?: any;
    path?: string;
}

export const FinancialRoute: FC<Props> = () => {
    return (
        <ReceiptProvider>
            <ReceiptUnknownProvider>
                <FinancialProvider>
                    <EnterpriseProvider>
                        <CampaignProvider>
                            <ReceiptWriteOffProvider>
                                <Outlet />
                            </ReceiptWriteOffProvider>
                        </CampaignProvider>
                    </EnterpriseProvider>
                </FinancialProvider>
            </ReceiptUnknownProvider>
        </ReceiptProvider>
    );
}
