import { FC } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { status } from '../../../../enums/Registrations/Company/Status';
import { buscarPorData } from '../../../../enums/Registrations/Company/BuscarPorData';
import ReactDatetime from 'react-datetime';

interface SidebarCompanyFilterProps { }

const SidebarCompanyFilter: FC<SidebarCompanyFilterProps> = () => {
  return (
    <div className="SidebarCompanyFilter" data-testid="SidebarCompanyFilter">
      <Row className="mt-1">
        <Col>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">CODIGO PROMOTORA</label>
            <input
              className={`form-control`}
              type='text'
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">NOME</label>
            <input
              className={`form-control`}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">ATIVO</label>
            <ReactSelect
              isSearchable
              options={status}
              placeholder="Selecione..."
              className={`form-control p-0`}
              noOptionsMessage={() => 'Não há registros'}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">BUSCAR POR DATA</label>
            <ReactSelect
              isSearchable
              options={buscarPorData}
              placeholder="Selecione..."
              className={`form-control p-0`}
              noOptionsMessage={() => 'Não há registros'}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">DATA INICIAL</label>
            <ReactDatetime
              closeOnSelect
              dateFormat="DD/MM/YYYY"
              timeFormat={false}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <FormGroup className="form-group">
            <label htmlFor="neighborhood">DATA FINAL</label>
            <ReactDatetime
              closeOnSelect
              dateFormat="DD/MM/YYYY"
              timeFormat={false}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

export default SidebarCompanyFilter;
