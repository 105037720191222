import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { MonthlyGoalTagModel } from "../../../models/PanelConnects/MonthlyGoalTagModel";
import { MonthlyGoalConditionService } from "../../../services/PanelConnects/MonthlyGoal/MonthlyGoalConditionService";
import { MonthlyGoalConditionModel } from "../../../models/PanelConnects/MonthlyGoalConditionModel";
import { MonthlyGoalModel } from "../../../models/PanelConnects/MonthlyGoalModel";
import { MonthlyGoalOperationModel } from "../../../models/PanelConnects/MonthlyGoalOperationModel";
import { MonthlyGoalTypeModel } from "../../../models/PanelConnects/MonthlyGoalTypeModel";
import { MonthlyGoalCategoryModel } from "../../../models/PanelConnects/MonthlyGoalCategoryModel";
import { MonthlyGoalScoreTagModel } from "../../../models/PanelConnects/MonthlyGoalScoreTagModel";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";


interface ContextProps {
    tags: MonthlyGoalTagModel[],
    tagsOptions: any[],
    handleTag: () => void,
    // scoreTags: MonthlyGoalScoreTagModel[],
    calculableScoreTagsOptions: any[],
    observableScoreTagsOptions: any[],
    handleScoreTag: () => void,
    conditions: MonthlyGoalTagModel[],
    conditionsOptions: any[],
    handleCondition: () => void,
    operations: MonthlyGoalOperationModel[],
    operationsOptions: any[],
    categories: MonthlyGoalCategoryModel[],
    categoriesOptions: any[],
    categoryPages: PagesPaginateModel,
    categoryParams: any,
    setCategoryParams: Dispatch<SetStateAction<any>>,
    handleOperation: () => void,
    handleCategory: () => void,
    handleSaveCategory: (payload: MonthlyGoalCategoryModel) => Promise<boolean>,
    handleDeleteCategory: (id: number) => Promise<boolean>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    isLoading: boolean,
}

export const MonthlyGoalConditionContext = createContext<ContextProps>({} as ContextProps);

interface MonthlyGoalConditionProviderProps {
    children: ReactNode;
}

export const MonthlyGoalConditionProvider = ({ children }: MonthlyGoalConditionProviderProps) => {

    const [tags, setTags] = useState<MonthlyGoalTagModel[]>([]);
    const [tagsOptions, setTagsOptions] = useState<any[]>([]);
    const [calculableScoreTagsOptions, setCalculableScoreTagsOptions] = useState<any[]>([]);
    const [observableScoreTagsOptions, setObservableScoreTagsOptions] = useState<any[]>([]);
    const [conditions, setConditions] = useState<MonthlyGoalConditionModel[]>([]);
    const [conditionsOptions, setConditionsOptions] = useState<any[]>([]);
    const [operations, setOperations] = useState<MonthlyGoalOperationModel[]>([]);
    const [operationsOptions, setOperationsOptions] = useState<any[]>([]);
    const [categories, setCategories] = useState<MonthlyGoalCategoryModel[]>([]);
    const [categoriesOptions, setCategoriesOptions] = useState<any[]>([]);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [categoryPages, setCategoryPages] = useState<PagesPaginateModel>({});
    const [categoryParams, setCategoryParams] = useState<any>({});

    const service = new MonthlyGoalConditionService();

    const handleTag = useCallback(async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.getTag();
            setIsLoading(false);

            if (_Error) {
                setTags([]);
                return setError(_Response?.message || _Error);
            }

            setTags(_Response.data);
            return setError('');
        } catch (e) {
            setTags([]);
            setIsLoading(false);
            return setError('Não foi possível carregar as condições');
        }
    }, []);

    const handleScoreTag = async () => {
        try {
            setIsLoading(true);
            const [_ResponseCalculable, _ErrorCalculable] = await service.getScoreTag(true, null);
            const [_ResponseObservable, _ErrorObservable] = await service.getScoreTag(null, true);
            setIsLoading(false);

            if (_ErrorCalculable || _ErrorObservable) {
                setCalculableScoreTagsOptions([]);
                setObservableScoreTagsOptions([]);

                return setError(_ErrorCalculable || _ErrorObservable);
            }

            setCalculableScoreTagsOptions(
                _ResponseCalculable?.data?.map((item: MonthlyGoalScoreTagModel) => {
                    return {
                        label: item.description,
                        value: item.id,
                        name: item.name,
                        observation: item.observation
                    };
                })
            );

            setObservableScoreTagsOptions(
                _ResponseObservable?.data?.map((item: MonthlyGoalScoreTagModel) => {
                    return {
                        label: item.description,
                        value: item.id,
                        name: item.name,
                        observation: item.observation
                    };
                })
            );
            return setError('');
        } catch (e) {
            setCalculableScoreTagsOptions([]);
            setObservableScoreTagsOptions([]);
            setIsLoading(false);
            return setError('Não foi possível carregar as condições');
        }
    };

    const handleCondition = useCallback(async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.getCondition();
            setIsLoading(false);

            if (_Error) {
                setConditions([]);
                return setError(_Response?.message || _Error);
            }

            setConditions(_Response.data);
            return setError('');
        } catch (e) {
            setConditions([]);
            setIsLoading(false);
            return setError('Não foi possível carregar as condições');
        }
    }, []);

    const handleOperation = useCallback(async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.getOperation();
            setIsLoading(false);

            if (_Error) {
                setOperations([]);
                return setError(_Response?.message || _Error);
            }

            setOperations(_Response.data);
            return setError('');
        } catch (e) {
            setOperations([]);
            setIsLoading(false);
            return setError('Não foi possível carregar as condições');
        }
    }, []);

    const handleCategory = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.getCategories(categoryParams);
            setIsLoading(false);

            if (_Error) {
                setCategories([]);
                setCategoryPages({});
                return setError(_Response?.message || _Error);
            }

            setCategoryPages(_Response.pages);
            setCategories(_Response.data);
            return setError('');
        } catch (e) {
            setCategories([]);
            setCategoryPages({});
            setIsLoading(false);
            return setError('Não foi possível carregar as condições');
        }
    };

    const handleSaveCategory = async (payload: MonthlyGoalCategoryModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.saveCategory(payload);
            setIsLoading(false);

            if (_Error) {
                setIsLoading(false);
                setError(_Error);
                return false
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e);
            return false;
        }
    }

    const handleDeleteCategory = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.deleteCategory(id);
            setIsLoading(false);

            if (_Error) {
                setIsLoading(false);
                setError(_Error);
                return false
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e);
            return false;
        }
    }

    useEffect(() => {
        handleTag();
        handleCondition();
        handleOperation();
    }, []);

    useEffect(() => {
        setTagsOptions(
            tags?.map((item: MonthlyGoalTagModel) => {
                return { label: item.name, value: item.id };
            })
        )
    }, [tags]);

    useEffect(() => {
        setConditionsOptions(
            conditions?.map((item: MonthlyGoalConditionModel) => {
                return { label: item.name, value: item.id };
            })
        )
    }, [conditions]);

    useEffect(() => {
        setOperationsOptions(
            operations?.map((item: MonthlyGoalOperationModel) => ({
                label: item.operator + ' (' + item.name + ')',
                value: item.id,
                operator: item.operator
            }))
        )
    }, [operations]);

    useEffect(() => {
        setCategoriesOptions(
            categories?.map((item: MonthlyGoalCategoryModel) => ({
                label: item.name, value: item.id
            }))?.sort((a: any, b: any) => a.name < b.name ? -1 : 1)
        )
    }, [categories]);

    return (
        <MonthlyGoalConditionContext.Provider value={useMemo(() => ({
            tags,
            tagsOptions,
            handleTag,
            calculableScoreTagsOptions,
            observableScoreTagsOptions,
            handleScoreTag,
            conditions,
            conditionsOptions,
            handleCondition,
            operations,
            operationsOptions,
            categories,
            categoriesOptions,
            categoryPages,
            categoryParams,
            setCategoryParams,
            handleOperation,
            handleCategory,
            handleSaveCategory,
            handleDeleteCategory,
            error,
            setError,
            isLoading,
        }), [
            tags,
            tagsOptions,
            handleTag,
            calculableScoreTagsOptions,
            observableScoreTagsOptions,
            handleScoreTag,
            conditions,
            conditionsOptions,
            handleCondition,
            operations,
            operationsOptions,
            categories,
            categoriesOptions,
            categoryPages,
            categoryParams,
            setCategoryParams,
            handleOperation,
            handleCategory,
            handleSaveCategory,
            handleDeleteCategory,
            error,
            setError,
            isLoading,
        ])}>
            {children}
        </MonthlyGoalConditionContext.Provider>
    );
}

export const useMonthlyGoalCondition = () => useContext(MonthlyGoalConditionContext);