import { FC, useEffect, useState } from 'react';
import './MonthlyGoalCategory.scss';
import { toast } from 'react-toastify';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../providers/PaginateProvider';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import { useMonthlyGoalCondition } from '../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalConditionProvider';
import AppPagination from '../../../components/AppPagination/AppPagination';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import { MonthlyGoalCategoryModel } from '../../../models/PanelConnects/MonthlyGoalCategoryModel';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import moment from 'moment';
import SidebarMonthlyGoalCategoryFilter from '../../../components/PanelConnects/MonthlyGoalCategory/SidebarMonthlyGoalCategoryFilter/SidebarMonthlyGoalCategoryFilter';
import ModalMonthlyGoalCategoryInsert from '../../../components/PanelConnects/MonthlyGoalCategory/ModalMonthlyGoalCategoryInsert/ModalMonthlyGoalCategoryInsert';

interface MonthlyGoalCategoryProps { }

const MonthlyGoalCategory: FC<MonthlyGoalCategoryProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
  const [categoryEdit, setCategoryEdit] = useState<MonthlyGoalCategoryModel>();

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { handlePaginate } = usePaginate();
  const {
    handleCategory,
    categories,
    handleDeleteCategory,
    isLoading,
    error,
    setError,
    categoryPages,
    categoryParams,
    setCategoryParams
  } = useMonthlyGoalCondition();

  useEffect(() => {
    setCategoryParams({ withPaginate: true })
  }, []);

  useEffect(() => {
    if (!showModalInsert) {
      setCategoryEdit(undefined);
    }
  }, [showModalInsert]);

  useEffect(() => {
    handleCategory();
  }, [categoryParams]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError('');
    }
  }, [error]);

  const handleEdit = (item: MonthlyGoalCategoryModel) => {
    setCategoryEdit(item);
    setShowModalInsert(true);
  }

  const handleDeleteMonthlyGoalCategory = (item: MonthlyGoalCategoryModel) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja excluir a categoria ${item?.name}?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const result = await handleDeleteCategory(item.id ?? 0);
        if (result) {
          toast.success('Categoria deletada com sucesso!');
          handleCategory();
        }
      }
    });
  }

  const renderList = (): JSX.Element => {
    return (
      <>
        {categories?.length > 0 ? (
          <>
            {categories.map((x: MonthlyGoalCategoryModel, key: number) => (
              <tr key={x.id} className="text-sm">
                <td className="text-center">{x.id}</td>
                <td className='text-wrap'>{x?.name}</td>
                <td className='text-wrap'>{x?.description}</td>
                <td className="text-center">{moment(x?.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td className="text-center">
                  <DropdownMenuTable key={key}>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleEdit(x)}>
                      <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
                    </Dropdown.Item>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleDeleteMonthlyGoalCategory(x)}>
                      <FontAwesomeIcon icon={faTrash} className="me-2" /> Remover
                    </Dropdown.Item>
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={5} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7 text-center" scope="col">#</th>
                  <th className="text-uppercase opacity-7" scope="col">Nome</th>
                  <th className="text-uppercase opacity-7" scope="col">Descrição</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Última Alteração</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}

        {categories?.length > 0 && (
          <div>
            <nav aria-label="...">
              <AppPagination
                pages={categoryPages}
                handlePaginate={handlePaginate}
                params={categoryParams}
                setParams={setCategoryParams}
                style={{ backgroundColor: '#fff' }}
              />
            </nav>
          </div>
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="Listagem de Categorias"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="MonthlyGoalCategory" data-testid="MonthlyGoalCategory">
        <PageBase
          title="Listagem de Categorias"
          subtitle="Página para gerenciar as categorias utilizadas nas metas mensais."
          handleInsert={() => setShowModalInsert(true)}
          handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          content={renderContent()}
          hasFilter
        />

        <ModalMonthlyGoalCategoryInsert
          show={showModalInsert}
          onClose={() => setShowModalInsert(false)}
          categoryEdit={categoryEdit}
        />
      </div>

      <SidebarMonthlyGoalCategoryFilter />
    </MasterPage>
  );
}

export default MonthlyGoalCategory;
