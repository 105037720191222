import { FC, useEffect, useState } from 'react';
import './ModalGenerateUnknownReceiptInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../../../../providers/AuthProvider';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { schema } from './Validate';
import { useReceipt } from '../../../../providers/Commissioning/Receipt/ReceiptProvider';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { useCampaign } from '../../../../providers/Commissioning/Campaign/CampaignProvider';
import { useReceiptUnknown } from '../../../../providers/Commissioning/Receipt/ReceiptUnknown/ReceiptUnknownProvider';

interface ModalGenerateUnknownReceiptInsertProps {
  show: boolean;
  onClose: () => void;
}

const ModalGenerateUnknownReceiptInsert: FC<ModalGenerateUnknownReceiptInsertProps> = ({ show, onClose }) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [showSelectCampaign, setShowSelectCampaign] = useState<boolean>(false);
  const [selectedReceiptType, setSelectedReceiptType] = useState<any>(null);
  const [selectedCampaign, setSelectedCampaign] = useState<any>(null);

  const { receiptsTypeOptions } = useReceipt()
  const { campaignsOptions } = useCampaign();
  const { receiptUnknown, setReceiptUnknown, handleFetchGenerateReceipt, setError, handleList } = useReceiptUnknown()

  const { onInvalid } = useAuth();
  const animatedComponents = makeAnimated();

  const { handleSubmit, register, setValue, reset, formState: { errors } } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setError('');
    setSelectedReceiptType(null);
    setSelectedCampaign(null);
    setReceiptUnknown(undefined);
    reset();
    onClose();
  }

  useEffect(() => {
    setValue('campaignId', selectedCampaign?.value);
    setValue('receiptTypeId', selectedReceiptType?.value);

    const hasCampaign: boolean = selectedReceiptType?.value === 4 ? true : false;
    setShowSelectCampaign(hasCampaign);
  }, [selectedCampaign, selectedReceiptType]);

  const onSubmit = async (data: any) => {


    const generateReceiptUnknown = {
      id: receiptUnknown?.id,
      ...data
    }

    const ret = await handleFetchGenerateReceipt(generateReceiptUnknown);

    if (ret) {
      toast.success('Recebimento gerado com sucesso!');
      handleList();
      setSelectedReceiptType(null);
      setSelectedCampaign(null);
      setReceiptUnknown(undefined);
      onClose();
    }

    setIsLoadingSubmit(false);
  }




  return (
    <ModalDefault
      title={'Gerar recebimento'}
      show={show}
      onClose={handleClose}
      sizeModal={'lg'}
      showFooter={true}
      buttonText={isLoadingSubmit ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoadingSubmit}
      disabledSubmit={isLoadingSubmit}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalGenerateUnknownReceiptInsert" data-testid="ModalGenerateUnknownReceiptInsert">
        <Row>
          <Col md={6}>
            <FormGroup className="form-group">
              <label htmlFor="receiptTypeId">TIPO RECEBIMENTO *</label>
              <ReactSelect
                name="receiptTypeId"
                isClearable
                isSearchable
                options={receiptsTypeOptions}
                placeholder="Selecione..."
                className={`form-control p-0 ${onInvalid(errors?.receiptTypeId)}`}
                value={selectedReceiptType ?? ''}
                defaultValue={selectedReceiptType}
                components={animatedComponents}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedReceiptType(val);
                }}
                styles={customStyles}
              />
              <ErrorMessage name={'Tipo de recebimento'} type={errors?.receiptTypeId?.type} />
            </FormGroup>
          </Col>
          {showSelectCampaign && (
            <>
              <Col md={6}>
                <FormGroup className="form-group">
                  <label htmlFor="campaignId">CAMPANHA *</label>
                  <ReactSelect
                    name="campaignId"
                    isClearable
                    isSearchable
                    options={campaignsOptions}
                    placeholder="Selecione..."
                    className={`form-control p-0 ${onInvalid(errors?.campaignId)}`}
                    value={selectedCampaign ?? ''}
                    defaultValue={selectedCampaign}
                    components={animatedComponents}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={(val: any) => {
                      setSelectedCampaign(val);
                    }}
                    styles={customStyles}
                  />
                  <ErrorMessage name={'Campanha'} type={errors?.campaignId?.type} />
                </FormGroup>
              </Col>
            </>
          )}
        </Row>
      </div>
    </ModalDefault>
  );
}

export default ModalGenerateUnknownReceiptInsert;
