import React, { Dispatch, FC, SetStateAction } from 'react';
import './RangeSlider.scss';
import OriginalRangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

interface RangeSliderProps {
  min: number,
  max: number,
  step?: number,
  defaultValue?: number[],
  value?: number[],
  setValue?: Dispatch<SetStateAction<number[]>>,
  onChange?: any,
  allDisabled?: boolean,
  minMaxDisabled?: boolean[],
  orientation?: 'horizontal' | 'vertical',
  className?: string,
  showValues?: boolean
}

const RangeSlider: FC<RangeSliderProps> = ({
  allDisabled,
  defaultValue,
  max,
  min,
  minMaxDisabled,
  onChange,
  orientation,
  step,
  value,
  setValue,
  className,
  showValues = false
}) => {

  const change = (e: number[]) => {
    if (!!setValue) {
      setValue([...e]);
    }
    !!onChange && onChange(e);
  }

  return (
    <div className={`RangeSlider ${showValues && 'gap-2'}`} data-testid="RangeSlider">
      {showValues &&
        <>
          {!!value && value[0]}
        </>
      }
      <OriginalRangeSlider
        min={min}
        max={max}
        step={step}
        defaultValue={defaultValue}
        value={value}
        onInput={change}
        disabled={allDisabled}
        thumbsDisabled={minMaxDisabled}
        orientation={orientation}
        className={className}
      />
      {showValues &&
        <>
          {!!value && value[1]}
        </>
      }
    </div>
  )
};

export default RangeSlider;
