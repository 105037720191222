import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { FinancialModel } from "../../../models/Registrations/Financial/FinancialModel";
import { FinancialParams } from "../../../models/Registrations/Financial/FinancialParams";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { FinancialService } from "../../../services/Registrations/Financial/FinancialService";
import { toast } from "react-toastify";
import { ToastSettings } from "../../../utils/ToastSettings";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";

interface ContextProps {
    financial: FinancialModel,
    setFinancial: Dispatch<SetStateAction<FinancialModel>>,
    financials: FinancialModel[],
    params: FinancialParams,
    setParams: Dispatch<SetStateAction<FinancialParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleFetch: (data: FinancialModel) => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>,
    financialsOptions: ReactSelectOptions[],
    handleListFinancialsOptions: (withPaginate: boolean) => Promise<boolean | void>
}

export const FinancialContext = createContext<ContextProps>({} as ContextProps);

interface FinancialProviderProps {
    children: ReactNode;
}

export const FinancialProvider = ({ children }: FinancialProviderProps) => {
    const [financial, setFinancial] = useState<FinancialModel>({} as FinancialModel);
    const [financials, setFinancials] = useState<FinancialModel[]>([]);
    const [params, setParams] = useState<FinancialParams>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [financialsOptions, setFinancialsOptions] = useState<any[]>([]);

    const service = new FinancialService();

    const handleList = useCallback(async () => {

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);

            setIsLoading(false);

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setFinancials(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar as financeiras');
        }
    }, [params]);

    const handleListFinancialsOptions = async (withPaginate: boolean) => {

        try {
            setIsLoading(true);

            const [_Response, _Error] = await service.list({ withPaginate });

            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response?.message || _Error);
            }

            setFinancials(_Response.data);
            setPages(_Response?.pages);
            setError('');
            return true;
        }
        catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar as financeiras');
            return false;
        }
    }

    const handleFetch = async (data: FinancialModel) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.fetch(data);
            if (!!_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setError('Houve um erro ao salvar a financeira');
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar a financeira');
            return false;
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        setFinancialsOptions(
            financials?.map((item: FinancialModel) => {
                return {
                    label: item.name,
                    value: item.id,
                    externalId: item.externalId
                };
            })
        )
    }, [financials]);

    return (
        <FinancialContext.Provider value={{
            financial,
            setFinancial,
            financials,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleFetch,
            handleDelete,
            handleListFinancialsOptions,
            financialsOptions
        }}>
            {children}
        </FinancialContext.Provider>
    );
}

export const useFinancial = () => useContext(FinancialContext);