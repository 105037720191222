export enum StatusReceiptEnum {
    PENDENTE = 0,
    PAGAMENTO_PARCIAL = 1,
    PAGO = 2
}


export const StatusReceiptColor = (status: StatusReceiptEnum): any => {
    switch (status) {
        case StatusReceiptEnum.PENDENTE:
            return '#dee2e6';
        case StatusReceiptEnum.PAGAMENTO_PARCIAL:
            return '#ffa500';
        case StatusReceiptEnum.PAGO:
            return '#359b6c';
        default:
            return '#808080';
    }
};