import { FC, useState } from 'react';
import './ModalFraming.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useIndicatorManagementPreVisitVisits } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementPreVisitProvider';
import { PreVisitModel } from '../../../../models/Visits/IndicatorManagement/PreVisitModel';
import VisitsFramingList from '../VisitsFramingList/VisitsFramingList';
interface ModalFramingProps {
  show: boolean;
  onClose: () => void;
  framing?: any[]
}

const ModalFraming: FC<ModalFramingProps> = ({ show, onClose, framing }) => {
  const [isLoadingSubmit] = useState<boolean>(false);

  const { setPreVisit, setError } = useIndicatorManagementPreVisitVisits();

  const handleClose = () => {
    setError('');
    setPreVisit({} as PreVisitModel);
    onClose();
  }

  return (
    <ModalDefault
      title={'Enquadramentos'}
      show={show}
      onClose={handleClose}
      sizeModal={'xxl'}
      scrollable
      showFooter={true}
      buttonText={isLoadingSubmit ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoadingSubmit}
      disabledSubmit={isLoadingSubmit}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalFraming" data-testid="ModalFraming">
        <VisitsFramingList framing={framing} />
      </div >
    </ModalDefault >
  );
}

export default ModalFraming;
