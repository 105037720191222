import { FC, useState } from 'react';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import { Col, FormGroup, Row } from 'react-bootstrap';
import InputSearchPartner from '../../../Registrations/Partner/InputSearchPartner/InputSearchPartner';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import moment from 'moment';
import { useLog } from '../../../../providers/Logs/LogProvider';
import { useForm } from 'react-hook-form';
import { LogFormFilterParams } from '../../../../models/Logs/LogScreen/LogFormFilterParams';

interface SidebarLogScreenFilterProps { }

// @ts-ignore
export const defaultValues = {
  ip: '',
  user: null,
  financials: [],
  startDate: '',
  endDate: '',
} as LogFormFilterParams;

const SidebarLogScreenFilter: FC<SidebarLogScreenFilterProps> = () => {
  const [startDate, setStartDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
  const [valuePartner, setValuePartner] = useState<string>('');

  const { params, setParams } = useLog();

  const { handleSubmit, control, register, setValue } = useForm<any>({ defaultValues });

  const onSubmit = (data: LogFormFilterParams) => {
    data.startDate = data?.startDate ? moment(data?.startDate).format('YYYY-MM-DD') : '';
    data.endDate = data?.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : '';
    data.user = data?.user ? parseInt(data?.user?.value) : null;

    setParams({ ...params, ...data, ...{ page: 1 } });
  }

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarLogScreenFilter" data-testid="SidebarLogScreenFilter">
        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="ipClient">IP</label>
              <input
                className="form-control"
                {...register('ipClient')}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="user">USUÁRIO CADASTRO</label>
              <InputSearchPartner
                name="user"
                control={control}
                setValue={setValue}
                valuePartner={valuePartner}
                setValuePartner={setValuePartner}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              startLabelText={'DATA INICIAL'}
              endLabelText={'DATA FINAL'}
            />
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  );
}

export default SidebarLogScreenFilter;
