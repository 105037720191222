import { FC } from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Placement } from "react-bootstrap/types";

interface ButtonDeleteProps {
    onClick?: any,
    color?: string,
    size?: string,
    tooltipText?: string,
    tooltipPlacement?: Placement
}

const ButtonDelete: FC<ButtonDeleteProps> = ({
    onClick,
    color = 'var(--icon)',
    size = 'sm',
    tooltipText = 'Inativar',
    tooltipPlacement = 'top'
}) => (
    <span className="ButtonDelete" data-testid="ButtonDelete">
        {tooltipText ? (
            <OverlayTrigger
                placement={tooltipPlacement}
                overlay={
                    <Tooltip>
                        {tooltipText}
                    </Tooltip>
                }
            >
                <div role="button" tabIndex={0} onClick={onClick}>
                    <i className={`fas fa-trash ${size === 'lg' ? 'text-lg' : ''}`} style={{ color: color }} />
                </div>
            </OverlayTrigger>
        ) : (
            <div role="button" tabIndex={0} onClick={onClick}>
                <i className={`fas fa-trash ${size === 'lg' ? 'text-lg' : ''}`} style={{ color: color }} />
            </div>
        )}
    </span>
);

export default ButtonDelete;
