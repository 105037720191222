import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './ModalMonthlyGoalInsertOperation.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { MonthlyGoalOperationCondition } from '../../../../models/PanelConnects/MonthlyGoalModel';
import { useMonthlyGoalCondition } from '../../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalConditionProvider';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { toast } from 'react-toastify';

interface ModalMonthlyGoalInsertOperationProps {
  show: boolean,
  onClose: any,
  operations: MonthlyGoalOperationCondition[],
  setOperations: Dispatch<SetStateAction<MonthlyGoalOperationCondition[]>>,
}

const ModalMonthlyGoalInsertOperation: FC<ModalMonthlyGoalInsertOperationProps> = ({ show, onClose, operations, setOperations }) => {
  const [selectedOperation, setSelectedOperation] = useState<any>();
  const [selectedTags, setSelectedTags] = useState<any[]>();

  const { handleOperation, operationsOptions, calculableScoreTagsOptions } = useMonthlyGoalCondition();

  useEffect(() => {
    handleOperation();
  }, []);

  useEffect(() => {
    if (!show) {
      setSelectedOperation(null);
      setSelectedTags([]);
    }
  }, [show]);

  const onSubmit = () => {
    if (selectedOperation && selectedTags && selectedTags?.length > 1) {
      setOperations(selectedTags?.map((item) => (
        {
          operationId: selectedOperation?.value,
          scoreTagId: item.value
        }
      )));
      onClose();
    } else {
      toast.warning('Informe a operação e ao menos DUAS tags');
    }
  }

  return (
    <ModalDefault
      title={'Manutenção de Operações'}
      show={show}
      onClose={onClose}
      sizeModal={'lg'}
      showFooter={true}
      buttonText='Adicionar'
      handleSubmit={onSubmit}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalMonthlyGoalInsertOperation" data-testid="ModalMonthlyGoalInsertOperation">
        <Row>
          <Col md={12}>
            <FormGroup className='form-group'>
              <label>OPERAÇÃO</label>
              <ReactSelect
                name='operation'
                placeholder="Selecione..."
                options={operationsOptions}
                className={`form-control p-0`}
                noOptionsMessage={() => 'Não há registros'}
                value={selectedOperation}
                onChange={(val: any) => {
                  setSelectedOperation(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup className='form-group'>
              <label>TAGS</label>
              <ReactSelect
                name='tags'
                isMulti
                isSearchable
                placeholder="Selecione..."
                options={calculableScoreTagsOptions}
                className={`form-control p-0`}
                noOptionsMessage={() => 'Não há registros'}
                value={selectedTags}
                onChange={(val: any) => {
                  setSelectedTags(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className='observation d-flex flex-column'>
              <span>
                As operações matemáticas serão executadas na ordem de escolha das Tags.
              </span>
              <span>
                Ex.: Operação: <b>- (Subtrair)</b>
              </span>
              <span>
                Será: <b>Primeira Tag Escolhida - Segunda Tag Escolhida - Terceira Tag Escolhida</b>,
                e assim sucessivamente.
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  )
};

export default ModalMonthlyGoalInsertOperation;
