import { FC, useEffect, useState } from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import { customStyles } from "../../../models/SelectCustomStyles";
import { useTermsDocuments } from "../../../providers/Terms/TermsDocumentsProvider";
import { TermsDocumentsParams } from "../../../models/Terms/TermsDocumentsParams";
import SidebarFilter from "../../SidebarFilter/SidebarFilter";
import { useForm } from "react-hook-form";
import { TypePersonEnum } from "../../../enums/Person/TypePersonEnum";

interface SidebarTermsDocumentsFilterProps {}

export const defaultValues: TermsDocumentsParams = {
  title: "",
  sector: 0,
  typePerson: 0,
  status: 0,
};

const SidebarTermsDocumentsFilter: FC<
  SidebarTermsDocumentsFilterProps
> = () => {
  const { termsSectorOptions, termsStatusOptions, params, setParams } =
    useTermsDocuments();
  const { handleSubmit, control, setValue } = useForm<TermsDocumentsParams>({
    defaultValues,
  });
  const [selectedSector, setSelectedSector] = useState<any>();
  const [selectedTypePerson, setSelectedTypePerson] = useState<any>();
  const [selectedStatus, setSelectedStatus] = useState<any>();

  useEffect(() => {
    setValue("sector", selectedSector?.value);
  }, [selectedSector]);

  useEffect(() => {
    setValue("typePerson", selectedTypePerson?.value);
  }, [selectedTypePerson]);

  useEffect(() => {
    setValue("status", selectedStatus?.value);
  }, [selectedStatus]);

  const onSubmit = async (data: any) => {
    setParams({ ...params, ...data });
  };

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div
        className="SidebarTermsDocumentsFilter"
        data-testid="SidebarTermsDocumentsFilter"
      >
        <Row className="mt-1">
          <Col md={12}>
            <FormGroup className="form-group">
              <label>TÍTULO DO DOCUMENTO</label>
              <input
                className="form-control"
                type="text"
                onChange={(e) => {
                  setValue("title", e.target.value);
                }}
              />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="sector">SETOR</label>
              <ReactSelect
                isClearable
                isSearchable
                options={termsSectorOptions}
                value={selectedSector ?? ""}
                defaultValue={selectedSector}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => "Não há registros"}
                styles={customStyles}
                onChange={(val: any) => {
                  setSelectedSector(val);
                }}
              />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="typePerson">TIPO PESSOA</label>
              <ReactSelect
                isClearable
                isSearchable
                options={TypePersonEnum}
                value={selectedTypePerson ?? ""}
                defaultValue={selectedTypePerson}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => "Não há registros"}
                styles={customStyles}
                onChange={(val: any) => {
                  setSelectedTypePerson(val);
                }}
              />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="status">STATUS</label>
              <ReactSelect
                isClearable
                isSearchable
                options={termsStatusOptions}
                value={selectedStatus ?? ""}
                defaultValue={selectedStatus}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => "Não há registros"}
                styles={customStyles}
                onChange={(val: any) => {
                  setSelectedStatus(val);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  );
};

export default SidebarTermsDocumentsFilter;
