import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import './NavPills.scss';
import { Col, Row } from 'react-bootstrap';

interface NavPillsProps extends React.HTMLAttributes<HTMLDivElement> {
  options: any[];
  current: string;
  setCurrent: Dispatch<SetStateAction<string>>;
  mdMenu?: number;
  classNameMenu?: string;
}

const NavPills: FC<NavPillsProps> = ({
  options,
  current,
  setCurrent,
  mdMenu = 4,
  classNameMenu = '',
  ...rest
}) => {
  const initNavs = () => {
    document.querySelectorAll('.nav-pills').forEach((item: any) => {
      let moving_div = document.createElement('div');
      let first_li = item.querySelector('li:first-child .nav-link');
      let tab = first_li?.cloneNode();
      tab.innerHTML = "-";

      moving_div.classList.add('moving-tab', 'position-absolute', 'nav-link');
      tab && moving_div.appendChild(tab);
      item.appendChild(moving_div);

      let list_length = item.getElementsByTagName("li").length;

      moving_div.style.padding = '0px';
      moving_div.style.width = item.querySelector('li:nth-child(1)').offsetWidth + 'px';
      moving_div.style.transform = 'translate3d(0px, 0px, 0px)';
      moving_div.style.transition = '.5s ease';

      item.onmouseover = (event: any) => {
        let target = getEventTarget(event);
        let li = target.closest('li');
        if (li) {
          let nodes = Array.from(li.closest('ul').children);
          let index = nodes.indexOf(li) + 1;
          item.querySelector('li:nth-child(' + index + ') .nav-link').onclick = function () {
            moving_div = item.querySelector('.moving-tab');
            let sum = 0;
            if (item.classList.contains('flex-column')) {
              for (var j = 1; j <= nodes.indexOf(li); j++) {
                sum += item.querySelector('li:nth-child(' + j + ')').offsetHeight;
              }
              moving_div.style.transform = 'translate3d(0px,' + sum + 'px, 0px)';
              moving_div.style.height = item.querySelector('li:nth-child(' + j + ')').offsetHeight;
            } else {
              for (var j = 1; j <= nodes.indexOf(li); j++) {
                sum += item.querySelector('li:nth-child(' + j + ')').offsetWidth;
              }
              moving_div.style.transform = 'translate3d(' + sum + 'px, 0px, 0px)';
              moving_div.style.width = item.querySelector('li:nth-child(' + index + ')').offsetWidth + 'px';
            }
          }
        }
      }

      item.onclick = (event: any) => {
        let target = getEventTarget(event);
        let item = target.closest('li a');
        if (item) {
          document.querySelectorAll('.nav-pills li a').forEach(e => e.classList.remove('active'));
          setTimeout(() => {
            item.classList.add('active');
          }, 250);
        }
      }
    });
  }

  window.addEventListener('resize', () => {
    document.querySelectorAll('.nav-pills').forEach((item: any) => {
      item.querySelector('.moving-tab').remove();
      let moving_div = document.createElement('div');
      let tab = item.querySelector(".nav-link.active").cloneNode();
      tab.innerHTML = "-";

      moving_div.classList.add('moving-tab', 'position-absolute', 'nav-link');
      moving_div.appendChild(tab);

      item.appendChild(moving_div);

      moving_div.style.padding = '0px';
      moving_div.style.transition = '.5s ease';

      let li = item.querySelector(".nav-link.active").parentElement;

      if (li) {
        let nodes = Array.from(li.closest('ul').children); // get array
        let index = nodes.indexOf(li) + 1;

        let sum = 0;
        if (item.classList.contains('flex-column')) {
          for (var j = 1; j <= nodes.indexOf(li); j++) {
            sum += item.querySelector('li:nth-child(' + j + ')').offsetHeight;
          }
          moving_div.style.transform = 'translate3d(0px,' + sum + 'px, 0px)';
          moving_div.style.width = item.querySelector('li:nth-child(' + index + ')').offsetWidth + 'px';
          moving_div.style.height = item.querySelector('li:nth-child(' + j + ')').offsetHeight;
        } else {
          for (var j = 1; j <= nodes.indexOf(li); j++) {
            sum += item.querySelector('li:nth-child(' + j + ')').offsetWidth;
          }
          moving_div.style.transform = 'translate3d(' + sum + 'px, 0px, 0px)';
          moving_div.style.width = item.querySelector('li:nth-child(' + index + ')').offsetWidth + 'px';
        }
      }
    });

    if (window.innerWidth < 991) {
      document.querySelectorAll('.nav-pills').forEach((item: any) => {
        if (!item.classList.contains('flex-column')) {
          item.classList.remove('flex-row');
          item.classList.add('flex-column', 'on-resize');
          let li = item.querySelector(".nav-link.active").parentElement;
          let nodes = Array.from(li.closest('ul').children); // get array
          let index = nodes.indexOf(li) + 1;
          let sum = 0;
          for (var j = 1; j <= nodes.indexOf(li); j++) {
            sum += item.querySelector('li:nth-child(' + j + ')').offsetHeight;
          }

          let moving_div = document.querySelector<HTMLElement>('.moving-tab');
          if (moving_div) {
            moving_div.style.width = item.querySelector('li:nth-child(1)').offsetWidth + 'px';
            moving_div.style.transform = 'translate3d(0px,' + sum + 'px, 0px)';
          }
        }
      });
    } else {
      document.querySelectorAll('.nav-pills').forEach((item: any) => {
        if (item.classList.contains('on-resize')) {
          item.classList.remove('flex-column', 'on-resize');
          item.classList.add('flex-row');
          let li = item.querySelector(".nav-link.active").parentElement;
          let nodes = Array.from(li.closest('ul').children); // get array
          let index = nodes.indexOf(li) + 1;
          let sum = 0;
          for (var j = 1; j <= nodes.indexOf(li); j++) {
            sum += item.querySelector('li:nth-child(' + j + ')').offsetWidth;
          }

          let moving_div = document.querySelector<HTMLElement>('.moving-tab');

          if (moving_div) {
            moving_div.style.transform = 'translate3d(' + sum + 'px, 0px, 0px)';
            moving_div.style.width = item.querySelector('li:nth-child(' + index + ')').offsetWidth + 'px';
          }
        }
      })
    }
  });

  const getEventTarget = (e: any) => {
    e = e || window.event;
    return e.target || e.srcElement;
  }

  useEffect(() => {
    setTimeout(() => {
      initNavs();

      // Function to remove flex row on mobile devices
      if (window.innerWidth < 991) {
        document.querySelectorAll('.nav-pills').forEach((item: any) => {
          if (item.classList.contains('flex-row')) {
            item.classList.remove('flex-row');
            item.classList.add('flex-column', 'on-resize');
          }
        });
      }
    }, 100);
  }, []);

  return (
    <div className={`nav-wrapper position-relative end-0 NavPills ${rest?.className}`} data-testid="NavPills">
      <Row>
        <Col md={mdMenu} className={classNameMenu}>
          <ul className="nav nav-pills nav-fill p-1" role="tablist" key={0}>
            {options?.map((item: any, key: number) => (
              <li className={`nav-item ${!item?.show ? 'd-none' : ''}`} key={key}>
                <a
                  className={`nav-link mb-0 px-0 py-1 ${current === item?.acronym ? 'active' : ''} d-flex align-items-center justify-content-center`}
                  data-bs-toggle="tab"
                  href={undefined}
                  role="tab"
                  aria-selected="true"
                  onClick={() => setCurrent(item?.acronym)}
                >
                  {item?.icon}
                  <span className="ms-2">{item?.name}</span>
                </a>
              </li>
            ))}
          </ul>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md={12}>
          {options?.map((item: any, key: number) => (
            <div className={`${item?.show && current === item?.acronym ? 'h-100' : 'd-none'}`} key={key}>
              <>{item?.element}</>
            </div>
          ))}
        </Col>
      </Row>
    </div>
  );
}

export default NavPills;
