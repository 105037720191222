import { FC } from "react";
import { Outlet } from "react-router-dom";
import { ProposalImportProvider } from "../../providers/Proposals/Import/ProposalImportProvider";
import { FinancialProcessProvider } from "../../providers/Proposals/Process/FinancialProcessProvider";
import { ProposalConfigProvider } from "../../providers/Proposals/Config/ProposalConfigProvider";
import { ProposalConfigDetailProvider } from "../../providers/Proposals/Config/ProposalConfigDetailProvider";
import { ProposalRuleProvider } from "../../providers/Proposals/Rule/ProposalRuleProvider";
import { ProposalRuleConditionProvider } from "../../providers/Proposals/Rule/ProposalRuleConditionProvider";
import { ProposalRuleOperationProvider } from "../../providers/Proposals/Rule/ProposalRuleOperationProvider";
import { ProposalExportProvider } from "../../providers/Proposals/Export/ProposalExportProvider";
import { ProposalSituationProvider } from "../../providers/Proposals/Import/ProposalSituationProvider";
import { FinancialProvider } from "../../providers/Registrations/Financial/FinancialProvider";
import { ProposalImportMonitorProvider } from "../../providers/Proposals/Import/Monitor/ProposalImportMonitorProvider";
import { CovenantProvider } from "../../providers/Registrations/Covenant/CovenantProvider";
import { CovenantGroupProvider } from "../../providers/Registrations/Covenant/CovenantGroupProvider";
import { MasterCovenantGroupProvider } from "../../providers/Registrations/Covenant/MasterCovenantGroupProvider";
import { ContractFormConfigProvider } from "../../providers/Tools/Configurations/ContractFormConfigProvider";
import { ContractFormProvider } from "../../providers/Proposals/ContractForm/ContractFormProvider";
import { CovenantTableConfigProvider } from "../../providers/Tools/Configurations/CovenantTableConfigProvider";
import { CovenantTableProvider } from "../../providers/Tools/Configurations/CovenantTableProvider";
interface Props {
  element?: any;
  path?: string;
}

export const ProposalRoute: FC<Props> = () => {
  return (
    <ProposalImportProvider>
      <ProposalConfigProvider>
        <ProposalConfigDetailProvider>
          <ProposalRuleProvider>
            <ProposalRuleConditionProvider>
              <ProposalRuleOperationProvider>
                <ProposalExportProvider>
                  <FinancialProcessProvider>
                    <ProposalSituationProvider>
                      <FinancialProvider>
                        <ProposalImportMonitorProvider>
                          <CovenantProvider>
                            <CovenantGroupProvider>
                              <MasterCovenantGroupProvider>
                                <ContractFormConfigProvider>
                                  <ContractFormProvider>
                                    <FinancialProcessProvider>
                                      <CovenantTableConfigProvider>
                                        <CovenantGroupProvider>
                                          <CovenantProvider>
                                            <CovenantTableProvider>
                                              <Outlet />
                                            </CovenantTableProvider>
                                          </CovenantProvider>
                                        </CovenantGroupProvider>
                                      </CovenantTableConfigProvider>
                                    </FinancialProcessProvider>
                                  </ContractFormProvider>
                                </ContractFormConfigProvider>
                              </MasterCovenantGroupProvider>
                            </CovenantGroupProvider>
                          </CovenantProvider>
                        </ProposalImportMonitorProvider>
                      </FinancialProvider>
                    </ProposalSituationProvider>
                  </FinancialProcessProvider>
                </ProposalExportProvider>
              </ProposalRuleOperationProvider>
            </ProposalRuleConditionProvider>
          </ProposalRuleProvider>
        </ProposalConfigDetailProvider>
      </ProposalConfigProvider>
    </ProposalImportProvider>
  );
};
