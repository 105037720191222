import { AccessPageParams } from "../../../models/Registrations/Access/AccessPageParams";
import { AccessPageModel } from "../../../models/Registrations/Access/AccessPageModel";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/access/page';

export class AccessPageService extends BaseService {

    async details(id: number): Promise<any> {
        try {
            const response = await api.post(`${URL}/details/${id}`);

            return [response.data, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async list(params: AccessPageParams): Promise<any[]> {
        try {
            const _page = params.pageNumber || 1;
            const response = await api.post(`${URL}/${_page}`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getAll(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async create(payload: AccessPageModel): Promise<any> {
        try {
            const response = await api.put(`${URL}`, payload);

            return [response.data, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async delete(id: number, deleteViews: boolean = false, deleteActions: boolean = false): Promise<any> {
        try {
            const response = await api.delete(`${URL}`, {
                data:
                {
                    id,
                    views: deleteViews,
                    actions: deleteActions
                }
            });
            return [response.data, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async updateOrderPages(pages: { id: number, order: number }[]): Promise<any> {
        try {
            const response = await api.put(`${URL}/order`, { pages: pages });
            return [response.data, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}