import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './ModalPreVisits.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useIndicatorManagementPreVisitVisits } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementPreVisitProvider';
import { useForm } from 'react-hook-form';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { toast } from 'react-toastify';
import { PreVisitModel, VisitsPreActionsModel, VisitsPreActivitiesModel } from '../../../../models/Visits/IndicatorManagement/PreVisitModel';
import moment from 'moment';
import InputDateTime from '../../../InputDateTime/InputDateTime';
import DataModalVisits from '../DataModalVisits/DataModalVisits';
import { useIndicatorManagementWalletVisits } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementWalletProvider';
import { useCommercial } from '../../../../providers/Registrations/User/UserCommercialProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validate';
import TooltipItem from '../../../TooltipItem/TooltipItem';
import Swal from 'sweetalert2';
import { useAuth } from '../../../../providers/AuthProvider';

interface ModalPreVisitsProps {
  show: boolean;
  onClose: () => void;
  action: 'add' | 'edit' | 'view' | 'analysis';
  preVisitEdit?: PreVisitModel;
  setPreVisitEdit?: Dispatch<SetStateAction<any>>;
  partner: { id: number, name: string };
  hasAnalysis?: boolean;
  disabledAnalysis?: boolean;
}

const ModalPreVisits: FC<ModalPreVisitsProps> = ({ show, onClose, action, preVisitEdit, hasAnalysis, partner, setPreVisitEdit, disabledAnalysis }) => {

  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
  const [typeAction, setTypeAction] = useState<any[]>([]);
  const [typeActivitie, setTypeActivitie] = useState<any[]>([]);
  const [observation, setObservation] = useState<string>('');
  const [observationAnalysis, setObservationAnalysis] = useState<string>('');
  const [commercial, setCommercial] = useState<any>()

  const { preVisit, setPreVisit, setError, handleFetch, handleList, params: paramsPreVisits, handlePrintPreVisits } = useIndicatorManagementPreVisitVisits();
  const { params, handleGetWallet } = useIndicatorManagementWalletVisits();
  const { handleSubmit, register, setValue, formState: { errors } } = useForm<any>({ mode: 'onChange', resolver: yupResolver(schema) });
  const { commercialsOptions, handleList: handleListCommercial } = useCommercial();
  const { userLogged } = useAuth();
  const { CanAccess } = useAuth();

  const [canApprove, setCanApprove] = useState<any>();
  const [showApproveButton, setShowApproveButton] = useState<any>();

  useEffect(() => {
    if (show) {
      setValue('wasApproved', null);
      setValue('userVisitorId', params?.commercialId);
      handleListCommercial().then();
      setValue('userVisitId', partner?.id);

      if (preVisitEdit) {
        setObservation(preVisitEdit?.observation || '');
        setObservationAnalysis(preVisitEdit?.observationAnalysis || '');
        setTypeAction(preVisitEdit?.visitsPreActions?.map((x: VisitsPreActionsModel) => x.id) || []);
        setTypeActivitie(preVisitEdit?.visitsPreActivities?.map((x: VisitsPreActivitiesModel) => x.id) || []);
        setStartDate(preVisitEdit?.scheduledDate);
        setShowApproveButton((preVisitEdit?.wasApproved === 0 || preVisitEdit?.wasApproved === null) && preVisitEdit?.userAnalysis === null && !!canApprove);
      }
    } else {
      setStartDate(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
      !!setPreVisitEdit && setPreVisitEdit(undefined);
      setTypeAction([])
      setTypeActivitie([])
      setObservation('')
    }
  }, [show, preVisitEdit]);

  useEffect(() => {
    setCommercial(commercialsOptions?.find((x: any) => x.value.toString() === params?.commercialId || x.value === paramsPreVisits?.userVisitorId || x.value === preVisitEdit?.userVisitorId))
  }, [params, paramsPreVisits, preVisitEdit]);

  useEffect(() => {
    setValue('scheduledDate', startDate);
  }, [startDate]);

  useEffect(() => {
    setValue('observation', observation);
  }, [observation]);

  useEffect(() => {
    setValue('visitsPreActions', typeAction);
  }, [typeAction]);

  useEffect(() => {
    setValue('visitsPreActivities', typeActivitie);
  }, [typeActivitie]);

  useEffect(() => {
    CanAccess('pre-visita_agente analise.pode_aprovar.regra').then((x: boolean) => { setCanApprove(x) });
  }, []);

  const handleClose = () => {
    setError('');
    setPreVisit({} as PreVisitModel);
    onClose();
  }

  const onSubmit = async (data: any) => {
    setIsLoadingSubmit(true);
    setError('');

    const ret = await handleFetch({ ...data, observation, id: preVisitEdit?.id });

    if (ret) {
      toast.success('Pré Visita salva com sucesso!');
      action !== 'analysis' && handleGetWallet(params);
      handleList(paramsPreVisits);
    }

    handleClose();
    setIsLoadingSubmit(false);
  }

  const onAnalysis = async (data: any, type: 'approve' | 'disapprove') => {
    if (observationAnalysis?.trim()) {
      Swal.fire({
        html: `Deseja realmente <b>${type === 'approve' ? 'APROVAR' : 'REPROVAR'}</b> a pré-visita?`,
        icon: 'question',
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
      }).then((result) => {
        if (result.isConfirmed) {
          type === 'approve' ? setValue('wasApproved', 1) : setValue('wasApproved', 0);
          setValue('userAnalysisId', userLogged?.id);
          onSubmit(data);
        }
      });
    } else {
      toast.error('Insira observações sobre a análise!');
    }
  }

  const handlePrintPreVisit = (idPreVisit: number) => {
    handlePrintPreVisits(idPreVisit);
  }

  return (
    <ModalDefault
      title={action === 'add' ? 'Pré Visita' : action === 'edit' ? 'Editar Pré Visita' : action === 'analysis' ? 'Análise de Pré Visita' : 'Visualizar Pré Visita'}
      show={show}
      onClose={handleClose}
      sizeModal={'xl'}
      showFooter={true}
      buttonText={action === 'analysis' ? 'Reprovar' : isLoadingSubmit ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoadingSubmit}
      disabledSubmit={isLoadingSubmit}
      handleSubmit={(action === 'view') || (action === 'analysis' && !showApproveButton) ? undefined : action === 'analysis' ? handleSubmit((data) => onAnalysis(data, 'disapprove')) : handleSubmit(onSubmit)}
      handleAdditionalSubmit={action === 'analysis' && showApproveButton ? handleSubmit((data: any) => onAnalysis(data, 'approve')) : undefined}
      buttonBackgroundColor={action === 'analysis' ? 'btn-danger' : undefined}
      buttonAdditionalBackgroundColor={action === 'analysis' ? 'btn-success' : undefined}
      buttonTextColor={action === 'analysis' ? 'text-white' : undefined}
      buttonAdditionalTextColor={action === 'analysis' ? 'text-white' : undefined}
      buttonIcon={action === 'analysis' ? 'fa-solid fa-thumbs-down me-1' : undefined}
      buttonAdditionalIcon={action === 'analysis' ? 'fa-solid fa-thumbs-up me-1' : undefined}
      buttonTextAdditional='Aprovar'
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalPreVisits" data-testid="ModalPreVisits">
        <Row>
          <Col md={11}>
            <strong>Dados da Pré-Visita:</strong>
          </Col>

          {action === 'analysis' && (
            <Col md={1}>
              <TooltipItem text={"Impressão de Pré Visita"} position='top'>
                <button type="button" className="btn btn-primary btn-print" onClick={() => handlePrintPreVisit(preVisitEdit?.id ?? 0)}>
                  <i className="fa-solid fa-print me-1"></i>
                </button>
              </TooltipItem>
            </Col>
          )}
        </Row>

        <Row>
          <Col md={4}>
            <FormGroup className="form-group">
              <label htmlFor="userVisitId">COMERCIAL/RESPONSÁVEL *</label>
              <input
                className={`form-control`}
                type='text'
                disabled={true}
                value={commercial?.label}
              />
              <ErrorMessage name={'Comercial'} />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className="form-group">
              <label htmlFor="userVisitorId">PARCEIRO/VISITA *</label>
              <input
                className={`form-control`}
                type='text'
                disabled={true}
                value={partner?.name}
              />
              <ErrorMessage name={'Parceiro'} />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className="form-group">
              <div className="form-group">
                <InputDateTime
                  startDate={startDate}
                  setStartDate={setStartDate}
                  setValue={setValue}
                  startLabelText={'DATA *'}
                  disableStartDate={action === 'view'}
                  nameStartDate='scheduledDate'
                  disabled={action === 'view' || action === 'analysis'}
                  errors={errors}
                  showClearButton={false}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>

        <DataModalVisits
          action={action}
          typeAction={typeAction}
          setTypeAction={setTypeAction}
          typeActivitie={typeActivitie}
          setTypeActivitie={setTypeActivitie}
          observation={observation}
          setObservation={setObservation}
          typeActionName='visitsPreActions'
          typeActivitieName='visitsPreActivities'
          observationName='observation'
          errors={errors}
        />

        {hasAnalysis && (
          <>
            <Row>
              <Col md={12}>
                <FormGroup className="form-group">
                  <label htmlFor="observationAnalysis">OBSERVAÇÕES VALIDADOR *</label>
                  <textarea rows={3} className='form-control'
                    {...register('observationAnalysis')}
                    onChange={(e: any) => {
                      setObservationAnalysis(e?.target?.value);
                    }}
                    placeholder='Insira observações ou informe pontos importantes da para pré visita.'
                    disabled={!showApproveButton}
                    value={observationAnalysis}
                    maxLength={500}
                  />
                  <ErrorMessage name={'Descrição'} type={errors?.observationAnalysis?.type?.toString()} />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormGroup className="form-group">
                  <label htmlFor="situation">SITUAÇÃO </label><br />
                  <span className={`badge ${(preVisitEdit?.wasApproved === 0 || preVisitEdit?.wasApproved === null) && preVisitEdit?.userAnalysis === null ? 'badge-dark' : !!preVisitEdit?.wasApproved ? 'badge-success' : 'badge-danger'}`}>
                    {(preVisitEdit?.wasApproved === 0 || preVisitEdit?.wasApproved === null) && preVisitEdit?.userAnalysis === null ? 'NÃO ANALISADO' : !!preVisitEdit?.wasApproved ? 'APROVADO' : 'REPROVADO'}
                  </span>
                  <ErrorMessage name={'Situação'} />
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
      </div >
    </ModalDefault >
  );

}

export default ModalPreVisits;