import { FC } from "react";
import './ModalSignature.scss';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import SkeletonTable from "../../../SkeletonTable/SkeletonTable";
import { useTermsTracking } from "../../../../providers/Terms/TermsTrackingProvider";
import FormatPhone from "../../../../utils/FormatPhone";
import returnBadgeStatusSignature from "../../../../utils/BadgeStatusSignature";

interface ModalSignatureProps {
    show: boolean;
    onClose: () => void;
  }
  
  const ModalSignature: FC<ModalSignatureProps> = ({ show, onClose }) => {

    const {isLoading, termsSignatures} = useTermsTracking();

    const renderList = () => {
        return (
            <>
                {
                    termsSignatures?.length > 0 ? termsSignatures?.map((x: any, key: number) => (
                        <>
                            <tr>
                                <td> 
                                    {x?.termSupplierSubscriber?.termSubscriber?.name} <br />
                                    {x?.termSupplierSubscriber?.termSubscriber?.email} <br />
                                    {
                                        x?.termSupplierSubscriber?.termSubscriber?.phone && (
                                            <>
                                                {FormatPhone(x?.termSupplierSubscriber?.termSubscriber?.phone)} <br />       
                                            </>
                                        )
                                    }
                                    <small> <b> {x?.termSupplierSubscriber?.termSupplier?.name} </b> </small>
                                </td>
                                <td> {x?.subscribesType?.toUpperCase()} </td>
                                <td className="text-center">
                                    <span className={`badge ${returnBadgeStatusSignature(x?.termSignatureStatus?.id)}`}> {x?.termSignatureStatus?.name} </span>
                                </td>
                            </tr>
                        </>
                    )) : (
                        <>
                            <tr>
                                <td colSpan={3} className="text-center text-sm">Não há registros</td>
                            </tr>
                        </>
                    )
                }
            </>
        );
    }

    const renderContent = (): JSX.Element => {
        return (
            <>
            {!isLoading ? (
                <div className="table-responsive">
                    <table className="table table-flush m-0 w-100">
                        <thead className="thead-light">
                            <tr>
                                <th className="text-uppercase opacity-7" scope="col">Responsável</th>
                                <th className="text-uppercase opacity-7" scope="col">Segmento</th>
                                <th className="text-uppercase opacity-7 text-center" scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderList()}
                        </tbody>
                    </table>
                </div>
                ) : (
                    <SkeletonTable />
                )}
            </>
        );
    }

    const handleClose = () => {
        onClose();
    }

    return (
        <ModalDefault
            title={"Ver Assinaturas"}
            show={show}
            onClose={handleClose}
            sizeModal={'lg'}
            showFooter={false}
            backdrop="static"
            backgroundColor="#f8f9fa"
            >
            <div className="ModalSignature" data-testid="ModalSignature">
                {renderContent()}
            </div>
        </ModalDefault>
    );
  };

  export default ModalSignature;