import { FC } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useIndicatorManagementProductionRange } from '../../../../../providers/Visits/IndicatorManagement/IndicatorManagementProductionRangeProvider';
import { ProductionRangeListModel } from '../../../../../models/Visits/IndicatorManagement/ProductionRangeModel';

interface ProductionChartByRangeCurrentMonthProps { }

const chartColors = ["#7779BB", "#222343", "#25CBDB", "#454787", "#D987FA", "#AF7AC5", "#AED6F1"];

const ProductionChartByRangeCurrentMonth: FC<ProductionChartByRangeCurrentMonthProps> = () => {
  const { productionsRangeList } = useIndicatorManagementProductionRange();

  return (
    <div className="ProductionChartByRangeCurrentMonth" data-testid="ProductionChartByRangeCurrentMonth">
      <div className='w-100 text-center'>
        <Doughnut
          height={500}
          data={{
            labels: productionsRangeList?.lines?.slice(0, -1)?.map((item: ProductionRangeListModel) => item.range),
            datasets: [
              {
                data: productionsRangeList?.lines?.slice(0, -1)?.map((item: ProductionRangeListModel) => item.quantityPercentM3?.value),
                backgroundColor: chartColors,
              }
            ]
          }}
          options={
            {
              responsive: true,
              maintainAspectRatio: false,
              cutout: '0',
              interaction: {
                intersect: true,
                mode: 'index',
              },
              plugins: {
                legend: {
                  position: 'bottom'
                },
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: (value) => value.raw + '%'
                  }
                },
                datalabels: {
                  anchor: 'end',
                  display: 'auto',
                  color: '#333',
                  align: 'end',
                  offset: 25,
                  backgroundColor: (data) => {
                    return '#E5E5E5';
                  },
                  borderRadius: 5,
                  formatter: (value) => {
                    return value + '%';
                  },
                }
              },
              layout: {
                padding: {
                  top: 50
                }
              }
            }
          }
        />
      </div>
    </div>
  )
};

export default ProductionChartByRangeCurrentMonth;
