import { FC, ReactNode } from 'react';
import './TableDefaultHome.scss';
import { HeadersTable } from '../../models/Tables/TablesDefault';

interface TableDefaultHomeProps extends React.HTMLAttributes<HTMLDivElement> {
  headers: HeadersTable[];
  items: any[];
  numberColumns: number;
  children: ReactNode;
}

const TableDefaultHome: FC<TableDefaultHomeProps> = ({ headers, items, numberColumns, children, ...rest }) => {

  const renderHeader = () => {
    if (headers?.length > 0) {
      return (
        <tr>
          {headers?.map((item: HeadersTable, key: number) => (
            <th className={item?.class} scope="col" key={key}>{item?.name}</th>
          ))}
        </tr>
      );
    }
  }

  const renderList = () => {
    if (items?.length > 0) {
      return (<>{children}</>);
    } else {
      return (
        <tr className='text-sm text-center'>
          <td colSpan={numberColumns}>Não há registros</td>
        </tr>
      );
    }
  }

  return (
    <div className="TableDefaultHome table-responsive p-0" data-testid="TableDefaultHome">
      <table className={`table ${rest?.className}`}>
        <thead className="thead-light">
          {renderHeader()}
        </thead>
        <tbody>
          <>{renderList()}</>
        </tbody>
      </table>
    </div >
  );
}

export default TableDefaultHome;
