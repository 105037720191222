import { FC, useEffect, useRef } from 'react';
import './SidebarFilter.scss';
import { useSidebarFilter } from '../../providers/SidebarFilterProvider';
import { Card, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

interface SidebarFilterProps extends React.HTMLProps<HTMLDivElement> {
    disabledSubmit?: boolean,
    handleSubmit?: () => void,
    handleClear?: () => void,
    buttonRef?: any,
}

const SidebarFilter: FC<SidebarFilterProps> = ({
    children,
    disabledSubmit,
    handleSubmit,
    handleClear,
    buttonRef
}) => {
    const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
    const wrapperRef = useRef<HTMLElement>(null);

    useEffect(() => {
        setShowSidebarFilter(isMobile ? false : true);
    }, [])

    useEffect(() => {
        let body = document.getElementsByTagName("body")[0];

        if (!isMobile)
        {
            if (!!showSidebarFilter) 
            {
                if (body) 
                {
                    body.classList.add('sidebar-filter-show');
                    body.classList.remove('sidebar-filter-hidden');
                }
            }
            else 
            {
                if (body) 
                {
                    body.classList.add('sidebar-filter-hidden');
                    body.classList.remove('sidebar-filter-show');
                }
            }
        }
    }, [showSidebarFilter]);

    return (
        <aside
            ref={wrapperRef}
            className={`SidebarFilter fixed-plugin ${showSidebarFilter ? 'ps show' : ''}`}
            data-testid="SidebarFilter"
        >
            <Row>
                <Card className="shadow-lg px-1">
                    <Card.Header className="card-header py-3 pb-1 bg-transparent d-flex align-items-center justify-content-between">
                        <div>
                            <h5 className="m-0">Filtros</h5>
                        </div>
                        <div>
                            <button className="btn btn-link text-violet-40 p-0 m-0 fixed-plugin-close-button" onClick={() => setShowSidebarFilter(false)}>
                                <i className="fa fa-close"></i>
                            </button>
                        </div>
                    </Card.Header>

                    <Card.Body className="pt-sm-2 pt-0 px-3 overflow-y-auto overflow-x-hidden">
                        {children}
                    </Card.Body>

                    <Card.Footer className="px-3">
                        <button
                            type="button"
                            className="btn bg-primary text-white btn-sm mb-0"
                            onClick={() => {
                                handleSubmit && handleSubmit();
                                isMobile && setShowSidebarFilter(false);
                            }}
                            disabled={disabledSubmit}
                            ref={buttonRef}
                        >
                            Filtrar
                        </button>

                        {!!handleClear &&
                            <button
                                type="button"
                                className="btn btn-outline-primary my-0 ms-2"
                                onClick={handleClear}
                                disabled={disabledSubmit}
                            >
                                Limpar
                            </button>
                        }
                    </Card.Footer>
                </Card>
            </Row>
        </aside>
    );
}

export default SidebarFilter;