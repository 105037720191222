import { FC } from 'react';
import './VisitsFramingList.scss';
import { usePaginate } from '../../../../providers/PaginateProvider';
import AppPagination from '../../../AppPagination/AppPagination';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import moment from 'moment';
import { useIndicatorManagementWalletVisits } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementWalletProvider';
import FormatMoney from '../../../../utils/FormatMoney';

interface VisitsFramingListProps {
  framing?: any[]
}

const VisitsFramingList: FC<VisitsFramingListProps> = ({ framing }) => {
  const { params, setParams, pages, isLoading } = useIndicatorManagementWalletVisits();

  const { handlePaginate } = usePaginate();

  const renderList = (): JSX.Element => {
    return (
      <>
        {framing?.map((item: any, key: number) => (
          <tr key={item?.timeframe_id || key} className="text-sm">

            <td className="text-center">{moment(item?.date).format('DD/MM/YYYY')}</td>
            <td className="text-center">{item?.framing_days ? item?.framing_days : ''}</td>
            <td className="text-center">{item?.timeframe ? item?.timeframe : ''}</td>
            <td className="text-center">{item?.commission ? item?.commission : ''}</td>
            <td className="text-center">{item?.last_30days_production ? FormatMoney(item?.last_30days_production) : ''}</td>
            <td className="text-center">{item?.last_60days_production ? FormatMoney(item?.last_60days_production) : ''}</td>
          </tr>
        ))}
      </>
    );
  }

  return (
    <div className="VisitsTableList" data-testid="VisitsFramingList">
      {!isLoading ? (
        <>
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-center text-uppercase opacity-7" scope="col">DATA</th>
                  <th className="text-center text-uppercase opacity-7" scope="col">ENQUADRAMENTO</th>
                  <th className="text-center opacity-7" scope="col">PRAZO</th>
                  <th className="text-center opacity-7" scope="col">COMISSÃO</th>
                  <th className="text-center text-uppercase opacity-7" scope="col">PRODUÇÃO 30 DIAS</th>
                  <th className="text-center opacity-7" scope="col">PRODUÇÃO 60 DIAS</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <SkeletonTable />
      )}
    </div>
  );
}

export default VisitsFramingList;
