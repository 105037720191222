import { MonthlyGoalAccessProfileEnum } from "../../../enums/PanelConnects/MonthlyGoalAccessProfileEnum";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/connect/menu';

export class MonthlyGoalPeriodConfigurationService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async save(payload: any): Promise<any> {
        try {
            const response = await api.put(`${URL}`, payload);
            return [response.data, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listGoals(closingDate: string, accessProfileId: MonthlyGoalAccessProfileEnum, manual: any, global: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/goals`, { closingDate, accessProfileId, manual, global }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}