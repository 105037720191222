import { FC, useState } from 'react';
import SidebarFilter from '../../SidebarFilter/SidebarFilter';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import InputDateRange from '../../InputDateRange/InputDateRange';
import { useCyberIncident } from '../../../providers/Security/CyberIncidentProvider';

interface SidebarCyberIncidentResponseFormFilterProps { }

// @ts-ignore
export const defaultValues = {
  startDate: '',
  endDate: '',
} as any;

const SidebarCyberIncidentResponseFormFilter: FC<SidebarCyberIncidentResponseFormFilterProps> = () => {
  const [startDate, setStartDate] = useState<any>(moment(new Date()).format('YYYY-MM-01 HH:mm:ss'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));

  const { handleSubmit, setValue } = useForm<any>({ defaultValues });

  const { params, setParams } = useCyberIncident();

  const onSubmit = async (data: any) => {
    data.startDate = data?.startDate ? moment(data?.startDate).format('YYYY-MM-DD') : '';
    data.endDate = data?.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : '';

    setParams({ ...params, ...data, ...{ page: 1 } });
  }

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarCyberIncidentResponseFormFilter" data-testid="SidebarCyberIncidentResponseFormFilter">
        <Row className="mt-1">
          <Col>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              startLabelText={'DATA INICIAL'}
              endLabelText={'DATA FINAL'}
            />
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  );
}

export default SidebarCyberIncidentResponseFormFilter;
