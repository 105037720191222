import { FC } from "react";
import { ModuleToolsProvider } from "../../providers/Registrations/ModuleTools/ModuleToolsProvider";
import { Outlet } from "react-router-dom";

interface Props {
    element?: any;
    path?: string;
}

export const ModuleToolsRoute: FC<Props> = () => {
    return (
        <ModuleToolsProvider>
            <Outlet />
        </ModuleToolsProvider>
    );
}