import { FC, useEffect, useState } from 'react';
import './DashboardProduction.scss';
import MasterPage from '../../../../components/MasterPage/MasterPage';
import PageBase from '../../../../components/PageBase/PageBase';
import { useSidebarFilter } from '../../../../providers/SidebarFilterProvider';
import TabDefault from '../../../../components/Bi/Dashboard/TabDefault/TabDefault';
import SidebarMenu from '../../../../components/Bi/Dashboard/SidebarMenu/SidebarMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarChart, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useBiDashboard } from '../../../../providers/BI/Dashboard/BiDashboardProvider';
import SidebarBiDashboardFilter from '../../../../components/Bi/Dashboard/SidebarBiDashboardFilter/SidebarBiDashboardFilter';
import TooltipItem from '../../../../components/TooltipItem/TooltipItem';

interface DashboardProductionProps { }

const DashboardProduction: FC<DashboardProductionProps> = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const { setShowSidebarFilter } = useSidebarFilter();
  const { handleListGroupReport, tabs } = useBiDashboard();

  useEffect(() => {
    handleListGroupReport();
    setShowSidebarFilter(false);
  }, []);

  useEffect(() => {
    if (tabs?.length === 0) {
      setShowMenu(true);
    }
  }, [tabs]);

  const renderContent = (): JSX.Element => {
    return (
      <div>
        <div className="d-flex justify-content-md-end justify-content-center gap-2">
          <button
            className="btn btn-secondary text-white"
            onClick={() => setShowMenu((current) => !current)}
          >
            <FontAwesomeIcon icon={faBarChart} /> Menu de Relatórios
          </button>
          <TooltipItem position='top' text='Abrir manual de utilização'>
            <button
              className="btn btn-primary manual-button"
              onClick={() => window.open(`${process.env.PUBLIC_URL}/documents/bi/ManualBI.pdf`, '_blank', 'noopener,noreferrer')}
            >
              <FontAwesomeIcon icon={faQuestionCircle} className='text text-lg' />
            </button>
          </TooltipItem>
        </div>
        <TabDefault />
      </div>
    );
  };

  return (
    <MasterPage
      title="Dashboard BI"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="DashboardProduction" data-testid="DashboardProduction">
        <PageBase
          title="Dashboard BI"
          subtitle="Gerenciamento Dashboard BI"
          handleFilter={() => setShowSidebarFilter((current: boolean) => !current)}
          // error={error}
          content={renderContent()}
          handleAddtionalMenu={() => setShowMenu((current) => !current)}
          hasFilter
        />

        <SidebarMenu show={showMenu} onClose={setShowMenu} />
      </div>

      <SidebarBiDashboardFilter />
    </MasterPage>
  );
};

export default DashboardProduction;
