import { FC, useEffect, useRef, useState } from "react";
import "./ModalTermsInsert.scss";
import ModalDefault from "../../ModalDefault/ModalDefault";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Card, Col, FormGroup, Row } from "react-bootstrap";
import { schema } from "./Validate";
import ReactSelect from "react-select";
import { TermsDocumentsParams } from "../../../models/Terms/TermsDocumentsParams";
import { useTermsDocuments } from "../../../providers/Terms/TermsDocumentsProvider";
import { customStyles } from "../../../models/SelectCustomStyles";
import { toast } from "react-toastify";
import "react-quill/dist/quill.snow.css";
import {
  TermsDocumentsTagsModel,
  TermsTagsColumnsTablesModel,
} from "../../../models/Terms/TermsDocumentsTagsModel";
import Tags from "@yaireo/tagify/dist/react.tagify";
import { TermsDocumentsTagsDescModel } from "../../../models/Terms/TermsDocumentsTagsDescModel";
import { TypePersonEnum } from "../../../enums/Person/TypePersonEnum";

interface ModalTermsInsertProps {
  show: boolean;
  onClose: () => void;
}

export const defaultValues: TermsDocumentsParams = {};

const ModalTermsInsert: FC<ModalTermsInsertProps> = ({ show, onClose }) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const quillRef = useRef<any>(null);
  const {
    termsSectorOptions,
    handleSave,
    handleList,
    setParams,
    setError,
    term,
    setTerm,
    tags,
    tagsDesc,
    handleListGetDocument,
    documents,
    handleUpdate,
  } = useTermsDocuments();
  const [selectedSector, setSelectedSector] = useState<any>();
  const [selectedTypePerson, setSelectedTypePerson] = useState<any>();
  const [tagsValues, setTagsValues] = useState<any>(null);
  const tagifyRef = useRef<any>(null);
  const [tagsBlur, setTagsBlue] = useState<[]>([]);
  const [valuDf, setValueDf] = useState<string | undefined>("");

  const handleBlur = () => {
    if (tagifyRef.current) {
      const blur = tagifyRef.current.value?.map((x: any) => x.id);
      setTagsBlue(blur);
    }
  };

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setError("");
    setTerm({} as any);
    onClose();
  };

  const onSubmit = async (data: any) => {
    setIsLoadingSubmit(true);
    setError("");
    setValueDf(documents);

    setValue("termsTagsColumnsTablesIds", tagsBlur);

    data.kindOfPerson.value === 1
      ? setValue("kindOfPerson", "T")
      : data.kindOfPerson.value === 2
      ? setValue("kindOfPerson", "F")
      : setValue("kindOfPerson", "J");

    if (term?.id) {
      const updt = {
        name: data.name,
        hasExclusive: data.hasExclusive,
        kindOfPerson: data.kindOfPerson,
        termDocumentCategoryId: data.termDocumentCategoryId,
        htmlContent: data.htmlContent,
      };
      const ret = await handleUpdate(updt, term?.id);

      if (ret) {
        toast.success("Termo alterado com sucesso!");
        handleList({});
        onClose();
      }
    } else {
      const ret = await handleSave(data as any);

      if (ret) {
        toast.success("Termo salvo com sucesso!");
        handleList({});
        onClose();
      }
    }

    setIsLoadingSubmit(false);
  };

  useEffect(() => {
    const tagsList = tagsDesc?.map((x: TermsDocumentsTagsDescModel) => {
      return { value: x.name, id: x.id };
    });

    setTagsValues(tagsList);
  }, [tagsDesc]);

  useEffect(() => {
    setValue("htmlContent", documents ?? "");
  }, [documents]);

  useEffect(() => {
    setValue("kindOfPerson", selectedTypePerson);
  }, [selectedTypePerson]);

  useEffect(() => {
    if (quillRef.current) {
      quillRef.current.editor.root.style.height = "420px";
    }
    if (show) {
      if (term?.id) {
        setValueDf(documents);
        handleListGetDocument(term?.id);

        setValue("name", term?.name ?? "");
        setValue("hasExclusive", 0);
        setValue("termDocumentCategoryId", 1);
        term?.kindOfPerson === "J"
          ? setSelectedTypePerson({ id: 3, label: "PESSOA JURÍDICA" })
          : term?.kindOfPerson === "F"
          ? setSelectedTypePerson({ id: 2, label: "PESSOA FÍSICA" })
          : setSelectedTypePerson({ id: 1, label: "TODOS" });
        setSelectedSector({
          id: term?.category?.id,
          label: term?.category?.name,
        });
      } else {
        setValueDf("");
        reset();
        setValue("htmlContent", "");
        setValue("name", "");
        setSelectedTypePerson(null);
        setSelectedSector(null);
      }
    }
  }, [show]);

  return (
    <ModalDefault
      title={"Cadastrar Documento"}
      show={show}
      onClose={handleClose}
      sizeModal={"xxl"}
      showFooter={true}
      buttonText={isLoadingSubmit ? "Salvando..." : "Salvar"}
      showButtonSpinner={isLoadingSubmit}
      disabledSubmit={isLoadingSubmit}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalTermsInsert" data-testid="ModalTermsInsert">
        <Row>
          <Col md={8} sm={12}>
            <Card
              style={{
                maxHeight: "660px",
                minHeight: "660px",
                display: "flex",
              }}
              className="p-3"
            >
              <FormGroup className="form-group">
                <label>TÍTULO DO DOCUMENTO</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={term?.name ?? ""}
                  onChange={(e) => {
                    setValue("name", e.target.value);
                  }}
                />
              </FormGroup>

              <FormGroup className="form-group d-flex align-items-center">
                <div style={{ width: "50%" }}>
                  <label htmlFor="kindOfPerson">TIPO PESSOA</label>
                  <ReactSelect
                    isClearable
                    isSearchable
                    options={TypePersonEnum}
                    value={selectedTypePerson ?? ""}
                    defaultValue={selectedTypePerson}
                    placeholder="Selecione..."
                    className={`form-control p-0`}
                    noOptionsMessage={() => "Não há registros"}
                    styles={customStyles}
                    onChange={(val: any) => {
                      setSelectedTypePerson(val);
                      setValue("kindOfPerson", val);
                      setValue("hasExclusive", 0);
                      setValue("termDocumentCategoryId", 1);
                    }}
                  />
                </div>

                <div className="ms-2" style={{ width: "50%" }}>
                  <label htmlFor="sector">SETOR</label>
                  <ReactSelect
                    isClearable
                    isSearchable
                    options={termsSectorOptions}
                    value={selectedSector ?? ""}
                    defaultValue={selectedSector}
                    placeholder="Selecione..."
                    className={`form-control p-0`}
                    noOptionsMessage={() => "Não há registros"}
                    styles={customStyles}
                    onChange={(val: any) => {
                      setSelectedSector(val);
                      setValue("termDocumentCategoryId", val.value);
                    }}
                  />
                </div>
              </FormGroup>

              <div>
                <FormGroup>
                  <div style={{ height: "450" }}>
                    <Tags
                      defaultValue={valuDf}
                      InputMode="textarea"
                      tagifyRef={tagifyRef}
                      settings={{
                        placeholder:
                          "Digite o texto e tags (iniciando com @ ou #)...",
                        mode: "mix",
                        pattern: /@|#/,
                        dropdown: {
                          enabled: 1,
                        },
                        enforceWhitelist: true,
                        whitelist: tagsValues,
                      }}
                      onChange={(val: any) => {
                        setValue("htmlContent", val.detail.value);
                      }}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                  </div>
                </FormGroup>
              </div>
            </Card>
          </Col>

          <Col md={4} sm={12}>
            <Card className="p-2 mt-1 mb-1" style={{ display: "flex" }}>
              {tags?.map((x: TermsDocumentsTagsModel) => (
                <div>
                  <b>#</b> TAGS - {x?.name}
                  <br />
                  <hr />
                  <ul
                    style={{
                      columnCount: "2",
                      columnGap: "30px",
                      padding: 0,
                      listStyleType: "none",
                    }}
                  >
                    {x?.termsTagsColumnsTables?.map(
                      (t: TermsTagsColumnsTablesModel) => (
                        <li>#{t?.tag?.name}#</li>
                      )
                    )}
                  </ul>
                </div>
              ))}
            </Card>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
};

export default ModalTermsInsert;
