import { FC, useEffect, useRef, useState } from 'react';
import './Sidebar.scss';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { AccessPageModel } from '../../models/Registrations/Access/AccessPageModel';
import { Collapse } from 'react-bootstrap';
import { useStyle } from '../../providers/Style/StyleProvider';

interface SidebarProps {
}

const Sidebar: FC<SidebarProps> = () => {
    const [open, setOpen] = useState<string>('');
    const [openSubview, setOpenSubview] = useState<string>('');
    const wrapperRef = useRef<HTMLElement>(null);

    const { userLogged, accessToken } = useAuth();
    const { urlDomus } = useStyle();
    const location = useLocation();

    const handleClickOutside = (event: any) => {
        if (wrapperRef.current && !wrapperRef.current?.contains(event.target)) {
            if (
                !event.target.classList.contains('sidenav-toggler-inner') &&
                !event.target.classList.contains('sidenav-toggler-line') &&
                !event.target.classList.contains('sidenav') &&
                !event.target.classList.contains('nav')
            ) {
                const sidenavShow = document.getElementsByClassName('g-sidenav-pinned')[0];
                if (sidenavShow && !sidenavShow.classList.contains('g-sidenav-hidden')) {
                    sidenavShow.classList.remove('g-sidenav-show');
                    sidenavShow.classList.add('g-sidenav-hidden');
                }
            }
        }
    }

    useEffect(() => {
        userLogged?.accessProfile?.pages?.filter((x: AccessPageModel) => x.path !== '/home')?.map((prop: any) => {
            prop?.views?.map((view: any) => {
                if (location.pathname.indexOf(prop.path + view.layout) > -1) {
                    return setOpen(prop?.state);
                }
            })
        });
    }, []);

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, [wrapperRef]);

    const activeRoute = (routeName: string) => {
        return location.pathname === routeName ? "active" : "";
    };

    const renderList = () => {
        return userLogged?.accessProfile?.pages?.filter((x: AccessPageModel) => x.path !== '/home')?.map((prop: any) => {
            return (
                <li className="nav-item" key={prop?.id}>
                    {!prop.collapse ? (
                        <Link to={prop.path} className={`nav-link text-wrap ${activeRoute(prop.path)}`}>
                            {!!prop?.icon &&
                                <div
                                    className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                    <i className={`${prop?.icon} text-secondary text-sm opacity-10`} />
                                </div>}
                            <span className="nav-link-text ms-1">{prop?.name}</span>
                        </Link>
                    ) : (
                        <>
                            <a data-bs-toggle="collapse"
                                onClick={() => {
                                    if (open !== prop?.state) {
                                        setOpen(prop?.state);
                                        setOpenSubview('');
                                    } else {
                                        setOpen('');
                                    }
                                }}
                                className={`nav-link text-wrap ${activeRoute(prop.path + prop.layout)}`}
                                aria-controls={prop?.state} role="button" aria-expanded="false">
                                {!!prop?.icon &&
                                    <div
                                        className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className={`${prop?.icon} text-secondary text-sm opacity-10`} />
                                    </div>}
                                <span className="nav-link-text ms-1">{prop?.name}</span>
                            </a>

                            <Collapse in={prop?.state === open}>
                                <ul className="nav ms-4">
                                    {
                                        prop?.views?.map((view: any) => {
                                            return (
                                                <li className={`nav-item`}
                                                    key={view?.id}>
                                                    {!view?.collapse ?
                                                        <Link
                                                            className={`nav-link text-wrap ${activeRoute(prop?.path + view?.layout)}`}
                                                            to={prop?.path + view?.layout}
                                                        >
                                                            {!!view?.icon &&
                                                                <div
                                                                    className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                                                    <i className={`${view?.icon} text-secondary text-sm opacity-10`} />
                                                                </div>}
                                                            <span className="sidenav-mini-icon">
                                                                {view?.name.charAt(0)}
                                                            </span>
                                                            <span className="sidenav-normal">
                                                                {view?.name}
                                                            </span>
                                                        </Link>
                                                        :
                                                        <>
                                                            <a data-bs-toggle="collapse"
                                                                onClick={() => setOpenSubview(openSubview !== view?.state ? view?.state : '')}
                                                                className={`nav-link text-wrap ${activeRoute(prop.path + view.path)}`}
                                                                aria-controls={view?.state} role="button"
                                                                aria-expanded="false">
                                                                {!!view?.icon &&
                                                                    <div
                                                                        className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                                                        <i className={`${view?.icon} text-secondary text-sm opacity-10`} />
                                                                    </div>}
                                                                <span className="nav-link-text ms-0">{view?.name}</span>
                                                            </a>

                                                            <Collapse in={view?.state === openSubview}>
                                                                <ul className="nav ms-4">
                                                                    {
                                                                        view?.views?.map((item: any) => {
                                                                            return (
                                                                                <li className={`nav-item`} key={item?.id}>
                                                                                    <Link
                                                                                        className={`nav-link text-wrap ${activeRoute(prop?.path + view?.path + item?.layout)}`}
                                                                                        to={prop?.path + view?.path + item?.layout}
                                                                                    >
                                                                                        {!!item?.icon &&
                                                                                            <div
                                                                                                className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                                                                                <i className={`${item?.icon} text-secondary text-sm opacity-10`} />
                                                                                            </div>}
                                                                                        <span
                                                                                            className="sidenav-mini-icon">
                                                                                            {item?.name.charAt(0)}
                                                                                        </span>
                                                                                        <span
                                                                                            className="sidenav-normal">
                                                                                            {item?.name}
                                                                                        </span>
                                                                                    </Link>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </Collapse>

                                                        </>
                                                    }
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </Collapse>
                        </>
                    )}
                </li>
            );
        });
    }

    return (
        <aside
            ref={wrapperRef}
            className="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 fixed-start Sidebar"
            id="sidenav-main"
            data-testid="Sidebar"
        >
            <hr className="horizontal dark mt-0" />
            <div className="collapse navbar-collapse w-auto h-auto" id="sidenav-collapse-main">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className={`nav-link`} href={`${urlDomus}/app/api/v1/auth/authTokenSB?token=${accessToken}`} >
                            <div
                                className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                <i className={`fas fa-home text-secondary text-sm opacity-10`} />
                            </div>
                            <span className="nav-link-text ms-1">Home</span>
                        </a>
                    </li>
                    {renderList()}
                </ul>
            </div>
        </aside>
    );
}

export default Sidebar;
