import React, { FC } from 'react';
import './SidebarMonthlyGoalCategoryFilter.scss';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import { useForm } from 'react-hook-form';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { useMonthlyGoalCondition } from '../../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalConditionProvider';

interface SidebarMonthlyGoalCategoryFilterProps { }

const SidebarMonthlyGoalCategoryFilter: FC<SidebarMonthlyGoalCategoryFilterProps> = () => {

  const { categoryParams, setCategoryParams } = useMonthlyGoalCondition();
  const { handleSubmit, register } = useForm();

  const onSubmit = async (data: any) => {
    setCategoryParams({ ...categoryParams, ...data });
  }

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)} >
      <div className="SidebarMonthlyGoalCategoryFilter" data-testid="SidebarMonthlyGoalCategoryFilter">
        <Row>
          <Col>
            <FormGroup className='form-group'>
              <label>Nome</label>
              <input
                className='form-control'
                type='text'
                {...register('name')}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  )
};

export default SidebarMonthlyGoalCategoryFilter;
