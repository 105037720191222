import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { ProposalRuleConditionService } from "../../../services/Proposals/Rule/ProposalRuleConditionService";
import { ProposalRuleConditionModel } from "../../../models/Proposals/Rule/Condition/ProposalRuleConditionModel";
import { ProposalRuleConditionListParams } from "../../../models/Proposals/Rule/Condition/ProposalRuleConditionListParams";

interface ContextProps {
    conditions: ProposalRuleConditionModel[],
    conditionsOptions: any[],
    params: ProposalRuleConditionListParams,
    setParams: Dispatch<SetStateAction<ProposalRuleConditionListParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
}

export const ProposalRuleConditionContext = createContext<ContextProps>({} as ContextProps);

interface ProposalRuleConditionProviderProps {
    children: ReactNode;
}

export const ProposalRuleConditionProvider = ({ children }: ProposalRuleConditionProviderProps) => {
    const [conditions, setConditions] = useState<ProposalRuleConditionModel[]>([]);
    const [conditionsOptions, setConditionsOptions] = useState<any[]>([]);
    const [params, setParams] = useState<ProposalRuleConditionListParams>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new ProposalRuleConditionService();

    const handleList = useCallback(async () => {
        try {
            const [_Response, _Error] = await service.list({ withPaginate: false });

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setConditions(_Response.data);
            return setError('');
        } catch (e) {
            return setError('Não foi possível carregar as condições');
        }
    }, []);

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    useEffect(() => {
        setError('');
    }, []);

    useEffect(() => {
        setConditionsOptions(
            conditions
                ?.map((item: ProposalRuleConditionModel) => {
                    return { label: item.name, value: item.id, operator: item.operator };
                })
        )
    }, [conditions]);

    return (
        <ProposalRuleConditionContext.Provider value={{
            conditions,
            conditionsOptions,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
        }}>
            {children}
        </ProposalRuleConditionContext.Provider>
    );
}

export const useProposalRuleCondition = () => useContext(ProposalRuleConditionContext);