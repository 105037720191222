import { FC, useEffect, useState } from 'react';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { schema } from './Validate';
import { Col, FormGroup, Row } from 'react-bootstrap';
import InputDropzone from '../../../InputDropzone/InputDropzone';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { customStyles } from '../../../../models/SelectCustomStyles';
import ReactSelect from 'react-select';
import { useAuth } from '../../../../providers/AuthProvider';
import makeAnimated from 'react-select/animated';
import { useFinancialProcess } from '../../../../providers/Proposals/Process/FinancialProcessProvider';
import { useProposalImport } from '../../../../providers/Proposals/Import/ProposalImportProvider';
import { ProposalFinancialEnum } from '../../../../enums/Proposals/Process/ProposalFinancialEnum';
import { ReactSelectOptions } from '../../../../models/ReactSelectOptions';

interface IForm {
  financialId: number;
  file: string;
}

interface ModalProposalImportInsertProps {
  show: boolean;
  onClose: () => void;
}

const ModalProposalImportInsert: FC<ModalProposalImportInsertProps> = ({ show, onClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<any[]>([]);
  const [selectedFinancial, setSelectedFinancial] = useState<ReactSelectOptions | null>(null);
  const [fileTypes, setFileTypes] = useState<any>({
    'application/vnd.ms-excel': ['.xls', '.xlsx', '.csv'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
  });

  const { onInvalid } = useAuth();
  const { handleFetch } = useProposalImport();
  const { handleList: handleListFinancials, financialsOptions } = useFinancialProcess();
  const animatedComponents = makeAnimated();

  const { handleSubmit, setValue, control, reset, formState: { errors } } = useForm<IForm>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleClose = async () => {
    onClose();
  }

  const onSubmit = (data: IForm) => {
    setIsLoading(true);

    handleFetch(data, files).then((ret: boolean) => {
      setIsLoading(false);

      ret && handleClose();
    });
  }

  useEffect(() => {
    if (show) {
      handleListFinancials();

      setIsLoading(false);
      setSelectedFinancial(null);
      setFiles([]);
      reset();
    }
  }, [show]);

  useEffect(() => {
    if (files?.length > 0) {
      setValue('file', files[0]?.path);
    } else {
      setValue('file', '');
    }
  }, [files]);

  useEffect(() => {
    setValue('financialId', selectedFinancial?.value ?? 0);

    if (selectedFinancial?.value === ProposalFinancialEnum.BBMAIS) {
      setFileTypes({
        'text/plain': ['.ret']
      });
    } else {
      setFileTypes({
        'application/vnd.ms-excel': ['.xls', '.xlsx', '.csv'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
      });
    }
  }, [selectedFinancial]);

  return (
    <ModalDefault
      title={'Importar Arquivo de Produção'}
      show={show}
      onClose={handleClose}
      sizeModal={'lg'}
      buttonText={isLoading ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoading}
      disabledSubmit={isLoading}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
    >
      <div className="ModalProposalImportInsert" data-testid="ModalProposalImportInsert">
        <Row>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="financialId">Financeira *</label>
              <Controller
                name="financialId"
                control={control}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isClearable
                    isSearchable
                    options={financialsOptions}
                    placeholder="Selecione..."
                    className={`form-control p-0 ${onInvalid(errors?.financialId)}`}
                    value={selectedFinancial ?? ''}
                    defaultValue={selectedFinancial}
                    components={animatedComponents}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={(val: any) => {
                      onChange(val?.value ?? null);
                      setSelectedFinancial(val);
                    }}
                    styles={customStyles}
                  />
                )}
              />
              <ErrorMessage name="Financeira"
                type={errors?.financialId?.type} />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup className="form-group">
              <InputDropzone
                label="Arquivo de Importação *"
                name="file"
                files={files}
                setFiles={setFiles}
                types={fileTypes}
                maxSize={157286400}
                error={!!errors?.file}
              />

              <ErrorMessage name="Arquivo"
                type={errors?.file?.type} />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
}

export default ModalProposalImportInsert;
