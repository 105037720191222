import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { AccessProfileProvider } from '../../providers/Registrations/Access/AccessProfileProvider';
import { AccessPageProvider } from '../../providers/Registrations/Access/AccessPageProvider';
import { AccessActionProvider } from '../../providers/Registrations/Access/AccessActionProvider';
import { AccessApplicationProvider } from '../../providers/Registrations/Access/AccessApplicationProvider';

interface Props {
    element?: any;
    path?: string;
}

export const AccessLevelRoute: FC<Props> = () => {
    return (
        <AccessProfileProvider>
            <AccessPageProvider>
                <AccessApplicationProvider>
                    <AccessActionProvider>
                        <Outlet />
                    </AccessActionProvider>
                </AccessApplicationProvider>
            </AccessPageProvider>
        </AccessProfileProvider>
    );
}
