import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { ApiKeyService } from "../../services/ApiKey/ApiKeyService";
import { ApiKeyModel } from "../../models/ApiKey/ApiKeyModel";

interface ContextProps {
  apiKey: ApiKeyModel | undefined;
  isLoading: boolean;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  createApiKey: () => any;
  listApiKey: () => any;
}

export const ApiKeyContext = createContext<ContextProps>({} as ContextProps);

interface ApiKeyProviderProps {
  children: ReactNode;
}

export const ApiKeyProvider = ({ children }: ApiKeyProviderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [apiKey, setApiKey] = useState<ApiKeyModel>({});

  const service = new ApiKeyService();

  const createApiKey = useCallback(async () => {
    try {
      setIsLoading(true);
      const [_Response, _Error] = await service.createKey();
      setIsLoading(false);

      if (_Error) {
        setApiKey({});
        setError(_Error);
      }

      setApiKey(_Response?.data);
      setError("");
    } catch (e) {
      setIsLoading(false);
      setApiKey({});
      setError("Não foi possível gerar a api key.");
    }
  }, []);

  const listApiKey = useCallback(async () => {
    try {
      setIsLoading(true);
      const [_Response, _Error] = await service.listKey();
      setIsLoading(false);

      if (_Error) {
        setApiKey({});
        setError(_Error);
      }

      return _Response?.data[0];
    } catch (e) {
      setIsLoading(false);
      setApiKey({});
      setError("Não foi possível gerar a api key.");
    }
  }, []);

  return (
    <ApiKeyContext.Provider
      value={useMemo(
        () => ({
          apiKey,
          isLoading,
          error,
          setError,
          createApiKey,
          listApiKey,
        }),
        [apiKey, isLoading, error, setError, createApiKey]
      )}
    >
      {children}
    </ApiKeyContext.Provider>
  );
};

export const useApiKey = () => useContext(ApiKeyContext);
