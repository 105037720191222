import { FC } from "react";
import { BiDashboardProvider } from "../../providers/BI/Dashboard/BiDashboardProvider";
import { BiExecutiveProvider } from "../../providers/BI/Dashboard/Executive/BiExecutiveProvider";
import { BiGeneralProductionProvider } from "../../providers/BI/Dashboard/BiGeneralProductionProvider";
import { BiCommercialProvider } from "../../providers/BI/Dashboard/BiCommercialProvider";
import { BiFinancialProductionProvider } from "../../providers/BI/Dashboard/BiFinancial/BiFinancialProductionProvider";
import { BiFinancialByUfProvider } from "../../providers/BI/Dashboard/BiFinancial/BiFinancialByUfProvider";
import { BiCovenantProvider } from "../../providers/BI/Dashboard/Covenant/BiCovenantProvider";
import { BiPartnerProvider } from "../../providers/BI/Dashboard/BiPartnerProvider";
import { BiContractFormProvider } from "../../providers/BI/Dashboard/BiContractFormProvider";
import { BiFinancialUpdateFrequencyProvider } from "../../providers/BI/BiFinancialUpdateFrequencyProvider";
import { Outlet } from "react-router-dom";
import { BiSupervisorProvider } from "../../providers/BI/Dashboard/Supervisor/BiSupervisorProvider";
import { BiGroupProvider } from "../../providers/BI/Dashboard/BiGroupProvider";
import { CommercialProvider } from "../../providers/Registrations/User/UserCommercialProvider";
import { SubsidiaryProvider } from "../../providers/Registrations/Company/SubsidiaryProvider";
import { CovenantProvider } from "../../providers/Registrations/Covenant/CovenantProvider";
import { CovenantGroupProvider } from "../../providers/Registrations/Covenant/CovenantGroupProvider";
import { MasterCovenantGroupProvider } from "../../providers/Registrations/Covenant/MasterCovenantGroupProvider";
import { ContractFormProvider } from "../../providers/Proposals/ContractForm/ContractFormProvider";
import { FinancialProvider } from "../../providers/Registrations/Financial/FinancialProvider";
interface Props {
  element?: any;
  path?: string;
}

export const BiRoute: FC<Props> = () => {
  return (
    <BiDashboardProvider>
      <BiGeneralProductionProvider>
        <BiCommercialProvider>
          <BiExecutiveProvider>
            <BiFinancialProductionProvider>
              <BiFinancialByUfProvider>
                <BiContractFormProvider>
                  <BiPartnerProvider>
                    <BiCovenantProvider>
                      <BiFinancialUpdateFrequencyProvider>
                        <BiSupervisorProvider>
                          <BiGroupProvider>
                            <CommercialProvider>
                              <SubsidiaryProvider>
                                <FinancialProvider>
                                  <CovenantProvider>
                                    <CovenantGroupProvider>
                                      <MasterCovenantGroupProvider>
                                        <ContractFormProvider>
                                          <Outlet />
                                        </ContractFormProvider>
                                      </MasterCovenantGroupProvider>
                                    </CovenantGroupProvider>
                                  </CovenantProvider>
                                </FinancialProvider>
                              </SubsidiaryProvider>
                            </CommercialProvider>
                          </BiGroupProvider>
                        </BiSupervisorProvider>
                      </BiFinancialUpdateFrequencyProvider>
                    </BiCovenantProvider>
                  </BiPartnerProvider>
                </BiContractFormProvider>
              </BiFinancialByUfProvider>
            </BiFinancialProductionProvider>
          </BiExecutiveProvider>
        </BiCommercialProvider>
      </BiGeneralProductionProvider>
    </BiDashboardProvider>
  );
};
