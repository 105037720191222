import { AccessActionModel } from "./AccessActionModel";

export class AccessPageModel {
    id?: number | null;
    name?: string;
    parentId?: number;
    parent?: number;
    parentObj?: AccessPageModel;
    icon?: string;
    path?: string; // caminho base da url - ex: na url /admin/usuario, essa propriedade teria a informação /admin
    layout?: string; // caminho final da url - ex: na url /admin/usuario, essa propriedade teria a informação /usuario
    collapse?: string | boolean | null = null; // true se layout for vazio e não tiver parent, se não false
    state?: string; // é o path + Collapse - ex: adminCollapse
    views?: AccessPageModel[] = [];
    actions?: AccessActionModel[] = []; // Usado quando carrega o perfil de acesso
    actionsPage?: AccessActionModel[] = []; // Usado quando carrega tudo da página
    order?: number;
    type?: string; // Define se é uma página do desktop ou mobile
    showOnMenu?: string | boolean | null = null; // Define se a página será mostrada no menu ou não - Atualmente usada só para as páginas mobile
}