import React, { FC } from 'react';
import './SidebarCovenantGroupFilter.scss';
import { Col, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import makeAnimated from 'react-select/animated';
import { yesNoOption } from '../../../../models/YesNoOptions';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import { useCovenantGroup } from '../../../../providers/Registrations/Covenant/CovenantGroupProvider';
import { useMasterCovenantGroup } from '../../../../providers/Registrations/Covenant/MasterCovenantGroupProvider';

interface SidebarCovenantGroupFilterProps { }

const activeInactiveOptions = [
  { value: 'S', label: 'Ativos' },
  { value: 'N', label: 'Inativos' },
]

const SidebarCovenantGroupFilter: FC<SidebarCovenantGroupFilterProps> = () => {

  const { setParams } = useCovenantGroup();
  const { masterGroupOptions } = useMasterCovenantGroup();
  const { handleSubmit, register, control } = useForm();
  const animatedComponents = makeAnimated();

  const onSubmit = async (data: any) => {
    const _data = { ...data };
    _data.blocked = _data?.blocked?.value;
    _data.closure = _data?.closure?.value;
    _data.active = _data?.active?.value;
    _data.idMasterGroup = _data?.idMasterGroup?.value?.toString();

    setParams(_data);
  }

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarCovenantGroupFilter" data-testid="SidebarCovenantGroupFilter">
        <Row>
          <Col xs={12} className='form-group'>
            <label>Nome</label>
            <input
              className='form-control'
              type='text'
              {...register('name')}
            />
          </Col>
          <Col xs={12} className='form-group'>
            <label>Bloqueado</label>
            <Controller
              control={control}
              name='blocked'
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  placeholder="Selecione..."
                  options={yesNoOption}
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                  onChange={(val: any) => {
                    onChange(val ?? null);
                  }}
                />
              )}
            />
          </Col>
          <Col xs={12} className='form-group'>
            <label>Fechamento</label>
            <Controller
              control={control}
              name='closure'
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  placeholder="Selecione..."
                  options={yesNoOption}
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                  onChange={(val: any) => {
                    onChange(val ?? null);
                  }}
                />
              )}
            />
          </Col>
          <Col xs={12} className='form-group'>
            <label>Situação</label>
            <Controller
              control={control}
              name='active'
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  placeholder="Selecione..."
                  options={activeInactiveOptions}
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                  onChange={(val: any) => {
                    onChange(val ?? null);
                  }}
                />
              )}
            />
          </Col>
          <Col xs={12} className='form-group'>
            <label>Grupo Master</label>
            <Controller
              control={control}
              name='idMasterGroup'
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  placeholder="Selecione..."
                  options={masterGroupOptions}
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                  onChange={(val: any) => {
                    onChange(val ?? null);
                  }}
                />
              )}
            />
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  )
};

export default SidebarCovenantGroupFilter;
