import { FC, useEffect, useState } from 'react';
import './ModalVisitsTypesActivitiesInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useVisitsTypesActivities } from '../../../../providers/Visits/VisitsTypesActivities/VisitsTypesActivitiesProvider';
import { useForm } from 'react-hook-form';
import { VisitsTypesActivitiesModel } from '../../../../models/Visits/VisitsTypesActivities/VisitsTypesActivitiesModel';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useAuth } from '../../../../providers/AuthProvider';
import { toast } from 'react-toastify';

interface ModalVisitsTypesActivitiesInsertProps {
  show: boolean;
  onClose: () => void;
}

type FormValues = {
  id?: number;
  name?: string;
  description?: number;
}

const ModalVisitsTypesActivitiesInsert: FC<ModalVisitsTypesActivitiesInsertProps> = ({ show, onClose }) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const { typesActivitie, setTypesActivitie, setError, handleList, handleFetch } = useVisitsTypesActivities();
  const { onInvalid } = useAuth();

  const { handleSubmit, register, reset, formState: { errors } } = useForm({
    mode: "onChange",
  });

  const handleClose = () => {
    setError('');
    setTypesActivitie({} as VisitsTypesActivitiesModel);
    onClose();
  }

  const onSubmit = async (data: any) => {
    setIsLoadingSubmit(true);
    setError('');

    const ret = await handleFetch(data as VisitsTypesActivitiesModel);

    if (ret) {
      toast.success('Foco salvo com sucesso!');
      handleList();
      onClose();
    }

    setIsLoadingSubmit(false);
  }

  useEffect(() => {
    if (show) {
      if (!!typesActivitie?.id) {
        reset(typesActivitie as FormValues);
      } else {
        reset(new VisitsTypesActivitiesModel());
        setTypesActivitie({} as VisitsTypesActivitiesModel);
      }
    }
  }, [show]);

  return (
    <ModalDefault
      title={!!typesActivitie?.id ? 'Editar Foco' : 'Adicionar Foco'}
      show={show}
      onClose={handleClose}
      sizeModal={'lg'}
      showFooter={true}
      buttonText={isLoadingSubmit ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoadingSubmit}
      disabledSubmit={isLoadingSubmit}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalVisitsTypesActivitiesInsert" data-testid="ModalVisitsTypesActivitiesInsert">
        <Row>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="name">NOME *</label>
              <input
                className={`form-control ${onInvalid(errors?.name)}`}
                type='text'
                {...register('name')}
                onChange={(e: any) => setTypesActivitie({ ...typesActivitie, name: e?.target?.value })}
              />
              <ErrorMessage name={'Nome'} />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="description">DESCRIÇÃO *</label>
              <textarea rows={3} className='form-control'
                {...register('description')}
                onChange={(e: any) => setTypesActivitie({ ...typesActivitie, description: e?.target?.value })}
              ></textarea>
              <ErrorMessage name={'Descrição'} />
            </FormGroup>
          </Col>

        </Row>
      </div>
    </ModalDefault >
  );
}

export default ModalVisitsTypesActivitiesInsert;