import { FC, useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'react-bootstrap';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import AppPagination from '../../../AppPagination/AppPagination';
import { usePaginate } from '../../../../providers/PaginateProvider';
import './CommercialScore.scss';
import { useMetricManagementProvider } from '../../../../providers/pgm/MetricManagement/MetricManagementProvider';

interface CommercialScoreProps { };

const CommercialScore: FC<CommercialScoreProps> = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { handlePaginate }        = usePaginate();
    const { metric }                = useMetricManagementProvider();

    const tableList = () => {
        return (
            <>
                {
                    metric?.pontuacaoComercial && metric?.pontuacaoComercial?.length > 0 ? (
                        <>
                            {
                                metric.pontuacaoComercial.map((item:any, index:number) => (
                                    <tr className={`tr-${index}`}>
                                        <td> {item?.nomeComercial} </td>
                                        <td className="text-center"> {item.pisoProducao} </td>
                                        <td className="text-center"> {item.grandeNegociacao} </td>
                                        <td className="text-center"> {item.marketplaceIndicacao} </td>
                                        <td className="text-center"> {item.marketplace} </td>
                                        <td className="text-center"> {item.metaGlobal} </td>
                                        <td className="text-center"> 0,00 </td>
                                        <td className="text-center"> {item.produtos} </td>
                                        <td className="text-center"> {item.consorcio} </td>
                                        <td className="text-center"> {item.visitaMes40} </td>
                                        <td className="text-center"> {item.visitaPresencial} </td>
                                        <td className="text-center"> {item.carteira} </td>
                                        <td className="text-center"> {item.qualidade} </td>
                                        <td className="text-center"> {item.cadastroMes} </td>
                                        <td className="text-center"> {item.cadastroMaiorAtivo} </td>
                                        <td className="text-center"> {item.rentabilidade} </td>
                                        <td className="text-center"> {item.totalPontuacao} </td>
                                        <td className="text-center"> {item.rankingMes} </td>
                                        <td className="text-center"> {item.nomeRegional} </td>
                                        <td className="text-center"> {item.nomeExecutivo} </td>
                                        <td className="text-center"> {item.nomeDiretor} </td>
                                    </tr>
                                ))
                            }
                        </>
                    ) : (
                        <tr>
                          <td colSpan={24} className="text-center text-sm">Não há registros</td>
                        </tr>
                    )
                }
            </>
        );
    }

    useEffect(() => {
        setIsLoading(false);
    })

    return(
        <>
            { !isLoading ? (
                <div className="CommercialScore" data-testid="CommercialScore">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={12} xs={12}>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-flush m-0 w-100">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col" className="text-left sticky-column" >COMERCIAL</th>
                                                    <th scope="col" className="text-left sticky-cabecalho">PISO DE PRODUÇÃO</th>
                                                    <th scope="col" className="text-left sticky-cabecalho">CAMPANHA (nome da campanha)</th>
                                                    <th scope="col" className="text-left sticky-cabecalho">CAMPANHA FOCO   GRANDES NEGOCIAÇÕES</th>
                                                    <th scope="col" className="text-left sticky-cabecalho">INDICAÇÃO (MARKETPLACE)</th>
                                                    <th scope="col" className="text-left sticky-cabecalho">APROVEITAMENTO (MARKETPLACE)</th>
                                                    <th scope="col" className="text-left sticky-cabecalho">META GLOBAL</th>
                                                    <th scope="col" className="text-left sticky-cabecalho">PRODUTOS (CONVÊNIOS)</th>
                                                    <th scope="col" className="text-left sticky-cabecalho">PRODUTOS (CONSÓRCIO)</th>
                                                    <th scope="col" className="text-left sticky-cabecalho">VISITAS TOTAIS</th>
                                                    <th scope="col" className="text-left sticky-cabecalho">PRESENCIAL (VISITAS) </th>
                                                    <th scope="col" className="text-left sticky-cabecalho">60-80 +25K (CARTEIRA)</th>
                                                    <th scope="col" className="text-left sticky-cabecalho">MAIOR OU IGUAL A 100% (QUALIDADE)</th>
                                                    <th scope="col" className="text-left sticky-cabecalho">2 AO MÊS (CADASTRO)</th>
                                                    <th scope="col" className="text-left sticky-cabecalho">1 AO MÊS (ATIVO) NOS ULTIMOS 90 DIAS (CADASTRO) </th>
                                                    <th scope="col" className="text-left sticky-cabecalho">MAIOR OU IGUAL A 100% (SPRED EMPRESA)</th>
                                                    <th scope="col" className="text-left sticky-cabecalho">TOTAL GERAL</th>
                                                    <th scope="col" className="text-left sticky-cabecalho">RANKING BEVI</th>
                                                    <th scope="col" className="text-left sticky-cabecalho">REGIONAL</th>
                                                    <th scope="col" className="text-left sticky-cabecalho">EXECUTIVO</th>
                                                    <th scope="col" className="text-left sticky-cabecalho">DIRETOR</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableList()}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>

                            {/* {true && (
                                <div>
                                    <nav aria-label="...">
                                        <AppPagination
                                            pages={pages}
                                            handlePaginate={handlePaginate}
                                            params={params}
                                            setParams={setParams}
                                            style={{ backgroundColor: '#fff' }}
                                        />
                                    </nav>
                                </div>
                            )} */}
                        </CardBody>
                    </Card>
                </div>     
              ) : (<SkeletonTable />)
            }
        </>
    );
}

export default CommercialScore;