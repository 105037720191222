import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import './FormSimulation.scss';
import { useAuth } from "../../../../../providers/AuthProvider";
import { Card, Col, FormGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import ErrorMessage from "../../../../ErrorMessage/ErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Tagfy from '../../../../Tagfy/Tagfy';
import { toast } from 'react-toastify';
import RangeSlider from '../../../../RangeSlider/RangeSlider';
import TooltipItem from '../../../../TooltipItem/TooltipItem';
import { yesNoOptions } from '../../../../../models/OptionsValues';
import Tags from '@yaireo/tagify/dist/react.tagify';


interface FormSimulationProps {
   handleSubmit: () => void,
   isLoading: boolean,
   register: any,
   setValue: any,
   errors: any,
   control: any,
   options: any,
   removedProposalId: string,
   setRemovedProposalId: Dispatch<SetStateAction<string>>
   setRemovedProposalsId: Dispatch<SetStateAction<string[]>>
}



const FormSimulation: FC<FormSimulationProps> = ({ options, register, control, setValue, errors, isLoading, handleSubmit, removedProposalId, setRemovedProposalsId, setRemovedProposalId }) => {
   const [proposalsNumber, setProposalsNumber] = useState<number[]>([]);
   const [partnerCodes, setPartnerCodes] = useState<string[]>([]);
   const [proposalMinMax, setProposalMinMax] = useState<number[]>([0, 100000]);
   const [ageMinMax, setAgeMinMax] = useState<number[]>([18, 118]);

   const { onInvalid } = useAuth();

   useEffect(() => {
      setValue('typed_proposal_id', proposalsNumber);
   }, [proposalsNumber]);

   useEffect(() => {
      setValue('typed_proposal_agent_partner_code', partnerCodes);
   }, [partnerCodes]);

   useEffect(() => {
      setValue('typed_proposal_gross_value_min', proposalMinMax[0]);
      setValue('typed_proposal_gross_value_max', proposalMinMax[1]);
   }, [proposalMinMax]);

   useEffect(() => {
      setValue('client_age_min', ageMinMax[0]);
      setValue('client_age_max', ageMinMax[1]);
   }, [ageMinMax]);

   const onInvalidTag = (e: any) => {
      toast.warning(e.detail?.message);
   }

   const changeProposalMinMaxInput = (value: any, type: 'min' | 'max') => {
      setProposalMinMax((current: number[]) => type === 'min' ? [Number(value), current[1]] : [current[0], Number(value)]);
   }

   const onBlurProposalMinMax = () => {
      if (proposalMinMax[0] > proposalMinMax[1]) {
         setProposalMinMax((current: number[]) => [...current.reverse()]);
      }
   }

   return (

      <Row className="FormSimulation" data-testid="FormSimulation">
         <Card>
            <Card.Header className="pt-3 pb-0 px-2">
               Filtros
            </Card.Header>
            <Card.Body className="py-1">
               <Row>
                  <Col md={2}>
                     <FormGroup className="form-group">
                        <label htmlFor="financial_id">Financeira</label>
                        {control && (
                           <Controller
                              name="financial_id"
                              control={control}
                              render={({ field: { name, ref } }) => (
                                 <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    isMulti
                                    options={options?.financials ?? []}
                                    noOptionsMessage={() => 'Não há registros'}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.financial_id)}`}
                                    {...register('financial_id', { required: false, })}
                                    onChange={(val: any) => setValue('financial_id', val?.map((option: any) => option?.value))}
                                 />
                              )}
                           />
                        )}
                        <ErrorMessage name="Financeira" type={errors?.financial_id?.type} />
                     </FormGroup>
                  </Col>
                  <Col md={2}>
                     <FormGroup className="form-group">
                        <label htmlFor="productgroup_id">Produto</label>
                        {control && (
                           <Controller
                              name="productgroup_id"
                              control={control}
                              render={({ field: { name, ref } }) => (
                                 <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    isMulti
                                    options={options?.productGroups ?? []}
                                    noOptionsMessage={() => 'Não há registros'}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.productgroup_id)}`}
                                    {...register('productgroup_id', { required: false, })}
                                    onChange={(val: any) => setValue('productgroup_id', val?.map((option: any) => option?.value))}
                                 />
                              )}
                           />
                        )}
                        <ErrorMessage name="Produto" type={errors?.productgroup_id?.type} />
                     </FormGroup>
                  </Col>
                  <Col md={2}>
                     <FormGroup className="form-group">
                        <label htmlFor="formcontract_id">Forma de Contrato</label>
                        {control && (
                           <Controller
                              name="formcontract_id"
                              control={control}
                              render={({ field: { name, ref } }) => (
                                 <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    isMulti
                                    options={options?.formContracts ?? []}
                                    noOptionsMessage={() => 'Não há registros'}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.formcontract_id)}`}
                                    {...register('formcontract_id', { required: false, })}
                                    onChange={(val: any) => setValue('formcontract_id', val?.map((option: any) => option?.value))}
                                 />
                              )}
                           />
                        )}
                        <ErrorMessage name="Forma de Contrato" type={errors?.formcontract_id?.type} />
                     </FormGroup>
                  </Col>
                  <Col md={2}>
                     <FormGroup className="form-group">
                        <label htmlFor="commercial_manager_id">Comercial</label>
                        {control && (
                           <Controller
                              name="commercial_manager_id"
                              control={control}
                              render={({ field: { name, ref } }) => (
                                 <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    isMulti
                                    options={options?.commercialManagers ?? []}
                                    noOptionsMessage={() => 'Não há registros'}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.commercial_manager_id)}`}
                                    {...register('commercial_manager_id', { required: false, })}
                                    onChange={(val: any) => setValue('commercial_manager_id', val?.map((option: any) => option?.value))}
                                 />
                              )}
                           />
                        )}
                        <ErrorMessage name="Comercial" type={errors?.commercial_manager_id?.type} />
                     </FormGroup>
                  </Col>
                  <Col md={2}>
                     <FormGroup className="form-group">
                        <label htmlFor="regional_manager_id">Regional</label>
                        {control && (
                           <Controller
                              name="regional_manager_id"
                              control={control}
                              render={({ field: { name, ref } }) => (
                                 <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    isMulti
                                    options={options?.regionalManagers ?? []}
                                    noOptionsMessage={() => 'Não há registros'}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.regional_manager_id)}`}
                                    {...register('regional_manager_id', { required: false, })}
                                    onChange={(val: any) => setValue('regional_manager_id', val?.map((option: any) => option?.value))}
                                 />
                              )}
                           />
                        )}
                        <ErrorMessage name="Regional" type={errors?.regional_manager_id?.type} />
                     </FormGroup>
                  </Col>
                  <Col md={2}>
                     <FormGroup className="form-group">
                        <label htmlFor="executive_manager_id">Executivo</label>
                        {control && (
                           <Controller
                              name="executive_manager_id"
                              control={control}
                              render={({ field: { name, ref } }) => (
                                 <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    isMulti
                                    options={options?.executiveManagers ?? []}
                                    noOptionsMessage={() => 'Não há registros'}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.executive_manager_id)}`}
                                    {...register('executive_manager_id', { required: false, })}
                                    onChange={(val: any) => setValue('executive_manager_id', val?.map((option: any) => option?.value))}
                                 />
                              )}
                           />
                        )}
                        <ErrorMessage name="Executivo" type={errors?.executive_manager_id?.type} />
                     </FormGroup>
                  </Col>
                  <Col md={2}>
                     <FormGroup className="form-group">
                        <label htmlFor="director_manager_id">Diretor</label>
                        {control && (
                           <Controller
                              name="director_manager_id"
                              control={control}
                              render={({ field: { name, ref } }) => (
                                 <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    isMulti
                                    options={options?.directorManagers ?? []}
                                    noOptionsMessage={() => 'Não há registros'}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.director_manager_id)}`}
                                    {...register('director_manager_id', { required: false, })}
                                    onChange={(val: any) => setValue('director_manager_id', val?.map((option: any) => option?.value))}
                                 />
                              )}
                           />
                        )}
                        <ErrorMessage name="Diretor" type={errors?.director_manager_id?.type} />
                     </FormGroup>
                  </Col>
                  <Col md={3}>
                     <FormGroup className="form-group">
                        <label htmlFor="typed_proposal_id">Número Proposta</label>
                        <Tagfy
                           handleAdd={(e: any) => {
                              setProposalsNumber((current: any) => current ? [...current, e] : [e]);

                           }}
                           handleRemove={(e: any) => setProposalsNumber((current: any) => current.filter((x: string) => x !== e))}
                           validate={false}
                           regexValidate={/^\d+$/}
                           regexTextWarning='Informe apenas números'
                           onInvalidTag={onInvalidTag}
                        />
                     </FormGroup>
                  </Col>
                  <Col md={3}>
                     <FormGroup className="form-group">
                        <label htmlFor="typed_proposal_agent_partner_code">Código Parceiro</label>
                        <Tagfy
                           handleAdd={(e: any) => {
                              setPartnerCodes((current: any) => current ? [...current, e] : [e]);

                           }}
                           handleRemove={(e: any) => setPartnerCodes((current: any) => current.filter((x: string) => x !== e))}
                           validate={false}
                        />
                     </FormGroup>
                  </Col>

                  <Col md={2}>
                     <FormGroup>
                        <TooltipItem position='top' text='Apenas propostas digitadas com os dias superiores aos informados serão listadas'>
                           <label htmlFor='typed_proposal_days'>Dias Mínimos da Proposta</label>
                        </TooltipItem>
                        <input
                           className='form-control'
                           type='number'
                           {...register('typed_proposal_days')}
                        />
                     </FormGroup>
                  </Col>
                  <Col md={2}>
                     <FormGroup className="form-group">
                        <TooltipItem position='top' text='Além de considerar o intervalo de idade informado, considerar também clientes que não possuem data de nascimento cadastrada'>
                           <label htmlFor="client_without_age">Considerar Clientes sem Idade</label>
                        </TooltipItem>
                        {control && (
                           <Controller
                              name="client_without_age"
                              control={control}
                              render={({ field: { name, ref } }) => (
                                 <ReactSelect
                                    ref={ref}
                                    name={name}
                                    options={yesNoOptions}
                                    noOptionsMessage={() => 'Não há registros'}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.client_without_age)}`}
                                    {...register('client_without_age', { required: false, })}
                                    onChange={(val: any) => setValue('client_without_age', !!val && val.value === 1)}
                                 />
                              )}
                           />
                        )}
                        <ErrorMessage name="Clientes sem idade" type={errors?.client_without_age?.type} />
                     </FormGroup>
                  </Col>


                  <Col md={6}>
                     <label>Valor da Proposta</label>
                     <div className='d-flex justify-content-between align-items-center gap-1'>
                        <div>
                           <input
                              type='number'
                              value={proposalMinMax[0]}
                              className='form-control'
                              onChange={(e) => changeProposalMinMaxInput(e?.target?.value, 'min')}
                              onBlur={onBlurProposalMinMax}
                           />
                        </div>
                        <div className='w-100'>
                           <RangeSlider
                              min={0}
                              max={100000}
                              value={proposalMinMax}
                              setValue={setProposalMinMax}
                              defaultValue={proposalMinMax}
                           />
                        </div>
                        <div>
                           <input
                              type='number'
                              value={proposalMinMax[1]}
                              className='form-control'
                              onChange={(e) => changeProposalMinMaxInput(e?.target?.value, 'max')}
                              onBlur={onBlurProposalMinMax}
                           />
                        </div>
                     </div>
                  </Col>

                  <Col md={6}>
                     <FormGroup>
                        <label>Idade do Cliente</label>
                        <RangeSlider
                           min={18}
                           max={118}
                           value={ageMinMax}
                           setValue={setAgeMinMax}
                           defaultValue={ageMinMax}
                           showValues
                        />
                     </FormGroup>
                  </Col>

                  <Col md={6} className='mt-3'>
                     <FormGroup className="form-group">
                        <label htmlFor="exception_proposal">Propostas Excluídas da Campanha</label>
                        <Tagfy
                           handleAdd={(e: any) => {
                              setRemovedProposalsId((current: any) => current ? [...current, e] : [e]);

                           }}
                           handleRemove={(e: any) => setRemovedProposalsId((current: any) => current.filter((x: string) => x !== e))}
                           tagToAddRemove={removedProposalId}
                           setTagToAddRemove={setRemovedProposalId}
                           validate={false}
                        />
                     </FormGroup>
                  </Col>
               </Row>

               <Row className="justify-content-end mt-3">
                  <Col className="text-end">
                     <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={isLoading}>
                        {isLoading && (
                           <FontAwesomeIcon className="me-2" icon={faSpinner} spin />
                        )}

                        {isLoading ? 'Simulando...' : 'Simular'}
                     </button>
                  </Col>
               </Row>
            </Card.Body>
         </Card>
      </Row>
   );
}
export default FormSimulation;
