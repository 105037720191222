function returnBadgeStatusSignature (statusId:number) {
    let badge = 'badge-danger'
   
    if (statusId == 3) {
      badge = 'badge-primary';
    }
    else if (statusId == 2) {
      badge = 'badge-success';
    }
  
    return badge;
}

export default returnBadgeStatusSignature;