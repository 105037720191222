import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react";
import { MasterCovenantGroupModel } from "../../../models/Registrations/Covenant/MasterCovenantGroupModel";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { MasterCovenantGroupService } from "../../../services/Registrations/Covenant/MasterCovenantGroupService";

interface ContextProps {
    masterGroups: MasterCovenantGroupModel[],
    masterGroupOptions: any[],
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => Promise<void>,
    handleSave: (payload: MasterCovenantGroupModel) => Promise<boolean>
}

export const MasterCovenantGroupContext = createContext<ContextProps>({} as ContextProps);

interface MasterCovenantGroupProviderProps {
    children: ReactNode;
}

export const MasterCovenantGroupProvider = ({ children }: MasterCovenantGroupProviderProps) => {
    const [masterGroups, setMasterGroups] = useState<MasterCovenantGroupModel[]>([]);
    const [masterGroupOptions, setMasterGroupOptions] = useState<any[]>([]);
    const [params, setParams] = useState<any>({ withPaginate: false, active: 'S' });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const service = new MasterCovenantGroupService();

    const handleList = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                setMasterGroups([]);
                setMasterGroupOptions([]);
                setPages({});
                setError(_Error);
                return;
            }

            setMasterGroups(_Response?.data);
            setPages(_Response?.pages);
            setMasterGroupOptions(_Response?.data?.map((x: MasterCovenantGroupModel) => (
                {
                    value: x.id,
                    label: x.name,
                    externalId: x.externalId
                }
            )));
            setError('');
        } catch (e: any) {
            setIsLoading(false);
            setMasterGroups([]);
            setMasterGroupOptions([]);
            setPages({});
            setError(e);
        }
    }

    const handleSave = async (payload: MasterCovenantGroupModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.save(payload);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e);
            return false;
        }
    }

    return (
        <MasterCovenantGroupContext.Provider value={useMemo(() => ({
            masterGroups,
            masterGroupOptions,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleSave
        }), [
            masterGroups,
            masterGroupOptions,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleSave
        ])}>
            {children}
        </MasterCovenantGroupContext.Provider>
    );
}

export const useMasterCovenantGroup = () => useContext(MasterCovenantGroupContext);
