import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import {
  BiContractFormAnnualProductionModel,
} from "../../../models/BI/Dashboard/ContractForm/BiContractFormAnnualProductionModel";
import { BiContractFormChartsParticipationModel } from "../../../models/BI/Dashboard/ContractForm/BiContractFormChartsParticipationModel";
import { BiMonthlyProductionModel } from "../../../models/BI/Dashboard/BiMonthlyProductionModel";
import { BiAnnualProductionModel } from "../../../models/BI/Dashboard/BiAnnualProductionModel";

interface ContextProps {
  monthlyProductionByContractForm: BiMonthlyProductionModel | undefined;
  setMonthlyProductionByContractForm: Dispatch<
    SetStateAction<BiMonthlyProductionModel | undefined>
  >;
  annualProductionByContractForm: BiAnnualProductionModel | undefined;
  setAnnualProductionByContractForm: Dispatch<
    SetStateAction<BiAnnualProductionModel | undefined>
  >;
  chartsParticipationsByContractForm:
  | BiContractFormChartsParticipationModel
  | undefined;
  contractFormProductionByCovenant: BiMonthlyProductionModel | undefined;
  setContractFormProductionByCovenant: Dispatch<
    SetStateAction<BiMonthlyProductionModel | undefined>
  >;
  contractFormProductionByFinancial: BiMonthlyProductionModel | undefined;
  setContractFormProductionByFinancial: Dispatch<
    SetStateAction<BiMonthlyProductionModel | undefined>
  >;
  contractFormProductionByCard: BiMonthlyProductionModel | undefined;
  setContractFormProductionByCard: Dispatch<
    SetStateAction<BiMonthlyProductionModel | undefined>
  >;

  contractFormProductionByCommercial: BiMonthlyProductionModel | undefined;
  setContractFormProductionByCommercial: Dispatch<
    SetStateAction<BiMonthlyProductionModel | undefined>
  >;
  contractFormProductionBySupervisor: BiMonthlyProductionModel | undefined;
  setContractFormProductionBySupervisor: Dispatch<
    SetStateAction<BiMonthlyProductionModel | undefined>
  >;
  params: any;
  setParams: Dispatch<SetStateAction<any>>;
  isLoading: boolean;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
}

export const BiContractFormContext = createContext<ContextProps>(
  {} as ContextProps
);

interface BiContractFormProviderProps {
  children: ReactNode;
}

export const BiContractFormProvider = ({
  children,
}: BiContractFormProviderProps) => {
  const [monthlyProductionByContractForm, setMonthlyProductionByContractForm] =
    useState<BiMonthlyProductionModel>();
  const [annualProductionByContractForm, setAnnualProductionByContractForm] =
    useState<BiContractFormAnnualProductionModel>();
  const [
    chartsParticipationsByContractForm,
    setChartsParticipationsByContractForm,
  ] = useState<BiContractFormChartsParticipationModel>();
  const [
    contractFormProductionByCovenant,
    setContractFormProductionByCovenant,
  ] = useState<BiMonthlyProductionModel>();
  const [
    contractFormProductionByFinancial,
    setContractFormProductionByFinancial,
  ] = useState<BiMonthlyProductionModel>();
  const [contractFormProductionByCard, setContractFormProductionByCard] =
    useState<BiMonthlyProductionModel>();
  const [
    contractFormProductionByCommercial,
    setContractFormProductionByCommercial,
  ] = useState<BiMonthlyProductionModel>();
  const [
    contractFormProductionBySupervisor,
    setContractFormProductionBySupervisor,
  ] = useState<BiMonthlyProductionModel>();
  const [params, setParams] = useState<any>({ withPaginate: false });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError("");
    }
  }, [error]);

  return (
    <BiContractFormContext.Provider
      value={useMemo(
        () => ({
          monthlyProductionByContractForm,
          setMonthlyProductionByContractForm,
          annualProductionByContractForm,
          setAnnualProductionByContractForm,
          chartsParticipationsByContractForm,
          contractFormProductionByCovenant,
          setContractFormProductionByCovenant,
          contractFormProductionByFinancial,
          setContractFormProductionByFinancial,
          contractFormProductionByCard,
          setContractFormProductionByCard,
          contractFormProductionByCommercial,
          setContractFormProductionByCommercial,
          contractFormProductionBySupervisor,
          setContractFormProductionBySupervisor,
          params,
          setParams,
          isLoading,
          error,
          setError,
        }),
        [
          monthlyProductionByContractForm,
          setMonthlyProductionByContractForm,
          annualProductionByContractForm,
          setAnnualProductionByContractForm,
          chartsParticipationsByContractForm,
          contractFormProductionByCovenant,
          setContractFormProductionByCovenant,
          contractFormProductionByFinancial,
          setContractFormProductionByFinancial,
          contractFormProductionByCard,
          setContractFormProductionByCard,
          contractFormProductionByCommercial,
          setContractFormProductionByCommercial,
          contractFormProductionBySupervisor,
          setContractFormProductionBySupervisor,
          params,
          setParams,
          isLoading,
          error,
          setError,
        ]
      )}
    >
      {children}
    </BiContractFormContext.Provider>
  );
};

export const useBiContractForm = () => useContext(BiContractFormContext);
