import React, { FC } from 'react';
import './IncidentContent.scss';
import { Card, Col, FormGroup } from 'react-bootstrap';
import FormatPhone from '../../../../utils/FormatPhone';
import { dateFormat } from '../../../../utils/FormatDate';

interface IncidentContentProps {
  cyberIncident: any;
}

const IncidentContent: FC<IncidentContentProps> = ({ cyberIncident }) => {

  const incidentTypeLabels: any = {
    malware: 'Malware',
    phishing: 'Phishing',
    ddos: 'DDoS',
    dataBreach: 'Violação de Dados',
    unauthorizedAccess: 'Acesso Não Autorizado',
    ransomware: 'Ransomware',
    vulnerabilityExploitation: 'Exploração de Vulnerabilidade',
    other: 'Outro'
  };

  const incidentType: any = cyberIncident?.incidentStage1?.detailedIncidentDescription?.incidentType;

  return (
    <div className="IncidentContent" data-testid="IncidentContent">
      <>
        <Col md={12}>
          <Card>
            <Card.Body>
              <Col>
                <FormGroup className="form-group">
                  <label>NOME DO RESPONSÁVEL PELO RELATO</label>
                  <input
                    type="text"
                    className="form-control"
                    value={cyberIncident?.incidentStage1?.reportResponsibleName}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group">
                  <label>DEPARTAMENTO</label>
                  <input
                    type="text"
                    className="form-control"
                    value={cyberIncident?.incidentStage1?.department}
                    disabled
                  />
                </FormGroup>
              </Col>
              <label>CONTATO</label>
              <Col>
                <FormGroup className="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control"
                    value={cyberIncident?.incidentStage1?.contact?.email}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group">
                  <label>Telefone</label>
                  <input
                    type="text"
                    className="form-control"
                    value={FormatPhone(cyberIncident?.incidentStage1?.contact?.phone)}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group">
                  <label>DATA E HORA DA DETECÇÃO</label>
                  <input
                    type="text"
                    className="form-control"
                    value={dateFormat({ date: cyberIncident?.incidentStage1?.detectionDateTime, datetime: true })}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group">
                  <label>MÉTODO DE DETECÇÃO</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.incidentStage1?.detectionMethod}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group">
                  <label>FERRAMENTA AUTOMÁTICA</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.incidentStage1?.automatedTool}
                    disabled
                  />
                </FormGroup>
              </Col>
              <label>RELATO DE FUNCIONÁRIO</label>
              <Col>
                <FormGroup className="form-group">
                  <label>Nome</label>
                  <input
                    type="text"
                    className="form-control"
                    value={cyberIncident?.incidentStage1?.employeeReport?.name}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group">
                  <label>Contato</label>
                  <input
                    type="text"
                    className="form-control"
                    value={FormatPhone(cyberIncident?.incidentStage1?.employeeReport?.contact)}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group">
                  <label>OUTRO</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.incidentStage1?.other}
                    disabled
                  />
                </FormGroup>
              </Col>
              <label>DESCRIÇÃO DETALHADA DO INCIDENTE</label>
              <Col>
                <FormGroup className="form-group">
                  <label>Tipo de Incidente</label>
                  <div className="checkbox-group ms-3">
                    {incidentType && Object.keys(incidentType).map(key => (
                      key === 'other' ? (
                        <div key={key} className="form-group">
                          <label>{incidentTypeLabels[key]}</label>
                          <textarea
                            className="form-control"
                            value={incidentType[key]}
                            disabled
                          />
                        </div>
                      ) : (
                        <div key={key} className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={incidentType[key]}
                          />
                          <label>{incidentTypeLabels[key]}</label>
                        </div>
                      )
                    ))}
                  </div>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group">
                  <label>Descrição detalhada</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.incidentStage1?.detailedIncidentDescription?.detailedDescription}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group">
                  <label>{'Data e Hora de Início (estimada)'}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={dateFormat({ date: cyberIncident?.incidentStage1?.detailedIncidentDescription?.estimatedStartDateTime, datetime: true })}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group">
                  <label>{'Data e Hora de Término  (estimada)'}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={dateFormat({ date: cyberIncident?.incidentStage1?.detailedIncidentDescription?.estimatedEndDateTime, datetime: true })}
                    disabled
                  />
                </FormGroup>
              </Col>
              <label>IMPACTO DO INCIDENTE</label>
              <Col>
                <FormGroup className="form-group">
                  <label>Sistemas Afetados</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.incidentStage1?.incidentImpact?.affectedSystems}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group">
                  <label>Lista de Sistemas/Servidores</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.incidentStage1?.incidentImpact?.systemsServersList}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group">
                  <label>Dados Comprometidos</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.incidentStage1?.incidentImpact?.compromisedData}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group">
                  <label>Tipo e Volume de Dados</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.incidentStage1?.incidentImpact?.dataTypeVolume}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group">
                  <label>Número de Usuários Afetados</label>
                  <input
                    type="text"
                    className="form-control"
                    value={cyberIncident?.incidentStage1?.incidentImpact?.affectedUsersCount}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group">
                  <label>Impacto na Operação</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.incidentStage1?.incidentImpact?.operationImpact}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group">
                  <label>Descrição do Impacto</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.incidentStage1?.incidentImpact?.impactDescription}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group">
                  <label>Duração do Impacto</label>
                  <input
                    type="text"
                    className="form-control"
                    value={cyberIncident?.incidentStage1?.incidentImpact?.impactDuration}
                    disabled
                  />
                </FormGroup>
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </>
    </div>
  )
};

export default IncidentContent;
