import { FC, useEffect, useState } from 'react';
import './CovenantGroup.scss';
import MasterPage from '../../../../components/MasterPage/MasterPage';
import PageBase from '../../../../components/PageBase/PageBase';
import SkeletonTable from '../../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../../components/AppPagination/AppPagination';
import { useSidebarFilter } from '../../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../../providers/PaginateProvider';
import { toast } from 'react-toastify';
import DropdownMenuTable from '../../../../components/DropdownMenuTable/DropdownMenuTable';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faLock, faLockOpen, faRemove } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useCovenantGroup } from '../../../../providers/Registrations/Covenant/CovenantGroupProvider';
import { CovenantGroupModel } from '../../../../models/Registrations/Covenant/CovenantGroupModel';
import SidebarCovenantGroupFilter from '../../../../components/Registrations/Covenant/SidebarCovenantGroupFilter/SidebarCovenantGroupFilter';
import ModalCovenantGroupInsert from '../../../../components/Registrations/Covenant/ModalCovenantGroupInsert/ModalCovenantGroupInsert';
import { useMasterCovenantGroup } from '../../../../providers/Registrations/Covenant/MasterCovenantGroupProvider';

interface CovenantGroupProps { }

const CovenantGroup: FC<CovenantGroupProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
  const [itemEdit, setItemEdit] = useState<CovenantGroupModel>();

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { handleList, handleSave, pages, params, setParams, covenantGroups, error, setError, isLoading } = useCovenantGroup();
  const { handleList: handleListMasterCovenantGroup, masterGroupOptions, params: paramsMasterCovenantGroup } = useMasterCovenantGroup();
  const { handlePaginate } = usePaginate();

  useEffect(() => {
    setParams({ withPaginate: true });
  }, []);

  useEffect(() => {
    handleList().then();
  }, [params]);

  useEffect(() => {
    handleListMasterCovenantGroup().then();
  }, [paramsMasterCovenantGroup]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError('');
    }
  }, [error]);

  const handleEdit = (item: CovenantGroupModel) => {
    setItemEdit(item);
    setShowModalInsert(true);
  }

  const handleDeleteCovenantGroup = (item: CovenantGroupModel) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja ${item?.active === 'S' ? 'desativar' : 'ativar'} o grupo ${item?.name}?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleSave({
          id: item?.id,
          active: item?.active === 'S' ? 'N' : 'S'
        }).then();
        if (ret) {
          toast.success(`Grupo ${item?.active === 'S' ? 'inativado' : 'ativado'} com sucesso!`);
          handleList().then();
        } else {
          await Swal.fire('Ops!', `Não foi possível realizar a ação.<br>${error}`, 'error');
        }
      }
    });
  }

  const handleBlock = async (item: CovenantGroupModel) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja ${item.blocked === 'S' ? 'DESBLOQUEAR' : 'BLOQUEAR'} o grupo ${item?.name}?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleSave({
          id: item.id,
          blocked: item.blocked === 'S' ? 'N' : 'S'
        }).then();
        if (ret) {
          toast.success(`Grupo ${item.blocked === 'S' ? 'desbloqueado' : 'bloqueado'} com sucesso!`);
          handleList().then();
        } else {
          await Swal.fire('Ops!', `Não foi realizar a ação.<br>${error}`, 'error');
        }
      }
    });
  }

  const renderList = (): JSX.Element => {
    return (
      <>
        {covenantGroups?.length > 0 ? (
          <>
            {covenantGroups?.map((x: CovenantGroupModel) => (
              <tr key={x.id} className="text-sm">
                <td className="text-center">{x.id}</td>
                <td>{x?.name}</td>
                <td className='text-center'>
                  <span className={`badge rounded-pill ${x?.blocked === 'S' ? 'bg-success' : 'bg-danger'}`}>
                    {x?.blocked === 'S' ? 'Sim' : 'Não'}
                  </span>
                </td>
                <td className='text-center'>
                  <span className={`badge rounded-pill ${x?.closure === 'S' ? 'bg-success' : 'bg-danger'}`}>
                    {x?.closure === 'S' ? 'Sim' : 'Não'}
                  </span>
                </td>
                <td className='text-center'>
                  <span className={`badge ${x?.active === 'S' ? 'bg-primary' : 'bg-secondary'}`}>
                    {x?.active === 'S' ? 'Ativo' : 'Inativo'}
                  </span>
                </td>
                <td>
                  {masterGroupOptions?.find((master: any) => master.value === x.idMasterGroup)?.label}
                </td>
                <td className="text-center">
                  <DropdownMenuTable key={x.id ?? 0}>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleBlock(x)}>
                      <FontAwesomeIcon icon={x.blocked === 'S' ? faLockOpen : faLock} className="me-2" /> {x.blocked === 'S' ? 'Desbloquear' : 'Bloquear'}
                    </Dropdown.Item>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleEdit(x)}>
                      <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
                    </Dropdown.Item>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleDeleteCovenantGroup(x)}>
                      <FontAwesomeIcon icon={x?.active === 'S' ? faRemove : faCheck} className="me-2" /> {x?.active === 'S' ? 'Desativar' : 'Ativar'}
                    </Dropdown.Item>
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={6} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <>
            <div className="table-responsive">
              <table className="table table-flush m-0 w-100">
                <thead className="thead-light">
                  <tr>
                    <th className="text-uppercase opacity-7 text-center" scope="col">#</th>
                    <th className="text-uppercase opacity-7" scope="col">Nome</th>
                    <th className="text-uppercase opacity-7 text-center" scope="col">Bloqueado</th>
                    <th className="text-uppercase opacity-7 text-center" scope="col">Fechamento</th>
                    <th className="text-uppercase opacity-7 text-center" scope="col">Situação</th>
                    <th className="text-uppercase opacity-7" scope="col">Grupo Master</th>
                    <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {renderList()}
                </tbody>
              </table>
            </div>
            {covenantGroups?.length > 0 && (
              <div>
                <nav aria-label="...">
                  <AppPagination
                    pages={pages}
                    handlePaginate={handlePaginate}
                    params={params}
                    setParams={setParams}
                    style={{ backgroundColor: '#fff' }}
                  />
                </nav>
              </div>
            )}
          </>
        ) : (
          <SkeletonTable />
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="CovenantGroup"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="CovenantGroup" data-testid="CovenantGroup">
        <PageBase
          title="Grupos de Convênio"
          subtitle="Tela para manutenção dos grupos de convênio."
          handleInsert={() => setShowModalInsert(true)}
          handleFilter={() => showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          error={error}
          content={renderContent()}
          hasFilter
        />

        <ModalCovenantGroupInsert
          show={showModalInsert}
          onClose={() => setShowModalInsert(false)}
          itemEdit={itemEdit}
          setItemEdit={setItemEdit}
        />
      </div>

      <SidebarCovenantGroupFilter />
    </MasterPage>
  );
}

export default CovenantGroup;
