import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './ProductionCommercialBySupervisorDetail.scss';
import { useBiCommercial } from '../../../../../../providers/BI/Dashboard/BiCommercialProvider';
import { Col, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faCircleInfo, faSpinner } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import FormatMoney from '../../../../../../utils/FormatMoney';
import AppDataTable from '../../../../../AppDataTable/AppDataTable';

interface ProductionCommercialBySupervisorDetailProps {
  show: boolean,
  onClose: any,
  payload?: {
    supervisorId: number,
    commercialId: number,
    startDate: string,
    endDate: string
  }
  setPayload?: Dispatch<SetStateAction<any>>
}

const ProductionCommercialBySupervisorDetail: FC<ProductionCommercialBySupervisorDetailProps> = ({ show, onClose, payload, setPayload }) => {
  const [financialColumns, setFinancialColumns] = useState<any[]>();
  const [covenantColumns, setCovenantColumns] = useState<any[]>();
  const [contractFormColumns, setContractFormColumns] = useState<any[]>();
  const [financialRows, setFinancialRows] = useState<any[]>();
  const [covenantRows, setCovenantRows] = useState<any[]>();
  const [contractFormRows, setContractFormRows] = useState<any[]>();

  const {
    handleListProductionCommercialBySupervisorDetail,
    productionCommercialBySupervisorDetail,
    setProductionCommercialBySupervisorDetail,
    isLoadingDetail } = useBiCommercial();

  useEffect(() => {
    if (payload) {
      handleListProductionCommercialBySupervisorDetail(payload);
    }
  }, [payload]);

  useEffect(() => {
    mountColumns();
    mountRows();
  }, [productionCommercialBySupervisorDetail]);

  const handleClose = () => {
    setPayload && setPayload(undefined);
    setProductionCommercialBySupervisorDetail(undefined);
    onClose();
  }

  const mountColumns = () => {
    if (productionCommercialBySupervisorDetail &&
      productionCommercialBySupervisorDetail?.financial &&
      productionCommercialBySupervisorDetail?.financial?.length > 0 &&
      productionCommercialBySupervisorDetail?.financial[0]?.periods &&
      productionCommercialBySupervisorDetail?.financial[0]?.periods?.length > 0) {

      const _columns: any = [];
      productionCommercialBySupervisorDetail?.financial[0]?.periods?.forEach((item) => {
        _columns.push({
          name: <div className='bg-info text-table text-uppercase'>{moment(item.month, 'YYYY-MM').format('MMMM')}</div>,
          selector: (data: any) => data[item.month ?? ''],
          cell: (data: any) => (
            <div className='text-nowrap'>
              {FormatMoney(data[item.month ?? ''])}
            </div>
          ),
          sortable: true,
        });

        _columns.push({
          name: <div className='bg-info text-table text-uppercase'>C/Q</div>,
          selector: (data: any) => data[`${item.month}cq`],
          cell: (data: any) => (
            <div className={`${data[`${item.month}cq`] < 0 ? 'bg-negative' : 'bg-positive'}`}>
              {FormatMoney(data[`${item.month}cq`], false)}%
              <FontAwesomeIcon icon={data[`${item.month}cq`] < 0 ? faArrowDown : faArrowUp} className="ms-2" />
            </div>
          ),
          sortable: true,
          center: true,
        });

        _columns.push({
          name: <div className='bg-info text-table text-uppercase'>Meta Atingida</div>,
          selector: (data: any) => data[`${item.month}hitTarget`],
          cell: (data: any) => (
            <div>
              {FormatMoney(data[`${item.month}hitTarget`], false)}%
            </div>
          ),
          sortable: true,
          compact: true,
        });

        _columns.push({
          name: <div className='bg-info text-table text-uppercase'>Participação</div>,
          selector: (data: any) => data[`${item.month}participation`],
          cell: (data: any) => (
            <div>
              {FormatMoney(data[`${item.month}participation`], false)}%
            </div>
          ),
          sortable: true,
          compact: true,
        });
      });

      _columns.push({
        name: <div className='bg-info text-table text-uppercase'>Projeção</div>,
        selector: (data: any) => data?.projection,
        cell: (data: any) => (
          <div className='text-nowrap'>
            {FormatMoney(data?.projection)}
          </div>
        ),
        sortable: true,
      });

      _columns.push({
        name: <div className='bg-info text-table text-uppercase'>Projeção C/Q</div>,
        selector: (data: any) => data.growthProjection,
        cell: (data: any) => (
          <div className={`${data.growthProjection < 0 ? 'bg-negative' : 'bg-positive'}`}>
            {FormatMoney(data.growthProjection, false)}%
            <FontAwesomeIcon icon={data.growthProjection < 0 ? faArrowDown : faArrowUp} className="ms-2" />
          </div>
        ),
        sortable: true,
        compact: true,
        center: true,
      });

      setFinancialColumns([
        {
          name: <div className='bg-info text-table text-uppercase'>Financeira</div>,
          selector: (data: any) => data?.name,
          sortable: true,
          width: '250px',
        },
        ..._columns,
        {
          name: <div className='bg-info text-table text-uppercase'>Meta Projeção</div>,
          selector: (data: any) => data.targetProjection,
          cell: (data: any) => (
            <div>
              {FormatMoney(data.targetProjection, false)}%
            </div>
          ),
          sortable: true,
          compact: true,
        }
      ]);

      setCovenantColumns([
        {
          name: <div className='bg-info text-table text-uppercase'>Convênio</div>,
          selector: (data: any) => data?.name,
          sortable: true,
          width: '300px',
        },
        ..._columns,
        {
          name: <div className='bg-info text-table text-uppercase'>Meta Projeção</div>,
          selector: (data: any) => data.targetProjection,
          cell: (data: any) => (
            <div>
              {FormatMoney(data.targetProjection, false)}%
            </div>
          ),
          sortable: true,
          compact: true,
          center: true,
        }
      ]);

      setContractFormColumns([
        {
          name: <div className='bg-info text-table text-uppercase'>Forma de Contrato</div>,
          selector: (data: any) => data?.name,
          sortable: true,
          width: '300px',
        },
        ..._columns
      ]);

    }
  }

  const mountItems = (item: any) => {
    const _rows: any = [];
    item?.forEach((x: any) => {
      const _row: any = { ...x };
      x?.periods?.forEach((period: any) => {
        _row[period.month ?? ''] = period.amount;
        _row[`${period.month}cq`] = period.growth;
        _row[`${period.month}hitTarget`] = period.hitTarget;
        _row[`${period.month}participation`] = period.participation;
      });
      _rows.push(_row);
    });
    return _rows;
  }

  const mountRows = () => {
    if (productionCommercialBySupervisorDetail) {
      setFinancialRows(mountItems(productionCommercialBySupervisorDetail?.financial));
      setCovenantRows(mountItems(productionCommercialBySupervisorDetail.covenant));
      setContractFormRows(mountItems(productionCommercialBySupervisorDetail.contractForm));
    }
  }

  const renderTable = () => {

    return (
      <Row md={12}>
        <Col >
          <Tabs
            defaultActiveKey="financial"
            className="mb-3"
            variant='pills'
            fill
          >
            <Tab eventKey="financial" title="FINANCEIRA">
              <AppDataTable
                columns={financialColumns}
                rows={financialRows}
                pagination={false}
              />
            </Tab>
            <Tab eventKey="covenant" title="CONVÊNIO">
              <AppDataTable
                columns={covenantColumns}
                rows={covenantRows}
                pagination={false}
              />
            </Tab>
            <Tab eventKey="form_contract" title="FORMA CONTRATO">
              <AppDataTable
                columns={contractFormColumns}
                rows={contractFormRows}
                pagination={false}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    );
  }

  return (
    <div className="ProductionCommercialBySupervisorDetail" data-testid="ProductionCommercialBySupervisorDetail">
      <Offcanvas
        show={show}
        placement='bottom'
        onHide={handleClose}
        className='ProductionCommercialBySupervisorDetail'
      >
        <OffcanvasHeader
          closeButton
          className='ProductionCommercialBySupervisorDetail text-2xl text-primary'
        >
          <FontAwesomeIcon icon={faCircleInfo} />
          <div className='ms-2'>
            {isLoadingDetail ? 'Carregando...'
              :
              <>
                {productionCommercialBySupervisorDetail?.supervisorName} - {productionCommercialBySupervisorDetail?.commercialName}
              </>}
          </div>
        </OffcanvasHeader>
        <OffcanvasBody className='ProductionCommercialBySupervisorDetail'>
          <hr className='mt-0' />
          {productionCommercialBySupervisorDetail ?
            <>
              {renderTable()}
            </>
            :
            <div className='d-flex justify-content-center align-items-center h-100 text-bold'>
              {isLoadingDetail ? <FontAwesomeIcon icon={faSpinner} className='text-2xl' spin /> : 'Não existem informações para listar!'}
            </div>
          }
        </OffcanvasBody>
      </Offcanvas>
    </div>
  )
};

export default ProductionCommercialBySupervisorDetail;
