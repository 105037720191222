import { FC } from 'react';
import './ModalPartnerInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { usePartner } from '../../../../providers/Registrations/Partner/PartnerProvider';
import { Container } from 'react-bootstrap';
import MultiStepsForm from '../../../MultiStepsForm/MultiStepsForm';
import { faSignInAlt, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import StepRegistrationType from './StepRegistrationType/StepRegistrationType';
import StepGeneralInformation from './StepGeneralInformation/StepGeneralInformation';

interface ModalPartnerInsertProps {
  show: boolean;
  onClose: () => void;
}

const ModalPartnerInsert: FC<ModalPartnerInsertProps> = ({ show, onClose }) => {
  const { partner, setPartner, setError } = usePartner();

  const handleClose = () => {
    setError('');
    setPartner({});
    onClose();
  }

  const steps = [
    {
      title: 'Tipo de Cadastro',
      descricao: 'Tipo de Cadastro',
      icon: faUserGroup,
      children: <StepRegistrationType />,
      bgCard: '#fff',
      order: 1,
    },
    {
      title: 'Informações Gerais',
      descricao: '',
      icon: faSignInAlt,
      children: <StepGeneralInformation />,
      bgCard: '#fff',
      order: 2,
    },
  ];

  return (
    <ModalDefault
      title={partner?.id ? 'Editar Parceiro' : 'Adicionar Parceiro'}
      show={show}
      onClose={handleClose}
      sizeModal={'xxl'}
      showFooter={false}
      // buttonText={isLoading ? 'Salvando...' : 'Salvar'}
      // showButtonSpinner={isLoading}
      // disabledSubmit={isLoading}
      // handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalPartnerInsert" data-testid="ModalPartnerInsert">
        <Container fluid className="p-0">
          <div className="row">
            <div className="col-12">
              <MultiStepsForm steps={steps} navClick={false} />
            </div>
          </div>
        </Container>
      </div>
    </ModalDefault>
  );
}

export default ModalPartnerInsert;
