import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { ProposalRuleOperationService } from "../../../services/Proposals/Rule/ProposalRuleOperationService";
import { ProposalRuleOperationModel } from "../../../models/Proposals/Rule/Operation/ProposalRuleOperationModel";
import { ProposalRuleOperationListParams } from "../../../models/Proposals/Rule/Operation/ProposalRuleOperationListParams";

interface ContextProps {
    operations: ProposalRuleOperationModel[],
    operationsOptions: any[],
    params: ProposalRuleOperationListParams,
    setParams: Dispatch<SetStateAction<ProposalRuleOperationListParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
}

export const ProposalRuleOperationContext = createContext<ContextProps>({} as ContextProps);

interface ProposalRuleOperationProviderProps {
    children: ReactNode;
}

export const ProposalRuleOperationProvider = ({ children }: ProposalRuleOperationProviderProps) => {
    const [operations, setOperations] = useState<ProposalRuleOperationModel[]>([]);
    const [operationsOptions, setOperationsOptions] = useState<any[]>([]);
    const [params, setParams] = useState<ProposalRuleOperationListParams>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new ProposalRuleOperationService();

    const handleList = useCallback(async () => {
        try {
            const [_Response, _Error] = await service.list({ withPaginate: false });

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setOperations(_Response.data);
            return setError('');
        } catch (e) {
            return setError('Não foi possível carregar as condições');
        }
    }, []);

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    useEffect(() => {
        setError('');
    }, []);

    useEffect(() => {
        setOperationsOptions(
            operations
                ?.map((item: ProposalRuleOperationModel) => {
                    return { label: item.name, value: item.id, operator: item.operator };
                })
        )
    }, [operations]);

    return (
        <ProposalRuleOperationContext.Provider value={{
            operations,
            operationsOptions,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
        }}>
            {children}
        </ProposalRuleOperationContext.Provider>
    );
}

export const useProposalRuleOperation = () => useContext(ProposalRuleOperationContext);