import { VisitsRegisterInactiveModel } from "../../../models/Visits/VisitsRegister/VisitsRegisterInactiveModel";
import { VisitsRegisterNewActiveModel } from "../../../models/Visits/VisitsRegister/VisitsRegisterNewActiveModel";
import { VisitsRegisterReactivatedModel } from "../../../models/Visits/VisitsRegister/VisitsRegisterReactivatedModel";
import { VisitsRegisterUnsubscribeModel } from "../../../models/Visits/VisitsRegister/VisitsRegisterUnsubscribeModel";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/domus/pgi/register';

export class VisitsRegisterService extends BaseService {

    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

}