import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react";
import { CovenantTableConfigService } from "../../../services/Tools/Configurations/CovenantTableConfigService";
import { CovenantTableConfigListModel } from "../../../models/Tools/Configurations/CovenantTable/CovenantTableConfigListModel";
import { CovenantTableConfigParamsModel } from "../../../models/Tools/Configurations/CovenantTable/CovenantTableConfigParamsModel";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";

interface ContextProps {
    covenantTableConfigs: CovenantTableConfigListModel[],
    params: CovenantTableConfigParamsModel,
    setParams: Dispatch<SetStateAction<CovenantTableConfigParamsModel>>,
    isLoading: boolean,
    error: string,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => Promise<void>,
    handleSave: (payload: any) => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>,
}

export const CovenantTableConfigContext = createContext<ContextProps>({} as ContextProps);

interface CovenantTableConfigProviderProps {
    children: ReactNode;
}

export const CovenantTableConfigProvider = ({ children }: CovenantTableConfigProviderProps) => {
    const [covenantTableConfigs, setCovenantTableConfigs] = useState<CovenantTableConfigListModel[]>([]);
    const [params, setParams] = useState<CovenantTableConfigParamsModel>({});
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const service = new CovenantTableConfigService();

    const handleList = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                setCovenantTableConfigs([]);
                setPages({});
                setError(_Error);
                return;
            }

            setCovenantTableConfigs(_Response?.data);
            setPages(_Response?.pages);
            setError('');
        } catch (e: any) {
            setIsLoading(false);
            setCovenantTableConfigs([]);
            setPages({});
            setError(e);
        }
    }

    const handleSave = async (payload: any) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.save(payload);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e);
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e);
            return false;
        }
    }

    return (
        <CovenantTableConfigContext.Provider value={useMemo(() => ({
            covenantTableConfigs,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleSave,
            handleDelete,
        }), [
            covenantTableConfigs,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleSave,
            handleDelete,
        ])}>
            {children}
        </CovenantTableConfigContext.Provider>
    );
}

export const useCovenantTableConfig = () => useContext(CovenantTableConfigContext);