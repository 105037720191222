import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { MonitoringCommercialPointsParamsModel } from "../../../models/PanelConnects/MonitoringCommercialPoints/MonitoringCommercialPointsParamsModel";
import { MonitoringCommercialPointsService } from "../../../services/PanelConnects/MonitoringCommercialPoints/MonitoringCommercialPointsService";
import { MonitoringPointsModel, MonitoringScorePointsModel } from "../../../models/PanelConnects/MonitoringCommercialPoints/MonitoringPointsModel";
import { MonitoringPointsHeaderModel } from "../../../models/PanelConnects/MonitoringCommercialPoints/MonitoringPointsHeaderModel";
import { TreeViewModel } from "../../../models/TreeViewModel";
import { CustomHeaderTemplateDataTable } from "../../../utils/CustomHeaderTemplateDataTable";
import * as XLSX from "xlsx";
import MonitoringPointsObservation from "../../../components/PanelConnects/MonitoringCommercialPoints/MonitoringPointsObservation/MonitoringPointsObservation";
import saveAs from "file-saver";
import { ToastSettings } from "../../../utils/ToastSettings";
import { MonitoringRankingModel } from "../../../models/PanelConnects/MonitoringCommercialPoints/MonitoringRankingModel";
import moment from "moment";

interface ContextProps {
    points: MonitoringPointsModel | undefined,
    commercialPoints: any[],
    regionalPoints: any[],
    executivePoints: any[],
    rankingPoints: any[],
    header: MonitoringPointsHeaderModel[],
    rankingHeader: MonitoringPointsHeaderModel[],
    handleListPoints: (params: MonitoringCommercialPointsParamsModel) => Promise<void>,
    handleGetExcel: (type: 'commercial' | 'regional' | 'executive' | 'ranking') => void,
    getNameCode: (props: any) => ReactNode,
    params: MonitoringCommercialPointsParamsModel,
    setParams: Dispatch<SetStateAction<MonitoringCommercialPointsParamsModel>>,
    isLoading: boolean,
    isLoadingExcel: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleFetchManualGoal: (data: any, file: File[]) => Promise<boolean>,
}

export const MonitoringCommercialPointsContext = createContext<ContextProps>({} as ContextProps);

interface MonitoringCommercialPointsProviderProps {
    children: ReactNode;
}

export const MonitoringCommercialPointsProvider = ({ children }: MonitoringCommercialPointsProviderProps) => {
    const [points, setPoints] = useState<MonitoringPointsModel>();
    const [header, setHeader] = useState<MonitoringPointsHeaderModel[]>([]);
    const [columnsHeader, setColumnsHeader] = useState<string[]>([]);
    const [rankingHeader, setRankingHeader] = useState<MonitoringPointsHeaderModel[]>([]);
    const [commercialPoints, setCommercialPoints] = useState<any[]>([]);
    const [regionalPoints, setRegionalPoints] = useState<any[]>([]);
    const [executivePoints, setExecutivePoints] = useState<any[]>([]);
    const [rankingPoints, setRankingPoints] = useState<any[]>([]);
    const [excelHeader, setExcelHeader] = useState<any[]>([]);
    const [params, setParams] = useState<MonitoringCommercialPointsParamsModel>({});
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingExcel, setIsLoadingExcel] = useState<boolean>(false);

    const service = new MonitoringCommercialPointsService();

    const handleListPoints = async (params: MonitoringCommercialPointsParamsModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);


            if (_Error) {
                setError(_Response?.message || _Error);
                setPoints(undefined);
                setIsLoading(false);
                return;
            }

            setError('');
            setIsLoading(false);
            setPoints(_Response?.data?.body);

            if (Array.isArray(_Response?.data?.header)) {
                mountHeader(_Response?.data?.header);
            } else {
                setHeader([]);
            }

            mountRankingHeader(_Response.data?.body?.ranking);

        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao listar');
            setPoints(undefined);
        }
    }

    const handleGetExcel = (type: 'commercial' | 'regional' | 'executive' | 'ranking') => {
        try {
            const excelData: any = getDataColumnsToExcel();
            const worksheet = XLSX.utils.json_to_sheet(
                type === 'commercial' ? excelData?.commercial :
                    type === 'executive' ? excelData?.executive :
                        type === 'regional' ? excelData?.regional : excelData?.ranking);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            const blob = new Blob([excelBuffer], {
                type: "application/octet-stream",
            });
            saveAs(blob, `painel-conecta-${type}-${params?.closingDate}.xlsx`);
        } catch (e) {
            setIsLoading(false);
            setError("Não foi possível gerar o excel.");
        }
    }

    const normalizeDataToExcel = (type: 'commercial' | 'regional' | 'executive' | 'ranking') => {
        if (points) {
            if (type !== 'ranking' && points[type]) {
                const _rows: any[] = [];
                points[type]?.forEach((item: any) => {
                    const _row: any = {};
                    const nameLabel = type === 'commercial' ? 'COMERCIAL' : type === 'regional' ? 'REGIONAL' : 'EXECUTIVO';
                    _row['CÓDIGO'] = item[type]?.code;
                    _row[nameLabel] = item[type]?.name;

                    if (item?.scores) {
                        item?.scores?.forEach((score: any) => {
                            const columnLabel = excelHeader?.find((x) => x.id === 'm' + score.goalId)?.label;
                            if (columnLabel) {
                                _row[columnLabel] = score?.score;
                                if (score.observation) {
                                    _row['OBSERVAÇÃO - ' + columnLabel] = score?.observation;
                                }
                            }
                        });
                    }
                    _row['TOTAL'] = item?.total;

                    if (item?.regional) {
                        _row['CÓDIGO REGIONAL'] = item?.regional?.code;
                        _row['REGIONAL'] = item?.regional?.name;
                    }

                    if (item?.executive) {
                        _row['CÓDIGO EXECUTIVO'] = item?.executive?.code;
                        _row['EXECUTIVO'] = item?.executive?.name;
                    }

                    if (item?.director) {
                        _row['CÓDIGO DIRETOR'] = item?.director?.code;
                        _row['DIRETOR'] = item?.director?.name;
                    }

                    _rows?.push(_row);
                });
                return _rows
            } else {
                if (type === 'ranking') {
                    const _rows: any[] = [];
                    points?.ranking?.body?.forEach((item) => {
                        const _row: any = {
                            'CÓDIGO': item?.commercial?.code,
                            'COMERCIAL': item?.commercial?.name
                        }
                        points?.ranking?.header?.forEach((period) => {
                            _row['PONTUAÇÃO ' + moment(period, 'YYYY-MM').format('MMMM/YY').toUpperCase()] = item[period]?.total;
                            _row['RANKING ' + moment(period, 'YYYY-MM').format('MMMM/YY').toUpperCase()] = item[period]?.rank ?? '';
                        });
                        _row['PONTUAÇÃO TOTAL'] = item?.annual?.total;
                        _row['RANKING ANO ' + moment(params?.closingDate ?? new Date(), 'YYYY-MM-DD').format('YYYY')] = item?.annual?.rank;
                        _rows?.push(_row);
                    });
                    return _rows;
                } else {
                    return [];
                }
            }
        }
    }

    const getDataColumnsToExcel = () => {
        const data: any = {};
        data.commercial = normalizeDataToExcel('commercial');
        data.regional = normalizeDataToExcel('regional');
        data.executive = normalizeDataToExcel('executive');
        data.ranking = normalizeDataToExcel('ranking');
        return data;
    }

    const mountHeader = async (header: TreeViewModel[]) => {
        const _header: MonitoringPointsHeaderModel[] = header?.map(transformToMonitoringPointsHeaderModel) ?? [];
        setHeader([
            ..._header,
            {
                field: 'total',
                headerName: 'TOTAL',
                cellClass: 'center-row',
                valueFormatter: (params: any) => params?.value?.toFixed(2)
            },
        ]);
    }

    const transformToMonitoringPointsHeaderModel = (node: TreeViewModel): MonitoringPointsHeaderModel => {
        if (node.children) {
            return {
                headerName: node.name,
                children: node.children.map(transformToMonitoringPointsHeaderModel)
            }
        } else {
            setExcelHeader((current) => [...current, { id: node.id, label: `${node.name} - ${node.score}` }]);
            return {
                field: node.id,
                headerName: node.name,
                headerComponentParams: {
                    template: CustomHeaderTemplateDataTable(
                        getColumnLabel(node.score ?? 0)
                    )
                },
                cellRenderer: MonitoringPointsObservation,
                cellClass: 'center-row',
                headerClass: 'header-goals',
                comparator: (valueA: any, valueB: any) =>
                    Number(valueA?.score?.toFixed(2)) < Number(valueB?.score?.toFixed(2)) ? -1 : 1
            };
        }
    }

    const getColumnLabel = (point: number): string => {
        return `
    <div class='circle-points d-flex justify-content-center align-items-center'>
      <i class="fa-solid fa-up-long"></i>
      ${point}
    </div>
    `;
    }

    const dataRankingNormalize = () => {
        const _rows: any[] = [];

        points?.ranking?.body?.forEach((item) => {
            const _row: any = {
                commercial: item.commercial
            };

            points?.ranking?.header?.forEach((column) => {

                const maxValue = points?.ranking?.body?.reduce((max, item) => {
                    if (item[column]?.rank > max) {
                        return item[column]?.rank;
                    }
                    return max;
                }, Number.NEGATIVE_INFINITY) ?? 9999;

                _row['total_' + column] = item[column]?.total ?? 0;
                _row['ranking_' + column] = item[column]?.rank ?? maxValue + 1;
            });
            _row['totalPoints'] = item?.annual?.total ?? 0;
            _row['annualRanking'] = item?.annual?.rank ?? 0;
            _rows.push(_row);
        });
        setRankingPoints(_rows);
    }

    const dataNormalize = (origin: any[], destination: Dispatch<SetStateAction<any[]>>) => {
        const _rows: any[] = [];

        const maxValue = origin.reduce((max, item) => {
            if (item.rank !== null && item.rank > max) {
                return item.rank;
            }
            return max;
        }, Number.NEGATIVE_INFINITY);

        const updatedItems = origin.map((item) => ({
            ...item,
            rank: item.rank === null ? maxValue + 1 : item.rank
        }));

        updatedItems?.forEach((item) => {
            const _row: any = {
                ...item
            };
            delete _row?.scores;

            excelHeader?.forEach((column) => {
                _row[column.id] = item?.scores?.find((score: any) => column?.id === 'm' + score?.goalId) ?? { score: 0, observation: '' };
            });
            _rows.push(_row);
        });
        destination(_rows);
    }

    useEffect(() => {
        if (points && excelHeader?.length > 0 && header?.length > 0) {
            dataNormalize(points?.commercial ?? [], setCommercialPoints);
            dataNormalize(points?.regional ?? [], setRegionalPoints);
            dataNormalize(points?.executive ?? [], setExecutivePoints);
            dataRankingNormalize();
        } else {
            setCommercialPoints([]);
            setRegionalPoints([]);
            setExecutivePoints([]);
            setRankingPoints([]);
        }
    }, [points, excelHeader]);

    const getNameCode = (props: any) => {
        const { value } = props;
        return (
            <div className='d-flex flex-column lh-base'>
                <span>{value?.name}</span>
                <b className='text-xs'>{value?.code}</b>
            </div>
        );
    }

    const handleFetchManualGoal = async (data: any, file: File[]) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.fetchManualGoal(data, file[0]);

            if (!!_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setError('Houve um erro ao salvar a meta manual');
            return false;
        }
    }

    const mountRankingHeader = (ranking: MonitoringRankingModel) => {
        if (!ranking) {
            setRankingHeader([]);
            return;
        }

        const _header: MonitoringPointsHeaderModel[] = [];

        _header?.push({
            field: 'commercial',
            headerName: 'COMERCIAL',
            minWidth: 250,
            cellRenderer: getNameCode,
        });

        ranking?.header?.forEach((item) => {
            _header?.push({
                field: 'total_' + item,
                headerName: 'PONTUAÇÃO ' + moment(item, 'YYYY-MM').format('MMMM/YY').toUpperCase(),
                cellClass: 'center-row',
            });

            _header?.push({
                field: 'ranking_' + item,
                headerName: 'RANKING ' + moment(item, 'YYYY-MM').format('MMMM/YY').toUpperCase(),
                cellClass: 'center-row',
            });
        });

        _header?.push({
            field: 'totalPoints',
            headerName: 'PONTUAÇÃO TOTAL',
            cellClass: 'center-row',
        });

        _header?.push({
            field: 'annualRanking',
            headerName: `RANKING ANO ${moment(params?.closingDate ?? new Date(), 'YYYY-MM-DD').format('YYYY')}`,
            cellClass: 'center-row',
        });

        setRankingHeader(_header);
    }

    return (
        <MonitoringCommercialPointsContext.Provider value={useMemo(() => ({
            points,
            commercialPoints,
            regionalPoints,
            executivePoints,
            rankingPoints,
            header,
            rankingHeader,
            handleListPoints,
            handleGetExcel,
            getNameCode,
            params,
            setParams,
            isLoading,
            isLoadingExcel,
            error,
            setError,
            handleFetchManualGoal,
        }), [
            points,
            commercialPoints,
            regionalPoints,
            executivePoints,
            rankingPoints,
            header,
            rankingHeader,
            handleListPoints,
            handleGetExcel,
            getNameCode,
            params,
            setParams,
            isLoading,
            isLoadingExcel,
            error,
            setError,
            handleFetchManualGoal,
        ])}>
            {children}
        </MonitoringCommercialPointsContext.Provider>
    );
}

export const useMonitoringCommercialPoints = () => useContext(MonitoringCommercialPointsContext);