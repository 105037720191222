import { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { useIndicatorManagementProductionRange } from '../../../../../providers/Visits/IndicatorManagement/IndicatorManagementProductionRangeProvider';
import { ProductionPerMonthModel } from '../../../../../models/Visits/IndicatorManagement/ProductionPerMonthModel';
import FormatMoney from '../../../../../utils/FormatMoney';
import moment from 'moment';

interface ProductionChartPerMonthProps { }

const chartColors = ["#7779BB", "#222343", "#25CBDB", "#454787", "#787ABA", "#AF7AC5", "#AED6F1", "#3498DB", "#633974", "#D2B4DE", "#D987FA", "#1F9BA7"];

const ProductionChartPerMonth: FC<ProductionChartPerMonthProps> = () => {
  const { productionsPerMonth } = useIndicatorManagementProductionRange();

  return (
    <div className="ProductionChartPerMonth" data-testid="ProductionChartPerMonth">
      <Bar
        height={100}
        data={{
          labels: productionsPerMonth?.map((item: ProductionPerMonthModel) => moment(item?.month).format('MM/YYYY')),
          datasets: [
            {
              data: productionsPerMonth?.map((item: ProductionPerMonthModel) => item?.production),
              backgroundColor: chartColors,
              borderRadius: 8,
              barThickness: 70,
            }
          ],
        }}
        options={
          {
            responsive: true,
            maintainAspectRatio: true,
            interaction: {
              intersect: false,
              mode: 'index',
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: true,
                intersect: true,
                callbacks: {
                  title: (item) => {
                    return 'Período: ' + item[0].label;
                  },
                  label: (item) => {
                    return 'Produção: ' + FormatMoney(Number(item?.raw));
                  }
                }
              },
              datalabels: {
                display: true,
                color: '#333',
                backgroundColor: (data) => {
                  const value = data.dataset.data[data.dataIndex];
                  return (value && parseInt(Number(value)?.toString()) >= 10) ? '#fff' : '';
                },
                borderRadius: 5,
                formatter: (value) => {
                  return FormatMoney(value);
                },
                font: {
                  weight: 'bold',
                  size: 12
                },
                align: 'top'
              }
            },
            scales: {
              x: {
                ticks: {
                  font: {
                    size: 12,
                    weight: 'bold'
                  }
                }
              }
            }
          }
        }
      />
    </div>
  )
};

export default ProductionChartPerMonth;
