export class FinancialModel {
    id?: number;
    name?: string;
    externalId!: number;
    febrabanCode!: number;
    cnpj?: string;
    url?: string;
    order?: number;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}