import FileSaver from "file-saver";
import api from "../Api";
import { BaseService } from "../BaseService";
import { VisitModel } from "../../models/Visits/IndicatorManagement/VisitModel";

const URL = 'api/app';

export class VisitsService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/visit/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async export(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/visit/export`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async save(payload: VisitModel): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/visit/save`, payload));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async download(id: number): Promise<any> {
        try {
            const response = await api.post(`${URL}/visit/download`, { id }, { responseType: 'blob' });
            const filename = response.headers['content-disposition'].split('filename=')[1];
            FileSaver.saveAs(response.data, filename, { autoBom: false });
            return [true, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getContactsType(): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/visitsTypesContacts/list`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getAttendance(): Promise<any> {
        try {
            return this.handleResponse(await api.get(`${URL}/visitsAttendance/list`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listQuantity(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/visit/quantityPerPartner`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}