import { FC, useState } from 'react';
import './ModalPartnerRegistrationUpdate.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { Card, Col, FormGroup, Row } from 'react-bootstrap';
import NavPills from '../../../NavPills/NavPills';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap } from '@fortawesome/free-solid-svg-icons';
import GoogleMaps from '../../../GoogleMaps/GoogleMaps';
import { usePartnerRegistrationUpdate } from '../../../../providers/Registrations/PartnerRegistrationUpdate/PartnerRegistrationUpdateProvider';
import { QuestionsModel } from '../../../../models/Registrations/PartnerRegistrationUpdate/PartnerRegistrationUpdateModel';
import FormatCnpj from '../../../../utils/FormatCnpj';
import FormatPhone from '../../../../utils/FormatPhone';
import moment from 'moment';

interface ModalPartnerRegistrationUpdateProps {
  show: boolean;
  onClose: () => void;
}

const TabEnum = {
  ATUALIZACAO: 'atualizacao',
  QUIZ: 'quiz',
}

const ModalPartnerRegistrationUpdate: FC<ModalPartnerRegistrationUpdateProps> = ({ show, onClose }) => {

  const [currentActiveTab, setCurrentActiveTab] = useState<string>(TabEnum.ATUALIZACAO);
  const { partnerUpdate, setPartnerUpdate } = usePartnerRegistrationUpdate();

  const handleClose = () => {
    setCurrentActiveTab(TabEnum.ATUALIZACAO);
    setPartnerUpdate(undefined);
    onClose();
  }

  const FormatDate = (value: string) => {
    return moment(value).format('DD/MM/YYYY');
  };

  const FormatCpf = (value: string) => {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const FormatRg = (value: string) => {
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
  };

  const FormatCep = (value: string) => {
    return value.replace(/(\d{5})(\d{3})/, '$1-$2');
  };

  const nameLogs: any[] = [
    { label: 'CPF', key: 'cpf', format: FormatCpf },
    { label: 'RG', key: 'rg', format: FormatRg },
    { label: 'RG Emissor', key: 'rgIssuer' },
    { label: 'RG UF', key: 'rgUf' },
    { label: 'Nome do Proprietário', key: 'ownerName' },
    { label: 'Data de Nascimento do Proprietário', key: 'ownerBirthday', format: FormatDate },
    { label: 'Estado Civil', key: 'userMaritalStatus' },
    { label: 'Nome da Mãe do Proprietário', key: 'ownerMotherName' },
    { label: 'Nome do Procurador', key: 'procuratorName' },
    { label: 'Nome Fantasia', key: 'fantasyName' },
    { label: 'CNPJ', key: 'cnpj', format: FormatCnpj },
    { label: 'Razão Social', key: 'socialReason' },
    { label: 'Tamanho da Empresa', key: 'enterpriseSize' },
    { label: 'Telefones', key: 'phones', format: FormatPhone },
    { label: 'Endereço', key: 'address' },
    { label: 'Logradouro', key: 'publicPlace' },
    { label: 'Número', key: 'numbers' },
    { label: 'Bairro', key: 'neighborhood' },
    { label: 'Complemento', key: 'complement' },
    { label: 'Cep', key: 'cep', format: FormatCep },
    { label: 'Cidade', key: 'city' },
    { label: 'Estado', key: 'state' },
  ];

  const renderLabel = (key: string) => {
    const nameLog = nameLogs?.find(x => x?.key === key);
    return nameLog ? nameLog?.label : key;
  };

  const renderMaskValue = (key: string, value: any) => {
    const log = nameLogs?.find(log => log?.key === key);
    if (log && log?.format) {
      return log?.format(value);
    }
    return value;
  };

  const AtualizacaoCadastraisContent: FC<{ log: any }> = ({ log }) => (
    <>
      <Row>
        {['before', 'after']?.map((type: string, index: number) => (
          <Col md={6} key={index}>
            <Card className={`${type} mb-2`} style={{ background: '#f8f9fa' }}>
              <Card.Header style={{ background: '#f8f9fa' }}>
                <h6>DADOS {type === 'before' ? 'ANTERIOR' : 'ATUALIZADO'}</h6>
              </Card.Header>
              <Card.Body>
                {Object?.keys(log)?.map((key: string, index: number) => (
                  Array.isArray(log[key]) ? null : (
                    <FormGroup className="form-group" key={index}>
                      <label>{renderLabel(key)?.toUpperCase()}</label>
                      {Array.isArray(log[key]?.[type]) ? (
                        log[key]?.[type]?.map((item: any, index: number) => (
                          <div key={index}>
                            {key === 'address'
                              ? Object?.entries(item)?.map(([subKey, value], subIndex) => (
                                !['id', 'origin', 'createdAt']?.includes(subKey) && (
                                  <div key={subIndex}>
                                    <label>{renderLabel(subKey)}</label>
                                    <input
                                      type="text"
                                      className="form-control mb-2"
                                      value={renderMaskValue(subKey, value || '')}
                                      disabled
                                    />
                                  </div>
                                )
                              ))
                              : (
                                <>
                                  <label>{item?.description}</label>
                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    value={renderMaskValue(key, item?.phone || item?.email || '')}
                                    disabled
                                  />
                                </>
                              )}
                          </div>
                        ))
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          value={renderMaskValue(key, log[key]?.[type] || '')}
                          disabled
                        />
                      )}
                    </FormGroup>
                  )
                ))}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Row>
        <Col md={12}>
          <FormGroup className="form-group">
            <label>
              <FontAwesomeIcon icon={faMap} /> LOCALIZAÇÃO DE ALTERAÇÃO
            </label>
            <GoogleMaps
              zoom={19}
              points={[{ lat: Number(partnerUpdate?.latitude) || 0, lng: Number(partnerUpdate?.longitude) || 0, label: 'Local' }]}
              showCircle={false}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );

  const QuizContent = () => (
    <Col md={12}>
      <div>
        <label><h5>{partnerUpdate?.quiz?.title}</h5></label>
        {partnerUpdate?.quiz?.questions?.map((question: QuestionsModel, index: number) => (
          <Card className="mb-2" key={index}>
            <Card.Body>
              <div className="mb-3">
                <span><b>{question?.description}</b></span>
              </div>
              <div >
                <Card className="p-2 badge-response">
                  {question?.values?.map((value, index) => (
                    <span key={index} className="badge badge-success">
                      {value}
                    </span>
                  ))}
                </Card>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>
    </Col>
  );

  return (
    <ModalDefault
      title={currentActiveTab === TabEnum.ATUALIZACAO ? 'Visualização de atualização cadastral' : 'Visualização Quiz'}
      show={show}
      onClose={handleClose}
      sizeModal={'xl'}
      showFooter={true}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalPartnerRegistrationUpdate" data-testid="ModalPartnerRegistrationUpdate">
        <Row>
          <Col md={12}>
            <NavPills mdMenu={6}
              options={[
                {
                  acronym: TabEnum.ATUALIZACAO,
                  name: 'Atualização cadastral',
                  icon: <i className="fa-solid fa-user"></i>,
                  show: true,
                },
                {
                  acronym: TabEnum.QUIZ,
                  name: 'Quiz',
                  icon: <i className="fa-solid fa-comment-dots"></i>,
                  show: true,
                },
              ]}
              current={currentActiveTab}
              setCurrent={setCurrentActiveTab}
            />
          </Col>
          {currentActiveTab === TabEnum.ATUALIZACAO && <AtualizacaoCadastraisContent log={partnerUpdate?.log || {}} />}
          {currentActiveTab === TabEnum.QUIZ && <QuizContent />}
        </Row>
      </div>
    </ModalDefault>
  );
}

export default ModalPartnerRegistrationUpdate;
