import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react";
import { CovenantTableService } from "../../../services/Registrations/Covenant/CovenantTableService";
import { CovenantTableModel } from "../../../models/Registrations/Covenant/CovenantTableModel";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";

interface ContextProps {
    covenantTables: CovenantTableModel[],
    covenantTablesOptions: ReactSelectOptions[],
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    isLoading: boolean,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => Promise<void>,
    handleSave: (payload: CovenantTableModel) => Promise<boolean>
}

export const CovenantTableContext = createContext<ContextProps>({} as ContextProps);

interface CovenantTableProviderProps {
    children: ReactNode;
}

export const CovenantTableProvider = ({ children }: CovenantTableProviderProps) => {
    const [covenantTables, setCovenantTables] = useState<CovenantTableModel[]>([]);
    const [covenantTablesOptions, setCovenantTablesOptions] = useState<ReactSelectOptions[]>([]);
    const [params, setParams] = useState<any>({});
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const service = new CovenantTableService();

    const handleList = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                setCovenantTables([]);
                setError(_Error);
                return;
            }

            setCovenantTables(_Response?.data);
            setCovenantTablesOptions(_Response?.data?.map((x: CovenantTableModel) => ({
                value: x.id, label: x.name
            })));
            setError('');
        } catch (e: any) {
            setIsLoading(false);
            setCovenantTables([]);
            setError(e);
        }
    }

    const handleSave = async (payload: any) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.save(payload);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e);
            return false;
        }
    }

    return (
        <CovenantTableContext.Provider value={useMemo(() => ({
            covenantTables,
            covenantTablesOptions,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleSave,
        }), [
            covenantTables,
            covenantTablesOptions,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleSave,
        ])}>
            {children}
        </CovenantTableContext.Provider>
    );
}

export const useCovenantTable = () => useContext(CovenantTableContext);