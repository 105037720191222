import { FC } from 'react';
import './CardQualityProcess.scss';
import { Card, Col, Row } from 'react-bootstrap';
import ChartBarPanel from '../../Import/ChartBarPanel/ChartBarPanel';
import { useProposalImportMonitor } from '../../../../providers/Proposals/Import/Monitor/ProposalImportMonitorProvider';
import TableDefaultHome from '../../../TableDefaultHome/TableDefaultHome';

interface CardQualityProcessProps { }

const CardQualityProcess: FC<CardQualityProcessProps> = () => {

  const { importsOptions, financialsMonitorOptions } = useProposalImportMonitor();

  return (
    <Card className="CardQualityProcess" data-testid="CardQualityProcess">
      <Card.Body>
        <Row>
          <Col md={12}>
            <ChartBarPanel
              data={{
                labels: financialsMonitorOptions?.map((item: any) => item?.name),
                datasets: [
                  {
                    data: importsOptions?.map((item: any) => item?.totalProcessedNew),
                    backgroundColor: '#454787',
                    borderRadius: 5,
                  },
                  {
                    data: importsOptions?.map((item: any) => item?.totalProcessed),
                    backgroundColor: '#787ABA',
                    borderRadius: 5,
                  },
                  {
                    data: importsOptions?.map((item: any) => item?.totalQuantity),
                    backgroundColor: '#222343',
                    borderRadius: 5,
                  },
                ],
              }}

              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                  },
                  datalabels: {
                    display: true,
                    color: '#333',
                    backgroundColor: '#fff',
                    formatter: (value: any) => {
                      return value.toLocaleString('pt-br', {})
                    },
                    borderRadius: 5,
                  }
                },
                scales: {
                  x: {
                    stacked: true,
                    display: true,
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    stacked: true,
                    grid: {
                      display: false,
                    },
                    border: {
                      display: false
                    },
                  },
                },
                dataLabels: {
                  enabled: true,
                }

              }}
            />
          </Col>
          <Col md={12}>
            <TableDefaultHome
              headers={[]}
              items={['Total', 'Processado', 'Processado Novo']}
              numberColumns={0}
              className="table-flush p-0 mt-1 mb-0"
            >
              <>
                <tr key={'1'}>

                  <td className="text-violet-60 py-2">
                    <div className="d-flex align-items-center color-status">
                      <div className="retangule-color-status" style={{ backgroundColor: '#222343' }}></div>
                      <span>{'Total Importado'}</span>
                    </div>
                  </td>

                  <td className="text-violet-60 py-2">
                    <div className="d-flex align-items-center color-status">
                      <div className="retangule-color-status" style={{ backgroundColor: '#787ABA' }}></div>
                      <span>{'Total Processado'}</span>
                    </div>
                  </td>

                  <td className="text-violet-60 py-2">
                    <div className="d-flex align-items-center color-status">
                      <div className="retangule-color-status" style={{ backgroundColor: '#454787' }}></div>
                      <span>{'Processado Novo'}</span>
                    </div>
                  </td>

                </tr>

              </>
            </TableDefaultHome>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default CardQualityProcess;