import { FC, useState, useEffect } from 'react';
import './ModalExportCalendarVisits.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useIndicatorManagementPreVisitVisits } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementPreVisitProvider';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import ModalHelpVisits from '../ModalHelpVisits/ModalHelpVisits';
import moment from 'moment';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import { toast } from 'react-toastify';
import { useIndicatorManagementWalletVisits } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementWalletProvider';

interface ModalExportCalendarVisitsProps {
  show: boolean;
  onClose: () => void;
}

const ModalExportCalendarVisits: FC<ModalExportCalendarVisitsProps> = ({ show, onClose }) => {

  const [showFormHelpVisits, setShowFormHelpVisits] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD'));
  const [dateMonth, setDateMonth] = useState<any>(moment(new Date()).add(3, 'M').format('YYYY-MM-DD'))

  const { setError, handleDownloadSchedule } = useIndicatorManagementPreVisitVisits();
  const { handleSubmit, setValue } = useForm<any>();
  const { params } = useIndicatorManagementWalletVisits();

  const handleClose = () => {
    setError('');
    onClose();
  }

  useEffect(() => {
    if (show) {
      setValue('commercial', params?.commercialId);
      setValue('startDate', startDate);
      setValue('endDate', endDate);
    } else {
      setStartDate(moment(new Date()).format('YYYY-MM-DD'));
      setEndDate(moment(new Date()).format('YYYY-MM-DD'));
    }
  }, [show]);

  const onSubmit = async (data: any) => {
    data.endDate = moment(data.endDate).format('YYYY-MM-DD');
    setIsLoadingSubmit(true);
    setError('');

    const ret = await handleDownloadSchedule(data);

    if (ret) {
      toast.success('Agenda baixada com sucesso!');
    }

    handleClose();
    setIsLoadingSubmit(false);
  }

  const onSubmitShowModalHelp = async () => {
    setShowFormHelpVisits(true)
  }

  return (
    <ModalDefault
      title={'EXPORTAR AGENDA DE PRÉ-VISITA'}
      show={show}
      onClose={handleClose}
      sizeModal={'xl'}
      showFooter={true}
      buttonTextAdditional={'Exportar Agenda'}
      handleAdditionalSubmit={handleSubmit(onSubmit)}
      buttonText={'Ajuda'}
      handleSubmit={handleSubmit(onSubmitShowModalHelp)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalExportCalendarVisits" data-testid="ModalExportCalendarVisits">
        <Row><strong>Período a ser exportado:</strong></Row>
        <br />
        <Row>
          <Col>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              startLabelText={'DATA INICIAL'}
              endLabelText={'DATA FINAL'}
              maxEndDate={dateMonth}
            />
          </Col>
        </Row>

        <ModalHelpVisits
          show={showFormHelpVisits}
          onClose={() => setShowFormHelpVisits(false)}
        />

      </div >
    </ModalDefault >
  );

}

export default ModalExportCalendarVisits;
