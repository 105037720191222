import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ProposalImportMonitorService } from "../../../../services/Proposals/Import/Monitor/ProposalImportMonitorService";
import { ReactSelectOptions } from "../../../../models/ReactSelectOptions";
import { ProposalImportMonitorModel } from "../../../../models/Proposals/Import/Monitor/ProposalImportMonitorModel";
import { ToastSettings } from "../../../../utils/ToastSettings";
import { ProposalPanelMonitorParams } from "../../../../models/Proposals/Panel/Monitor/ProposalPanelMonitorParams";

interface ContextProps {
    params: ProposalPanelMonitorParams,
    setParams: Dispatch<SetStateAction<ProposalPanelMonitorParams>>,
    imports: ProposalImportMonitorModel[] | null,
    importsOptions: ReactSelectOptions[],
    handleList: (data: any) => Promise<boolean>,
    financialsMonitor: ProposalImportMonitorModel[] | null,
    financialsMonitorOptions: ReactSelectOptions[],
    processedProduction: ProposalImportMonitorModel[] | null,
    processedProductionOptions: ReactSelectOptions[],
    financialValueSelected: ReactSelectOptions | undefined,
    setFinancialValueSelected: Dispatch<SetStateAction<ReactSelectOptions | undefined>>,
    timePeriodSelected: ReactSelectOptions | undefined,
    setTimePeriodSelected: Dispatch<SetStateAction<ReactSelectOptions | undefined>>,
}

export const ProposalImportMonitorContext = createContext<ContextProps>({} as ContextProps);

interface ProposalImportMonitorProviderProps {
    children: ReactNode;
}

export const ProposalImportMonitorProvider = ({ children }: ProposalImportMonitorProviderProps) => {
    const [imports, setImports] = useState<ProposalImportMonitorModel[]>([]);
    const [error, setError] = useState<string>('');
    const [params, setParams] = useState<ProposalPanelMonitorParams>({ group: true, "withPaginate": true } as ProposalPanelMonitorParams);
    const [importsOptions, setImportsOptions] = useState<ReactSelectOptions[]>([]);
    const [financialsMonitor, setFinancialsMonitor] = useState<ProposalImportMonitorModel[]>([]);
    const [financialsMonitorOptions, setFinancialsMonitorOptions] = useState<ReactSelectOptions[]>([]);
    const [processedProduction, setProcessedProduction] = useState<ProposalImportMonitorModel[]>([]);
    const [processedProductionOptions, setProcessedProductionOptions] = useState<any[]>([]);

    const [financialValueSelected, setFinancialValueSelected] = useState<ReactSelectOptions | undefined>(undefined);
    const [timePeriodSelected, setTimePeriodSelected] = useState<ReactSelectOptions | undefined>(undefined);

    const service = new ProposalImportMonitorService();

    const handleList = async (data: any) => {

        try {
            const [_Response, _Error] = await service.list(data);
            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Error);
                return false;
            }

            setError('');
            setImports(_Response.data);
            setFinancialsMonitor(_Response.data)
            setProcessedProduction(_Response.data)

            return true;
        } catch (e) {
            setError('Não foi possível carregar os tipos');
            return false;
        }
    };

    useEffect(() => {

        setImportsOptions(
            imports?.map((item: ProposalImportMonitorModel) => {
                return { totalQuantity: Number(item.totalQuantity), value: item.financialId, totalProcessed: Number(item.totalProcessed), totalProcessedNew: Number(item.totalProcessedNew) };
            })
        )

        setFinancialsMonitorOptions(
            financialsMonitor?.map((item: ProposalImportMonitorModel) => {
                return { name: item.financial?.name, value: item.financial?.id, acronym: item.financial?.acronym, externalId: item.financial?.externalId };
            })
        )

        setProcessedProductionOptions(
            processedProduction?.map((item: ProposalImportMonitorModel) => {
                return item?.processedProduction && item?.processedProduction.map((x: any) => {
                    return { uniqueKey: x?.uniqueKey, value: x?.importId, quantity: x?.quantity, processed: Number(x?.processed), processedNew: Number(x?.processedNew) };
                })
            })
        )

    }, [imports, financialsMonitor, processedProduction]);

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        params && handleList(params);
    }, [params]);

    return (
        <ProposalImportMonitorContext.Provider value={{
            params,
            setParams,
            imports,
            importsOptions,
            handleList,
            financialsMonitor,
            financialsMonitorOptions,
            processedProduction,
            processedProductionOptions,
            financialValueSelected,
            setFinancialValueSelected,
            timePeriodSelected,
            setTimePeriodSelected
        }}>
            {children}
        </ProposalImportMonitorContext.Provider>
    );
}

export const useProposalImportMonitor = () => useContext(ProposalImportMonitorContext);