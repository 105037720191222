import { FC, useEffect, useState } from 'react';
import './FormListVisits.scss';
import VisitsTableList from '../../VisitsAnalysis/VisitsTableList/VisitsTableList';
import { useVisits } from '../../../../providers/Visits/VisitsProvider';
import { TabEnumIndicatorManagement } from '../../../../pages/Visits/IndicatorManagement/IndicatorManagement';
import { toast } from 'react-toastify';
import VisitsReportsChart from '../../VisitsReports/VisitsReportsChart/VisitsReportsChart';
import { Card } from 'react-bootstrap';
import { useVisitsReports } from '../../../../providers/Visits/VisitsReports/VisitsReportsProvider';
import AppQuantityPerPage from '../../../AppQuantityPerPage/AppQuantityPerPage';
import ModalLastVisits from '../ModalLastVisits/ModalLastVisits';

interface FormListVisitsProps {
  currentActiveTab?: TabEnumIndicatorManagement;
}

const FormListVisits: FC<FormListVisitsProps> = ({ currentActiveTab }) => {
  const [showLastVisits, setShowLastVisits] = useState<boolean>(false);

  const { handleList, params, setParams, pages, handleListQuantity, handleExcel } = useVisits();
  const { handleListByMonths } = useVisitsReports();

  const onSubmitExcel = () => {
    handleExcel();
  }

  useEffect(() => {
    if (currentActiveTab === TabEnumIndicatorManagement.VISIT) {
      if (params?.commercialId) {
        handleList().then();
        handleListByMonths(Number(params?.commercialId));
        handleListQuantity().then();
      } else {
        toast.warning('Faça um filtro para listar a Visita!');
      }
    }
  }, [currentActiveTab, params]);

  return (

    <div className="FormListVisits" data-testid="FormListVisits">
      <>

        <div className='d-flex justify-content-end flex-row mb-2 '>
          <div>
            <div className="p-2 d-flex gap-2 justify-content-end align-items-center">

              <button className="btn btn-primary me-md-2 mb-0 btn-text-size" type="button"
                onClick={() => setShowLastVisits(true)}
              >Listar Última Visita</button>

              <button className="btn btn-primary bg-dark text-white mb-0 btn-text-size" type="button"
                onClick={() => setParams({ ...params })}
              >
                <i className="fa-solid fa-rotate me-2"></i>
                Atualizar aba
              </button>

              <div className="p-2 bd-highlight">
                <button type="button" className="btn bg-success text-white mb-0 d-flex align-items-center justify-content-end gap-2"
                  onClick={onSubmitExcel}
                >
                  <i className="fa-regular fa-file-excel me-2"></i>
                  Excel
                </button>
              </div>


              <div className='d-md-flex d-none'>
                <AppQuantityPerPage
                  params={params}
                  setParams={setParams}
                  pages={pages}
                />
              </div>

            </div>
            <div className='d-flex d-md-none justify-content-end'>
              <AppQuantityPerPage
                params={params}
                setParams={setParams}
                pages={pages}
              />
            </div>
          </div>
        </div>
      </>

      <div className='row mb-3'>
        <div className='col-12'><VisitsTableList /></div>
      </div>

      <Card className='mb-3'>
        <Card.Header>
          <Card.Title>
            Últimos 5 Meses
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <VisitsReportsChart />
        </Card.Body>
      </Card>

      <ModalLastVisits
        show={showLastVisits}
        onClose={setShowLastVisits}
      />
    </div>
  );
}

export default FormListVisits;
