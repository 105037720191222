import { FC, useState } from 'react';
import './StepRegistrationType.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faUser } from '@fortawesome/free-solid-svg-icons';
import { useMultiSteps } from '../../../../../providers/MultiStepsProvider';

interface StepRegistrationTypeProps {
  handleExtraAction?: any;
  textButtonExtraAction?: string;
}

const StepRegistrationType: FC<StepRegistrationTypeProps> = ({ handleExtraAction, textButtonExtraAction }) => {
  const [selected, setSelected] = useState<string>('PJ');

  const { handleNext, renderNav } = useMultiSteps();

  return (
    <>
      <div className="StepRegistrationType mt-4" data-testid="StepRegistrationType">
        <div className="options-wrapper">
          <div className="options-item">
            <input
              id="btnradioPhysicalPerson"
              type="radio"
              className="btn-radio"
              value="PF"
              checked={selected === 'PF'}
              onChange={(e: any) => setSelected(e.target.value)}
            />
            <label htmlFor="btnradioPhysicalPerson">
              <h6>
                <FontAwesomeIcon icon={faUser} />
                Pessoa Física
              </h6>
            </label>
          </div>

          <div className="options-item">
            <input
              id="btnradioLegalPerson"
              type="radio"
              className="btn-radio"
              value="PJ"
              checked={selected === 'PJ'}
              onChange={(e: any) => setSelected(e.target.value)}
            />
            <label htmlFor="btnradioLegalPerson">
              <h6>
                <FontAwesomeIcon icon={faBriefcase} />
                Pessoa Jurídica
              </h6>
            </label>
          </div>
        </div>
      </div>

      {renderNav(handleNext, !selected, handleExtraAction, textButtonExtraAction)}
    </>
  );
}

export default StepRegistrationType;
