import * as yup from "yup";

export const schema = yup.object().shape({
    enterpriseId: yup
        .number()
        .required(),
    financialId: yup
        .number()
        .required(),
    amount: yup
        .string()
        .required(),
    paymentDate: yup
        .string()
        .required(),
    description: yup
        .string()
        .min(3)
        .max(255),
});