import * as yup from "yup";

export const schema = yup.object().shape({
    name: yup
        .string()
        .trim()
        .min(3)
        .max(150)
        .required(),
    closure: yup
        .object()
        .required(),
    idMasterGroup: yup
        .object()
        .required(),
});