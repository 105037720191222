import { CampaignCreateModel } from "../../../models/Marketplace/Campaign/CampaignCreateModel";
import { CampaignModel } from "../../../models/Marketplace/Campaign/CampaignModel";
import { CampaignParams } from "../../../models/Marketplace/Campaign/CampaignParams";
import { CampaignSimulationFilter } from "../../../models/Marketplace/Campaign/CampaignSilmulaitonFilter";
import api from "../../Api";
import { BaseService } from "../../BaseService";
import FileSaver from 'file-saver';

const URL = "api/services/marketplace";

export class DashboardService extends BaseService {


    async getGuestToken(dashboarduid: string): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/public/dashboard/superset/guesttoken/${dashboarduid}`));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }
}