import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PagesPaginateModel } from "../../../../models/Paginate/PagesPaginate";
import { BiMonthlyProductionModel } from "../../../../models/BI/Dashboard/BiMonthlyProductionModel";

interface ContextProps {
    financialByUfs: BiMonthlyProductionModel | undefined,
    setFinancialByUfs: Dispatch<SetStateAction<BiMonthlyProductionModel | undefined>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
}

export const BiFinancialByUfContext = createContext<ContextProps>({} as ContextProps);

interface BiFinancialByUfProviderProps {
    children: ReactNode;
}

export const BiFinancialByUfProvider = ({ children }: BiFinancialByUfProviderProps) => {
    const [financialByUfs, setFinancialByUfs] = useState<BiMonthlyProductionModel>();
    const [params, setParams] = useState<any>({ withPaginate: false });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    return (
        <BiFinancialByUfContext.Provider value={{
            financialByUfs,
            setFinancialByUfs,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
        }}>
            {children}
        </BiFinancialByUfContext.Provider>
    );
}

export const useBiFinancialByUf = () => useContext(BiFinancialByUfContext);