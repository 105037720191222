import React, { FC } from 'react';
import './PasswordVisitsList.scss';
import { useIndicatorManagementPasswordVisits } from '../../../../../providers/Visits/IndicatorManagement/IndicatorManagementPasswordProvider';
import { useStyle } from '../../../../../providers/Style/StyleProvider';
import { useAuth } from '../../../../../providers/AuthProvider';
import { usePaginate } from '../../../../../providers/PaginateProvider';
import { IndicatorManagementPasswordModel } from '../../../../../models/Visits/IndicatorManagement/IndicatorManagementPasswordModel';
import moment from 'moment';
import { TabEnumPasswordIndicatorManagement } from '../FormPasswordVisits';
import AppPagination from '../../../../AppPagination/AppPagination';
import SkeletonTable from '../../../../SkeletonTable/SkeletonTable';

interface PasswordVisitsListProps {
  data: IndicatorManagementPasswordModel[],
  activeTab: TabEnumPasswordIndicatorManagement,
}

const PasswordVisitsList: FC<PasswordVisitsListProps> = ({ data, activeTab }) => {
  const { isLoading, pages, params, setParams } = useIndicatorManagementPasswordVisits();
  const { urlDomus } = useStyle();
  const { accessToken } = useAuth();
  const { handlePaginate } = usePaginate();

  const renderList = () => {
    return data?.map((item: IndicatorManagementPasswordModel) => (
      <tr key={item?.partnerId}>
        <td className='text-wrap'>
          <a
            href={`${urlDomus}/app/api/v1/auth/authTokenSB?token=${accessToken}&redirect=${urlDomus}/vidadoagente.php?codigo=${item?.partnerId}`}
            target='_blank'
            className="link-primary"
            style={{ cursor: 'pointer' }}
          >
            {item.protocolLogin}
          </a>
          <br />
          {item.protocolName}</td>
        <td>{item.protocol}</td>
        <td>{item.login}</td>
        <td>{item.financial}</td>
        <td>{moment(item.protocolCreationDate).format('DD/MM/YYYY HH:mm:ss')}</td>
        {![TabEnumPasswordIndicatorManagement.CREATE_FINISHED, TabEnumPasswordIndicatorManagement.RESET_FINISHED].includes(activeTab) &&
          <td>{item.status}</td>
        }
        {
          [TabEnumPasswordIndicatorManagement.CREATE_FINISHED, TabEnumPasswordIndicatorManagement.RESET_FINISHED].includes(activeTab) &&
          <td>{moment(item.conclusionDate).format('DD/MM/YYYY HH:mm:ss')}</td>
        }
        {
          TabEnumPasswordIndicatorManagement.REQUEST === activeTab &&
          <td>{item.hasLogin}</td>
        }
        <td className='text-wrap'>{item.secondCompletedLogin}</td>
      </tr>
    ));
  }

  return (
    <div className="PasswordVisitsList" data-testid="PasswordVisitsList">
      {isLoading ?
        <SkeletonTable />
        :
        <div className='table-responsive'>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Agente</th>
                <th>Protocolo</th>
                <th>Usuário</th>
                <th>Banco</th>
                <th>Data Solicitação</th>
                {![TabEnumPasswordIndicatorManagement.CREATE_FINISHED, TabEnumPasswordIndicatorManagement.RESET_FINISHED].includes(activeTab) &&
                  <th>Status</th>
                }
                {[TabEnumPasswordIndicatorManagement.CREATE_FINISHED, TabEnumPasswordIndicatorManagement.RESET_FINISHED].includes(activeTab) &&
                  <th>Data Conclusão</th>
                }
                {TabEnumPasswordIndicatorManagement.REQUEST === activeTab &&
                  <th>Possui Login</th>
                }
                <th>Jornada de Solicitação</th>
              </tr>
            </thead>
            <tbody>
              {isLoading
                ?
                <SkeletonTable />
                :
                <>
                  {data?.length > 0
                    ?
                    renderList()
                    :
                    <tr>
                      <td colSpan={20} className='text-center'>Não existem registros!</td>
                    </tr>
                  }
                </>
              }
            </tbody>
          </table>
          {data?.length > 0 && (
            <div>
              <nav aria-label="...">
                <AppPagination
                  pages={pages}
                  handlePaginate={handlePaginate}
                  params={params}
                  setParams={setParams}
                  style={{ backgroundColor: '#fff' }}
                />
              </nav>
            </div>
          )}
        </div>
      }
    </div>
  )
};

export default PasswordVisitsList;
