import { CovenantTableModel } from "../../../models/Registrations/Covenant/CovenantTableModel";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/services/proposals/proposal/table';

export class CovenantTableService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async save(payload: CovenantTableModel): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/save`, payload));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}