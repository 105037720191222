import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/app/visit';

export class VisitsReportsService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listByMonths(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/reports/month`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listByAgents(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/reports/agents`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}