import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react";
import { BiFinancialUpdateFrequencyModel } from "../../models/BI/BiFinancialUpdateFrequencyModel";
import { PagesPaginateModel } from "../../models/Paginate/PagesPaginate";
import { BiFinancialUpdateFrequencyService } from "../../services/BI/BiFinancialUpdateFrequencyService";

interface ContextProps {
    frequencyOptions: any[],
    financials: BiFinancialUpdateFrequencyModel[],
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleListFinancials: () => void,
    handleListFrequencyOptions: () => void,
    handleSaveConfig: (financial_id: number, frequency: number) => Promise<boolean>
}

export const BiFinancialUpdateFrequencyContext = createContext<ContextProps>(
    {} as ContextProps
);

interface BiFinancialUpdateFrequencyProviderProps {
    children: ReactNode;
}

export const BiFinancialUpdateFrequencyProvider = ({ children }: BiFinancialUpdateFrequencyProviderProps) => {
    const [frequencyOptions, setFrequencyOptions] = useState<any[]>([]);
    const [financials, setFinancials] = useState<BiFinancialUpdateFrequencyModel[]>([]);
    const [params, setParams] = useState<any>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const service = new BiFinancialUpdateFrequencyService();

    const handleListFinancials = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.listFinancialsConfig(params);
            setIsLoading(false);

            if (_Error) {
                setFinancials([]);
                setPages({});
                setError(_Error);
                return;
            }

            setFinancials(_Response.data);
            setPages(_Response?.pages);
            setError("");
        } catch (e) {
            setIsLoading(false);
            setFinancials([]);
            setPages({});
            setError("Não foi possível carregar os exemplos do sistema.");
        }
    };

    const handleListFrequencyOptions = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.listFrequency();
            setIsLoading(false);

            if (_Error) {
                setFrequencyOptions([]);
                setError(_Error);
                return;
            }

            setFrequencyOptions(_Response?.data?.map((x: any) => (
                {
                    label: x.name,
                    value: x.value
                }
            )));
            setError("");
        } catch (e) {
            setIsLoading(false);
            setFrequencyOptions([]);
            setError("Não foi possível carregar os exemplos do sistema.");
        }
    };

    const handleSaveConfig = async (financial_id: number, frequency: number): Promise<boolean> => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.saveConfig({ financial_id, frequency });
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return false;
            }

            setError("");
            return true
        } catch (e) {
            setIsLoading(false);
            setError("Não foi possível carregar os exemplos do sistema.");
            return false;
        }
    };

    return (
        <BiFinancialUpdateFrequencyContext.Provider
            value={useMemo(() => ({
                frequencyOptions,
                financials,
                params,
                setParams,
                pages,
                setPages,
                isLoading,
                error,
                setError,
                handleListFinancials,
                handleListFrequencyOptions,
                handleSaveConfig,
            }), [
                frequencyOptions,
                financials,
                params,
                setParams,
                pages,
                setPages,
                isLoading,
                error,
                setError,
                handleListFinancials,
                handleListFrequencyOptions,
                handleSaveConfig,
            ])}
        >
            {children}
        </BiFinancialUpdateFrequencyContext.Provider>
    );
}

export const useBiFinancialUpdateFrequency = () => useContext(BiFinancialUpdateFrequencyContext);