import * as yup from "yup";

export const schema = yup.object().shape({
    covenantGroup: yup
        .object()
        .required(),
    covenant: yup
        .object()
        .required(),
    contractForm: yup
        .object()
        .required(),
    covenantTable: yup
        .object()
        .required()
})