import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CompanyModel } from "../../../models/Registrations/Company/CompanyModel";
import { CompanyParams } from "../../../models/Registrations/Company/CompanyParams";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { useLocation } from "react-router-dom";
import { CompanyService } from "../../../services/Registrations/Company/CompanyService";
import { CompanysEnum } from "../../../models/Registrations/Company/CompanysEnum";
import { useStyle } from "../../Style/StyleProvider";
import { CompanyEnum } from "../../../enums/Company/CompanyEnum";
import { toast } from "react-toastify";
import { ToastSettings } from "../../../utils/ToastSettings";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";

interface ContextProps {
    companys: CompanyModel[],
    company: CompanyModel,
    setCompany: Dispatch<SetStateAction<CompanyModel>>,
    params: CompanyParams,
    setParams: Dispatch<SetStateAction<CompanyParams>>,
    selectedCompany: any | null,
    setSelectedCompany: Dispatch<SetStateAction<any | null>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    companysOptions: ReactSelectOptions[],
    setCompanysOptions: Dispatch<SetStateAction<any>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => Promise<boolean>,
    handleGetAll: () => Promise<boolean>,
    handleFetch: (data: CompanyModel) => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>,
}

export const CompanyContext = createContext<ContextProps>({} as ContextProps);

interface CompanyProviderProps {
    children: ReactNode;
}

export const CompanyProvider = ({ children }: CompanyProviderProps) => {
    const [companys, setCompanys] = useState<CompanyModel[]>([]);
    const [company, setCompany] = useState<CompanyModel>({});
    const [params, setParams] = useState<CompanyParams>({});
    const [selectedCompany, setSelectedCompany] = useState<any | null>(null);
    const [companysOptions, setCompanysOptions] = useState<any | null>(null);
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const { style } = useStyle();

    const service = new CompanyService();
    const location = useLocation();

    const handleList = useCallback(async () => {
        try {
            if (!style || style?.id !== CompanyEnum.BEVI) {
                return false;
            }

            setIsLoading(true);
            const [_Response, _Error] = await service.list({ ...params, withPaginate: true });
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setCompanys(_Response.data);
            setPages(_Response?.pages);

            setError('');
            return true;
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar as promotoras');
            return false;
        }
    }, [params, location.pathname, style]);

    const handleGetAll = async () => {
        try {
            const [_Response, _Error] = await service.list({ ...params, withPaginate: false });

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setCompanysOptions(
                _Response.data.map((item: any) => {
                    if (item.id === CompanysEnum.BEVI) {
                        setSelectedCompany({ label: item.name, value: item.id, externalId: item?.externalId });
                    }

                    return { label: item.name, value: item.id, externalId: item?.externalId };
                })
            );

            setError('');
            return true;
        } catch (e) {
            setError('Não foi possível carregar as promotoras');
            return false;
        }
    };

    const handleFetch = async (data: CompanyModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error, _Code, _Errors] = await service.fetch(data);
            setIsLoading(false);

            if (!!_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setIsLoading(false);
            setError('Houve um erro ao salvar a empresa');
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar a empresa');
            return false;
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        setCompanysOptions(
            companys
                ?.map((item: CompanyModel) => {
                    return { label: item.name, value: item.id, externalId: item?.externalId };
                })
        )
    }, [companys]);

    return (
        <CompanyContext.Provider value={useMemo(() => ({
            companys,
            company,
            setCompany,
            params,
            setParams,
            selectedCompany,
            setSelectedCompany,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            companysOptions,
            setCompanysOptions,
            handleList,
            handleGetAll,
            handleFetch,
            handleDelete
        }), [
            companys,
            company,
            setCompany,
            params,
            setParams,
            selectedCompany,
            setSelectedCompany,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            companysOptions,
            setCompanysOptions,
            handleList,
            handleGetAll,
            handleFetch,
            handleDelete
        ])}>
            {children}
        </CompanyContext.Provider>
    );
}

export const useCompany = () => useContext(CompanyContext);