import React, { FC } from 'react';
import './ContainmentContent.scss';
import { Card, Col, FormGroup } from 'react-bootstrap';

interface ContainmentContentProps {
  cyberIncident: any;
}

const ContainmentContent: FC<ContainmentContentProps> = ({ cyberIncident }) => {

  return (
    <div className="ContainmentContent" data-testid="ContainmentContent">
      <>
        <Col md={12}>
          <Card>
            <Card.Body>
              <Col>
                <FormGroup className="form-group" >
                  <label>AÇÕES IMEDIATAS TOMADAS</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.immediateActionsTaken}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>ISOLAMENTO DE SISTEMAS</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.systemIsolation}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>DESATIVAÇÃO DE CONTAS COMPROMETIDAS</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.compromisedAccountsDeactivation}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>BLOQUEIO DE IPS/MALWARE</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.ipMalwareBlocking}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>PROCEDIMENTO DE CONTENÇÃO A CURTO PRAZO</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.shortTermContainmentProcedures}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>APLICAÇÃO DE PATCHES E ATUALIZAÇÕES</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.patchesUpdatesApplication}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>AJUSTE DE CONFIGURAÇÃO DE SEGURANÇA</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.securityConfigurationAdjustments}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>AÇÕES DE ERRADICAÇÃO</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.eradicationActions}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>MEDIDAS IMPLEMENTADAS PARA REMOÇÃO DO INCIDENTE</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.incidentRemovalMeasures}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>VARREDURA E REMOÇÃO DE MALWARE</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.malwareScanningRemoval}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>REMOÇÃO DE BACKDOORS</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.backdoorRemoval}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>CORREÇÃO DE VULNERABILIDADES</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.vulnerabilitiesCorrection}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>VALIDAÇÃO DA LIMPEZA</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.cleaningValidation}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>RESULTADOS DE NOVAS VARREDURAS</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.newScansResults}
                    disabled
                  />
                </FormGroup>
              </Col>
              <label>AÇÕES DE RECUPERAÇÃO</label>
              <Col>
                <FormGroup className="form-group" >
                  <label>Sistemas Restaurados</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.recoveryActions?.restoredSystems}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Lista de Sistemas/Serviços Restaurados</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.recoveryActions?.restoredSystemsServicesList}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Dados Recuperados</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.recoveryActions?.recoveredData}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Origem dos Backups</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.recoveryActions?.backupSource}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Monitoramento Pós-Incidente</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.recoveryActions?.postIncidentMonitoring}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" >
                  <label>Período de Monitoramento Intensivo</label>
                  <textarea
                    className="form-control"
                    value={cyberIncident?.containmentStage2?.recoveryActions?.intensiveMonitoringPeriod}
                    disabled
                  />
                </FormGroup>
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </>
    </div>
  )

};

export default ContainmentContent;
