export enum ProposalRuleConditionEnum {
    EQUAL = 1,
    BIGGER_THAN = 2,
    LESS_THAN = 3,
    DIFFERENT_THAN = 4,
    BETWEEN = 5,
    CONTAINED_IN = 6,
    CONTAINS = 7,
    EMPTY = 8,
    NOT_EMPTY = 9,
}