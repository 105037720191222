import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from "react";
import { ProductionRangeApiReturn, ProductionRangeListModel } from "../../../models/Visits/IndicatorManagement/ProductionRangeModel";
import { ProductionPerMonthModel } from "../../../models/Visits/IndicatorManagement/ProductionPerMonthModel";
import { IndicatorManagementProductionRangeService } from "../../../services/Visits/IndicatorManagement/IndicatorManagementProductionRangeService";
import { VisitsActionsParams } from "../../../models/Visits/VisitsActions/VisitsActionsParams";
import { ProductionRangeExcelParams } from "../../../models/Visits/IndicatorManagement/ProductionRangeExcelParams";
import { ToastSettings } from "../../../utils/ToastSettings";
import { toast } from "react-toastify";

interface ContextProps {
    productionsPerMonth: ProductionPerMonthModel[],
    productionsRangeList: { lines?: ProductionRangeListModel[], months?: string[] },
    productionsRangeApi: ProductionRangeApiReturn[],
    isLoading: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    isFileLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleGetProduction: (params: VisitsActionsParams) => Promise<any>,
    handleGetExcel: (params: ProductionRangeExcelParams) => Promise<any>
}

export const IndicatorManagementProductionRangeContext = createContext<ContextProps>({} as ContextProps);

interface IndicatorManagementProductionRangeProviderProps {
    children: ReactNode;
}

const rangeOptions = [{
    value: '<=30',
    label: 'Até 30 Mil'
},
{
    value: '<=80',
    label: 'Até 80 Mil'
},
{
    value: '<=300',
    label: 'Até 300 Mil'
},
{
    value: '<=500',
    label: 'Até 500 Mil'
},
{
    value: '<=1m',
    label: 'Até 1 Milhão'
},
{
    value: '<=5m',
    label: 'Até 5 Milhões'
},
{
    value: '>5m',
    label: 'Acima de 5 Milhões'
},
{
    value: 'total',
    label: 'Total'
}
];

export const IndicatorManagementProductionRangeProvider = ({ children }: IndicatorManagementProductionRangeProviderProps) => {
    const [productionsRangeApi, setProductionsRangeApi] = useState<ProductionRangeApiReturn[]>([])
    const [productionsRangeList, setProductionsRangeList] = useState<{ lines?: ProductionRangeListModel[], months?: string[] }>({});
    const [productionsPerMonth, setProductionsPerMonth] = useState<ProductionPerMonthModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const service = new IndicatorManagementProductionRangeService();

    const handleGetProduction = async (params: VisitsActionsParams) => {
        try {
            setIsLoading(true);

            const [_Response, _Error] = await service.getProductionRange(params.commercialId ?? '', params.userId);

            if (_Error) {
                setIsLoading(false);
                setError(_Error);
                setProductionsRangeApi([]);
                setProductionsRangeList({});
                setProductionsPerMonth([]);
                return;
            }

            if (!_Response?.data?.productionsRange) {
                setProductionsRangeApi([]);
            }

            setProductionsRangeApi(_Response?.data?.productionRange);
            setProductionsPerMonth(_Response?.data?.productionPerMonth);
            setIsLoading(false);
            setError('');

            return;

        } catch (error: any) {
            setIsLoading(false);
            setError('Houve um erro ao listar a Produção por Faixa');
            return;
        }
    }

    // const handleGetExcel = async (params: ProductionRangeExcelParams) => {
    //     try {
    //         setIsFileLoading(true);
    //         const [_Response, _Error] = await service.getExcel({ ...params, type: params.type, userId: params.commercialId });
    //         if (!!_Error) {
    //             setIsFileLoading(false);
    //             setError(_Error);
    //             return false;
    //         }
    //         setIsFileLoading(false);
    //         setError('');
    //         return true;
    //     } catch (error: any) {
    //         setIsFileLoading(false);
    //         setError(error);
    //         return false;
    //     }
    // }

    const handleGetExcel = async (params: ProductionRangeExcelParams) => {
        setError('');
        setIsLoading(true);

        const [_Response, _Error] = await service.getExcel({ ...params, type: params.type });

        if (_Error) {
            setIsLoading(false);
            ToastSettings(_Error, 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoading(false);
    };

    useEffect(() => {
        if (productionsRangeApi && productionsRangeApi?.length > 0) {
            const _lines: ProductionRangeListModel[] = [];
            let months = !!productionsRangeApi[0]?.data && [productionsRangeApi[0]?.data[0].month ?? '', productionsRangeApi[0].data[1]?.month ?? '', productionsRangeApi[0].data[2]?.month ?? ''];

            rangeOptions.forEach((item: { value: string, label: string }, index: number) => {
                const rangeItems = productionsRangeApi.find((x: ProductionRangeApiReturn) => x.range === item.value);
                if (rangeItems?.data) {
                    _lines.push({
                        rangeId: index,
                        range: item.label,
                        rangeAcronym: item.value,
                        productionM1: { value: rangeItems?.data[0]?.production, month: rangeItems?.data[0]?.month },
                        productionPercentM1: { value: rangeItems?.data[0]?.productionPercent, month: rangeItems?.data[0]?.month },
                        quantityM1: { value: rangeItems?.data[0]?.quantity, month: rangeItems?.data[0]?.month },
                        quantityPercentM1: { value: rangeItems?.data[0]?.quantityPercent, month: rangeItems?.data[0]?.month },
                        productionM2: { value: rangeItems?.data[1]?.production, month: rangeItems?.data[1]?.month },
                        productionPercentM2: { value: rangeItems?.data[1]?.productionPercent, month: rangeItems?.data[1]?.month },
                        quantityM2: { value: rangeItems?.data[1]?.quantity, month: rangeItems?.data[1]?.month },
                        quantityPercentM2: { value: rangeItems?.data[1]?.quantityPercent, month: rangeItems?.data[1]?.month },
                        productionM3: { value: rangeItems?.data[2]?.production, month: rangeItems?.data[2]?.month },
                        productionPercentM3: { value: rangeItems?.data[2]?.productionPercent, month: rangeItems?.data[2]?.month },
                        quantityM3: { value: rangeItems?.data[2]?.quantity, month: rangeItems?.data[2]?.month },
                        quantityPercentM3: { value: rangeItems?.data[2]?.quantityPercent, month: rangeItems?.data[2]?.month },
                    })
                }
            });
            setProductionsRangeList({ lines: _lines, months: months || [] });
        }
    }, [productionsRangeApi]);

    return (
        <IndicatorManagementProductionRangeContext.Provider value={useMemo(() => ({
            productionsRangeList,
            productionsPerMonth,
            productionsRangeApi,
            isLoading,
            setIsLoading,
            isFileLoading,
            error,
            setError,
            handleGetProduction,
            handleGetExcel
        }), [
            productionsRangeList,
            productionsPerMonth,
            productionsRangeApi,
            isLoading,
            setIsLoading,
            isFileLoading,
            error,
            setError,
            handleGetProduction,
            handleGetExcel
        ])}>
            {children}
        </IndicatorManagementProductionRangeContext.Provider>
    )
}

export const useIndicatorManagementProductionRange = () => useContext(IndicatorManagementProductionRangeContext);