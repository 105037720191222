import React, { FC, useState } from "react";
import { Card, CardBody, Col, Dropdown, Row } from "react-bootstrap";
import { useVisitsDivergencesAssessments } from "../../../providers/Visits/VisitsDivergencesAssessments/VisitsDivergencesAssessmentsProvider";
import { VisitModel } from "../../../models/Visits/IndicatorManagement/VisitModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";
import MasterPage from "../../../components/MasterPage/MasterPage";
import PageBase from "../../../components/PageBase/PageBase";
import moment from "moment";
import './VisitsDivergencesAssessments.scss';
import TooltipItem from "../../../components/TooltipItem/TooltipItem";
import DropdownMenuTable from "../../../components/DropdownMenuTable/DropdownMenuTable";
import ModalDivergenceDetail from "../../../components/Visits/VisitsDivergencesAssessments/ModalDivergenceDetail/ModalDivergenceDetail";
import AppPagination from "../../../components/AppPagination/AppPagination";
import { usePaginate } from "../../../providers/PaginateProvider";
import { useSidebarFilter } from "../../../providers/SidebarFilterProvider";
import SidebarFilterDivergenceDetail from "../../../components/Visits/VisitsDivergencesAssessments/SidebarFilterDivergenceDetail/SidebarFilterDivergenceDetail";

interface VisitsDivergencesAssessmentsPropos { }

const VisitsDivergencesAssessments: FC<VisitsDivergencesAssessmentsPropos> = () => {

    const [showModalDetail, setShowModalDetail] = useState<boolean>(false);
    const [visit, setVisit] = useState<any | null>(null);
    const { visits, pages, isLoading, params, setParams } = useVisitsDivergencesAssessments();
    const { handlePaginate } = usePaginate();
    const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();

    const renderContent = (): JSX.Element => {
        return (
            <>
                {!isLoading ? (
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={12} xs={12}>
                                    <div className="table-responsive">
                                        <table className="table table-flush m-0 w-100">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className="text-uppercase opacity-7 text-center" scope="col"> #ID </th>
                                                    <th className="text-uppercase opacity-7" scope="col"> DADOS VISITAS </th>
                                                    <th className="text-center text-uppercase opacity-7" scope="col"> PROSPECÇÃO? </th>
                                                    <th className="text-center text-uppercase opacity-7" scope="col"> DIVERGÊNCIAS? </th>
                                                    <th className="text-center text-uppercase opacity-7" scope="col"> STATUS DIVERGÊNCIA </th>
                                                    <th className="text-center text-uppercase opacity-7" scope="col"> OBSERVAÇÃO DIVERGÊNCIA </th>
                                                    <th className="text-center text-uppercase opacity-7" scope="col"> AÇÃO </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderList()}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>

                            {(visits?.length > 0) && (
                                <div>
                                    <nav aria-label="...">
                                        <AppPagination
                                            pages={pages}
                                            handlePaginate={handlePaginate}
                                            params={params}
                                            setParams={setParams}
                                            style={{ backgroundColor: '#fff' }}
                                        />
                                    </nav>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                ) : (<SkeletonTable />)
                }
            </>
        );
    };

    const renderList = () => {
        return (
            <>
                {visits?.length > 0 ? (
                    <>
                        {
                            visits.map((item: VisitModel, key: number) => (
                                <tr key={item?.id || key} className="text-sm">
                                    <td className="text-center">{item?.id}</td>
                                    <td className='text-wrap' style={{ minWidth: '250px' }}>
                                        <p className="mb-1" style={{ lineHeight: '20px' }}> <b> <small> COMERCIAL </small> </b> <br /> {item?.userVisitor?.partnerCode || ''} - {item?.userVisitor?.name || ''} </p>
                                        <p className="mb-1" style={{ lineHeight: '20px' }}>
                                            <b> <small> PARCEIRO </small> </b> <br /> {!!item?.userVisited ?
                                                <> {item?.userVisited?.partnerCode || ''} - {item?.userVisited?.name || ''} </> :
                                                <>
                                                    {item?.partnerRegistrationProspecting?.partnerCode} - {item?.partnerRegistrationProspecting?.name}
                                                </>
                                            }
                                        </p>
                                        <p className="mb-0" style={{ lineHeight: '20px' }}>
                                            <b> <small> DATA DA VISITA </small> </b> <br /> {moment(item?.visitDate).format('DD/MM/YYYY HH:mm:ss')}
                                        </p>
                                    </td>
                                    <td className='text-center'>
                                        <p> <b> {!!item?.userVisited ? <span className="badge badge-danger"> Não </span> : <span className="badge badge-success"> Sim </span>} </b> </p>
                                    </td>
                                    <td className="text-center">
                                        {
                                            !item?.address && !item?.userVisited?.address ? (
                                                <>
                                                    <TooltipItem
                                                        position='top'
                                                        text={<span>
                                                            Endereço da visita e do parceiro não localizado.
                                                        </span>}
                                                    >
                                                        <span className="badge-dot mr-4 badge">
                                                            <i className="bg-warning" />
                                                            <span className="status text-sm text-gray">Atenção</span>
                                                        </span>
                                                    </TooltipItem>

                                                </>
                                            ) : (
                                                <>
                                                    {
                                                        item.divergence === 'S' ? (
                                                            <TooltipItem
                                                                position='top'
                                                                text={<span>Endereço do parceiro não está dentro do raio de distância permitido no endereço da visita.</span>}
                                                            >
                                                                <span className="badge-dot mr-4 badge">
                                                                    <i className="bg-danger" />
                                                                    <span className="status text-sm text-gray">Sim</span>
                                                                </span>
                                                            </TooltipItem>
                                                        ) : (
                                                            <span className="badge-dot mr-4 badge">
                                                                <i className="bg-success" />
                                                                <span className="status text-sm text-gray">Não</span>
                                                            </span>
                                                        )

                                                    }
                                                </>
                                            )
                                        }
                                    </td>
                                    <td className="text-center">
                                        {
                                            item?.visitDivergenceAssessment?.visitStatusDivergenceId === 1 ?
                                                (<p> <span className="badge badge-success"> {item?.visitDivergenceAssessment.visitsStatusDivergences?.name} </span> </p>) :
                                                item?.visitDivergenceAssessment?.visitStatusDivergenceId === 2 ?
                                                    (<p> <span className="badge badge-danger"> {item?.visitDivergenceAssessment.visitsStatusDivergences?.name} </span> </p>) :
                                                    (<p> <span className="badge badge-info"> Aguardando avaliação </span> </p>)
                                        }
                                    </td>
                                    <td className="text-center"> <p> {item?.visitDivergenceAssessment?.observationStatusDivergence} </p> </td>
                                    <td>
                                        <DropdownMenuTable key={key}>
                                            <Dropdown.Item className="border-radius-md py-2" onClick={() => { setShowModalDetail(true); setVisit(item || null) }}>
                                                <FontAwesomeIcon icon={faList} className="me-2" /> Detelhar divergência
                                            </Dropdown.Item>
                                        </DropdownMenuTable>
                                    </td>
                                </tr>
                            ))
                        }
                    </>
                ) : (
                    <tr>
                        <td colSpan={7} className="text-center text-sm">Não há registros</td>
                    </tr>
                )
                }
            </>
        );
    }

    return (

        <MasterPage
            title={'Visitas Divergentes'}
            icon={'fas fa-unlock'}
            footerFixed
            footerInverse
        >
            <div className="VisitsDivergencesAssessments" data-testid="VisitsDivergencesAssessments">
                <PageBase
                    title={'Visitas Divergentes'}
                    subtitle={'Gerenciamento de visitas divergentes.'}
                    content={renderContent()}
                    handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
                    hasFilter
                />
            </div>

            <SidebarFilterDivergenceDetail />

            <ModalDivergenceDetail
                show={showModalDetail}
                onClose={() => setShowModalDetail(false)}
                visit={visit}
            />
        </MasterPage>
    );
};

export default VisitsDivergencesAssessments;