import { Dispatch, FC, SetStateAction, useEffect, useRef } from "react";
import Tags, { InputMode } from "@yaireo/tagify/dist/react.tagify";
import { TagifySettings } from "@yaireo/tagify";
import "./Tagfy.scss";

interface TagfyProps {
  handleAdd?: any;
  handleRemove?: any;
  onChange?: any;
  allowDuplicate?: boolean;
  placeholder?: string;
  disabled?: boolean;
  blackList?: string[];
  defaultValue?: string[];
  validate?: boolean;
  regexValidate?: RegExp;
  regexTextWarning?: string;
  onInvalidTag?: any;
  tagToAddRemove?: string;
  setTagToAddRemove?: Dispatch<SetStateAction<string>>;
  whitelist?: string[];
  inputMode?: InputMode;
}

const Tagfy: FC<TagfyProps> = ({
  handleRemove,
  handleAdd,
  onChange,
  allowDuplicate = false,
  placeholder = 'Pressione "ENTER"',
  disabled = false,
  blackList = [],
  defaultValue = [],
  validate = true,
  regexValidate = null,
  regexTextWarning = "",
  onInvalidTag,
  tagToAddRemove,
  setTagToAddRemove,
  whitelist = [],
  inputMode = "input",
}) => {
  const settings: TagifySettings = {
    callbacks: {
      add: (e: any) => !!handleAdd && handleAdd(e.detail?.data?.value),
      remove: (e: any) => !!handleRemove && handleRemove(e.detail?.data?.value),
      input: (e: any) => !!onChange && onChange(e.detail?.value),
      invalid: (e: any) => !!onInvalidTag && onInvalidTag(e),
    },
    duplicates: allowDuplicate,
    blacklist: blackList,
    pattern: regexValidate,
    texts: {
      pattern: regexTextWarning,
    },
    enforceWhitelist: true,
  };

  const ref: any = useRef();

  useEffect(() => {
    if (
      !!tagToAddRemove &&
      ref?.current.getTagIndexByValue(tagToAddRemove)?.length > 0
    ) {
      ref?.current.removeTag(tagToAddRemove);
    } else {
      ref?.current.addTags(tagToAddRemove);
    }
    !!setTagToAddRemove && setTagToAddRemove("");
  }, [tagToAddRemove]);

  return (
    <div
      className={`Tagfy ${!validate ? "no-validate" : ""}`}
      data-testid="Tagfy"
    >
      <Tags
        tagifyRef={ref}
        className="form-control"
        placeholder={placeholder}
        settings={settings}
        readOnly={disabled}
        defaultValue={defaultValue}
        whitelist={whitelist || []}
        InputMode={inputMode || "input"}
      />
    </div>
  );
};

export default Tagfy;
