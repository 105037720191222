import React, { FC } from 'react';
import './Spinner.scss';

interface SpinnerProps {
  backgroundColor?: string,
  opacity?: string,
  position?: string,
  color?: string,
}

const Spinner: FC<SpinnerProps> = ({ backgroundColor = 'black', opacity = '0.7', position = 'position-fixed', color = 'var(--primary)' }) => (
  <>
    <div className={`spinner spinner-border text-center ${position}`} style={{ color: color }}></div>
    <div className={`Spinner ${position}`} data-testid="Spinner" style={{ backgroundColor: backgroundColor, opacity: opacity }}></div>
  </>
);

export default Spinner;
