import { FC, useEffect, useRef } from 'react';
import './SidebarInformative.scss';
import { useInformative } from '../../../providers/InformativeProvider';
import CardInformative from '../CardInformative/CardInformative';
import { Card, Col, Row } from 'react-bootstrap';
import { informatives } from '../../../enums/Informatives';

interface SidebarInformativeProps { }

const SidebarInformative: FC<SidebarInformativeProps> = () => {
  const { showInformative, setShowInformative } = useInformative();
  const wrapperRef = useRef<HTMLElement>(null);

  useEffect(() => {
    // Add event listener to the document object
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowInformative(false);
    }
  }

  useEffect(() => {
    let backdrop = document.getElementsByClassName('backdrop-violet-80')[0];

    if (backdrop) {
      if (showInformative) {
        backdrop.classList.remove('hide');
        setTimeout(() => { backdrop.classList.remove('hide-opacity') }, 20);
      } else {
        backdrop.classList.add('hide-opacity');
      }
    }
  }, [showInformative]);

  return (
    <aside
      ref={wrapperRef}
      className={`SidebarInformative fixed-plugin ${showInformative ? 'ps show' : ''}`}
      data-testid="SidebarInformative"
    >
      <Card className="shadow-lg">
        <Card.Header className="bg-transparent">
          <div className="float-start">
            <h5 className="m-0">Ver todas <i className="fa-solid fa-arrow-right ms-2"></i></h5>
          </div>
          <div className="float-end mt-0">
            <button className="btn btn-link text-violet-40 p-0 fixed-plugin-close-button" onClick={() => setShowInformative(false)}>
              <i className="fa fa-close"></i>
            </button>
          </div>
        </Card.Header>
        <Card.Body className="pt-sm-3 pt-0 overflow-auto">
          <Row className="mt-1">
            <Col md={12}>
              {informatives?.map((item: any) => (
                <CardInformative
                  message={item?.message}
                  readDate={item?.readDate}
                  sendDate={item?.endDate}
                  identify={item?.identify}
                  key={item?.id}
                />
              ))}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </aside>
  );
}

export default SidebarInformative;
