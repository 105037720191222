import { FC, ReactNode } from 'react';
import { Card } from 'react-bootstrap';
import './CardImport.scss';
import ReactSelect from 'react-select';
import { timeValues } from '../../../../enums/Proposals/Import/ProposalTimeEnum';
import { useProposalSituation } from '../../../../providers/Proposals/Import/ProposalSituationProvider';
import { useFinancial } from '../../../../providers/Registrations/Financial/FinancialProvider';
import { useProposalImportMonitor } from '../../../../providers/Proposals/Import/Monitor/ProposalImportMonitorProvider';

interface CardImportProps {
  title: string;
  icon: JSX.Element;
  isTransparent?: boolean;
  isDetail?: boolean;
  classNameHeader?: string;
  classNameBody?: string;
  children: ReactNode;
}

const CardImport: FC<CardImportProps> = ({ title, icon, children, classNameHeader, classNameBody, isTransparent = false, isDetail = true }) => {

  const { setParams, timeValueSelected, setTimeValueSelected } = useProposalSituation();

  const { setParams: setParamsFinancial, financialValueSelected, setFinancialValueSelected } = useProposalImportMonitor();

  const { financialsOptions } = useFinancial();

  return (
    <Card className={`CardImport ${isTransparent ? 'transparent' : ''}`} data-testid="CardImport">
      <Card.Header className={classNameHeader}>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5>{icon} <span dangerouslySetInnerHTML={{ __html: title }}></span></h5>
          </div>

          {isDetail && (
            <div className="row ">
              <div className="col-lg-6 col-12">
                <ReactSelect
                  name={'timeValues'}
                  isClearable
                  isSearchable
                  options={timeValues}
                  defaultValue={timeValueSelected}
                  placeholder='Período'
                  className={`form-control p-0`}
                  onChange={(e: any) => {
                    setTimeValueSelected(e);
                    setParams((current: any) => { return { ...current, timePeriod: e?.value } })
                    setFinancialValueSelected(e);
                    setParamsFinancial((current: any) => { return { ...current, timePeriod: e?.value } })

                  }}
                />
              </div>

              <div className="col-lg-6 col-12">
                <ReactSelect
                  name={'financialValues'}
                  isClearable
                  isSearchable
                  options={financialsOptions}
                  defaultValue={financialValueSelected}
                  placeholder='Financeira'
                  className={`form-control p-0`}
                  onChange={(e: any) => {
                    setFinancialValueSelected(e);
                    setParamsFinancial((current: any) => { return { ...current, externalFinancialId: e?.value } })
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </Card.Header>
      <Card.Body className={classNameBody}>
        {children}
      </Card.Body>
    </Card>
  );
}

export default CardImport;
