import React, { Dispatch, FC, SetStateAction } from 'react';
import './ReceiptTypePeriod.scss';
import ReceiptTypeDaily from '../ReceiptTypeDaily/ReceiptTypeDaily';
import ReceiptTypeWeekly from '../ReceiptTypeWeekly/ReceiptTypeWeekly';
import ReceiptTypeMonthly from '../ReceiptTypeMonthly/ReceiptTypeMonthly';
import ReceiptTypePredefined from '../ReceiptTypePredefined/ReceiptTypePredefined';
import ReceiptTypeUndefined from '../ReceiptTypeUndefined/ReceiptTypeUndefined';

interface ReceiptTypePeriodProps {
  receiptTypePeriod: number | null,
  receiptTypes: any,
  setReceiptTypes: any
  itemIdEdit: number | string | undefined,
}

const ReceiptTypePeriod: FC<ReceiptTypePeriodProps> = ({ receiptTypePeriod, receiptTypes, setReceiptTypes, itemIdEdit }) => (
  <div className="ReceiptTypePeriod" data-testid="ReceiptTypePeriod">
    {receiptTypePeriod === 1 && <ReceiptTypeDaily />}
    {receiptTypePeriod === 2 && <ReceiptTypeWeekly
      receiptTypes={receiptTypes}
      setReceiptTypes={setReceiptTypes}
      itemIdEdit={itemIdEdit} />}
    {receiptTypePeriod === 3 && <ReceiptTypeMonthly
      receiptTypes={receiptTypes}
      setReceiptTypes={setReceiptTypes}
      itemIdEdit={itemIdEdit} />}
    {receiptTypePeriod === 4 && <ReceiptTypePredefined
      receiptTypes={receiptTypes}
      setReceiptTypes={setReceiptTypes}
      itemIdEdit={itemIdEdit} />}
    {receiptTypePeriod === 5 && <ReceiptTypeUndefined />}
  </div>
);

export default ReceiptTypePeriod;
