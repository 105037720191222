import { FC, useEffect, useState } from "react";
import "./ChartParticipation.scss";
import { Card, Col, Row } from "react-bootstrap";
import PieChart from "../../../PieChart/PieChart";
import { nameAbbreviation } from "../../../../../../utils/NameAbbreviation";
import { useBiCovenant } from "../../../../../../providers/BI/Dashboard/Covenant/BiCovenantProvider";
import { useBiDashboard } from "../../../../../../providers/BI/Dashboard/BiDashboardProvider";
import { BI_VIEWS_ENUM } from "../../../../../../models/BI/Dashboard/BiProductionParamsModel";
import SkeletonCircle from "../../../../../SkeletonCircle/SkeletonCircle";

interface ChartParticipationProps { }

const ChartParticipation: FC<ChartParticipationProps> = () => {

  const [monthData, setMonthData] = useState<any[]>([]);
  const [annualData, setAnnualData] = useState<any[]>([]);
  const [isMonthLoading, setIsMonthLoading] = useState<boolean>(false);
  const [isAnnualLoading, setIsAnnualLoading] = useState<boolean>(false);

  const {
    covenantProductions,
    covenants,
    setCovenantProductions,
    setCovenants,
  } = useBiCovenant();

  const { handleListMonthlyProduction, handleListAnnualProduction, params } = useBiDashboard();

  useEffect(() => {
    setIsMonthLoading(true);
    setIsAnnualLoading(true);
    handleListMonthlyProduction({
      ...params,
      view: BI_VIEWS_ENUM.COVENANT,
      group: {}
    }).then((x: any) => {
      if (x) {
        setCovenantProductions(x);
      }
    }).finally(() => setIsMonthLoading(false));

    handleListAnnualProduction({
      ...params,
      view: BI_VIEWS_ENUM.COVENANT,
      group: {}
    }).then((x: any) => {
      if (x) {
        setCovenants(x);
      }
    }).finally(() => setIsAnnualLoading(false));
  }, [params]);

  useEffect(() => {
    if (covenantProductions?.items &&
      covenantProductions?.items?.length > 0 &&
      covenantProductions?.items[0]?.periods &&
      covenantProductions?.items[0]?.periods?.length > 0
    ) {
      let _data = covenantProductions?.items?.map((item) => item?.periods ?
        { value: Number(item?.periods[item?.periods?.length - 1].totalProductionPercentage), label: nameAbbreviation(item?.name ?? '', 13) } :
        {}) ?? [];
      _data = _data?.sort((a: any, b: any) => a?.value > b?.value ? -1 : 1)?.filter((x: any, index: number) => index <= 9);
      setMonthData(_data);
    }
  }, [covenantProductions]);

  useEffect(() => {
    if (covenants?.items &&
      covenants?.items?.length > 0 &&
      covenants?.items[0]?.periods &&
      covenants?.items[0]?.periods?.length > 0
    ) {
      let _data = covenants?.items?.map((item) => item?.periods ?
        { value: Number(item?.periods[item?.periods?.length - 1].totalProductionPercentage), label: nameAbbreviation(item?.name ?? '', 13) } :
        {}) ?? [];
      _data = _data?.sort((a: any, b: any) => a?.value > b?.value ? -1 : 1)?.filter((x: any, index: number) => index <= 9);
      setAnnualData(_data);
    }
  }, [covenants]);

  const renderMonthlyChart = () => {
    return (
      <Card className="h-100 shandow-xs-none">
        <Card.Header className="pb-0">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <h6 className="surtitle text-gray m-0 text-start">
                PARTICIPAÇÃO MENSAL
              </h6>
            </div>
            <div></div>
          </div>
        </Card.Header>
        <Card.Body>
          {!isMonthLoading ?
            <PieChart
              labels={monthData?.map((item) => item?.label)}
              data={monthData?.map((item) => item?.value?.toFixed(2))}
              // max={Math.trunc(Math.max.apply(Math, mock?.producoes?.map(o => o?.crescimento)))}
              height={450}
            /> :
            <SkeletonCircle width={300} />}
        </Card.Body>
      </Card>
    );
  };

  const renderAnnualChart = () => {
    return (
      <Card className="h-100 shandow-xs-none">
        <Card.Header className="pb-0">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <h6 className="surtitle text-gray m-0 text-start">
                PARTICIPAÇÃO ANUAL
              </h6>
            </div>
            <div></div>
          </div>
        </Card.Header>
        <Card.Body>
          {!isAnnualLoading ?
            <PieChart
              labels={annualData?.map((item) => item?.label)}
              data={annualData?.map((item) => item?.value?.toFixed(2))}
              // max={Math.trunc(Math.max.apply(Math, mock?.producoes?.map(o => o?.crescimento)))}
              height={450}
            /> :
            <SkeletonCircle width={300} />}
        </Card.Body>
      </Card>
    );
  };

  return (
    <div className="ChartParticipation" data-testid="ChartParticipation">
      <Row>
        <Col sm={12} md={6}>
          {renderMonthlyChart()}
        </Col>
        <Col sm={12} md={6}>
          {renderAnnualChart()}
        </Col>
      </Row>
    </div>
  );
};

export default ChartParticipation;
