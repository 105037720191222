import { FC, useEffect, useState } from 'react';
import './ModalAccessControlInsert.scss';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { schema } from './Validate';
import { useAuth } from '../../../../providers/AuthProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useAccessControl } from '../../../../providers/Marketplace/AccessControl/AccessControlProvider';
import ReactSelect from 'react-select';
import { useCompany } from '../../../../providers/Registrations/Company/CompanyProvider';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { useType } from '../../../../providers/Registrations/Partner/PartnerTypeProvider';
import { useAccessProfile } from '../../../../providers/Registrations/Access/AccessProfileProvider';
import InputSearchPartner from '../../../Registrations/Partner/InputSearchPartner/InputSearchPartner';

interface ModalAccessControlInsertProps {
  show: boolean;
  onClose: () => void;
}

const ModalAccessControlInsert: FC<ModalAccessControlInsertProps> = ({ show, onClose }) => {
  const [companySelected, setCompanySelected] = useState<any | null>(null);
  const [userTypeSelected, setUserTypeSelected] = useState<any | null>(null);
  const [accessLevelSelected, setAccessLevelSelected] = useState<any | null>(null);
  const [userId, setUserId] = useState<string>('');
  const [positiveSelected, setPositiveSelected] = useState<any | null>(null)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const { handleList, handleFetch, setError } = useAccessControl();
  const { handleGetAll, companysOptions } = useCompany();
  const { handleList: HandlelistType, typesOptions } = useType();
  const {
    handleList: handleListAccessProfile,
    profilesOptions,
    setParams: setParamsAccessProfile,
    params: paramsAccesProfile
  } = useAccessProfile();

  const { onInvalid } = useAuth();

  const { handleSubmit, setValue, control, reset, formState: { errors } } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setParamsAccessProfile({
      withPaginate: false
    })
  }, []);

  useEffect(() => {
    if (Object.keys(paramsAccesProfile).length > 0) {
      handleListAccessProfile();
    }
  }, [paramsAccesProfile]);

  useEffect(() => {
    handleGetAll();
    HandlelistType();
  }, []);

  useEffect(() => {
    setValue('promoter', companySelected?.externalId || null);
  }, [companySelected]);

  useEffect(() => {
    setValue('level', accessLevelSelected?.externalId || null);
  }, [accessLevelSelected]);

  useEffect(() => {
    setValue('agent_type', userTypeSelected?.externalId || null);
  }, [userTypeSelected]);


  useEffect(() => {
    setValue('positive', positiveSelected?.value);
  }, [positiveSelected]);


  const handleClose = () => {
    setError('');
    reset();
    setCompanySelected(null);
    setUserTypeSelected(null);
    setAccessLevelSelected(null);
    setUserId('');
    setPositiveSelected(null);
    onClose();
  }

  const onSubmit = async (data: any) => {
    setIsLoadingSubmit(true);
    setError('');


    data.promoter = data?.promoter ? companySelected?.externalId : null;
    data.agent_type = data?.agent_type ? userTypeSelected?.externalId : null;
    data.level = data?.level ? accessLevelSelected?.externalId : null;
    data.user_partner_id = data?.user_partner_id ? data?.user_partner_id?.partnerId : null;
    data.positive = data?.positive;

    const ret = await handleFetch(data);

    if (ret) {
      toast.success('Acesso de controle salvo com sucesso!');
      handleList();
      setError('');
      reset();
      setCompanySelected(null);
      setUserTypeSelected(null);
      setAccessLevelSelected(null);
      setUserId('');
      setPositiveSelected(null);
      onClose();
    }

    setIsLoadingSubmit(false);
  }

  const filteProfilesOptions = profilesOptions?.filter((x: any) => x?.isLevelDependent === 0);

  return (
    <ModalDefault
      title={'Adicionar Acesso de Controle'}
      show={show}
      onClose={handleClose}
      sizeModal={'lg'}
      showFooter={true}
      buttonText={isLoadingSubmit ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoadingSubmit}
      disabledSubmit={isLoadingSubmit}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalAccessControlInsert" data-testid="ModalAccessControlInsert">
        <Row className="mt-4">

          <Col md={6}>
            <FormGroup className="form-group">
              <label htmlFor="promoter">PROMOTORA</label>
              {control && (
                <Controller
                  name="promoter"
                  control={control}
                  render={({ field: { onChange, name, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      name={name}
                      isClearable
                      isSearchable
                      options={companysOptions}
                      placeholder="Selecione..."
                      className={`form-control p-0 ${onInvalid(errors?.promoter)}`}
                      value={companySelected || ''}
                      defaultValue={companySelected}
                      onChange={val => {
                        onChange(val?.externalId || '');
                        setCompanySelected(val);
                      }}
                      noOptionsMessage={() => 'Não há registros'}
                      styles={customStyles}
                    />
                  )}
                />
              )}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup className="form-group">
              <label htmlFor="agent_type">TIPO DO AGENTE</label>
              {control && (
                <Controller
                  name="agent_type"
                  control={control}
                  render={({ field: { onChange, name, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      name={name}
                      isClearable
                      isSearchable
                      options={typesOptions}
                      placeholder="Selecione..."
                      className={`form-control p-0 ${onInvalid(errors?.promoter)}`}
                      value={userTypeSelected || ''}
                      defaultValue={userTypeSelected}
                      onChange={val => {
                        onChange(val?.externalId || '');
                        setUserTypeSelected(val);
                      }}
                      noOptionsMessage={() => 'Não há registros'}
                      styles={customStyles}
                    />
                  )}
                />
              )}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup className="form-group">
              <label htmlFor="level">NÍVEL DE ACESSO</label>
              {control && (
                <Controller
                  name="level"
                  control={control}
                  render={({ field: { onChange, name, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      name={name}
                      isClearable
                      isSearchable
                      options={filteProfilesOptions}
                      placeholder="Selecione..."
                      className={`form-control p-0 ${onInvalid(errors?.promoter)}`}
                      value={accessLevelSelected || ''}
                      defaultValue={accessLevelSelected}
                      onChange={val => {
                        onChange(val?.externalId || '');
                        setAccessLevelSelected(val);
                      }}
                      noOptionsMessage={() => 'Não há registros'}
                      styles={customStyles}
                    />
                  )}
                />
              )}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="positive">EXCEÇÃO</label>
              <Controller
                control={control}
                name="positive"
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isClearable
                    isSearchable
                    options={[
                      { value: true, label: 'POSITIVA' },
                      { value: false, label: 'NEGATIVA' },
                    ]}
                    placeholder="Selecione..."
                    className={`form-control p-0 ${onInvalid(errors?.positive)}`}
                    value={positiveSelected || ''}
                    defaultValue={positiveSelected}
                    onChange={val => {
                      onChange(val?.value || '');
                      setPositiveSelected(val);
                    }}
                    styles={customStyles}
                  />
                )}
              />
              <ErrorMessage name="Exceção" type={errors?.positive?.type} />
            </FormGroup>
          </Col>

          <Col xl={6}>
            <FormGroup className={`form-group ${errors?.promoter ? 'has-error' : ''}`}>
              <label htmlFor="user_partner_id">PARCEIRO</label>
              <InputSearchPartner
                name="user_partner_id"
                control={control}
                setValue={setValue}
                valuePartner={userId}
                setValuePartner={setUserId}
              />
            </FormGroup>
          </Col>
          <ErrorMessage name="Global" isArray min={1} type={errors?.promoter?.message} />
        </Row>

      </div>
    </ModalDefault>
  );
}

export default ModalAccessControlInsert;
