import * as yup from "yup";

export const schema = yup.object().shape({
    period: yup
        .object()
        .required(),
    accessProfileId: yup
        .object()
        .required(),
    file: yup
        .string()
        .required()
});