import api from "../Api";
import { BaseService } from "../BaseService";

const URL = 'api/user';

export class UserCommercialService extends BaseService {

    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/commercial`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listRegional(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/regional`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listExecutive(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/executive`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

}