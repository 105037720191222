import { FC, useEffect, useMemo, useState } from "react";
import "./DebtorByExecutive.scss";
import { Card, Col, Row } from "react-bootstrap";
import { useBiExecutive } from "../../../../../providers/BI/Dashboard/Executive/BiExecutiveProvider";
import AppDataTable from "../../../../AppDataTable/AppDataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import FormatMoney from "../../../../../utils/FormatMoney";
import { useBiDashboard } from "../../../../../providers/BI/Dashboard/BiDashboardProvider";
import { BI_VIEWS_ENUM } from "../../../../../models/BI/Dashboard/BiProductionParamsModel";
import SkeletonTable from "../../../../SkeletonTable/SkeletonTable";

interface DebtorByExecutiveProps {}

const DebtorByExecutive: FC<DebtorByExecutiveProps> = () => {
  const [columns, setColumns] = useState<any[]>([]);
  const [hideButtons, setHideButtons] = useState<any[]>([]);

  const { executivesDebtor, setExecutivesDebtor } = useBiExecutive();
  const {
    params,
    handleListDebtor,
    isLoading,
    setParams,
    setHandleListOnFilter,
    resultOnFilter,
    setResultOnFilter,
    generalErrorMsg,
    isParamsUpdated,
    setIsParamsUpdated,
    handleExcel,
  } = useBiDashboard();

  useEffect(() => {
    mountHideButtons();
  }, [executivesDebtor]);

  useEffect(() => {
    if (isParamsUpdated) {
      setHandleListOnFilter(() => handleListDebtor);
      setParams({
        ...params,
        group: {
          branch: true,
        },
        view: BI_VIEWS_ENUM.EXECUTIVE,
      });
      if (!executivesDebtor) {
        handleFetch().then();
      }
      setIsParamsUpdated(false);
    }
  }, [isParamsUpdated]);

  useEffect(() => {
    if (resultOnFilter) {
      setExecutivesDebtor(resultOnFilter);
      setResultOnFilter(undefined);
    }
  }, [resultOnFilter]);

  const handleFetch = async () => {
    const result = await handleListDebtor({
      ...params,
      group: {
        branch: true,
      },
      view: BI_VIEWS_ENUM.EXECUTIVE,
    }).then();

    if (result) {
      setExecutivesDebtor(result);
    }
  };

  const handleExport = (rows: any) => {
    const _data: any[] = [];
    rows.forEach((item: any) => {
      const _row: any = {
        Filial: item.name,
        Total: item.totalQuantity,
        Ativos: item.activeQuantity,
        Inativos: item.inactiveQuantity,
        Valor: item.totalDebt,
        "Participacao Ativos": item.totalDebtActivePercentage,
        "Participacao inativos": item.totalDebtInactivePercentage,
      };
      _data.push(_row);
    });
    handleExcel(_data, "Devedores por executivo");
  };

  const hasData = (): boolean => {
    if (
      executivesDebtor?.items &&
      executivesDebtor?.items?.length > 0 &&
      executivesDebtor?.items[0]?.group &&
      executivesDebtor?.items[0]?.group?.length > 0 &&
      executivesDebtor?.items[0]?.group[0]?.periods &&
      executivesDebtor?.items[0]?.group[0]?.periods?.length > 0
    ) {
      return true;
    }
    return false;
  };

  const mountColumns = useMemo(() => {
    if (hasData()) {
      const _columns: any = [
        {
          id: "colIndex",
          name: <div className="bg-info text-table text-uppercase">Filial</div>,
          selector: (data: any) => data?.name,
          cell: (data: any) => (
            <div className="text-wrap text-bold">{data?.name}</div>
          ),
          sortable: true,
          width: "250px",
        },
        {
          name: <div className="bg-info text-table text-uppercase">Total</div>,
          selector: (data: any) => data?.totalQuantity,
          sortable: true,
          center: true,
          omit: !hideButtons?.find((x: any) => x.alias === "totalQuantity")
            ?.show,
        },
        {
          name: <div className="bg-info text-table text-uppercase">Ativos</div>,
          selector: (data: any) => data?.activeQuantity,
          sortable: true,
          center: true,
          omit: !hideButtons?.find((x: any) => x.alias === "activeQuantity")
            ?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">Inativos</div>
          ),
          selector: (data: any) => data?.inactiveQuantity,
          sortable: true,
          center: true,
          omit: !hideButtons?.find((x: any) => x.alias === "inactiveQuantity")
            ?.show,
        },
        {
          name: <div className="bg-info text-table text-uppercase">Valor</div>,
          selector: (data: any) => data?.totalDebt,
          right: true,
          cell: (data: any) => (
            <div className="text-nowrap">{FormatMoney(data?.totalDebt)}</div>
          ),
          sortable: true,
          omit: !hideButtons?.find((x: any) => x.alias === "totalDebt")?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Participação <br />
              Ativos
            </div>
          ),
          selector: (data: any) => data?.totalDebtActivePercentage,
          right: true,
          cell: (data: any) => (
            <div>{FormatMoney(data.totalDebtActivePercentage, false)}%</div>
          ),
          sortable: true,
          omit: !hideButtons?.find(
            (x: any) => x.alias === "totalDebtActivePercentage"
          )?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Participação <br />
              Inativos
            </div>
          ),
          selector: (data: any) => data?.totalDebtInactivePercentage,
          right: true,
          cell: (data: any) => (
            <div>{FormatMoney(data.totalDebtInactivePercentage, false)}%</div>
          ),
          sortable: true,
          omit: !hideButtons?.find(
            (x: any) => x.alias === "totalDebtInactivePercentage"
          )?.show,
        },
      ];

      setColumns(_columns);
    }
  }, [executivesDebtor, hideButtons]);

  const mountRows = (items: any[]) => {
    const _rows: any = [];

    items.forEach((item) => {
      const _row: any = {
        name: item?.name,
      };

      item?.periods?.forEach((period: any) => {
        _row.totalQuantity = period?.totalQuantity;
        _row.activeQuantity = period?.activeQuantity;
        _row.inactiveQuantity = period?.inactiveQuantity;
        _row.totalDebt = period?.totalDebt;
        _row.totalDebtActivePercentage = period?.totalDebtActivePercentage;
        _row.totalDebtInactivePercentage = period?.totalDebtInactivePercentage;
      });

      _rows.push(_row);
    });

    return _rows;
  };

  const mountHideButtons = () => {
    if (hasData()) {
      const _buttons: any = [];
      executivesDebtor?.items![0]?.group![0]?.periods?.forEach((item) => {
        _buttons.push({
          alias: `totalQuantity`,
          label: "Total",
          show: true,
        });
      });

      _buttons.push({
        alias: `activeQuantity`,
        label: "Ativos",
        show: true,
      });

      _buttons.push({
        alias: `inactiveQuantity`,
        label: "Inativos",
        show: true,
      });

      _buttons.push({
        alias: `totalDebt`,
        label: "Valor",
        show: true,
      });

      _buttons.push({
        alias: `totalDebtActivePercentage`,
        label: "Participação Ativos",
        show: true,
      });

      _buttons.push({
        alias: `totalDebtInactivePercentage`,
        label: "Participação Inativos",
        show: true,
      });

      setHideButtons(_buttons);
    }
  };

  const onClickHideButton = (button: any, index: number) => {
    const _hideButtons = [...hideButtons];
    button.show = !button.show;
    _hideButtons[index] = button;
    setHideButtons(_hideButtons);
  };

  const renderTable = (x: any) => {
    const rows = mountRows(x?.group);

    return (
      <Card className="mt-4" key={x?.name}>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center w-100 mb-2">
            <span className="text-start text-primary pb-1">{`${x?.name} - ${x?.partnerCode}`}</span>
            <button
              className="btn bg-success text-white rounded m-0 ms-5"
              onClick={() => {
                handleExport(rows);
              }}
            >
              <i className="fas fa-file-excel" /> Exportar
            </button>
          </div>
          <AppDataTable columns={columns} rows={rows} pagination={false} />
        </Card.Body>
      </Card>
    );
  };

  return (
    <div className="DebtorByExecutive" data-testid="DebtorByExecutive">
      {!isLoading ? (
        <>
          <Col md={12} className="d-flex justify-content-end">
            <Row className="display-button mb-3">
              <div className="d-flex justify-content-between align-items-center flex-md-row">
                <div className="d-flex gap-1 justify-content-center align-items-center flex-md-row">
                  {hideButtons?.map((x: any, index: number) => (
                    <button
                      type="button"
                      className="btn bg-primary text-white mb-0 ms-2 min-width-btn text-capitalize"
                      onClick={() => onClickHideButton(x, index)}
                    >
                      <FontAwesomeIcon
                        icon={x.show ? faEye : faEyeSlash}
                        className="me-1"
                      />
                      {x.label}
                    </button>
                  ))}
                </div>
              </div>
            </Row>
          </Col>

          <Col sm={12}>
            <div
              style={{ zIndex: "auto" }}
              className="d-flex justify-content-center align-items-center alert alert-primary text-table mt-3"
            >
              <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
              Atenção: Este relatório apresenta estatísticas atualizadas e não
              inclui filtros de datas.
            </div>
          </Col>

          {executivesDebtor?.items ? (
            executivesDebtor?.items?.map((item: any) => renderTable(item))
          ) : (
            <div className="d-flex mt-9 justify-content-center general-error-msg">
              {generalErrorMsg}
            </div>
          )}
        </>
      ) : (
        <SkeletonTable />
      )}
    </div>
  );
};

export default DebtorByExecutive;
