import { ProposalExportFormFilterParams } from "../../../models/Proposals/Export/ProposalExportFormFilterParams";
import api from "../../Api";
import { BaseService } from "../../BaseService";
import FileSaver from 'file-saver';

const URL = 'api/report/proposal';

export class ProposalExportService extends BaseService {
    async exportSynthetic(params: ProposalExportFormFilterParams): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/exportSynthetic`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async exportResume(params: ProposalExportFormFilterParams): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/exportResume`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async exportResume2(params: ProposalExportFormFilterParams): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/exportResume2`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async exportTyping(params: ProposalExportFormFilterParams): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/exportTyping`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async export(params: ProposalExportFormFilterParams): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/export`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}