import * as yup from "yup";

export const schema = yup.object().shape({
    name: yup
        .string()
        .trim()
        .min(3)
        .max(150)
        .required(),
    typing: yup
        .object()
        .required(),
    national: yup
        .object()
        .required(),
    proposalsGroupId: yup
        .object()
        .required(),
    region: yup
        .object()
        .nullable()
});