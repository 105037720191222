import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { ProposalConfigDetailParams } from "../../../models/Proposals/Config/ProposalConfigDetailParams";
import { ProposalConfigDetailModel } from "../../../models/Proposals/Config/ProposalConfigDetailModel";
import { ProposalConfigDetailService } from "../../../services/Proposals/Config/ProposalConfigDetailService";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { ToastSettings } from "../../../utils/ToastSettings";
import { boolean } from "yup";

interface ContextProps {
    config: ProposalConfigDetailModel|null,
    configs: ProposalConfigDetailModel[],
    setConfig: Dispatch<SetStateAction<ProposalConfigDetailModel|null>>,
    setParams: Dispatch<SetStateAction<ProposalConfigDetailParams>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleListFieldGroup: (data:any) => Promise<boolean>,
    updateFieldGroup: (data:any) => Promise<boolean>,
}

export const ProposalConfigDetailContext = createContext<ContextProps>({} as ContextProps);

interface ProposalConfigDetailProviderProps {
    children: ReactNode;
}

export const ProposalConfigDetailProvider = ({ children }: ProposalConfigDetailProviderProps) => {
    
    const [config, setConfig]       = useState<ProposalConfigDetailModel|null>(null);
    const [configs, setConfigs]     = useState<ProposalConfigDetailModel[]>([]);
    const [params, setParams]       = useState<ProposalConfigDetailParams>({ page: 1 });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError]         = useState<string>('');

    const service                   = new ProposalConfigDetailService()
    // const location                  = useLocation();

    const handleListFieldGroup = async (data:any) => {
        try {

            const [_Response, _Error, _Code, _Errors] = await service.list(data);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            setConfigs(_Response.data);
        
            return true;
        }
        catch(e:any) {
            setError('Não foi possível carregar os cabeçalhos');
            return false;
        }
    };

    const updateFieldGroup = async (data:any) => {
        try {

            const [_Response, _Error, _Code, _Errors] = await service.update(data);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
        
            ToastSettings('Grupo de cabeçalho inserido com sucesso!', 'bottom-center', 'success');

            handleListFieldGroup({financialId: data?.financialId});

            return true;
        }
        catch(e:any) {
            setError('Não foi possível carregar os cabeçalhos');
            return false;
        }
    }

    useEffect(() => {
        setError('');
    }, []);

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    return (
        <ProposalConfigDetailContext.Provider value={{
            config,
            configs, 
            setConfig, 
            setParams,
            isLoading,
            error,
            setError,
            handleListFieldGroup,
            updateFieldGroup
        }}>
            {children}
        </ProposalConfigDetailContext.Provider>
    );
}

export const useProposalConfigDetail = () => useContext(ProposalConfigDetailContext);