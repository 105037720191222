import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { BiAnnualProductionModel } from "../../../models/BI/Dashboard/BiAnnualProductionModel";
import { BiMonthlyProductionModel } from "../../../models/BI/Dashboard/BiMonthlyProductionModel";
import { BiDebtorsModel } from "../../../models/BI/Dashboard/BiDebtorsModel";
import { BiStatusProductionModel } from "../../../models/BI/Dashboard/BiStatusProductionModel";

interface ContextProps {
  monthlyProductionPartners: BiMonthlyProductionModel | undefined;
  setMonthlyProductionPartners: Dispatch<
    SetStateAction<BiMonthlyProductionModel | undefined>
  >;
  annualProductionPartners: BiAnnualProductionModel | undefined;
  setAnnualProductionPartners: Dispatch<
    SetStateAction<BiAnnualProductionModel | undefined>
  >;
  partnerProductionByBranch: BiMonthlyProductionModel | undefined;
  setPartnerProductionByBranch: Dispatch<
    SetStateAction<BiMonthlyProductionModel | undefined>
  >;
  debtorsReportByBranch: BiDebtorsModel | undefined;
  setDebtorsReportByBranch: Dispatch<
    SetStateAction<BiDebtorsModel | undefined>
  >;
  partnerStatusReportByBranch: BiStatusProductionModel | undefined;
  setPartnerStatusReportByBranch: Dispatch<
    SetStateAction<BiStatusProductionModel | undefined>
  >;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
}

export const BiPartnerContext = createContext<ContextProps>({} as ContextProps);

interface BiPartnerProviderProps {
  children: ReactNode;
}

export const BiPartnerProvider = ({ children }: BiPartnerProviderProps) => {
  const [monthlyProductionPartners, setMonthlyProductionPartners] =
    useState<BiMonthlyProductionModel>();
  const [annualProductionPartners, setAnnualProductionPartners] =
    useState<BiAnnualProductionModel>();
  const [partnerProductionByBranch, setPartnerProductionByBranch] =
    useState<BiMonthlyProductionModel>();
  const [debtorsReportByBranch, setDebtorsReportByBranch] =
    useState<BiDebtorsModel>();
  const [partnerStatusReportByBranch, setPartnerStatusReportByBranch] =
    useState<BiStatusProductionModel>();
  const [error, setError] = useState<string>("");

  useEffect(() => {
    !!error && toast.error(error);
  }, [error]);

  return (
    <BiPartnerContext.Provider
      value={{
        monthlyProductionPartners,
        setMonthlyProductionPartners,
        annualProductionPartners,
        setAnnualProductionPartners,
        partnerProductionByBranch,
        setPartnerProductionByBranch,
        debtorsReportByBranch,
        setDebtorsReportByBranch,
        partnerStatusReportByBranch,
        setPartnerStatusReportByBranch,
        error,
        setError,
      }}
    >
      {children}
    </BiPartnerContext.Provider>
  );
};

export const useBiPartner = () => useContext(BiPartnerContext);
