import React, { FC, useState } from 'react';
import { Button, Card, Col, FormGroup, Row, Spinner } from 'react-bootstrap';
import InputDropzone from '../../../InputDropzone/InputDropzone';
import './ModalEasyPortInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useForm } from 'react-hook-form';
import { EasyPortModel } from '../../../../models/Marketplace/EasyPort/EasyPortModel';
import { useEasyPort } from '../../../../providers/Marketplace/EasyPort/EasyPortProvider';
import ReactInputMask from 'react-input-mask';

interface ModalEasyPortInsertProps {
  show: boolean,
  onClose: any,
}

const DefaultFormValuesCreate: EasyPortModel = {
  paycheck_file: '',
  cpf: '',
}

const ModalEasyPortInsert: FC<ModalEasyPortInsertProps> = ({show, onClose}) => {
  const [files, setFiles] = useState<any[]>([]);
  const [fileTypes, setFileTypes] = useState<any>({
    'application/pdf': ['.pdf'],
  });

  const {
      reset: resetCreate,
      register: registerCreate,
      setValue: setValueCreate,
      handleSubmit: handleSubmitCreate,
      control: controlCreate,
      formState: { errors: errorsCreate }
  } = useForm<EasyPortModel>({
      defaultValues: DefaultFormValuesCreate
  });

  const { handleCreateEasyPort} = useEasyPort();

  const handleCloseCreate = () => {
      resetCreate();
      onClose();
  }

  const onCreateSubmit = async (data: EasyPortModel) => {
    const _data = JSON.parse(JSON.stringify(data));
    const response = await handleCreateEasyPort(_data, files[0]).then();
    response && resetCreate(); onClose();
  }

  return (
    <>
    <ModalDefault
      title={"Inserir Simulação Port. Fácil"}
      show={show}
      onClose={handleCloseCreate}
      sizeModal={'md'}
      showFooter={true}
      handleAdditionalSubmit={handleSubmitCreate(onCreateSubmit)}
      buttonTextAdditional='Simular'
      buttonAdditionalBackgroundColor='btn-secondary'
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <Row className="ImportCampaign mb-2" data-testid="ImportCampaign">
        <Card>
          <Card.Header className="pt-3 pb-0 px-2">
          </Card.Header>
          <Card.Body className="py-1 mb-3">
            <FormGroup className="form-group">
              <label htmlFor="neighborhood">CPF</label>
              <ReactInputMask
                  className={`form-control `}
                  mask="999.999.999-99"
                  {...registerCreate('cpf')}
              />
            </FormGroup>
            <InputDropzone 
              label="Contracheque"
              name="file"
              files={files}
              setFiles={setFiles}
              types={fileTypes}
              maxSize={157286400}
            />
          </Card.Body>
        </Card>
      </Row>
    </ModalDefault>
    </>
  )
};

export default ModalEasyPortInsert;
