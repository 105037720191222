export function NormalizesFieldNames(name: string): string {
    let newName = '';

    if (name !== name.toUpperCase()) {
        name.split('').forEach((char: string) => {
            if (/^(?=[A-Z])[A-Z\s]+$/.test(char)) {
                newName += ` ${char}`;
            } else {
                newName += char;
            }
        });
    } else {
        newName = name;
    }

    newName = newName.replace('Usu', 'Usuário');
    newName = newName.replace('qtd', 'Quantidade');
    newName = newName.replace('Cad', 'Cadastro');
    newName = newName.replace('Int', 'Interno');
    newName = newName.replace('vlr', 'Valor');
    newName = newName.replace('descricao', 'Descrição');

    return newName.charAt(0).toUpperCase() + newName.slice(1);
}