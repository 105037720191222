import React, { FC, useEffect, useState } from 'react';
import './FormPasswordVisits.scss';
import { TabEnumIndicatorManagement } from '../../../../pages/Visits/IndicatorManagement/IndicatorManagement';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useIndicatorManagementPasswordVisits } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementPasswordProvider';
import { toast } from 'react-toastify';
import { IndicatorManagementPasswordModel } from '../../../../models/Visits/IndicatorManagement/IndicatorManagementPasswordModel';
import PasswordVisitsList from './PasswordVisitsList/PasswordVisitsList';

interface FormPasswordVisitsProps {
  currentActiveTab?: TabEnumIndicatorManagement;
}

export enum TabEnumPasswordIndicatorManagement {
  CREATE_FINISHED = 'create_finished',
  RESET_FINISHED = 'reset_finished',
  CREATE_IN_PROGRESS = 'create_in_progress',
  RESET_IN_PROGRESS = 'reset_in_progress',
  REQUEST = 'request'
}

const FormPasswordVisits: FC<FormPasswordVisitsProps> = ({ currentActiveTab }) => {
  const [currentNav, setCurrentNav] = useState<string>(TabEnumPasswordIndicatorManagement.CREATE_FINISHED);
  const [previousNav, setPreviousNav] = useState<string>();
  const [dataCreatedConcluded, setDataCreatedConcluded] = useState<IndicatorManagementPasswordModel[]>([]);
  const [dataCreatedInProgress, setDataCreatedInProgress] = useState<IndicatorManagementPasswordModel[]>([]);
  const [dataResetConcluded, setDataResetConcluded] = useState<IndicatorManagementPasswordModel[]>([]);
  const [dataResetInProgress, setDataResetInProgress] = useState<IndicatorManagementPasswordModel[]>([]);
  const [dataRequest, setDataRequest] = useState<IndicatorManagementPasswordModel[]>([]);

  const {
    params,
    error,
    setError,
    createdConcluded,
    createdInProgress,
    resetConcluded,
    resetInProgress,
    request,
    handleCreatedConcluded,
    handleCreatedInProgress,
    handleRequest,
    handleResetConcluded,
    handleResetInProgress
  } = useIndicatorManagementPasswordVisits();

  useEffect(() => {
    if (currentActiveTab === TabEnumIndicatorManagement.PASSWORD) {
      switch (currentNav) {
        case TabEnumPasswordIndicatorManagement.CREATE_FINISHED:
          handleCreatedConcluded(currentNav !== previousNav).then();
          setPreviousNav(currentNav);
          break;
        case TabEnumPasswordIndicatorManagement.CREATE_IN_PROGRESS:
          handleCreatedInProgress(currentNav !== previousNav).then();
          setPreviousNav(currentNav);
          break;
        case TabEnumPasswordIndicatorManagement.RESET_FINISHED:
          handleResetConcluded(currentNav !== previousNav).then();
          setPreviousNav(currentNav);
          break;
        case TabEnumPasswordIndicatorManagement.RESET_IN_PROGRESS:
          handleResetInProgress(currentNav !== previousNav).then();
          setPreviousNav(currentNav);
          break;
        case TabEnumPasswordIndicatorManagement.REQUEST:
          handleRequest(currentNav !== previousNav).then();
          setPreviousNav(currentNav);
          break;
        default:
          break;
      }
    }
  }, [currentNav, currentActiveTab, params]);

  useEffect(() => {
    setDataCreatedConcluded(createdConcluded)
  }, [createdConcluded]);

  useEffect(() => {
    setDataCreatedInProgress(createdInProgress);
  }, [createdInProgress]);

  useEffect(() => {
    setDataResetConcluded(resetConcluded)
  }, [resetConcluded]);

  useEffect(() => {
    setDataResetInProgress(resetInProgress)
  }, [resetInProgress]);

  useEffect(() => {
    setDataRequest(request);
  }, [request]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError('');
    }
  }, [error]);

  return (
    <div className="FormPasswordVisits" data-testid="FormPasswordVisits">
      <Row md={12}>
        <Col>
          <Tabs
            defaultActiveKey={TabEnumPasswordIndicatorManagement.CREATE_FINISHED}
            className="mb-3"
            variant='pills'
            fill
            onSelect={(e: any) => setCurrentNav(e)}
          >
            <Tab eventKey={TabEnumPasswordIndicatorManagement.CREATE_FINISHED} title="CRIAÇÃO - CONCLUÍDO - 7 DIAS">
              <PasswordVisitsList activeTab={currentNav as TabEnumPasswordIndicatorManagement} data={dataCreatedConcluded} />
            </Tab>
            <Tab eventKey={TabEnumPasswordIndicatorManagement.RESET_FINISHED} title="RESET - CONCLUÍDO - 7 DIAS">
              <PasswordVisitsList activeTab={currentNav as TabEnumPasswordIndicatorManagement} data={dataResetConcluded} />
            </Tab>
            <Tab eventKey={TabEnumPasswordIndicatorManagement.CREATE_IN_PROGRESS} title="CRIAÇÃO - EM ANDAMENTO">
              <PasswordVisitsList activeTab={currentNav as TabEnumPasswordIndicatorManagement} data={dataCreatedInProgress} />
            </Tab>
            <Tab eventKey={TabEnumPasswordIndicatorManagement.RESET_IN_PROGRESS} title="RESET - EM ANDAMENTO">
              <PasswordVisitsList activeTab={currentNav as TabEnumPasswordIndicatorManagement} data={dataResetInProgress} />
            </Tab>
            <Tab eventKey={TabEnumPasswordIndicatorManagement.REQUEST} title="SOLICITAÇÃO">
              <PasswordVisitsList activeTab={currentNav as TabEnumPasswordIndicatorManagement} data={dataRequest} />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </div>
  )
};

export default FormPasswordVisits;
