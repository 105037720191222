import { FC, useState, useEffect } from 'react';
import './Receipt.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faReceipt, faTrash, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { useReceipt } from '../../../providers/Commissioning/Receipt/ReceiptProvider';
import ModalReceiptInsert from '../../../components/Commissioning/Receipt/ModalReceiptInsert/ModalReceiptInsert';
import { ReceiptModel } from '../../../models/Commissioning/Receipt/ReceiptModel';
import ModalLowReceiptInsert from '../../../components/Commissioning/Receipt/ModalLowReceiptInsert/ModalLowReceiptInsert';
import { EventInput } from '@fullcalendar/core';
import SidebarFilter from '../../../components/SidebarFilter/SidebarFilter';
import SidebarReceiptFilter from '../../../components/Commissioning/Receipt/SidebarReceiptFilter/SidebarReceiptFilter';
import { StatusReceiptColor } from '../../../components/Commissioning/Receipt/Rule/LowReceiptInsert/StatusReceiptEnum'
import Spinner from '../../../components/Spinner/Spinner';
import moment from 'moment';
import FormatMoney from '../../../utils/FormatMoney';
import { useCampaign } from '../../../providers/Commissioning/Campaign/CampaignProvider';
import { Col, Row } from 'react-bootstrap';
import { nameAbbreviation } from '../../../utils/NameAbbreviation';
import { PerTypeModel } from '../../../models/Commissioning/Receipt/PerTypeModel';

interface ReceiptProps { }

const Receipt: FC<ReceiptProps> = () => {

  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
  const [showModalLowReceiptInsert, setShowModalLowReceiptInsert] = useState<boolean>(false);
  const [receiptId, setReceiptId] = useState<number>(0);
  const [receiptDate, setReceiptDate] = useState<string>('');
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const {
    isLoading,
    error,
    receipts,
    handleList,
    handleListReceiptType,
    handleListReceiptTypePeriod,
    handleDelete,
    handleExcel,
    isLoadingFile,
    receiptsTypePeriod,
    setParams,
    params,
    perType,
    setParamsPerType
  } = useReceipt();
  const { handleListCampaignsOptions: handleListCampaigns } = useCampaign();


  useEffect(() => {
    (!!params?.startDate && !!params?.endDate) && handleList();
  }, [params]);

  useEffect(() => {
    handleListReceiptTypePeriod();
    handleListReceiptType();
  }, []);

  useEffect(() => {
    setReceiptId(0);
    setReceiptDate('');
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const isMobile = windowWidth <= 767;

  const handleDeleteReceipt = (receiptId: number, title: string) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja excluir o recebimento ${title}?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleDelete(receiptId || 0).then();
        if (ret) {
          handleList();
          handleListCampaigns();
          toast.success('Recebimento excluido com sucesso!');
        } else {
          await Swal.fire('Ops!', `Não foi possível excluir o recebimento.<br>${error}`, 'error');
        }
      }
    });
  }

  const handleShowModalLowReceiptInsert = (receiptId: number, receiptDate: string) => {
    setReceiptId(receiptId);
    setReceiptDate(receiptDate);
    if (receiptId > 0) {
      setShowModalLowReceiptInsert(true);
    }
  };

  const handleShowModalReceiptInsert = (receiptId: number, receiptDate: string) => {
    setReceiptId(receiptId);
    setReceiptDate(receiptDate);
    if (receiptId > 0) {
      setShowModalInsert(true);
    }
  };

  const events = (receipts && receipts?.length > 0) ?
    receipts?.flatMap((receipt: ReceiptModel) =>
      receipt?.itens?.flatMap((item: ReceiptModel) =>
        item?.receipts?.map((receiptFinancials: ReceiptModel) => ({
          title: receiptFinancials?.name,
          start: receipt?.date,
          resourceId: item?.type,
          allDay: true,
          externalId: receiptFinancials?.externalId,
          receiptId: receiptFinancials?.id,
          receiptTypePeriod: receiptFinancials?.receiptTypePeriodId,
          receiptDate: receipt?.date,
          writeOffTotalAmount: receiptFinancials?.writeOffTotalAmount,
          previsionTotalAmount: receiptFinancials?.previsionTotalAmount,
          status: receiptFinancials?.status,
          genarateReceiptId: receiptFinancials?.receiptUnknown?.id,
          color: receiptFinancials?.status !== undefined ? StatusReceiptColor(receiptFinancials?.status) : '#dee2e6'
        }))
      )
    ) : [];

  const filteredEvents = events?.filter(event => event !== undefined) as EventInput[];

  const uniqueTypes = new Set<string>();
  const resources = (receipts && receipts?.length > 0) ?
    receipts?.flatMap((receipt: ReceiptModel) => {
      return receipt?.itens?.flatMap((item: ReceiptModel) => {
        if (item?.type !== undefined && !uniqueTypes.has(item?.type)) {
          uniqueTypes.add(item?.type);
          const matchingPerType = perType?.find((x: PerTypeModel) => x?.type === item?.type);
          const financials = matchingPerType ? matchingPerType?.financials?.map((x: any) => ({
            financial: x?.name,
            previsionTotalAmount: x?.prevision,
            writeOffTotalAmount: x?.writeOff
          })) : [];

          return [{
            id: item?.type,
            title: item?.type?.toUpperCase(),
            financials: financials
          }];
        }
        return [];
      });
    }) : [];

  const filteredResources = resources?.filter(resource => resource !== undefined) as { id: string; title: string; financials: any[] }[];

  const renderResource = (resource: any) => {
    return (
      <div className="resource-container mb-2">
        <div>
          <h6 className="font-weight-bold">{resource?.title}</h6>
        </div>
        <ul className="list-group list-group-flush">
          {resource?.extendedProps?.financials?.map((x: any) => (
            <li key={x?.financial} className="list-group-item px-3 py-2">
              <Row className="align-items-center">
                <Col>
                  <p className="text-xs font-weight-bold mb-0">Financeira</p>
                  <span className="text-sm mb-0">{nameAbbreviation(x?.financial?.toUpperCase(), 10)}</span>
                </Col>
                <Col className="text-center">
                  <p className="text-xs font-weight-bold mb-0">Previsão</p>
                  <span className="text-sm mb-0">{FormatMoney(x?.previsionTotalAmount)}</span>
                </Col>
                <Col className="text-center">
                  <p className="text-xs font-weight-bold mb-0">Recebimento</p>
                  <span className="text-sm mb-0">{FormatMoney(x?.writeOffTotalAmount)}</span>
                </Col>
              </Row>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderNameReceiptTypePeriod = (receiptTypePeriod: number | undefined) => {
    const typePeriod = receiptsTypePeriod?.find(x => x?.id === receiptTypePeriod);
    return typePeriod ? typePeriod?.name : '';
  }

  const renderButtonEvent = (e: any) => {
    const { event } = e;

    const lengthNameFinancial = 20;
    const truncatedNameFinancial = event.title.length > lengthNameFinancial ? event.title.substring(0, lengthNameFinancial) + "..." : event.title;

    return (
      <div className="d-flex justify-content-center align-items-center custom-event">
        <div className="d-flex justify-content-end align-items-center gap-3 card-body" onClick={(e) => {
          e.stopPropagation();
          handleShowModalLowReceiptInsert(event?.extendedProps?.receiptId, event?.extendedProps?.receiptDate);
        }}>
          <div className="custom-image">
            {event?.extendedProps?.externalId && (
              <img
                src={`/assets/img/financials/${event?.extendedProps?.externalId}.svg`}
                alt={event?.title}
                onError={(e: any) => {
                  e.target.onerror = null;
                  const imageFinancial = document.querySelectorAll('.custom-image img') as NodeListOf<HTMLImageElement>;
                  imageFinancial.forEach((img) => {
                    if (img === e.target) {
                      img.style.display = 'none';
                      img.insertAdjacentHTML('afterend', `<p>${truncatedNameFinancial}</p>`);
                    }
                  });
                }}
              />
            )}
          </div>
          {(event.extendedProps.status === 2 && event?.extendedProps?.writeOffTotalAmount < event?.extendedProps?.previsionTotalAmount) &&
            <div className="amount-alert" title='Baixa de recebimento menor que a previsão'>
              <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
            </div>
          }
          {event?.extendedProps?.genarateReceiptId &&
            <div className="receipt-unknown" title='Recebimento desconhecido'>
              <FontAwesomeIcon icon={faReceipt} className="me-2" />
            </div>
          }
          <div className="expected-value">
            <span>
              <span style={{ color: '#5c5eac' }}>{FormatMoney(event?.extendedProps?.previsionTotalAmount)}</span> - <span style={{ color: '#2dce89' }}>{FormatMoney(event?.extendedProps?.writeOffTotalAmount)}</span>
            </span>
          </div>
          <div className="name-type-period">
            <span>{renderNameReceiptTypePeriod(event?.extendedProps?.receiptTypePeriod)}</span>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center custom-button">
            <button className="btn btn-icon-only mb-2" onClick={(e) => {
              e.stopPropagation(); handleShowModalReceiptInsert(event?.extendedProps?.receiptId, event?.extendedProps?.receiptDate)
            }}>
              <FontAwesomeIcon icon={faEdit} className="me-2" />
            </button>
            <button className="btn btn-icon-only" onClick={(e) => {
              e.stopPropagation(); handleDeleteReceipt(event?.extendedProps?.receiptId, event?.title)
            }}>
              <FontAwesomeIcon icon={faTrash} className="me-2" />
            </button>
          </div>
        </div>
      </div>

    );
  };

  const renderContent = (): JSX.Element => {

    const handleDatesSet = async (info: { view: { type: string, activeStart: Date, activeEnd: Date } }) => {
      const viewType = info?.view?.type;
      const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD');

      switch (viewType) {
        case 'resourceTimeline':
          const dayEnd = formatDate(info?.view?.activeEnd);
          setParams({ startDate: dayEnd, endDate: dayEnd });
          setParamsPerType({ startDate: dayEnd, endDate: dayEnd });
          break;
        case 'resourceTimelineWeek':
          const weekStart = formatDate(info?.view?.activeStart);
          const weekEnd = formatDate(info?.view?.activeEnd);
          setParams({ startDate: weekStart, endDate: weekEnd });
          setParamsPerType({ startDate: weekStart, endDate: weekEnd });
          break;
        case 'resourceTimelineMonth':
          const monthStart = moment(info?.view?.activeEnd).format('YYYY-MM-01');
          const monthEnd = formatDate(info?.view?.activeEnd);
          setParams({ startDate: monthStart, endDate: monthEnd });
          setParamsPerType({ startDate: monthStart, endDate: monthEnd });
          break;
        default:
          break;
      }
    };

    return (
      <>
        <div className="card card-body border-radius-lg p-3" style={{ width: '100%', overflow: 'hidden' }}>
          <FullCalendar
            plugins={[resourceTimelinePlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: isMobile ? '' : 'title',
              right: isMobile ? 'title' : 'resourceTimeline,resourceTimelineWeek,resourceTimelineMonth'
            }}
            initialView='resourceTimeline'
            dayMaxEvents={true}
            weekends={false}
            height={"65vh"}
            slotMinWidth={200}
            resourceAreaWidth={"25%"}
            locale={'pt-br'}
            timeZone='America/Sao_Paulo'
            buttonText={{
              prev: 'Anterior',
              next: 'Próximo',
              today: 'Hoje',
              resourceTimeline: 'Dia',
              resourceTimelineWeek: 'Semana',
              resourceTimelineMonth: 'Mês'
            }}
            resourceAreaHeaderContent={'Tipos de recebimentos'}
            resources={filteredResources}
            resourceLabelContent={({ resource }) => renderResource(resource)}
            events={filteredEvents}
            eventContent={renderButtonEvent}
            slotDuration={{ day: 1 }}
            slotLabelContent={(arg) => {
              const CurrentDate = new Date(arg.date);
              CurrentDate.setMinutes(CurrentDate.getMinutes() + CurrentDate.getTimezoneOffset());
              return CurrentDate.toLocaleDateString('pt-br', { weekday: 'short', day: 'numeric' });
            }}
            eventClassNames={"custom-event"}
            resourceOrder={'horizontal'}
            eventOrder={'previsionTotalAmount desc'}
            datesSet={handleDatesSet}
            loading={() => isLoading}
          />
        </div>
        {isLoading && <Spinner />}
      </>
    );
  }

  return (
    <MasterPage
      title="Recebimentos"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="Receipt" data-testid="Receipt">
        <PageBase
          title="Recebimentos"
          subtitle="Gerenciamento de recebimento"
          handleInsert={() => setShowModalInsert(true)}
          handleFilter={() => showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          handleExcel={handleExcel}
          error={error}
          content={renderContent()}
          isLoadingFile={isLoadingFile}
          hasFilter
          hasExcelPdf
        />

        <SidebarFilter>
          <SidebarReceiptFilter />
        </SidebarFilter>

        <ModalReceiptInsert
          show={showModalInsert}
          onClose={() => setShowModalInsert(false)}
          receiptId={receiptId}
          setReceiptId={setReceiptId}
          receiptDate={receiptDate}
          setReceiptDate={setReceiptDate}
        />

        <ModalLowReceiptInsert
          show={showModalLowReceiptInsert}
          onClose={() => setShowModalLowReceiptInsert(false)}
          receiptId={receiptId}
          setReceiptId={setReceiptId}
          receiptDate={receiptDate}
          setReceiptDate={setReceiptDate}
        />
      </div>
    </MasterPage>
  );
}

export default Receipt;
