import DatePicker from 'react-date-picker';
import 'moment/locale/pt-br';
import { FormGroup } from 'react-bootstrap';
import moment from 'moment';

interface Params {
    value: any;
    onChange: (e: any) => void;
    classInvalid?: string;
    minDate?: Date | null;
    endDate?: any
}

const InputDate = ({ value, onChange, classInvalid = '', minDate, endDate }: Params) => {
    moment.locale('pt-br');

    return (
        <div data-testid="InputDate">
            <FormGroup className="form-group m-0">
                <DatePicker
                    onChange={onChange}
                    value={value ?? null}
                    defaultValue={null}
                    format="dd/MM/y"
                    locale="pt-BR"
                    minDate={minDate ? new Date(minDate) : undefined}
                    maxDate={endDate ? new Date(endDate) : new Date()}
                    className={`form-control ${classInvalid}`}
                />
            </FormGroup>
        </div>
    );
}


export default InputDate;
