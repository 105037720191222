import * as yup from "yup";

export const schema = yup.object().shape({
    userVisitorId: yup
        .number()
        .required(),
    userVisitedId: yup
        .mixed()
        .required(),
    visitTypeContactId: yup
        .number()
        .required(),
    visitsAttendance: yup
        .array()
        .required()
        .min(1),
    visitsActions: yup
        .array()
        .required()
        .min(1),
    visitsTypesActivities: yup
        .array()
        .required()
        .min(1),
    observation: yup
        .string()
        .required()
        .max(500),
});