import { ContractFormModel } from "../../../models/Proposals/ContractForm/ContractFormModel";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/services/proposals/proposal/contractForm';

export class ContractFormService extends BaseService {

    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async save(params: ContractFormModel): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/save`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

}