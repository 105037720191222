import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './InputSearchPartner.scss';
import { Controller } from 'react-hook-form';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { PartnerService } from '../../../../services/Registrations/Partner/PartnerService';
import { ToastSettings } from '../../../../utils/ToastSettings';
import { ReactSelectOptions } from '../../../../models/ReactSelectOptions';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { PartnerInputSearchModel } from '../../../../models/Partner/PartnerInputSearchModel';
import { useAuth } from '../../../../providers/AuthProvider';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';

interface InputSearchPartnerProps {
  name: string;
  control: any;
  setValue: any;
  valuePartner: string;
  setValuePartner: Dispatch<SetStateAction<string>>;
  setPartnerObj?: Dispatch<SetStateAction<PartnerInputSearchModel | null>>;
  errors?: any,
  disabled?: boolean,
}

const InputSearchPartner: FC<InputSearchPartnerProps> = ({ name = "user", control, setValue, valuePartner, setValuePartner, setPartnerObj, errors, disabled = false }) => {
  const [partners, setPartners] = useState<any[]>([]);
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [selectedPartner, setSelectedPartner] = useState<any | null>(null);

  const { onInvalid } = useAuth();

  const handleGet = async (value: string) => {
    setIsLoadingSearch(true);

    const [_Response, _Error, _Code, _Errors] = await (new PartnerService()).list({ partner: value, withPaginate: false });

    if (_Response && (_Response.success === false || _Response?.erro)) {
      ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
      setIsLoadingSearch(false);
      setSelectedPartner(null);
      setPartners([]);
      return false;
    }

    if (Array.isArray(_Response.data) && _Response.data.length > 0) {
      setPartners(
        _Response.data.map((item: any) => {
          return { label: item.name, value: item.id, partnerId: item?.partnerId }
        })
      );
      !!setPartnerObj && setPartnerObj(_Response.data[0]);
    } else if (!!_Response.data) {
      setPartners([{ label: _Response.data.name, value: _Response.data.id, partnerId: _Response?.data?.partnerId }]);
      !!setPartnerObj && setPartnerObj(_Response.data);
    } else {
      setSelectedPartner(null);
      setPartners([]);
      !!setPartnerObj && setPartnerObj(null);
    }

    setIsLoadingSearch(false);

    return true;
  }

  useEffect(() => {
    if (valuePartner) {
      handleGet(valuePartner);
    } else {
      setSelectedPartner(null);
      setPartners([]);
    }
  }, [valuePartner]);

  useEffect(() => {
    if (partners?.length === 1) {
      setSelectedPartner(partners[0]);
    }
  }, [partners]);

  useEffect(() => {
    setValue(name, selectedPartner)
  }, [selectedPartner]);

  return (
    <div className="InputSearchPartner" data-testid="InputSearchPartner">
      <FormGroup className="form-group d-flex gap-2 mb-0">
        <input
          className={`form-control ${onInvalid(errors?.[name])}`}
          defaultValue={valuePartner}
          onBlur={(e: any) => setValuePartner(e?.target?.value)}
          style={{ width: 120 }}
          disabled={disabled}
        />

        {!isLoadingSearch ? (
          <>
            {control && (
              <Controller
                name={name}
                control={control}
                render={({ field: { onChange, name: _name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={_name}
                    options={partners}
                    placeholder="Selecione..."
                    className={`form-control p-0 ${onInvalid(errors?.[name])}`}
                    value={selectedPartner}
                    defaultValue={selectedPartner}
                    onChange={val => {
                      onChange(val || null);
                      setSelectedPartner(val);
                    }}
                    noOptionsMessage={() => 'Não há registros'}
                    isDisabled={!valuePartner || disabled}
                    styles={customStyles}
                  />
                )}
              />
            )}
          </>
        ) : (
          <Row className="m-0 w-100 align-items-center justify-content-center">
            <Col className="loading-box">
              <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: 26 }} />
            </Col>
          </Row>
        )}
      </FormGroup>
      <ErrorMessage name="Parceiro" type={errors?.[name]?.type} />
    </div>
  );
}

export default InputSearchPartner;
