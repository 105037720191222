import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { PagesPaginateModel } from "../../../../models/Paginate/PagesPaginate";
import { ReceiptWriteOffSerive } from "../../../../services/Commissioning/Receipt/ReceiptWriteOff/ReceiptWriteOffService";
import { ToastSettings } from "../../../../utils/ToastSettings";
import { ReceiptWriteOffModel } from "../../../../models/Commissioning/Receipt/ReceiptWriteOff/ReceiptWriteOffModel";


interface ContextProps {
    receiptWriteOff: ReceiptWriteOffModel | undefined,
    setReceiptWriteOff: Dispatch<SetStateAction<ReceiptWriteOffModel | undefined>>,
    receiptWriteOffs: ReceiptWriteOffModel[],
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleFetch: (data: any) => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>,
}

export const ReceiptWriteOffContext = createContext<ContextProps>({} as ContextProps);

interface ReceiptWriteOffProviderProps {
    children: ReactNode;
}

export const ReceiptWriteOffProvider = ({ children }: ReceiptWriteOffProviderProps) => {
    const [receiptWriteOff, setReceiptWriteOff] = useState<ReceiptWriteOffModel>();
    const [receiptWriteOffs, setReceiptWriteOffs] = useState<ReceiptWriteOffModel[]>([]);
    const [params, setParams] = useState<any>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new ReceiptWriteOffSerive();
    const location = useLocation();

    const handleFetch = async (data: any) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.fetch(data);
            if (!!_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setError('Houve um erro ao salvar a baixa de recebimento');
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar a baixa');
            return false;
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    return (
        <ReceiptWriteOffContext.Provider value={{
            receiptWriteOff,
            setReceiptWriteOff,
            receiptWriteOffs,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleFetch,
            handleDelete
        }}>
            {children}
        </ReceiptWriteOffContext.Provider>
    );
}

export const useReceiptWriteOff = () => useContext(ReceiptWriteOffContext);