import { FC, useEffect, useState } from 'react';
import './BoardReceipt.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import { toast } from 'react-toastify';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faReceipt, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'
import ModalBoardReceiptInsert from '../../../components/Commissioning/BoardReceipt/ModalBoardReceiptInsert/ModalBoardReceiptInsert';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import { useReceipt } from '../../../providers/Commissioning/Receipt/ReceiptProvider';
import { TotalsModel } from '../../../models/Commissioning/Receipt/TotalsModel';
import Spinner from '../../../components/Spinner/Spinner';
import moment from 'moment';
import { useReceiptUnknown } from '../../../providers/Commissioning/Receipt/ReceiptUnknown/ReceiptUnknownProvider';
import FormatMoney from '../../../utils/FormatMoney';
import { ReceipUnknownModel } from '../../../models/Commissioning/Receipt/ReceiptUnknown/ReceiptUnknownModel';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import SidebarFilter from '../../../components/SidebarFilter/SidebarFilter';
import ModalGenerateUnknownReceiptInsert from '../../../components/Commissioning/BoardReceipt/ModalGenerateUnknownReceiptInsert/ModalGenerateUnknownReceiptInsert';
import { useCampaign } from '../../../providers/Commissioning/Campaign/CampaignProvider';
import SidebarBoardReceiptFilter from '../../../components/Commissioning/BoardReceipt/SidebarBoardReceiptFilter/SidebarBoardReceiptFilter';
import { EventClickArg } from 'fullcalendar';
import ReceiptDetailedTotals from '../../../components/Commissioning/BoardReceipt/ReceiptDetailedTotals/ReceiptDetailedTotals';
import { group } from 'console';

interface BoardReceiptProps { }

const BoardReceipt: FC<BoardReceiptProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
  const [showModalGenerateUnknownInsert, setShowModalGenerateUnknownInsert] = useState<boolean>(false);
  const [showDetailed, setShowDetailed] = useState<boolean>(false);
  const [totalPrevisions, setTotalPrevisions] = useState<number>(0);
  const [totalAmounts, setTotalAmounts] = useState<number>(0);

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { totals, totalsFilter, setParamsTotals, isLoadingTotals, handleListTotals, handleListDetailedTotals, setIsDataGeneral } = useReceipt();
  const { receiptUnknowns, setReceiptUnknown, handleDelete, handleList, error, setParams: setParamsUnknow, params } = useReceiptUnknown();
  const { setParams: setParamsCampaign } = useCampaign();

  useEffect(() => {
    setShowSidebarFilter(false);
  }, []);

  useEffect(() => {
    calculateTotals(totals);
  }, [totals]);

  useEffect(() => {
    calculateTotals(totalsFilter);
  }, [totalsFilter]);

  const calculateTotals = (data: TotalsModel[]) => {
    let totalPrevisionSum = 0;
    let totalAmountSum = 0;

    data?.forEach(item => {
      totalPrevisionSum += item?.prevision ?? 0;
      totalAmountSum += item?.writeOff ?? 0;
    });
    setTotalPrevisions(totalPrevisionSum);
    setTotalAmounts(totalAmountSum);
  };

  const handleEdit = (item: ReceipUnknownModel) => {
    setReceiptUnknown(item)
    setShowModalInsert(true);
  }

  const handleGenerateUnknownReceipt = (item: ReceipUnknownModel) => {
    setReceiptUnknown(item)
    setParamsCampaign({ financialId: item?.financial?.id ?? undefined, active: true });
    setShowModalGenerateUnknownInsert(true)
  }

  const handleShowDetailed = async (event: any) => {
    setShowDetailed(true);
    setIsDataGeneral(true);
    handleListDetailedTotals({ startDate: event.startStr, endDate: event.startStr });
  }

  const handleShowDetailedGenenral = async () => {
    setShowDetailed(true);
    setIsDataGeneral(false);
    handleListDetailedTotals({startDate: params.startDate, endDate: params.endDate, groupPerDay: false});
  } 

  const handleDeleteBoardReceipt = (item: ReceipUnknownModel) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja excluir o recebimento ${item?.enterprise?.name} - (${item?.financial?.name})?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleDelete(item.id ?? 0).then();
        if (ret) {
          toast.success('Recebimento inativado com sucesso!');
          handleList();
          handleListTotals();
        } else {
          await Swal.fire('Ops!', `Não foi possível inativar o recebimento.<br>${error}`, 'error');
        }
      }
    });
  }

  const events = (totals && totals?.length > 0) ?
    totals?.map((item: TotalsModel) => ({
      start: item?.date,
      totalAmount: item?.writeOff,
      totalPrevision: item?.prevision
    })) : [];

  const handleDateChange = (info: any) => {
    setParamsTotals({
      startDate: moment(info?.startStr).format('YYYY-MM-01'),
      endDate: moment(info?.startStr).endOf('month').format('YYYY-MM-DD')
    });
    setParamsUnknow({
      startDate: moment(info?.startStr).format('YYYY-MM-01'),
      endDate: moment(info?.startStr).endOf('month').format('YYYY-MM-DD')
    });
  };

  const renderList = (e: any): JSX.Element => {
    const { event } = e
    const showBadge = event?.extendedProps?.totalPrevision > 0 || event?.extendedProps?.totalAmount > 0;

    return (
      <div className="custom-event">
        {showBadge && (
          <>
            <div className="mb-1">
              <span
                className="badge cursor-pointer"
                style={{ backgroundColor: '#5c5eac ', minWidth: '125px' }}
                onClick={() => handleShowDetailed(event)}
              >
                {FormatMoney(event?.extendedProps?.totalPrevision)}
              </span>
            </div>
            <div>
              <span
                className="badge cursor-pointer"
                style={{ backgroundColor: '#2dce89', minWidth: '125px' }}
                onClick={() => handleShowDetailed(event)}
              >
                {FormatMoney(event?.extendedProps?.totalAmount)}
              </span>
            </div>
          </>
        )}
      </div>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        <Row md={12}>
          <Col sm={12} md={12} lg={8} className="mb-2" >
            <Card>
              <Card.Body>
                <FullCalendar
                  plugins={[dayGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: ''
                  }}
                  initialView={'dayGridMonth'}
                  weekends={false}
                  fixedWeekCount={false}
                  height={"70vh"}
                  locale={'pt-br'}
                  buttonText={{
                    prev: 'Anterior',
                    next: 'Proximo',
                    today: 'Hoje',
                    day: 'Dia',
                    week: 'Semana',
                    month: 'Mês'
                  }}
                  events={events}
                  eventContent={renderList}
                  datesSet={handleDateChange}
                  loading={() => isLoadingTotals}
                  showNonCurrentDates={false}
                  eventClick={(e: EventClickArg) => handleShowDetailed(e?.event)}
                />
              </Card.Body>
              <Card.Footer className='p-2'>
                <div className="d-md-flex d-none flex-md-row flex-column gap-2 gap-md-0 mt-2">
                  <span className='me-2 badge' style={{ backgroundColor: '#5c5eac' }}>
                    {'PREVISÃO RECEBIMENTO'}
                  </span>
                  <span className='me-2 badge' style={{ backgroundColor: '#2dce89' }}>
                    {'RECEBIMENTO TOTAL'}
                  </span>
                </div>
              </Card.Footer>
              {isLoadingTotals && <Spinner />}
            </Card>
          </Col>

          <Col sm={12} md={12} lg={4} className='mt-3 mt-md-0 '>
            <Row className='mb-2'>
              <Col md={12} className="mb-2">
                <Card>
                  <Card.Body className='p-3'>
                    <Row>
                      <Col xs={9} md={9} onClick={() => handleShowDetailedGenenral()} style={{cursor: "pointer"}}>
                        <p className="text-sm mb-0 text-uppercase font-weight-bold">PREVISÃO RECEBIMENTO</p>
                        <h5 className="font-weight-bolder">
                          {FormatMoney(totalPrevisions)}
                        </h5>
                      </Col>
                      <Col xs={3} md={3} className="d-flex justify-content-end">
                        <div className="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle">
                          <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={12}>
                <Card>
                  <Card.Body className='p-3'>
                    <Row>
                      <Col xs={9} md={9} onClick={() => handleShowDetailedGenenral()} style={{cursor: "pointer"}}>
                        <p className="text-sm mb-0 text-uppercase font-weight-bold">RECEBIMENTO TOTAL</p>
                        <h5 className="font-weight-bolder">
                          {FormatMoney(totalAmounts)}
                        </h5>
                      </Col>
                      <Col xs={3} md={3} className="d-flex justify-content-end">
                        <div className="icon icon-shape bg-gradient-success shadow-success text-center rounded-circle">
                          <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Card className="otherEntries">
              <Card.Header className="p-3 pb-0">
                <Row>
                  <Col md={6}>
                    <h5 className="mb-0">Outras Entradas</h5>
                  </Col>
                  <Col md={6} className="d-flex justify-content-end">
                    <button className="btn btn-primary" onClick={() => setShowModalInsert(true)}>Adicionar</button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="border-radius-lg pe-0" style={{ overflowY: 'auto' }}>
                {receiptUnknowns?.map((item: ReceipUnknownModel) => (
                  <>
                    <ul className="list-group">
                      <li className="list-group-item border-0 justify-content-between ps-0 pb-0 border-radius-lg">
                        <div className="d-flex">
                          <div className="d-flex align-items-center">
                            <button className="btn btn-icon-only btn-rounded btn-outline-success mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center">
                              <i className="fas fa-arrow-up"></i>
                            </button>
                            <div className="d-flex flex-column">
                              <h6 className="mb-0 text-dark text-sm">{`${item?.enterprise?.name} - (${item?.financial?.name})`}</h6>
                              <span className="mb-2 text-xs">{item?.description}</span>
                              <span className="text-xs">{moment(item?.paymentDate).format('DD/MM/YYYY')}</span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center ms-auto">
                            <div className="d-flex align-items-center text-success text-sm font-weight-bold ms-2">
                              {FormatMoney(item?.amount)}
                            </div>
                            <DropdownMenuTable key={item?.id ?? 0} >
                              <Dropdown.Item className="border-radius-md py-2" onClick={() => handleGenerateUnknownReceipt(item)}>
                                <FontAwesomeIcon icon={faReceipt} className="me-2" />Gerar recebimento
                              </Dropdown.Item>
                              <Dropdown.Item className="border-radius-md py-2" onClick={() => handleEdit(item)}>
                                <FontAwesomeIcon icon={faEdit} className="me-2" />Editar
                              </Dropdown.Item>
                              <Dropdown.Item className="border-radius-md py-2" onClick={() => handleDeleteBoardReceipt(item)}>
                                <FontAwesomeIcon icon={faTrash} className="me-2" />Remover
                              </Dropdown.Item>
                            </DropdownMenuTable>
                          </div>
                        </div>
                        <hr className="horizontal dark mt-3 mb-2" />
                      </li>
                    </ul>
                  </>
                ))}
              </Card.Body >
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <MasterPage
      title="Recebimentos"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="BoardReceipt" data-testid="BoardReceipt">
        <PageBase
          title="Recebimentos"
          subtitle="Gerenciamento de recebimento."
          error={error}
          content={renderContent()}
          hasFilter
          handleFilter={() => showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
        />


        <SidebarFilter>
          <SidebarBoardReceiptFilter />
        </SidebarFilter>

        <ModalBoardReceiptInsert
          show={showModalInsert}
          onClose={() => setShowModalInsert(false)}
        />

        <ModalGenerateUnknownReceiptInsert
          show={showModalGenerateUnknownInsert}
          onClose={() => setShowModalGenerateUnknownInsert(false)}
        />

        <ReceiptDetailedTotals
          show={showDetailed}
          onClose={setShowDetailed}
        />
      </div>
    </MasterPage>
  );
}

export default BoardReceipt;
