import { FC, SetStateAction, useEffect, useState } from 'react';
import './NotificationCenter.scss';
import MasterPage from '../../../../components/MasterPage/MasterPage';
import PageBase from '../../../../components/PageBase/PageBase';
import { Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useNotificationCenter } from '../../../../providers/Registrations/Notification/NotificationCenterProvider';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { ReactSelectOptions } from '../../../../models/ReactSelectOptions';
import { NotificationCenterModel } from '../../../../models/Registrations/Notification/NotificationCenterModel';
import { ModuleModel } from '../../../../models/Registrations/ModulesTool/ModuleModel';
import { ToolModel } from '../../../../models/Registrations/ModulesTool/ToolModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import InputSearchPartner from '../../../../components/Registrations/Partner/InputSearchPartner/InputSearchPartner';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../../providers/AuthProvider';
import { toast } from 'react-toastify';

interface NotificationCenterProps { }

const NotificationCenter: FC<NotificationCenterProps> = () => {
  const [selectedLevel, setSelectedLevel] = useState<ReactSelectOptions>();
  const [levelNotification, setLevelNotification] = useState<NotificationCenterModel>();
  const [itemIsChanging, setItemChanging] = useState<any[]>([0, null]);
  const [canSeeLevel, setCanSeeLevel] = useState<boolean>(false);
  const [partnerObj, setPartnerObj] = useState<any>();
  const [partnerValue, setPartnerValue] = useState<any>();

  const { setValue, control } = useForm();
  const { userLogged, CanAccess } = useAuth();
  const {
    levelOptions,
    levelNotifications,
    userNotifications,
    handleListByLevel,
    handleListByUser,
    handleDeleteLevel,
    handleDeleteUser,
    handleSaveLevel,
    handleSaveUser,
    error,
    setError,
    isLoading
  } = useNotificationCenter();

  const animatedComponents = makeAnimated();

  useEffect(() => {
    setPartnerValue(userLogged?.partnerCode);
    handleListByLevel().then();
    checkCanSeeLevel().then();
  }, []);

  useEffect(() => {
    setLevelNotification(levelNotifications?.find((x: NotificationCenterModel) => x.id === selectedLevel?.value));
  }, [selectedLevel]);

  useEffect(() => {
    !!partnerObj && handleListByUser(partnerObj?.id);
  }, [partnerObj]);

  useEffect(() => {
    !!levelNotification && setLevelNotification({ ...levelNotifications?.find((x: NotificationCenterModel) => x.id === selectedLevel?.value) });
  }, [levelNotifications]);

  useEffect(() => {
    if (!!error) {
      toast.error(error);
      setError('');
    }
  }, [error]);

  const checkCanSeeLevel = async () => {
    let can = await CanAccess('central_de_notificacoes.gerenciar_nivel.regra').then();
    if (can) {
      setCanSeeLevel(can);
    } else {
      can = await CanAccess('central_de_notificacoes.gerenciar_niveis.regra').then();
      setCanSeeLevel(can);
    }
  }

  const handleChangeSwitch = async (tool: ToolModel, checked: boolean, type: 'level' | 'user') => {
    setItemChanging([tool.id, type]);
    let result = false;
    if (type === 'level') {
      result = checked ? await handleDeleteLevel(tool.pivotId || 0) : await handleSaveLevel(selectedLevel?.value || 0, tool.id || 0);
      if (result) {
        handleListByLevel().then();
      }
    } else {
      result = checked ? await handleDeleteUser(tool.pivotId || 0) : await handleSaveUser(partnerObj?.id || 0, tool.id || 0);
      if (result) {
        handleListByUser(partnerObj?.id).then();
      }
    }
    result && toast.success(checked ? 'Notificação ativada!' : 'Notificação desativada!');
  }

  const renderContent = (): JSX.Element => {
    return (
      <Row className='mb-3 gap-3 gap-md-0'>
        {canSeeLevel &&
          <Col md={6} sm={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <FormGroup>
                      <label>Nível</label>
                      <ReactSelect
                        className='form-control p-0'
                        isSearchable
                        isClearable
                        placeholder='Selecione...'
                        value={selectedLevel}
                        options={levelOptions}
                        components={animatedComponents}
                        noOptionsMessage={() => 'Não há registros'}
                        styles={customStyles}
                        onChange={(val: any) => setSelectedLevel(val)}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className='modules'>
                  <Col>
                    {!!levelNotification ? levelNotification?.modules?.map((module: ModuleModel) => (
                      <Card key={module.id} className='mt-3'>
                        <Card.Header className='d-flex justify-content-between'>
                          <Card.Title>{module.name}</Card.Title>
                          <span className='pe-3 text-sm'>App</span>
                        </Card.Header>
                        <Card.Body className='d-flex flex-column gap-2'>
                          {module.tools?.map((tool: ToolModel) => (
                            <div key={tool.id} className='d-flex justify-content-between'>
                              <div>
                                {tool.name}
                              </div>
                              <div>
                                {(isLoading && itemIsChanging[0] === tool.id && itemIsChanging[1] === 'level')
                                  ? <FontAwesomeIcon icon={faSpinner} spin />
                                  : <Form.Switch
                                    checked={tool.app}
                                    onChange={(val) => handleChangeSwitch(tool, val?.target?.checked, 'level')}
                                  />}
                              </div>
                            </div>
                          ))}
                        </Card.Body>
                      </Card>
                    )) :
                      <div className='text-center mt-5'>
                        <h6>
                          Selecione um Nível!
                        </h6>
                      </div>}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>}
        <Col md={6} sm={12}>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <FormGroup>
                    <label>Usuário</label>
                    <InputSearchPartner
                      name={'partner'}
                      control={control}
                      setValue={setValue}
                      valuePartner={partnerValue}
                      setValuePartner={setPartnerValue}
                      setPartnerObj={setPartnerObj}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className='modules'>
                <Col>
                  {!!userNotifications?.modules && userNotifications?.modules?.length > 0 ? userNotifications?.modules?.map((module: ModuleModel) => (
                    <Card key={module.id} className='mt-3'>
                      <Card.Header className='d-flex justify-content-between'>
                        <Card.Title>{module.name}</Card.Title>
                        <span className='pe-3 text-sm'>App</span>
                      </Card.Header>
                      <Card.Body className='d-flex flex-column gap-2'>
                        {module.tools?.map((tool: ToolModel) => (
                          <div key={tool.id} className='d-flex justify-content-between'>
                            <div>
                              {tool.name}
                            </div>
                            <div>
                              {(isLoading && itemIsChanging[0] === tool.id && itemIsChanging[1] === 'user')
                                ? <FontAwesomeIcon icon={faSpinner} spin />
                                : <Form.Switch
                                  checked={tool.app}
                                  onChange={(val) => handleChangeSwitch(tool, val?.target?.checked, 'user')}
                                />}
                            </div>
                          </div>
                        ))}
                      </Card.Body>
                    </Card>
                  )) :
                    <div className='text-center mt-5'>
                      <h6>
                        Não existem Módulos e Ferramentas<br /> para listar!
                      </h6>
                    </div>}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <MasterPage
      title="NotificationCenter"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="NotificationCenter" data-testid="NotificationCenter">
        <PageBase
          title="Central de Notificações"
          subtitle="Central para gerenciar o recebimento de notificações."
          content={renderContent()}
        />
      </div>
    </MasterPage>
  );
}

export default NotificationCenter;