import * as yup from "yup";

export const schema = yup.object().shape({
    name: yup
        .string()
        .min(3)
        .max(100)
        .required(),
    description: yup
        .string()
        .min(3)
        .max(255)
        .required(),
    financialId: yup
        .number()
        .required()
        .test('typeError', 'Financeira é obrigatório', (value: number) => value > 0),
});