import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/domus/campaign';
const URL_API = 'api/services/commissioning/receipt/campaigns';

export class CampaignService extends BaseService {

    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listAll(): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL_API}`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}