import { FC, useEffect, useState } from 'react';
import './ModalVisitsActionsInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useVisitsActions } from '../../../../providers/Visits/VisitsActions/VisitsActionsProvider';
import { useForm } from 'react-hook-form';
import { VisitsActionsModel } from '../../../../models/Visits/VisitsActions/VisitsActionsModel';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useAuth } from '../../../../providers/AuthProvider';
import { toast } from 'react-toastify';

interface ModalVisitsActionsInsertProps {
  show: boolean;
  onClose: () => void;
}

type FormValues = {
  id?: number;
  name?: string;
  description?: number;
}

const ModalVisitsActionsInsert: FC<ModalVisitsActionsInsertProps> = ({ show, onClose }) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const { action, setAction, setError, handleList, handleFetch } = useVisitsActions();
  const { onInvalid } = useAuth();

  const { handleSubmit, register, reset, formState: { errors } } = useForm({
    mode: "onChange",
  });

  const handleClose = () => {
    setError('');
    setAction({} as VisitsActionsModel);
    onClose();
  }

  const onSubmit = async (data: any) => {
    setIsLoadingSubmit(true);
    setError('');

    const ret = await handleFetch(data as VisitsActionsModel);

    if (ret) {
      toast.success('Ação salva com sucesso!');
      handleList();
      handleClose();
    }

    setIsLoadingSubmit(false);
  }

  useEffect(() => {
    if (show) {
      if (!!action?.id) {
        reset(action as FormValues);
      } else {
        reset(new VisitsActionsModel());
        setAction({} as VisitsActionsModel);
      }
    }
  }, [show]);

  return (
    <ModalDefault
      title={!!action?.id ? 'Editar Ação' : 'Adicionar Ação'}
      show={show}
      onClose={handleClose}
      sizeModal={'lg'}
      showFooter={true}
      buttonText={isLoadingSubmit ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoadingSubmit}
      disabledSubmit={isLoadingSubmit}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalVisitsActionsInsert" data-testid="ModalVisitsActionsInsert">
        <Row>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="name">NOME *</label>
              <input
                className={`form-control ${onInvalid(errors?.name)}`}
                type='text'
                {...register('name')}
                onChange={(e: any) => setAction({ ...action, name: e?.target?.value })}
              />
              <ErrorMessage name={'Nome'} />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="description">DESCRIÇÃO *</label>
              <textarea rows={3} className='form-control'
                {...register('description')}
                onChange={(e: any) => setAction({ ...action, description: e?.target?.value })}
              ></textarea>
              <ErrorMessage name={'Descrição'} />
            </FormGroup>
          </Col>

        </Row>
      </div>
    </ModalDefault >
  );
}

export default ModalVisitsActionsInsert;