import { FC, ReactNode, useEffect, useState } from 'react';
import './MasterPage.scss';
import Sidebar from '../Sidebar/Sidebar';
import { useAuth } from '../../providers/AuthProvider';
import Navbar from '../Navbar/Navbar';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from '../Spinner/Spinner';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import MainFooter from '../MainFooter/MainFooter';
import SidebarInformative from '../Informative/SidebarInformative/SidebarInformative';
import { useLog } from '../../providers/Logs/LogProvider';
import SidebarReportRequest from '../Reports/ReportRequest/SidebarReportRequest/SidebarReportRequest';

interface MasterPageProps {
  title?: string;
  subtitle?: string;
  icon?: string;
  maintenance?: boolean;
  dataTestId?: string;
  children?: ReactNode;
  heightClass?: string;
  footerFixed?: boolean;
  footerInverse?: boolean;
}

const MasterPage: FC<MasterPageProps> = ({
  title = '',
  subtitle = '',
  icon = '',
  maintenance = false,
  dataTestId = '',
  heightClass = 'min-height-300',
  footerFixed = false,
  footerInverse = false,
  children
}) => {
  const [can, setCan] = useState<boolean>(true);
  const { isLoadingLogged, CanAccess } = useAuth();
  const { isLoadingPage } = useLog();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/unauthorized' && location.pathname !== '/home') {
      CanAccess(location.pathname, true).then((x: boolean) => {
        setCan(x);
      });
    }
  }, []);

  useEffect(() => {
    !can && navigate('/authenticator/domus');
  }, [can]);

  return (
    <>
      <div className={`position-absolute w-100 ${heightClass} top-0 MasterPage`}>
        <div className="mask bg-degrade opacity-9"></div>
      </div>

      <Sidebar />

      <main className="main-content position-relative border-radius-lg MasterPage" data-testid="MasterPage">
        <Navbar
          title={title}
          subtitle={subtitle}
          icon={icon}
        />

        <Container fluid className="px-5 main-wrapper" data-testid={dataTestId}>
          {!maintenance ? (
            <>{children}</>
          ) : (
            <Row className="justify-content-center">
              <Col lg={6} md={7} className="mx-auto text-center">
                <Card>
                  <Card.Body>
                    <h2 className="fadeIn1 fadeInBottom mt-3">Sistema em Manutenção</h2>
                    <p className="lead fadeIn2 fadeInBottom">Sugerimos que você acesse a página inicial enquanto resolvemos esse problema.</p>

                    <Button className="btn btn-primary mt-4 fadeIn2 fadeInBottom" onClick={() => navigate('/home')}>Voltar para página inicial</Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          {(isLoadingLogged || isLoadingPage) && (
            <div className='mt-12'>
              <Spinner backgroundColor='white' opacity='1' />
            </div>
          )}
        </Container>

        <MainFooter
          fixed={footerFixed}
          inverse={footerInverse}
        />
      </main>

      <SidebarInformative />
      <SidebarReportRequest />

      <div className="backdrop-violet-80 hide"></div>
    </>
  );
}

export default MasterPage;
