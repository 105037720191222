import api from "../Api";
import { BaseService } from "../BaseService";

const URL = 'api/fcm/response';

class AuthenticatorValidationEmailService extends BaseService {
    async fetch(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/responseToken`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}

export default AuthenticatorValidationEmailService;