import React, { FC, useEffect, useState } from 'react';
import './ReceiptTypeMonthly.scss';
import { Card, Col, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import ReactSelect from 'react-select';

interface ReceiptTypeMonthlyProps {
  itemIdEdit: string | number | undefined,
  receiptTypes: any[],
  setReceiptTypes: any
}

interface Day {
  value: number;
  label: string;
}

const optionsSelectDay: Day[] = [];

for (let i = 1; i <= 31; i++) {
  optionsSelectDay.push({ value: i, label: String(i) });
}

const ReceiptTypeMonthly: FC<ReceiptTypeMonthlyProps> = ({ receiptTypes, setReceiptTypes, itemIdEdit }) => {

  const [selectedMonthDay, setSelectedMonthDay] = useState<any[]>([]);

  useEffect(() => {
    const _item = receiptTypes?.find((x: any, key: number) => key === itemIdEdit);
    setSelectedMonthDay(_item?.receipt?.periods?.map((x: any) => {
      return {
        value: x,
        label: x.toString(),
      }
    }))
  }, [receiptTypes])

  const handleChangeMonthly = (val: any,) => {
    const _item = receiptTypes?.find((x: any, key: number) => key === itemIdEdit);
    _item.receipt.periods = val?.map((item: any) => item?.value);
    receiptTypes[receiptTypes?.findIndex((x: any, key: number) => key === itemIdEdit)] = _item;
    setReceiptTypes(JSON.parse(JSON.stringify(receiptTypes)));
  }


  return (
    <div className="ReceiptTypeMonthly" data-testid="ReceiptTypeMonthly">
      <Card>
        <Card.Header>
          <span>
            <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
            Mensal - Selecione o dia aproximado para verificação
          </span>
        </Card.Header>
        <Card.Body>
          <Col>
            <FormGroup className="form-group select-width">
              <ReactSelect
                isClearable
                isSearchable
                isMulti
                name='selectMonthly'
                placeholder="Selecione o período..."
                className={`form-control p-0`}
                options={optionsSelectDay}
                value={selectedMonthDay}
                onChange={(val: any) => handleChangeMonthly(val)}
                noOptionsMessage={() => 'Não há registros'}
              />
            </FormGroup>
          </Col>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ReceiptTypeMonthly;
