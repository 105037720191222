import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { BiCommercialService } from "../../../services/BI/Dashboard/BiCommercialService";
import { CommercialChartParticipationModel } from "../../../models/BI/Dashboard/Commercial/ProductionByCommercial/CommercialChartParticipationModel";
import { ProductionCommercialBySupervisorDetailModel } from "../../../models/BI/Dashboard/Commercial/ProductionCommercialBySupervisor/ProductionCommercialBySupervisorDetailModel";
import { GeneralCommercialProductionBySupervisorModel } from "../../../models/BI/Dashboard/Commercial/GeneralCommercialProductionBySupervisor/GeneralCommercialProductionBySupervisorModel";
import { BiAnnualProductionModel } from "../../../models/BI/Dashboard/BiAnnualProductionModel";
import { BiMonthlyProductionModel } from "../../../models/BI/Dashboard/BiMonthlyProductionModel";

interface ContextProps {
  monthlyProduction: BiMonthlyProductionModel | undefined;
  setMonthlyProduction: Dispatch<BiMonthlyProductionModel | undefined>;
  annualProduction: BiAnnualProductionModel | undefined;
  setAnnualProduction: Dispatch<BiAnnualProductionModel | undefined>;
  monthlyParticipation: CommercialChartParticipationModel[];
  annualParticipation: CommercialChartParticipationModel[];
  productionMainFinancialsByCommercial: BiMonthlyProductionModel | undefined;
  setProductionMainFinancialsByCommercial: Dispatch<
    SetStateAction<BiMonthlyProductionModel | undefined>
  >;
  productionCommercialBySupervisor: BiMonthlyProductionModel | undefined;
  setProductionCommercialBySupervisor: Dispatch<
    BiMonthlyProductionModel | undefined
  >;
  productionCommercialBySupervisorDetail:
  | ProductionCommercialBySupervisorDetailModel
  | undefined;
  setProductionCommercialBySupervisorDetail: Dispatch<
    SetStateAction<ProductionCommercialBySupervisorDetailModel | undefined>
  >;
  generalCommercialProductionBySupervisor:
  | GeneralCommercialProductionBySupervisorModel
  | undefined;
  setGeneralCommercialProductionBySupervisor: Dispatch<
    SetStateAction<GeneralCommercialProductionBySupervisorModel | undefined>
  >;
  params: any;
  setParams: Dispatch<SetStateAction<any>>;
  pages: PagesPaginateModel;
  setPages: Dispatch<SetStateAction<PagesPaginateModel>>;
  isLoading: boolean;
  isLoadingDetail: boolean;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  handleListProductionCommercialBySupervisorDetail: (params: any) => void;
}

export const BiCommercialContext = createContext<ContextProps>(
  {} as ContextProps
);

interface BiCommercialProviderProps {
  children: ReactNode;
}

export const BiCommercialProvider = ({
  children,
}: BiCommercialProviderProps) => {
  const [monthlyProduction, setMonthlyProduction] =
    useState<BiMonthlyProductionModel>();
  const [annualProduction, setAnnualProduction] =
    useState<BiAnnualProductionModel>();
  const [monthlyParticipation, setMonthlyParticipation] = useState<
    CommercialChartParticipationModel[]
  >([]);
  const [annualParticipation, setAnnualParticipation] = useState<
    CommercialChartParticipationModel[]
  >([]);
  const [
    productionMainFinancialsByCommercial,
    setProductionMainFinancialsByCommercial,
  ] = useState<BiMonthlyProductionModel>();
  const [
    productionCommercialBySupervisor,
    setProductionCommercialBySupervisor,
  ] = useState<BiMonthlyProductionModel>();
  const [
    productionCommercialBySupervisorDetail,
    setProductionCommercialBySupervisorDetail,
  ] = useState<ProductionCommercialBySupervisorDetailModel>();
  const [
    generalCommercialProductionBySupervisor,
    setGeneralCommercialProductionBySupervisor,
  ] = useState<GeneralCommercialProductionBySupervisorModel>();
  const [params, setParams] = useState<any>({ withPaginate: false });
  const [pages, setPages] = useState<PagesPaginateModel>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const service = new BiCommercialService();

  const handleListProductionCommercialBySupervisorDetail = async (
    params: any
  ) => {
    try {
      setIsLoadingDetail(true);
      const [_Response, _Error] =
        await service.listProductionCommercialBySupervisorDetail(params);
      setIsLoadingDetail(false);

      if (_Error) {
        setProductionCommercialBySupervisorDetail(undefined);
        setPages({});
        setError(_Error);
      }

      setProductionCommercialBySupervisorDetail(_Response.data);
      setPages(_Response?.pages);
      setError("");
    } catch (e) {
      setIsLoadingDetail(false);
      setProductionCommercialBySupervisorDetail(undefined);
      setError("Não foi possível carregar os exemplos do sistema.");
    }
  };

  return (
    <BiCommercialContext.Provider
      value={useMemo(
        () => ({
          monthlyProduction,
          setMonthlyProduction,
          annualProduction,
          setAnnualProduction,
          monthlyParticipation,
          annualParticipation,
          productionMainFinancialsByCommercial,
          setProductionMainFinancialsByCommercial,
          productionCommercialBySupervisor,
          setProductionCommercialBySupervisor,
          productionCommercialBySupervisorDetail,
          setProductionCommercialBySupervisorDetail,
          generalCommercialProductionBySupervisor,
          setGeneralCommercialProductionBySupervisor,
          params,
          setParams,
          pages,
          setPages,
          isLoading,
          isLoadingDetail,
          error,
          setError,
          handleListProductionCommercialBySupervisorDetail,
        }),
        [
          monthlyProduction,
          setMonthlyProduction,
          annualProduction,
          setAnnualProduction,
          monthlyParticipation,
          annualParticipation,
          productionMainFinancialsByCommercial,
          setProductionMainFinancialsByCommercial,
          productionCommercialBySupervisor,
          setProductionCommercialBySupervisor,
          productionCommercialBySupervisorDetail,
          setProductionCommercialBySupervisorDetail,
          generalCommercialProductionBySupervisor,
          setGeneralCommercialProductionBySupervisor,
          params,
          setParams,
          pages,
          setPages,
          isLoading,
          isLoadingDetail,
          error,
          setError,
          handleListProductionCommercialBySupervisorDetail,
        ]
      )}
    >
      {children}
    </BiCommercialContext.Provider>
  );
};

export const useBiCommercial = () => useContext(BiCommercialContext);
