import { FC, useEffect, useState } from 'react';
import './ModalRemoveOpportunity.scss';
import MasterPage from '../../../MasterPage/MasterPage';
import PageBase from '../../../PageBase/PageBase';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import AppPagination from '../../../AppPagination/AppPagination';
import { useSidebarFilter } from '../../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../../providers/PaginateProvider';
import { useExample } from '../../../../providers/ExampleProvider';
import { toast } from 'react-toastify';
import DropdownMenuTable from '../../../DropdownMenuTable/DropdownMenuTable';
import { Dropdown, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useOpportunity } from '../../../../providers/Marketplace/Opportunities/OpportunityProvider';
import { FailedStatusModel } from '../../../../models/Marketplace/Opportunity/FailedStatusModel';
import { OpportunityModel } from '../../../../models/Marketplace/Opportunity/OpportunityModel';

interface ModalRemoveOpportunityProps {
  show: boolean,
  onClose: any,
  opportunityNumber: number | null,
}

const ModalRemoveOpportunity: FC<ModalRemoveOpportunityProps> = ({onClose, show, opportunityNumber}) => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
  const { setShowSidebarFilter } = useSidebarFilter();
  const { pages, params, setParams, isLoading, error, examples, setExample, handleList, handleDelete } = useExample();
  const { failedStatus, handleGetFailedStatus, handleFailedOpportunity, opportunities, setOpportunities, } = useOpportunity();

  const handleFailedOpportunityClick = () => {
    handleFailedOpportunity(opportunityNumber || 0).then();
    setOpportunities(opportunities.filter((item: OpportunityModel) => item.opportunity_id !==opportunityNumber));
    onClose();
    toast.warning(`Oportunidade ${opportunityNumber} removida!`);
  };

  const renderContent = (): JSX.Element => {
    return (
      <>
        <FormGroup className="form-group">
            <label>Motivo</label>
            <select className="form-select">
                {failedStatus?.map((x: FailedStatusModel) => (
                    <option key={x.failed_status_id}> {x.failed_status_name}</option>
                ))}
            </select>
        </FormGroup>
      </>
    );
  }

  useEffect(() => {
      if (show) {
        handleGetFailedStatus();
      }
  }, [show]);

  return (
    <ModalDefault
      sizeModal = 'md'
      title={`# ${opportunityNumber}: Não foi possivel digitar ?`}
      show={show}
      onClose={onClose}
      handleSubmit={handleFailedOpportunityClick}
      buttonText='Confirmar'
    >
      <div className="ModalOpportunityView" data-testid="ModalOpportunityView">
      {renderContent()}
      </div>
    </ModalDefault>
  );
}

export default ModalRemoveOpportunity;
