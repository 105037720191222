import axios from "axios";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/app/visit/analysis';

export class VisitsAnalysisService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async export(params: any, type: 'perRegional' | 'perUf' | 'perFocus' | 'perActivities' | 'geral'): Promise<any[]> {
        try {
            let _url = '';
            switch (type) {
                case 'perRegional':
                    _url = '/export/quantityPerRegional';
                    break;
                case 'perUf':
                    _url = '/export/quantityPerUf';
                    break;
                case 'perFocus':
                    _url = '/export/quantityPerFocus';
                    break;
                case 'perActivities':
                    _url = '/export/quantityPerActivities';
                    break;
                case 'geral':
                    _url = '/export';
                    break;
                default:
                    break;
            }
            return this.handleResponse(await api.post(`${URL}${_url}`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async exportCommercialWallet(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/export/commercialWallet`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async exportRegionalWallet(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/export/regionalWallet`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listChartByRegional(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/quantityPerRegional`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listChartByUf(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/quantityPerUf`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listChartByFocus(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/quantityPerFocus`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listChartByOtherActions(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/quantityPerActivities`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}