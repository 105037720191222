import { FC, useEffect, useState } from 'react';
import './GoogleMaps.scss';
import { GoogleMap, useJsApiLoader, Marker, Circle, InfoWindow } from '@react-google-maps/api';

interface Point {
  lat: number;
  lng: number;
  label?: string;
}

interface Distance {
  label: string;
  distance: number;
}

interface GoogleMapsProps {
  zoom: number;
  points: Point[];
  showCircle?: boolean;
  radius?: number;
}

const GoogleMaps: FC<GoogleMapsProps> = ({ zoom, points, showCircle = false, radius = 5000 }) => {

  const [distances, setDistances] = useState<Distance[]>([]);
  const [selectedPoint, setSelectedPoint] = useState<Point | null>(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDejk2Ncoh7zgDDUvNKsotppPcJSGYekFE' ?? '',
    libraries: ['geometry']
  })

  useEffect(() => {
    if (isLoaded && window.google) {
      const center = points[0] ?? { lat: 0, lng: 0 };

      const calculateDistance = points.slice(1).map((x) => {
        const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
          new window.google.maps.LatLng(center?.lat, center?.lng),
          new window.google.maps.LatLng(x?.lat, x?.lng)
        );
        return {
          label: x?.label ?? `Local ${points?.indexOf(x) + 1}`,
          distance
        };
      });

      setDistances(calculateDistance);
    }
  }, [isLoaded, points]);

  const center = points[0] ?? { lat: 0, lng: 0 };

  return (
    <div className="GoogleMaps" data-testid="GoogleMaps">
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '400px' }}
          center={center}
          zoom={zoom}
          options={{
            disableDefaultUI: true,
            zoomControl: true,
            streetViewControl: true,
            fullscreenControl: true,
          }}
        >
          {points.map((point, index) => (
            <Marker
              key={index}
              position={point}
              onClick={() => setSelectedPoint(point)}
              options={{
                label: {
                  text: point?.label ?? `Local ${index + 1}`,
                  className: "map-marker"
                }
              }}
            >
              {selectedPoint === point && index > 0 && (
                <InfoWindow
                  position={point}
                  onCloseClick={() => setSelectedPoint(null)}
                >
                  <div className="custom-info-window">
                    <h6>{point?.label}</h6>
                    {index > 0 && (
                      <span><b>Distância: {(distances[index - 1]?.distance / 1000)?.toFixed(1)} KM</b></span>
                    )}
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))}

          {showCircle && points[0] && (
            <Circle
              center={points[0]}
              radius={radius}
              options={{
                fillColor: '#AA0000',
                strokeColor: '#AA0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillOpacity: 0.35,
              }}
            />
          )}
        </GoogleMap>
      ) : <></>}
    </div>
  );
}

export default GoogleMaps;
