import { NumericFormat } from "react-number-format";

const InputCurrencyDecimal = ({ ...inputProps }) => {
    const withValueCap = (inputObj: any) => {
        const { value } = inputObj;

        if (value <= inputProps.max) return true;

        return false;
    };

    return (
        <div className="InputCurrencyDecimal" data-testid="InputCurrencyDecimal">
            <NumericFormat
                // prefix='R$ '
                thousandSeparator={'.'}
                decimalSeparator={','}
                allowNegative={true}
                decimalScale={2}
                fixedDecimalScale={true}
                isAllowed={withValueCap}
                {...inputProps}
            />
        </div>
    );
}

export default InputCurrencyDecimal;

