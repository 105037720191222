import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SubsidiaryService } from "../../../services/Registrations/Company/SubsidiaryService/SubsidiaryService";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";
import { SubsidiaryModel } from "../../../models/Registrations/Company/Subsidiary/SubsidiaryModel";

interface ContextProps {
    subsidiarys: SubsidiaryModel[],
    subsidiarysOptions: ReactSelectOptions[],
    handleList: () => void,
}

export const SubsidiaryContext = createContext<ContextProps>({} as ContextProps);

interface SubsidiaryProviderProps {
    children: ReactNode;
}

export const SubsidiaryProvider = ({ children }: SubsidiaryProviderProps) => {
    const [subsidiarys, setSubsidiarys] = useState<SubsidiaryModel[]>([]);
    const [error, setError] = useState<string>('');
    const [subsidiarysOptions, setSubsidiarysOptions] = useState<ReactSelectOptions[]>([]);

    const service = new SubsidiaryService();

    const handleList = useCallback(async () => {
        try {
            const [_Response, _Error] = await service.list({ withPaginate: false });

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setSubsidiarys(_Response.data);
            return setError('');
        } catch (e) {
            return setError('Não foi possível carregar os tipos');
        }
    }, []);

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        setSubsidiarysOptions(
            subsidiarys
                ?.map((item: SubsidiaryModel) => {
                    return {
                        label: item.name,
                        value: item.id,
                        externalId: item.externalId
                    };
                })
        )
    }, [subsidiarys]);

    return (
        <SubsidiaryContext.Provider value={{
            subsidiarys,
            subsidiarysOptions,
            handleList,
        }}>
            {children}
        </SubsidiaryContext.Provider>
    );
}

export const useSubsidiary = () => useContext(SubsidiaryContext);