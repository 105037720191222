import React, { FC, useState } from 'react';
import './ReceiptDetailedTotals.scss';
import { useReceipt } from '../../../../providers/Commissioning/Receipt/ReceiptProvider';
import { Card, CardHeader, CardBody, Col, Offcanvas, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingColumns, faFileInvoiceDollar, faL, faSpinner } from '@fortawesome/free-solid-svg-icons';
import FormatMoney from '../../../../utils/FormatMoney';
import moment from 'moment';
import { TotalsModel } from '../../../../models/Commissioning/Receipt/TotalsModel';
import { format } from 'path';
import { useReceiptUnknown } from '../../../../providers/Commissioning/Receipt/ReceiptUnknown/ReceiptUnknownProvider';


interface ReceiptDetailedTotalsProps {
  show: boolean,
  onClose: any
}

const ReceiptDetailedTotals: FC<ReceiptDetailedTotalsProps> = ({ show, onClose }) => {

  const { detailedTotals, isLoadingTotals, isDataGeneral } = useReceipt();
  const { params } = useReceiptUnknown();

  const renderItem = (item: TotalsModel) => {
    return (
      <Row key={item.date} className='mb-2 d-flex align-items-center pb-2 border-bottom'>
        <Col className='text-sm'>
          <b>{item.name?.toUpperCase()}</b>
        </Col>
        <Col>
          <div className='text-sm font-weight-bolder d-flex flex-column'>
            <Row style={{ color: '#5c5eac' }} className='d-flex flex-nowrap'>
              <Col className='text-end'>
                Previsão:
              </Col>
              <Col>
                {FormatMoney(item.prevision)}
              </Col>
            </Row>
            <Row style={{ color: '#2dce89' }} className='d-flex flex-nowrap'>
              <Col className='text-end'>
                Recebimento:
              </Col>
              <Col>
                {FormatMoney(item.writeOff)}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }

  const renderContext = () => {

    let details = detailedTotals.filter((data:any) => data?.financials?.length > 0 || data?.types?.length > 0) ?? [];

    return (
      <>
        <Row>
          <Col md={12}>
            {
              details?.length > 0 ? (
                <>
                  {
                    detailedTotals.map((item:any) => (
                      <>
                        {
                          (item.financials.length > 0 || item.types.length > 0) && (
                            <>
                              <Row>
                                <Col sm={12} md={6}>
                                  <Card>
                                    <CardHeader>
                                      <div className='d-flex justify-content-between' style={{ color: 'var(--primary)' }}>
                                        <span>Financeira</span>
                                        <FontAwesomeIcon icon={faBuildingColumns} />
                                      </div>
                                    </CardHeader>
                                    <CardBody>
                                      {item?.financials && item?.financials?.length > 0 ?
                                        item?.financials?.map((data: TotalsModel) => (
                                          renderItem(data)
                                        ))
                                        :
                                        <div className='d-flex justify-content-center align-items-center text-sm'>
                                          Não existem itens para listar.
                                        </div>
                                      }
                                    </CardBody>
                                  </Card>
                                </Col>
                                <Col sm={12} md={6}>
                                  <Card className='mt-3 mt-md-0'>
                                    <CardHeader>
                                      <div className='d-flex justify-content-between' style={{ color: 'var(--primary)' }}>
                                        <span>Tipo</span>
                                        <FontAwesomeIcon icon={faFileInvoiceDollar} />
                                      </div>
                                    </CardHeader>
                                    <CardBody>
                                      {item?.types && item?.types?.length > 0 ?
                                        item?.types?.map((data: TotalsModel) => (
                                          renderItem(data)
                                        ))
                                        :
                                        <div className='d-flex justify-content-center align-items-center text-sm'>
                                          Não existem itens para listar.
                                        </div>
                                      }
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </>
                          )
                        }
                      </>
                    ))
                  } 
                </>
              ) : (
                <>
                  <div className='d-flex justify-content-center align-items-center text-sm'>
                    Não existem itens para listar.
                  </div>
                </>
              )
            }
          </Col>
        </Row>
      </>
    )
  }

return (
  <div className="ReceiptDetailedTotals" data-testid="ReceiptDetailedTotals">
    <Offcanvas show={show} placement='top' onHide={onClose} className='ReceiptDetailedTotals'>
      {!isLoadingTotals ?
        <>
          <Offcanvas.Header closeButton className='ReceiptDetailedTotals pb-0'>
            Totais Detalhados - {isDataGeneral && detailedTotals?.length > 0 ? (
                <>
                  {moment(detailedTotals[0]?.date).format('DD/MM/YYYY')}
                </>
              ) : (
                <>
                  {moment(params.startDate).format('DD/MM/YYYY')} à {moment(params.endDate).format('DD/MM/YYYY')}
                </>
              )
            }
          </Offcanvas.Header>
          <Offcanvas.Body className='ReceiptDetailedTotals'>
          {
            renderContext()
          }
          </Offcanvas.Body>
        </>
        :
        <div className='d-flex h-100 justify-content-center align-items-center'>
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      }
    </Offcanvas>
  </div>
)
};

export default ReceiptDetailedTotals;
