import { FC, useState } from "react";
import "./ProductionBySupervisor.scss";
import PageBase from "../../../../../PageBase/PageBase";
import { Col, Row } from "react-bootstrap";
import NavPills from "../../../../../NavPills/NavPills";
import TableMonthlyProduction from "../TableMonthlyProduction/TableMonthlyProduction";
import TableAnnualProduction from "../TableAnnualProduction/TableAnnualProduction";
import ChartParticipation from "../ChartParticipation/ChartParticipation";

interface ProductionBySupervisorProps {}

const TabEnum = {
  MONTHLY_PRODUCTION: "monthlyProduction",
  ANNUAL_PRODUCTION: "annualProduction",
  CHART_PARTICIPATION: "chartParticipation",
};

const ProductionBySupervisor: FC<ProductionBySupervisorProps> = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState<string>(
    TabEnum.MONTHLY_PRODUCTION
  );

  const renderContent = (): JSX.Element => {
    return (
      <>
        <Row>
          <Col md={12}>
            <NavPills
              mdMenu={12}
              options={[
                {
                  acronym: TabEnum.MONTHLY_PRODUCTION,
                  name: "Produção Mensal",
                  icon: <i className="fa-solid fa-table"></i>,
                  show: true,
                },
                {
                  acronym: TabEnum.ANNUAL_PRODUCTION,
                  name: "Produção Anual",
                  icon: <i className="fa-solid fa-table"></i>,
                  show: true,
                },
                {
                  acronym: TabEnum.CHART_PARTICIPATION,
                  name: "Gráfico de Participação",
                  icon: <i className="fa-solid fa-chart-pie"></i>,
                  show: true,
                },
              ]}
              current={currentActiveTab}
              setCurrent={setCurrentActiveTab}
            />
          </Col>
        </Row>
        {currentActiveTab === TabEnum.MONTHLY_PRODUCTION && (
          <TableMonthlyProduction />
        )}
        {currentActiveTab === TabEnum.ANNUAL_PRODUCTION && (
          <TableAnnualProduction />
        )}
        {currentActiveTab === TabEnum.CHART_PARTICIPATION && (
          <ChartParticipation />
        )}
      </>
    );
  };

  return (
    <div
      className="ProductionBySupervisor"
      data-testid="ProductionBySupervisor"
    >
      <PageBase content={renderContent()} showCardHeader={false} />
    </div>
  );
};

export default ProductionBySupervisor;
