import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { IndicatorManagementPasswordService } from "../../../services/Visits/IndicatorManagement/IndicatorManagementPasswordService";
import { IndicatorManagementPasswordModel } from "../../../models/Visits/IndicatorManagement/IndicatorManagementPasswordModel";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { useIndicatorManagementWalletVisits } from "./IndicatorManagementWalletProvider";

interface ContextProps {
    createdConcluded: IndicatorManagementPasswordModel[],
    createdInProgress: IndicatorManagementPasswordModel[],
    resetConcluded: IndicatorManagementPasswordModel[],
    resetInProgress: IndicatorManagementPasswordModel[],
    request: IndicatorManagementPasswordModel[],
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleCreatedConcluded: (isFirstPage: boolean) => Promise<any>,
    handleCreatedInProgress: (isFirstPage: boolean) => Promise<any>,
    handleResetConcluded: (isFirstPage: boolean) => Promise<any>,
    handleResetInProgress: (isFirstPage: boolean) => Promise<any>,
    handleRequest: (isFirstPage: boolean) => Promise<any>,
}

export const IndicatorManagementPasswordContext = createContext<ContextProps>({} as ContextProps);

interface IndicatorManagementPasswordProviderProps {
    children: ReactNode;
}

export const IndicatorManagementPasswordProvider = ({ children }: IndicatorManagementPasswordProviderProps) => {
    const [createdConcluded, setCreatedConcluded] = useState<IndicatorManagementPasswordModel[]>([]);
    const [createdInProgress, setCreatedInProgress] = useState<IndicatorManagementPasswordModel[]>([]);
    const [resetConcluded, setResetConcluded] = useState<IndicatorManagementPasswordModel[]>([]);
    const [resetInProgress, setResetInProgress] = useState<IndicatorManagementPasswordModel[]>([]);
    const [request, setRequest] = useState<IndicatorManagementPasswordModel[]>([]);
    const [params, setParams] = useState<any>();
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const { params: paramsWallet } = useIndicatorManagementWalletVisits();
    const service = new IndicatorManagementPasswordService();

    useEffect(() => {
        setParams({ ...paramsWallet, withPaginate: true, active: 'S' });
    }, [paramsWallet]);

    const handleCreatedConcluded = async (isFirstPage: boolean) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.listCreatedConcluded({ ...params, page: isFirstPage ? 1 : params?.page });
            setIsLoading(false);

            if (_Error) {
                setCreatedConcluded([]);
                setPages({});
                setError(_Error);
                return;
            }

            setCreatedConcluded(_Response?.data);
            setPages(_Response?.pages);
            setError('');
        } catch (e: any) {
            setIsLoading(false);
            setCreatedConcluded([]);
            setPages({});
            setError(e);
        }
    }

    const handleCreatedInProgress = async (isFirstPage: boolean) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.listCreatedInProgress({ ...params, page: isFirstPage ? 1 : params?.page });
            setIsLoading(false);

            if (_Error) {
                setCreatedInProgress([]);
                setPages({});
                setError(_Error);
                return;
            }

            setCreatedInProgress(_Response?.data);
            setPages(_Response?.pages);
            setError('');
        } catch (e: any) {
            setIsLoading(false);
            setCreatedInProgress([]);
            setPages({});
            setError(e);
        }
    }

    const handleResetConcluded = async (isFirstPage: boolean) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.listResetConcluded({ ...params, page: isFirstPage ? 1 : params?.page });
            setIsLoading(false);

            if (_Error) {
                setResetConcluded([]);
                setPages({});
                setError(_Error);
                return;
            }

            setResetConcluded(_Response?.data);
            setPages(_Response?.pages);
            setError('');
        } catch (e: any) {
            setIsLoading(false);
            setResetConcluded([]);
            setPages({});
            setError(e);
        }
    }

    const handleResetInProgress = async (isFirstPage: boolean) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.listResetInProgress({ ...params, page: isFirstPage ? 1 : params?.page });
            setIsLoading(false);

            if (_Error) {
                setResetInProgress([]);
                setPages({});
                setError(_Error);
                return;
            }

            setResetInProgress(_Response?.data);
            setPages(_Response?.pages);
            setError('');
        } catch (e: any) {
            setIsLoading(false);
            setResetInProgress([]);
            setPages({});
            setError(e);
        }
    }

    const handleRequest = async (isFirstPage: boolean) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.listRequest({ ...params, page: isFirstPage ? 1 : params?.page });
            setIsLoading(false);

            if (_Error) {
                setRequest([]);
                setPages({});
                setError(_Error);
                return;
            }

            setRequest(_Response?.data);
            setPages(_Response?.pages);
            setError('');
        } catch (e: any) {
            setIsLoading(false);
            setRequest([]);
            setPages({});
            setError(e);
        }
    }

    return (
        <IndicatorManagementPasswordContext.Provider value={useMemo(() => ({
            createdConcluded,
            createdInProgress,
            resetConcluded,
            resetInProgress,
            request,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleCreatedConcluded,
            handleCreatedInProgress,
            handleResetConcluded,
            handleResetInProgress,
            handleRequest,
        }), [
            createdConcluded,
            createdInProgress,
            resetConcluded,
            resetInProgress,
            request,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleCreatedConcluded,
            handleCreatedInProgress,
            handleResetConcluded,
            handleResetInProgress,
            handleRequest,
        ])}>
            {children}
        </IndicatorManagementPasswordContext.Provider>
    );
}

export const useIndicatorManagementPasswordVisits = () => useContext(IndicatorManagementPasswordContext);