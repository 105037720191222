import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react";
import { CovenantModel } from "../../../models/Registrations/Covenant/CovenantModel";
import { CovenantService } from "../../../services/Registrations/Covenant/CovenantService";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";

interface ContextProps {
    covenants: CovenantModel[],
    covenantsOptions: any[],
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => Promise<void>,
    handleSave: (payload: CovenantModel) => Promise<boolean>
}

export const CovenantContext = createContext<ContextProps>({} as ContextProps);

interface CovenantProviderProps {
    children: ReactNode;
}

export const CovenantProvider = ({ children }: CovenantProviderProps) => {
    const [covenants, setCovenants] = useState<CovenantModel[]>([]);
    const [covenantsOptions, setCovenantsOptions] = useState<any[]>([]);
    const [params, setParams] = useState<any>();
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const service = new CovenantService();

    const handleList = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                setCovenants([]);
                setCovenantsOptions([]);
                setPages({});
                setError(_Error);
                return;
            }

            setCovenants(_Response?.data);
            setPages(_Response?.pages);
            setCovenantsOptions(_Response?.data?.map((x: CovenantModel) => (
                {
                    value: x.id,
                    label: x.name,
                    externalId: x.externalId
                }
            )));
            setError('');
        } catch (e: any) {
            setIsLoading(false);
            setCovenants([]);
            setCovenantsOptions([]);
            setPages({});
            setError(e);
        }
    }

    const handleSave = async (payload: CovenantModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.save(payload);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e);
            return false;
        }
    }

    return (
        <CovenantContext.Provider value={useMemo(() => ({
            covenants,
            covenantsOptions,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleSave,
        }), [
            covenants,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleSave,
        ])}>
            {children}
        </CovenantContext.Provider>
    );
}

export const useCovenant = () => useContext(CovenantContext);