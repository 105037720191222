import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { ReportProvider } from '../../providers/Reports/Report/ReportProvider';

interface Props {
    element?: any;
    path?: string;
}

export const ReportRoute: FC<Props> = () => {
    return (
        <ReportProvider>
            <Outlet />
        </ReportProvider>
    );
}
