import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PagesPaginateModel } from "../../../../models/Paginate/PagesPaginate";
import { BiMonthlyProductionModel } from "../../../../models/BI/Dashboard/BiMonthlyProductionModel";
import { BiAnnualProductionModel } from "../../../../models/BI/Dashboard/BiAnnualProductionModel";

interface ContextProps {
    financialProductions: BiMonthlyProductionModel | undefined,
    setFinancialProductions: Dispatch<SetStateAction<BiMonthlyProductionModel | undefined>>,
    annualFinancialProductions: BiAnnualProductionModel | undefined,
    setAnnualFinancialProductions: Dispatch<SetStateAction<BiAnnualProductionModel | undefined>>,
    importationCurrentCommissions: any[],
    importationCommissionsResponsibles: any[],
    financialByCards: BiMonthlyProductionModel | undefined,
    setFinancialByCards: Dispatch<SetStateAction<BiMonthlyProductionModel | undefined>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    isLoadingAnnualFinancialProductions: boolean,
    isLoadingImporationCurrentCommission: boolean,
    isLoadingImporationCommissionResponsible: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
}

export const BiFinancialProductionContext = createContext<ContextProps>({} as ContextProps);

interface BiFinancialProductionProviderProps {
    children: ReactNode;
}

export const BiFinancialProductionProvider = ({ children }: BiFinancialProductionProviderProps) => {
    const [financialProductions, setFinancialProductions] = useState<BiMonthlyProductionModel>();
    const [annualFinancialProductions, setAnnualFinancialProductions] = useState<BiAnnualProductionModel>();
    const [importationCurrentCommissions, setImportationCurrentCommissions] = useState<any[]>([]);
    const [importationCommissionsResponsibles, setImportationCommissionsResponsibles] = useState<any[]>([]);
    const [financialByCards, setFinancialByCards] = useState<BiMonthlyProductionModel>();
    const [params, setParams] = useState<any>({ withPaginate: false });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingAnnualFinancialProductions, setIsLoadingAnnualFinancialProductions] = useState<boolean>(true);
    const [isLoadingImporationCurrentCommission, setIsLoadingImporationCurrentCommission] = useState<boolean>(true);
    const [isLoadingImporationCommissionResponsible, setIsLoadingImporationCommissionResponsible] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    return (
        <BiFinancialProductionContext.Provider value={{
            financialProductions,
            setFinancialProductions,
            annualFinancialProductions,
            setAnnualFinancialProductions,
            importationCurrentCommissions,
            importationCommissionsResponsibles,
            financialByCards,
            setFinancialByCards,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            isLoadingAnnualFinancialProductions,
            isLoadingImporationCurrentCommission,
            isLoadingImporationCommissionResponsible,
            error,
            setError,
        }}>
            {children}
        </BiFinancialProductionContext.Provider>
    );
}

export const useBiFinancialProduction = () => useContext(BiFinancialProductionContext);