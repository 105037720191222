import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { CovenantGroupModel } from '../../../../models/Registrations/Covenant/CovenantGroupModel';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useCovenantGroup } from '../../../../providers/Registrations/Covenant/CovenantGroupProvider';
import { Controller, useForm } from 'react-hook-form';
import './ModalCovenantGroupInsert.scss';
import { Col, Row } from 'react-bootstrap';
import { useAuth } from '../../../../providers/AuthProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validate';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import makeAnimated from 'react-select/animated';
import { yesNoOption } from '../../../../models/YesNoOptions';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useMasterCovenantGroup } from '../../../../providers/Registrations/Covenant/MasterCovenantGroupProvider';

interface ModalCovenantGroupInsertProps {
  show: boolean,
  onClose: any,
  itemEdit?: CovenantGroupModel
  setItemEdit?: Dispatch<SetStateAction<CovenantGroupModel | undefined>>
}

const ModalCovenantGroupInsert: FC<ModalCovenantGroupInsertProps> = ({ show, onClose, itemEdit, setItemEdit }) => {
  const [selectedMasterGroup, setSelectedMasterGroup] = useState<any>();
  const [selectedClosure, setSelectedClosure] = useState<any>();

  const { handleList, handleSave, isLoading } = useCovenantGroup();
  const { masterGroupOptions } = useMasterCovenantGroup();
  const { onInvalid } = useAuth();
  const { handleSubmit, register, setValue, reset, control, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });
  const animatedComponents = makeAnimated();

  useEffect(() => {
    if (show) {
      if (itemEdit) {
        setValue('name', itemEdit?.name ?? '');
        setSelectedClosure(yesNoOption?.find((x: any) => x.value === itemEdit?.closure));
        setSelectedMasterGroup(masterGroupOptions?.find((x: any) => x.value === itemEdit?.idMasterGroup));
      }
    } else {
      setValue('name', '');
      setSelectedClosure(undefined);
      setSelectedMasterGroup(undefined);
      setItemEdit && setItemEdit(undefined);
      reset();
    }
  }, [show]);

  useEffect(() => {
    setValue('idMasterGroup', selectedMasterGroup);
  }, [selectedMasterGroup]);

  useEffect(() => {
    setValue('closure', selectedClosure);
  }, [selectedClosure]);

  const onSubmit = async (data: any) => {
    const _data = { ...data };

    _data.id = itemEdit?.id;
    _data.name = _data?.name?.trim()?.toUpperCase();
    _data.closure = _data?.closure?.value;
    _data.idMasterGroup = _data?.idMasterGroup?.value?.toString();
    _data.active = 'S';
    _data.blocked = 'N';
    _data.dataRegister = itemEdit?.dateRegister ?? moment(new Date()).format('YYYY-MM-DD');

    const response = await handleSave(_data).then();
    if (response) {
      toast.success('Salvo com sucesso!');
      handleList().then();
      onClose();
    }
  }

  return (
    <ModalDefault
      title={`${itemEdit ? 'Editar Grupo de Convênio ' + itemEdit?.name : 'Novo Grupo de Convênio'}`}
      show={show}
      onClose={onClose}
      sizeModal={'lg'}
      showFooter={true}
      buttonText={isLoading ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoading}
      disabledSubmit={isLoading}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <div className="ModalCovenantGroupInsert" data-testid="ModalCovenantGroupInsert">
        <Row>
          <Col className='form-group' xs={12}>
            <label>Nome</label>
            <input
              className={`form-control ${onInvalid(errors?.name)}`}
              type='text'
              minLength={3}
              maxLength={150}
              {...register('name')}
            />
            <ErrorMessage name='Nome' type={errors?.name?.type} min={5} max={150} />
          </Col>

          <Col className='form-group' xs={12} md={6}>
            <label>Grupo Master</label>
            <Controller
              control={control}
              name='idMasterGroup'
              render={({ field: { name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isSearchable
                  placeholder="Selecione..."
                  options={masterGroupOptions}
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                  defaultValue={selectedMasterGroup}
                  value={selectedMasterGroup}
                  onChange={(val: any) => {
                    setSelectedMasterGroup(val);
                  }}
                />
              )}
            />
            <ErrorMessage name='Grupo Master' type={errors?.idMasterGroup?.type} />
          </Col>

          <Col className='form-group' xs={12} md={6}>
            <label>Fechamento</label>
            <Controller
              control={control}
              name='closure'
              render={({ field: { name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isSearchable
                  placeholder="Selecione..."
                  options={yesNoOption}
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                  defaultValue={selectedClosure}
                  value={selectedClosure}
                  onChange={(val: any) => {
                    setSelectedClosure(val);
                  }}
                />
              )}
            />
            <ErrorMessage name='Fechamento' type={errors?.closure?.type} />
          </Col>
        </Row>
      </div>
    </ModalDefault>
  )
};

export default ModalCovenantGroupInsert;
