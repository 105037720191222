import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { toast } from "react-toastify";
import { VisitsAgentsLifeService } from "../../../services/Visits/VisitsAgentsLife/VisitsAgentsLifeService";
import { VisitsAgentsLifeParams } from "../../../models/Visits/VisitsAgentsLife/VisitsAgentsLifeParams";
import { VisitsAgentsLifeModel } from "../../../models/Visits/VisitsAgentsLife/VisitsAgentsLifeModel";

interface ContextProps {
    agentsLife: VisitsAgentsLifeModel,
    setAgentsLife: Dispatch<SetStateAction<VisitsAgentsLifeModel>>,
    agentsLifes: VisitsAgentsLifeModel[],
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: (params: VisitsAgentsLifeParams) => Promise<any>,
}

export const VisitsAgentsLifeContext = createContext<ContextProps>({} as ContextProps);

interface VisitsAgentsLifeProviderProps {
    children: ReactNode;
}

export const VisitsAgentsLifeProvider = ({ children }: VisitsAgentsLifeProviderProps) => {
    const [agentsLife, setAgentsLife] = useState<VisitsAgentsLifeModel>({} as VisitsAgentsLifeModel);
    const [agentsLifes, setAgentsLifes] = useState<VisitsAgentsLifeModel[]>([]);
    const [params, setParams] = useState<VisitsAgentsLifeParams>({} as VisitsAgentsLifeParams);
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new VisitsAgentsLifeService();

    const handleList = async (params: VisitsAgentsLifeParams) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setAgentsLifes(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar a vida do agente');
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    return (
        <VisitsAgentsLifeContext.Provider value={useMemo(() => ({
            agentsLife,
            setAgentsLife,
            agentsLifes,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList
        }), [agentsLife,
            setAgentsLife,
            agentsLifes,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList])}>
            {children}
        </VisitsAgentsLifeContext.Provider>
    );
}

export const useVisitsAgentsLife = () => useContext(VisitsAgentsLifeContext);