import { FC } from 'react';
import './LogScreen.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import { useLog } from '../../../providers/Logs/LogProvider';
import { usePaginate } from '../../../providers/PaginateProvider';
import { LogModel } from '../../../models/Logs/LogScreen/LogModel';
import moment from 'moment';
import AppPagination from '../../../components/AppPagination/AppPagination';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import PageBase from '../../../components/PageBase/PageBase';
import SidebarLogScreenFilter from '../../../components/Logs/LogScreen/SidebarLogScreenFilter/SidebarLogScreenFilter';

interface LogScreenProps { }

const LogScreen: FC<LogScreenProps> = () => {
  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { pages, params, setParams, isLoading, isLoadingFile, error, logs, handleExcel } = useLog();
  const { handlePaginate } = usePaginate();

  const renderList = (): JSX.Element => {
    return (
      <>
        {logs?.length > 0 ? (
          <>
            {logs.map((x: LogModel) => (
              <tr key={x.id} className="text-sm">
                <td className="text-center">{x.id}</td>
                <td>{x?.user?.name}</td>
                <td>{x?.profile?.name}</td>
                <td>{x?.user?.company?.name}</td>
                <td>
                  <a href={`${x.hostname}${x.route}`} target="_blank" className="link">
                    <p className="text-sm m-0">{x.hostname}{x.route}</p>
                  </a>
                </td>
                <td>
                  <p className="text-sm mb-1"><b>IP:</b> {x.ipClient}</p>
                  <p className="text-sm mb-1"><b>IP CLOUDFLARE:</b> {x.ipCloudflare}</p>
                  <p className="text-sm mb-1"><b>NAVEGADOR:</b> {x.browser} {x.browserVersion}</p>
                  <p className="text-sm mb-0"><b>SISTEMA OPERACIONAL:</b> {x.platform}</p>
                </td>
                <td className="text-center">{moment(x.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={7} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7 text-center" scope="col">#</th>
                  <th className="text-uppercase opacity-7" scope="col">Usuário</th>
                  <th className="text-uppercase opacity-7" scope="col">Nível de Acesso</th>
                  <th className="text-uppercase opacity-7" scope="col">Promotora</th>
                  <th className="text-uppercase opacity-7" scope="col">Tela</th>
                  <th className="text-uppercase opacity-7" scope="col">Dados de Acesso</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Data de Acesso</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}
        
        {logs?.length > 0 && (
              <div>
                <nav aria-label="...">
                  <AppPagination
                    pages={pages}
                    handlePaginate={handlePaginate}
                    params={params}
                    setParams={setParams}
                    style={{ backgroundColor: '#fff' }}
                  />
                </nav>
              </div>
            )}
      </>
    );
  }

  return (
    <MasterPage
      title="Acessos por Telas"
      icon="fas fa-laptop-file"
      footerFixed
      footerInverse
    >
      <div className="LogScreen" data-testid="LogScreen">
        <PageBase
          title="Acessos por Telas"
          subtitle="Listagem de logs de acesso por telas do sistema."
          handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          handleExcel={handleExcel}
          error={error}
          content={renderContent()}
          isLoadingFile={isLoadingFile}
          hasFilter
          hasExcelPdf
        />

        <SidebarLogScreenFilter />
      </div>
    </MasterPage>
  );
}

export default LogScreen;
