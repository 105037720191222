import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import './ReceiptTypeWeekly.scss';
import { Card, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

interface Day {
  id: number;
  name: string;
}

interface ReceiptTypeWeeklyProps {
  itemIdEdit: string | number | undefined,
  receiptTypes: any[],
  setReceiptTypes: any
}

const ReceiptTypeWeekly: FC<ReceiptTypeWeeklyProps> = ({ receiptTypes, setReceiptTypes, itemIdEdit }) => {

  const daysOfWeek: Day[] = [
    { id: 1, name: 'SEG' },
    { id: 2, name: 'TER' },
    { id: 3, name: 'QUA' },
    { id: 4, name: 'QUI' },
    { id: 5, name: 'SEX' }
  ];

  const handleCheckboxChange = (item: any, e: any) => {
    const _item = receiptTypes?.find((x: any, key: number) => key === itemIdEdit);
    let arrayUpdate = _item?.receipt?.periods;
    if (e?.target?.checked) {
      arrayUpdate?.push(item?.id);
    } else {
      arrayUpdate = _item?.receipt?.periods?.filter((x: number) => x !== item?.id);
    }
    _item.receipt.periods = arrayUpdate;
    receiptTypes[receiptTypes?.findIndex((x: any, key: number) => key === itemIdEdit)] = _item;
    setReceiptTypes(JSON.parse(JSON.stringify(receiptTypes)));
  };

  const isChecked = (day: any): boolean => {
    const item = receiptTypes?.find((x: any, key: number) => key === itemIdEdit);
    return !!item?.receipt?.periods?.find((x: number) => x === day?.id);
  }

  return (
    <div className="ReceiptTypeWeekly" data-testid="ReceiptTypeWeekly">
      <Card>
        <Card.Header>
          <span>
            <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
            Semanal - Selecione os dias recebidos na semana
          </span>
        </Card.Header>
        <Card.Body className="d-flex flex-wrap justify-content-beetwen">
          {daysOfWeek?.map(item => (
            <Col key={item?.id} xs={12} md={6} lg={4} xl={3} xxl={2} className="form-check form-switch">
              <label className="form-check-label" htmlFor={'dayCheckbox'}>
                {item?.name}
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                name='dayCheckbox'
                checked={isChecked(item)}
                onChange={(e) => handleCheckboxChange(item, e)}
              />
            </Col>
          ))}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ReceiptTypeWeekly;
