import { FC, useEffect, useState } from 'react';
import './AuthenticatorValidationAlternative.scss';
import { useStyle } from '../../../providers/Style/StyleProvider';
import { Card, Col, Container, Row, Image, CardLink, CardBody } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faSpinner, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ReactCodeInput from 'react-verification-code-input';
import { useForm } from 'react-hook-form';
import { onValue, ref } from 'firebase/database';
import { realtimedb } from '../../../firebase';
import Spinner from '../../../components/Spinner/Spinner';
import { useAuth } from '../../../providers/AuthProvider';
import { useAuthenticatorValidationAlternative } from '../../../providers/Authenticator/AuthenticatorValidationAlternativeProvider';
import NavbarSite from '../../../components/NavbarSite/NavbarSite';
import StoreLinks from '../../../components/StoreLinks/StoreLinks';

interface AuthenticatorValidationAlternativeProps { }

type FormValues = {
  code: string;
};

const AuthenticatorValidationAlternative: FC<AuthenticatorValidationAlternativeProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(60);
  const [code, setCode] = useState<string>('');
  const [disabled, setDisable] = useState<boolean>(true);
  const [tokenValidation, setTokenValidation] = useState<string>('');

  const { isLoading: isLoadingStyle, style, urlDomus, handleStyle } = useStyle();
  const { accessToken } = useAuth();
  const { phoneSent, handleSend, handleVerify } = useAuthenticatorValidationAlternative();
  const navigate = useNavigate();

  const defaultValues = { code: '' } as FormValues;
  const { handleSubmit, setValue, formState: { errors } } = useForm<FormValues>({ mode: 'onChange', defaultValues });

  useEffect(() => {
    handleStyle().then();
  }, []);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    if (counter === 0) {
      setDisable(false);
    }

    if (timer) {
      return () => clearInterval(timer);
    }
  }, [counter]);

  useEffect(() => {
    setTokenValidation('');
    setIsLoadingSubmit(false);
    setCounter(60);
  }, []);

  useEffect(() => {
    if (code) {
      setValue('code', code);
    }
  }, [code]);

  useEffect(() => {
    if (urlDomus && tokenValidation) {
      const query = ref(realtimedb, 'tokens');
      return onValue(query, (snapshot) => {
        const data = snapshot.val();

        if (snapshot.exists()) {
          const _response = Object.values(data).map((v: any) => v)?.filter((item: any) => item?.token === tokenValidation).shift();

          switch (_response?.response) {
            case 1:
              window.location.href = `${urlDomus}/app/api/v1/auth/authTokenSB?token=${accessToken}`;
              break;
            case 0:
              window.location.href = `${urlDomus}`;
              break;
          }
        }
      });
    }
  }, [urlDomus, tokenValidation]);

  const onSubmit = async (data: any) => {
    setIsLoadingSubmit(true);

    handleVerify(data?.code).then((response: boolean) => {
      if (response) {
        window.location.href = `${urlDomus}/app/api/v1/auth/authTokenSB?token=${accessToken}`;
      }
    })

    setIsLoadingSubmit(false);
  }

  useEffect(() => {
    setIsLoading(true);
    if (accessToken) {
      handleSend().then(() => {
        setIsLoading(false);
      });
    }
  }, [accessToken]);

  return (
    <main className="AuthenticatorValidationAlternative main-content main-content-bg mt-0 h-100" data-testid="AuthenticatorValidationAlternative">
      {(!isLoadingStyle && style) && (
        <>
          <NavbarSite />
          <div className={`page-header min-vh-100`}>
            <Container>
              <Row className="justify-content-center align-items-center">
                <Col md={9} xs={12}>
                  <Row className="justify-content-center align-items-center">
                    <Col md={6} xs={12}>
                      <StoreLinks />
                    </Col>
                    <Col md={6} xs={12}>
                      <Row className="justify-content-center">
                        <Col md={12}>
                          <Card>
                            <CardBody>
                              {!isLoading ? 
                                (
                                  <>
                                    <Row className="justify-content-center align-items-center">
                                      <Col md={10} xs={10}>
                                        <h4 className="mb-0 mt-1"> Verificação em duas etapas </h4>
                                      </Col>
                                      <Col md={2} xs={2} className="text-center">
                                        <Image src={require('./../../../assets/img/padlock.svg').default} alt="lock" fluid />
                                      </Col>
                                      <Col md={12} xs={12} className="my-md-4 my-2">
                                        <p className="text-justify mb-0">
                                          Acabamos de enviar o código via SMS para o número <b> {phoneSent} </b>. Se ainda não chegou, verifique se o seu celular está com sinal. Caso não receba, solicite novamente o reenvio do código.
                                        </p>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={12}>
                                        <p className="mb-0 mt-md-0 mt-2"> <small> Insira aqui o código recebido* </small> </p>
                                      </Col>
                                      <Col md={12}>
                                        <ReactCodeInput
                                            type="number"
                                            autoFocus={true}
                                            fields={4}
                                            className="code"
                                            onChange={(value: string) => setCode(value)}
                                            fieldWidth={98}
                                        />
                                        {errors.code?.type === 'required' && (
                                          <p className="error">Código SMS é obrigatório</p>)}
                                        {errors.code?.type === 'min' && (<p className="error">Deve conter 4 caracteres</p>)}
                                      </Col>
                                    </Row>
                                    
                                    <Row>
                                      <Col md={12} className="text-start">
                                        <button
                                            type="button"
                                            className={`btn btn-link w-100 my-2 mb-0 p-0 text-start`}
                                            disabled={isLoadingSubmit || counter > 0 || disabled}
                                            onClick={() => {
                                              setDisable(true);
                                              handleSend();
                                              setCounter(60);
                                            }}
                                          >
                                            {!isLoadingSubmit ? (
                                              `Reenviar código (${counter})`
                                            ) : (
                                              <FontAwesomeIcon icon={faSpinner} spin />
                                            )}
                                        </button>
                                      </Col>
                                      <Col md={12} className="text-center">
                                        <button
                                          type="button"
                                          className={`btn btn-primary text-white w-100 my-2 p-3`}
                                          onClick={handleSubmit(onSubmit)}
                                          disabled={isLoadingSubmit || (code.length) < 4}
                                        >
                                          {!isLoadingSubmit ? (
                                          'Continuar'
                                          ) : (
                                          <FontAwesomeIcon icon={faSpinner} spin />
                                          )}
                                        </button>
                                      </Col>
                                      <Col md={12}>
                                        <button
                                          type="button"
                                          className={`btn btn-link w-100 text-start p-0 color-link`}
                                          disabled={isLoadingSubmit}
                                          onClick={() => navigate(-1)}
                                        >
                                          <FontAwesomeIcon icon={faArrowLeft} /> Voltar
                                        </button>
                                      </Col>
                                    </Row>
                                  </>
                                ) : (
                                  <>
                                    <div className='mt-12'>
                                      <Spinner backgroundColor='white' opacity='1' position='position-relative' />
                                    </div>
                                  </>
                                ) 
                              }
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}

      {isLoadingStyle && (
        <div className='mt-12'>
          <Spinner backgroundColor='white' opacity='1' />
        </div>
      )}
    </main>
  );
}

export default AuthenticatorValidationAlternative;
