import React, { FC, useEffect, useState } from 'react';
import './ReceiptTypePredefined.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import InputDate from '../../../InputDate/InputDate';

interface ReceiptTypePredefinedProps {
  itemIdEdit: string | number | undefined,
  receiptTypes: any[],
  setReceiptTypes: any
}


const ReceiptTypePredefined: FC<ReceiptTypePredefinedProps> = ({ receiptTypes, setReceiptTypes, itemIdEdit }) => {
  const [startDate, setStartDate] = useState<any>();
  const [datePredefined, setDatePredefined] = useState<any[]>([]);
  const [isValidStartDate, setIsValidStartDate] = useState<boolean>(true)

  useEffect(() => {
    setIsValidStartDate(isValid(startDate));
  }, [startDate]);

  useEffect(() => {
    const _item = receiptTypes?.find((x: any, key: number) => key === itemIdEdit);
    setDatePredefined(_item?.receipt?.periods?.map((x: any) => moment(x).format('DD/MM/YYYY')));
  }, [receiptTypes])

  const isValid = (date: any) => {
    return moment(date, 'YYYY-MM-DD', true).isValid();
  };

  const handleChangeAddDate = () => {
    if (isValidStartDate) {
      const _item = receiptTypes?.find((x: any, key: number) => key === itemIdEdit);
      _item?.receipt?.periods?.push(moment(startDate).format('YYYY-MM-DD'))
      receiptTypes[receiptTypes?.findIndex((x: any, key: number) => key === itemIdEdit)] = _item
      setReceiptTypes(JSON.parse(JSON.stringify(receiptTypes)));
      setDatePredefined([...datePredefined, moment(startDate).format('DD/MM/YYYY')]);
      setStartDate('');
    }
  };

  const handleDeleteDate = (index: number) => {
    const _item = receiptTypes?.find((x: any, key: number) => key === itemIdEdit);
    _item?.receipt?.periods?.splice(index, 1)
    receiptTypes[receiptTypes?.findIndex((x: any, key: number) => key === itemIdEdit)] = _item
    setReceiptTypes(JSON.parse(JSON.stringify(receiptTypes)));

    const _dates = [...datePredefined];
    _dates?.splice(index, 1);
    setDatePredefined(_dates);
  }

  return (
    <div className="ReceiptTypePredefined" data-testid="ReceiptTypePredefined">
      <Card>
        <Card.Header>
          <span>
            <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
            Pré Definido - Defina as datas de verificação
          </span>
        </Card.Header>
        <Card.Body>

          <Row className="mt-1">
            <Col xs={12} md={8} lg={6} xxl={4} >
              <InputDate
                value={startDate}
                onChange={setStartDate}
                endDate={'undefined'}
              />
            </Col>
            <Col xs={12} md={4} lg={2} xxl={2}>
              <button className="btn btn-primary" onClick={handleChangeAddDate} disabled={!isValidStartDate}>
                Adicionar
              </button>
            </Col>
          </Row>

          <Row >
            {datePredefined?.map((date, index) => (
              <Col sm={12} md={6} xl={4} xxl={3} key={index} className="mt-3" >
                <span className=" me-2 badge" style={{ backgroundColor: '#5c5eac' }}>
                  {date}
                  <button
                    className="btn btn-icon-only ms-2 button-color"
                    onClick={() => handleDeleteDate(index)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </span>
              </Col>
            ))}
          </Row>

        </Card.Body>
      </Card>
    </div>
  );
};

export default ReceiptTypePredefined;
