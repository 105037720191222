import React, { FC, useEffect } from 'react';
import './ModalVisitsAnalysisDetails.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { Card, Col, Row } from 'react-bootstrap';
import { useVisitsAnalysisDetails } from '../../../../providers/Visits/VisitsAnalysis/VisitsAnalysisDetailsProvider';
import VisistsAnalysisBarChart from '../VisistsAnalysisBarChart/VisistsAnalysisBarChart';
import moment from 'moment';
import SkeletonBar from '../../../SkeletonBar/SkeletonBar';
import { useStyle } from '../../../../providers/Style/StyleProvider';
import FormatNumber from '../../../../utils/FormatNumber';
import FormatMoney from '../../../../utils/FormatMoney';
import Skeleton from 'react-loading-skeleton';
import { Doughnut } from 'react-chartjs-2';
import ExportButtons from '../../../ExportButtons/ExportButtons';

interface ModalVisitsAnalysisDetailsProps {
  show: boolean;
  onClose: () => void;
  userVisitor: string;
  userVisitorId: number;
  startDate: string;
  endDate: string;
}

const ModalVisitsAnalysisDetails: FC<ModalVisitsAnalysisDetailsProps> = ({ show, onClose, userVisitor, userVisitorId, startDate, endDate }) => {
  const {
    details,
    visitsChartByDay,
    visitsChartByTypeContact,
    params,
    setParams,
    paramsChartByDay,
    setParamsChartByDay,
    paramsChartByTypeContact,
    setParamsChartByTypeContact,
    isLoadingChartByDay,
    isLoadingChartByTypeContact,
    handleExcelByDay,
    handleExcelByTypeContact
  } = useVisitsAnalysisDetails();
  const { style } = useStyle();

  const handleClose = async () => {
    onClose();
    setParams(null);
    setParamsChartByDay(null);
    setParamsChartByTypeContact(null);
  }

  useEffect(() => {
    if (show && userVisitorId > 0) {
      setParams({ ...params, ...{ userVisitorId, startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') } });
      setParamsChartByDay({ ...paramsChartByDay, ...{ userVisitorId, startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') } });
      setParamsChartByTypeContact({ ...paramsChartByTypeContact, ...{ userVisitorId, startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') } });
    }
  }, [show, userVisitorId]);

  return (
    <ModalDefault
      title={`Detalhamento de Visitas`}
      show={show}
      onClose={handleClose}
      sizeModal={'xl'}
      backdrop="static"
    >
      <div className="ModalVisitsAnalysisDetails" data-testid="ModalVisitsAnalysisDetails">
        <Row className="mb-4">
          <Col>
            <Card className="h-100">
              <Card.Header className="pb-0">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h6 className="surtitle text-gray m-0 text-start">
                      {moment(startDate).format('DD/MM/YYYY')} à {moment(endDate).format('DD/MM/YYYY')} {/** - {details?.qtDiaUtil} dias úteis */} - {userVisitor}
                    </h6>
                  </div>
                  <div>
                    <ExportButtons
                      getExcel={handleExcelByDay}
                    />
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                {!isLoadingChartByDay ? (
                  <VisistsAnalysisBarChart
                    labels={visitsChartByDay?.map((item: any) => moment(item?.date).format('DD/MMM').toUpperCase())}
                    data={visitsChartByDay?.map((item: any) => item?.quantity)}
                    max={Math.trunc(Math.max.apply(Math, visitsChartByDay?.map(o => o.quantity)))}
                    height={80}
                  />
                ) : (
                  <SkeletonBar
                    numberOfBars={14}
                    minHeight={70}
                    maxHeight={280}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="h-100">
              <Card.Header className="pb-0">
                <h6 className="surtitle text-gray m-0 text-start">Sintético</h6>
              </Card.Header>
              <Card.Body>
                {/* <div className="table-responsive">
                  <table className="table table-flush table-striped m-0 w-100">
                    <tbody>
                      <tr>
                        <td>
                          Total de Visitas: <b>{details?.qtVisita}</b>
                        </td>
                        <td>
                          Carteira Total: <b>{details?.qtCarteira}</b>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-wrap">
                          Quantidade de agentes atendidos
                        </td>
                        <td>
                          <b>{details?.qtdeTotalAtendido} ({FormatNumber(details?.percentualCarteiraVisitado)}%)</b>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-wrap">
                          Média de agentes por dia
                        </td>
                        <td>
                          <b>
                            {FormatNumber(details?.mediaDiaria)}<br />
                            <span className="text-secondary">(Média {style?.name}: {FormatNumber(details?.mediaBevi)}%)</span>
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-wrap">
                          Projeção de atendimento do restante da carteira
                        </td>
                        <td>
                          <b>
                            {details?.mesesProjecaoTerminoAtendimentoTotalCarteira} dias
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-wrap">
                          Parceiro mais visitado
                        </td>
                        <td className="text-wrap">
                          <b>
                            {details?.maisVisitado?.codAgente} - {details?.maisVisitado?.nomeAgente}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-wrap">
                          Quantidade: <b>{details?.maisVisitado?.qtVisita}</b>
                        </td>
                        <td className="text-wrap">
                          Produção 30 dias: <b>{FormatMoney(details?.maisVisitado?.prod30d)}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}
                <p className="text-center">Em atualização</p>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="h-100">
              <Card.Header className="pb-0">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h6 className="surtitle text-gray m-0 text-start">
                      Por Tipo de Contato
                    </h6>
                  </div>
                  <div>
                    <ExportButtons
                      getExcel={handleExcelByTypeContact}
                    />
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="text-center">
                <div className="d-flex align-items-center justify-content-center h-100">
                  {!isLoadingChartByTypeContact ? (
                    <Doughnut
                      height={400}
                      data={{
                        labels: visitsChartByTypeContact?.map((item: any) => item?.typeContact),
                        datasets: [
                          {
                            data: visitsChartByTypeContact?.map((item: any) => item?.percent),
                            backgroundColor: ["#7779BB", "#222343", "#25CBDB", "#454787", "#D987FA", "#AF7AC5", "#AED6F1"],
                          }
                        ]
                      }}
                      options={
                        {
                          responsive: true,
                          maintainAspectRatio: false,
                          cutout: '0',
                          interaction: {
                            intersect: true,
                            mode: 'index',
                          },
                          plugins: {
                            legend: {
                              position: 'bottom'
                            },
                            tooltip: {
                              enabled: true,
                              callbacks: {
                                label: (value) => FormatNumber(Number(value.raw)) + '%'
                              }
                            },
                            datalabels: {
                              anchor: 'end',
                              display: 'auto',
                              color: '#333',
                              align: 'end',
                              offset: 25,
                              backgroundColor: (data) => {
                                return '#E5E5E5';
                              },
                              borderRadius: 5,
                              formatter: (value) => {
                                return FormatNumber(Number(value)) + '%';
                              },
                            }
                          },
                          layout: {
                            padding: {
                              top: 50,
                              bottom: 50,
                            }
                          }
                        }
                      }
                    />
                  ) : (
                    <Skeleton
                      circle
                      width={400}
                      height={400}
                    />
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
}

export default ModalVisitsAnalysisDetails;
