import React, { FC, useEffect, useState } from 'react';
import './SidebarContractFormConfigFilter.scss';
import SidebarFilter from '../../../../SidebarFilter/SidebarFilter';
import { Controller, useForm } from 'react-hook-form';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { ContractFormConfigParamsModel } from '../../../../../models/Tools/Configurations/ContractForm/ContractFormConfigParamsModel';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { customStyles } from '../../../../../models/SelectCustomStyles';
import { useFinancialProcess } from '../../../../../providers/Proposals/Process/FinancialProcessProvider';
import { useContractForm } from '../../../../../providers/Proposals/ContractForm/ContractFormProvider';
import InputDateRange from '../../../../InputDateRange/InputDateRange';
import moment from 'moment';
import { useContractFormConfig } from '../../../../../providers/Tools/Configurations/ContractFormConfigProvider';
import { yesNoOption } from '../../../../../models/YesNoOptions';

interface SidebarContractFormConfigFilterProps { }

const defaultValues: ContractFormConfigParamsModel = {
  visualize: undefined,
  startDate: '',
  endDate: '',
  financialId: undefined,
  contractFormId: undefined,
  name: ''
}

const previewOptions: any[] = [
  { value: 1, label: 'Configurados' },
  { value: 2, label: 'Não Configurados' },
];

const SidebarContractFormConfigFilter: FC<SidebarContractFormConfigFilterProps> = () => {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const { handleSubmit, control, register, setValue } = useForm<ContractFormConfigParamsModel>({ defaultValues });
  const { params, setParams, handleList: handleListContractFormConfig } = useContractFormConfig();
  const { handleList: handleListFinancials, financialsOptions } = useFinancialProcess();
  const { contractFormsOptions } = useContractForm();

  const animatedComponents = makeAnimated();

  useEffect(() => {
    if (financialsOptions?.length === 0) {
      handleListFinancials();
    }
  }, []);

  useEffect(() => {
    handleListContractFormConfig().then();
  }, [params]);

  const onSubmit = async (data: any) => {
    const _data = JSON.parse(JSON.stringify(data));

    if (startDate) {
      _data.startDate = moment(startDate).format('YYYY-MM-DD');
    }

    if (endDate) {
      _data.endDate = moment(endDate).format('YYYY-MM-DD');
    }

    setParams(_data);
  }

  return (
    <SidebarFilter
      handleSubmit={handleSubmit(onSubmit)}
    >
      <div className="SidebarContractFormConfigFilter" data-testid="SidebarContractFormConfigFilter">
        <Row>
          <Col className="form-group">
            <label htmlFor='visualize'>Visualizar</label>
            <Controller
              name="visualize"
              control={control}
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  isSearchable
                  options={previewOptions}
                  placeholder="Selecione..."
                  className={`form-control p-0`}
                  components={animatedComponents}
                  onChange={(val: any) => {
                    onChange(val?.value || 'all');
                  }}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                />
              )}
            />
          </Col>
        </Row>

        <Row>
          <Col className="form-group">
            <label htmlFor='name'>Descrição</label>
            <input
              className='form-control'
              type='text'
              {...register('name')}
            />
          </Col>
        </Row>

        <Row>
          <Col className="form-group">
            <label htmlFor="financialId">Financeira</label>
            <Controller
              name="financialId"
              control={control}
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  isSearchable
                  options={financialsOptions}
                  placeholder="Selecione..."
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  onChange={(val: any) => {
                    onChange(val?.value || undefined);
                  }}
                  styles={customStyles}
                />
              )}
            />
          </Col>
        </Row>

        <Row>
          <Col className="form-group">
            <label htmlFor='contractFormId'>Forma Contrato</label>
            <Controller
              name="contractFormId"
              control={control}
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  isSearchable
                  options={contractFormsOptions}
                  placeholder="Selecione..."
                  className={`form-control p-0`}
                  components={animatedComponents}
                  onChange={(val: any) => {
                    onChange(val?.value || undefined);
                  }}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                />
              )}
            />
          </Col>
        </Row>

        <Row>
          <Col className="form-group">
            <label htmlFor='active'>Ativos</label>
            <Controller
              name="active"
              control={control}
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  isSearchable
                  options={yesNoOption}
                  placeholder="Selecione..."
                  className={`form-control p-0`}
                  components={animatedComponents}
                  onChange={(val: any) => {
                    onChange(val?.value || undefined);
                  }}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                />
              )}
            />
          </Col>
        </Row>

        <Row>
          <Col className="form-group">
            <label>Data Cadastro</label>
            <InputDateRange
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setValue={setValue}
              nameStartDate='startDate'
              nameEndDate='endDate'
              endLabelText=''
              startLabelText=''
            />
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  )
};

export default SidebarContractFormConfigFilter;
