import { FC, useEffect, useState } from "react";
import "./ChartsCommercialParticipation.scss";
import { useBiCommercial } from "../../../../../../providers/BI/Dashboard/BiCommercialProvider";
import { Card, Col, Row } from "react-bootstrap";
import PieChart from "../../../PieChart/PieChart";
import { nameAbbreviation } from "../../../../../../utils/NameAbbreviation";
import { useBiDashboard } from "../../../../../../providers/BI/Dashboard/BiDashboardProvider";
import { BI_VIEWS_ENUM } from "../../../../../../models/BI/Dashboard/BiProductionParamsModel";
import SkeletonCircle from "../../../../../SkeletonCircle/SkeletonCircle";

interface ChartsCommercialParticipationProps {}

const ChartsCommercialParticipation: FC<
  ChartsCommercialParticipationProps
> = () => {
  const {
    monthlyProduction,
    setMonthlyProduction,
    annualProduction,
    setAnnualProduction,
  } = useBiCommercial();

  const {
    handleListMonthlyProduction,
    handleListAnnualProduction,
    isLoading,
    params,
  } = useBiDashboard();

  const [monthData, setMonthData] = useState<any[]>([]);
  const [annualData, setAnnualData] = useState<any[]>([]);

  useEffect(() => {
    handleListMonthlyProduction({
      ...params,
      view: BI_VIEWS_ENUM.COMMERCIAL,
      group: {},
    }).then((x: any) => {
      if (x) {
        setMonthlyProduction(x);
      }
    });

    handleListAnnualProduction({
      ...params,
      view: BI_VIEWS_ENUM.COMMERCIAL,
      group: {},
    }).then((x: any) => {
      if (x) {
        setAnnualProduction(x);
      }
    });
  }, [params]);

  useEffect(() => {
    if (
      monthlyProduction?.items &&
      monthlyProduction?.items?.length > 0 &&
      monthlyProduction?.items[0]?.periods &&
      monthlyProduction?.items[0]?.periods?.length > 0
    ) {
      let _data =
        monthlyProduction?.items?.map((item) =>
          item?.periods
            ? {
                value: Number(
                  item?.periods[item?.periods?.length - 1]
                    .totalProductionPercentage
                ),
                label: nameAbbreviation(item?.name ?? "", 13),
              }
            : {}
        ) ?? [];
      _data = _data
        ?.sort((a: any, b: any) => (a?.value > b?.value ? -1 : 1))
        ?.filter((x: any, index: number) => index <= 9);
      setMonthData(_data);
    }
  }, [monthlyProduction]);

  useEffect(() => {
    if (
      annualProduction?.items &&
      annualProduction?.items?.length > 0 &&
      annualProduction?.items[0]?.periods &&
      annualProduction?.items[0]?.periods?.length > 0
    ) {
      let _data =
        annualProduction?.items?.map((item) =>
          item?.periods
            ? {
                value: Number(
                  item?.periods[item?.periods?.length - 1]
                    .totalProductionPercentage
                ),
                label: nameAbbreviation(item?.name ?? "", 13),
              }
            : {}
        ) ?? [];
      _data = _data
        ?.sort((a: any, b: any) => (a?.value > b?.value ? -1 : 1))
        ?.filter((x: any, index: number) => index <= 9);
      setAnnualData(_data);
    }
  }, [annualProduction]);

  return (
    <div
      className="ChartsCommercialParticipation"
      data-testid="ChartsCommercialParticipation"
    >
      <Row>
        <Col xl="6" className="mb-4">
          <Card className="h-100 shandow-xs-none">
            <Card.Header className="pb-0">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h6 className="surtitle text-gray m-0 text-start">
                    PARTICIPAÇÃO MENSAL
                  </h6>
                </div>
                <div></div>
              </div>
            </Card.Header>
            <Card.Body>
              {!isLoading ? (
                <PieChart
                  labels={monthData?.map((item) => item?.label)}
                  data={monthData?.map((item) => item?.value?.toFixed(2))}
                  height={450}
                />
              ) : (
                <SkeletonCircle width={300} />
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col xl="6" className="mb-4">
          <Card className="h-100 shandow-xs-none">
            <Card.Header className="pb-0">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h6 className="surtitle text-gray m-0 text-start">
                    PARTICIPAÇÃO ANUAL
                  </h6>
                </div>
                <div></div>
              </div>
            </Card.Header>
            <Card.Body>
              {!isLoading ? (
                <PieChart
                  labels={annualData?.map((item) => item?.label)}
                  data={annualData?.map((item) => item?.value?.toFixed(2))}
                  height={450}
                />
              ) : (
                <SkeletonCircle width={300} />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ChartsCommercialParticipation;
