import { FC, useEffect } from 'react';
import './MultiStepsForm.scss';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMultiSteps } from '../../providers/MultiStepsProvider';

interface MultiStepsFormProps {
  steps?: any[];
  navClick?: boolean;
  handleExtra?: () => void,
  buttonTextExtra?: string,
}

const MultiStepsForm: FC<MultiStepsFormProps> = ({ steps = [], navClick = true }) => {
  const {
    currentStep,
    setCurrentStep,
    setStepsLength
  } = useMultiSteps();

  useEffect(() => {
    setCurrentStep(0);
    setStepsLength(steps.length);
  }, []);

  const renderMenu = () => {
    return steps?.map((step: any, key: number) => (
      <div key={step?.order}>
        <button
          className={`multisteps-form__progress-btn ${!navClick ? 'not-click' : ''} ${key <= currentStep ? 'js-active' : ''}`}
          type="button" title={step.title} onClick={() => {
            navClick && setCurrentStep(key)
          }}>
          <span>{step.title}</span>
        </button>
      </div>
    ));
  }

  const renderList = () => {
    return steps?.map((step: any, key: number) => (
      <div key={step?.order}>
        {key === currentStep && (
          <div
            className={`card multisteps-form__panel border-radius-xl ${step?.class} ${key === currentStep ? 'js-active' : ''}`}
            data-animation="FadeIn" style={{ backgroundColor: step.bgCard }}>
            {step.descricao && (
              <div className="row m-0 text-start">
                <div className="col">
                  <h5 className="text-start">
                    <div className="wrapper">
                      <div className="wrapper-icon me-1"><FontAwesomeIcon icon={step.icon} /></div>
                      <span>{step.descricao}</span>
                    </div>
                  </h5>
                </div>
              </div>
            )}

            <div className="multisteps-form__content">
              <>{step.children}</>
            </div>
          </div>
        )}
      </div>
    ));
  }

  return (
    <div className="MultiStepsForm multisteps-form mb-0" data-testid="MultiStepsForm">
      <Row>
        <Col lg={12} className="mx-auto mb-3">
          <div className="multisteps-form__progress">
            {renderMenu()}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} className="m-auto">
          <form className="multisteps-form__form">
            {renderList()}
          </form>
        </Col>
      </Row>
    </div >
  );
}

export default MultiStepsForm;
