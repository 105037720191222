import React, { FC, useState } from 'react';
import './ProductionByPartner.scss';
import { Col, Row } from 'react-bootstrap';
import NavPills from '../../../../NavPills/NavPills';
import PageBase from '../../../../PageBase/PageBase';
import TableMonthlyProduction from './TableMonthlyProduction/TableMonthlyProduction';
import TableAnnualProduction from './TableAnnualProduction/TableAnnualProduction';
import ChartPartnerParticipation from './ChartPartnerParticipation/ChartPartnerParticipation';

interface ProductionByPartnerProps { }

export enum TabEnumProductionByPartner {
  MONTHLY_PRODUCTION = 'monthly_production',
  ANNUAL_PRODUCTION = 'annual_production',
  CHART_PARTICIPATION = 'chart_participation'
}

const ProductionByPartner: FC<ProductionByPartnerProps> = () => {

  const [currentActiveTab, setCurrentActiveTab] = useState<TabEnumProductionByPartner | string>(TabEnumProductionByPartner.MONTHLY_PRODUCTION);

  const renderContent = (): JSX.Element => {
    return (
      <>
        <Row>
          <Col md={12}>
            <NavPills mdMenu={12}
              options={[
                {
                  acronym: TabEnumProductionByPartner.MONTHLY_PRODUCTION,
                  name: 'Produção Mensal',
                  icon: <i className="fa-solid fa-table"></i>,
                  show: true,
                },
                {
                  acronym: TabEnumProductionByPartner.ANNUAL_PRODUCTION,
                  name: 'Produção Anual',
                  icon: <i className="fa-solid fa-table"></i>,
                  show: true,
                },
                {
                  acronym: TabEnumProductionByPartner.CHART_PARTICIPATION,
                  name: 'Gráfico de Participação',
                  icon: <i className="fa-solid fa-chart-pie"></i>,
                  show: true,
                }
              ]}
              current={currentActiveTab}
              setCurrent={setCurrentActiveTab}
            />
          </Col>
        </Row>

        {currentActiveTab === TabEnumProductionByPartner.MONTHLY_PRODUCTION &&
          <TableMonthlyProduction />
        }
        {currentActiveTab === TabEnumProductionByPartner.ANNUAL_PRODUCTION &&
          <TableAnnualProduction />
        }
        {currentActiveTab === TabEnumProductionByPartner.CHART_PARTICIPATION &&
          <ChartPartnerParticipation />
        }

      </>
    );
  }



  return (
    <div className="ProductionByPartner" data-testid="ProductionByPartner">
      <PageBase
        content={renderContent()}
        showCardHeader={false}
      />
    </div>
  );
};

export default ProductionByPartner;
