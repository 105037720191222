import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { MonthlyGoalProvider } from '../../providers/PanelConnects/MonthlyGoal/MonthlyGoalProvider';
import { MonthlyGoalConditionProvider } from '../../providers/PanelConnects/MonthlyGoal/MonthlyGoalConditionProvider';
import { MonitoringCommercialPointsProvider } from '../../providers/PanelConnects/MonitoringCommercialPoints/MonitoringCommercialPointsProvider';
import { CommercialProvider } from '../../providers/Registrations/User/UserCommercialProvider';
import { MetricManagementProvider } from '../../providers/pgm/MetricManagement/MetricManagementProvider';
import { FinancialProvider } from '../../providers/Registrations/Financial/FinancialProvider';
import { CovenantGroupProvider } from '../../providers/Registrations/Covenant/CovenantGroupProvider';
import { MonthlyGoalPeriodConfigurationProvider } from '../../providers/PanelConnects/MonthlyGoal/MonthlyGoalPeriodConfigurationProvider';
import { MasterCovenantGroupProvider } from '../../providers/Registrations/Covenant/MasterCovenantGroupProvider';
import { CovenantProvider } from '../../providers/Registrations/Covenant/CovenantProvider';
import { ContractFormProvider } from '../../providers/Proposals/ContractForm/ContractFormProvider';

interface Props {
    element?: any;
    path?: string;
}

export const PanelConnectsRoute: FC<Props> = () => {
    return (

        <MonthlyGoalProvider>
            <MonthlyGoalConditionProvider>
                <MonthlyGoalPeriodConfigurationProvider>
                    <MonitoringCommercialPointsProvider>
                        <CommercialProvider>
                            <MetricManagementProvider>
                                <FinancialProvider>
                                    <CovenantGroupProvider>
                                        <MasterCovenantGroupProvider>
                                            <CovenantProvider>
                                                <ContractFormProvider>
                                                    <Outlet />
                                                </ContractFormProvider>
                                            </CovenantProvider>
                                        </MasterCovenantGroupProvider>
                                    </CovenantGroupProvider>
                                </FinancialProvider>
                            </MetricManagementProvider>
                        </CommercialProvider>
                    </MonitoringCommercialPointsProvider>
                </MonthlyGoalPeriodConfigurationProvider>
            </MonthlyGoalConditionProvider>
        </MonthlyGoalProvider>
        // </DndProvider>
    );
}
