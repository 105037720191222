import { ProposalConfigFetchParams } from "../../../models/Proposals/Config/ProposalConfigFetchParams";
import { ProposalConfigFieldsListParams } from "../../../models/Proposals/Config/ProposalConfigFieldsListParams";
import { ProposalConfigHeadersDataGetParams } from "../../../models/Proposals/Config/ProposalConfigHeadersDataGetParams";
import { ProposalConfigListParams } from "../../../models/Proposals/Config/ProposalConfigListParams";
import { ProposalExportHeaderFieldsConfigurationModel } from "../../../models/Proposals/Config/ProposalExportHeaderConfigurationModel";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/services/proposals/config/headers';

export class ProposalConfigService extends BaseService {
    async list(params: ProposalConfigListParams): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listTablesColumnsNames(): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/get/tables/columns/list`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listTablesColumnsNamesNew(financialId: number, headerTypeId: number): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/getHeaderFinancial`, { financialId, headerTypeId }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listFields(params: ProposalConfigFieldsListParams): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/fields/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async fetch(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async delete(id: number): Promise<any> {
        try {
            return this.handleResponse(await api.delete(`${URL}`, { data: { id } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async fetchConfig(params: ProposalConfigFetchParams): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/updateTablesHeadersLinks`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listImportData(params: ProposalConfigHeadersDataGetParams | null): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/data/get`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listExportHeaderConfiguration(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`api/services/proposals/export/config/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async saveExportHeaderConfiguration(id: string, config: ProposalExportHeaderFieldsConfigurationModel): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`api/services/proposals/export/config/save`, { id, config }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}