import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import './ModalEndpointUsers.scss';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import opportunities from "../../../../pages/Marketplace/Opportunities/Opportunities";
import {useEndpoint} from "../../../../providers/Marketplace/Endpoint/EndpointProvider";
import {toast} from "react-toastify";
import {CampaignModel} from "../../../../models/Marketplace/Campaign/CampaignModel";
import AppPagination from "../../../AppPagination/AppPagination";
import SkeletonTable from "../../../SkeletonTable/SkeletonTable";
import {CampaignStatusEnum} from "../../../../enums/Marktplace/Campaigns/CampaignStatusEnum";
import {dateFormat} from "../../../../utils/FormatDate";
import {Button, Card, Col, Dropdown, FormGroup, Row} from "react-bootstrap";
import { EndpointModel } from '../../../../models/Marketplace/Endpoint/EndpointModel';
import { EndpointUsersModel } from '../../../../models/Marketplace/Endpoint/EndpointUsersModel';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import DropdownMenuTable from '../../../DropdownMenuTable/DropdownMenuTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPencil, faXmark } from '@fortawesome/free-solid-svg-icons';

interface ModalEndpointUsersProps {
    show: boolean,
    onClose: any,
    endpoint?: EndpointModel | null,
    setEndpoint:  Dispatch<SetStateAction<EndpointModel | null>>
}

const ModalEndpointUsers: FC<ModalEndpointUsersProps> = ({ endpoint, setEndpoint, onClose, show }) => {
    const {
        handleGetEndpointsUsers,
        error,
        setError,
        isLoading,
        pages,
        params,
        endpointUsers,
        updatedEndpointUsers,
        setEndpointUsers,
        setParams,
        onClickChangeEndpointUserStatus
    } = useEndpoint();

    useEffect(() => {
        if (!!error) {
            toast.error(error);
            setError('');
        }
    }, [error]);

    const renderList = (): JSX.Element => {
        return (
          <>
            {endpointUsers?.length > 0 ? (
              <>
                {endpointUsers.map((x: EndpointUsersModel) => (
                  <tr key={x.sanitize_user_id} className="text-sm">
                    <td># {x.sanitize_user_id}</td>
                    <td>{x?.sanitize_user_username}</td>
                    <td>{x?.sanitize_user_password}</td>
                    <td>
                      <div className={`badge badge-sm ${x?.has_limit ? 'badge-success' : 'badge-danger'}`}>
                          {x?.has_limit ? 'Disponivel'+` ( ${x?.uses} consultas )` : 'Atingiu o limite de consultas'}
                      </div>
                    </td>
                    <td>
                      <div className={`badge badge-sm ${x?.sanitize_user_active ? 'badge-success' : 'badge-danger'}`}>
                          {x?.sanitize_user_active ? 'Ativo' : 'Inativo'}
                      </div>
                    </td>
                    <td className="text-center">
                        <Button className="btn btn-primary m-2 p-2 fadeIn2" ><FontAwesomeIcon icon={faPencil} className="me-1" />Editar</Button>
                        <Button className="btn btn-primary m-2 p-2 fadeIn2"  onClick={() => onClickChangeEndpointUserStatus(x?.sanitize_user_id || 0)}><FontAwesomeIcon icon={x?.sanitize_user_active ? faXmark : faCheckCircle} className="me-1" />{x?.sanitize_user_active ? 'Desativar' : 'Ativar'}</Button>
                    </td>

                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={5} className="text-center text-sm">Não há registros</td>
              </tr>
            )}
          </>
        );
      }

    const renderContent = (): JSX.Element => {
        return (
            <>
                {!isLoading ? (
                    <div className="table-responsive">
                    <table className="table table-flush m-0 w-100">
                      <thead className="thead-light">
                        <tr>
                          <th className="text-uppercase opacity-7" scope="col">#</th>
                          <th className="text-uppercase opacity-7" scope="col">Usuário</th>
                          <th className="text-uppercase opacity-7" scope="col">Senha</th>
                          <th className="text-uppercase opacity-7" scope="col">Limite</th>
                          <th className="text-uppercase opacity-7" scope="col">Atividade</th>
                          <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                        </tr>
                      </thead>
                      <tbody>
                        {renderList()}
                      </tbody>
                    </table>
                  </div>
                ) : (
                    <SkeletonTable />
                )}
            </>
        );
    }

    useEffect(() => {
        handleGetEndpointsUsers(endpoint?.sanitize_type_id || 0);
    }, [show]);

    useEffect(() => {
        if (updatedEndpointUsers) {
            handleGetEndpointsUsers(endpoint?.sanitize_type_id || 0);
        }
      }, [updatedEndpointUsers]);
  

    return (
        <ModalDefault
            title={`Usuario do endpoint: ${endpoint?.sanitize_type_name}`}
            sizeModal = 'xl'
            show={show}
            onClose={onClose}
        >
            <div className="ModalEndpointUsers" data-testid="ModalEndpointUsers">
                {renderContent()}
            </div>
        </ModalDefault>
    )
};

export default ModalEndpointUsers;
