import { AuthenticatorValidationFetchParams } from "../../models/Authenticator/AuthenticatorValidationFetchParams";
import api from "../Api";
import { BaseService } from "../BaseService";

const URL = 'api/token';

class AuthenticatorValidationService extends BaseService {
    async get(): Promise<any> {
        try {
            return this.handleResponse(await api.get(`${URL}/get`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async fetch(params: AuthenticatorValidationFetchParams): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/send`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}

export default AuthenticatorValidationService;