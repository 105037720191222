import Pagination from 'react-bootstrap/Pagination';
import './AppPagination.scss';
import React, { Dispatch, SetStateAction } from "react";

interface Props extends React.HTMLAttributes<HTMLInputElement> {
    pages: any;
    params?: any,
    setParams?: Dispatch<SetStateAction<any>>,
    handlePaginate: (page: number, params?: any, setParams?: Dispatch<SetStateAction<any>>) => void;
    exportDiv?: React.JSX.Element;
    position?: 'top' | 'bottom';
    colorTotal?: string;
}

const AppPagination = ({
    pages,
    handlePaginate,
    exportDiv,
    params,
    setParams,
    position = 'bottom',
    colorTotal = 'text-primary',
    ...rest
}: Props) => {
    return (
        <div data-testid="AppPagination">
            {pages && (
                <div
                    className={`d-flex justify-content-between align-items-center mb-0 AppPagination pt-0 pb-0 ${position}`}
                    {...rest}
                >
                    <div>
                        {pages?.total && (
                            <p className='text-sm text-span mb-0'>
                                Exibindo {pages?.position?.first || 0} à {pages?.position?.last || 0} de um total de <span className={colorTotal}><b>{pages?.total || 0}</b></span> registros
                            </p>
                        )}
                    </div>

                    <div className="d-flex align-items-center">
                        {exportDiv && (
                            <div className="me-2">
                                {exportDiv}
                            </div>
                        )}
                        <Pagination className="pagination my-2">
                            {(pages?.current !== pages?.first) && (
                                <Pagination.First onClick={() => handlePaginate(pages?.first, params, setParams)} />
                            )}

                            {(pages?.previous) > 0 && (
                                <Pagination.Item onClick={() => handlePaginate(pages?.previous, params, setParams)}>
                                    {pages?.previous}
                                </Pagination.Item>
                            )}

                            <Pagination.Item className="active" onClick={() => handlePaginate(pages?.current, params, setParams)}>
                                {pages?.current}
                            </Pagination.Item>

                            {(pages?.next !== pages?.current) && (
                                <Pagination.Item onClick={() => handlePaginate(pages?.next, params, setParams)}>
                                    {pages?.next}
                                </Pagination.Item>
                            )}

                            {(pages?.current !== pages?.last) && (
                                <Pagination.Last onClick={() => handlePaginate(pages?.last, params, setParams)} />
                            )}
                        </Pagination>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AppPagination;
