import React, { FC, useEffect, useState } from 'react';
import './ModalModulesToolsInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useModuleTools } from '../../../../providers/Registrations/ModuleTools/ModuleToolsProvider';
import { useForm } from 'react-hook-form';
import { Card, Col, FormGroup, Row } from 'react-bootstrap';
import { ModuleModel } from '../../../../models/Registrations/ModulesTool/ModuleModel';
import { ToolModel } from '../../../../models/Registrations/ModulesTool/ToolModel';
import { useAuth } from '../../../../providers/AuthProvider';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaModule, schemaTool } from './Validate';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import { toast } from 'react-toastify';

interface ModalModulesToolsInsertProps {
  show: boolean,
  onClose: any
}

const defaultModuleValues: ModuleModel = {
  id: undefined,
  name: '',
  description: '',
}

const defaultToolValues: ToolModel = {
  id: undefined,
  moduleId: undefined,
  name: '',
  description: '',
  alias: ''
}

const ModalModulesToolsInsert: FC<ModalModulesToolsInsertProps> = ({ onClose, show }) => {
  const [toolEdit, setToolEdit] = useState<ToolModel>();

  const { handleSaveModule, handleSaveTool, handleDeleteTool, handleGetModule, handleList, moduleEdit, setModuleEdit, isLoading } = useModuleTools();
  const { onInvalid } = useAuth();
  const {
    register: registerModule,
    handleSubmit: handleSubmitModule,
    reset: resetModule,
    formState: { errors: errorsModule }
  } = useForm<ModuleModel | any>({
    mode: 'onChange',
    defaultValues: defaultModuleValues,
    resolver: yupResolver(schemaModule)
  });

  const {
    register: registerTool,
    handleSubmit: handleSubmitTool,
    reset: resetTool,
    formState: { errors: errorsTool }
  } = useForm<ToolModel | any>({
    mode: 'onChange',
    defaultValues: defaultToolValues,
    resolver: yupResolver(schemaTool)
  });

  useEffect(() => {
    if (show && !!moduleEdit) {
      resetModule(moduleEdit);
    }
  }, [show, moduleEdit]);

  useEffect(() => {
    resetTool(toolEdit);
  }, [toolEdit]);

  const canAddTool = () => !!moduleEdit?.id;

  const handleOnClose = () => {
    setModuleEdit(null);
    resetModule(defaultModuleValues);
    resetTool(defaultToolValues);
    onClose();
  }

  const handleDelete = (tool: ToolModel) => {
    Swal.fire({
      title: 'Atenção',
      html: `Deseja realmente excluir a Ferramenta ${tool.name}?`,
      icon: 'question',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCancelButton: true,
      showConfirmButton: true
    }).then(async (value) => {
      if (value.isConfirmed) {
        const result = await handleDeleteTool(tool.id || 0);
        if (result) {
          handleGetModule(moduleEdit?.id || 0);
          setToolEdit(defaultToolValues);
          toast.success('Ferramenta deletada com sucesso!');
          handleList().then();
        }
      }
    })
  }

  const onSubmitModule = async (data: ModuleModel) => {
    data.name = data.name?.trim();
    data.description = data.description?.trim();
    const result = await handleSaveModule(data);

    if (!!result) {
      handleGetModule(result?.id || 0);
      handleList().then();
      toast.success('Módulo salvo com sucesso!');
    }
  }

  const onSubmitTool = async (data: ToolModel) => {
    data.name = data.name?.trim();
    data.description = data.description?.trim();
    data.alias = data.alias?.trim();
    data.moduleId = moduleEdit?.id;
    const result = await handleSaveTool(data);

    if (!!result) {
      handleGetModule(moduleEdit?.id || 0);
      handleList().then();
      toast.success('Ferramenta salva com sucesso!');
      resetTool(defaultToolValues);
    }
  }

  return (
    <ModalDefault
      title={'Manutenção de Módulo'}
      show={show}
      onClose={handleOnClose}
      sizeModal="xl"
      showFooter={true}
    >
      <div className="ModalModulesToolsInsert" data-testid="ModalModulesToolsInsert">
        <Card>
          <Card.Header className='p-3 pb-1'>
            <Card.Title>Módulo</Card.Title>
          </Card.Header>
          <Card.Body className='p-3 py-0'>
            <Row>
              <Col md={4} sm={12}>
                <FormGroup>
                  <label>Nome *</label>
                  <input
                    className={`form-control ${onInvalid(errorsModule?.name)}`}
                    type='text'
                    {...registerModule('name')}
                  />
                  <ErrorMessage name="Nome" min={3} max={100} type={errorsModule?.name?.type?.toString()} />
                </FormGroup>
              </Col>
              <Col md={6} sm={12}>
                <FormGroup>
                  <label>Descrição *</label>
                  <input
                    className={`form-control ${onInvalid(errorsModule?.description)}`}
                    type='text'
                    {...registerModule('description')}
                  />
                  <ErrorMessage name="Descrição" min={3} max={150} type={errorsModule?.description?.type?.toString()} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label>&nbsp;</label>
                  <button
                    className='btn btn-primary w-100 d-block'
                    onClick={handleSubmitModule(onSubmitModule)}
                  >
                    Salvar
                  </button>
                </FormGroup>
              </Col>
            </Row>

            <Card className='mt-3 mb-3'>
              <Card.Header className='p-3 pb-1'>
                <Card.Title>Ferramenta</Card.Title>
              </Card.Header>
              <Card.Body className='p-3 py-0'>
                <Row>
                  <Col md={3} sm={12}>
                    <FormGroup>
                      <label>Nome *</label>
                      <input
                        className={`form-control ${onInvalid(errorsTool?.name)}`}
                        type='text'
                        {...registerTool('name')}
                        disabled={!canAddTool()}
                      />
                      <ErrorMessage name="Nome" min={3} max={100} type={errorsTool?.name?.type?.toString()} />
                    </FormGroup>
                  </Col>
                  <Col md={4} sm={12}>
                    <FormGroup>
                      <label>Descrição *</label>
                      <input
                        className={`form-control ${onInvalid(errorsTool?.description)}`}
                        type='text'
                        {...registerTool('description')}
                        disabled={!canAddTool()}
                      />
                      <ErrorMessage name="Descrição" min={3} max={150} type={errorsTool?.description?.type?.toString()} />
                    </FormGroup>
                  </Col>
                  <Col md={3} sm={12}>
                    <FormGroup>
                      <label>Alias *</label>
                      <input
                        className={`form-control ${onInvalid(errorsTool?.alias)}`}
                        type='text'
                        {...registerTool('alias')}
                        disabled={!canAddTool()}
                      />
                      <ErrorMessage name="Alias" min={3} max={50} type={errorsTool?.alias?.type?.toString()} />
                    </FormGroup>
                  </Col>
                  <Col>
                    <label>&nbsp;</label>
                    <button
                      className='btn btn-primary w-100 d-block'
                      onClick={handleSubmitTool(onSubmitTool)}
                      disabled={!canAddTool()}
                    >
                      {!!toolEdit?.id ? 'Salvar' : 'Adicionar'}
                    </button>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {!isLoading ?
                      <div className='table-responsive'>
                        <table className={`table ${(moduleEdit?.tools && moduleEdit?.tools?.length > 0) && 'table-striped'}`}>
                          <thead>
                            <tr>
                              <th className='p-2'>Nome</th>
                              <th className='p-2'>Descrição</th>
                              <th className='p-2'>Alias</th>
                              <th className='p-2'>Data</th>
                              <th className='text-center'>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {moduleEdit?.tools && moduleEdit?.tools?.length > 0
                              ?
                              moduleEdit?.tools?.map((x: ToolModel, key: number) => (
                                <tr className='text-sm'>
                                  <td className='p-2'>{x.name}</td>
                                  <td className='p-2'>{x.description}</td>
                                  <td className='p-2'>{x.alias}</td>
                                  <td className='p-2'>{!!x.updatedAt ? moment(x.updatedAt).format('DD/MM/YYYY') : moment(x.createdAt).format('DD/MM/YYYY')}</td>
                                  <td className="text-center">
                                    <div className='d-flex justify-content-center gap-2'>
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setToolEdit(x)}
                                      />
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleDelete(x)}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))
                              :
                              <tr>
                                <td
                                  colSpan={5}
                                  className='text-center text-sm'
                                >
                                  Não existem Ferramentas para listar.
                                  {!moduleEdit?.id && <><br /><b>Salve o Módulo para adicionar Ferramentas.</b></>}
                                </td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                      :
                      <SkeletonTable />
                    }
                  </Col>
                </Row>
              </Card.Body>
            </Card>

          </Card.Body>
        </Card>
      </div>
    </ModalDefault>
  )
};

export default ModalModulesToolsInsert;
