import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TypeService } from "../../../services/Registrations/Partner/Type/TypeService";
import { TypeModel } from "../../../models/Partner/Type/TypeModel";

interface ContextProps {
    types: TypeModel[],
    typesOptions: any | null,
    handleList: () => void,
}

export const TypeContext = createContext<ContextProps>({} as ContextProps);

interface TypeProviderProps {
    children: ReactNode;
}

export const TypeProvider = ({ children }: TypeProviderProps) => {
    const [types, setTypes] = useState<TypeModel[]>([]);
    const [error, setError] = useState<string>('');
    const [typesOptions, setTypesOptions] = useState<any | null>(null);

    const service = new TypeService();

    const handleList = useCallback(async () => {
        try {
            const [_Response, _Error] = await service.list({ withPaginate: false });

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setTypes(_Response.data);
            return setError('');
        } catch (e) {
            return setError('Não foi possível carregar os tipos');
        }
    }, []);

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        setTypesOptions(
            types
                ?.map((item: TypeModel) => {
                    return { label: item?.name, value: item?.id, externalId: item?.externalId };
                })
        )
    }, [types]);

    return (
        <TypeContext.Provider value={{
            types,
            typesOptions,
            handleList,
        }}>
            {children}
        </TypeContext.Provider>
    );
}

export const useType = () => useContext(TypeContext);