import * as yup from "yup";
import { MonthlyGoalConditionsEnum } from "../../../../enums/PanelConnects/MonthlyGoalConditionsEnum";

export const schema = yup.object().shape({
    logicCondition: yup
        .object()
        .required(),
    hasOperation: yup
        .boolean()
        .required(),
    proportional: yup
        .boolean()
        .required(),
    tag: yup
        .object()
        .when('hasOperation', {
            is: (item: any) => !item,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired()
        }),
    condition: yup
        .object()
        .required(),
    valueType: yup
        .string()
        .required(),
    value1: yup
        .mixed()
        .when('valueType', {
            is: (item: any) => item === 'value',
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired()
        }),
    value2: yup
        .mixed()
        .when('condition', {
            is: (item: any) => item?.value === MonthlyGoalConditionsEnum.ENTRE,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired()
        }),
    tagValue: yup
        .object()
        .when('valueType', {
            is: (item: any) => item === 'tag',
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired()
        }),
});