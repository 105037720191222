import { CovenantGroupModel } from "../../../models/Registrations/Covenant/CovenantGroupModel";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/services/proposals/proposal/group';

export class CovenantGroupService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async save(payload: CovenantGroupModel): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/save`, payload));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}