import { FC, useEffect, useState } from 'react';
import './DashboardAdmin.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../providers/PaginateProvider';
import { useExample } from '../../../providers/ExampleProvider';
import { toast } from 'react-toastify';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { useDashboard, fetchGuestTokenFromBackend} from '../../../providers/Marketplace/Dashboard/DashboardProvider';
import { DashboardEnum } from '../../../enums/Superset/DashboardEnum';

interface DashboardAdminProps { }

interface EmbedDashboardParams {
  fetchGuestToken: () => Promise<string>;
}

const DashboardAdmin: FC<DashboardAdminProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
  
  const { setShowSidebarFilter } = useSidebarFilter();
  const {  isLoading, error, hasElem, setHasElem} = useDashboard();
  const { handlePaginate } = usePaginate(); 

  useEffect(() => {
    const container = document.getElementById("superset-container") as HTMLElement;
    const supersetDomain: string = process.env.REACT_APP_SUPERSET || '';

    if (container) {
      embedDashboard({
          id: DashboardEnum.MARKETPLACE, 
          supersetDomain: supersetDomain,
          mountPoint: container, 
          fetchGuestToken: fetchGuestTokenFromBackend,
          dashboardUiConfig: { hideTitle: true, hideChartControls: true }
      });
    }
  }, [hasElem]);

  const renderContent = (): JSX.Element => {
    return (
      <>
      <div className="table-responsive">
        <div className="w-100 h-100" id="superset-container" > 
        </div>
      </div>
      </>
    );
  }

  return (
    <MasterPage
      title="DashboardAdmin"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="DashboardAdmin" data-testid="DashboardAdmin">
        <PageBase
          title="Dashboard"
          subtitle="Dashboard administrativo do Marketplace"
          error={error}
          content={renderContent()}
        />

      </div>

    </MasterPage>
  );
}

export default DashboardAdmin;

