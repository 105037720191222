import { createContext, useContext, useMemo, useState } from "react";
import { AccessActionModel } from "../../../models/Registrations/Access/AccessActionModel";
import { AccessActionParams } from "../../../models/Registrations/Access/AccessActionParams";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { AccessActionService } from "../../../services/Registrations/Access/AccessActionService";
import _ from "underscore";

export const AccessActionContext = createContext<AccessActionModel[] | any>({});

export const AccessActionProvider = (props: any) => {
    const [parameters, setParameters] = useState<AccessActionParams>({ pageNumber: 1 });
    const [actions, setActions] = useState<AccessActionModel[]>([]);
    const [action, setAction] = useState<AccessActionModel>();
    const [error, setError] = useState<string>('');
    const [pagesPaginate, setPagesPaginate] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const service = useMemo(() => new AccessActionService(), []);

    const handleDetails = async (actionId: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.details(actionId);
            setIsLoading(false);
            if (!!_Error) {
                return setError(_Response || _Error);
            }

            setError('');
            setAction(_Response.data);

            return true;

        } catch (err) {
        }
    };

    const handleDelete = async (actionId: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(actionId);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }
            setError('');
            setActions(
                _.reject(actions ?? [], d => d.id === actionId),
            );
            return [true, null];

        } catch (err) {
            return [false, null];
        }
    };

    return (
        <AccessActionContext.Provider value={{
            actions,
            setActions,
            action,
            setAction,
            error,
            setError,
            pagesPaginate,
            setPagesPaginate,
            parameters,
            setParameters,
            handleDetails,
            handleDelete,
            isLoading
        }}>
            {props.children}
        </AccessActionContext.Provider>
    )
}

export const useAccessAction = () => useContext(AccessActionContext);