import { CovenantModel } from "../../../models/Registrations/Covenant/CovenantModel";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/services/proposals/proposal/contractType';

export class CovenantService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async save(payload: CovenantModel): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/save`, payload));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}