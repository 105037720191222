import { FC, useEffect, useState } from "react";
import MasterPage from "../../components/MasterPage/MasterPage";
import PageBase from "../../components/PageBase/PageBase";
import SidebarTermsDocumentsFilter from "../../components/Terms/SideBarTermsDocumentsFilter/SideBarTermsDocumentsFilter";
import SidebarFilter from "../../components/SidebarFilter/SidebarFilter";
import { useSidebarFilter } from "../../providers/SidebarFilterProvider";
import { useTermsDocuments } from "../../providers/Terms/TermsDocumentsProvider";
import { usePaginate } from "../../providers/PaginateProvider";
import SkeletonTable from "../../components/SkeletonTable/SkeletonTable";
import AppPagination from "../../components/AppPagination/AppPagination";
import { TermsDocumentsModel } from "../../models/Terms/TermsDocumentsModel";
import DropdownMenuTable from "../../components/DropdownMenuTable/DropdownMenuTable";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faFilePen,
  faGear,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";
import ModalTermsInsert from "../../components/Terms/ModalTermsInsert/ModalTermsInsert";
import moment from "moment";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

interface TermsDocumentsProps {}

const TermsDocuments: FC<TermsDocumentsProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();

  const {
    terms,
    handleList,
    params,
    setParams,
    isLoading,
    pages,
    error,
    handleDelete,
    setTerm,
  } = useTermsDocuments();
  const { handlePaginate } = usePaginate();

  useEffect(() => {
    handleList(params);
  }, [params]);

  const handleEdit = (item: TermsDocumentsModel) => {
    setTerm(item);
    setShowModalInsert(true);
  };

  const handleDeleteTerm = (item: TermsDocumentsModel) => {
    Swal.fire({
      title: "<strong>Atenção?</strong>",
      html: `<span>Deseja inativar o termo?</span>`,
      icon: "question",
      confirmButtonText: "Sim, deletar!",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      confirmButtonColor: "var(--primary)",
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleDelete(item.id || 0).then();
        if (ret) {
          toast.success("Termo inativado com sucesso!");
          handleList(params);
        } else {
          await Swal.fire(
            "Ops!",
            `Não foi possível inativar o termo.<br>${error}`,
            "error"
          );
        }
      }
    });
  };

  const renderList = () => {
    return (
      <>
        {terms?.length > 0 ? (
          <>
            {terms.map((x: TermsDocumentsModel, key) => (
              <tr key={key} className="text-sm">
                <td>
                  <div className={`badge badge-sm badge-primary`}>
                    {x?.category?.name}
                  </div>
                  <br />
                  <b className="text-center">#{x?.id}</b>
                  <br />
                  <b>Ult. Atualização</b>
                  <br />
                  {moment(x?.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                </td>
                <td className="text-wrap">
                  <b>TÍTULO:</b> {x?.name}
                  <br />
                  <b>TIPO PESSOA:</b>{" "}
                  {x?.kindOfPerson === "J"
                    ? "PESSOA JURÍDICA"
                    : x?.kindOfPerson === "F"
                    ? "PESSOA FÍSICA"
                    : "TODOS"}
                </td>
                <td>
                  <b>ALTERAÇÕES DOC:</b>{" "}
                  <span style={{ color: "#5e72e4" }}>{}</span>
                  <br />
                  <b>CONFIGURAÇÕES:</b>{" "}
                  <span style={{ color: "#f5365c" }}>{}</span>
                </td>
                <td>
                  <div
                    className={`badge badge-sm ${
                      x?.deletedAt ? "badge-danger" : "badge-success"
                    }`}
                  >
                    {x?.deletedAt ? "Inativo" : "Ativo"}
                  </div>
                </td>
                <td>
                  <DropdownMenuTable key={key}>
                    <Dropdown.Item
                      className="border-radius-md py-2"
                      onClick={() => handleEdit(x)}
                    >
                      <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="border-radius-md py-2"
                      onClick={() => handleDeleteTerm(x)}
                    >
                      <FontAwesomeIcon icon={faRotate} className="me-2" />{" "}
                      Inativar
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="border-radius-md py-2"
                      onClick={() => {}}
                    >
                      <FontAwesomeIcon icon={faGear} className="me-2" />{" "}
                      Gerenciar
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="border-radius-md py-2"
                      onClick={() => {}}
                    >
                      <FontAwesomeIcon icon={faFilePen} className="me-2" />{" "}
                      Incluir Promotora
                    </Dropdown.Item>
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={8} className="text-center text-sm">
              Não há registros
            </td>
          </tr>
        )}
      </>
    );
  };

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase text-xs font-weight-bolder opacity-7">
                    INFORMAÇÕES
                  </th>
                  <th
                    className="text-uppercase text-xs font-weight-bolder opacity-7"
                    scope="col"
                  >
                    DETALHES DO DOCUMENTO
                  </th>
                  <th
                    className="text-uppercase text-xs font-weight-bolder opacity-7"
                    scope="col"
                  >
                    CONSISTÊNCIA
                  </th>
                  <th
                    className="text-uppercase text-xs font-weight-bolder opacity-7"
                    scope="col"
                  >
                    STATUS
                  </th>
                  <th
                    className="text-uppercase text-xs font-weight-bolder opacity-7"
                    scope="col"
                  >
                    AÇÕES
                  </th>
                </tr>
              </thead>
              <tbody>{renderList()}</tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}

        {terms?.length > 0 && (
          <div>
            <nav aria-label="...">
              <AppPagination
                pages={pages}
                handlePaginate={handlePaginate}
                params={params}
                setParams={setParams}
              />
            </nav>
          </div>
        )}
      </>
    );
  };

  return (
    <MasterPage title="Termo" icon="fas fa-industry" footerFixed footerInverse>
      <div className="TermsDocuments" data-testid="TermsDocuments">
        <PageBase
          title="Termo"
          subtitle="Gerenciamento de Documentos."
          handleFilter={() =>
            showSidebarFilter
              ? setShowSidebarFilter(false)
              : setShowSidebarFilter(true)
          }
          content={renderContent()}
          hasFilter
          handleInsert={() => setShowModalInsert(true)}
        />
      </div>

      <SidebarFilter>
        <SidebarTermsDocumentsFilter />
      </SidebarFilter>

      <ModalTermsInsert
        show={showModalInsert}
        onClose={() => setShowModalInsert(false)}
      />
    </MasterPage>
  );
};

export default TermsDocuments;
