import { FC } from 'react';
import { useStyle } from '../../providers/Style/StyleProvider';
import './Footer.scss';
import { Col, Container, Row } from 'react-bootstrap';

interface FooterProps {
  fixed?: boolean;
}

const Footer: FC<FooterProps> = ({ fixed = false }) => {
  const { style } = useStyle();

  return (
    <footer className={`footer Footer pt-1 ${fixed ? 'fixed' : ''}`} data-testid="Footer">
      <Container fluid>
        <Row className="align-items-center justify-content-lg-between footer-center">
          <Col md={6} className="mb-lg-0 mb-4 text-start">
            <div className="copyright text-white text-sm text-lg-start">
              © {style?.name} - Todos os direitos reservados.
            </div>
          </Col>
          <Col md={6} className="mb-lg-0 mb-4 text-end">
            <a href="https://www.bevioficial.com.br/politicaprivacidade" target='_blank' className="text-white text-sm text-lg-end">
              Termos de Uso e Política de Privacidade
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
