import api from "./Api";

class AuthService {

    async login(data: { username: string, password: string }) {
        try {
            const response = await api.post(`api/auth/login`, data);
            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, 'Os dados informados não conferem, por favor verique os dados e tente novamente.'];
        }
    }

    async me(token: string) {
        try {
            const response = await api.get(`api/auth/me`, { headers: { Authorization: `Bearer ${token}` } });
            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, 'Os dados informados não conferem, por favor verique os dados e tente novamente.'];
        }
    }

    async logout() {
        try {
            const response = await api.post(`api/auth/logout`, {});
            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, e?.error || 'Os dados informados não conferem, por favor verique os dados e tente novamente.'];
        }
    }
}

export default AuthService;