import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import './ModalOpportunityView.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { CampaignModel } from '../../../../models/Marketplace/Campaign/CampaignModel';
import { useOpportunity } from '../../../../providers/Marketplace/Opportunities/OpportunityProvider';
import { toast } from 'react-toastify';
import AppPagination from '../../../AppPagination/AppPagination';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import {OpportunityModel} from "../../../../models/Marketplace/Opportunity/OpportunityModel";
import {dateFormat} from "../../../../utils/FormatDate";
import {CampaignStatusEnum} from "../../../../enums/Marktplace/Campaigns/CampaignStatusEnum";
import DropdownMenuTable from "../../../DropdownMenuTable/DropdownMenuTable";
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faEye} from "@fortawesome/free-solid-svg-icons";

interface ModalOpportunityViewProps {
  show: boolean,
  onClose: any,
  campaign?: CampaignModel | null,
  setCampaign: Dispatch<SetStateAction<CampaignModel | null>>
}

const ModalOpportunityView: FC<ModalOpportunityViewProps> = ({ campaign, setCampaign, onClose, show }) => {
  const {
    handleGetOpportunitiesByCampaign,
    opportunities,
    error,
    setError,
    isLoading,
    pages,
    params,
    setParams
  } = useOpportunity();

  useEffect(() => {
    if (show && !!campaign) {
      handleGetOpportunitiesByCampaign(1, campaign?.campaign_id || 0).then();
    } else {
      setCampaign(null);
    }
  }, [show, campaign]);

  useEffect(() => {
    if (!!error) {
      toast.error(error);
      setError('');
    }
  }, [error]);

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7" scope="col">ID da Oportunidade</th>
                  <th className="text-uppercase opacity-7" scope="col">Nome do Cliente</th>
                  <th className="text-uppercase opacity-7" scope="col">CPF do Cliente</th>
                  <th className="text-uppercase opacity-7" scope="col">Cidade do Cliente</th>
                  <th className="text-uppercase opacity-7" scope="col">Estado</th>
                  <th className="text-uppercase opacity-7" scope="col">Agente</th>
                  <th className="text-uppercase opacity-7" scope="col">Financeira</th>
                  <th className="text-uppercase opacity-7" scope="col">Produto</th>
                  <th className="text-uppercase opacity-7" scope="col">Forma</th>
                  <th className="text-uppercase opacity-7" scope="col">Convênio</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>

            {opportunities?.length > 0 && (
              <div>
                <nav aria-label="...">
                  <AppPagination
                    pages={pages}
                    handlePaginate={handleGetOpportunitiesByCampaign}
                    params={campaign?.campaign_id || 0}
                    setParams={setParams}
                    style={{ backgroundColor: '#fff' }}
                  />
                </nav>
              </div>
            )}
          </div>
        ) : (
          <SkeletonTable />
        )}
      </>
    );
  }

  const renderList = (): JSX.Element => {
    return (
        <>
          {opportunities?.length > 0 ? (
              <>
                {opportunities.map((line: OpportunityModel) => (
                    <tr key={line.opportunity_id} className="text-sm">
                      <td># {line.opportunity_id}</td>
                      <td>{line.client_name}</td>
                      <td>{line.client_cpf}</td>
                      <td>{line.client_city}</td>
                      <td>{line.client_state}</td>
                      <td>{line.agent_partner_code}</td>
                      <td>{line.financial_name}</td>
                      <td>{line.productgroup_name}</td>
                      <td>{line.formcontract_name}</td>
                      <td>{line.agreement_name}</td>
                    </tr>
                ))}
              </>
          ) : (
              <tr>
                <td colSpan={6} className="text-center text-sm">Não há registros</td>
              </tr>
          )}
        </>
    );
  }

  return (
    <ModalDefault
        sizeModal = 'xxl'
      title={`Oportunidades da campanha ${campaign?.campaign_title}`}
      show={show}
      onClose={onClose}
    >
      <div className="ModalOpportunityView" data-testid="ModalOpportunityView">
        {renderContent()}
      </div>
    </ModalDefault>
  )
};

export default ModalOpportunityView;
