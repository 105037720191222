import { FC, useState, useEffect } from 'react';
import { usePaginate } from '../../../../providers/PaginateProvider';
import { useIndicatorManagementPreVisitVisits } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementPreVisitProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { PreVisitModel } from '../../../../models/Visits/IndicatorManagement/PreVisitModel';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { TabEnumIndicatorManagement } from '../../../../pages/Visits/IndicatorManagement/IndicatorManagement';
import { useIndicatorManagementWalletVisits } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementWalletProvider'
import './FormListPreVisits.scss';
import PageBase from '../../../PageBase/PageBase';
import AppPagination from '../../../AppPagination/AppPagination';
import moment from 'moment';
import ModalPreVisits from '../ModalPreVisits/ModalPreVisits';
import DropdownMenuTable from '../../../DropdownMenuTable/DropdownMenuTable';
import Swal from 'sweetalert2';
import ModalExportCalendarVisits from '../ModalExportCalendarVisits/ModalExportCalendarVisits';
import AppQuantityPerPage from '../../../AppQuantityPerPage/AppQuantityPerPage';
import { isNull } from 'underscore';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';

interface FormListPreVisitsProps {
  currentActiveTab?: TabEnumIndicatorManagement;
}

const FormListPreVisits: FC<FormListPreVisitsProps> = ({ currentActiveTab }) => {

  const { handlePaginate } = usePaginate();
  const { preVisits, handleList, error, isLoading, pages, setParams: setParamsPreVisit, params: paramsPreVisit, handleDelete, handleGetPreVisits, handleExcel } = useIndicatorManagementPreVisitVisits();
  const { params } = useIndicatorManagementWalletVisits();
  const [action, setAction] = useState<'add' | 'edit' | 'view'>('view');

  const [preVisitEdit, setPreVisitEdit] = useState<PreVisitModel>();
  const [showFormPreVisit, setShowFormPreVisit] = useState(false);
  const [showFormExportCalendar, setShowFormExportCalendar] = useState(false);
  const [partner, setPartner] = useState<any>();

  useEffect(() => {
    if (params?.commercialId) {
      handleList({ ...params, page: paramsPreVisit?.page, perPage: paramsPreVisit?.perPage }).then();
    } else {
      toast.warning('Faça um filtro para listar a Pré visita!');
    }
  }, [currentActiveTab, params, paramsPreVisit]);

  const handleShowModal = async (action: 'add' | 'edit' | 'view', item: PreVisitModel | undefined = undefined) => {
    if (action === 'edit' || action === 'view') {
      const dataVisit = await handleGetPreVisits(Number(item?.id)).then();
      setPreVisitEdit(dataVisit);
    }
    setPartner({ id: item?.userVisit?.id, name: item?.userVisit?.name });
    setAction(action);
    setShowFormPreVisit(true);
  }

  const handleDeleteVisit = (item: PreVisitModel) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja excluir a pré visita?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleDelete(item.id ?? 0).then();
        if (ret) {
          toast.success('Pré visita inativada com sucesso!');
          handleList(params).then();
        } else {
          await Swal.fire('Ops!', `Não foi possível inativar a pré visita.<br>${error}`, 'error');
        }
      }
    });
  }

  const getStatus = (item: PreVisitModel): 'approved' | 'notApproved' | 'notAnalysis' => {
    if (item.wasApproved === 1) {
      return 'approved'
    } else if ((item.wasApproved === 0 || isNull(item.wasApproved)) && isNull(item.userAnalysisId)) {
      return 'notAnalysis'
    } else {
      return 'notApproved'
    }
  }

  const renderList = (): JSX.Element => {
    return (
      <>
        {preVisits?.length > 0 ? (
          <>
            {preVisits.map((x: PreVisitModel) => (
              <tr key={x.id} className="text-sm">
                <td>{moment(x.scheduledDate).format('DD/MM/YYYY')}</td>
                <td className='text-wrap' >{x.userVisit?.name}</td>
                <td className='text-wrap' >
                  <ul className="m-0">
                    {x.visitsPreActions?.map((y: any) => (
                      <li key={y.id}>{y.name}</li>
                    ))}
                  </ul>
                </td>
                <td className='text-wrap' >
                  <ul className="m-0">
                    {x.visitsPreActivities?.map((y: any) => (
                      <li key={y.id}>{y.name}</li>
                    ))}
                  </ul>
                </td>
                <td className='text-wrap' >{x.observation}</td>
                <td className='text-wrap' >{x.userAnalysis?.name}</td>
                <td className='text-wrap' >{x.observationAnalysis}</td>
                <td><span className={`badge ${getStatus(x) === 'approved' ? 'badge-success' : getStatus(x) === 'notApproved' ? 'badge-danger' : 'badge  badge-dark'}`}>{getStatus(x) === 'approved' ? 'APROVADO' : getStatus(x) === 'notApproved' ? 'REPROVADO' : 'NÃO ANALISADO'}</span></td>
                <td>
                  <DropdownMenuTable key={1}>
                    {x && x?.wasApproved === null &&
                      <Dropdown.Item className="border-radius-md py-2" onClick={() => handleShowModal('edit', x)}>
                        <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
                      </Dropdown.Item>
                    }

                    {x && (!x?.wasApproved || x?.wasApproved === null) &&
                      <Dropdown.Item className="border-radius-md py-2" onClick={() => handleDeleteVisit(x)}>
                        <FontAwesomeIcon icon={faTrash} className="me-2" /> Remover
                      </Dropdown.Item>
                    }

                    {x && (x?.wasApproved || !x?.wasApproved) &&
                      <Dropdown.Item className="border-radius-md py-2" onClick={() => handleShowModal('view', x)}>
                        <FontAwesomeIcon icon={faEye} className="me-2" /> Visualizar
                      </Dropdown.Item>
                    }

                  </DropdownMenuTable>
                </td>
              </tr>

            ))}
          </>
        ) : (
          <tr>
            <td colSpan={8} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>

    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        <h4>PRÉ VISITA</h4>
        {preVisits?.length > 0 && !isLoading &&
          <div className="d-flex bd-highlight">

            <div className="me-auto p-2 ">
              <strong>Pré-visitas agendadas</strong>
            </div>

            <div className="p-2 bd-highlight">
              <button type="button" className="btn bg-dark text-white mb-0 d-flex align-items-center justify-content-end gap-2"
                onClick={() => handleList(params)}
              >
                <i className="fa-solid fa-rotate me-2"></i>
                Atualizar aba
              </button>
            </div>

            <div className="p-2 bd-highlight">
              <button type="button" className="btn bg-info text-white mb-0 d-flex align-items-center justify-content-end gap-2"
                onClick={() => setShowFormExportCalendar(true)}
              >
                <i className="fa-solid fa-file-export me-2"></i>
                Exportar Agenda
              </button>
            </div>

            <div className="p-2 bd-highlight">
              <button type="button" className="btn bg-success text-white mb-0 d-flex align-items-center justify-content-end gap-2"
                onClick={() => handleExcel(params)}
              >
                <i className="fa-regular fa-file-excel me-2"></i>
                Excel
              </button>
            </div>

            <div className='p-2'>
              <AppQuantityPerPage
                params={paramsPreVisit}
                setParams={setParamsPreVisit}
                pages={pages}
              />
            </div>
          </div>
        }

        {!isLoading ? (
          <>
            <div className='row'>
              <div className="table-responsive">
                <table className={`table ${preVisits?.length > 0 && 'table-striped'}`}>
                  <thead className="thead-light">
                    <tr role="row">
                      <th className="text-center bg-info text-table" colSpan={5} rowSpan={1} scope="col">AGENDAMENTO</th>
                      <th className="text-center bg-success text-table" colSpan={3} rowSpan={1} scope="col">VALIDADOR</th>
                      <th className="text-center bg-info text-table" colSpan={1} rowSpan={2} scope="col" style={{ width: '80.4px' }} >AÇÕES</th>
                    </tr>
                    <tr role="row">
                      <th className="text-center bg-info text-table" colSpan={1} rowSpan={1} scope="col" style={{ width: '80.4px' }} >DATA AGENDA</th>
                      <th className="text-center bg-info text-table text-wrap" colSpan={1} rowSpan={1} scope="col" style={{ width: '348.4px', maxWidth: '250px' }} >AGENTE</th>
                      <th className="text-center bg-info text-table" colSpan={1} rowSpan={1} scope="col" style={{ width: '169.4px' }}>AÇÃO</th>
                      <th className="text-center bg-info text-table" colSpan={1} rowSpan={1} scope="col" style={{ width: '169.4px' }}>FOCO</th>
                      <th className="text-center bg-info text-table text-wrap" colSpan={1} rowSpan={1} scope="col" style={{ width: '258.4px', maxWidth: '250px' }}>OBSERVAÇÃO</th>

                      <th className="text-center bg-success text-table" colSpan={1} rowSpan={1} scope="col" style={{ width: '169.4px' }}>NOME</th>
                      <th className="text-center bg-success text-table" colSpan={1} rowSpan={1} scope="col" style={{ width: '258.4px' }}>OBSERVAÇÃO</th>
                      <th className="text-center bg-success text-table" colSpan={1} rowSpan={1} scope="col" style={{ width: '82.4px' }}>APROVADO</th>
                    </tr>

                  </thead>
                  <tbody>
                    {preVisits?.length > 0 ?
                      renderList()
                      :
                      <tr style={{ backgroundColor: 'white' }}>
                        <th colSpan={9} className='text-center text-sm border-0'>Não há registros</th>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
              {preVisits?.length > 0 && (
                <div>
                  <nav aria-label="...">
                    <AppPagination
                      pages={pages}
                      handlePaginate={handlePaginate}
                      params={paramsPreVisit}
                      setParams={setParamsPreVisit}
                      style={{ backgroundColor: '#fff' }}
                    />
                  </nav>
                </div>
              )}
            </div >
          </ >
        ) : (
          <SkeletonTable />
        )}

      </>
    );
  }

  return (
    <div className="FormListPreVisits" data-testid="FormListPreVisits">
      <PageBase
        content={renderContent()}
        showCardHeader={false}
      />

      <ModalPreVisits
        show={showFormPreVisit}
        onClose={() => setShowFormPreVisit(false)}
        action={action}
        preVisitEdit={preVisitEdit}
        hasAnalysis
        partner={partner}
        setPreVisitEdit={setPreVisitEdit}
        disabledAnalysis
      />

      <ModalExportCalendarVisits
        show={showFormExportCalendar}
        onClose={() => setShowFormExportCalendar(false)}
      />
    </div>
  );
}

export default FormListPreVisits;
