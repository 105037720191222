import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import AuthenticatorValidationService from "../../services/Authenticator/AuthenticatorValidationService";
import { ToastSettings } from "../../utils/ToastSettings";

interface ContextProps {
    tokenResponse: string,
    setTokenResponse: Dispatch<SetStateAction<string>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleGet: () => Promise<any>,
    handleFetch: () => Promise<boolean>,
}

export const AuthenticatorValidationContext = createContext<ContextProps>({} as ContextProps);

interface AuthenticatorValidationProviderProps {
    children: ReactNode;
}

export const AuthenticatorValidationProvider = ({ children }: AuthenticatorValidationProviderProps) => {
    const [tokenResponse, setTokenResponse] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new AuthenticatorValidationService();

    const handleGet = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.get();
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setTokenResponse(_Response.data?.responseToken);
            setError('');
            return true;

        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível localizar o token.');
            return false;
        }
    }

    const handleFetch = async () => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.fetch({
                'title': 'Tentando fazer login?',
                'message': 'Você está tentando fazer login no domus?',
                'urlImage': '',
                'data': {
                    'sim': 1,
                    'não': 0
                }
            });
            if (!!_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            toast.success('Notificação reenviada com sucesso!');
            return true;
        } catch (e) {
            setError('Houve um erro ao reenviar a notificação');
            return false;
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    return (
        <AuthenticatorValidationContext.Provider value={{
            tokenResponse,
            setTokenResponse,
            isLoading,
            error,
            setError,
            handleGet,
            handleFetch
        }}>
            {children}
        </AuthenticatorValidationContext.Provider>
    );
}

export const useAuthenticatorValidation = () => useContext(AuthenticatorValidationContext);