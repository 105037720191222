import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { PartnerModel } from "../../../models/Registrations/Partner/PartnerModel";
import { PartnerParams } from "../../../models/Registrations/Partner/PartnerParams";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { useLocation } from "react-router-dom";
import { PartnerService } from "../../../services/Registrations/Partner/PartnerService";
import { toast } from "react-toastify";
import { ToastSettings } from "../../../utils/ToastSettings";
import { ProspectingModel } from "../../../models/Registrations/Partner/ProspectingModel";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";
import { useReportRequest } from "../../Reports/ReportRequest/ReportRequestProvider";

interface ContextProps {
    partner: PartnerModel,
    setPartner: Dispatch<SetStateAction<PartnerModel>>,
    partners: PartnerModel[],
    prospecting: ProspectingModel[],
    prospectingOptions: ReactSelectOptions[],
    params: PartnerParams,
    setParams: Dispatch<SetStateAction<PartnerParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleFetch: (data: PartnerModel) => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>,
    handleSearch: (value: string) => Promise<boolean>,
    handleListProspecting: (commercialManagerId: number) => Promise<any>,
    handleAgentExcel: (data: any) => Promise<any>,
    handleAgentReactivatedExcel: (data: any) => Promise<any>
}

export const PartnerContext = createContext<ContextProps>({} as ContextProps);

interface PartnerProviderProps {
    children: ReactNode;
}

export const PartnerProvider = ({ children }: PartnerProviderProps) => {
    const [partner, setPartner] = useState<PartnerModel>({});
    const [partners, setPartners] = useState<PartnerModel[]>([]);
    const [prospecting, setProspecting] = useState<ProspectingModel[]>([]);
    const [prospectingOptions, setProspectingOptions] = useState<ReactSelectOptions[]>([]);
    const [params, setParams] = useState<PartnerParams>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const { setIsLoadingFile } = useReportRequest();

    const service = new PartnerService();
    const location = useLocation();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/registrations/partner/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setPartners(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar os agentes');
        }
    }, [params, location.pathname]);

    const handleFetch = async (data: PartnerModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error, _Code, _Errors] = await service.fetch(data);
            setIsLoading(false);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setIsLoading(false);
            setError('Houve um erro ao salvar o agente');
            return false;
        }
    }

    const handleSearch = async (value: string) => {
        try {
            if (!value) {
                return false;
            }

            const [_Response, _Error, _Code, _Errors] = await service.list({ partner: value, withPaginate: false });

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setError('Houve um erro ao buscar o agente');
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar o agente');
            return false;
        }
    }

    const handleListProspecting = async (commercialManagerId: number) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.listProspecting(commercialManagerId);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                setProspecting([]);
                setProspectingOptions([]);
                return false;
            }

            setError('');
            setProspecting(_Response?.data);
            setProspectingOptions(_Response?.data?.map((x: ProspectingModel) => {
                return { value: x.id, label: x.name }
            }));
            return true;
        } catch (e) {
            setError('Houve um erro ao buscar prospecção');
            setProspecting([]);
            setProspectingOptions([]);
            return false;
        }
    }

    const handleAgentExcel = async (data: any) => {
        try {

            setIsLoadingFile(true);
            const [_Response, _Error, _Code, _Errors] = await service.export(data);
            setIsLoadingFile(false);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setIsLoadingFile(false);
            setError('');
            return true;
        } catch (e) {
            setError('Houve um erro ao buscar prospecção');
            setIsLoadingFile(false);
            return false;
        }
    };

    const handleAgentReactivatedExcel = async (data: any) => {
        try {

            setIsLoadingFile(true);
            const [_Response, _Error, _Code, _Errors] = await service.exportReactivatedAgent(data);
            setIsLoadingFile(false);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setIsLoadingFile(false);
            setError('');
            return true;
        } catch (e) {
            setError('Houve um erro ao buscar prospecção');
            setIsLoadingFile(false);
            return false;
        }
    };

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <PartnerContext.Provider value={useMemo(() => ({
            partner,
            setPartner,
            partners,
            prospecting,
            prospectingOptions,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleFetch,
            handleDelete,
            handleSearch,
            handleListProspecting,
            handleAgentExcel,
            handleAgentReactivatedExcel
        }), [
            partner,
            setPartner,
            partners,
            prospecting,
            prospectingOptions,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleFetch,
            handleDelete,
            handleSearch,
            handleListProspecting,
            handleAgentExcel,
            handleAgentReactivatedExcel
        ])}>
            {children}
        </PartnerContext.Provider>
    );
}

export const usePartner = () => useContext(PartnerContext);