import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { AccessPageModel } from '../../../../models/Registrations/Access/AccessPageModel';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useForm } from 'react-hook-form';
import { Col, FormCheck, FormGroup, Row } from 'react-bootstrap';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { toast } from "react-toastify";
import { useAuth } from '../../../../providers/AuthProvider';
import icons from '../../../../data/fontAwesome5.json';
import iconsUnicode from '../../../../data/fontAwesome5Unicode.json';

interface ModalAccessPageCollapseInsertProps {
    show: boolean;
    onClose?: any;
    pageView: AccessPageModel | null;
    setPageView: Dispatch<SetStateAction<AccessPageModel | null>>;
    isCollapse?: boolean | null;
    withCollapse: boolean;
    isEdit?: boolean;
    withRadioShowOnMenu?: boolean;
}

interface InputsView {
    id?: number | null;
    name?: string;
    icon?: string;
    parent?: number;
    path?: string;
    layout?: string;
    collapse?: string | boolean;
    collapsePage?: string | boolean;
    state?: string;
    views?: any[];
    showOnMenu?: string | boolean;
}

const ModalAccessPageCollapseInsert: FC<ModalAccessPageCollapseInsertProps> = ({
    show,
    onClose,
    pageView,
    setPageView,
    isCollapse,
    withCollapse,
    isEdit = false,
    withRadioShowOnMenu = false
}) => {
    const [iconsFiltered, setIconsFiltered] = useState<any[]>([]);

    const { onInvalid } = useAuth();

    const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm<InputsView>();

    useEffect(() => {
        if (!!pageView && isEdit) {
            pageView.layout = (pageView?.collapse) ? pageView.path?.replace(/^\//, '') : pageView.layout?.replace(/^\//, '');
            reset(pageView as InputsView);
        } else {
            clearViewForm();
        }
    }, [pageView, isEdit]);

    useEffect(() => {
        if (!show) {
            clearViewForm();
            setPageView({});
        } else {
            setValue('collapsePage', 'N');

            if (pageView?.id) {
                setValue('showOnMenu', pageView?.showOnMenu ? 'S' : 'N')
            } 
        }
    }, [show]);

    const clearViewForm = () => {
        setValue('id', null);
        setValue('name', '');
        setValue('path', '');
        setValue('layout', '');
        setValue('icon', '');
        setValue('showOnMenu', true);
    }

    const submit = (data: InputsView) => {
        if (isCollapse && !!pageView) {
            const viewsAux = pageView?.views ?? [];
            data.path = data.path ? `/${data.path}` : '';
            data.layout = data.layout ? `/${data.layout}` : '';

            const _pathView = (pageView?.collapse) ? data.layout : '';
            const _layoutView = (!pageView?.collapse) ? data.layout : '';

            if (isEdit) {
                pageView.id = data.id;
                pageView.name = data.name;
                pageView.icon = data.icon;
                pageView.path = _pathView;
                pageView.layout = _layoutView;
                pageView.state = `${data.layout?.replace('/', '')}Collapse`;
                pageView.showOnMenu = withRadioShowOnMenu ? data?.showOnMenu === 'S' : true;
            } else {
                if (!withCollapse) {
                    data.collapse = false;
                } else {
                    data.collapse = data?.collapsePage === 'S';
                }

                const _path = (data?.collapse) ? data.layout : '';
                const _layout = (!data?.collapse) ? data.layout : '';

                if (pageView?.id) {
                    viewsAux.push({
                        id: data.id,
                        name: data.name,
                        icon: data.icon,
                        collapse: data?.collapse,
                        showOnMenu: withRadioShowOnMenu ? data?.showOnMenu === 'S' : true,
                        path: _path,
                        layout: _layout,
                        parent: pageView.id,
                        state: `${data.layout?.replace('/', '')}Collapse`,
                        views: data?.views ?? [],
                        actions: [],
                        actionsPage: []
                    });
                } else {
                    viewsAux.push({
                        id: data.id,
                        name: data.name,
                        icon: data.icon,
                        collapse: data?.collapse,
                        showOnMenu: withRadioShowOnMenu ? data?.showOnMenu === 'S' : true,
                        path: _path,
                        layout: _layout,
                        state: `${data.layout?.replace('/', '')}Collapse`,
                        views: data?.views ?? [],
                        actions: [],
                        actionsPage: []
                    });
                }
            }
            clearViewForm();

            const _pages = { ...pageView, views: viewsAux };
            setPageView(_pages);

            toast.success(isEdit ? 'Página atualizada com sucesso!' : 'Página inserida com sucesso!');
            onClose();
        }
    }

    useEffect(() => {
        const aux = iconsUnicode.filter(x => icons.find(y => ('fas fa-' + x.name) === y.name))
            .map(x => {
                const i = icons.find(y => ('fas fa-' + x.name) === y.name);
                return { ...x, title: i?.title }
            });
        setIconsFiltered(aux);
    }, []);
  
    return (
        <ModalDefault
            title={isEdit ? 'Editar página' : "Inserir uma nova página"}
            show={show}
            onClose={() => {
                onClose();
            }}
            sizeModal="lg"
            showFooter={true}
            handleSubmit={handleSubmit(submit)}
        >
            <div className="ModalAccessPageCollapseInsert" data-testid="ModalAccessPageCollapseInsert">
                <Row>
                    <Col md={2}>
                        <FormGroup className="form-group">
                            <label>ID</label>
                            <input
                                className="form-control"
                                type="text"
                                disabled
                                {...register('id')}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={10}>
                        <FormGroup className="form-group">
                            <label>NOME *</label>
                            <input
                                className={`form-control ${onInvalid(errors?.name)}`}
                                type="text"
                                {...register('name', { required: true })}
                                disabled={!isCollapse}
                            />
                            <ErrorMessage name={'Nome'} type={errors?.name?.type} />
                        </FormGroup>
                    </Col>
                    
                    <Col md={5}>
                        <FormGroup className="form-group">
                            <label>URL *</label>
                            <div className="input-group">
                                {isCollapse && (
                                    <span
                                        style={{ paddingRight: 7 }}
                                        className={`input-group-text ${onInvalid(errors?.layout)}`}
                                    >
                                        /
                                    </span>
                                )}
                                <input
                                    className={`form-control ${onInvalid(errors?.layout)}`}
                                    type="text"
                                    {...register('layout', { required: true })}
                                    disabled={!isCollapse}
                                />
                            </div>
                            <ErrorMessage name={'URL'} type={errors?.layout?.type} />
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup className="form-group">
                            <label>ÍCONE</label>
                            <select
                                className="form-select"
                                style={{ fontFamily: "fontAwesome" }}
                                {...register('icon')}
                            >
                                {iconsFiltered.map((i: any) => (
                                    <option key={i.name} value={'fas fa-' + i.name}
                                        dangerouslySetInnerHTML={{ __html: `&#x${i.unicode}; ${i.name}` }}
                                        style={{ fontFamily: "fontAwesome" }}></option>
                                ))}
                            </select>
                        </FormGroup>
                    </Col>

                    {withCollapse && (
                        <Col md={3}>
                            <FormGroup className="form-group">
                                <label>COLLAPSE *</label>
                                <br />
                                <FormCheck className="form-check" inline>
                                    <input
                                        className={`form-check-input ${onInvalid(errors?.collapsePage)}`}
                                        type='radio'
                                        id="cvS"
                                        value="S"
                                        {...register('collapsePage', { required: true })}
                                    />
                                    <label className="form-check-label" htmlFor="cvS">Sim</label>
                                </FormCheck>
                                <FormCheck className="form-check" inline>
                                    <input
                                        className={`form-check-input ${onInvalid(errors?.collapsePage)}`}
                                        type='radio'
                                        id="cvN"
                                        value="N"
                                        {...register('collapsePage', { required: true })}
                                    />
                                    <label className="form-check-label" htmlFor="cvN">Não</label>
                                </FormCheck>
                                <ErrorMessage name={'Collapse'} type={errors?.collapsePage?.type} />
                            </FormGroup>
                        </Col>
                    )}

                    {withRadioShowOnMenu && (
                        <Col md={3}>
                            <FormGroup className="form-group">
                                <label>MOSTRAR NO MENU *</label>
                                <br />
                                <FormCheck className="form-check" inline>
                                    <input
                                        className={`form-check-input ${onInvalid(errors?.showOnMenu)}`}
                                        type='radio'
                                        id="cvS"
                                        value="S"
                                        {...register('showOnMenu', { required: true })}
                                    />
                                    <label className="form-check-label" htmlFor="cvS">Sim</label>
                                </FormCheck>
                                <FormCheck className="form-check" inline>
                                    <input
                                        className={`form-check-input ${onInvalid(errors?.showOnMenu)}`}
                                        type='radio'
                                        id="cvN"
                                        value="N"
                                        {...register('showOnMenu', { required: true })}
                                    />
                                    <label className="form-check-label" htmlFor="cvN">Não</label>
                                </FormCheck>
                                <ErrorMessage name={'Mostrar no menu'} type={errors?.showOnMenu?.type} />
                            </FormGroup>
                        </Col>
                    )}
                </Row>
            </div>
        </ModalDefault>
    );
}

export default ModalAccessPageCollapseInsert;
