import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";
import { NotificationCenterModel } from "../../../models/Registrations/Notification/NotificationCenterModel";
import { NotificationCenterService } from "../../../services/Registrations/Notification/NotificationCenterService";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";

interface ContextProps {
    levelNotifications: NotificationCenterModel[],
    levelOptions: ReactSelectOptions[],
    userNotifications: NotificationCenterModel | null,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleListByLevel: () => Promise<any>,
    handleListByUser: (id: number) => Promise<any>,
    handleSaveLevel: (accessProfileId: number, toolId: number) => Promise<boolean>,
    handleDeleteLevel: (id: number) => Promise<boolean>,
    handleSaveUser: (userId: number, moduleToolId: number) => Promise<boolean>,
    handleDeleteUser: (id: number) => Promise<boolean>
}

export const NotificationCenterContext = createContext<ContextProps>({} as ContextProps);

interface NotificationCenterProviderProps {
    children: ReactNode;
}

export const NotificationCenterProvider = ({ children }: NotificationCenterProviderProps) => {
    const [levelNotifications, setLevelNotifications] = useState<NotificationCenterModel[]>([]);
    const [userNotifications, setUserNotifications] = useState<NotificationCenterModel | null>(null);
    const [levelOptions, setLevelOptions] = useState<ReactSelectOptions[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const service = new NotificationCenterService();

    const handleListByLevel = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.listByLevel();
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                setLevelNotifications([]);
                setLevelOptions([]);
                return;
            }

            setLevelNotifications(_Response?.data);
            setLevelOptions(_Response?.data?.map((x: NotificationCenterModel) => {
                return {
                    value: x.id, label: x.name
                }
            }));
            setError('');
            return;
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível listar as notificações por Nível.');
            setLevelNotifications([]);
            setLevelOptions([]);
            return;
        }
    }

    const handleListByUser = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.listByUser(id);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                setUserNotifications(null);
                return;
            }

            setUserNotifications(_Response?.data);
            setError('');
            return;
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível listar as notificações do usuário.');
            setUserNotifications(null);
            return;
        }
    }

    const handleSaveLevel = async (accessProfileId: number, toolId: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.saveLevel(accessProfileId, toolId);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível salvar.');
            return false;
        }
    }

    const handleDeleteLevel = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.deleteLevel(id);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível deletar.');
            return false;
        }
    }

    const handleSaveUser = async (userId: number, moduleToolId: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.saveUser(userId, moduleToolId);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível salvar.');
            return false;
        }
    }

    const handleDeleteUser = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.deleteUser(id);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível deletar.');
            return false;
        }
    }

    return (
        <NotificationCenterContext.Provider value={{
            levelNotifications,
            userNotifications,
            levelOptions,
            isLoading,
            error,
            setError,
            handleListByLevel,
            handleListByUser,
            handleSaveLevel,
            handleDeleteLevel,
            handleSaveUser,
            handleDeleteUser
        }}>
            {children}
        </NotificationCenterContext.Provider>
    );
}

export const useNotificationCenter = () => useContext(NotificationCenterContext);