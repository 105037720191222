import { FC, useEffect, useState } from 'react';
import './MonitoringCommercialPoints.scss';
import { toast } from 'react-toastify';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import SidebarMonitoringCommercialPointsFilter from '../../../components/PanelConnects/MonitoringCommercialPoints/SidebarMonitoringCommercialPointsFilter/SidebarMonitoringCommercialPointsFilter';
import { useMonitoringCommercialPoints } from '../../../providers/PanelConnects/MonitoringCommercialPoints/MonitoringCommercialPointsProvider';
import { useMonthlyGoal } from '../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalProvider';
import { useReportRequest } from '../../../providers/Reports/ReportRequest/ReportRequestProvider';
import Swal from 'sweetalert2';
import { Col, Row } from 'react-bootstrap';
import NavPills from '../../../components/NavPills/NavPills';
import CommercialPointsTab from '../../../components/PanelConnects/MonitoringCommercialPoints/CommercialPointsTab/CommercialPointsTab';
import RegionalPointsTab from '../../../components/PanelConnects/MonitoringCommercialPoints/RegionalPointsTab/RegionalPointsTab';
import ExecutivePointsTab from '../../../components/PanelConnects/MonitoringCommercialPoints/ExecutivePointsTab/ExecutivePointsTab';
import ModalManualGoalInsert from '../../../components/PanelConnects/MonitoringCommercialPoints/ModalManualGoalInsert/ModalManualGoalInsert';
import RankingPointsTab from '../../../components/PanelConnects/MonitoringCommercialPoints/RankingPointsTab/RankingPointsTab';
import moment from 'moment';
import { useAuth } from '../../../providers/AuthProvider';

interface MonitoringCommercialPointsProps { }

const TabEnum = {
  COMMERCIAL: 1,
  REGIONAL: 2,
  EXECUTIVE: 3,
  RANKING: 4,
}

const itemsPerPageOptions = [
  5,
  15,
  30,
  50,
  100,
  150,
  200,
  300,
  500
];

const MonitoringCommercialPoints: FC<MonitoringCommercialPointsProps> = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState<any>(TabEnum.COMMERCIAL);
  const [itemsPerPage, setItemsPerPage] = useState<number>(30);
  const [canImport, setCanImport] = useState<boolean>(false);

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const [showModalManualGoal, setShowModalManualGoal] = useState<boolean>(false);
  const { isLoadingExcel, error, setError, params, handleGetExcel, points } = useMonitoringCommercialPoints();
  const { handleDownloadPdf } = useMonthlyGoal();
  const { isLoadingFile } = useReportRequest();
  const { CanAccess } = useAuth();

  useEffect(() => {
    CanAccess('painel_conecta.importar_pontosregra').then((x: boolean) => setCanImport(x));
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError('');
    }
  }, [error]);

  useEffect(() => {
    if (isLoadingFile || isLoadingExcel) {
      Swal.fire({
        title: "<p class='title-swal m-0'>Solicitando Arquivo...</p>",
        allowOutsideClick: false
      });
      Swal.showLoading();
    } else {
      if (error) {
        Swal.fire(`<p class='title-swal m-0'>${error}</p>`);
        Swal.hideLoading();
      } else {
        Swal.close();
      }
    }
  }, [isLoadingFile, isLoadingExcel]);

  const onDownloadPdf = async () => {
    handleDownloadPdf(params?.closingDate, params?.accessProfileId);
  }

  const onDownloadExcel = () => {
    handleGetExcel(
      currentActiveTab === TabEnum.COMMERCIAL ? 'commercial' :
        currentActiveTab === TabEnum.REGIONAL ? 'regional' :
          currentActiveTab === TabEnum.EXECUTIVE ? 'executive' : 'ranking'
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        <Row>
          <Col md={12}>
            <NavPills mdMenu={12}
              options={[
                {
                  acronym: TabEnum.COMMERCIAL,
                  name: 'Pontuação Comercial',
                  icon: <i className="fa-solid fa-table"></i>,
                  show: true,
                },
                {
                  acronym: TabEnum.REGIONAL,
                  name: 'Pontuação Regional',
                  icon: <i className="fa-solid fa-table"></i>,
                  show: true,
                },
                {
                  acronym: TabEnum.EXECUTIVE,
                  name: 'Pontuação Executivo',
                  icon: <i className="fa-solid fa-table"></i>,
                  show: true,
                },
                {
                  acronym: TabEnum.RANKING,
                  name: 'Ranking',
                  icon: <i className="fa-solid fa-chart-line"></i>,
                  show: true,
                },
              ]}
              current={currentActiveTab}
              setCurrent={setCurrentActiveTab}
            />
          </Col>
        </Row>
        <Row className='mx-1 items-per-page'>
          <Col className='d-flex justify-content-between align-items-center gap-2'>
            <div className='text-sm font-italic'>
              Última atualização dos dados: {points?.lastUpdate ? moment(points?.lastUpdate).format('DD/MM/YYYY HH:mm:ss') : 'Não informado'}
            </div>
            <div className='d-flex align-items-center gap-2'>
              <span className='text-sm'>Exibir</span>
              <select
                className='form-select form-select-sm'
                style={{ width: '65px' }}
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(Number(e.target.value))}
              >
                {itemsPerPageOptions?.map((option: number) => (
                  <option value={option}>{option}</option>
                ))}
              </select>
              <span className='text-sm'>registros por página.</span>
            </div>
          </Col>
        </Row>
        {currentActiveTab === TabEnum.COMMERCIAL && <CommercialPointsTab itemsPerPage={itemsPerPage} />}
        {currentActiveTab === TabEnum.REGIONAL && <RegionalPointsTab itemsPerPage={itemsPerPage} />}
        {currentActiveTab === TabEnum.EXECUTIVE && <ExecutivePointsTab itemsPerPage={itemsPerPage} />}
        {currentActiveTab === TabEnum.RANKING && <RankingPointsTab itemsPerPage={itemsPerPage} />}
      </>
    );
  }

  return (
    <MasterPage
      title="Painel Conecta"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="MonitoringCommercialPoints" data-testid="MonitoringCommercialPoints">
        <PageBase
          title="Painel Conecta"
          subtitle="Tela destinada para realizar o acompanhamento dos pontos dos comerciais."
          handleAddtional={() => onDownloadPdf()}
          showAddtionalButton
          textButtonAddtional='Download PDF'
          showAddtionalButtonExtra2={canImport}
          handleAddtionalExtra2={() => setShowModalManualGoal(true)}
          textButtonAddtionalExtra2='Importar Metas Manuais'
          handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          content={renderContent()}
          hasFilter
          handleAddtionalExtra={onDownloadExcel}
          textButtonAddtionalExtra='Download Excel'
          showAddtionalButtonExtra
        />
      </div>

      <SidebarMonitoringCommercialPointsFilter />

      <ModalManualGoalInsert
        show={showModalManualGoal}
        onClose={() => setShowModalManualGoal(false)}
      />
    </MasterPage>
  );
}

export default MonitoringCommercialPoints;
