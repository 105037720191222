import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { VisitsRegisterInactiveModel } from "../../../models/Visits/VisitsRegister/VisitsRegisterInactiveModel";
import { VisitsRegisterNewActiveModel } from "../../../models/Visits/VisitsRegister/VisitsRegisterNewActiveModel";
import { VisitsRegisterReactivatedModel } from "../../../models/Visits/VisitsRegister/VisitsRegisterReactivatedModel";
import { VisitsRegisterUnsubscribeModel } from "../../../models/Visits/VisitsRegister/VisitsRegisterUnsubscribeModel";
import { VisitsRegisterService } from "../../../services/Visits/VisitsRegister/VisitsRegisterService";
import { useIndicatorManagementWalletVisits } from "./IndicatorManagementWalletProvider";

interface ContextProps {
    inactives: VisitsRegisterInactiveModel[];
    newActives: VisitsRegisterNewActiveModel[];
    reactivateds: VisitsRegisterReactivatedModel[];
    unsubscribes: VisitsRegisterUnsubscribeModel[];
    params: any;
    setParams: Dispatch<SetStateAction<any>>;
    isLoading: boolean;
    error: string;
    setError: Dispatch<SetStateAction<string>>;
    handleList: (params: any) => Promise<any>;
}

export const VisitsRegistrationContext = createContext<ContextProps>({} as ContextProps);

interface VisitsRegistrationProviderProps {
    children: ReactNode;
};

export const IndicatorManagementRegistrationProvider = ({ children }: VisitsRegistrationProviderProps) => {
    const [inactives, setInactives] = useState<VisitsRegisterInactiveModel[]>([]);
    const [newActives, setNewActives] = useState<VisitsRegisterNewActiveModel[]>([]);
    const [reactivateds, setReactivateds] = useState<VisitsRegisterReactivatedModel[]>([]);
    const [unsubscribes, setUnsubscribes] = useState<VisitsRegisterUnsubscribeModel[]>([]);
    const [params, setParams] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const { params: paramsWallet } = useIndicatorManagementWalletVisits();

    const service = new VisitsRegisterService();

    useEffect(() => {
        setParams(paramsWallet);
    }, [paramsWallet]);

    const handleList = async (params: any) => {
        try {
            setIsLoading(true);

            const [_response, _error] = await service.list(params);

            setIsLoading(false);

            if (_error) {
                setInactives([]);
                setNewActives([]);
                setReactivateds([]);
                setUnsubscribes([]);
                return setError(_error?.message || _error);
            }

            setInactives(getInactivesUnsubscribes(_response?.data, 'inactives') || []);
            setNewActives(getActivesReactivateds(_response?.data, 'actives') || []);
            setReactivateds(getActivesReactivateds(_response?.data, 'reactivateds') || []);
            setUnsubscribes(getInactivesUnsubscribes(_response?.data, 'unsubscribes') || []);

            return setError('');
        }
        catch (e: any) {
            setInactives([]);
            setNewActives([]);
            setReactivateds([]);
            setUnsubscribes([]);
            setError('Houve um erro ao listar o gráfico');
            return false;
        }
    }

    const getActivesReactivateds = (data: any, type: 'actives' | 'reactivateds'): any[] => {
        return Object.entries(data?.map((x: any) => type === 'actives' ? x.active : x.reactivated)
            ?.flat(1)
            ?.reduce((acc: any, cur: any) => {
                if (!acc[cur.period]) {
                    acc[cur.period] = {
                        period: cur.period,
                        amount: 0,
                        production: 0
                    }
                }
                acc[cur.period].amount += !isNaN(cur.amount) ? Number(cur.amount) : 0;
                acc[cur.period].production += !isNaN(cur.production) ? Number(cur.production) : 0;
                return acc;
            }, [])).map(([key, value]) => value)
            ?.sort((a: any, b: any) => a.period < b.period ? -1 : 1);
    }

    const getInactivesUnsubscribes = (data: any, type: 'inactives' | 'unsubscribes'): any[] => {
        return Object.entries(data?.map((x: any) => type === 'inactives' ? x.inactive : x.unsubscribed)
            ?.flat(1)
            ?.reduce((acc: any, cur: any) => {
                if (!acc[cur.data]) {
                    acc[cur.data] = {
                        data: cur.data,
                        amount: 0
                    }
                }
                acc[cur.data].amount += !isNaN(cur.amount) ? Number(cur.amount) : 0;
                return acc;
            }, [])).map(([key, value]: any) => {
                const _date = value?.data;
                delete value?.data;
                return { ...value, period: _date }
            });
    }

    return (
        <VisitsRegistrationContext.Provider value={useMemo(() => ({
            inactives,
            newActives,
            reactivateds,
            unsubscribes,
            params,
            setParams,
            isLoading,
            error,
            setError,
            handleList
        }), [
            inactives,
            newActives,
            reactivateds,
            unsubscribes,
            params,
            setParams,
            isLoading,
            error,
            setError,
            handleList
        ])}>
            {children}
        </VisitsRegistrationContext.Provider>
    );
}

export const useVisitsRegistration = () => useContext(VisitsRegistrationContext);