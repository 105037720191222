import { FC, useEffect, useState } from 'react';
import { Card, Col, FormGroup, Row } from 'react-bootstrap';
import { useAuth } from '../../../../../providers/AuthProvider';
import { Controller } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { CompanyModel } from '../../../../../models/Registrations/Company/CompanyModel';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import { ViaCepService } from '../../../../../services/ViaCepService';
import { ufs } from '../../../../../models/OptionsValues';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../../models/SelectCustomStyles';
import { toast } from 'react-toastify';

interface FormAddressInfoProps {
  control: any;
  errors: any;
  register: any;
  setValue: any;
  company: CompanyModel;
}

const FormAddressInfo: FC<FormAddressInfoProps> = ({
  errors,
  control,
  setValue,
  register,
  company,
}) => {
  const [selectedCep, setSelectedCep] = useState<string>('');
  const [selectedUf, setSelectedUf] = useState<any>('');

  const { onInvalid } = useAuth();

  const cepService = new ViaCepService();

  const handleCep = async (cep: string) => {
    if (cep == '') {
      return;
    }

    cep = cep?.replace(/\D+/g, '');

    setSelectedCep(cep);

    const [_Response, _Error] = await cepService.get(cep);

    if (_Response && (_Response.erro || _Response?.erro) || _Error) {
      toast.error(_Error || _Response?.message || 'Não foi possível localizar o CEP informado.');
      return;
    }

    if (_Response?.cep) {
      setSelectedCep(_Response.cep);
    }

    setValue('publicPlace', _Response.logradouro || '');
    setValue('neighborhood', _Response.bairro || '');
    setValue('city', _Response.localidade || '');

    if (_Response?.uf) {
      setSelectedUf(ufs.find(c => c.value === _Response.uf));
    }

    setValue('numbers', '');
  }

  useEffect(() => {
    setValue('state', selectedUf?.value || '');
  }, [selectedUf]);

  useEffect(() => {
    selectedCep && setValue('cep', selectedCep);
  }, [selectedCep]);

  useEffect(() => {
    setValue('cep', company?.address?.cep || '');
    setValue('publicPlace', company?.address?.publicPlace || '');
    setValue('numbers', company?.address?.numbers || '');
    setValue('neighborhood', company?.address?.neighborhood || '');
    setValue('complement', company?.address?.complement || '');
    setValue('city', company?.address?.city || '');

    setSelectedCep(company?.address?.cep);
    setSelectedUf(ufs.find(c => c.value === company?.address?.state));
  }, []);

  return (
    <Row className="FormAddressInfo mt-4" data-testid="FormAddressInfo">
      <Card>
        <Card.Header className="pb-1 px-2">
          <h5>Endereço</h5>
        </Card.Header>
        <Card.Body className="p-2">
          <Row>
            <Col md={3}>
              <FormGroup className="form-group">
                <label htmlFor="cep">CEP *</label>
                {control && (
                  <Controller
                    name="cep"
                    control={control}
                    render={({ field: { name, ref } }) => (
                      <ReactInputMask
                        ref={ref}
                        name={name}
                        className={`form-control ${onInvalid(errors?.cep)}`}
                        mask="99999-999"
                        defaultValue={company?.address?.cep}
                        onBlur={e => (handleCep(e.target.value))}
                      />
                    )}
                  />
                )}
                <ErrorMessage name="CEP" type={errors?.cep?.type} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="form-group">
                <label htmlFor="publicPlace">RUA *</label>
                <input
                  type="text"
                  className={`form-control ${onInvalid(errors?.publicPlace)}`}
                  defaultValue={company?.publicPlace}
                  {...register('publicPlace')}
                />
                <ErrorMessage name="Rua" type={errors?.publicPlace?.type} min={3} max={150} />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="form-group">
                <label htmlFor="numbers">NÚMERO *</label>
                <input
                  type="text"
                  className={`form-control ${onInvalid(errors?.numbers)}`}
                  defaultValue={company?.numbers}
                  {...register('numbers')}
                />
                <ErrorMessage name="Número" type={errors?.numbers?.type} min={1} max={50} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="form-group">
                <label htmlFor="neighborhood">BAIRRO *</label>
                <input
                  type="text"
                  className={`form-control ${onInvalid(errors?.neighborhood)}`}
                  defaultValue={company?.neighborhood}
                  {...register('neighborhood')}
                />
                <ErrorMessage name="Bairro" type={errors?.neighborhood?.type} min={2} max={150} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="form-group">
                <label htmlFor="complement">COMPLEMENTO</label>
                <input
                  type="text"
                  className={`form-control ${onInvalid(errors?.complement)}`}
                  {...register('complement')}
                />
                <ErrorMessage name="Complemento" type={errors?.complement?.type} max={100} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="form-group">
                <label htmlFor="state">ESTADO *</label>
                {control && (
                  <Controller
                    name="state"
                    control={control}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <ReactSelect
                        ref={ref}
                        name={name}
                        isClearable
                        isSearchable
                        options={ufs}
                        placeholder="Selecione..."
                        className={`form-control p-0 ${onInvalid(errors?.state)}`}
                        value={selectedUf || ''}
                        defaultValue={selectedUf}
                        onChange={val => {
                          onChange(val?.value || '');
                          setSelectedUf(val);
                        }}
                        noOptionsMessage={() => 'Não há registros'}
                        styles={customStyles}
                      />
                    )}
                  />
                )}
                <ErrorMessage name="Estado" type={errors?.state?.type} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="form-group">
                <label htmlFor="city">CIDADE *</label>
                <input
                  type="text"
                  className={`form-control ${onInvalid(errors?.city)}`}
                  {...register('city')}
                />
                <ErrorMessage name="Cidade" type={errors?.city?.type} min={3} max={150} />
              </FormGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
}

export default FormAddressInfo;
