import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PagesPaginateModel } from "../../models/Paginate/PagesPaginate";
import { CyberIncidentService } from "../../services/Security/CyberIncidentService";
import { CyberIncidentModel } from "../../models/Security/CyberIncidentModel";


interface ContextProps {
    cyberIncident?: CyberIncidentModel,
    setCyberIncident: Dispatch<SetStateAction<CyberIncidentModel | undefined>>,
    cyberIncidents: CyberIncidentModel[],
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void
}

export const CyberIncidentContext = createContext<ContextProps>({} as ContextProps);

interface CyberIncidentProviderProps {
    children: ReactNode;
}

export const CyberIncidentProvider = ({ children }: CyberIncidentProviderProps) => {
    const [cyberIncident, setCyberIncident] = useState<CyberIncidentModel>();
    const [cyberIncidents, setCyberIncidents] = useState<CyberIncidentModel[]>([]);
    const [params, setParams] = useState<any>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new CyberIncidentService();

    const handleList = useCallback(async () => {

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response?.message || _Error);
            }

            setCyberIncidents(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar os exemplos do sistema.');
        }
    }, [params]);



    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <CyberIncidentContext.Provider value={{
            cyberIncident,
            setCyberIncident,
            cyberIncidents,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList
        }}>
            {children}
        </CyberIncidentContext.Provider>
    );
}

export const useCyberIncident = () => useContext(CyberIncidentContext);