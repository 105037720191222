import { FC, useEffect, useState } from 'react';
import './FormProposalExportFilter.scss';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useFinancialProcess } from '../../../../providers/Proposals/Process/FinancialProcessProvider';
import { ProposalExportFormFilterParams } from '../../../../models/Proposals/Export/ProposalExportFormFilterParams';
import { ReactSelectOptions } from '../../../../models/ReactSelectOptions';
import makeAnimated, { Input } from 'react-select/animated';
import { Col, FormCheck, FormGroup, Row } from 'react-bootstrap';
import InputSearchPartner from '../../../Registrations/Partner/InputSearchPartner/InputSearchPartner';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import Tagfy from '../../../Tagfy/Tagfy';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import { useProposalExport } from '../../../../providers/Proposals/Export/ProposalExportProvider';

interface FormProposalExportFilterProps { }

// @ts-ignore
export const defaultValues = {
  user: null,
  userMultistore: null,
  typistUser: '',
  table: '',
  financials: '',
  contractNumbers: [],
  cpf: [],
  withPartnerCode: false,
  startDate: '',
  endDate: '',
  startDateTyping: '',
  endDateTyping: '',
} as ProposalExportFormFilterParams;

const FormProposalExportFilter: FC<FormProposalExportFilterProps> = () => {
  const [selectedFinancial, setSelectedFinancial] = useState<ReactSelectOptions|null>(null);
  const [startDate, setStartDate] = useState<any>(moment(new Date()).format('YYYY-MM-01 HH:mm:ss'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
  const [startDateTyping, setStartDateTyping] = useState<any>();
  const [endDateTyping, setEndDateTyping] = useState<any>();
  const [valuePartner, setValuePartner] = useState<string>('');
  const [valuePartnerMultistore, setValuePartnerMultistore] = useState<string>('');
  const [contractNumbers, setContractNumbers] = useState<any[]>([]);
  const [cpf, setCpf] = useState<any[]>([]);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const { handleSubmit, control, setValue, register } = useForm<any>({ defaultValues });

  const { handleList: handleListFinancials, financialsOptions } = useFinancialProcess();
  const { handleExport, handleExportResume, handleExportResume2, handleExportSynthetic, handleExportTyping } = useProposalExport();

  const animatedComponents = makeAnimated();

  const onSubmit = async (data: ProposalExportFormFilterParams, handle: (params: ProposalExportFormFilterParams) => Promise<boolean>) => {
    setIsLoadingSubmit(true);

    data.startDate = data?.startDate ? moment(data?.startDate).format('YYYY-MM-DD') : '';
    data.endDate = data?.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : '';
    data.startDateTyping = data?.startDateTyping ? moment(data?.startDateTyping).format('YYYY-MM-DD') : '';
    data.endDateTyping = data?.endDateTyping ? moment(data?.endDateTyping).format('YYYY-MM-DD') : '';
    data.user = data?.user ? parseInt(data?.user?.value) : null;
    data.userMultistore = data?.userMultistore ? parseInt(data?.userMultistore?.value) : null;
    data.financials = data.financials?.value || null;

    handle(data).then((result: boolean) => {
      setIsLoadingSubmit(false);
    });
  }

  useEffect(() => {
    setContractNumbers([]);
    handleListFinancials();
  }, []);

  useEffect(() => {
    setValue('financials', selectedFinancial);
  }, [selectedFinancial]);

  useEffect(() => {
    setValue('contractNumbers', contractNumbers);
  }, [contractNumbers]);

  useEffect(() => {
    setValue('cpf', cpf);
  }, [cpf]);

  return (
    <div className="FormProposalExportFilter" data-testid="FormProposalExportFilter">
      <Row className="mt-1">
        <Col sm={12} md={12} lg={4} xl={4}>
          <FormGroup className="form-group">
            <label htmlFor="user">AGENTE</label>
            <InputSearchPartner
              name="user"
              control={control}
              setValue={setValue}
              valuePartner={valuePartner}
              setValuePartner={setValuePartner}
            />
          </FormGroup>
        </Col>

        <Col sm={12} md={12} lg={4} xl={4}>
          <FormGroup className="form-group">
            <label htmlFor="userMultistore">MULTILOJA</label>
            <InputSearchPartner
              name="userMultistore"
              control={control}
              setValue={setValue}
              valuePartner={valuePartnerMultistore}
              setValuePartner={setValuePartnerMultistore}
            />
          </FormGroup>
        </Col>

        <Col sm={12} md={12} lg={4} xl={4}>
          <FormGroup className="form-group">
            <label htmlFor="financials">FINANCEIRA</label>
            <ReactSelect
              name="financials"
              isClearable
              isSearchable
              options={financialsOptions}
              placeholder="Selecione..."
              className={`form-control p-0`}
              value={selectedFinancial ?? ''}
              defaultValue={selectedFinancial}
              components={animatedComponents}
              noOptionsMessage={() => 'Não há registros'}
              onChange={(val: any) => {
                setSelectedFinancial(val);
              }}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row className="mt-1">
        <Col sm={12} md={12} lg={4} xl={4}>
          <FormGroup className="form-group">
            <label htmlFor="typistUser">USUÁRIO DIGITADOR</label>
            <input
              className="form-control"
              {...register('typistUser')}
            />
          </FormGroup>
        </Col>

        <Col sm={12} md={12} lg={4} xl={4}>
          <FormGroup className="form-group">
            <label htmlFor="table">TABELA</label>
            <input
              className="form-control"
              {...register('table')}
            />
          </FormGroup>
        </Col>

        <Col sm={12} md={12} lg={4} xl={4}>
          <FormGroup className="form-group">
            <label htmlFor="contractNumbers">NÚMERO DO CONTRATO</label>
            <Tagfy
              handleAdd={(e: any) => setContractNumbers((current: any) => current ? [...current, e] : [e])}
              handleRemove={(e: any) => setContractNumbers((current: any) => current.filter((x: string) => x !== e))}
              validate={false}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row className="mt-1">
        <Col sm={12} md={12} lg={4} xl={4}>
          <FormGroup className="form-group">
            <label htmlFor="cpf">CPF</label>
            <Tagfy
              handleAdd={(e: any) => setCpf((current: any) => current ? [...current, e] : [e])}
              handleRemove={(e: any) => setCpf((current: any) => current.filter((x: string) => x !== e))}
              validate={false}
            />
          </FormGroup>
        </Col>

        <Col sm={12} md={12} lg={12} xl={4}>
          <label>PERÍODO</label>
          <InputDateRange
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setValue={setValue}
            startLabelText={''}
            endLabelText={''}
          />
        </Col>

        <Col sm={12} md={12} lg={12} xl={4}>
          <label>DATA DE DIGITAÇÃO</label>
          <InputDateRange
            startDate={startDateTyping}
            setStartDate={setStartDateTyping}
            endDate={endDateTyping}
            setEndDate={setEndDateTyping}
            setValue={setValue}
            startLabelText={''}
            endLabelText={''}
            nameStartDate='startDateTyping'
            nameEndDate='endDateTyping'
          />
        </Col>

        <Col sm={12} md={12} lg={12} xl={3}>
          <FormGroup className="form-group">
            <div className="d-flex wrapper-column">
              <label htmlFor="withPartnerCode" className="mb-0">EXIBIR CÓDIGO PARCEIRO</label>
              <FormCheck className="form-check form-switch p-0 m-0 form-input-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  {...register('withPartnerCode')}
                />
              </FormCheck>
            </div>
          </FormGroup>
        </Col>
      </Row>

      <Row className="mt-1">
        <Col>
          <div className="d-flex screen-mobile-scroll">
            <div className="ms-auto my-3">
              <div className="d-flex gap-2">
                <button className="btn bg-gradient-info text-white rounded m-0" disabled={isLoadingSubmit}
                  onClick={handleSubmit((data) => onSubmit(data, handleExportSynthetic))}>
                  <i className="fas fa-file-excel" /> Exportar Sintético
                </button>

                <button className="btn btn-primary text-white rounded m-0" disabled={isLoadingSubmit}
                  onClick={handleSubmit((data) => onSubmit(data, handleExportResume))}>
                  <i className="fas fa-file-excel" /> Exportar Resumido
                </button>

                <button className="btn btn-primary text-white rounded m-0" disabled={isLoadingSubmit}
                  onClick={handleSubmit((data) => onSubmit(data, handleExportResume2))}>
                  <i className="fas fa-file-excel" /> Exportar Resumido 2
                </button>

                <button className="btn btn-primary text-white rounded m-0" disabled={isLoadingSubmit}
                  onClick={handleSubmit((data) => onSubmit(data, handleExportTyping))}>
                  <i className="fas fa-file-excel" /> Exportar Digitação
                </button>

                <button className="btn bg-gradient-success text-white rounded m-0 ms-5" disabled={isLoadingSubmit}
                  onClick={handleSubmit((data) => onSubmit(data, handleExport))}>
                  <i className="fas fa-file-excel" /> Exportar
                </button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default FormProposalExportFilter;
