import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './InputDateTime.scss';
import { useAuth } from '../../providers/AuthProvider';
import moment from 'moment';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import 'moment/locale/pt-br';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';

interface InputDateTimeProps {
  startDate: any;
  setStartDate: Dispatch<SetStateAction<any>>;
  startLabelText?: string;
  errors?: any;
  disableStartDate?: boolean;
  setValue?: any;
  nameStartDate?: string;
  disabled?: boolean;
  showClearButton?: boolean;
}
type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const InputDateTime: FC<InputDateTimeProps> = ({
  startDate,
  setStartDate,
  startLabelText = 'Período Inicial',
  errors,
  disableStartDate = false,
  nameStartDate = 'startDate',
  setValue,
  disabled = false,
  showClearButton = true
}) => {
  const { onInvalid } = useAuth();

  moment.locale('pt-br');

  useEffect(() => {
    nameStartDate && setValue(nameStartDate, startDate);
  }, [startDate]);

  const [value, onChange] = useState<Value>(new Date());

  useEffect(() => {
    onChange(startDate);
  }, []);

  return (
    <Row className="InputDateTime " data-testid="InputDateTime">
      <Col>
        <FormGroup className="form-group">
          {startLabelText && (
            <label className="form-control-label" htmlFor={nameStartDate}>
              {startLabelText}
            </label>
          )}
          <DateTimePicker
            onChange={(val) => {
              onChange(val);
              setStartDate(moment(val).format('YYYY-MM-DD HH:mm:ss'));
            }}
            value={value}
            defaultValue={null}
            format="dd/MM/y HH:mm"
            locale="pt-BR"
            minDate={undefined}
            className={`form-control left react-calendar`}
            disabled={disabled}
            clearIcon={showClearButton ? <FontAwesomeIcon icon={faRemove} /> : null}
          />
          <ErrorMessage name="Data" type={errors?.[nameStartDate]?.type} />
        </FormGroup>
      </Col>

    </Row>
  );
}

export default InputDateTime;
