import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { VisitsTypesActivitiesModel } from "../../../models/Visits/VisitsTypesActivities/VisitsTypesActivitiesModel";
import { VisitsTypesActivitiesParams } from "../../../models/Visits/VisitsTypesActivities/VisitsTypesActivitiesParams";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { VisitsTypesActivitiesService } from "../../../services/Visits/VisitsTypesActivities/VisitsTypesActivitiesService";
import { toast } from "react-toastify";
import { ToastSettings } from "../../../utils/ToastSettings";

interface ContextProps {
    typesActivitie: VisitsTypesActivitiesModel,
    setTypesActivitie: Dispatch<SetStateAction<VisitsTypesActivitiesModel>>,
    typesActivities: VisitsTypesActivitiesModel[],
    params: VisitsTypesActivitiesParams,
    setParams: Dispatch<SetStateAction<VisitsTypesActivitiesParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleFetch: (data: VisitsTypesActivitiesModel) => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>,
}

export const VisitsTypesActivitiesContext = createContext<ContextProps>({} as ContextProps);

interface VisitsTypesActivitiesProviderProps {
    children: ReactNode;
}

export const VisitsTypesActivitiesProvider = ({ children }: VisitsTypesActivitiesProviderProps) => {
    const [typesActivitie, setTypesActivitie] = useState<VisitsTypesActivitiesModel>({} as VisitsTypesActivitiesModel);
    const [typesActivities, setTypesActivities] = useState<VisitsTypesActivitiesModel[]>([]);
    const [params, setParams] = useState<VisitsTypesActivitiesParams>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new VisitsTypesActivitiesService();

    const handleList = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response?.message || _Error);
            }

            setTypesActivities(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar os tipos de contatos');
        }
    }

    const handleFetch = async (data: VisitsTypesActivitiesModel) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.fetch(data);
            if (!!_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setError('Houve um erro ao salvar o tipo de atividade');
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar o tipo de atividade');
            return false;
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        handleList()
    }, [params]);

    return (
        <VisitsTypesActivitiesContext.Provider value={{
            typesActivitie,
            setTypesActivitie,
            typesActivities,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleFetch,
            handleDelete,
        }}>
            {children}
        </VisitsTypesActivitiesContext.Provider>
    );
}

export const useVisitsTypesActivities = () => useContext(VisitsTypesActivitiesContext);