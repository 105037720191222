import api from "../../../Api";
import { BaseService } from "../../../BaseService";


const URL = 'api/services/commissioning/receipt/write-off';

export class ReceiptWriteOffSerive extends BaseService {

    async fetch(payload: any): Promise<any> {
        try {
            const formData = new FormData();
            Object.keys(payload).forEach((key) => {

                if (payload[key] !== undefined) {
                    formData.append(key, payload[key]);
                }
            })

            return this.handleResponse(await api.post(`${URL}/save`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async delete(id: number): Promise<any> {
        try {
            return this.handleResponse(await api.delete(`${URL}`, { data: { id } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}