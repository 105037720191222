export const customStyles = {
    control: (_: any, { selectProps: { width, isDisabled } }: any) => ({
        display: 'flex',
        width: width,
        padding: '1px 2px 1px 0',
        cursor: isDisabled ? 'not-allowed' : 'default',
        color: isDisabled ? '#aaa' : 'white',
        backgroundColor: isDisabled ? '#e9ecef' : 'white',
        borderRadius: '0.5rem',
    }),
    placeholder: (defaultStyles: any) => ({
        ...defaultStyles,
        paddingLeft: '5px',
    }),
    input: (defaultStyles: any) => ({
        ...defaultStyles,
        paddingLeft: '3px',
    }),
    singleValue: (provided: any) => {
        return {
            ...provided,
            paddingLeft: '5px',
        };
    },
    menu: (defaultStyles: any) => {
        return {
            ...defaultStyles,
            zIndex: 999999,
        };
    }
}

export const customStylesSmallText = {
    control: (_: any, { selectProps: { width } }: any) => ({
        display: 'flex',
        width: width,
        padding: '0 15px',
        fontSize: '15px',
    }),
    option: (styles: any) => {
        return {
            ...styles,
            fontSize: '14px',
        };
    },
    placeholder: (defaultStyles: any) => ({
        ...defaultStyles,
        marginLeft: '-15px',
    }),
    singleValue: (provided: any) => {
        return {
            ...provided,
            marginLeft: '-15px',
            fontSize: '14px',
        };
    },
    menu: (defaultStyles: any) => {
        return {
            ...defaultStyles,
            zIndex: 999999
        };
    },
}