import { FirebaseMessagingFetchParams } from "../models/Fcm/FirebaseMessagingFetchParams";
import api from "./Api";
import { BaseService } from "./BaseService";

const URL = 'api/fcm/token';

export class FirebaseMessagingService extends BaseService {
    async fetch(params: FirebaseMessagingFetchParams): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/saveLoggedUser`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}