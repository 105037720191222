import { MonthlyGoalAccessProfileEnum } from "../../enums/PanelConnects/MonthlyGoalAccessProfileEnum";

export const monthlyGoalAccessProfileOptions: any[] = [
    {
        value: MonthlyGoalAccessProfileEnum.COMMERCIAL_ADVISOR,
        label: 'Assessor Comercial',
        rule: 'painel_conecta.ver_comercialregra'
    },
    {
        value: MonthlyGoalAccessProfileEnum.DIGITAL_COMMERCIAL_ADVISOR,
        label: 'Assessor Comercial Digital',
        rule: 'painel_conecta.ver_comercial_digitalregra'
    },
    {
        value: MonthlyGoalAccessProfileEnum.CONSORTIUM_COMMERCIAL_ADVISOR,
        label: 'Assessor Comercial Consórcio',
        rule: 'painel_conecta.ver_comercial_consorcioregra'
    },
];