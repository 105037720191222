import { FC, Fragment, useEffect, useState } from 'react';
import './ModalCampaignImportedList.scss';
import MasterPage from '../../../MasterPage/MasterPage';
import PageBase from '../../../PageBase/PageBase';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import AppPagination from '../../../AppPagination/AppPagination';
import { useSidebarFilter } from '../../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../../providers/PaginateProvider';
import { useExample } from '../../../../providers/ExampleProvider';
import { toast } from 'react-toastify';
import DropdownMenuTable from '../../../DropdownMenuTable/DropdownMenuTable';
import { Button, Card, Dropdown, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faArrowDown, faArrowDown19, faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useCampaign } from '../../../../providers/Marketplace/Campaign/CampaignProvider';
import { CampaignModel } from '../../../../models/Marketplace/Campaign/CampaignModel';
import { CampaignExcelLineModel, CampaignExcelListModel } from '../../../../models/Marketplace/Campaign/CampaignExcelListModel';
import { dateFormat } from '../../../../utils/FormatDate';
import ModalOpportunityDetails from '../../Opportunity/ModalOpportunityDetails/ModalOpportunityDetails';

interface ModalCampaignImportedListProps {
  show: boolean,
  onClose: any,
}

const ModalCampaignImportedList: FC<ModalCampaignImportedListProps> = ({show, onClose}) => {
  const {
    campaignExcelList, setCampaigns,
    campaignFilter, setCampaignFilter,
    campaignExcelListPages, setCampaignExcelListPages,
    error, setError,
    handleCampaignImportedList,
    handleDisableCampaign,
    loading, isLoadingFile
  } = useCampaign();

  const [showOpportunities, setShowOpportunities] = useState<boolean>(false);
  const [opportunityView, setOpportunityView] = useState<number | null>(null);

  const [state, setState] = useState(0);


  const handleClick = (index: number) => {
    const updatedState = campaignExcelList[index];

    if (updatedState.view) {
      updatedState.view = false;
      setState((pre) => {
        return pre + 1;
      });
    } else {
      updatedState.view = true;
      setState((pre) => {
        return pre + 1;
      });
    }
  };

  const renderList = () => {
    return (
      <>
        {campaignExcelList?.length > 0 ? (
          <>
            {campaignExcelList.map((line: CampaignExcelListModel, index) => (
              <Fragment key={line.excel_id}>
                <tr key={line.excel_id} className="text-sm" style={{ cursor: "pointer" }} onClick={() => handleClick(index)}>
                  <td>{line.excel_id}</td>
                  <td>{line.excel_namefile}</td>
                  <td>{line.excel_title}</td>
                  <td>{dateFormat({ date: line.excel_datetime, datetime: false })}</td>
                  <td>{line.excel_agent}</td>
                  <td><FontAwesomeIcon icon={line.view ? faAngleUp: faAngleDown} className="me-2" /></td>
                </tr>
                {line.view ? (
                  <Fragment>
                    <tr>
                        <td colSpan={6}>
                          <Card>
                            <table className="table table-flush m-0 w-100">
                              <thead className="thead-light">
                                <tr>
                                  <th className="text-uppercase opacity-7" scope="col">Resultado</th>
                                  <th className="text-uppercase opacity-7" scope="col">Linha</th>
                                  <th className="text-uppercase opacity-7" scope="col">Info</th>
                                  <th className="text-uppercase opacity-7" scope="col">Oportunidade</th>
                                  <th className="text-uppercase opacity-7" scope="col">Ação</th>
                                </tr>
                              </thead>
                              <tbody>
                                {line?.lines?.map((subline: CampaignExcelLineModel) => (
                                  <tr>
                                    <td>
                                      <div className={`badge badge-sm ${subline?.line_error ? 'badge-danger' : 'badge-success'}`}>
                                        <small>{subline?.line_error ? 'ERRO' : 'SUCESSO'}</small>
                                      </div>
                                    </td>
                                    <td><small>{subline?.line_number}</small></td>
                                    <td className='text-break'><small>{subline?.line_info}</small></td>
                                    <td><small>{subline?.line_opportunity}</small></td>
                                    <td>
                                      {subline?.line_error ? '' : <Button className='p-2 m-0' onClick={() => {setShowOpportunities(true); setOpportunityView(subline?.line_opportunity || 0); }} ><small>visualizar</small></Button>}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Card>
                      </td>
                    </tr>
                  </Fragment>
              ) : null}
              </Fragment>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={6} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContentList = (): JSX.Element => {
    return (
      <>
        {!loading?.listExcel ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7" scope="col">Código</th>
                  <th className="text-uppercase opacity-7" scope="col">Nome do arquivo</th>
                  <th className="text-uppercase opacity-7" scope="col">Nome da campanha</th>
                  <th className="text-uppercase opacity-7" scope="col">Data Importação</th>
                  <th className="text-uppercase opacity-7" scope="col">Agente</th>
                  <th className="text-uppercase opacity-7" scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}

        {campaignExcelList?.length > 0 && (
              <div>
                <nav aria-label="...">
                  <AppPagination
                    pages={campaignExcelListPages}
                    handlePaginate={handleCampaignImportedList}
                    style={{ backgroundColor: '#fff' }}
                  />
                </nav>
              </div>
            )}
      </>
    );
  }

  useEffect(() => {
    if (show){
      handleCampaignImportedList();
    }
  }, [show]);

  return (
    <ModalDefault
          title={"Campanhas geradas por excel"}
          show={show}
          onClose={onClose}
          sizeModal={'xxl'}
          showFooter={true}
          backdrop="static"
          backgroundColor="#f8f9fa"
      > 

      <ModalOpportunityDetails
      show={showOpportunities}
      onClose={setShowOpportunities}
      opportunityNumber={opportunityView}
      setOpportunityNumber={setOpportunityView}
      />
        
      {renderContentList()}
        
    </ModalDefault>

    

    
  );
}

export default ModalCampaignImportedList;
