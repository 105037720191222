export const informatives = [
    {
        id: 1,
        message: 'Simplificando a LGPD - Veja como se adequar as regras da LGPD',
        sendDate: '2023-09-19 22:11:00',
        readDate: '',
        identify: '23/197-09',
    },
    {
        id: 2,
        message: 'Banco Pan - Averbação LOAS',
        sendDate: '2023-09-19 22:11:00',
        readDate: '2023-09-19 22:11:00',
        identify: '23/201-09',
    },
    {
        id: 3,
        message: 'C6 Consig - Já estamos pagando LOAS INSS',
        sendDate: '2023-09-19 22:11:00',
        readDate: '2023-09-19 22:11:00',
        identify: '23/194-09',
    },
    {
        id: 4,
        message: 'Banco BMG - Atenção Nova regra para operações de LOAS INSS!',
        sendDate: '2023-09-19 22:11:00',
        readDate: '',
        identify: '23/192-09',
    },
    {
        id: 5,
        message: 'Fatores do Dia INSS 18-09-2023',
        sendDate: '2023-09-19 22:11:00',
        readDate: '',
        identify: '23/192-09',
    },
    {
        id: 6,
        message: 'Fatores do Dia Siape 18-09-2023',
        sendDate: '2023-09-19 22:11:00',
        readDate: '',
        identify: '23/192-09',
    },
    {
        id: 7,
        message: 'Banco Pan - Movimentações - Maciça INSS',
        sendDate: '2023-09-19 22:11:00',
        readDate: '',
        identify: '23/192-09',
    },
];