import { IndicatorManagementWalletParams } from "../../../models/Visits/IndicatorManagement/IndicatorManagementWalletParams";
import { BaseService } from "../../BaseService";
import api from "../../Api";
import FileSaver from 'file-saver';

const URL = 'api/domus/pgi/wallet';

export class IndicatorManagementWalletService extends BaseService {

    async list(params: IndicatorManagementWalletParams): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async export(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/export`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async delete(id: number): Promise<any> {
        try {
            return this.handleResponse(await api.delete(`${URL}`, { data: { id } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async exportSub(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/export/sub`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}