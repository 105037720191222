import { FC } from 'react';
import './Home.scss';
import MasterPage from '../../components/MasterPage/MasterPage';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useStyle } from '../../providers/Style/StyleProvider';
import { useAuth } from '../../providers/AuthProvider';

interface HomeProps { }

const Home: FC<HomeProps> = () => {
  const { style, urlDomus } = useStyle();
  const { accessToken } = useAuth();

  return (
    <MasterPage
      title={'Business Intelligence Produção'}
      icon={'fas fa-home'}
      heightClass="min-height-470"
      footerFixed
    >
      <div className="Home" data-testid="Home">
        <Row>
          <Col md={12}>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <div>
                <h4 className="text-white m-0">Bem-vindo ao <b>Painel {style?.name}</b></h4>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card className="h-100">
              <Card.Body className="text-center h-card">
                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                  <h5>Para acessar a página inicial<br />clique no botão abaixo</h5>
                  <div className="viewButtons">
                    <a href={`${urlDomus}/app/api/v1/auth/authTokenSB?token=${accessToken}`}>
                      <Button className="mt-4 fadeIn2 fadeInBottom">Ir para a página inicial</Button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </MasterPage >
  );
}

export default Home;
