import { FC, useEffect, useState } from 'react';
import './AuthenticatorValidation.scss';
import { useStyle } from '../../../providers/Style/StyleProvider';
import { Card, CardBody, Col, Container, Row, Image, Button, CardHeader } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { realtimedb } from '../../../firebase';
import { onValue, ref, } from 'firebase/database';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../providers/AuthProvider';
import Spinner from '../../../components/Spinner/Spinner';
import { useAuthenticatorValidation } from '../../../providers/Authenticator/AuthenticatorValidationProvider';
import NavbarSite from '../../../components/NavbarSite/NavbarSite';
import StoreLinks from '../../../components/StoreLinks/StoreLinks';
import { CompanyEnum } from '../../../enums/Company/CompanyEnum';

interface AuthenticatorValidationProps { }

const AuthenticatorValidation: FC<AuthenticatorValidationProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(60);
  const [disabled, setDisable] = useState<boolean>(true);

  const urlParams = new URLSearchParams(window.location.search);
  const { isLoading: isLoadingStyle, style, urlDomus, handleStyle } = useStyle();
  const { tokenResponse, setTokenResponse, handleGet, handleFetch } = useAuthenticatorValidation();
  const { handleMe } = useAuth();

  useEffect(() => {
    handleStyle().then();
  }, []);

  useEffect(() => {
    if (urlDomus && tokenResponse) {
      const query = ref(realtimedb, '/tokens');
      return onValue(query, (snapshot) => {
        const data = snapshot.val();

        if (snapshot.exists()) {
          const _response = Object.values(data).map((v: any) => v)?.filter((item: any) => item?.token === tokenResponse).shift();

          switch (_response?.response) {
            case 1:
              window.location.href = `${urlDomus}/app/api/v1/auth/authTokenSB?token=${urlParams.get('token')}`;
              break;
            case 0:
              window.location.href = `${urlDomus}`;
              break;
          }
        }
      });
    }
  }, [urlDomus, tokenResponse]);

  const handleToken = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    let logged = false;

    if (urlParams.get('token')) {
      logged = await handleMe(urlParams.get('token') ?? '');

      if (logged) {
        handleGet();
      } else {
        return window.location.href = `${urlDomus}`;
      }
    } else {
      return window.location.href = `${urlDomus}`;
    }
  }

  useEffect(() => {
    !!urlParams && handleToken();
  }, []);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    if (counter === 0) {
      setDisable(false);
    }

    if (timer) {
      return () => clearInterval(timer);
    }
  }, [counter]);

  useEffect(() => {
    setTokenResponse('');
    setIsLoading(false);
    setCounter(60);
  }, []);

  return (
    <main className="AuthenticatorValidation main-content main-content-bg mt-0 h-100" data-testid="AuthenticatorValidation">
      {(!isLoadingStyle && style) && (
        <>
          <NavbarSite />
          <div className={`page-header min-vh-100`}>
            <Container>
              <Row className="justify-content-center align-items-center">
                <Col md={9} xs={12}>
                  <Row className="justify-content-center align-items-center">
                    <Col md={6} xs={12}>
                      <StoreLinks />
                    </Col>

                    <Col md={6} xs={12}>
                      <Card>
                        <CardBody className="p-md-4 p-3">
                          <Row className="justify-content-center align-items-center">
                            <Col md={10} xs={10}>
                              <h4 className="mb-0"> Verificação em duas etapas </h4>
                            </Col>
                            <Col md={2} xs={2} className="text-center">
                              <Image src={require('./../../../assets/img/padlock.svg').default} alt="lock" fluid />
                            </Col>
                            <Col md={12} className="my-md-3 my-2">
                              <p className="text-start mb-0"> Para <b> proteger </b> a sua conta, queremos confirmar se é realmente você que está tentando fazer login. </p>
                            </Col>
                          </Row>
                          <CardBody className="bg-card-body mb-md-4 mb-3 p-3">
                            <Row>
                              <Col md={12}>
                                <Card>
                                  <CardHeader className="p-3">
                                    <Row className="justify-content-center align-items-center">
                                      <Col md={2} xs={2}>
                                        <span className="icon-bevi">
                                          <Image src={require('./../../../assets/img/bevi-logo-b-white.svg').default} alt="logo" fluid width={25}/>
                                        </span>
                                      </Col>
                                      <Col md={10} xs={10} className="p-0">
                                        <small className="mx-1 text-notify">
                                          Bevi - agora
                                        </small>
                                        <small className="mx-2">
                                          <Image src={require('./../../../assets/img/bell.svg').default} alt="logo" fluid width={15}/>
                                        </small>
                                      </Col>
                                    </Row>
                                  </CardHeader>
                                  <CardBody className="px-md-1 px-3 pt-0 pb-3">
                                    <Row className="justify-content-end">
                                      <Col md={10} xs={12}>
                                        <Row>
                                          <Col md={12}>
                                          <p className="mb-0 text-notify"> <b> Tentando fazer login? </b> </p>
                                          </Col>
                                          <Col md={12}>
                                            <p className="mb-0 text-notify"> <small> Você está tentando fazer login no domus? </small> </p>
                                          </Col>
                                          <Col md={12} className="mt-1">
                                            <span> Não </span>
                                            <span className="mx-3"> Sim </span>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col md={12} className="text-start">
                                <p className="mt-2 mb-0">
                                  Enviamos uma notificação para o seu smartphone. Abra o App { style?.id === CompanyEnum.BEVI ? 'Bevi' : 'Multsistema' }  e toque em <b> Sim </b> na solicitação para confirmar sua identidade.  
                                </p>
                              </Col>
                            </Row>
                          </CardBody>
                          <Row className="justify-content-center align-items-center text-center">
                            <Col md={12}>
                              <Button 
                                className="mb-0 w-100 p-3"
                                disabled={isLoading || counter > 0 || disabled}
                                onClick={() => {
                                  setCounter(60);
                                  setDisable(true);
                                  handleFetch().then((response: boolean) => {
                                    if (response) {
                                      handleToken();
                                    }
                                  });
                                }}
                              > 
                              {!isLoading ? (
                                  `Reenviar notificação (${counter})`
                                ) : (
                                  <FontAwesomeIcon icon={faSpinner} spin />
                                )}
                              </Button>
                            </Col>
                            <Col md={12}>
                              <Link to={`/authenticator/validation-alternative`}>
                                <button
                                  type="button"
                                  className={`btn btn-link w-100 color-link mb-0 pb-0`}
                                  disabled={isLoading}
                                >
                                  Tentar de outro jeito
                                </button>
                              </Link>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}

      {isLoadingStyle && (
        <div className='mt-12'>
          <Spinner backgroundColor='white' opacity='1' />
        </div>
      )}
    </main>
  );
}

export default AuthenticatorValidation;
