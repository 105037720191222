import * as yup from "yup";

export const schema = yup.object().shape({
    name: yup
        .string()
        .required()
        .min(3)
        .max(100),
    description: yup
        .string()
        .required()
        .min(3)
        .max(255),
    identifier: yup
        .string()
        .required()
        .min(3)
        .max(255),
    prefixFilename: yup
        .string()
        .required(),
    extension: yup
        .string()
        .required(),
    classJob: yup
        .string()
        .required()
        .min(3)
        .max(255),
    microservice: yup
        .string()
        .nullable(),
    endpoint: yup
        .string()
        .when('microservice', {
            is: (item: any) => !!item,
            then: (schema) => schema.test('typeError', 'Rota da Api é obrigatória', (value: any) => !!value).required().min(3).max(255),
            otherwise: (schema) => schema.notRequired()
        }),
    active: yup
        .bool(),
});