import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { PagesPaginateModel } from "../../models/Paginate/PagesPaginate";
import { useLocation } from "react-router-dom";
import { LogService } from "../../services/Logs/LogService";
import { toast } from "react-toastify";
import { ToastSettings } from "../../utils/ToastSettings";
import { LogModel } from "../../models/Logs/LogScreen/LogModel";
import moment from "moment";
import { LogFormFilterParams } from "../../models/Logs/LogScreen/LogFormFilterParams";

interface ContextProps {
    log: LogModel,
    setLog: Dispatch<SetStateAction<LogModel>>,
    logs: LogModel[],
    params: LogFormFilterParams,
    setParams: Dispatch<SetStateAction<LogFormFilterParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    isLoadingFile: boolean,
    isLoadingPage: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleFetch: (data: LogModel) => Promise<boolean>,
    handleExcel: () => void,
}

export const LogContext = createContext<ContextProps>({} as ContextProps);

interface LogProviderProps {
    children: ReactNode;
}

export const LogProvider = ({ children }: LogProviderProps) => {
    const [log, setLog] = useState<LogModel>({} as LogModel);
    const [logs, setLogs] = useState<LogModel[]>([]);
    const [params, setParams] = useState<LogFormFilterParams>({
        page: 1,
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
    });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);

    const service = new LogService();
    const location = useLocation();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/tool/log/screen/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response?.message || _Error);
            }

            setLogs(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar os logs de acessos por tela');
        }
    }, [params, location.pathname]);

    const handleFetch = async (data: LogModel) => {
        try {
            setIsLoadingPage(true);
            const [_Response, _Error, _Code, _Errors] = await service.fetch(data);
            if (!!_Error) {
                setIsLoadingPage(false);
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setIsLoadingPage(false);
            setError('');
            return true;
        } catch (e) {
            setIsLoadingPage(false);
            setError('Houve um erro ao salvar o log');
            return false;
        }
    }

    const handleExcel = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.export(params);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <LogContext.Provider value={{
            log,
            setLog,
            logs,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            isLoadingFile,
            isLoadingPage,
            error,
            setError,
            handleList,
            handleFetch,
            handleExcel,
        }}>
            {children}
        </LogContext.Provider>
    );
}

export const useLog = () => useContext(LogContext);