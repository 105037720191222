import { FC, useEffect, useState } from 'react';
import './ModulesTools.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import { usePaginate } from '../../../providers/PaginateProvider';
import { toast } from 'react-toastify';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import ModalModulesToolsInsert from '../../../components/Registrations/ModuleTools/ModalModulesToolsInsert/ModalModulesToolsInsert';
import { useModuleTools } from '../../../providers/Registrations/ModuleTools/ModuleToolsProvider';
import { ModuleModel } from '../../../models/Registrations/ModulesTool/ModuleModel';
import { ToolModel } from '../../../models/Registrations/ModulesTool/ToolModel';
import moment from 'moment';
import SidebarFilter from '../../../components/SidebarFilter/SidebarFilter';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';

interface ModulesToolsProps { }

const ModulesTools: FC<ModulesToolsProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();

  const { modules, setModuleEdit, pages, params, setParams, handleDeleteModule, handleList, error, setError, isLoading } = useModuleTools();
  const { handlePaginate } = usePaginate();

  useEffect(() => {
    handleList().then();
  }, [params]);

  useEffect(() => {
    if (showSidebarFilter) {
      setShowSidebarFilter(false)
    }
  }, [showSidebarFilter]);

  useEffect(() => {
    if (!!error) {
      if (Array.isArray(error)) {
        toast.error(<>
          {error?.map((x: string) => (<div>{x}</div>))}
        </>);
      } else {
        toast.error(error);
      }
      setError('');
    }
  }, [error]);

  const handleEdit = (item: any) => {
    setModuleEdit(item);
    setShowModalInsert(true);
  }

  const handleDeleteModulesTools = (item: any) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja excluir o módulo <b>${item?.name}</b>?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleDeleteModule(item.id || 0).then();
        if (ret) {
          toast.success('Módulo deletado com sucesso!');
          handleList();
        }
      }
    });
  }

  const renderList = (): JSX.Element => {
    return (
      <>
        {modules?.length > 0 ? (
          <>
            {modules?.map((x: ModuleModel, key: number) => (
              <tr key={x.id} className="text-sm">
                <td className="text-center">{x.id}</td>
                <td>{x?.name}</td>
                <td>{x?.description}</td>
                <td>
                  <div className='d-flex gap-2'>
                    {x.tools?.map((tool: ToolModel) => (
                      <span key={tool.id} className='badge bg-info'>{tool.name}</span>
                    ))}
                  </div>
                </td>
                <td>
                  {!!x.updatedAt ? moment(x.updatedAt).format('DD/MM/YYYY') : moment(x.createdAt).format('DD/MM/YYYY')}
                </td>
                <td className="text-center">
                  <DropdownMenuTable key={key}>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleEdit(x)}>
                      <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
                    </Dropdown.Item>
                    {!x?.deletedAt && (
                      <Dropdown.Item className="border-radius-md py-2" onClick={() => handleDeleteModulesTools(x)}>
                        <FontAwesomeIcon icon={faTrash} className="me-2" /> Remover
                      </Dropdown.Item>
                    )}
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={5} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive" style={{ minHeight: '200px' }}>
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7 text-center" scope="col">#</th>
                  <th className="text-uppercase opacity-7" scope="col">Nome</th>
                  <th className="text-uppercase opacity-7" scope="col">Descrição</th>
                  <th className="text-uppercase opacity-7" scope="col">Ferramentas</th>
                  <th className="text-uppercase opacity-7" scope="col">Data</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>

            {modules?.length > 0 && (
              <div>
                <nav aria-label="...">
                  <AppPagination
                    pages={pages}
                    handlePaginate={handlePaginate}
                    params={params}
                    setParams={setParams}
                    style={{ backgroundColor: '#fff' }}
                  />
                </nav>
              </div>
            )}
          </div>
        ) : (
          <SkeletonTable />
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="ModulesTools"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="ModulesTools" data-testid="ModulesTools">
        <PageBase
          title="Módulos e Ferramentas"
          subtitle="Manutenção dos módulos e ferramentas que disparam notificações no sistema."
          handleInsert={() => setShowModalInsert(true)}
          content={renderContent()}
        />

        <ModalModulesToolsInsert
          show={showModalInsert}
          onClose={() => setShowModalInsert(false)}
        />
      </div>

      <SidebarFilter />

      {/* <SidebarModulesToolsFilter /> */}

    </MasterPage>
  );
}

export default ModulesTools;
