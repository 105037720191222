import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BiMonthlyProductionModel } from "../../../models/BI/Dashboard/BiMonthlyProductionModel";
import { BiAnnualProductionModel } from "../../../models/BI/Dashboard/BiAnnualProductionModel";


interface ContextProps {
    monthlyGroupProduction: BiMonthlyProductionModel | undefined,
    setMonthlyGroupProduction: Dispatch<SetStateAction<BiMonthlyProductionModel | undefined>>,
    annualGroupProduction: BiAnnualProductionModel | undefined,
    setAnnualGroupProduction: Dispatch<SetStateAction<BiAnnualProductionModel | undefined>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
}

export const BiGroupContext = createContext<ContextProps>({} as ContextProps);

interface BiGroupProviderProps {
    children: ReactNode;
}

export const BiGroupProvider = ({ children }: BiGroupProviderProps) => {
    const [monthlyGroupProduction, setMonthlyGroupProduction] = useState<BiMonthlyProductionModel>()
    const [annualGroupProduction, setAnnualGroupProduction] = useState<BiAnnualProductionModel>();
    const [error, setError] = useState<string>('');

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);



    return (
        <BiGroupContext.Provider value={{
            monthlyGroupProduction,
            setMonthlyGroupProduction,
            annualGroupProduction,
            setAnnualGroupProduction,
            error,
            setError,
        }}>
            {children}
        </BiGroupContext.Provider>
    );
}

export const useBiGroup = () => useContext(BiGroupContext);