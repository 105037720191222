import { FC, useEffect, useRef, useState } from 'react';
import './SidebarPreVisitsAnalysisFilter.scss';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import { Controller, useForm } from 'react-hook-form';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { situation } from '../../../../enums/Visits/PreVisitsAnalysis/EnumPreVisitsAnalysisSituation';
import { useCommercial } from '../../../../providers/Registrations/User/UserCommercialProvider';
import InputSearchPartner from '../../../Registrations/Partner/InputSearchPartner/InputSearchPartner';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import { PreVisitsAnalysisFormFilterParams } from '../../../../models/Visits/PreVisitsAnalysis/PreVisitsAnalysisFormFilterParams';
import moment from 'moment';
import { useIndicatorManagementPreVisitVisits } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementPreVisitProvider';
import makeAnimated from 'react-select/animated';

interface SidebarPreVisitsAnalysisFilterProps { }

// @ts-ignore
export const defaultValues = {
  userId: null,
  commercialId: null,
  wasApproved: null,
  startDate: '',
  endDate: '',
} as PreVisitsAnalysisFormFilterParams;

const SidebarPreVisitsAnalysisFilter: FC<SidebarPreVisitsAnalysisFilterProps> = () => {

  const [startDate, setStartDate] = useState<any>(moment(new Date()).subtract(15, 'days').format('YYYY-MM-DD HH:mm:ss'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
  const [dateMonth] = useState<any>(moment(new Date()).add(12, 'M').format('YYYY-MM-DD HH:mm:ss'))
  const [valuePartner, setValuePartner] = useState<string>('');
  const [selectedCommercial, setSelectedCommercial] = useState<any>();

  const animatedComponents = makeAnimated();

  const refSubmitButtom = useRef<HTMLButtonElement>(null);

  const { handleSubmit, control, setValue } = useForm<any>({ defaultValues });
  const { setParams } = useIndicatorManagementPreVisitVisits();
  const { commercialsOptions, handleList: handleListCommercial } = useCommercial();

  useEffect(() => {
    if (commercialsOptions && commercialsOptions?.length > 1) {
      refSubmitButtom?.current?.click();
    }
    if (commercialsOptions && commercialsOptions?.length === 1) {
      setSelectedCommercial(commercialsOptions[0]);
    }
  }, [commercialsOptions]);

  useEffect(() => {
    setValue('commercialId', selectedCommercial)
  }, [selectedCommercial]);

  const onSubmit = async (data: PreVisitsAnalysisFormFilterParams) => {
    data.startDate = data?.startDate ? moment(data?.startDate).format('YYYY-MM-DD') : '';
    data.endDate = data?.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : '';

    data.userId = data?.userId?.value;
    data.commercialId = data?.commercialId?.value;

    setParams({ ...data, ...{ page: 1 } });
  }

  useEffect(() => {
    handleListCommercial().then();
    setParams({ startDate, endDate });
  }, [])

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarPreVisitsAnalysisFilter" data-testid="SidebarPreVisitsAnalysisFilter">

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="userVisitorId">COMERCIAL</label>
              <Controller
                name="commercialId"
                control={control}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    value={selectedCommercial}
                    isSearchable
                    isClearable={commercialsOptions?.length > 1}
                    options={commercialsOptions}
                    placeholder="Selecione..."
                    className={`form-control p-0`}
                    components={animatedComponents}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={(val: any) => {
                      onChange(val?.value ?? null);
                      setSelectedCommercial(val);
                    }}
                    styles={customStyles}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="userVisitId">PARCEIRO</label>
              <InputSearchPartner
                name="userId"
                control={control}
                setValue={setValue}
                valuePartner={valuePartner}
                setValuePartner={setValuePartner}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              startLabelText={'DATA INICIAL'}
              endLabelText={'DATA FINAL'}
              maxEndDate={dateMonth}
            />
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="wasApproved">SITUAÇÃO</label>
              <Controller
                name="wasApproved"
                control={control}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isClearable
                    options={situation}
                    placeholder="Selecione..."
                    className={`form-control p-0`}
                    components={animatedComponents}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={(val: any) => {
                      onChange(val?.value ?? null);
                    }}
                    styles={customStyles}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

      </div>
    </SidebarFilter>
  );
}

export default SidebarPreVisitsAnalysisFilter;
