import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import './ModalMonthlyGoalCategoryInsert.scss';
import { MonthlyGoalCategoryModel } from '../../../../models/PanelConnects/MonthlyGoalCategoryModel';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validate';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useMonthlyGoalCondition } from '../../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalConditionProvider';
import { toast } from 'react-toastify';

interface ModalMonthlyGoalCategoryInsertProps {
  show: boolean,
  onClose: any,
  categoryEdit?: MonthlyGoalCategoryModel,
  setWasAdded?: Dispatch<SetStateAction<boolean>>
}

const ModalMonthlyGoalCategoryInsert: FC<ModalMonthlyGoalCategoryInsertProps> = ({ show, onClose, categoryEdit, setWasAdded }) => {

  const { handleSaveCategory, isLoading, handleCategory } = useMonthlyGoalCondition();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    clearErrors
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  useEffect(() => {
    if (!show) {
      setValue('name', '');
      setValue('description', '');
      clearErrors();
    }
  }, [show]);

  useEffect(() => {
    if (categoryEdit) {
      setValue('id', categoryEdit.id);
      setValue('name', categoryEdit.name ?? '');
      setValue('description', categoryEdit.description ?? '');
    }
  }, [categoryEdit]);

  const onSubmit = async (data: any) => {
    const result = await handleSaveCategory({
      ...data,
      name: data?.name?.toUpperCase(),
      description: data?.description?.toUpperCase()
    }).then();
    if (result) {
      toast.success('Categoria salva com sucesso!');
      handleCategory();
      if (setWasAdded) {
        setWasAdded(true);
      }
      onClose();
    }
  }

  return (
    <ModalDefault
      title={'Manutenção de Categoria'}
      show={show}
      onClose={onClose}
      sizeModal={'lg'}
      showFooter={true}
      buttonText={isLoading ? '...Salvando' : 'Salvar'}
      disabledSubmit={isLoading}
      showButtonSpinner={isLoading}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalMonthlyGoalCategoryInsert" data-testid="ModalMonthlyGoalCategoryInsert">
        <Row>
          <Col>
            <FormGroup className='form-group'>
              <label>NOME *</label>
              <input
                className='form-control'
                type='text'
                {...register('name')}
              />
              <ErrorMessage name={'Nome'} type={errors?.name?.type} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup className='form-group'>
              <label>DESCRIÇÃO *</label>
              <textarea
                className='form-control'
                rows={4}
                {...register('description')}
              />
              <ErrorMessage name={'Descrição'} type={errors?.description?.type} />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  )
};

export default ModalMonthlyGoalCategoryInsert;
