import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './ModalMasterCovenantGroupInsert.scss';
import { MasterCovenantGroupModel } from '../../../../models/Registrations/Covenant/MasterCovenantGroupModel';
import makeAnimated from 'react-select/animated';
import { useMasterCovenantGroup } from '../../../../providers/Registrations/Covenant/MasterCovenantGroupProvider';
import { useAuth } from '../../../../providers/AuthProvider';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validate';
import { yesNoOption } from '../../../../models/YesNoOptions';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { Card, Col, Row } from 'react-bootstrap';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { CovenantGroupModel } from '../../../../models/Registrations/Covenant/CovenantGroupModel';
import moment from 'moment';
import { toast } from 'react-toastify';

interface ModalMasterCovenantGroupInsertProps {
  show: boolean,
  onClose: any,
  itemEdit?: MasterCovenantGroupModel
  setItemEdit?: Dispatch<SetStateAction<MasterCovenantGroupModel | undefined>>
}

const ModalMasterCovenantGroupInsert: FC<ModalMasterCovenantGroupInsertProps> = ({ show, onClose, itemEdit, setItemEdit }) => {
  const [selectedClosure, setSelectedClosure] = useState<any>();

  const { handleList, handleSave, isLoading } = useMasterCovenantGroup();
  const { onInvalid } = useAuth();
  const { handleSubmit, register, setValue, reset, control, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });
  const animatedComponents = makeAnimated();

  useEffect(() => {
    if (show) {
      if (itemEdit) {
        setValue('name', itemEdit?.name ?? '');
        setSelectedClosure(yesNoOption?.find((x: any) => x.value === itemEdit?.closure));
      }
    } else {
      setValue('name', '');
      setSelectedClosure(undefined);
      setItemEdit && setItemEdit(undefined);
      reset();
    }
  }, [show]);

  useEffect(() => {
    setValue('closure', selectedClosure);
  }, [selectedClosure]);

  const onSubmit = async (data: any) => {
    const _data = { ...data };

    _data.id = itemEdit?.id;
    _data.name = _data?.name?.trim()?.toUpperCase();
    _data.closure = _data?.closure?.value;
    _data.active = itemEdit?.active ?? 'S';
    _data.blocked = itemEdit?.blocked ?? 'N';
    _data.dataRegister = itemEdit?.dateRegister ?? moment(new Date()).format('YYYY-MM-DD');

    const response = await handleSave(_data).then();
    if (response) {
      toast.success('Salvo com sucesso!');
      handleList().then();
      onClose();
    }
  }

  return (
    <ModalDefault
      title={`${itemEdit ? 'Editar Grupo Master de Convênio ' + itemEdit?.name : 'Novo Grupo Master de Convênio'}`}
      show={show}
      onClose={onClose}
      sizeModal={'lg'}
      showFooter={true}
      buttonText={isLoading ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoading}
      disabledSubmit={isLoading}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <div className="ModalMasterCovenantGroupInsert" data-testid="ModalMasterCovenantGroupInsert">
        <Row>
          <Col className='form-group' xs={12} md={6}>
            <label>Nome</label>
            <input
              className={`form-control ${onInvalid(errors?.name)}`}
              type='text'
              minLength={3}
              maxLength={150}
              {...register('name')}
            />
            <ErrorMessage name='Nome' type={errors?.name?.type} min={5} max={150} />
          </Col>
          <Col className='form-group' xs={12} md={6}>
            <label>Fechamento</label>
            <Controller
              control={control}
              name='closure'
              render={({ field: { name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isSearchable
                  placeholder="Selecione..."
                  options={yesNoOption}
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                  defaultValue={selectedClosure}
                  value={selectedClosure}
                  onChange={(val: any) => {
                    setSelectedClosure(val);
                  }}
                />
              )}
            />
            <ErrorMessage name='Fechamento' type={errors?.closure?.type} />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Card>
              <Card.Header className='p-3 pb-0'>
                <b>Grupos</b>
                <hr className='my-2' />
              </Card.Header>
              <Card.Body className='p-2'>
                <div className='table-responsive'>
                  <table className='table table-striped'>
                    <thead>
                      <tr>
                        <th className="text-uppercase opacity-7 text-wrap" scope="col">Nome</th>
                        <th className="text-uppercase opacity-7 text-center" scope="col">Bloqueado</th>
                        <th className="text-uppercase opacity-7 text-center" scope="col">Fechamento</th>
                        <th className="text-uppercase opacity-7 text-center" scope="col">Situação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(itemEdit?.groups && itemEdit?.groups?.length > 0) ?
                        itemEdit?.groups?.map((x: CovenantGroupModel) => (
                          <tr key={x.id} className='text-sm'>
                            <td>{x?.name}</td>
                            <td className='text-center'>
                              <span className={`badge rounded-pill ${x?.blocked === 'S' ? 'bg-success' : 'bg-danger'}`}>
                                {x?.blocked === 'S' ? 'Sim' : 'Não'}
                              </span>
                            </td>
                            <td className='text-center'>
                              <span className={`badge rounded-pill ${x?.closure === 'S' ? 'bg-success' : 'bg-danger'}`}>
                                {x?.closure === 'S' ? 'Sim' : 'Não'}
                              </span>
                            </td>
                            <td className='text-center'>
                              <span className={`badge ${x?.active === 'S' ? 'bg-primary' : 'bg-secondary'}`}>
                                {x?.active === 'S' ? 'Ativo' : 'Inativo'}
                              </span>
                            </td>
                          </tr>
                        )) :
                        <tr>
                          <td colSpan={5} className='text-center'>Não existem grupos!</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  )
};

export default ModalMasterCovenantGroupInsert;
