import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './ModalMonthlyGoalPeriodConfigurationInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown, faCirclePlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import TreeView from '../../../TreeView/TreeView';
import { TreeViewModel } from '../../../../models/TreeViewModel';
import { Card, Col, FormGroup, Row } from 'react-bootstrap';
import moment from 'moment';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { useMonthlyGoalCondition } from '../../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalConditionProvider';
import { MonthlyGoalModel } from '../../../../models/PanelConnects/MonthlyGoalModel';
import { useMonthlyGoalPeriodConfiguration } from '../../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalPeriodConfigurationProvider';
import { MonthlyGoalPeriodConfigListModel } from '../../../../models/PanelConnects/MonthlyGoalPeriodConfigListModel';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import TooltipItem from '../../../TooltipItem/TooltipItem';
import ModalMonthlyGoalCategoryInsert from '../../MonthlyGoalCategory/ModalMonthlyGoalCategoryInsert/ModalMonthlyGoalCategoryInsert';
import { monthlyGoalAccessProfileOptions } from '../../../../models/PanelConnects/MonthlyGoalAccessProfileOptions';

interface ModalMonthlyGoalPeriodConfigurationInsertProps {
  show: boolean,
  onClose: any,
  periodEdit?: MonthlyGoalPeriodConfigListModel,
  setPeriodEdit?: Dispatch<SetStateAction<MonthlyGoalPeriodConfigListModel | undefined>>
}

const schema = yup.object().shape({
  period: yup
    .object()
    .required(),
  accessProfileId: yup
    .object()
    .required()
});

const ModalMonthlyGoalPeriodConfigurationInsert: FC<ModalMonthlyGoalPeriodConfigurationInsertProps> = (
  {
    show,
    onClose,
    periodEdit,
    setPeriodEdit
  }) => {
  const [selectedPeriod, setSelectedPeriod] = useState<any>();
  const [selectedAccessProfileId, setSelectedAccessProfileId] = useState<any>();
  const [periodOptions, setPeriodOptions] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [removedNode, setRemovedNode] = useState<TreeViewModel>();
  const [availableCategories, setAvailableCategories] = useState<any[]>([]);
  const [availableGoals, setAvailableGoals] = useState<MonthlyGoalModel[]>([]);
  const [canEdit, setCanEdit] = useState<boolean>(true);
  const [addedNewCategory, setAddedNewCategory] = useState<boolean>(false);
  const [showAddCategory, setShowAddCategory] = useState<boolean>(false);

  const { handleSubmit, setValue, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });
  const { handleCategory, categoriesOptions, isLoading: isLoadingCategory, setCategoryParams } = useMonthlyGoalCondition();
  const {
    handleList,
    listGoals,
    handleSave,
    goals,
    isLoadingGoals,
    setGoals,
    getPeriod,
    error,
    setError
  } = useMonthlyGoalPeriodConfiguration();

  useEffect(() => {
    setCategoryParams({ withPaginate: false });
  }, []);

  useEffect(() => {
    if (!show) {
      setRemovedNode(undefined);
      setSelectedPeriod(undefined);
      setSelectedAccessProfileId(null);
      setPeriodOptions([]);
      setData([]);
    } else {
      handleCategory();
      getPeriodOptions();
    }
  }, [show]);

  useEffect(() => {
    if (periodEdit) {
      const _periodOption = periodOptions?.find((x: any) => x.value === moment(periodEdit?.closingDate, 'YYYY-MM-DD').format('YYYY-MM'));
      if (!_periodOption) {
        setPeriodOptions((current) => [...current, {
          label: moment(periodEdit?.closingDate, 'YYYY-MM-DD').format('MMMM/YY')?.toUpperCase(),
          value: moment(periodEdit?.closingDate, 'YYYY-MM-DD').format('YYYY-MM')
        }]);
      }

      if (!selectedPeriod) {
        setSelectedPeriod({
          label: moment(periodEdit?.closingDate, 'YYYY-MM-DD').format('MMMM/YY')?.toUpperCase(),
          value: moment(periodEdit?.closingDate, 'YYYY-MM-DD').format('YYYY-MM')
        });
      }

      setSelectedAccessProfileId(monthlyGoalAccessProfileOptions?.find((x: any) => x.value === periodEdit?.accessProfile?.id));

      setData(periodEdit?.menu ?? []);
      setCanEdit(moment(new Date())?.startOf('month')?.subtract(1, 'M').format('YYYY-MM-DD') <= (periodEdit?.closingDate ?? ''));
    }
  }, [periodEdit]);

  useEffect(() => {
    setAvailableCategories(categoriesOptions);
  }, [categoriesOptions]);

  useEffect(() => {
    setAvailableGoals(goals);
  }, [goals]);

  useEffect(() => {
    getAvailableOptions();
  }, [data, availableGoals, availableCategories]);

  useEffect(() => {
    if (removedNode) {
      onRemoveNode();
    }
  }, [removedNode]);

  useEffect(() => {
    if (addedNewCategory) {
      handleCategory();
      setAddedNewCategory(false);
    }
  }, [addedNewCategory]);

  useEffect(() => {
    setValue('period', selectedPeriod);
    setValue('accessProfileId', selectedAccessProfileId);
    if (selectedPeriod && selectedAccessProfileId) {
      if (selectedPeriod?.manual) {
        setData([]);
        handleCategory();
        getPeriod(moment(selectedPeriod?.value, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'), selectedAccessProfileId?.value).then((result) => {
          if (setPeriodEdit) {
            setPeriodEdit(result);
            if (moment(new Date())?.startOf('month')?.format('YYYY-MM-DD') <= (result?.closingDate ?? '')) {
              Swal.fire({
                title: 'Atenção!',
                html: 'Esse período já possui uma configuração. Ela foi carregada para ser editada!',
                icon: 'info',
                confirmButtonText: 'OK'
              });
            }
          }
        });
      }
      listGoals(moment(selectedPeriod?.value, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'), selectedAccessProfileId?.value);
    } else {
      setGoals([]);
    }
  }, [selectedPeriod, selectedAccessProfileId]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError('');
    }
  }, [error]);

  const getPeriodOptions = () => {
    Array.from(Array(5)).forEach((x, i) => {
      periodOptions?.push({
        label: moment(new Date()).add(i - 1, 'M').format('MMMM/YY')?.toUpperCase(),
        value: moment(new Date()).add(i - 1, 'M').format('YYYY-MM')
      });
    });
  }

  const addNewItem = (item: any, type: 'category' | 'goal') => {
    const _item = type === 'category' ?
      {
        id: item?.value?.toString(),
        name: item?.label,
        children: []
      } :
      {
        id: 'm' + item?.id,
        name: item?.description,
        score: item?.score
      }
    setData([...data, _item]);
  }

  const addAvailableOption = (node: TreeViewModel, categoriesAdd: any[], goalsAdd: any[]) => {
    if (node?.children) {
      categoriesAdd?.push({
        value: Number(node?.id),
        label: node.name
      });

      node?.children?.forEach((child: TreeViewModel) => {
        addAvailableOption(child, categoriesAdd, goalsAdd);
      })
    } else {
      goalsAdd?.push({
        id: Number(node?.id?.replace('m', '')),
        description: node?.name,
        score: node?.score
      })
    }
  }

  const onRemoveNode = () => {
    const categoriesToAdd: any[] = [];
    const goalsToAdd: any[] = [];
    addAvailableOption(removedNode ?? {}, categoriesToAdd, goalsToAdd);

    if (categoriesToAdd?.length > 0) {
      setAvailableCategories((current) =>
        [...current, ...categoriesToAdd]
          ?.sort((a: any, b: any) => a.label < b.label ? -1 : 1));
    }

    if (goalsToAdd?.length > 0) {
      setAvailableGoals((current) =>
        [...current, ...goalsToAdd]
          ?.sort((a: any, b: any) => a.description < b.description ? -1 : 1));
    }
  }

  const removeAvailableOption = (node: TreeViewModel, id: string, idsToRemove: string[]) => {
    if (node?.id === id) {
      idsToRemove?.push(id);
    }

    if (node?.children) {
      node?.children?.forEach((childNode: TreeViewModel) => {
        removeAvailableOption(childNode, id, idsToRemove);
      });
    }
  }

  const getAvailableOptions = () => {
    const categoriesIdsToRemove: string[] = [];
    const goalsIdsToRemove: string[] = [];
    data?.forEach((node: TreeViewModel) => {
      availableCategories?.forEach((categorie) => {
        removeAvailableOption(node, categorie?.value?.toString(), categoriesIdsToRemove);
      });

      availableGoals?.forEach((goal: MonthlyGoalModel) => {
        removeAvailableOption(node, `m${goal?.id}`, goalsIdsToRemove);
      });
    });

    if (categoriesIdsToRemove?.length > 0) {
      setAvailableCategories((current) => current?.filter((x: any) => !categoriesIdsToRemove?.includes(x?.value?.toString())));
    }

    if (goalsIdsToRemove?.length > 0) {
      setAvailableGoals((current) => current?.filter((x: any) => !goalsIdsToRemove?.includes('m' + x?.id)));
    }
  }

  const onSubmit = async (formData: any) => {
    if (data?.length > 0) {
      const _data: any = {};
      _data.closingDate = moment(formData.period?.value, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
      _data.accessProfileId = formData.accessProfileId?.value;
      _data.menu = data;

      const result = await handleSave(_data);

      if (result) {
        toast.success('Configuração salva com sucesso!');
        handleList();
        onClose();
      }

    } else {
      toast.warning('É necessário inserir itens no período para salvar a configuração.');
    }
  }

  return (
    <ModalDefault
      title={'Configuração de Período'}
      show={show}
      onClose={onClose}
      sizeModal={'xl'}
      showFooter={true}
      buttonText='Salvar'
      handleSubmit={canEdit ? handleSubmit(onSubmit) : undefined}
      backdrop="static"
      backgroundColor="#f8f9fa"
      disableEsc={true}
    >
      <div className="ModalMonthlyGoalPeriodConfigurationInsert" data-testid="ModalMonthlyGoalPeriodConfigurationInsert">
        <Row>
          <Col md={4}>
            <FormGroup className='form-group'>
              <label>PERÍODO *</label>
              <ReactSelect
                name='period'
                value={selectedPeriod}
                isSearchable
                isClearable={false}
                options={periodOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedPeriod({ ...val, manual: true });
                }}
                styles={customStyles}
                isDisabled={!!periodEdit?.id && !selectedPeriod?.manual}
              />
              <ErrorMessage name='Período' type={errors?.period?.type} />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className="form-group">
              <label>TIPO COMERCIAL *</label>
              <ReactSelect
                name='accessProfileId'
                value={selectedAccessProfileId}
                isClearable={false}
                options={monthlyGoalAccessProfileOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedAccessProfileId(val);
                }}
                isDisabled={!!periodEdit?.id && !selectedPeriod?.manual}
                styles={customStyles}
              />
              <ErrorMessage name='Tipo Comercial' type={errors?.accessProfileId?.type} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Card>
              <Card.Header>
                Itens do Período
              </Card.Header>
              <Card.Body>
                {data?.length > 0 ?
                  <TreeView
                    data={data}
                    setData={setData}
                    width={'100%'}
                    height={500}
                    folderIcon={<FontAwesomeIcon icon={faArrowCircleDown} />}
                    setRemovedNode={setRemovedNode}
                    disableDragDrop={!canEdit}
                    showDeleteButton={canEdit}
                  />
                  :
                  <div className='d-flex h-75 flex-column justify-content-center align-items-center' style={{ minHeight: '500px' }}>
                    <span>
                      Selecione o <b>Período, Tipo Comercial</b> e depois
                    </span>
                    <span>
                      as <b>Categorias</b> e <b>Metas</b> ao lado para
                    </span>
                    <span>
                      realizar a configuração do período.
                    </span>
                  </div>
                }
              </Card.Body>
            </Card>
          </Col>

          <Col md={6}>
            <Row className='h-100 gap-2'>
              <Col md={12}>
                <Card className='h-100'>
                  <Card.Header className='d-flex justify-content-between'>
                    <span>
                      Categorias
                    </span>
                    <TooltipItem position='top' text='Nova Categoria'>
                      <FontAwesomeIcon
                        icon={faCirclePlus}
                        className='cursor-pointer'
                        onClick={() => setShowAddCategory(true)}
                      />
                    </TooltipItem>
                  </Card.Header>
                  <Card.Body>
                    {!isLoadingCategory ?
                      <div className='d-flex flex-wrap gap-1'>
                        {availableCategories?.map((category: any, index: number) => (
                          <span
                            key={index}
                            className={`badge bg-gradient-danger ${canEdit && 'cursor-pointer'}`}
                            onClick={() => (canEdit && selectedPeriod && selectedAccessProfileId) ? addNewItem(category, 'category') : undefined}
                          >
                            {category?.label}
                          </span>
                        ))}
                      </div>
                      :
                      <div className='d-flex h-100 flex-column justify-content-center align-items-center'>
                        <FontAwesomeIcon icon={faSpinner} spinPulse />
                      </div>
                    }
                  </Card.Body>
                </Card>
              </Col>
              <Col md={12}>
                <Card className='h-100'>
                  <Card.Header>Metas do Período</Card.Header>
                  <Card.Body>
                    {!isLoadingGoals ?
                      <>
                        {availableGoals?.length > 0 ?
                          <div className='d-flex flex-wrap gap-1'>
                            {availableGoals?.map((goal: any, index: number) => (
                              <span
                                key={index}
                                className={`badge bg-gradient-info ${canEdit && 'cursor-pointer'}`}
                                onClick={() => canEdit ? addNewItem(goal, 'goal') : undefined}
                              >
                                {goal?.description}
                              </span>
                            ))}
                          </div>
                          :
                          <div className='d-flex h-75 flex-column justify-content-center align-items-center'>
                            {(!selectedPeriod || !selectedAccessProfileId) ?
                              <>
                                <span>
                                  Selecione um Período e Tipo para
                                </span>
                                <span>
                                  carregar as metas.
                                </span>
                              </>
                              :
                              <>
                                <span>
                                  Não existem metas configuradas para
                                </span>
                                <span>
                                  o período informado.
                                </span>
                              </>
                            }
                          </div>
                        }
                      </>
                      :
                      <div className='d-flex h-100 flex-column justify-content-center align-items-center'>
                        <FontAwesomeIcon icon={faSpinner} spinPulse />
                      </div>
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <ModalMonthlyGoalCategoryInsert
          show={showAddCategory}
          onClose={setShowAddCategory}
          setWasAdded={setAddedNewCategory}
        />
      </div>
    </ModalDefault>
  )
};

export default ModalMonthlyGoalPeriodConfigurationInsert;
