import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { CyberIncidentProvider } from '../../providers/Security/CyberIncidentProvider';


interface Props {
    element?: any;
    path?: string;
}

export const SecurityRoute: FC<Props> = () => {
    return (
        <CyberIncidentProvider>
            <Outlet />
        </CyberIncidentProvider>

    );
}
