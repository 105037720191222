import React, { Dispatch, FC, useEffect, useState } from 'react';
import './ModalChangeOpportunitiesPartner.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { OpportunityModel } from '../../../../models/Marketplace/Opportunity/OpportunityModel';
import InputSearchPartner from '../../../Registrations/Partner/InputSearchPartner/InputSearchPartner';
import { useForm } from 'react-hook-form';
import { useOpportunity } from '../../../../providers/Marketplace/Opportunities/OpportunityProvider';
import TooltipItem from '../../../TooltipItem/TooltipItem';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

interface ModalChangeOpportunitiesPartnerProps {
  show: boolean;
  onClose: any;
  opportunities: OpportunityModel[];
  setOpportunities: Dispatch<any>;
}

const ModalChangeOpportunitiesPartner: FC<ModalChangeOpportunitiesPartnerProps> = ({ show, onClose, opportunities, setOpportunities }) => {
  const [partnerValue, setPartnerValue] = useState<any>();
  const [partnerObj, setPartnerObj] = useState<any>();

  const { handleChangePartner, handleGetOpportunities, params, isLoading, error, setError } = useOpportunity();
  const { control, setValue } = useForm();

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError('');
    }
  }, [error]);

  const handleRemove = (item: OpportunityModel) => {
    Swal.fire({
      icon: 'question',
      title: 'Atenção!',
      html: `Deseja realmente remover a oportunidade <b>#${item.opportunity_id}</b> da lista de alteração?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((value) => {
      if (value.isConfirmed) {
        setOpportunities((current: OpportunityModel[]) => current?.filter((x: OpportunityModel) => x.opportunity_id !== item.opportunity_id));
      }
    });
  }

  const handleClear = () => {
    Swal.fire({
      icon: 'question',
      title: 'Atenção!',
      html: `Deseja realmente limpar a lista de alteração?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((value) => {
      if (value.isConfirmed) {
        setOpportunities([]);
        toast.success('A lista foi limpa!');
        handleOnClose();
      }
    });
  }

  const handleOnClose = () => {
    setPartnerObj(undefined);
    setPartnerValue('');
    onClose();
  }

  const onSubmit = async () => {
    Swal.fire({
      icon: 'question',
      title: 'Atenção!',
      html: `Deseja realmente alterar o agente das oportunidades?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then(async (value) => {
      if (value.isConfirmed) {
        const result = await handleChangePartner(
          {
            agent_id: partnerObj?.partnerId,
            opportunity_id_list: opportunities?.map((item: OpportunityModel) => item.opportunity_id ?? 0)
          });

        if (result) {
          toast.success('Oportunidades alteradas com sucesso!');
          handleGetOpportunities(params?.page, params);
          setOpportunities([]);
          handleOnClose();
        }
      }
    });
  }

  return (
    <ModalDefault
      title={'Alterar Parceiro das Oportunidades'}
      sizeModal='lg'
      show={show}
      onClose={handleOnClose}
      handleSubmit={onSubmit}
      buttonText='Alterar Agente'
      disabledSubmit={!partnerObj || opportunities?.length === 0}
      showButtonSpinner={isLoading}
    >
      <div className="ModalChangeOpportunitiesPartner" data-testid="ModalChangeOpportunitiesPartner">
        <InputSearchPartner
          name="partner"
          control={control}
          setValue={setValue}
          valuePartner={partnerValue}
          setValuePartner={setPartnerValue}
          setPartnerObj={setPartnerObj}
        />

        <div className='table-responsive mt-3'>
          <table className='table'>
            <thead>
              <tr>
                <th className='text-uppercase opacity-7'>Oportunidade</th>
                <th className='text-uppercase opacity-7'>Agente Atual</th>
                <th className='text-uppercase opacity-7 text-center'>#</th>
              </tr>
            </thead>
            <tbody>
              {opportunities?.length > 0 ?
                <>
                  {opportunities?.map((item: OpportunityModel) => (
                    <tr key={item.opportunity_id ?? 0}>
                      <td>{item.opportunity_id}</td>
                      <td>{item.opportunity_agent}</td>
                      <td className='text-center'>
                        <TooltipItem position='top' text='Remover oportunidade da lista de alteração'>
                          <i
                            className='fa fa-trash cursor-pointer'
                            onClick={() => handleRemove(item)}
                          />
                        </TooltipItem>
                      </td>
                    </tr>
                  ))}
                </>
                :
                <tr>
                  <td colSpan={3} className='text-center pt-4'>
                    Não existem oportunidades selecionadas!
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>

        <div className='text-end'>
          <button
            className='btn btn-secondary text-white mb-0 mt-3'
            onClick={handleClear}
          >
            Limpar Lista
          </button>
        </div>
      </div>
    </ModalDefault>
  )
};

export default ModalChangeOpportunitiesPartner;
