import React, { FC } from "react";
import "./SidebarMenu.scss";
import {
  Col,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { useBiDashboard } from "../../../../providers/BI/Dashboard/BiDashboardProvider";
import { BiReportGroupModel } from "../../../../models/BI/Dashboard/BiReportGroupModel";
import { BiReportModel } from "../../../../models/BI/Dashboard/BiReportModel";

interface SidebarMenuProps {
  show: boolean;
  onClose: any;
}

const SidebarMenu: FC<SidebarMenuProps> = ({ show, onClose }) => {
  const { reportGroups, tabs, setTabs, biComponents, isLoading } =
    useBiDashboard();

  const handleClick = (report: BiReportModel) => {
    setTabs((current: any) => [
      ...current,
      {
        title: report.name,
        content: biComponents[report.componentName ?? ""],
        name: report.componentName,
      },
    ]);
    onClose();
  };

  return (
    <div className="SidebarMenu" data-testid="SidebarMenu">
      <Offcanvas
        show={show}
        placement="start"
        onHide={onClose}
        className="SidebarMenu"
      >
        <OffcanvasHeader
          closeButton
          className="SidebarMenu text-2xl text-primary"
        >
          <FontAwesomeIcon icon={faChartBar} />
          <div className="ms-2">B.I.</div>
        </OffcanvasHeader>
        <OffcanvasBody className="SidebarMenu">
          <hr className="mt-0" />
          {reportGroups?.length > 0 ? (
            <Row className="d-flex gap-1">
              {reportGroups?.map((item: BiReportGroupModel) => (
                <>
                  {item && item.report && item.report.length > 0 ? (
                    <Col sm={12} key={item.id ?? 0}>
                      <div className="group-name">{item?.name}</div>
                      {item?.report?.map((report: BiReportModel) => (
                        <div
                          className="report-name text-wrap cursor-pointer"
                          onClick={() => handleClick(report)}
                        >
                          <i className={`fa-solid ${report?.icon}`} />{" "}
                          {report.name}
                        </div>
                      ))}
                      <hr />
                    </Col>
                  ) : null}
                </>
              ))}
            </Row>
          ) : (
            <div className="d-flex justify-content-center align-items-center h-100 text-bold">
              Não existem relatórios para listar!
            </div>
          )}
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};

export default SidebarMenu;
