import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { AccessProfileService } from "../../../services/Registrations/Access/AccessProfileService";
import { Generics } from "../../../models/Generics";
import { AccessActionModel } from "../../../models/Registrations/Access/AccessActionModel";
import { useLocation } from "react-router-dom";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { AccessProfileModel } from "../../../models/Registrations/Access/AccessProfileModel";
import _ from "underscore";
import { useAuth } from "../../AuthProvider";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";

interface ContextProps {
    profiles: AccessProfileModel[],
    profile: AccessProfileModel,
    setProfile: Dispatch<SetStateAction<AccessProfileModel>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleDetails: (profileId: number, type?: string) => any,
    handleList: () => Promise<boolean>,
    handleDelete: (profileId: number) => any,
    handleSave: (payload: AccessProfileModel) => any,
    isLoading: boolean,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pagesPaginate: PagesPaginateModel,
    setPagesPaginate: Dispatch<SetStateAction<PagesPaginateModel>>,
    profilesOptions: any | null,
}

export const AccessProfileContext = createContext<ContextProps>({} as ContextProps);

export const AccessProfileProvider = (props: any) => {
    const [profiles, setProfiles] = useState<AccessProfileModel[]>([]);
    const [profile, setProfile] = useState<AccessProfileModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [params, setParams] = useState<any>({});
    const [pagesPaginate, setPagesPaginate] = useState<PagesPaginateModel>({});
    const [profilesOptions, setProfilesOptions] = useState<any | null>(null);

    const { userLogged } = useAuth();
    const location = useLocation();

    const service = new AccessProfileService();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/registrations/access-level/list') && (params.withPaginate)) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.getAll(params);
            setIsLoading(false);
            if (!!_Error) {
                setError(_Response || _Error);
                return false;
            }

            setError('');

            // Apenas usuários com perfil Admin verão o perfil Admin para manutenção
            if (userLogged?.accessProfile?.id === 1) {
                setProfiles(Generics.toArray<AccessActionModel>(_Response.data));
            } else {
                setProfiles(Generics.toArray<AccessActionModel>(_Response.data.filter((x: AccessProfileModel) => x.id !== 1)));
            }

            setPagesPaginate(_Response.pages);

            return true;
        } catch (err) {
            return false;
        }
    }, [params, location.pathname]);

    const handleDetails = async (profileId: number, type?: string) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.details(profileId, type);
            setIsLoading(false);
            if (!!_Error) {
                return setError(_Response || _Error);
            }

            setError('');
            setProfile(_Response.data);

            return true;

        } catch (err) {
        }
    };

    const handleSave = async (payload: AccessProfileModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.create(payload);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }

            setError('');
            handleList().then();

            return [true, null];

        } catch (err) {
            return [false, null];
        }
    };

    const handleDelete = async (profileId: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(profileId);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }
            setProfiles(
                _.reject(profiles ?? [], (d: any) => d.id === profileId),
            );
            setError('');
            return [true, null];

        } catch (err) {
            return [false, null];
        }
    };

    useEffect(() => {
        setProfilesOptions(
            profiles
                ?.map((item: AccessProfileModel) => {
                    return {
                        label: item.name,
                        value: item.id,
                        externalId: item?.externalId,
                        isLevelDependent: item?.isLevelDependent
                    };
                })
        )
    }, [profiles]);

    return (
        <AccessProfileContext.Provider value={{
            profiles,
            profile,
            setProfile,
            error,
            setError,
            handleDetails,
            handleList,
            handleDelete,
            handleSave,
            isLoading,
            params,
            setParams,
            pagesPaginate,
            setPagesPaginate,
            profilesOptions
        }}>
            {props.children}
        </AccessProfileContext.Provider>
    );
}

export const useAccessProfile = () => useContext(AccessProfileContext);