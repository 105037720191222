import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserCommercialService } from "../../../services/User/UserCommercialService";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";
import { UserCommercialModel } from "../../../models/User/UserCommercialModel";

interface ContextProps {
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    commercials: UserCommercialModel[],
    regionals: UserCommercialModel[],
    executives: UserCommercialModel[],
    commercialsOptions: ReactSelectOptions[],
    regionalsOptions: ReactSelectOptions[],
    executivesOptions: ReactSelectOptions[],
    handleList: () => Promise<any>,
    handleListRegional: (data: any) => void,
    handleListExecutive: () => void,
}

export const CommercialContext = createContext<ContextProps>({} as ContextProps);

interface CommercialProviderProps {
    children: ReactNode;
}

export const CommercialProvider = ({ children }: CommercialProviderProps) => {
    const [commercials, setCommercials] = useState<UserCommercialModel[]>([]);
    const [regionals, setRegionals] = useState<UserCommercialModel[]>([]);
    const [executives, setExecutives] = useState<UserCommercialModel[]>([]);
    const [error, setError] = useState<string>('');
    const [commercialsOptions, setCommercialsOptions] = useState<ReactSelectOptions[]>([]);
    const [regionalsOptions, setRegionalsOptions] = useState<ReactSelectOptions[]>([]);
    const [executivesOptions, setExecutivesOptions] = useState<ReactSelectOptions[]>([]);
    const [params, setParams] = useState<any>({});

    const service = new UserCommercialService();

    const handleList = async () => {
        try {

            let data = { ...params, withPaginate: false };

            const [_Response, _Error] = await service.list(data);

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setCommercials(Array.isArray(_Response.data) ? _Response.data : [_Response.data]);
            return setError('');
        } catch (e) {
            return setError('Não foi possível carregar os comerciais');
        }
    };

    const handleListRegional = async (data: any = {}) => {
        try {

            data = { ...data, withPaginate: false };

            const [_Response, _Error] = await service.listRegional(data);

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setRegionals(Array.isArray(_Response.data) ? _Response.data : [_Response.data]);
            return setError('');
        } catch (e) {
            return setError('Não foi possível carregar os regionais');
        }
    };

    const handleListExecutive = async () => {
        try {
            const [_Response, _Error] = await service.listExecutive({ withPaginate: false });

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setExecutives(Array.isArray(_Response.data) ? _Response.data : [_Response.data]);
            return setError('');
        } catch (e) {
            return setError('Não foi possível carregar os executivos');
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        setCommercialsOptions(
            commercials
                ?.map((item: UserCommercialModel) => {
                    return {
                        label: `${item.name?.toUpperCase()} - ${item.partnerCode}`,
                        value: item.id,
                        partnerId: item.partnerId
                    };
                })
        )
    }, [commercials]);

    useEffect(() => {
        setRegionalsOptions(
            regionals
                ?.map((item: UserCommercialModel) => {
                    return {
                        label: `${item.name?.toUpperCase()} - ${item.partnerCode}`,
                        value: item.id,
                        partnerId: item.partnerId
                    };
                })
        )
    }, [regionals]);

    useEffect(() => {
        setExecutivesOptions(
            executives
                ?.map((item: UserCommercialModel) => {
                    return {
                        label: `${item.name?.toUpperCase()} - ${item.partnerCode}`,
                        value: item.id,
                        partnerId: item.partnerId
                    };
                })
        )
    }, [executives]);


    useEffect(() => {
        handleList().then();
    }, [params]);


    return (
        <CommercialContext.Provider value={{
            params,
            setParams,
            commercials,
            regionals,
            executives,
            commercialsOptions,
            regionalsOptions,
            executivesOptions,
            handleList,
            handleListRegional,
            handleListExecutive,
        }}>
            {children}
        </CommercialContext.Provider>
    );
}

export const useCommercial = () => useContext(CommercialContext);