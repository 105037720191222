import { FC } from "react";
import { Outlet } from "react-router-dom";
import { NotificationCenterProvider } from "../../providers/Registrations/Notification/NotificationCenterProvider";

interface Props {
    element?: any;
    path?: string;
}

export const NotificationRoute: FC<Props> = () => {
    return (
        <NotificationCenterProvider>
            <Outlet />
        </NotificationCenterProvider>
    );
}