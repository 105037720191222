import React, { FC } from 'react';
import { Navbar, Col, Container, Row, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import './NavbarSite.scss';

interface NavbarSiteProps { }

const NavbarSite: FC<NavbarSiteProps> = () => {
  return (
    <Navbar className="NavbarSite" data-testid="NavbarSite">
        <Container>
            <Row className="justify-content-center align-items-center">
                <Col md={4} xs={12} className="text-md-start text-center">
                    <a href="https://www.bevioficial.com.br" className="mb-0" target="_self"> 
                        <FontAwesomeIcon icon={faArrowLeft} /> 
                        <span className="mx-2"> Ir para o site oficial </span>
                    </a>
                </Col>

                <Col md={4} xs={12} className="text-center d-xs-none">
                    <Image src={require('./../../assets/img/logo-bevi.svg').default} alt="Play Store" fluid width={50} />
                </Col>

                <Col md={4} xs={12} className="text-md-end text-center">
                    <p className="mb-0 mt-md-0 mt-1"> <span className="font-weight-700"> 0800 771 9100 </span> (9h às 17h - horário de Brasília) </p>
                </Col>
            </Row>
        </Container>
    </Navbar>
  );
}

export default NavbarSite;
