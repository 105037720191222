import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/domus/pgi/agent/life';
export class VisitsAgentsLifeService extends BaseService {

    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}