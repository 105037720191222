import { FC, useEffect, useState } from 'react';
import './Covenant.scss';
import MasterPage from '../../../../components/MasterPage/MasterPage';
import PageBase from '../../../../components/PageBase/PageBase';
import SkeletonTable from '../../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../../components/AppPagination/AppPagination';
import { useSidebarFilter } from '../../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../../providers/PaginateProvider';
import { toast } from 'react-toastify';
import DropdownMenuTable from '../../../../components/DropdownMenuTable/DropdownMenuTable';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faLock, faLockOpen, faRemove } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import SidebarCovenantFilter from '../../../../components/Registrations/Covenant/SidebarCovenantFilter/SidebarCovenantFilter';
import ModalCovenantInsert from '../../../../components/Registrations/Covenant/ModalCovenantInsert/ModalCovenantInsert';
import { useCovenant } from '../../../../providers/Registrations/Covenant/CovenantProvider';
import { CovenantModel } from '../../../../models/Registrations/Covenant/CovenantModel';
import { RegionOptions } from '../../../../models/RegionOptions';

interface CovenantProps { }

const Covenant: FC<CovenantProps> = () => {
  const [covenantEdit, setCovenantEdit] = useState<CovenantModel>();
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { covenants, handleList, handleSave, error, setError, isLoading, pages, params, setParams } = useCovenant();
  const { handlePaginate } = usePaginate();

  useEffect(() => {
    handleList().then();
  }, [params]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError('');
    }
  }, [error]);

  const handleEdit = (item: CovenantModel) => {
    setCovenantEdit(item);
    setShowModalInsert(true);
  }

  const handleDeleteCovenant = (item: CovenantModel) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja ${item?.active === 'N' ? 'ativar' : 'desativar'} o convênio ${item?.name}?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleSave({ id: item.id, active: item?.active === 'N' ? 'S' : 'N' }).then();
        if (ret) {
          toast.success(`Convênio ${item?.active === 'N' ? 'ativado' : 'desativado'} com sucesso!`);
          handleList().then();
        } else {
          await Swal.fire('Ops!', `Não foi possível realizar a ação.<br>${error}`, 'error');
        }
      }
    });
  }

  const handleBlock = async (item: CovenantModel) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja ${item.blocked === 'S' ? 'DESBLOQUEAR' : 'BLOQUEAR'} o convênio ${item?.name}?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleSave({ id: item.id, blocked: item.blocked === 'S' ? 'N' : 'S' }).then();
        if (ret) {
          toast.success(`Convênio ${item.blocked === 'S' ? 'desbloqueado' : 'bloqueado'} com sucesso!`);
          handleList().then();
        } else {
          await Swal.fire('Ops!', `Não foi realizar a ação.<br>${error}`, 'error');
        }
      }
    });
  }

  const handleBlockProcessing = async (item: CovenantModel) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja ${item.blockedProc === 'S' ? 'DESBLOQUEAR' : 'BLOQUEAR'} o processamento do convênio ${item?.name}?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleSave({ id: item.id, blockedProc: item.blockedProc === 'S' ? 'N' : 'S' }).then();
        if (ret) {
          toast.success(`Processamento do convênio ${item.blockedProc === 'S' ? 'desbloqueado' : 'bloqueado'} com sucesso!`);
          handleList().then();
        } else {
          await Swal.fire('Ops!', `Não foi realizar a ação.<br>${error}`, 'error');
        }
      }
    });
  }

  const renderList = (): JSX.Element => {
    return (
      <>
        {covenants?.length > 0 ? (
          <>
            {covenants.map((x: CovenantModel, key: number) => (
              <tr key={x.id} className="text-sm">
                <td className="text-center">{x.id}</td>
                <td className='text-wrap'>{x?.name}</td>
                <td className='text-wrap'>{x?.proposalGroup?.name}</td>
                <td className='text-center'>
                  <span className={`badge rounded-pill ${x?.typing === 'S' ? 'bg-success' : 'bg-danger'}`}>
                    {x?.typing === 'S' ? 'Sim' : 'Não'}
                  </span>
                </td>
                <td className='text-center'>
                  <span className={`badge rounded-pill ${x?.national === 1 ? 'bg-success' : 'bg-danger'}`}>
                    {x?.national === 1 ? 'Sim' : 'Não'}
                  </span>
                </td>
                <td className='text-center'>{x?.region && RegionOptions?.find((item: any) => item.value === x?.region)?.label}</td>
                <td>
                  <span className={`badge ${x?.active === 'S' ? 'bg-primary' : 'bg-secondary'}`}>
                    {x?.active === 'S' ? 'Ativo' : 'Inativo'}
                  </span>
                </td>
                <td className="text-center">
                  <DropdownMenuTable key={x.id ?? 0}>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleBlock(x)}>
                      <FontAwesomeIcon icon={x.blocked === 'S' ? faLockOpen : faLock} className="me-2" /> {x.blocked === 'S' ? 'Desbloquear' : 'Bloquear'}
                    </Dropdown.Item>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleBlockProcessing(x)}>
                      <FontAwesomeIcon icon={x.blockedProc === 'S' ? faLockOpen : faLock} className="me-2" /> {x.blockedProc === 'S' ? 'Desbloquear' : 'Bloquear'} Processamento
                    </Dropdown.Item>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleEdit(x)}>
                      <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
                    </Dropdown.Item>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleDeleteCovenant(x)}>
                      <FontAwesomeIcon icon={x?.active === 'S' ? faRemove : faCheck} className="me-2" /> {x?.active === 'S' ? 'Desativar' : 'Ativar'}
                    </Dropdown.Item>
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={7} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <>
            <div className="table-responsive" style={{ minHeight: '200px' }}>
              <table className="table table-flush m-0 w-100">
                <thead className="thead-light">
                  <tr>
                    <th className="text-uppercase opacity-7 text-center" scope="col">#</th>
                    <th className="text-uppercase opacity-7" scope="col">Nome</th>
                    <th className="text-uppercase opacity-7" scope="col">Grupo</th>
                    <th className="text-uppercase opacity-7 text-center" scope="col">Digitação</th>
                    <th className="text-uppercase opacity-7 text-center" scope="col">Nacional</th>
                    <th className="text-uppercase opacity-7 text-center" scope="col">Região</th>
                    <th className="text-uppercase opacity-7" scope="col">Situação</th>
                    <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {renderList()}
                </tbody>
              </table>
            </div>

            {covenants?.length > 0 && (
              <div>
                <nav aria-label="...">
                  <AppPagination
                    pages={pages}
                    handlePaginate={handlePaginate}
                    params={params}
                    setParams={setParams}
                    style={{ backgroundColor: '#fff' }}
                  />
                </nav>
              </div>
            )}
          </>
        ) : (
          <SkeletonTable />
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="Covenant"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="Covenant" data-testid="Covenant">
        <PageBase
          title="Listagem de Convênios"
          subtitle="Tela para manutenção dos Convênios."
          handleInsert={() => setShowModalInsert(true)}
          handleFilter={() => showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          error={error}
          content={renderContent()}
          hasFilter
        />

        <ModalCovenantInsert
          show={showModalInsert}
          onClose={() => setShowModalInsert(false)}
          itemEdit={covenantEdit}
          setItemEdit={setCovenantEdit}
        />
      </div>

      <SidebarCovenantFilter />
    </MasterPage>
  );
}

export default Covenant;
