import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { PagesPaginateModel } from "../../../../models/Paginate/PagesPaginate";
import { ReceiptUnknownService } from "../../../../services/Commissioning/Receipt/ReceiptUnknown/ReceiptUnknownService";
import { ToastSettings } from "../../../../utils/ToastSettings";
import { ReceipUnknownModel } from "../../../../models/Commissioning/Receipt/ReceiptUnknown/ReceiptUnknownModel";


interface ContextProps {
    receiptUnknown?: ReceipUnknownModel,
    setReceiptUnknown: Dispatch<SetStateAction<ReceipUnknownModel | undefined>>,
    receiptUnknowns: ReceipUnknownModel[],
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleFetch: (data: ReceipUnknownModel) => Promise<boolean>,
    handleFetchGenerateReceipt: (data: ReceipUnknownModel) => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>,
}

export const ReceiptUnknownContext = createContext<ContextProps>({} as ContextProps);

interface ReceiptUnknownProviderProps {
    children: ReactNode;
}

export const ReceiptUnknownProvider = ({ children }: ReceiptUnknownProviderProps) => {
    const [receiptUnknown, setReceiptUnknown] = useState<ReceipUnknownModel>();
    const [receiptUnknowns, setReceiptUnknowns] = useState<ReceipUnknownModel[]>([]);
    const [params, setParams] = useState<any>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new ReceiptUnknownService();
    const location = useLocation();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/financial/commissioning/board-receipt')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setReceiptUnknowns(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar os recebimentos desconhecidos do sistema.');
        }
    }, [params, location.pathname]);

    const handleFetch = async (data: ReceipUnknownModel) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.fetch(data);
            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setError('Houve um erro ao salvar o recebimento');
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar o recebiemnto');
            return false;
        }
    }

    const handleFetchGenerateReceipt = async (data: ReceipUnknownModel) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.generateReceiptUnknown(data);
            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setError('Houve um erro ao salvar o recebimento');
            return false;
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <ReceiptUnknownContext.Provider value={{
            receiptUnknown,
            setReceiptUnknown,
            receiptUnknowns,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleFetch,
            handleFetchGenerateReceipt,
            handleDelete
        }}>
            {children}
        </ReceiptUnknownContext.Provider>
    );
}

export const useReceiptUnknown = () => useContext(ReceiptUnknownContext);