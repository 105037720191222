import FileSaver from "file-saver";
import api from "../../Api";
import { BaseService } from "../../BaseService";

// const URL = 'api/app/agent/commercial';
const URL_API = 'api/app/visitsPre';


export class IndicatorManagementPreVisitService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL_API}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async fetch(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.put(`${URL_API}/save`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async delete(id: number): Promise<any> {
        try {
            return this.handleResponse(await api.delete(`${URL_API}/delete`, { data: { id } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async download(id: number): Promise<any> {
        try {
            const response = await api.post(`${URL_API}/download`, { id }, { responseType: 'blob' });
            const filename = response.headers['content-disposition'].split('filename=')[1];
            FileSaver.saveAs(response.data, filename, { autoBom: false });
            return [true, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async get(id: number): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL_API}/get/${id}`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async downloadSchedule(params: any): Promise<any[]> {
        try {
            const response = await api.post(`${URL_API}/calendar`, params, { responseType: 'blob' });
            const filename = response.headers['content-disposition'].split('filename=')[1];
            FileSaver.saveAs(response.data, filename, { autoBom: false });
            return [true, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async exportPdf(id: number): Promise<any> {
        try {
            const response = await api.get(`${URL_API}/export/pdf/${id}`, { responseType: 'blob' });
            const filename = response.headers['content-disposition'].split('filename=')[1];
            const blobUrl = URL.createObjectURL(response.data);
            window.open(blobUrl, '_blank');

            return [true, undefined];          
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async export(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL_API}/export`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}