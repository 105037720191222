import * as yup from "yup";

export const schemaModule = yup.object().shape({
    name: yup
        .string()
        .trim()
        .required()
        .min(3)
        .max(100),
    description: yup
        .string()
        .trim()
        .required()
        .min(3)
        .max(150),
});

export const schemaTool = yup.object().shape({
    name: yup
        .string()
        .trim()
        .required()
        .min(3)
        .max(100),
    description: yup
        .string()
        .trim()
        .required()
        .min(3)
        .max(150),
    alias: yup
        .string()
        .trim()
        .required()
        .min(3)
        .max(50),
});