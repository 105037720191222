import { GeneralCommercialProductionBySupervisorModel } from "../../../models/BI/Dashboard/Commercial/GeneralCommercialProductionBySupervisor/GeneralCommercialProductionBySupervisorModel";
import { AnnualProductionModel } from "../../../models/BI/Dashboard/Commercial/ProductionByCommercial/AnnualProductionModel";
import { CommercialChartParticipationModel } from "../../../models/BI/Dashboard/Commercial/ProductionByCommercial/CommercialChartParticipationModel";
import { MonthlyProductionModel } from "../../../models/BI/Dashboard/Commercial/ProductionByCommercial/MonthlyProductionModel";
import { ProductionCommercialBySupervisorDetailModel } from "../../../models/BI/Dashboard/Commercial/ProductionCommercialBySupervisor/ProductionCommercialBySupervisorDetailModel";
import { ProductionCommercialBySupervisorModel } from "../../../models/BI/Dashboard/Commercial/ProductionCommercialBySupervisor/ProductionCommercialBySupervisorModel";
import { ProductionMainFinancialsByCommercialModel } from "../../../models/BI/Dashboard/Commercial/ProductionMainFinancialsByCommercial/ProductionMainFinancialsByCommercialModel";
import { BaseService } from "../../BaseService";

const URL = "api/bi/commercial";

const productionCommercialBySupervisorDetailMock: ProductionCommercialBySupervisorDetailModel =
{
  supervisorName: "MARTYCLEIBER JOSE DE LIMA",
  commercialName: "JOSE FRANCISCO DE FRANCA",
  financial: [
    {
      name: "C6 BANK",
      projection: 21569857.65,
      growthProjection: -4,
      targetProjection: -3,
      periods: [
        {
          month: "2024-04",
          amount: 69785197.73,
          growth: 18.75,
          participation: 6.89,
          hitTarget: 2,
        },
        {
          month: "2024-05",
          amount: 56785197.98,
          growth: 14.45,
          participation: 4.34,
          hitTarget: 4,
        },
        {
          month: "2024-06",
          amount: 15485457.59,
          growth: 23.64,
          participation: 2.43,
          hitTarget: 11,
        },
      ],
    },
    {
      name: "PAN",
      projection: 1111111.65,
      growthProjection: 3,
      targetProjection: 11,
      periods: [
        {
          month: "2024-04",
          amount: 5677775.573,
          growth: -38.75,
          participation: -9.89,
          hitTarget: 6,
        },
        {
          month: "2024-05",
          amount: 3332266.52,
          growth: 21.45,
          participation: -6.34,
          hitTarget: 7,
        },
        {
          month: "2024-06",
          amount: 234523234.59,
          growth: 3.64,
          participation: -2.43,
          hitTarget: 10,
        },
      ],
    },
  ],
  covenant: [
    {
      name: "INSS",
      projection: 21569857.65,
      growthProjection: -4,
      targetProjection: -3,
      periods: [
        {
          month: "2024-04",
          amount: 55555197.73,
          growth: -18.75,
          participation: 8.89,
          hitTarget: 4,
        },
        {
          month: "2024-05",
          amount: 99985197.98,
          growth: 19.45,
          participation: 9.34,
          hitTarget: 11,
        },
        {
          month: "2024-06",
          amount: 33335457.59,
          growth: -4.64,
          participation: -2.43,
          hitTarget: 17,
        },
      ],
    },
    {
      name: "CONVÊNIOS BANCO DO BRASIL",
      projection: 33339857.65,
      growthProjection: -13,
      targetProjection: 22,
      periods: [
        {
          month: "2024-04",
          amount: 55555197.73,
          growth: 77675,
          participation: 16.89,
          hitTarget: -2,
        },
        {
          month: "2024-05",
          amount: 65655197.98,
          growth: 4.45,
          participation: -8.34,
          hitTarget: 4,
        },
        {
          month: "2024-06",
          amount: 33385457.59,
          growth: 3.64,
          participation: 2.43,
          hitTarget: 32,
        },
      ],
    },
  ],
  contractForm: [
    {
      name: "NOVO",
      projection: 11169857.65,
      growthProjection: -4,
      periods: [
        {
          month: "2024-04",
          amount: 21215197.73,
          growth: 8.75,
          participation: -8.89,
          hitTarget: 3,
        },
        {
          month: "2024-05",
          amount: 45455197.98,
          growth: -7.45,
          participation: 6.21,
          hitTarget: 4,
        },
        {
          month: "2024-06",
          amount: 54645457.59,
          growth: 2.64,
          participation: 26.43,
          hitTarget: 71,
        },
      ],
    },
    {
      name: "PORTABILIDADE",
      projection: 88869857.65,
      growthProjection: 3,
      periods: [
        {
          month: "2024-04",
          amount: 7775197.73,
          growth: 8.75,
          participation: 6.89,
          hitTarget: 2,
        },
        {
          month: "2024-05",
          amount: 56785197.98,
          growth: 14.45,
          participation: 4.34,
          hitTarget: 4,
        },
        {
          month: "2024-06",
          amount: 15485457.59,
          growth: 23.64,
          participation: 2.43,
          hitTarget: 11,
        },
      ],
    },
  ],
};

export class BiCommercialService extends BaseService {

  async listProductionCommercialBySupervisorDetail(
    params: any
  ): Promise<any[]> {
    try {
      // return this.handleResponse(await api.post(`${URL}/list`, params));
      return [{ data: productionCommercialBySupervisorDetailMock }, undefined];
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }
}
