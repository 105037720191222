import * as yup from "yup";
import { validateCNPJ } from "validations-br";

export const schema = yup.object().shape({
    externalId: yup
        .number()
        .required(),
    name: yup
        .string()
        .required()
        .min(3)
        .max(100),
    febrabanCode: yup
        .mixed()
        .nullable(),
    cnpj: yup
        .string()
        .required()
        .max(18).test('invalid', 'CNPJ inválido', (value: string | undefined) => validateCNPJ(value ?? '')),
    url: yup
        .string()
        .nullable()
        .max(100),
});