import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { TermsDocumentsProvider } from '../../providers/Terms/TermsDocumentsProvider';
import { TermsTrackingProvider } from '../../providers/Terms/TermsTrackingProvider';
import { CompanyProvider } from '../../providers/Registrations/Company/CompanyProvider';
import { UserDependentProvider } from '../../providers/User/UserDependentProvider';

interface Props {
    element?: any;
    path?: string;
}

export const TermsRoute: FC<Props> = () => {
    return (
        <TermsDocumentsProvider>
            <TermsTrackingProvider>
                <CompanyProvider>
                    <UserDependentProvider>
                        <Outlet />
                    </UserDependentProvider>
                </CompanyProvider>
            </TermsTrackingProvider>
        </TermsDocumentsProvider>
    );
}
