import * as yup from "yup";

export const schema = yup
    .object()
    .shape({
        fields: yup
            .array()
            .of(
                yup.object()
                    .shape({
                        fieldsId: yup
                            .array()
                            .of(yup.number()),
                        tableColumnNameProposalId: yup.number().nullable(),
                    })
            )
    });