import { FC, useState } from 'react';
import './Simulator.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../providers/PaginateProvider';
import { useExample } from '../../../providers/ExampleProvider';
import { toast } from 'react-toastify';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import { Dropdown, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import {Card, Col, Row} from "react-bootstrap";
import Swal from 'sweetalert2';
import SimulatorResult from '../SimulatorResult/SimulatorResult';

interface SimulatorProps { }

const Simulator: FC<SimulatorProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);

  const { setShowSidebarFilter } = useSidebarFilter();
  const { pages, params, setParams, isLoading, error, examples, setExample, handleList, handleDelete } = useExample();
  const { handlePaginate } = usePaginate();

  const handleEdit = (item: any) => {
    setExample(item);
    setShowModalInsert(true);
  }

  const handleDeleteSimulator = (item: any) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja excluir o Simulator ${item?.name}?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleDelete(item.id || 0).then();
        if (ret) {
          toast.success('Simulator inativado com sucesso!');
          handleList();
        } else {
          await Swal.fire('Ops!', `Não foi possível inativar o Simulator.<br>${error}`, 'error');
        }
      }
    });
  }

  const renderList = (): JSX.Element => {
    return (
      <>
        {examples?.length > 0 ? (
          <>
            {examples.map((x: any, key: number) => (
              <tr key={x.id} className="text-sm">
                <td className="text-center">{x.id}</td>
                <td>{x?.column1}</td>
                <td>{x?.column2}</td>
                <td className="text-center">{x?.column3}</td>
                <td className="text-center">
                  <DropdownMenuTable key={key}>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleEdit(x)}>
                      <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
                    </Dropdown.Item>
                    {!x?.deletedAt && (
                      <Dropdown.Item className="border-radius-md py-2" onClick={() => handleDeleteSimulator(x)}>
                        <FontAwesomeIcon icon={faTrash} className="me-2" /> Remover
                      </Dropdown.Item>
                    )}
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={5} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        <div >
                      <Row>
                          <Col md={12}>
                            <Card className="h-100 bg-secondary" >
                                <Card.Body className="h-card">
                                    <Row>
                                      <Col md={4}>
                                        <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                            <img
                                              alt={'Marketplace'}
                                              className="w-75"
                                              src="/assets/img/seu-marketplace-horizontal.svg"
                                            />
                                        </div>
                                      </Col>
                                      <Col md={8}>
                                        <Card className="h-100 bg-white">
                                          <Card.Body className="h-card p-3">
                                            <div className="d-flex flex-column align-items-center justify-content-center  h-100">
                                            <FormGroup className="form-group w-75">
                                              <label htmlFor="cpf">Digite um CPF</label>
                                              <div className="input-group">
                                                <input type="text" placeholder="000.000.000-00" className="form-control" />
                                                <div className="input-group-append">
                                                  <button className={`btn btn-primary text-white m-0`}>
                                                    <FontAwesomeIcon icon={faSearch} className="me-2" />
                                                  </button>
                                                </div>
                                              </div>
                                            </FormGroup>
                                            </div>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                    </Row>
                                    
                                    </Card.Body>
                                </Card>
                          </Col>
                      </Row>
                      
                      <Row className='mt-3'>
                        <SimulatorResult title="FGTS" result={"daycoval"} />
                        <SimulatorResult title="INSS Novo" />
                        <SimulatorResult title="INSS Refin" />
                        <SimulatorResult title="Crédito Pessoal" />
                      </Row>
                  </div>
      </>
    );
  }

  return (
    <MasterPage
      title="Simulator"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="Simulator" data-testid="Simulator">
        <PageBase
          title="Simule um CPF"
          error={error}
          content={renderContent()}
        />
      </div>
    </MasterPage>
  );
}

export default Simulator;
