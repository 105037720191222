import React, { FC } from 'react';
import './DropdownMenuTable.scss';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

interface DropdownMenuTableProps extends React.HTMLAttributes<HTMLDivElement> {
  key: number;
}

const DropdownMenuTable: FC<DropdownMenuTableProps> = ({ key, children }) => {
  return (
    <Dropdown className="DropdownMenuTable" data-testid="DropdownMenuTable" key={key}>
      <Dropdown.Toggle id={`dropdownTable${key}`} className="cursor-pointer" as="div">
        <FontAwesomeIcon icon={faEllipsisV} /> {key}
      </Dropdown.Toggle>
      <Dropdown.Menu className="py-2" aria-labelledby={`dropdownTable${key}`}>
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownMenuTable;
