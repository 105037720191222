import { Dispatch, ReactNode, SetStateAction, createContext, useCallback, useContext, useEffect, useState } from "react";
import { CampaignModel } from "../../../models/Marketplace/Campaign/CampaignModel";
import { CampaignParams } from "../../../models/Marketplace/Campaign/CampaignParams";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { useLocation } from "react-router-dom";
import { CampaignService } from "../../../services/Marketplace/Campaign/CampaignService";
import { toast } from "react-toastify";
import { CampaignSimulationModel } from "../../../models/Marketplace/Campaign/CampaignSimulationModel";
import { CampaignSimulationFilter } from "../../../models/Marketplace/Campaign/CampaignSilmulaitonFilter";
import { dateFormat } from "../../../utils/FormatDate";
import { CampaignCreateModel } from "../../../models/Marketplace/Campaign/CampaignCreateModel";
import { useReportRequest } from "../../Reports/ReportRequest/ReportRequestProvider";
import { CampaignExcelListModel } from "../../../models/Marketplace/Campaign/CampaignExcelListModel";
import { CampaignAutoGenModel } from "../../../models/Marketplace/Campaign/CampaignAutoGenModel";
import { faBedPulse } from "@fortawesome/free-solid-svg-icons";

interface ContextProps {
    campaign: CampaignModel,
    setCampaign: Dispatch<SetStateAction<CampaignModel>>,
    campaignExcelList: CampaignExcelListModel[],
    setCampaignExcelList: Dispatch<SetStateAction<CampaignExcelListModel[]>>,
    campaigns: CampaignModel[],
    setCampaigns: Dispatch<SetStateAction<CampaignModel[]>>,
    campaignsAutoGen: CampaignModel[],
    setCampaignsAutoGen: Dispatch<SetStateAction<CampaignModel[]>>,
    campaignFilter: CampaignParams,
    setCampaignFilter: Dispatch<SetStateAction<CampaignParams>>,
    campaignPages: PagesPaginateModel,
    setCampaignPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    campaignAutoGenPages: PagesPaginateModel,
    setCampaignAutoGenPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    campaignExcelListPages: PagesPaginateModel,
    setCampaignExcelListPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    campaignCreate: CampaignCreateModel,
    setCampaignCreate: Dispatch<SetStateAction<CampaignCreateModel>>,
    filtersCampaign: CampaignSimulationFilter,
    setFiltersCampaign: Dispatch<SetStateAction<CampaignSimulationFilter>>,
    isLoadingFile: boolean,
    simulations: CampaignSimulationModel[],
    setSimulations: Dispatch<SetStateAction<CampaignSimulationModel[]>>,
    simulationFilter: CampaignSimulationFilter,
    setSimulationFilter: Dispatch<SetStateAction<CampaignSimulationFilter>>,
    simulationPages: PagesPaginateModel,
    setSimulationPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    handleLoadFilters: () => Promise<void>,
    handleDownloadModel: () => any,
    handleDownloadPathfile: (filePath: string) => any,
    handleCampaign: () => any,
    handleCampaignAutoGen: () => any,
    handleCampaignImportedList: () => any,
    handleSimulation: any,
    handleCreateCampaign: (payload: CampaignCreateModel) => Promise<any>,
    handleCreateCampaignExcel: (payload: CampaignCreateModel, file: File) => Promise<any>,
    handleDisableCampaign: (id: number) => Promise<any>,
    handleDisableCampaignAutoGen: (id: number) => Promise<any>,
    handleGetFiltersByCampaign: (campaignId: number) => any,

    loading: Loading,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    options: any,
}

export const CampaignContext = createContext<ContextProps>({} as ContextProps);

interface CampaignProviderProps {
    children: ReactNode;
}

export type OptionType = {
    value: string;
    label: string;
};

export interface SelectOptions {
    financials?: OptionType[];
    productGroups?: OptionType[];
    formContracts?: OptionType[];
    commercialManagers?: OptionType[];
    regionalManagers?: OptionType[];
    executiveManagers?: OptionType[];
    directorManagers?: OptionType[];
    sanitizeTypes?: OptionType[];
}

export interface Loading {
    listSimulation: boolean,
    createCampaign: boolean,
    listCampaign: boolean,
    listCampaignAutoGen: boolean,
    listExcel: boolean,
    getFiltersCampaign: boolean,
}

export const CampaignProvider = ({ children }: CampaignProviderProps) => {
    const [campaigns, setCampaigns] = useState<CampaignModel[]>([]);
    const [campaignsAutoGen, setCampaignsAutoGen] = useState<CampaignAutoGenModel[]>([]);
    const [campaignExcelList, setCampaignExcelList] = useState<CampaignExcelListModel[]>([]);
    const [campaign, setCampaign] = useState<CampaignModel>({});
    const [campaignFilter, setCampaignFilter] = useState<CampaignParams>({ page: 1 });
    const [campaignPages, setCampaignPages] = useState<PagesPaginateModel>({});
    const [campaignAutoGenPages, setCampaignAutoGenPages] = useState<PagesPaginateModel>({});
    const [campaignExcelListPages, setCampaignExcelListPages] = useState<PagesPaginateModel>({});
    const [campaignCreate, setCampaignCreate] = useState<CampaignCreateModel>({});
    const { isLoadingFile, setIsLoadingFile } = useReportRequest();
    const [simulations, setSimulations] = useState<CampaignSimulationModel[]>([]);
    const [simulationFilter, setSimulationFilter] = useState<CampaignSimulationFilter>({ page: 1 });
    const [simulationPages, setSimulationPages] = useState<PagesPaginateModel>({});
    const [filtersCampaign, setFiltersCampaign] = useState<CampaignSimulationFilter>({});

    const [loading, setLoading] = useState<Loading>({
        listSimulation: false,
        createCampaign: false,
        listExcel: false,
        listCampaign: false,
        listCampaignAutoGen: false,
        getFiltersCampaign: false,
    });
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");
    const [options, setOptions] = useState<SelectOptions>({});

    const service = new CampaignService();
    const location = useLocation();

    const handleLoadFilters = async () => {
        try {
            const [_Response, _Error] = await service.filters();
            const {
                financials,
                productgroups,
                formcontracts,
                commercialmanagers,
                regionalmanagers,
                executivemanagers,
                directormanagers,
                sanitizetypes,
                message
            } = _Response ?? {};

            if (_Error) {
                return setError("Não foi possível carregar os filtros!");
            }

            setOptions({
                financials: financials?.map((item: any): OptionType => ({ label: item.name, value: item.id })) ?? [],
                productGroups: productgroups?.map((item: any): OptionType => ({
                    label: item.name,
                    value: item.id
                })) ?? [],
                formContracts: formcontracts?.map((item: any): OptionType => ({
                    label: item.name,
                    value: item.id
                })) ?? [],
                commercialManagers: commercialmanagers?.map((item: any): OptionType => ({
                    label: item.name,
                    value: item.id
                })) ?? [],
                regionalManagers: regionalmanagers?.map((item: any): OptionType => ({
                    label: item.name,
                    value: item.id
                })) ?? [],
                executiveManagers: executivemanagers?.map((item: any): OptionType => ({
                    label: item.name,
                    value: item.id
                })) ?? [],
                directorManagers: directormanagers?.map((item: any): OptionType => ({
                    label: item.name,
                    value: item.id
                })) ?? [],
                sanitizeTypes: sanitizetypes?.filter((item: any) => item.sanitize_type_active)
                    ?.map((item: any): OptionType => ({
                        label: item.sanitize_type_name,
                        value: item.sanitize_type_id
                    })) ?? []
            });

            return setError('');
        } catch (e) {
            setError("Não foi possível carregar os filtros!");
        }
    };

    const handleDownloadModel = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.downloadModel();

        if (_Error) {
            setIsLoadingFile(false);
            return false;
        }

        toast.success(_Response?.message || 'Baixando modelo!');
        setIsLoadingFile(false);
    };

    const handleDownloadPathfile = async (filePath: string) => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.handleDownloadPathfile(filePath);

        if (_Error) {
            setIsLoadingFile(false);
            return false;
        }

        toast.success(_Response?.message || 'Baixando Excel!');
        setIsLoadingFile(false);
    };

    const handleCampaign = async (page: number = 1) => {
        if (!location.pathname.includes("/marketplace/campaign/list")) {
            return false;
        }

        try {
            setLoading({ ...loading, listCampaign: true });
            const [_Response, _Error] = await service.list({ ...campaignFilter, page });
            const { message, data, pages } = _Response ?? {};

            if (_Error) {
                setLoading({ ...loading, listCampaign: false });
                return setError(message || _Error);
            }

            if (data) {

                setCampaigns(data);
            }

            if (pages) {

                setCampaignPages(pages);
            }

            setLoading({ ...loading, listCampaign: false });
            return setError('');
        } catch (e) {
            setLoading({ ...loading, listCampaign: false });
            setError('Não foi possível carregar as campanhas');
            return console.warn(e);
        }
    };

    const handleCampaignAutoGen = async (page: number = 1) => {
        if (!location.pathname.includes("/marketplace/campaign/autogen")) {
            return false;
        }

        try {
            setLoading({ ...loading, listCampaignAutoGen: true });
            const [_Response, _Error] = await service.listGen({ page });
            const { message, data, pages } = _Response ?? {};

            if (_Error) {
                setLoading({ ...loading, listCampaignAutoGen: false });
                return setError(message || _Error);
            }

            if (data) {
                setCampaignsAutoGen(data);
            }

            if (pages) {

                setCampaignAutoGenPages(pages);
            }

            setLoading({ ...loading, listCampaignAutoGen: false });
            return setError('');
        } catch (e) {
            setLoading({ ...loading, listCampaignAutoGen: false });
            setError('Não foi possível carregar as campanhas');
            return console.warn(e);
        }
    };

    const handleCampaignImportedList = async (page: number = 1) => {
        if (!location.pathname.includes("/marketplace/campaign/list")) {
            return false;
        }

        try {
            setLoading({ ...loading, listExcel: true });
            const [_Response, _Error] = await service.importedList({ ...campaignFilter, page });
            const { message, data, pages } = _Response ?? {};

            if (_Error) {
                setLoading({ ...loading, listExcel: false });
                return setError(message || _Error);
            }

            if (data) {
                setCampaignExcelList(data);
            }

            if (pages) {
                setCampaignExcelListPages(pages);
            }

            setLoading({ ...loading, listExcel: false });
            return setError('');
        } catch (e) {
            setLoading({ ...loading, listExcel: false });
            setError('Não foi possível carregar as campanhas');
            return console.warn(e);
        }
    };

    const handleDisableCampaign = async (id: number) => {
        try {
            setLoading({ ...loading, listCampaign: true });
            const [_Response, _Error] = await service.disabled([id]);

            if (_Error) {
                setLoading({ ...loading, listCampaign: false });
                return setError(_Error);
            }

            setSuccess(_Response?.message ?? "Campanha desabilitada com sucessso!");

            handleCampaign().then();

            setLoading({ ...loading, listCampaign: false });
            return setError('');
        } catch (e) {
            setLoading({ ...loading, listCampaign: false });
            setError('Não foi possível desabilitar a campanha');
            return console.warn(e);
        }
    }

    const handleDisableCampaignAutoGen = async (id: number) => {
        try {
            setLoading({ ...loading, listCampaignAutoGen: true });
            const [_Response, _Error] = await service.disabledAutoGen([id]);

            if (_Error) {
                setLoading({ ...loading, listCampaignAutoGen: false });
                return setError(_Error);
            }

            setSuccess(_Response?.message ?? "Geração desabilitada com sucessso!");

            handleCampaignAutoGen().then();

            setLoading({ ...loading, listCampaignAutoGen: false });
            return setError('');
        } catch (e) {
            setLoading({ ...loading, listCampaignAutoGen: false });
            setError('Não foi possível desabilitar a campanha');
            return console.warn(e);
        }
    }


    const handleSimulation = async (params: CampaignSimulationFilter) => {
        try {
            setLoading({ ...loading, listSimulation: true });

            const [_Response, _Error] = await service.simulate(params);
            const { message, data, pages } = _Response ?? {};

            if (_Error) {
                setLoading({ ...loading, listSimulation: false });
                return setError(message || _Error);
            }

            if (data) {
                setSimulationFilter(params);
                setSimulations(data);
            }

            if (pages) {

                setSimulationPages(pages);
            }

            setLoading({ ...loading, listSimulation: false });
            return setError('');
        } catch (e) {
            setLoading({ ...loading, listSimulation: false });
            setError('Não foi possível carregar a simulação!');
            return console.warn(e);
        }
    };

    const handleCreateCampaign = async (payload: CampaignCreateModel) => {
        try {
            setLoading({ ...loading, createCampaign: true });

            const [_Response, _Error] = await service.save({ ...payload, ...simulationFilter });
            const { message } = _Response ?? {};

            if (_Error) {
                setLoading({ ...loading, createCampaign: false });
                setError(message || _Error);
                return false;
            }

            setSuccess(_Response?.message ?? "Campanha gerada com sucessso!");

            setLoading({ ...loading, createCampaign: false });
            setError('');
            return true;
        } catch (e) {
            setLoading({ ...loading, createCampaign: false });
            setError('Não foi possível carregar a simulação!');
            console.warn(e);
            return false;
        }
    };

    const handleCreateCampaignExcel = async (payload: CampaignCreateModel, file: File) => {
        try {
            setLoading({ ...loading, createCampaign: true });
            const [_Response, _Error] = await service.saveExcel(payload, file);
            const { message } = _Response ?? {};

            if (_Error) {
                setLoading({ ...loading, createCampaign: false });
                setError(message || _Error);
                return false;
            }

            setSuccess(_Response?.message ?? "Campanha gerada com sucessso!");

            setLoading({ ...loading, createCampaign: false });
            setError('');
            return true;
        } catch (e) {
            setLoading({ ...loading, createCampaign: false });
            setError('Não foi possível Importar!');
            console.warn(e);
            return false;
        }
    };

    const handleGetFiltersByCampaign = async (campaignId: number) => {
        try {
            setLoading({ ...loading, getFiltersCampaign: true });

            const [_Response, _Error] = await service.getFiltersByCampaign(campaignId);
            setLoading({ ...loading, getFiltersCampaign: false });

            if (!!_Error) {
                setError(_Error);
                return;
            }

            setFiltersCampaign(_Response?.data);
            setError('');

            return;

        } catch (error: any) {
            console.warn(error);
            setLoading({ ...loading, getFiltersCampaign: false });
            setFiltersCampaign({});
            setError(error);
            return;
        }
    }


    useEffect(() => {
        error && toast.error(error);
    }, [error]);

    useEffect(() => {
        if (!!success) {
            toast.success(success);
            setSuccess('');
            handleCampaign(1).then();
        }
    }, [success]);

    return (
        <CampaignContext.Provider value={{
            campaign,
            setCampaign,
            campaignExcelList,
            setCampaignExcelList,
            campaigns,
            setCampaigns,
            campaignsAutoGen,
            setCampaignsAutoGen,
            campaignFilter,
            setCampaignFilter,
            campaignPages,
            setCampaignPages,
            campaignAutoGenPages,
            setCampaignAutoGenPages,
            campaignExcelListPages,
            setCampaignExcelListPages,
            campaignCreate,
            setCampaignCreate,
            isLoadingFile,
            simulations,
            setSimulations,
            simulationFilter,
            setSimulationFilter,
            simulationPages,
            setSimulationPages,
            filtersCampaign,
            setFiltersCampaign,
            handleGetFiltersByCampaign,
            error,
            setError,
            handleLoadFilters,
            handleDownloadModel,
            handleDownloadPathfile,
            handleCampaign,
            handleCampaignAutoGen,
            handleCampaignImportedList,
            handleSimulation,
            handleCreateCampaign,
            handleCreateCampaignExcel,
            handleDisableCampaign,
            handleDisableCampaignAutoGen,
            loading, options
        }}>
            {children}
        </CampaignContext.Provider>
    );
}

export const useCampaign = () => useContext(CampaignContext);