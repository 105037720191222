import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/config/notifications'

export class NotificationCenterService extends BaseService {

    async listByLevel(): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/perLevel`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listByUser(id: number): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/perUser/${id}`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async saveLevel(accessProfileId: number, toolId: number): Promise<any[]> {
        try {
            return this.handleResponse(await api.put(`${URL}/accessTools`, { accessProfileId, toolId }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async deleteLevel(id: number): Promise<any[]> {
        try {
            return this.handleResponse(await api.delete(`${URL}/accessTools`, { data: { id } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async saveUser(userId: number, toolId: number): Promise<any[]> {
        try {
            return this.handleResponse(await api.put(`${URL}/userTools`, { userId, toolId }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async deleteUser(id: number): Promise<any[]> {
        try {
            return this.handleResponse(await api.delete(`${URL}/userTools`, { data: { id } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

}