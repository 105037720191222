import { FC } from 'react';
import './VisitsReportsRanking.scss';
import { useVisitsReports } from '../../../../providers/Visits/VisitsReports/VisitsReportsProvider';

interface VisitsReportsRankingProps { }

const VisitsReportsRanking: FC<VisitsReportsRankingProps> = () => {
  const { reportAgents } = useVisitsReports();

  const renderList = () => {
    return (
      <>
        {reportAgents?.length > 0 ? (
          <>
            {reportAgents.map((item: any, key: number) => (
              <tr key={item?.id || key} className="text-sm">
                <td>{key + 1}</td>
                <td className="text-wrap">{item?.name || ''}</td>
                <td className="text-center">{item?.visits}</td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={3} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  return (
    <div className="VisitsReportsRanking" data-testid="VisitsReportsRanking">
      <div className="d-flex justify-content-center gap-3 wrapper">
        <div className="w-100 ">
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7" scope="col">#</th>
                  <th className="text-uppercase opacity-7" scope="col">COMERCIAL</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">VISITAS</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisitsReportsRanking;
