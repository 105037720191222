import { FC, useState } from 'react';
import './ModalCyberIncidentResponseForm.scss';
import ModalDefault from '../../ModalDefault/ModalDefault';
import { Col, Row } from 'react-bootstrap';
import NavPills from '../../NavPills/NavPills';
import { useCyberIncident } from '../../../providers/Security/CyberIncidentProvider';
import IncidentContent from './IncidentContent/IncidentContent';
import ContainmentContent from './ContainmentContent/ContainmentContent';
import CommunicationContent from './CommunicationContent/CommunicationContent';

interface ModalCyberIncidentResponseFormProps {
  show: boolean;
  onClose: () => void;
}

const TabEnum = {
  INCIDENTE: 'incidente',
  CONTECAO: 'contecao',
  COMUNICACAO: 'comunicacao'
}


const ModalCyberIncidentResponseForm: FC<ModalCyberIncidentResponseFormProps> = ({ show, onClose }) => {

  const [currentActiveTab, setCurrentActiveTab] = useState<string>(TabEnum.INCIDENTE);

  const { cyberIncident, setCyberIncident } = useCyberIncident();

  const handleClose = () => {
    setCurrentActiveTab(TabEnum.INCIDENTE);
    setCyberIncident(undefined);
    onClose();
  }

  return (
    <ModalDefault
      title={`Visualizar Resposta Formulário - ${cyberIncident?.incidentStage1?.reportResponsibleName}`}
      show={show}
      onClose={handleClose}
      sizeModal={'lg'}
      showFooter={true}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalCyberIncidentResponseForm" data-testid="ModalCyberIncidentResponseForm">
        <Row>
          <Col md={12}>
            <NavPills mdMenu={12}
              options={[
                {
                  acronym: TabEnum.INCIDENTE,
                  name: 'Incidente',
                  icon: <i className="fa-solid fa-triangle-exclamation"></i>,
                  show: true,
                },
                {
                  acronym: TabEnum.CONTECAO,
                  name: 'Conteção',
                  icon: <i className="fa-solid fa-shield-halved"></i>,
                  show: true,
                },
                {
                  acronym: TabEnum.COMUNICACAO,
                  name: 'Comunicação',
                  icon: <i className="fa-solid fa-comment-dots"></i>,
                  show: true,
                },
              ]}
              current={currentActiveTab}
              setCurrent={setCurrentActiveTab}
            />
          </Col>
          {currentActiveTab === TabEnum.INCIDENTE && <IncidentContent cyberIncident={cyberIncident} />}
          {currentActiveTab === TabEnum.CONTECAO && <ContainmentContent cyberIncident={cyberIncident} />}
          {currentActiveTab === TabEnum.COMUNICACAO && <CommunicationContent cyberIncident={cyberIncident} />}
        </Row>
      </div>
    </ModalDefault>
  );
}

export default ModalCyberIncidentResponseForm;
