import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { VisitsActionsModel } from "../../../models/Visits/VisitsActions/VisitsActionsModel";
import { VisitsActionsParams } from "../../../models/Visits/VisitsActions/VisitsActionsParams";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { VisitsActionsService } from "../../../services/Visits/VisitsActions/VisitsActionsService";
import { toast } from "react-toastify";
import { ToastSettings } from "../../../utils/ToastSettings";

interface ContextProps {
    action: VisitsActionsModel,
    setAction: Dispatch<SetStateAction<VisitsActionsModel>>,
    actions: VisitsActionsModel[],
    params: VisitsActionsParams,
    setParams: Dispatch<SetStateAction<VisitsActionsParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleFetch: (data: VisitsActionsModel) => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>,
}

export const VisitsActionsContext = createContext<ContextProps>({} as ContextProps);

interface VisitsActionsProviderProps {
    children: ReactNode;
}

export const VisitsActionsProvider = ({ children }: VisitsActionsProviderProps) => {
    const [action, setAction] = useState<VisitsActionsModel>({} as VisitsActionsModel);
    const [actions, setActions] = useState<VisitsActionsModel[]>([]);
    const [params, setParams] = useState<VisitsActionsParams>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const service = new VisitsActionsService();

    const handleList = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response?.message || _Error);
            }

            setActions(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar a ação');
        }
    }

    const handleFetch = async (data: VisitsActionsModel) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.fetch(data);
            if (!!_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setError('Houve um erro ao salvar a ação');
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar a ação');
            return false;
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        handleList()
    }, [params]);

    return (
        <VisitsActionsContext.Provider value={{
            action,
            setAction,
            actions,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleFetch,
            handleDelete,
        }}>
            {children}
        </VisitsActionsContext.Provider>
    );
}

export const useVisitsActions = () => useContext(VisitsActionsContext);