import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { MonthlyGoalService } from "../../../services/PanelConnects/MonthlyGoal/MonthlyGoalService";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";
import { MonthlyGoalModel } from "../../../models/PanelConnects/MonthlyGoalModel";
import { MonthlyGoalTypeModel } from "../../../models/PanelConnects/MonthlyGoalTypeModel";
import { MonthlyGoalParams } from "../../../models/PanelConnects/MonthlyGoalParams";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { ToastSettings } from "../../../utils/ToastSettings";
import { MonthlyGoalImportParams } from "../../../models/PanelConnects/MonthlyGoalImportParams";
import { useReportRequest } from "../../Reports/ReportRequest/ReportRequestProvider";
import moment from "moment";
import { MonthlyGoalAccessProfileEnum } from "../../../enums/PanelConnects/MonthlyGoalAccessProfileEnum";

interface ContextProps {
    monthlyGoals: MonthlyGoalModel[],
    monthlyGoal: MonthlyGoalModel | undefined,
    setMonthlyGoal: Dispatch<SetStateAction<MonthlyGoalModel | undefined>>,
    types: MonthlyGoalTypeModel[],
    typesOptions: any[],
    handleList: () => void,
    handleListToClone: (closingDate: string, accessProfileId: MonthlyGoalAccessProfileEnum) => Promise<MonthlyGoalModel[]>,
    handleType: () => void,
    handleDelete: (id: number) => Promise<boolean>,
    isLoading: boolean,
    isLoadingClone: boolean,
    isLoadingImport: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    params: MonthlyGoalParams,
    setParams: Dispatch<SetStateAction<MonthlyGoalParams>>,
    pages: PagesPaginateModel,
    handleSave: (monthlyGoal: MonthlyGoalModel, isCloning?: boolean) => Promise<any>,
    handleImport: (data: MonthlyGoalImportParams, file: File[]) => Promise<boolean>,
    handleImportPDF: (file: File[], closingDate: string, accessProfileId: MonthlyGoalAccessProfileEnum) => Promise<boolean>,
    handleDownloadPdf: (closingDate?: string, accessProfileId?: MonthlyGoalAccessProfileEnum) => void,
}

export const MonthlyGoalContext = createContext<ContextProps>({} as ContextProps);

interface MonthlyGoalProviderProps {
    children: ReactNode;
}

export const MonthlyGoalProvider = ({ children }: MonthlyGoalProviderProps) => {
    const [monthlyGoals, setMonthlyGoals] = useState<MonthlyGoalModel[]>([]);
    const [monthlyGoal, setMonthlyGoal] = useState<MonthlyGoalModel>();
    const [error, setError] = useState<string>('');
    const [types, setTypes] = useState<MonthlyGoalTypeModel[]>([]);
    const [typesOptions, setTypesOptions] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingClone, setIsLoadingClone] = useState<boolean>(false);
    const [isLoadingImport, setIsLoadingImport] = useState<boolean>(false);
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [params, setParams] = useState<MonthlyGoalParams>({
        startDate: moment(new Date()).format('YYYY-MM-01'),
        endDate: moment(new Date()).endOf('month').format('YYYY-MM-DD')
    });
    const { setIsLoadingFile } = useReportRequest();

    const service = new MonthlyGoalService();

    const handleList = useCallback(async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                return setError(_Error);
            }

            setMonthlyGoals(_Response.data);
            setPages(_Response.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            return setError('Não foi possível carregar as metas');
        }
    }, [params]);

    const handleListToClone = async (closingDate: string, accessProfileId: MonthlyGoalAccessProfileEnum) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list({
                startDate: closingDate,
                endDate: closingDate,
                accessProfileId: accessProfileId,
                withPaginate: false
            });
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return [];
            }

            setError('');
            return _Response.data;
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar as metas');
            return [];
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar a meta');
            return false;
        }
    }

    const handleType = useCallback(async () => {
        try {
            const [_Response, _Error] = await service.getType();

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setTypes(_Response.data);
            return setError('');
        } catch (e) {
            return setError('Não foi possível carregar os tipos');
        }
    }, []);

    const handleDownloadPdf = async (closingDate: string = moment(new Date()).startOf('month').format('YYYY-MM-DD'), accessProfileId: MonthlyGoalAccessProfileEnum = MonthlyGoalAccessProfileEnum.COMMERCIAL_ADVISOR) => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.getDownloadPDF(closingDate, accessProfileId);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error, 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Arquivo baixado com sucesso!');
        setIsLoadingFile(false);
        return true;
    };

    const handleSave = async (monthlyGoal: MonthlyGoalModel, isCloning: boolean = false) => {
        try {
            if (!isCloning) { setIsLoading(true); }
            const [_Response, _Error] = await service.save(monthlyGoal);
            if (!isCloning) { setIsLoading(false); }

            if (_Error) {
                if (!isCloning) {
                    setError(_Error);
                    return false
                } else {
                    return _Error;
                }
            }

            if (!isCloning) {
                setError('');
                handleList();
                return true;
            } else {
                return '';
            }

        } catch (e: any) {
            if (!isCloning) {
                setIsLoading(false);
                setError(e);
                return false;
            } else {
                return e;
            }
        }
    };

    const handleImport = async (data: MonthlyGoalImportParams, file: File[]) => {
        try {
            setIsLoadingImport(true);
            const [_Response, _Error, _Code, _Errors] = await service.import(data, file[0]);
            setIsLoadingImport(false);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            ToastSettings('Importação realizada com sucesso!', 'bottom-center', 'success');
            handleList();

            return true;
        } catch (e) {
            setIsLoadingImport(false);
            setError('Houve um erro ao salvar a importação');
            return false;
        }
    }

    const handleImportPDF = async (file: File[], closingDate: string, accessProfileId: MonthlyGoalAccessProfileEnum) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.importPDF(file[0], closingDate, accessProfileId);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');

            ToastSettings('Importação PDF criada com sucesso!', 'bottom-center', 'success');
            handleList();

            return true;
        } catch (e) {
            setError('Houve um erro ao salvar a importação');
            return false;
        }
    }

    useEffect(() => {
        if (error) {
            toast.error(error);
            setError('');
        }
    }, [error]);

    useEffect(() => {
        handleType();
    }, []);

    useEffect(() => {
        setTypesOptions(
            types?.map((item: MonthlyGoalTypeModel) => {
                return {
                    label: `${item.name} - ${item?.description}`,
                    value: item.id,
                    filter: item.filter
                };
            })
        )
    }, [types]);


    return (
        <MonthlyGoalContext.Provider value={useMemo(() => ({
            monthlyGoals,
            monthlyGoal,
            setMonthlyGoal,
            types,
            typesOptions,
            handleList,
            handleListToClone,
            handleType,
            handleDelete,
            isLoading,
            isLoadingClone,
            isLoadingImport,
            error,
            setError,
            params,
            setParams,
            pages,
            handleSave,
            handleImport,
            handleImportPDF,
            handleDownloadPdf
        }), [
            monthlyGoals,
            monthlyGoal,
            setMonthlyGoal,
            types,
            typesOptions,
            handleList,
            handleListToClone,
            handleType,
            handleDelete,
            isLoading,
            isLoadingClone,
            isLoadingImport,
            error,
            setError,
            params,
            setParams,
            pages,
            handleSave,
            handleImport,
            handleImportPDF,
            handleDownloadPdf
        ])}>
            {children}
        </MonthlyGoalContext.Provider>
    );
}

export const useMonthlyGoal = () => useContext(MonthlyGoalContext);