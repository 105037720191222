import React, { FC, useEffect, useState } from 'react';
import './SidebarBoardReceiptFilter.scss';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import moment from 'moment';
import { useReceiptUnknown } from '../../../../providers/Commissioning/Receipt/ReceiptUnknown/ReceiptUnknownProvider';
import { useReceipt } from '../../../../providers/Commissioning/Receipt/ReceiptProvider';

interface SidebarBoardReceiptFilterProps { }

const SidebarBoardReceiptFilter: FC<SidebarBoardReceiptFilterProps> = () => {
  const [startDate, setStartDate] = useState<any>(moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'));
  const [endDate, setEndDate] = useState<any>(moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'));

  const { handleSubmit, setValue } = useForm();
  const { setParams: setParamsUnknown } = useReceiptUnknown();
  const { handleListTotalsFilter } = useReceipt();

  useEffect(() => {
    setValue('startDate', startDate);
  }, [startDate]);

  useEffect(() => {
    setValue('endDate', endDate);
  }, [endDate]);

  const onSubmit = async (data: any) => {
    const _data = { ...data };

    _data.startDate = moment(_data?.startDate).format('YYYY-MM-DD');
    _data.endDate = moment(_data?.endDate).format('YYYY-MM-DD');
    setParamsUnknown(_data);
    handleListTotalsFilter(_data).then();
  }

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarBoardReceiptFilter" data-testid="SidebarBoardReceiptFilter">
        <Row>
          <Col>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              startLabelText={'DATA INICIAL'}
              endLabelText={'DATA FINAL'}
              maxEndDate={moment().add(1, 'year').format('YYYY-MM-DD')}
            />
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col>
            <div className='observation'>
              <b>Obs.:</b> O calendário não será afetado por essa filtragem.
            </div>
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  )
};

export default SidebarBoardReceiptFilter;
