import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserOriginTypeService } from "../../../services/User/UserOriginTypeService";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";
import { UserOriginTypeModel } from "../../../models/User/UserOriginTypeModel";

interface ContextProps {
    originTypes: UserOriginTypeModel[],
    originTypesOptions: ReactSelectOptions[],
    handleList: () => void,
}

export const OriginTypeContext = createContext<ContextProps>({} as ContextProps);

interface OriginTypeProviderProps {
    children: ReactNode;
}

export const OriginTypeProvider = ({ children }: OriginTypeProviderProps) => {
    const [originTypes, setOriginTypes] = useState<UserOriginTypeModel[]>([]);
    const [error, setError] = useState<string>('');
    const [originTypesOptions, setOriginTypesOptions] = useState<ReactSelectOptions[]>([]);

    const service = new UserOriginTypeService();

    const handleList = useCallback(async () => {
        try {
            const [_Response, _Error] = await service.list({ withPaginate: false });

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setOriginTypes(_Response.data);
            return setError('');
        } catch (e) {
            return setError('Não foi possível carregar as origens');
        }
    }, []);

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        setOriginTypesOptions(
            originTypes
                ?.map((item: UserOriginTypeModel) => {
                    return { label: item.name, value: item.id };
                })
        )
    }, [originTypes]);

    return (
        <OriginTypeContext.Provider value={{
            originTypes,
            originTypesOptions,
            handleList,
        }}>
            {children}
        </OriginTypeContext.Provider>
    );
}

export const useOriginType = () => useContext(OriginTypeContext);