import { FC, useState } from 'react';
import './SimulatorResult.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import {Button, Card, Col, Row} from "react-bootstrap";

interface SimulatorResultProps {
  title?: string;
  result?: string
}

const renderList = () => {
  return (
      <>
        <tr className="text-sm">
          <td>Daycoval</td>
          <td>Aprovado</td>
        </tr>

        <tr className="text-sm">
          <td>Pan</td>
          <td>Falhou</td>
        </tr>
      </>
  );
}

const SimulatorResult: FC<SimulatorResultProps> = ({title, result}) => {
  return (
    <Col md={3} data-testid="SimulatorResult">
        <Card className="h-100 bg-white">
          <Card.Header className="pb-0">{title}</Card.Header>
          <Card.Body className="h-card p-0">
          { result ? (
            <div className="table-responsive mt-3">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7" scope="col">Banco</th>
                  <th className="text-uppercase opacity-7" scope="col">Resultado</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
          ) : (
              <SkeletonTable columns={2}/>
          )}
          </Card.Body>
      </Card>
    </Col>
  );
}

export default SimulatorResult;
