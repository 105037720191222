import { FC } from 'react';
import './SidebarVisitsActionsFilter.scss';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import { useForm } from 'react-hook-form';
import { useVisitsActions } from '../../../../providers/Visits/VisitsActions/VisitsActionsProvider';
import { Col, FormGroup, Row } from 'react-bootstrap';
interface SidebarVisitsActionsFilterProps { }

export const defaultValues = {
  name: ''
} as any;

const SidebarVisitsActionsFilter: FC<SidebarVisitsActionsFilterProps> = () => {

  const { handleSubmit, register } = useForm<any>({ defaultValues });

  const { params, setParams } = useVisitsActions();

  const onSubmit = async (data: any) => {
    setParams({ ...params, ...data, ...{ page: 1 } });
  }

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarVisitsActionsFilter" data-testid="SidebarVisitsActionsFilter">
        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="name">NOME</label>
              <input
                className="form-control"
                {...register('name')}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  );
}

export default SidebarVisitsActionsFilter;