import { FC, useCallback, useEffect, useState } from 'react';
import './AccessLevel.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import { useStyle } from '../../../providers/Style/StyleProvider';
import { Col, Row } from 'react-bootstrap';
import { CompanyEnum } from '../../../enums/Company/CompanyEnum';
import AccessLevelList from '../../../components/Registrations/Access/AccessLevelList/AccessLevelList';
import PagesList from '../../../components/Registrations/Access/PagesList/PagesList';
import ApplicationList from '../../../components/Registrations/Access/ApplicationList/ApplicationList';
import NavPills from '../../../components/NavPills/NavPills';
import Spinner from '../../../components/Spinner/Spinner';
import { useAccessPage } from '../../../providers/Registrations/Access/AccessPageProvider';
import { useAccessProfile } from '../../../providers/Registrations/Access/AccessProfileProvider';
import { useAccessAction } from '../../../providers/Registrations/Access/AccessActionProvider';
import { useAccessApplication } from '../../../providers/Registrations/Access/AccessApplicationProvider';
import ModalAccessPageInsert from '../../../components/Registrations/Access/ModalAccessPageInsert/ModalAccessPageInsert';
import ModalAccessProfileInsert from '../../../components/Registrations/Access/ModalAccessProfileInsert/ModalAccessProfileInsert';
import ModalAccessApplicationInsert from '../../../components/Registrations/Access/ModalAccessApplicationInsert/ModalAccessApplicationInsert';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import SidebarAccessLevelApplicationFilter from '../../../components/Registrations/Access/SidebarAccessLevelFilter/SidebarAccessLevelApplicationFilter/SidebarAccessLevelApplicationFilter';
import SidebarAccessLevelFilter from '../../../components/Registrations/Access/SidebarAccessLevelFilter/SidebarAccessLevelFilter/SidebarAccessLevelFilter';
import SidebarAccessLevelPaginasFilter from '../../../components/Registrations/Access/SidebarAccessLevelFilter/SidebarAccessLevelPagesFilter/SidebarAccessLevelPagesFilter';
import { AccesLevelParams } from '../../../models/Registrations/Access/AccesLevelParams';
import { AccessPageParams } from '../../../models/Registrations/Access/AccessPageParams';
import { useAuth } from '../../../providers/AuthProvider';
import { AccessApplicationParams } from '../../../models/Registrations/Access/AccessApplicationParams';

interface AccessLevelProps {
}

const TabEnum = {
  NIVEIS: 'nivel',
  PAGINAS: 'pagina',
  APLICATIVOS: 'aplicativo'
}

export const defaultValuesLevel = {
  name: '',
  startDate: '',
  endDate: '',
} as AccesLevelParams;

export const defaultValuesPage = {
  name: ''
} as AccessPageParams;

export const defaultValuesApplication = {
  name: ''
} as AccessApplicationParams;

const AccessLevel: FC<AccessLevelProps> = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState<string>(TabEnum.NIVEIS);

  const [showModalAddProfile, setShowModalAddProfile] = useState<boolean>(false);
  const [showModalAddPage, setShowModalAddPage] = useState<boolean>(false);
  const [showModalAddApplication, setShowModalAddApplication] = useState<boolean>(false);

  const [typeAccess, setTypeAccess] = useState<string>("desktop");

  const { isLoading: isLoadingAction } = useAccessAction();
  const { isLoading: isLoadingPage, params: paramsPage, setParams: setParamsPage } = useAccessPage();
  const { isLoading: isLoadingApplication, params: paramsApplication, setParams: setParamsApplication } = useAccessApplication();
  const { isLoading: isLoadingProfile, params: paramsProfile, setParams: setParamsProfile } = useAccessProfile();

  const { style } = useStyle();
  const { isDevelopment } = useAuth();

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();

  const [tabTitle, setTabTitle] = useState<string>("Níveis de Acesso");
  const [tabSubtitle, setTabSubtitle] = useState<string>("Controle de níveis de acesso do sistema.");

  const handleShowModalInsert = () => {
    switch (currentActiveTab) {
      case TabEnum.NIVEIS:
        setShowModalAddProfile(true)
        break;

      case TabEnum.PAGINAS:
        setShowModalAddPage(true)
        break;

      case TabEnum.APLICATIVOS:
        setShowModalAddApplication(true)
        break;

      default:
    }
  }

  useEffect(() => {
    switch (currentActiveTab) {
      case TabEnum.NIVEIS:
        setTabTitle("Níveis de Acesso");
        setTabSubtitle("Controle de níveis de acesso do sistema.");
        setParamsPage({ ...paramsPage, ...defaultValuesPage, ...{ page: 1 } });
        break;

      case TabEnum.PAGINAS:
        setTabTitle("Páginas");
        setTabSubtitle("Gerenciamento de páginas do sistema.");
        setParamsProfile({ ...paramsProfile, ...defaultValuesLevel, ...{ page: 1 } });
        break;

      case TabEnum.APLICATIVOS:
        setTabTitle("Aplicativos");
        setTabSubtitle("Gerenciamento de páginas do aplicativo.");
        setParamsApplication({ ...paramsApplication, ...defaultValuesApplication, ...{ page: 1 } });
        break;

      default:
    }
  }, [currentActiveTab])

  const renderContent = (): JSX.Element => {
    return (
      <>
        <Row>
          <Col md={12}>
            {!!style && (
              <NavPills
                options={[
                  {
                    acronym: TabEnum.NIVEIS,
                    name: 'Níveis de Acesso',
                    element: <AccessLevelList setShowEdit={setShowModalAddProfile} setTypeAccess={setTypeAccess} />,
                    icon: <i className="fa-solid fa-unlock"></i>,
                    show: true
                  },
                  {
                    acronym: TabEnum.PAGINAS,
                    name: 'Páginas',
                    element: <PagesList setShowEdit={setShowModalAddPage} />,
                    icon: <i className="fa-solid fa-desktop"></i>,
                    show: style?.id === CompanyEnum.BEVI || isDevelopment
                  },
                  {
                    acronym: TabEnum.APLICATIVOS,
                    name: 'Aplicativos',
                    element: <ApplicationList setShowEdit={setShowModalAddApplication} />,
                    icon: <i className="fa-solid fa-mobile-screen-button"></i>,
                    show: true
                  },
                ]}
                current={currentActiveTab}
                setCurrent={setCurrentActiveTab}
              />
            )}
          </Col>
        </Row>
      </>
    )
  }

  return (
    <MasterPage
      title={'Nível de Acesso'}
      icon={'fas fa-unlock'}
      footerFixed
      footerInverse
    >
      <div className="AccessLevel" data-testid="AccessLevel">
        <PageBase
          title={tabTitle}
          subtitle={tabSubtitle}
          handleInsert={() => handleShowModalInsert()}
          hasFilter
          handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          textButtonAdd="Adicionar"
          textButtonFilter="Filtrar"
          content={
            renderContent()
          }
        />

        {(isLoadingPage || isLoadingProfile || isLoadingAction || isLoadingApplication) && <Spinner />}

        <ModalAccessPageInsert
          show={showModalAddPage}
          onClose={() => {
            setShowModalAddPage(false)
          }}
        />

        <ModalAccessProfileInsert
          show={showModalAddProfile}
          onClose={() => {
            setShowModalAddProfile(false)
          }}
          typeAccess={typeAccess}
        />

        <ModalAccessApplicationInsert
          show={showModalAddApplication}
          onClose={() => {
            setShowModalAddApplication(false)
          }}
        />

        {
          currentActiveTab === TabEnum.PAGINAS ? <SidebarAccessLevelPaginasFilter /> :
            currentActiveTab === TabEnum.APLICATIVOS ? <SidebarAccessLevelApplicationFilter /> : <SidebarAccessLevelFilter />
        }
      </div>
    </MasterPage>
  );
}

export default AccessLevel;
