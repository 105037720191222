import { FC, useEffect, useState } from 'react';
import './ModalProposalConfigInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { useProposalConfig } from '../../../../providers/Proposals/Config/ProposalConfigProvider';
import { ProposalHeaderFields, ProposalTablesColumnsNames } from '../../../../models/Proposals/Config/ProposalHeaderFieldsModel';
import ReactSelect from 'react-select';
import { ReactSelectOptions } from '../../../../models/ReactSelectOptions';
import makeAnimated from 'react-select/animated';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validate';
import AppPagination from '../../../AppPagination/AppPagination';
import { usePaginate } from '../../../../providers/PaginateProvider';
import { useProposalImport } from '../../../../providers/Proposals/Import/ProposalImportProvider';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import SkeletonBadges from '../../../SkeletonBadges/SkeletonBadges';

const defaultValues = {
  fields: []
};

interface ModalProposalConfigInsertProps {
  show: boolean;
  onClose: () => void;
}

const ModalProposalConfigInsert: FC<ModalProposalConfigInsertProps> = ({ show, onClose }) => {
  const [fieldsOptions, setFieldsOptions] = useState<ReactSelectOptions[]>([]);

  const { isLoading, config, headerFinancial, handleFetch, handleListHeaderFinancial } = useProposalConfig();
  const { importData, pagesDetails, paramsDetails, setParamsDetails, isLoadingsDetails } = useProposalImport();
  const { handlePaginateDetails } = usePaginate();

  const animatedComponents = makeAnimated();

  const { register, control, reset } = useForm({
    defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });

  const { fields: fieldsValidation, append, update } = useFieldArray({ name: 'fields', control });

  const handleClose = async () => {
    onClose();
  }

  useEffect(() => {
    headerFinancial?.tablesColumnsNamesProposals?.map((table: ProposalTablesColumnsNames) => {
      let _values = headerFinancial?.headersFields?.filter((field: ProposalHeaderFields) => {
        return field.headersFieldsTablesColumnsNamesProposals?.find((e: any) => {
          return e?.tablesColumnsNamesProposalsId === table.id;
        })
      }) as number[];

      append({
        tableColumnNameProposalId: table?.id,
        fieldsId: _values.map((item: any) => item.id),
      });
    });

    const _fields = headerFinancial?.headersFields?.map((item: ProposalHeaderFields) => { return { label: item?.key, value: item?.id }; });
    setFieldsOptions(_fields ?? []);
  }, [headerFinancial]);

  useEffect(() => {
    if (show) {
      reset();
      config?.financialsId && handleListHeaderFinancial(config?.financialsId);
      config?.id && setParamsDetails((current: any) => { return { ...current, page: 1, perPage: 5, headerId: config?.id } });
    }
  }, [show, config]);

  const handleChange = (
    values: any,
    actions: any,
    key: number,
    tableColumnNameProposalId?: number,
  ) => {
    let _id = 0;
    let _action = 0;

    if (actions?.action === 'select-option') {
      _id = actions?.option?.value;
      _action = 1;
    }

    if (actions?.action === 'remove-value') {
      _id = actions?.removedValue?.value;
      _action = 2;
    }

    if (_action > 0) {
      handleFetch({
        id: _id,
        tableColumnNameProposalId,
        action: _action
      }).then((ret: boolean) => {
        if (!ret) {
          if (actions?.action === 'select-option') {
            update(key, { fieldsId: values.map((item: ReactSelectOptions) => item?.value).filter((val: number) => val !== _id), tableColumnNameProposalId });
          }

          if (actions?.action === 'remove-value') {
            const _values = fieldsOptions?.filter((v: ReactSelectOptions) => v?.value === _id).map((item: ReactSelectOptions) => item?.value);
            update(key, { fieldsId: _values, tableColumnNameProposalId });
          }
        }
      });
    }
  };

  return (
    <ModalDefault
      title={'Configurar Cabeçalho de Produção'}
      show={show}
      onClose={handleClose}
      sizeModal={'xxl'}
      backdrop="static"
    >
      <div className="ModalProposalConfigInsert" data-testid="ModalProposalConfigInsert">
        <Row>
          <Col sm={12} md={12} lg={12} xl={5}>
            <div className="mb-4">
              <h5>Configuração do Cabeçalho</h5>
              {!isLoading ? (
                <>
                  <div className="legend">
                    <div className="square-essential"></div> Campos Essenciais
                  </div>

                  <div className="container-max-height">
                    <table className="table table-striped m-0 w-100">
                      <thead className="thead-light">
                        <tr>
                          <th className="text-uppercase opacity-7" scope="col">Coluna Interna</th>
                          <th className="text-uppercase opacity-7 text-center" scope="col">Coluna Planilha</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fieldsValidation?.map((item: any, key: number) => (
                          <tr className={`${headerFinancial?.tablesColumnsNamesProposals && headerFinancial?.tablesColumnsNamesProposals[key]?.essential ? 'bg-essential' : ''}`} key={item?.id}>
                            <td>{headerFinancial?.tablesColumnsNamesProposals ? headerFinancial?.tablesColumnsNamesProposals[key]?.description : ''}</td>
                            <td>
                              <FormGroup className="form-group mb-0 mx-auto select-width">
                                <div key={item.id}>
                                  <Controller
                                    key={item.id}
                                    control={control}
                                    {...register(`fields.${key}.fieldsId`)}
                                    render={({ field: { onChange, name } }) => (
                                      <ReactSelect
                                        name={name}
                                        isMulti
                                        isSearchable
                                        autoFocus={false}
                                        isClearable={false}
                                        options={fieldsOptions}
                                        placeholder="Selecione..."
                                        className={`form-control p-0`}
                                        defaultValue={fieldsOptions?.filter((v: ReactSelectOptions) => item?.fieldsId?.find((f: number) => f === v?.value)) || ''}
                                        components={animatedComponents}
                                        noOptionsMessage={() => 'Não há registros'}
                                        onChange={(val: any, action: any) => {
                                          onChange(val?.map((v: any) => v?.value) || null);
                                          headerFinancial?.tablesColumnsNamesProposals && handleChange(val, action, key, headerFinancial?.tablesColumnsNamesProposals[key]?.id);
                                        }}
                                        styles={customStyles}
                                      />
                                    )}
                                  />
                                </div>
                              </FormGroup>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div className="container-max-height">
                  <SkeletonTable rows={11} columns={3} />
                </div>
              )}
            </div>
          </Col>
          <Col sm={12} md={12} lg={12} xl={7}>
            <Row>
              <Col md={12} className="mb-4">
                <h5><b>Novos Campos</b> da Planilha</h5>
                {!isLoading ? (
                  <>
                    {headerFinancial?.headersFields && (
                      <div className="badge badge-info w-100 my-3 p-3 text-start d-flex align-items-center">
                        {headerFinancial?.headersFields?.filter((item: ProposalHeaderFields) => item?.headersBindsHeadersFields?.headerId === config?.id)?.map((item: ProposalHeaderFields, key: number) => (
                          <div className="item" key={key}>
                            <div className="bg-item position-relative">
                              <span>
                                {item?.key}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="badge badge-gray w-100 my-3 p-3 text-start d-flex align-items-center">
                    <SkeletonBadges items={75} />
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h5><b>Campos</b> da Planilha</h5>

                {!isLoadingsDetails ? (
                  <div className="tableFixHead">
                    <table className="table table-flush m-0 w-100">
                      {importData?.length > 0 && (
                        <thead className="thead-light">
                          <tr>
                            <>
                              {Object.keys(importData[0]).map((key: string, index: number) => (
                                <th className="text-uppercase opacity-7" scope="col" key={index}>
                                  <>{key}</>
                                </th>
                              ))}
                            </>
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {importData?.map((x: any) => (
                          <tr key={x.id} className="text-sm">
                            <>
                              {Object.keys(x).map((key: string, index: number) => (
                                <td key={index}>{x[key]}</td>
                              ))}
                            </>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="tableFixHead">
                    <SkeletonTable rows={6} />
                  </div>
                )}

                {importData?.length > 0 && (
                  <div>
                    <nav aria-label="...">
                      <AppPagination
                        pages={pagesDetails}
                        handlePaginate={handlePaginateDetails}
                        params={paramsDetails}
                        setParams={setParamsDetails}
                        style={{ backgroundColor: '#fff' }}
                      />
                    </nav>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
}

export default ModalProposalConfigInsert;
