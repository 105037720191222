import { FC } from 'react';

interface ErrorMessageProps {
  name: string;
  type?: string;
  min?: number;
  max?: number;
  isNumber?: boolean;
  isArray?: boolean;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ name, type, min, max, isNumber = false, isArray = false }) => {
  return (
    <div className="ErrorMessage" data-testid="ErrorMessage">
      {type && (
        <>
          {['required', 'typeError', 'optionality', 'nullable'].includes(type) && (<p className="error">{name} é obrigatório</p>)}
          {['min', 'minLength'].includes(type) && (!isArray) && (
            <p className="error">{isNumber ? `Valor mínimo é ${min}` : `Deve conter no mínimo ${min} caracteres`}</p>)}
          {['max', 'maxLength'].includes(type) && (!isArray) && (
            <p className="error">{isNumber ? `Valor máximo é ${max}` : `Deve conter no máximo ${min} caracteres`}</p>)}
          {['invalid', 'validate', 'pattern', 'invalida'].includes(type) && (
            <p className="error">{name} é inválido</p>)}
          {['nomeComposto'].includes(type) && (<p className="error">Informe o {name}</p>)}
          {['phoneEquals'].includes(type) && (<p className="error">Os celulares devem ser iguais</p>)}
          {['passwordEquals'].includes(type) && (<p className="error">As senhas devem ser iguais</p>)}
          {['score'].includes(type) && (
            <p className="error">A {name} não atingiu os requisitos mínimos de segurança, informe uma senha
              mais forte</p>)}
          {['range'].includes(type) && (<p className="error">O valor mínimo é {min} e o máximo é {max}</p>)}
          {isArray && ((min && max) ?
            <p className="error">Deve conter ao menos {min} item(s) e no máximo {max} item(s)</p> :
            min ? <p className="error">Deve conter ao menos {min} item(s)</p> :
              max ? <p className="error">Deve conter no máximo {max} item(s)</p> : <></>
          )}
        </>
      )}
    </div>
  );
}

export default ErrorMessage;
