import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row, Card, CardText, CardBody, CardLink, Image } from 'react-bootstrap';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import NavbarSite from '../../components/NavbarSite/NavbarSite';
import './Invitation.scss';

interface InvitationProps { }

const Invitation : FC<InvitationProps> = () => {

    const { code } = useParams();
    const [ codeLink, setCodeLink ] = useState<string>('');

    useEffect(() => {
        if (code) {
            setCodeLink(code);
        }
    }, [code]);

    return (
        <div className="Invitation" data-testid="Invitation">
            <NavbarSite />

            <div className="page-header min-vh-100">
                <Container> 
                    <Row>
                        <Col md={12} className="mx-auto text-center">
                            <Row className="justify-content-center align-items-center">
                                <Col md={5}>
                                    <Row>
                                        <Col md={12}>
                                            <Card className="p-md-5 p-3">
                                                <Row className="justify-content-center align-items-center">
                                                    <Col md={12} xs={3}>
                                                        <Image src={require('./../../assets/img/logo-bevi.svg').default} alt="Play Store" fluid />
                                                    </Col>

                                                    <Col md={12}>
                                                        <h3 className="py-md-4 py-3 mb-0"> Baixe o App </h3>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <CardBody className="px-md-3 py-md-5 py-md-5 px-3 py-4">
                                                            <CardText className="text-start">
                                                                Parceiro(a) , você precisa do aplicativo instalado para o seu convite funcionar.
                                                            </CardText>
                                                            <CardText className="text-start">
                                                                Após instalar o App, <a href={'https://m.lacqua.app/convite/' + codeLink} className="bnt btn-link"> clique novamente no link </a> deste convite para finalizar o seu registro.
                                                            </CardText>
                                                        </CardBody>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <p className="mt-4 mb-0 here-link"> <small> Clique no botão abaixo para baixar </small> </p>
                                                    </Col>

                                                    {
                                                        (!isMobile || (isMobile && isAndroid)) && (
                                                            <Col md={12}>
                                                                <CardLink href="https://play.google.com/store/apps/details?id=br.com.bevicred.bevicredmobiletoken" className="app-button mt-2">
                                                                    <img src={require('./../../assets/img/gplay.svg').default} alt="Play Store" />
                                                                </CardLink>
                                                            </Col>
                                                        )
                                                    }

                                                    {
                                                        (!isMobile || (isMobile && isIOS)) && (
                                                            <Col md={12}>
                                                                <CardLink href="https://apps.apple.com/us/app/bevi/id1495034890" className="app-button mt-2">
                                                                    <img src={require('./../../assets/img/astore.svg').default} alt="Apple Store" />
                                                                </CardLink>
                                                            </Col>
                                                        )
                                                    }
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Invitation;