import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import AuthService from '../services/AuthService';
import { AccessProfileService } from '../services/Registrations/Access/AccessProfileService';
import { AccessProfileModel } from '../models/Registrations/Access/AccessProfileModel';
import { AccessPageModel } from '../models/Registrations/Access/AccessPageModel';
import { AccessActionModel } from '../models/Registrations/Access/AccessActionModel';
import { UserLogged } from '../models/userLogged';
import { useFirebaseMessaging } from './FirebaseMessaging';
import Cookies from 'js-cookie';


interface ContextProps {
    userLogged: UserLogged | null,
    setUserLogged: Dispatch<SetStateAction<UserLogged | null>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    isLoading: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    accessToken: string,
    login: (data: { username: string, password: string }) => Promise<any>,
    logout: () => Promise<any>,
    deleteCookie: (name: string) => void,
    onInvalid: (property?: any | undefined) => string,
    CanAccess: (value: string, isUrl?: boolean) => Promise<boolean>,
    handleMe: (token: string) => Promise<boolean>,
    isLoadingLogged: boolean,
    origin: string | null,
    setOrigin: Dispatch<SetStateAction<string>>,
    isDevelopment: boolean
}

export const AuthContext = React.createContext<ContextProps>({} as ContextProps);

export const AuthProvider = (props: any) => {
    const [userLogged, setUserLogged] = useState<UserLogged | null>(null);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingLogged, setIsLoadingLogged] = useState<boolean>(false);
    const [accessToken, setAccessToken] = useState<string>("");
    const [origin, setOrigin] = useState<string>("");

    const { handleTokenFcm } = useFirebaseMessaging();

    useEffect(() => {
        const disposer = () => {
            const userLoggedStorage = localStorage.getItem(`@lacquaGui:user`);
            setUserLogged(!!userLoggedStorage ? JSON.parse(userLoggedStorage) : null);
        }
        disposer();
    }, []);

    useEffect(() => {
        if (userLogged) {
            localStorage.setItem('@lacquaGui:user', JSON.stringify(userLogged));
        }
    }, [userLogged]);

    useEffect(() => {
        const disposer = () => {
            let token = '';
            if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
                token = localStorage.getItem("@lacquaGui:auth") || "";
                token = (token && token !== 'undefined') ? JSON.parse(token) : '';
            } else {
                token = Cookies.get("@lacquaGui:auth") || "";
            }
            setAccessToken(token);
        }
        disposer();
    }, []);

    useEffect(() => {
        if (origin) {
            localStorage.setItem('@lacquaGui:origin', origin);
        }
    }, [origin]);

    const login = async (data: { username: string, password: string }) => {
        try {
            setIsLoading(true);

            const [_Response, _Error, _Code] = await (new AuthService()).login(data);

            if (!!_Error || _Code === 401 || !_Response) {
                setIsLoading(false);
                setError(_Response || _Error || 'Os dados informados não conferem, por favor verique os dados e tente novamente.');
                return false;
            }

            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                localStorage.setItem(`@lacquaGui:auth`, JSON.stringify(_Response.access_token));
            }

            const [_ResponseProfile, _ErrorProfile] = await (new AccessProfileService()).getLogged();

            let userRes: UserLogged = {
                id: _Response?.user?.id,
                partnerCode: _Response?.user?.partnerCode,
                name: _Response?.user?.name,
                cellphone: _Response?.user?.cellphone,
                document: _Response?.user?.document,
                email: _Response?.user?.email,
                profileImage: _Response?.user?.profileImage,
                subsidiary: _Response?.user?.subsidiary,
                accessProfile: profileOrder(_ResponseProfile.data)
            }

            setUserLogged({ ...userLogged, ...userRes });

            setIsLoading(false);

            handleTokenFcm();

            return true;
        } catch (err) {
            return err;
        }
    };

    const logout = async () => {
        try {
            await (new AuthService()).logout();
            deleteCookie(`@lacquaGui:auth`);
            setUserLogged(null);
            localStorage.clear();

            return true;
        } catch (err) {
            return err;
        }
    };

    const deleteCookie = (name: string) => {
        document.cookie = `${name}=; Path=/;  Domain=${process.env.REACT_APP_COOKIE_DOMAIN}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }

    const onInvalid = (property?: any | undefined) => {
        return property ? 'invalid' : '';
    }

    const profileOrder = (profile: AccessProfileModel): AccessProfileModel => {
        profile.pages = profile.pages?.sort((a: AccessPageModel, b: AccessPageModel) => (a.order || 0) < (b.order || 0) ? -1 : 1);
        profile.pages?.forEach((x: AccessPageModel) => {
            x.views = x.views?.sort((a: AccessPageModel, b: AccessPageModel) => (a.order || 0) < (b.order || 0) ? -1 : 1);
        });

        return profile;
    }

    const _canAccess = (value: string, isUrl: boolean, pages: AccessPageModel[], path: string, canRef: { can: boolean }) => {
        pages?.forEach((p: AccessPageModel) => {
            if (p.collapse) {
                _canAccess(value, isUrl, p.views || [], path + p.path, canRef);
            } else {
                if (isUrl) {
                    if (value.includes(path + p.layout)) {
                        canRef.can = true;
                        return;
                    }
                } else {
                    p.actions?.forEach((a: AccessActionModel) => {
                        if (value === a.alias) {
                            canRef.can = true;
                            return;
                        }
                    });
                }
            }
        })
    }

    const CanAccess = async (value: string, isUrl: boolean = false): Promise<boolean> => {
        const canAccess: { can: boolean } = { can: false };

        if (!isUrl) {
            userLogged?.accessProfile?.userActions?.forEach((a: any) => {
                if (value === a?.action?.alias) {
                    canAccess.can = true;
                    return;
                }
            });
        }

        _canAccess(value, isUrl, userLogged?.accessProfile?.pages || [], '', canAccess);

        return canAccess.can;
    }

    const handleMe = async (token: string): Promise<boolean> => {
        try {
            const [_Response, _Error, _Code] = await (new AuthService()).me(token);
            if (!!_Error || _Code === 401 || !_Response) {
                setIsLoading(false);
                setError(_Response || _Error || 'Os dados informados não conferem, por favor verique os dados e tente novamente.');
                return false;
            }

            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                localStorage.setItem(`@lacquaGui:auth`, JSON.stringify(token));
            }

            setAccessToken(token);

            const [_ResponseProfile, _ErrorProfile] = await (new AccessProfileService()).getLogged();

            let userRes: UserLogged = {
                id: _Response.data?.id,
                partnerCode: _Response.data?.partnerCode,
                name: _Response.data?.name,
                cellphone: _Response.data?.cellphone,
                document: _Response.data?.document,
                email: _Response.data?.email,
                profileImage: _Response.data?.profileImage,
                subsidiary: _Response.data?.subsidiary,
                accessProfile: profileOrder(_ResponseProfile.data)
            }

            setUserLogged({ ...userLogged, ...userRes });

            setIsLoading(false);

            return true;
        } catch (err) {
            return false;
        }
    }

    const isDevelopment = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

    return (
        <AuthContext.Provider value={{
            userLogged,
            setUserLogged,
            error,
            setError,
            isLoading,
            setIsLoading,
            accessToken,
            login,
            logout,
            deleteCookie,
            onInvalid,
            CanAccess,
            handleMe,
            isLoadingLogged,
            origin,
            setOrigin,
            isDevelopment
        }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => React.useContext(AuthContext);