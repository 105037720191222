import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './ModalCovenantInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { CovenantModel } from '../../../../models/Registrations/Covenant/CovenantModel';
import { useCovenant } from '../../../../providers/Registrations/Covenant/CovenantProvider';
import { Controller, useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validate';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useAuth } from '../../../../providers/AuthProvider';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { useCovenantGroup } from '../../../../providers/Registrations/Covenant/CovenantGroupProvider';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { yesNoOption } from '../../../../models/YesNoOptions';
import { RegionOptions } from '../../../../models/RegionOptions';
import moment from 'moment';
import { toast } from 'react-toastify';

interface ModalCovenantInsertProps {
  show: boolean,
  onClose: any,
  itemEdit?: CovenantModel,
  setItemEdit?: Dispatch<SetStateAction<CovenantModel | undefined>>
}

const ModalCovenantInsert: FC<ModalCovenantInsertProps> = ({ show, onClose, itemEdit, setItemEdit }) => {
  const [selectedCovenantGroup, setSelectedCovenantGroup] = useState<any>();
  const [selectedTyping, setSelectedTyping] = useState<any>();
  const [selectedNational, setSelectedNational] = useState<any>();
  const [selectedRegion, setSelectedRegion] = useState<any>();

  const { isLoading, handleSave, handleList } = useCovenant();
  const { covenantGroupsOptions } = useCovenantGroup();
  const { onInvalid } = useAuth();
  const { handleSubmit, setValue, register, control, reset, formState: { errors } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });
  const animatedComponents = makeAnimated();

  useEffect(() => {
    if (show) {
      if (itemEdit) {
        setValue('name', itemEdit?.name?.replaceAll('\t', '') ?? '');
        setSelectedNational(yesNoOption?.find((x: any) => x.value === (itemEdit?.national === 1 ? 'S' : 'N')));
        setSelectedTyping(yesNoOption?.find((x: any) => x.value === itemEdit?.typing));
        setSelectedRegion(RegionOptions?.find((x: any) => x.value === itemEdit?.region));
        setSelectedCovenantGroup(covenantGroupsOptions?.find((x: any) => x.value === itemEdit?.proposalsGroupId));
      }
    } else {
      setValue('name', '');
      setSelectedCovenantGroup(null);
      setSelectedNational(null);
      setSelectedRegion(null);
      setSelectedTyping(null);
      setItemEdit && setItemEdit(undefined);
      reset();
    }
  }, [show, itemEdit]);

  useEffect(() => {
    setValue('proposalsGroupId', selectedCovenantGroup);
  }, [selectedCovenantGroup]);

  useEffect(() => {
    setValue('typing', selectedTyping);
  }, [selectedTyping]);

  useEffect(() => {
    setValue('national', selectedNational);
  }, [selectedNational]);

  useEffect(() => {
    setValue('region', selectedRegion);
  }, [selectedRegion]);

  const onSubmit = async (data: any) => {
    const _data = { ...data };

    _data.id = itemEdit?.id;
    _data.active = 'S';
    _data.blocked = 'N';
    _data.blockedProc = 'N';
    _data.dateRegister = itemEdit?.dateRegister ?? moment(new Date()).format('YYYY-MM-DD');
    _data.name = _data?.name?.trim()?.toUpperCase();
    _data.proposalsGroupId = _data?.proposalsGroupId?.value;
    _data.typing = _data?.typing?.value;
    _data.national = _data?.national?.value === 'S' ? 1 : 0;
    _data.region = _data?.region?.value;

    const response = await handleSave(_data).then();
    if (response) {
      toast.success('Convênio salvo!');
      handleList().then();
      onClose();
    }
  }

  return (
    <ModalDefault
      title={`${itemEdit ? 'Editar Convênio ' + itemEdit?.name : 'Novo Convênio'}`}
      show={show}
      onClose={onClose}
      sizeModal={'lg'}
      showFooter={true}
      buttonText={isLoading ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoading}
      disabledSubmit={isLoading}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <div className="ModalCovenantInsert" data-testid="ModalCovenantInsert">
        <Row>
          <Col className='form-group' xs={12} md={6}>
            <label>Nome</label>
            <input
              className={`form-control ${onInvalid(errors?.name)}`}
              type='text'
              minLength={3}
              maxLength={150}
              {...register('name')}
            />
            <ErrorMessage name='Nome' type={errors?.name?.type} min={5} max={150} />
          </Col>

          <Col className='form-group' xs={12} md={6}>
            <label>Grupo</label>
            <Controller
              control={control}
              name='proposalsGroupId'
              render={({ field: { name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isSearchable
                  placeholder="Selecione..."
                  options={covenantGroupsOptions}
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                  defaultValue={selectedCovenantGroup}
                  value={selectedCovenantGroup}
                  onChange={(val: any) => {
                    setSelectedCovenantGroup(val);
                  }}
                />
              )}
            />
            <ErrorMessage name='Grupo' type={errors?.proposalsGroupId?.type} />
          </Col>

          <Col className='form-group' xs={12} md={4}>
            <label>Liberado Digitação</label>
            <Controller
              control={control}
              name='typing'
              render={({ field: { name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isSearchable
                  placeholder="Selecione..."
                  options={yesNoOption}
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                  defaultValue={selectedTyping}
                  value={selectedTyping}
                  onChange={(val: any) => {
                    setSelectedTyping(val);
                  }}
                />
              )}
            />
            <ErrorMessage name='Liberado Digitação' type={errors?.typing?.type} />
          </Col>

          <Col className='form-group' xs={12} md={4}>
            <label>Nacional</label>
            <Controller
              control={control}
              name='national'
              render={({ field: { name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isSearchable
                  placeholder="Selecione..."
                  options={yesNoOption}
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                  defaultValue={selectedNational}
                  value={selectedNational}
                  onChange={(val: any) => {
                    setSelectedNational(val);
                  }}
                />
              )}
            />
            <ErrorMessage name='Nacional' type={errors?.national?.type} />
          </Col>

          <Col className='form-group' xs={12} md={4}>
            <label>Região</label>
            <Controller
              control={control}
              name='region'
              render={({ field: { name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isSearchable
                  placeholder="Selecione..."
                  options={RegionOptions}
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                  defaultValue={selectedRegion}
                  value={selectedRegion}
                  onChange={(val: any) => {
                    setSelectedRegion(val);
                  }}
                />
              )}
            />
            <ErrorMessage name='Região' type={errors?.region?.type} />
          </Col>
        </Row>
      </div>
    </ModalDefault>
  )
};

export default ModalCovenantInsert;
