import * as yup from "yup";
import { validateCNPJ, validateCep, validateEmail, validatePhone } from "validations-br";

export const schema = yup.object().shape({
    acronym: yup
        .string()
        .required()
        .min(2)
        .max(50),
    corporateName: yup
        .string()
        .required()
        .min(3)
        .max(150),
    name: yup
        .string()
        .required()
        .min(3)
        .max(100),
    cnpj: yup
        .string()
        .required()
        .max(18).test('invalid', 'CNPJ inválido', (value: string | undefined) => validateCNPJ(value ?? '')),
    hostname: yup
        .string()
        .required()
        .min(3)
        .max(100),
    colorPrimary: yup
        .string()
        .required(),
    colorSecondary: yup
        .string()
        .required(),
    colorText: yup
        .string()
        .required(),
    email: yup
        .string()
        .required()
        .test('invalid', 'E-mail inválido', (value: string | undefined) => validateEmail(value ?? '')),
    phone: yup
        .string()
        .required()
        .min(3)
        .max(255)
        .test("invalid", "Telefone é inválido", (value: string | undefined) => validatePhone(value ?? '')),
    logoDefault: yup.string().required(),
    logoInverse: yup.string().required(),
    favicon: yup.string(),
    backgroundLogin: yup.string(),

    cep: yup
        .string()
        .required()
        .test(
            "invalid",
            "CEP é inválido",
            (value: string | undefined) => validateCep(value?.replace(/\D/g, "").replace(/(\d{5})(\d)/, "$1-$2") ?? '')
        ),
    publicPlace: yup
        .string()
        .required()
        .min(3)
        .max(150),
    numbers: yup
        .string()
        .required()
        .min(1)
        .max(10),
    complement: yup
        .string()
        .max(100),
    neighborhood: yup
        .string()
        .required()
        .min(2)
        .max(150),
    city: yup
        .string()
        .required()
        .min(3)
        .max(150),
    state: yup
        .string()
        .required(),
});