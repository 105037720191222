import { FC, useEffect } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import SidebarFilter from '../../../../SidebarFilter/SidebarFilter';
import { useForm } from 'react-hook-form';
import { AccessPageParams } from '../../../../../models/Registrations/Access/AccessPageParams';
import { useAccessPage } from '../../../../../providers/Registrations/Access/AccessPageProvider';

interface SidebarAccessLevelPagesFilterProps { }

export const defaultValues = {
  name: ''
} as AccessPageParams;

const SidebarAccessLevelPagesFilter: FC<SidebarAccessLevelPagesFilterProps> = () => {

  const { handleSubmit, register } = useForm<any>({ defaultValues });

  const { params, setParams, handleList } = useAccessPage();

  useEffect(() => {
    handleList()
  }, [params]);

  const onSubmit = async (data: AccessPageParams) => {
    setParams({ ...params, ...data, ...{ page: 1 } });
  }

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarAccessLevelPagesFilter" data-testid="SidebarAccessLevelPagesFilter">
        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="name">NOME</label>
              <input
                className={`form-control`}
                {...register('name')}
                type='text'
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  )
}

export default SidebarAccessLevelPagesFilter;
