import { FC } from 'react';
import './SidebarVisitsTypesActivitiesFilter.scss';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import { useForm } from 'react-hook-form';
import { useVisitsTypesActivities } from '../../../../providers/Visits/VisitsTypesActivities/VisitsTypesActivitiesProvider';
import { Col, FormGroup, Row } from 'react-bootstrap';

interface SidebarVisitsTypesActivitiesFilterProps { }

export const defaultValues = {
  name: ''
} as any;

const SidebarVisitsTypesActivitiesFilter: FC<SidebarVisitsTypesActivitiesFilterProps> = () => {

  const { handleSubmit, register } = useForm<any>({ defaultValues });

  const { params, setParams } = useVisitsTypesActivities();

  const onSubmit = async (data: any) => {
    setParams({ ...params, ...data, ...{ page: 1 } });
  }

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarVisitsTypesActivitiesFilter" data-testid="SidebarVisitsTypesActivitiesFilter">
        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="name">NOME</label>
              <input
                className="form-control"
                {...register('name')}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  );
}

export default SidebarVisitsTypesActivitiesFilter;