import { FC } from 'react';
import './ImportToolsWrapper.scss';
import { useProposalSituation } from '../../../../../providers/Proposals/Import/ProposalSituationProvider';
import { ProposalIconsFileEnum, ProposalIconsCardStyleEnum } from '../../../../../enums/Proposals/Import/Monitor/ProposalIconsCardEnum';

interface ImportToolsWrapperProps { }

const ImportToolsWrapper: FC<ImportToolsWrapperProps> = () => {

  const { situations, situationsOptions } = useProposalSituation();

  const icons = ProposalIconsFileEnum
  const iconsStyle = ProposalIconsCardStyleEnum

  return (
    <>
      <div className="row" data-testid="ImportToolsWrapper">
        <div className="col-lg-12">
          <div className="row">

            {situations ? (
              <>
                {situationsOptions?.map((x: any, index) => (
                  <div className="col-xl-4 col-lg-6 col-md-12 col-12">
                    <div className="card  mb-4 h-100 ">
                      <div className="card-body p-3">
                        <div className="row">
                          <div className="col-8">
                            <div className="numbers">
                              <p className="text-sm mb-0 text-uppercase font-weight-bold">{x.label}</p>
                              <h5 className="align-postition-number" style={{ color: x.color }}>
                                {x.qty}
                              </h5>
                            </div>
                          </div>
                          <div className="col-4 text-end">

                            <div className={iconsStyle.map((item: any) => item.icon)[index]}>
                              <i className={icons.map((item: any) => item.icon)[index]} aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div>
                <span className="text-center text-sm">Não há registros</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );

}

export default ImportToolsWrapper;