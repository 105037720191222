import api from "../Api";
import { BaseService } from "../BaseService";

const URL = '/api/auth/company';

export class StyleService extends BaseService {
    async getStyle(host: string): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/getByHost`, { hostname: host }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}