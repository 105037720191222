import { Dispatch, FC, SetStateAction } from 'react';
import './ModalLastVisits.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { VisitModel } from '../../../../models/Visits/IndicatorManagement/VisitModel';
import VisitsQuantityList from '../../VisitsAnalysis/VisitsQuantityList/VisitsQuantityList';

interface ModalLastVisitsProps {
  show: boolean;
  onClose: any;
  visitEdit?: VisitModel;
  setVisitEdit?: Dispatch<SetStateAction<VisitModel | undefined>>
}

const ModalLastVisits: FC<ModalLastVisitsProps> = ({ show, onClose }) => {

  const handleClose = () => {
    onClose();
  }


  return (
    <>
      <ModalDefault
        title={'LISTAGEM DE ÚLTIMA VISITA'}
        show={show}
        onClose={handleClose}
        sizeModal={'xl'}
        showFooter={true}
        backdrop="static"
        backgroundColor="#f8f9fa"
      >
        <div className="ModalLastVisits" data-testid="ModalLastVisits">
          <div><VisitsQuantityList /></div>
        </div >

      </ModalDefault >

    </>
  );

}

export default ModalLastVisits;
