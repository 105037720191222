import * as yup from "yup";
import { validateCNPJ, validateCep, validateEmail, validatePhone } from "validations-br";

export const schema = yup.object().shape({
    name: yup
        .string()
        .required()
        .min(3)
        .max(100),
    company: yup
        .string()
        .required(),
    userType: yup
        .string()
        .required(),
    accessLevel: yup
        .string()
        .required(),
});