import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './ModalCampaignInsert.scss';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import { useCampaign } from "../../../../providers/Marketplace/Campaign/CampaignProvider";
import { Card, Dropdown, Row } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import FormSimulation from "./FormSimulation/FormSimulation";
import { CampaignSimulationModel } from "../../../../models/Marketplace/Campaign/CampaignSimulationModel";
import { dateFormat } from "../../../../utils/FormatDate";
import SkeletonTable from "../../../SkeletonTable/SkeletonTable";
import AppPagination from "../../../AppPagination/AppPagination";
import FormatNumber from "../../../../utils/FormatNumber";
import FormatCpfCnpj from "../../../../utils/FormatCpfCnpj";
import { Show } from "../../../../pages/Marketplace/Campaign/Campaign";
import FormCampaign from "./FormCampaign/FormCampaign";
import { CampaignSimulationFilter } from "../../../../models/Marketplace/Campaign/CampaignSilmulaitonFilter";
import { CampaignCreateModel } from '../../../../models/Marketplace/Campaign/CampaignCreateModel';
import FormatMoney from '../../../../utils/FormatMoney';
import DropdownMenuTable from '../../../DropdownMenuTable/DropdownMenuTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

interface ModalCampaignInsertProps {
    show: Show;
    setShow: Dispatch<SetStateAction<Show>>;
}

const DefaultFormValuesSimulation: CampaignSimulationFilter = {
    client_age_max: 118,
    client_age_min: 18,
    client_without_age: true,
    commercial_manager_id: [],
    director_manager_id: [],
    exception_proposal: [],
    executive_manager_id: [],
    financial_id: [],
    formcontract_id: [],
    productgroup_id: [],
    regional_manager_id: [],
    typed_proposal_agent_partner_code: [],
    typed_proposal_days: 0,
    typed_proposal_gross_value_max: 100000,
    typed_proposal_gross_value_min: 0,
    typed_proposal_id: [],
}

const DefaultFormValuesCreate: CampaignCreateModel = {
    ...DefaultFormValuesSimulation,
    campaign_name: '',
    campaign_obs: '',
    campaign_origin: '',
    campaign_sanitize: null,
    campaign_deadline: 0,
    campaign_gen_id: null,
    campaign_generate_again_in_x_days: null
}

const ModalCampaignInsert: FC<ModalCampaignInsertProps> = ({ show, setShow }) => {
    const [removedProposalsId, setRemovedProposalsId] = useState<string[]>([]);
    const [tagToAddRemove, setTagToAddRemove] = useState<string>('');

    const {
        simulations,
        setSimulations,
        simulationPages,
        setSimulationPages,
        handleSimulation,
        handleCreateCampaign,
        loading,
        options
    } = useCampaign();

    const {
        reset,
        register,
        setValue,
        handleSubmit,
        control,
        formState: { errors }
    } = useForm<CampaignSimulationFilter>({
        defaultValues: DefaultFormValuesSimulation
    });

    const {
        reset: resetCreate,
        register: registerCreate,
        setValue: setValueCreate,
        handleSubmit: handleSubmitCreate,
        control: controlCreate,
        formState: { errors: errorsCreate }
    } = useForm<CampaignCreateModel>({
        defaultValues: DefaultFormValuesCreate
    });

    useEffect(() => setValue('page', 1), []);

    const handleCloseSimulation = () => {
        setSimulations([]);
        setSimulationPages({});
        reset();
        resetCreate();
        setRemovedProposalsId([]);
        setTagToAddRemove('');
        setShow({ ...show, simulation: false })
    }

    const handleCloseCampaign = () => {

        setShow({ ...show, campaign: false })
    }

    const clearForm = () => {
        setValue('client_age_max', 118);
        setValue('client_age_min', 0);
        setValue('client_without_age', true);
        setValue('commercial_manager_id', []);
        setValue('director_manager_id', []);
        setValue('executive_manager_id', []);
        setValue('financial_id', []);
        setValue('formcontract_id', []);
        setValue('productgroup_id', []);
        setValue('regional_manager_id', []);
        setValue('typed_proposal_agent_partner_code', []);
        setValue('typed_proposal_days', 0);
        setValue('typed_proposal_gross_value_max', 100000);
        setValue('typed_proposal_gross_value_min', 0);
        setValue('typed_proposal_id', []);
    }

    const removeProposal = (proposalId: number) => {
        setTagToAddRemove(proposalId.toString());
        if (proposalHasRemoved(proposalId)) {
            setRemovedProposalsId((current: string[]) => current.filter((x: string) => x !== proposalId.toString()));
        } else {
            setRemovedProposalsId((current: string[]) => [...current, proposalId.toString()]);
        }
    }

    const proposalHasRemoved = (proposalId: number = 0): boolean => {
        return !!removedProposalsId.find((x: string) => proposalId.toString() === x);
    }

    const onShowCreateCampaign = () => {
        if (simulations?.length > 0) {
            setShow({ ...show, campaign: true })
        } else {
            toast.warning('Realize a simulação para gerar a campanha');
        }
    }

    const onSimulationSubmit = async (data: CampaignSimulationFilter) => {
        const _data: CampaignSimulationFilter = JSON.parse(JSON.stringify(data));

        if (Object.hasOwn(_data, 'typed_proposal_days')) {
            if (!_data.typed_proposal_days) {
                delete _data.typed_proposal_days;
            } else {
                _data.typed_proposal_days = Number(_data.typed_proposal_days);
            }
        }

        if (_data.typed_proposal_id && _data.typed_proposal_id.length > 0) {
            _data.typed_proposal_id = _data.typed_proposal_id.map((x: any) => Number(x));
        }

        if (removedProposalsId.length > 0) {
            _data.exception_proposal = removedProposalsId?.map((x: string) => Number(x));
        }

        await handleSimulation(_data);
    }

    const onCreateSubmit = async (data: CampaignCreateModel) => {
        const _data = JSON.parse(JSON.stringify(data));

        _data.campaign_sanitize = _data.campaign_sanitize === 1;
        _data.campaign_deadline = Number(_data.campaign_deadline);
        _data.campaign_generate_again_in_x_days = !!_data.campaign_generate_again_in_x_days ? Number(_data.campaign_generate_again_in_x_days) : null;

        const response = await handleCreateCampaign(_data).then();

        response && setShow({ campaign: false, simulation: false });
    }

    const renderList = () => {
        return (
            <>
                {simulations?.length > 0 ? (
                    <>
                        {simulations.map((line: CampaignSimulationModel) => (
                            <tr key={line.typed_proposal_id} className={`text-sm ${proposalHasRemoved(line.typed_proposal_id) && 'removed'}`}>
                                <td>{line.financial_name}</td>
                                <td>{line.productgroup_name}</td>
                                <td>{line.formcontract_name}</td>
                                <td>{line.agreement_name}</td>
                                <td>{line.typed_proposal_agent_partner_code}</td>
                                <td>{line.typed_proposal_number}</td>
                                <td>{dateFormat({
                                    date: line.typed_proposal_date,
                                    datetime: false
                                })}</td>
                                <td>{line.typed_proposal_days}</td>
                                <td>{FormatMoney(line.typed_proposal_gross_value)}</td>
                                <td>{FormatMoney(line.typed_proposal_liquid_value)}</td>
                                <td>{dateFormat({ date: line.updated_in, datetime: true })}</td>
                                <td>{FormatCpfCnpj(line.client_cpf)}</td>
                                <td>{line.client_name}</td>
                                <td>{dateFormat({
                                    date: line.client_date_of_birth,
                                    datetime: false
                                })}</td>
                                <td>{line.client_age}</td>
                                <td>{line.client_city}/{line.client_state}</td>
                                <td>{line.client_phonenumber}</td>
                                <td className='text-center'>
                                    <DropdownMenuTable key={line.typed_proposal_id || 0}>
                                        <Dropdown.Item
                                            className="border-radius-md py-2"
                                            onClick={() => removeProposal(line.typed_proposal_id || 0)}>
                                            <FontAwesomeIcon
                                                icon={proposalHasRemoved(line.typed_proposal_id) ? faCheck : faBan}
                                                className={`me-2 ${proposalHasRemoved(line.typed_proposal_id) ? 'text-success' : 'text-danger'}`}
                                            />
                                            {proposalHasRemoved(line.typed_proposal_id) ? 'Adicionar Proposta na Campanha' : 'Remover Proposta da Campanha'}
                                        </Dropdown.Item>
                                    </DropdownMenuTable>
                                </td>
                            </tr>
                        ))}
                    </>
                ) : (
                    <tr>
                        <td colSpan={16} className="text-center text-sm">Não há registros</td>
                    </tr>
                )
                }
            </>
        );
    }

    const renderContent = (): JSX.Element => {
        return (
            <>
                {!loading?.listSimulation ? (
                    <div>
                        <div className="table-responsive">
                            <table className="table table-flush m-0 w-100">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="text-uppercase opacity-7" scope="col">Financeira</th>
                                        <th className="text-uppercase opacity-7" scope="col">Produto</th>
                                        <th className="text-uppercase opacity-7" scope="col">Forma</th>
                                        <th className="text-uppercase opacity-7" scope="col">Convênio</th>
                                        <th className="text-uppercase opacity-7" scope="col">Cód. Agente</th>
                                        <th className="text-uppercase opacity-7" scope="col">Adesão</th>
                                        <th className="text-uppercase opacity-7" scope="col">Data Contrato</th>
                                        <th className="text-uppercase opacity-7" scope="col">Dias do contrato
                                        </th>
                                        <th className="text-uppercase opacity-7" scope="col">Valor Bruto</th>
                                        <th className="text-uppercase opacity-7" scope="col">Valor Líquido</th>
                                        <th className="text-uppercase opacity-7" scope="col">Data Atualização
                                        </th>
                                        <th className="text-uppercase opacity-7" scope="col">CPF Cliente</th>
                                        <th className="text-uppercase opacity-7" scope="col">Nome Cliente</th>
                                        <th className="text-uppercase opacity-7" scope="col">Data Nascimento
                                            Cliente
                                        </th>
                                        <th className="text-uppercase opacity-7" scope="col">Idade Cliente</th>
                                        <th className="text-uppercase opacity-7" scope="col">Cidade</th>
                                        <th className="text-uppercase opacity-7" scope="col">Telefone</th>
                                        <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderList()}
                                </tbody>
                            </table>
                        </div>

                        {simulations?.length > 0 && (
                            <div>
                                <nav aria-label="...">
                                    <AppPagination
                                        pages={simulationPages}
                                        handlePaginate={(page) => {
                                            setValue('page', page);
                                            handleSubmit(onSimulationSubmit)();
                                        }}
                                        style={{ backgroundColor: '#fff' }}
                                    />
                                </nav>
                            </div>
                        )}
                    </div>
                ) : (
                    <SkeletonTable />
                )}
            </>
        );
    }

    return (
        <>
            <ModalDefault
                title={"Gerar Campanha"}
                show={show.simulation}
                onClose={handleCloseSimulation}
                sizeModal={'xxl'}
                showFooter={true}
                buttonText={'Gerar Campanha'}
                showButtonSpinner={false}
                disabledSubmit={false}
                handleSubmit={onShowCreateCampaign}
                backdrop="static"
                backgroundColor="#f8f9fa"
            >
                <div className="ModalCampaignInsert">
                    <FormSimulation
                        register={register}
                        setValue={setValue}
                        errors={errors}
                        control={control}
                        options={options}
                        isLoading={loading.listSimulation}
                        handleSubmit={handleSubmit(onSimulationSubmit)}
                        removedProposalId={tagToAddRemove}
                        setRemovedProposalsId={setRemovedProposalsId}
                        setRemovedProposalId={setTagToAddRemove}
                    />
                    <Row className="mt-3">
                        <Card>
                            <Card.Header className="pb-1 px-2">
                                Oportunidades Simuladas
                            </Card.Header>
                            <Card.Body className="form-group">
                                {renderContent()}
                            </Card.Body>
                        </Card>
                    </Row>
                </div>
            </ModalDefault>
            <ModalDefault
                title={"Gerar Campanha"}
                show={show.campaign}
                onClose={handleCloseCampaign}
                sizeModal={'xl'}
                showFooter={true}
                buttonText={loading.createCampaign ? 'Gerando...' : 'Gerar'}
                showButtonSpinner={loading.createCampaign}
                disabledSubmit={loading.createCampaign}
                handleSubmit={handleSubmitCreate(onCreateSubmit)}
                backdrop="static"
                backgroundColor="#f8f9fa"
            >
                <FormCampaign
                    show={show.campaign}
                    register={registerCreate}
                    setValue={setValueCreate}
                    errors={errorsCreate}
                    control={controlCreate}
                    showGenerateAgain={true}
                ></FormCampaign>
            </ModalDefault>
        </>
    )
};

export default ModalCampaignInsert;
