import { FC } from 'react';
import { useIndicatorManagementProductionRange } from '../../../../../providers/Visits/IndicatorManagement/IndicatorManagementProductionRangeProvider';
import { ProductionRangeListModel } from '../../../../../models/Visits/IndicatorManagement/ProductionRangeModel';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

interface ProductionChartByRangeProps { }

const ProductionChartByRange: FC<ProductionChartByRangeProps> = () => {
  const { productionsRangeList } = useIndicatorManagementProductionRange();

  const getMaxValue = (): number => {
    let maxValue = 0;
    productionsRangeList?.lines?.slice(0, -1)?.forEach((x: ProductionRangeListModel) => {
      let max = 0;
      if ((x.quantityM1?.value || 0) > (x.quantityM2?.value || 0) && (x.quantityM1?.value || 0) > (x.quantityM3?.value || 0)) {
        max = x.quantityM1?.value ?? 0;
      } else if ((x.quantityM2?.value || 0) > (x.quantityM3?.value || 0)) {
        max = x.quantityM2?.value ?? 0;
      } else {
        max = x.quantityM3?.value ?? 0;
      }
      if (max > maxValue) {
        maxValue = max;
      }
    });
    return maxValue;
  }

  return (
    <div className="ProductionChartByRange" data-testid="ProductionChartByRange">
      <Bar
        height={100}
        data={{
          labels: productionsRangeList?.lines?.slice(0, -1)?.map((item: ProductionRangeListModel) => item.range),
          datasets: [
            {
              data: productionsRangeList?.lines?.slice(0, -1)?.map((item: ProductionRangeListModel) => item.quantityM1?.value),
              label: productionsRangeList?.lines && moment(productionsRangeList?.lines[0]?.quantityM1?.month).format('MM/YYYY'),
              backgroundColor: '#7779BB',
              borderRadius: 8,
            },
            {
              data: productionsRangeList?.lines?.slice(0, -1)?.map((item: ProductionRangeListModel) => item.quantityM2?.value),
              label: productionsRangeList?.lines && moment(productionsRangeList?.lines[0]?.quantityM2?.month).format('MM/YYYY'),
              backgroundColor: '#222343',
              borderRadius: 8,
            },
            {
              data: productionsRangeList?.lines?.slice(0, -1)?.map((item: ProductionRangeListModel) => item.quantityM3?.value),
              label: productionsRangeList?.lines && moment(productionsRangeList?.lines[0]?.quantityM3?.month).format('MM/YYYY'),
              backgroundColor: '#25CBDB',
              borderRadius: 8,
            }
          ],
        }}
        options={
          {
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: 'bottom'
              },
              tooltip: {
                enabled: true,
                intersect: true,
                callbacks: {
                  title: (item) => {
                    return 'Faixa: ' + item[0].label;
                  },
                  label: (data) => {
                    return 'Quantidade: ' + data.raw;
                  },
                  afterBody: (data) => {
                    switch (data[0].datasetIndex) {
                      case 0:
                        return 'Porcentagem: ' + (productionsRangeList?.lines && productionsRangeList?.lines[data[0].dataIndex].quantityPercentM1?.value) + '%';
                      case 1:
                        return 'Porcentagem: ' + (productionsRangeList?.lines && productionsRangeList?.lines[data[0].dataIndex].quantityPercentM2?.value) + '%';
                      case 2:
                        return 'Porcentagem: ' + (productionsRangeList?.lines && productionsRangeList?.lines[data[0].dataIndex].quantityPercentM3?.value) + '%';
                      default:
                        break;
                    }
                  }
                }
              },
              datalabels: {
                display: false,
              }
            },
            scales: {
              y: {
                min: 0,
                max: getMaxValue() + 2,
              }
            }
          }
        }
      />
    </div>
  )
};

export default ProductionChartByRange;
