import React, { FC, useEffect, useState } from 'react';
import './SidebarCovenantTableConfigFilter.scss';
import SidebarFilter from '../../../../SidebarFilter/SidebarFilter';
import { Controller, useForm } from 'react-hook-form';
import { CovenantTableConfigParamsModel } from '../../../../../models/Tools/Configurations/CovenantTable/CovenantTableConfigParamsModel';
import { useCovenantTableConfig } from '../../../../../providers/Tools/Configurations/CovenantTableConfigProvider';
import { Col, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { customStyles } from '../../../../../models/SelectCustomStyles';
import { useFinancialProcess } from '../../../../../providers/Proposals/Process/FinancialProcessProvider';
import InputDateRange from '../../../../InputDateRange/InputDateRange';
import moment from 'moment';
import { VisualizeEnum } from '../../../../../enums/Tool/Configurations/CovenantTable/VisualizeEnum';

interface SidebarCovenantTableConfigFilterProps { }

const previewOptions: any[] = [
  { value: VisualizeEnum.NOT_CONFIGURED, label: 'Não Configurados' },
  { value: VisualizeEnum.CONFIGURED, label: 'Configurados' },
  { value: VisualizeEnum.INACTIVE, label: 'Inativos' }
];

const originOptions: any[] = [
  { value: 'C', label: 'Comissão' },
  { value: 'P', label: 'Produção' },
];

const yesNoOptions: any[] = [
  { value: 1, label: 'Sim' },
  { value: 2, label: 'Não' }
];

const SidebarCovenantTableConfigFilter: FC<SidebarCovenantTableConfigFilterProps> = () => {
  const [startDate, setStartDate] = useState<string>(moment(new Date()).subtract(15, 'days').format('YYYY-MM-DD hh:mm:ss'));
  const [endDate, setEndDate] = useState<string>(moment(new Date()).format('YYYY-MM-DD hh:mm:ss'));

  const { handleList, params, setParams } = useCovenantTableConfig();
  const { handleSubmit, control, setValue, register } = useForm<CovenantTableConfigParamsModel>();
  const { handleList: handleListFinancials, financialsOptions } = useFinancialProcess();

  const animatedComponents = makeAnimated();

  useEffect(() => {
    if (financialsOptions?.length === 0) {
      handleListFinancials();
    }
  }, []);

  useEffect(() => {
    handleList().then();
  }, [params]);

  useEffect(() => {
    setValue('startDate', startDate);
  }, [startDate]);

  useEffect(() => {
    setValue('endDate', endDate);
  }, [endDate]);

  const onSubmit = async (data: CovenantTableConfigParamsModel) => {
    const _data = { ...data };

    _data.startDate = _data.startDate ? moment(_data.startDate).format('YYYY-MM-DD') : '';
    _data.endDate = _data.endDate ? moment(_data.endDate).format('YYYY-MM-DD') : '';

    setParams(_data);
  }

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarCovenantTableConfigFilter" data-testid="SidebarCovenantTableConfigFilter">
        <Row>
          <Col sm={12} className='form-group'>
            <label htmlFor='preview'>Visualizar</label>
            <Controller
              name="visualize"
              control={control}
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  isSearchable
                  options={previewOptions}
                  placeholder="Selecione..."
                  className={`form-control p-0`}
                  components={animatedComponents}
                  onChange={(val: any) => {
                    onChange(val?.value);
                  }}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                />
              )}
            />
          </Col>

          <Col sm={12} className='form-group'>
            <label htmlFor="financialId">Financeira</label>
            <Controller
              name="financialId"
              control={control}
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  isSearchable
                  options={financialsOptions}
                  placeholder="Selecione..."
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  onChange={(val: any) => {
                    onChange(val?.value || undefined);
                  }}
                  styles={customStyles}
                />
              )}
            />
          </Col>

          <Col sm={12} className='form-group'>
            <label htmlFor="origin">Origem</label>
            <Controller
              name="origin"
              control={control}
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  isSearchable
                  options={originOptions}
                  placeholder="Selecione..."
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  onChange={(val: any) => {
                    onChange(val?.value || undefined);
                  }}
                  styles={customStyles}
                />
              )}
            />
          </Col>

          <Col sm={12} className='form-group'>
            <label htmlFor="priceRequested">Solicitado Price</label>
            <Controller
              name="requested"
              control={control}
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  isSearchable
                  options={yesNoOptions}
                  placeholder="Selecione..."
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  onChange={(val: any) => {
                    onChange(val?.value || undefined);
                  }}
                  styles={customStyles}
                />
              )}
            />
          </Col>

          <Col sm={12} className='form-group'>
            <label htmlFor="priceResponse">Retorno Price</label>
            <Controller
              name="returned"
              control={control}
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  isSearchable
                  options={yesNoOptions}
                  placeholder="Selecione..."
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  onChange={(val: any) => {
                    onChange(val?.value || undefined);
                  }}
                  styles={customStyles}
                />
              )}
            />
          </Col>

          <Col sm={12}>
            <label>Data Importação</label>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              startLabelText=''
              endLabelText=''
            />
          </Col>

          <Col sm={12} className='form-group'>
            <label>Descrição Convênio</label>
            <input
              className='form-control'
              type='text'
              {...register('covenant')}
            />
          </Col>

          <Col sm={12} className='form-group'>
            <label>Descrição Tabela</label>
            <input
              className='form-control'
              type='text'
              {...register('table')}
            />
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  )
};

export default SidebarCovenantTableConfigFilter;
