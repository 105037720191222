import api from "../../Api";
import { BaseService } from "../../BaseService";



const URL = 'api/user/registrations/updates';

export class PartnerRegistrationUpdateService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async export(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`api/report/user/registrations`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}