import { FC, useEffect } from 'react';
import './FormWalletVisits.scss';
import PageBase from '../../../PageBase/PageBase';
import { useIndicatorManagementWalletVisits } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementWalletProvider';
import { toast } from 'react-toastify';
import { TabEnumIndicatorManagement } from '../../../../pages/Visits/IndicatorManagement/IndicatorManagement';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import WalletVisitsList from './WalletVisitsList/WalletVisitsList';

interface FormWalletVisitsProps {
  currentActiveTab?: TabEnumIndicatorManagement;
}

const FormWalletVisits: FC<FormWalletVisitsProps> = ({ currentActiveTab }) => {

  const { wallets, params, isLoading, handleGetWallet } = useIndicatorManagementWalletVisits();

  useEffect(() => {
    if (currentActiveTab === TabEnumIndicatorManagement.WALLET) {
      if (params?.commercialId) {
        if (!wallets) {
          handleGetWallet(params).then();
        }
      } else {
        toast.warning('Faça um filtro para listar a Carteira!');
      }
    }
  }, [currentActiveTab, params]);

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!!wallets?.results && !isLoading ? (
          <>
            <WalletVisitsList />
          </ >
        ) : (
          isLoading ? <>
            <SkeletonTable columns={11} />
          </> :
            <>
              <h4>CARTEIRA</h4>
              <div className='text-center font-weight-bold'>
                Sem dados para gerar a tabela!
              </div>
            </>
        )
        }
      </>

    );
  }

  return (
    <div className="FormWalletVisits" data-testid="FormWalletVisits">

      <PageBase
        content={renderContent()}
        showCardHeader={false}
      />

    </div>
  );

}

export default FormWalletVisits;
