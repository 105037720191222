import React, { Dispatch, FC, SetStateAction } from 'react';
import './ContractFormHideButtons.scss';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

interface ContractFormHideButtonsProps {
  hideButtons: any[],
  setHideButtons: Dispatch<SetStateAction<any[]>>
}

const ContractFormHideButtons: FC<ContractFormHideButtonsProps> = ({ hideButtons, setHideButtons }) => {

  const onClickHideButton = (button: any, index: number) => {
    const _hideButtons = [...hideButtons];
    button.show = !button.show;
    _hideButtons[index] = button;
    setHideButtons(_hideButtons);
  }

  return (
    <div className="ContractFormHideButtons" data-testid="ContractFormHideButtons">
      <Col md={12} className="d-flex justify-content-end">
        <Row className="display-button mb-3">
          <div className="d-flex justify-content-between align-items-center flex-md-row">
            <div className="d-flex gap-1 justify-content-center align-items-center flex-md-row">
              {hideButtons?.map((x: any, index: number) => (
                <button
                  type="button"
                  className="btn bg-primary text-white mb-0 ms-2 min-width-btn text-capitalize"
                  onClick={() => onClickHideButton(x, index)}
                >
                  <FontAwesomeIcon
                    icon={x.show ? faEye : faEyeSlash}
                    className="me-1"
                  />
                  {x.label}
                </button>
              ))}
            </div>
          </div>
        </Row>
      </Col>
    </div>
  )
};

export default ContractFormHideButtons;
