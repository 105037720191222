import { FC, useEffect, useState } from 'react';
import './AuthenticatorValidationEmail.scss';
import Spinner from '../../../components/Spinner/Spinner';
import { useStyle } from '../../../providers/Style/StyleProvider';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useAuthenticatorValidationEmail } from '../../../providers/Authenticator/AuthenticatorValidationEmailProvider';

interface AuthenticatorValidationEmailProps { }

const AuthenticatorValidationEmail: FC<AuthenticatorValidationEmailProps> = () => {
  const [responseControl, setResponseControl] = useState<boolean>(false);

  const { isLoading: isLoadingStyle, style, handleStyle } = useStyle();
  const { isLoading, error, setError, handleFetch } = useAuthenticatorValidationEmail();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('token') && urlParams.get('value')) {
      handleFetch(urlParams.get('token') || '', Number(urlParams.get('value'))).then((response: boolean) => {
        if (response) {
          setResponseControl(response);
        }
      })
    }
  }, []);

  useEffect(() => {
    setError('');
    handleStyle().then();
  }, []);

  return (
    <main className="AuthenticatorValidationEmail main-content main-content-bg mt-0 h-100" data-testid="AuthenticatorValidationEmail">
      {(!isLoadingStyle && style) && (
        <>
          <div className={`page-header min-vh-100`} style={{ backgroundImage: `url('${style?.backgroundLogin}')` }}>
            <span className="mask bg-gradient-dark opacity-3"></span>
            <Container>
              <Row className="justify-content-center">
                <Col lg={4} md={7}>
                  <Card className="border-0 mb-0">
                    <Card.Header className="bg-transparent">
                      <h5 className="text-dark text-center mt-2 mb-3">
                        <img
                          src={style?.logoDefault}
                          alt="Logo"
                          style={{ maxHeight: 48 }}
                        />
                      </h5>
                    </Card.Header>
                    <Card.Body className="px-lg-5 pt-0">
                      {isLoading ? (
                        <div className="text-center text-dark mb-0">
                          <div className="mt-5">
                            <Spinner backgroundColor='white' opacity='1' position='position-relative' />
                            <p>Validando o seu acesso</p>
                          </div>
                        </div>
                      ) : (
                        <>
                          {responseControl ? (<p className="text-center">Acesso liberado</p>) : (<>{error && (<p className="text-center">{error}</p>)}</>)}
                        </>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}

      {isLoadingStyle && (
        <div className='mt-12'>
          <Spinner backgroundColor='white' opacity='1' />
        </div>
      )}
    </main>
  );
}

export default AuthenticatorValidationEmail;
