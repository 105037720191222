import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import {ToastSettings} from "../../../utils/ToastSettings";
import {toast} from "react-toastify";
import {useReportRequest} from "../../Reports/ReportRequest/ReportRequestProvider";
import { EasyPortService } from "../../../services/Marketplace/EasyPort/EasyPortService";
import { EasyPortModel } from "../../../models/Marketplace/EasyPort/EasyPortModel";

interface ContextProps {
    easyPorts: EasyPortModel[],
    setEasyPorts: Dispatch<SetStateAction<EasyPortModel[]>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    updatedEasyPort: boolean,
    updatedEasyPortUsers: boolean,
    isLoading: boolean,
    isLoadingFile: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleGetEasyPorts: () => any,
    handleDownloadEasyPortFile: (filePath: string) => any,
    handleCreateEasyPort: (payload: EasyPortModel, file: File) => Promise<any>,
    handleDisableEasyPort: (id: number) => Promise<any>,
}

export const EasyPortContext = createContext<ContextProps>({} as ContextProps);

export interface Loading {
    listSimulation: boolean,
    createCampaign: boolean,
    listEasyPorts: boolean,
}

interface EasyPortProviderProps {
    children: ReactNode;
}

export const EasyPortProvider = ({ children }: EasyPortProviderProps) => {
    const [easyPorts, setEasyPorts] = useState<any[]>([]);
    const [params, setParams] = useState<any>({});
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [updatedEasyPort, setUpdatedEasyPort] = useState<boolean>(false);
    const [updatedEasyPortUsers, setUpdatedEasyPortUsers] = useState<boolean>(false);
    const {isLoadingFile, setIsLoadingFile } = useReportRequest();

    const service = new EasyPortService();

    const handleGetEasyPorts = async () => {
        try {
            setIsLoading(true);
            setUpdatedEasyPort(false);
            const [_Response, _Error] = await service.getEasyPorts(params);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Error);
                return;
            }

            setEasyPorts(_Response?.data);
            setPages(_Response?.pages);
            setError('');

            return;

        } catch (error: any) {
            console.warn(error);
            setIsLoading(false);
            setEasyPorts([]);
            setError(error);
            return;
        }
    }

    const handleDownloadEasyPortFile = async (filePath: string) => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.handleDownloadEasyPortFile(filePath);

        if (_Error) {
            setIsLoadingFile(false);
            return false;
        }

        toast.success(_Response?.message || 'Baixando Excel!');
        setIsLoadingFile(false);
    };

    const handleCreateEasyPort = async (payload: EasyPortModel, file: File) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.save(payload, file);
            const { message } = _Response ?? {};

            if (_Error) {
                setIsLoading(false);
                setError(message || _Error);
                return false;
            }
            
            toast.success(_Response?.message || 'Simulação inserida. Aguarde o resultado !');
            
            handleGetEasyPorts().then();
            setIsLoading(false);
            setError('');
            return true;
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível Importar!');
            console.warn(e);
            return false;
        }
    };

    const handleDisableEasyPort = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.disable([id]);

            if (_Error) {
                setIsLoading(false);
                return setError(_Error);
            }
            
            handleGetEasyPorts().then();
            setIsLoading(false);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível desabilitar a campanha');
            return console.warn(e);
        }
    }

    return (
        <EasyPortContext.Provider value={{
            easyPorts,
            setEasyPorts,
            isLoading,
            isLoadingFile,
            updatedEasyPort,
            updatedEasyPortUsers,
            error,
            setError,
            params,
            setParams,
            pages,
            handleGetEasyPorts,
            handleDownloadEasyPortFile,
            handleCreateEasyPort,
            handleDisableEasyPort
        }}>
            {children}
        </EasyPortContext.Provider>
    )
}

export const useEasyPort = () => useContext(EasyPortContext);