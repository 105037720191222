import { FC } from 'react';
import './CardQuality.scss';
import { Card, Col, Row } from 'react-bootstrap';
import ChartBarPanel from '../../Import/ChartBarPanel/ChartBarPanel';
import { useProposalSituation } from '../../../../providers/Proposals/Import/ProposalSituationProvider';
import { colorCard } from '../../../../enums/Proposals/Import/ProposalColorsCardEnum';

interface CardQualityProps { }

const CardQuality: FC<CardQualityProps> = () => {

  const { situationsProcessedOptions } = useProposalSituation();

  const colors = colorCard

  situationsProcessedOptions?.forEach((item: any, index) => {
    item.color = colors.map((item: any) => item.color)[index] ? colors.map((item: any) => item.color)[index] : "#222343";
  })

  const color = colors.map((item: any) => item.color)

  const labels = situationsProcessedOptions?.map((item: any) => item?.label);

  return (
    <Card className="CardQuality" data-testid="CardQuality">
      <Card.Body>
        <Row>
          <Col md={12}>
            <ChartBarPanel
              data={{
                labels,
                datasets: [
                  {
                    data: situationsProcessedOptions?.map((item: any) => item?.qty),
                    backgroundColor: color,
                    borderRadius: 8,
                  }
                ],
              }}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                  },
                  datalabels: {
                    display: true,
                    color: '#333',
                    backgroundColor: '#fff',
                    formatter: (value: any) => {
                      return value.toLocaleString('pt-br', {})
                    },
                    borderRadius: 5,
                  }
                },
                scales: {
                  x: {
                    display: true,
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    grid: {
                      display: false,
                    },
                    min: 0,
                    max: Math.max.apply(null, situationsProcessedOptions?.map((item: any) => item?.qty)) < 80 ? Math.trunc(Math.max.apply(null, situationsProcessedOptions?.map((item: any) => item?.qty)) + 10) : 100,
                    ticks: {
                      stepSize: 20
                    },
                    border: {
                      display: false
                    }
                  },
                }
              }}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default CardQuality;
