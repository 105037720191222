import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './ModalVisits.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { Controller, useForm } from 'react-hook-form';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useAuth } from '../../../../providers/AuthProvider';
import { toast } from 'react-toastify';
import moment from 'moment';
import { VisitModel } from '../../../../models/Visits/IndicatorManagement/VisitModel';
import { useVisits } from '../../../../providers/Visits/VisitsProvider';
import ReactSelect from 'react-select';
import { useCommercial } from '../../../../providers/Registrations/User/UserCommercialProvider';
import { customStyles } from '../../../../models/SelectCustomStyles';
import InputSearchPartner from '../../../Registrations/Partner/InputSearchPartner/InputSearchPartner';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import DataModalVisits from '../DataModalVisits/DataModalVisits';
import { PartnerInputSearchModel } from '../../../../models/Partner/PartnerInputSearchModel';
import { schema } from './Validate';
import { yupResolver } from '@hookform/resolvers/yup';
interface ModalVisitsProps {
  show: boolean;
  onClose: any;
  visitEdit?: VisitModel;
  setVisitEdit?: Dispatch<SetStateAction<VisitModel | undefined>>
}

const ModalVisits: FC<ModalVisitsProps> = ({ show, onClose, visitEdit, setVisitEdit }) => {
  const [startDate, setStartDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
  const [valuePartner, setValuePartner] = useState<string>('');
  const [partnerObj, setPartnerObj] = useState<PartnerInputSearchModel | null>(null);
  const [visitsActions, setVisitsActions] = useState<any[]>([]);
  const [visitsTypesActivities, setVisitsTypesActivities] = useState<any[]>([]);
  const [observation, setObservation] = useState<string>('');

  const [selectedCommercial, setSelectedCommercial] = useState<any>();
  const [selectedVisitTypeContact, setSelectedVisitTypeContact] = useState<any>();
  const [selectedVisitsAttendance, setSelectedVisitsAttendance] = useState<any>();
  const [selectedProspecting, setSelectedProspecting] = useState<any | null>(null);

  const { getAttendance, getContactsTypes, attendanceOptions, contactsTypeOptions, params, isLoadingSave, handleSave, handleList } = useVisits();
  const { commercialsOptions, handleList: getCommercial } = useCommercial();

  const { onInvalid } = useAuth();

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    control,
    formState: { errors } } =
    useForm<any>({
      mode: 'onChange',
      resolver: yupResolver(schema)
    });

  useEffect(() => {
    if (show) {
      getAttendance().then();
      getContactsTypes().then();
      if (commercialsOptions?.length <= 0) {
        getCommercial();
      }
    } else {
      !!setVisitEdit && setVisitEdit(undefined);
      reset();
      setStartDate(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
      setValuePartner('');
      setPartnerObj(null);
      setVisitsActions([]);
      setVisitsTypesActivities([]);
      setObservation('');
      setSelectedCommercial(undefined);
      setSelectedVisitTypeContact(undefined);
      setSelectedVisitsAttendance([]);
    }
  }, [show]);

  useEffect(() => {
    if (attendanceOptions?.length > 0 && contactsTypeOptions?.length > 0 && commercialsOptions?.length > 0 && !!visitEdit) {
      if (!!visitEdit) {
        setValue('id', visitEdit.id);
        setValue('city', visitEdit.city);
        !!visitEdit.observation && setObservation(visitEdit.observation);
        setSelectedCommercial(commercialsOptions?.find((x: any) => x.value === visitEdit.userVisitorId));
        setValuePartner(visitEdit.userVisitedId?.toString() || '');
        setValue('userVisitedId', valuePartner);
        setStartDate(visitEdit.visitDate);
        setSelectedVisitTypeContact(contactsTypeOptions?.find((x: any) => x.value === visitEdit.visitTypeContactId));
        setSelectedVisitsAttendance(visitEdit.visitsAttendance);
        setVisitsActions(visitEdit.visitsActions || []);
        setVisitsTypesActivities(visitEdit.visitsTypesActivities || []);
        setSelectedProspecting({ value: visitEdit.partnerRegistrationProspecting?.id, label: visitEdit.partnerRegistrationProspecting?.name });
        setValue('partnerRegistrationProspectingId', visitEdit.partnerRegistrationProspecting?.id);
      }
    }
  }, [attendanceOptions, contactsTypeOptions, commercialsOptions, visitEdit]);

  useEffect(() => {
    if (commercialsOptions && commercialsOptions?.length > 0) {
      setSelectedCommercial(commercialsOptions.find((x: any) => x.value === Number(params.commercialId)));
    }
  }, [commercialsOptions]);

  useEffect(() => {
    setValue('visitsAttendance', selectedVisitsAttendance);
  }, [selectedVisitsAttendance]);

  useEffect(() => {
    setValue('userVisitorId', selectedCommercial?.value);
  }, [selectedCommercial]);

  useEffect(() => {
    setValue('visitTypeContactId', selectedVisitTypeContact?.value);
  }, [selectedVisitTypeContact]);

  useEffect(() => {
    if (!!partnerObj?.address && partnerObj?.address?.length > 0) {
      setValue('city', partnerObj?.address[0].city);
    }
  }, [partnerObj]);

  useEffect(() => {
    setValue('visitsActions', visitsActions);
  }, [visitsActions]);

  useEffect(() => {
    setValue('visitsTypesActivities', visitsTypesActivities);
  }, [visitsTypesActivities]);

  useEffect(() => {
    setValue('observation', observation);
  }, [observation]);

  const handleClose = () => {
    onClose();
  }

  const onSubmit = async (data: any) => {
    const _data = JSON.parse(JSON.stringify(data));
    _data.userVisitedId = partnerObj?.id;
    _data.userVisitorId = _data.userVisitorId?.value || _data.userVisitorId;
    _data.visitsAttendance = _data.visitsAttendance?.map((item: any) => item.value);
    _data.visitsActions = _data.visitsActions?.map((item: any) => Number(item));
    _data.visitsTypesActivities = _data.visitsTypesActivities?.map((item: any) => Number(item));
    const response = await handleSave(_data).then();

    if (response) {
      toast.success('Visita salva com sucesso!');
      handleList().then();
      onClose();
    }
  }

  return (
    <ModalDefault
      title={'Cadastro de Visita'}
      show={show}
      onClose={handleClose}
      sizeModal={'xl'}
      showFooter={true}
      buttonText={isLoadingSave ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoadingSave}
      disabledSubmit={isLoadingSave}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalVisits" data-testid="ModalVisits">
        <Row><strong>Dados da Visita:</strong></Row>
        <br />
        <Row>
          <Col md={6} sm={12}>
            <FormGroup className="form-group">
              <label htmlFor="userVisitorId">COMERCIAL / RESPONSÁVEL *</label>
              <Controller
                name="userVisitorId"
                control={control}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isClearable
                    isSearchable
                    options={commercialsOptions}
                    placeholder="Selecione..."
                    value={selectedCommercial}
                    className={`form-control p-0 ${onInvalid(errors?.userVisitorId)}`}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={(val) => {
                      setSelectedCommercial(val);
                    }}
                    styles={customStyles}
                    isDisabled
                  />
                )}
              />
              <ErrorMessage name="Comercial" type={errors?.userVisitorId?.type?.toString()} />
            </FormGroup>
          </Col>
          {!visitEdit || visitEdit?.userVisitedId &&
            <Col md={6} sm={12}>
              <FormGroup className="form-group">
                <label htmlFor="agente">PARCEIRO / VISITA *</label>
                <InputSearchPartner
                  name="userVisitedId"
                  control={control}
                  setValue={setValue}
                  valuePartner={valuePartner}
                  setValuePartner={setValuePartner}
                  setPartnerObj={setPartnerObj}
                  errors={errors}
                  disabled={!!visitEdit}
                />
              </FormGroup>
            </Col>}

          {visitEdit && !visitEdit?.userVisitedId &&
            <Col md={6} sm={12}>
              <FormGroup className='form-group'>
                <label htmlFor='partnerRegistrationProspectingId'>PROSPECÇÃO *</label>
                <input
                  type='text'
                  className='form-control'
                  value={selectedProspecting?.label}
                  disabled
                />
              </FormGroup>
            </Col>
          }

        </Row>

        <Row>
          <Col md={3} sm={12}>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              setValue={setValue}
              startLabelText='DATA DA VISITA *'
              nameStartDate='visitDate'
              viewEndDate={false}
              showStartDateClearButton={false}
            />
          </Col>
          <Col md={3} sm={12}>
            <FormGroup className="form-group">
              <label htmlFor="agente">CIDADE</label>
              <input
                type='text'
                className='form-control'
                {...register('city')}
                disabled
              />
            </FormGroup>
          </Col>
          <Col md={3} sm={12}>
            <FormGroup className="form-group">
              <label htmlFor="visitTypeContactId">CONTATO *</label>
              <Controller
                name="visitTypeContactId"
                control={control}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isClearable
                    isSearchable
                    options={contactsTypeOptions}
                    value={selectedVisitTypeContact}
                    placeholder="Selecione..."
                    className={`form-control p-0`}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={(val) => {
                      onChange(!!val ? val?.value : null);
                      setSelectedVisitTypeContact(val);
                    }}
                    styles={customStyles}
                  />
                )}
              />
              <ErrorMessage name="Contato" type={errors?.visitTypeContactId?.type?.toString()} />
            </FormGroup>
          </Col>
          <Col md={3} sm={12}>
            <FormGroup className="form-group">
              <label htmlFor="visitsAttendance">PRESENÇA *</label>
              <Controller
                name="visitsAttendance"
                control={control}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isClearable
                    isMulti
                    isSearchable
                    value={selectedVisitsAttendance}
                    options={attendanceOptions}
                    placeholder="Selecione..."
                    className={`form-control p-0`}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={(val) => {
                      onChange(!!val ? val : null);
                      setSelectedVisitsAttendance(val);
                    }}
                    styles={customStyles}
                  />
                )}
              />
              <ErrorMessage name="Presença" isArray={true} min={1} type={errors?.visitsAttendance?.type?.toString()} />
            </FormGroup>
          </Col>
        </Row>

        <DataModalVisits
          action={'add'}
          typeAction={visitsActions}
          setTypeAction={setVisitsActions}
          typeActivitie={visitsTypesActivities}
          setTypeActivitie={setVisitsTypesActivities}
          observation={observation}
          setObservation={setObservation}
          typeActionName='visitsActions'
          typeActivitieName='visitsTypesActivities'
          observationName='observation'
          errors={errors}
        />
      </div >
    </ModalDefault >
  );

}

export default ModalVisits;
