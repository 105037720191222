import React, { FC, useEffect } from 'react';
import './SidebarCovenantFilter.scss';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import { Controller, useForm } from 'react-hook-form';
import { CovenantParamsModel } from '../../../../models/Registrations/Covenant/CovenantParamsModel';
import { Col, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { useCovenantGroup } from '../../../../providers/Registrations/Covenant/CovenantGroupProvider';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { yesNoOption } from '../../../../models/YesNoOptions';
import { useCovenant } from '../../../../providers/Registrations/Covenant/CovenantProvider';

interface SidebarCovenantFilterProps { }

const activeInactiveOptions = [
  { value: 'S', label: 'Ativos' },
  { value: 'N', label: 'Inativos' },
]

const SidebarCovenantFilter: FC<SidebarCovenantFilterProps> = () => {
  const { handleSubmit, register, control } = useForm<CovenantParamsModel>();
  const { setParams } = useCovenant();
  const { handleList: handleListCovenantGroup, covenantGroupsOptions } = useCovenantGroup();
  const animatedComponents = makeAnimated();

  useEffect(() => {
    handleListCovenantGroup().then();
  }, []);

  const onSubmit = async (data: any) => {
    const _data = { ...data };
    _data.proposalsGroupId = _data?.proposalsGroupId?.length > 0 ? _data?.proposalsGroupId?.map((x: any) => x.value) : undefined;
    _data.national = _data?.national?.value && (_data?.national?.value === 'S' ? 1 : 0);
    _data.typing = _data?.typing?.value;
    _data.active = _data?.active?.value;

    setParams(_data);
  }

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className='SidebarCovenantFilter' data-testid="SidebarCovenantFilter">
        <Row>
          <Col xs={12} className='form-group'>
            <label>Nome</label>
            <input
              className='form-control'
              type='text'
              {...register('name')}
            />
          </Col>
          <Col xs={12} className='form-group'>
            <label>Grupo</label>
            <Controller
              control={control}
              name='proposalsGroupId'
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  isMulti
                  isSearchable
                  placeholder="Selecione..."
                  options={covenantGroupsOptions}
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                  onChange={(val: any) => {
                    onChange(val ?? null);
                  }}
                />
              )}
            />
          </Col>
          <Col xs={12} className='form-group'>
            <label>Liberado Digitação</label>
            <Controller
              control={control}
              name='typing'
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  placeholder="Selecione..."
                  options={yesNoOption}
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                  onChange={(val: any) => {
                    onChange(val ?? null);
                  }}
                />
              )}
            />
          </Col>
          <Col xs={12} className='form-group'>
            <label>Nacional</label>
            <Controller
              control={control}
              name='national'
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  placeholder="Selecione..."
                  options={yesNoOption}
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                  onChange={(val: any) => {
                    onChange(val ?? null);
                  }}
                />
              )}
            />
          </Col>
          <Col xs={12} className='form-group'>
            <label>Situação</label>
            <Controller
              control={control}
              name='active'
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  placeholder="Selecione..."
                  options={activeInactiveOptions}
                  className={`form-control p-0`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  styles={customStyles}
                  onChange={(val: any) => {
                    onChange(val ?? null);
                  }}
                />
              )}
            />
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  )
};

export default SidebarCovenantFilter;
