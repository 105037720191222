import { FC, useEffect, useState } from 'react';
import './ModalAccessApplicationInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useAccessApplication } from '../../../../providers/Registrations/Access/AccessApplicationProvider';
import { useForm } from 'react-hook-form';
import {
    Button,
    Card,
    Col,
    FormCheck,
    FormGroup,
    ListGroup,
    OverlayTrigger,
    Row,
    Tooltip
} from 'react-bootstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { AccessPageModel } from '../../../../models/Registrations/Access/AccessPageModel';
import Swal from 'sweetalert2';
import { toast } from "react-toastify";
import icons from '../../../../data/fontAwesome5.json';
import iconsUnicode from '../../../../data/fontAwesome5Unicode.json';
import PopoverOrderPage from '../PopoverOrderPage/PopoverOrderPage';
import ButtonEdit from '../../../ButtonEdit/ButtonEdit';
import ButtonDelete from '../../../ButtonDelete/ButtonDelete';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useAuth } from '../../../../providers/AuthProvider';
import ModalAccessActionInsert from '../ModalAccessActionInsert/ModalAccessActionInsert';
import ModalAccessPageCollapseInsert from '../ModalAccessPageCollapseInsert/ModalAccessPageCollapseInsert';

interface ModalAccessApplicationInsertProps {
  show: boolean;
  onClose?: any;
}

interface InputsPage {
  id?: string;
  name?: string;
  icon?: string;
  path?: string;
  collapse?: string | boolean;
  state?: string;
  type?: string;
  showOnMenu?: string | boolean;
}

const ModalAccessApplicationInsert: FC<ModalAccessApplicationInsertProps> = ({ show, onClose }) => {
  const [isEditView, setIsEditView] = useState<boolean>(false);
  const [iconsFiltered, setIconsFiltered] = useState<any[]>([]);
  const [isCollapse, setIsCollapse] = useState<boolean>();
  const [pageActions, setPageActions] = useState<AccessPageModel | null>(null);
  const [showModalActionInsert, setShowModalActionInsert] = useState<boolean>(false);
  const [showModalPageInsert, setShowModalPageInsert] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [icon, setIcon] = useState<string>('');
  const [withCollapse, setWithCollapse] = useState<boolean>(true);
  const [pageName, setPageName] = useState<string>('');
  const [isRequiredUrl, setIsRequiredUrl] = useState<boolean>(true);

  const { page, pages, setPage, handleSave, error, isLoading, updateOrders, handleDelete } = useAccessApplication();
  const { onInvalid } = useAuth();

  const { reset, register, setValue, handleSubmit, formState: { errors } } = useForm<InputsPage>();

  const submit = async (data: InputsPage) => {
      const errors: string[] = [];
      if (isCollapse) {
          page?.views?.forEach((v: AccessPageModel) => {
              if (!v.collapse && (!v.actionsPage || v.actionsPage?.length <= 0)) {
                  errors.push(v.name || '');
              }

              v?.views?.forEach((i: AccessPageModel) => {
                  if (!i.actionsPage || i.actionsPage?.length <= 0) {
                      errors.push(i.name || '');
                  }
              });
          });
      } else {
          if (page.actionsPage.length <= 0) {
              errors.push(data.name || '');
          }
      }

    if (errors.length <= 0) {
        const dataAux = Object.assign({}, data);
        dataAux.collapse = data.collapse === 'S';
        dataAux.state = `${dataAux.path}Collapse`
        dataAux.path = dataAux.path === '' ? dataAux.path : `/${dataAux.path}`;
        dataAux.type = "mobile";
        dataAux.showOnMenu = data.showOnMenu === 'S';

        if (!dataAux?.collapse) {
            page.views = [];
            await handleSave({
                ...dataAux,
                actions: page.actions,
                order: page.order ? page.order : pages.length + 1
            });
        } else {
            page.actions = [];
            page.actionsPage = [];
            await handleSave({ ...dataAux, views: page.views, order: page.order ? page.order : pages.length + 1 });
        }
        if (error) {
            await Swal.fire('Ops!', `Houve ou erro ao salvar a página.<br>${error}`, 'error');
        } else {
            toast.success('Página salva com sucesso!');
            onClose();
        }
    } else {
        let msg = 'As páginas devem ter ao menos uma ação.<br/><br/>A(s) página(s) está(ão) sem ação:<br>';
        errors.forEach((x: string) => msg += `<br>${x}`);
        Swal.fire('Atenção', msg, 'error');
    }
  }

  const handleOnDragEnd = async (result: any) => {
      if (!result.destination) {
          return;
      }
      const items: AccessPageModel[] = Array.from(page?.views) as AccessPageModel[];
      await updateOrders(items, result);

      setPage({ ...page, views: items });
  }

  const changeIsCollapse = (action: boolean) => {
      if (action) {
          if (page?.actionsPage?.length > 0) {
              Swal.fire({
                  title: 'Atenção',
                  html: `Tornando a página COLLAPSE, as ações dessa página serão perdidas.<br>Deseja continuar?`,
                  icon: 'question',
                  showConfirmButton: true,
                  showCancelButton: true,
                  confirmButtonText: 'Sim',
                  cancelButtonText: 'Não'
              }).then(async (result) => {
                  if (result.isConfirmed) {
                      await handleDelete(page.id, false, true);
                      if (!error) {
                          setPage({ ...page, actions: [], actionsPage: [] });
                          setIsCollapse(true);
                      } else {
                          toast.error(`Não foi possível remover as ações!<br/>${error}`);
                      }
                  } else {
                      setIsCollapse(false);
                      setValue('collapse', 'N');
                  }
              });
          } else {
              setIsCollapse(true);
          }
      } else {
          if (page?.views?.length > 0) {
              Swal.fire({
                  title: 'Atenção',
                  html: `Tornando a página como NÃO COLLAPSE, as páginas filhas dessa página serão perdidas.<br>Deseja continuar?`,
                  icon: 'question',
                  showConfirmButton: true,
                  showCancelButton: true,
                  confirmButtonText: 'Sim',
                  cancelButtonText: 'Não'
              }).then(async (result) => {
                  if (result.isConfirmed) {
                      await handleDelete(page.id, true, false);
                      if (!error) {
                          setPage({ ...page, views: [] });
                          setIsCollapse(false);
                      } else {
                          toast.error(`Não foi possível remover as páginas!<br/>${error}`);
                      }
                  } else {
                      setIsCollapse(true);
                      setValue('collapse', 'S');
                  }
              });
          } else {
              setIsCollapse(false);
          }
      }
  }

  const deleteView = (view: AccessPageModel, index: number) => {
      Swal.fire({
          title: 'Atenção',
          html: `Deseja realmente excluir a página ${view.name}?`,
          icon: 'question',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Excluir',
          cancelButtonText: 'Cancelar'
      }).then(async (result) => {
          if (result.isConfirmed) {
              if (view.id) {
                  await handleDelete(view.id);
              }

              if (!error) {
                  const viewsAux = page.views;
                  viewsAux.splice(index, 1);
                  setPage({ ...page, views: viewsAux });
                  toast.success('Registro removido com sucesso!');
              } else {
                  toast.success(`Não foi possível remover a página.<br>${error}`);
              }
          }
      });
  }

  const showActions = (view: AccessPageModel, name: string) => {
      setPageActions(view);
      setPageName(name);
      setShowModalActionInsert(true);
  }

  const showPages = (view: AccessPageModel, withCollapse: boolean, isEdit: boolean = false) => {
      setWithCollapse(withCollapse);
      setIsEditView(isEdit);
      setPageActions(view);
      setShowModalPageInsert(true);
  }

  const renderViews = () => {    
      return (
          <>
            {page?.views && page?.views?.length > 0 ? (
                <>
                    {page?.views?.map(((item: AccessPageModel, key: number) => (
                        <>
                            <tr className='text-sm'>
                                <td className='text-center'>
                                    <PopoverOrderPage page={item} isView={true} />
                                </td>
                                <td>{item.name}</td>
                                <td>{item.collapse ? item.path : item.layout}</td>
                                <td>{item.showOnMenu ? 'Sim' : 'Não'}</td>
                                <td className='d-flex justify-content-center gap-3'>
                                    <ButtonEdit
                                        tooltipText='Editar página'
                                        onClick={() => showPages(item, false, true)}
                                    />

                                    {(item?.collapse) ? (
                                        <OverlayTrigger
                                            placement={'top'}
                                            overlay={
                                                <Tooltip>
                                                    Páginas
                                                </Tooltip>
                                            }
                                        >
                                            <div role="button" tabIndex={0}
                                                onClick={() => showPages(item, false)}>
                                                <i className={`fas fa-square-plus`}
                                                    style={{ color: 'var(--icon)' }} />
                                            </div>
                                        </OverlayTrigger>
                                    ) : (
                                        <OverlayTrigger
                                            placement={'top'}
                                            overlay={
                                                <Tooltip>
                                                    Ações
                                                </Tooltip>
                                            }
                                        >
                                            <div role="button" tabIndex={0}
                                                onClick={() => showActions(item, item?.name || '')}>
                                                <i className={`fas fa-cubes`}
                                                    style={{ color: 'var(--icon)' }} />
                                            </div>
                                        </OverlayTrigger>
                                    )}

                                    <ButtonDelete
                                        tooltipText='Deletar página'
                                        onClick={() => deleteView(item, key)}
                                    />
                                </td>
                            </tr>

                            {item?.views && item?.views?.length > 0 && (
                                <>{renderSubViews(item?.views || [])}</>
                            )}
                        </>
                    )))}
                </>
            ) : (
                <tr>
                    <td colSpan={4} className="text-center text-sm">Não há registros</td>
                </tr>
            )}
        </>
      )
  }

  const renderSubViews = (views: AccessPageModel[]) => {
      return (
          <>
              {views?.map((item: AccessPageModel, key: number) => (
                  <tr className='text-sm'>
                      <td className='text-center'>
                          <PopoverOrderPage page={item} isView={true} />
                      </td>
                      <td>{item.name}</td>
                      <td>{item.collapse ? item.path : item.layout}</td>
                      <td className='d-flex justify-content-center gap-3'>
                          <ButtonEdit
                              tooltipText='Editar página'
                              onClick={() => showPages(item, false, true)}
                          />

                          <OverlayTrigger
                              placement={'top'}
                              overlay={
                                  <Tooltip>
                                      Ações
                                  </Tooltip>
                              }
                          >
                              <div role="button" tabIndex={0} onClick={() => showActions(item, item?.name || '')}>
                                  <i className={`fas fa-cubes`} style={{ color: 'var(--icon)' }} />
                              </div>
                          </OverlayTrigger>

                          <ButtonDelete
                              tooltipText='Deletar página'
                              onClick={() => deleteView(item, key)}
                          />
                      </td>
                  </tr>
              ))}
          </>
      );
  }

  useEffect(() => {
      if (show) {
          setWithCollapse(true);
          setPageName('');
          setName('');

          if (page?.id) {
              reset(page);
              setValue('path', page?.path?.replace?.('/', ''));
              setValue('collapse', page?.collapse ? 'S' : 'N');
              setValue('showOnMenu', page?.showOnMenu ? 'S' : 'N')
              setPage({
                  ...page,
                  views: page.views?.sort((a: AccessPageModel, b: AccessPageModel) => (a.order || 0) < (b.order || 0) ? -1 : 1)
              });
              setIsCollapse(page.collapse);
              setIsRequiredUrl(!page.collapse)
              setName(page.name);
              setIcon(page?.icon || '');
          } else {
              setPage(new AccessPageModel());
          }
      } else {
          reset();
          setValue('id', '');
          setValue('name', '');
          setValue('path', '');
          setValue('icon', '');
          setValue('state', '');
          setValue('collapse', '');
          setValue('showOnMenu', '');
          setPage(new AccessPageModel());
      }
  }, [show]);

  useEffect(() => {
      const aux = iconsUnicode.filter(x => icons.find(y => ('fas fa-' + x.name) === y.name))
          .map(x => {
              const i = icons.find(y => ('fas fa-' + x.name) === y.name);
              return { ...x, title: i?.title }
          });
      setIconsFiltered(aux);
  }, []);

  return (
      <ModalDefault
          title={page?.id ? 'Editar página aplicativo' : 'Cadastrar uma nova página do aplicativo'}
          show={show}
          onClose={() => {
              setPage(undefined);
              onClose();
          }}
          sizeModal="xl"
          showFooter={true}
          handleSubmit={handleSubmit(submit)}
          disabledSubmit={isLoading}
          buttonText={isLoading ? "Salvando página..." : "Salvar"}
          backdrop="static"
      >
          <div className="ModalAccessApplicationInsert" data-testid="ModalAccessApplicationInsert">
              <Row>
                  <Col md={8}>
                      <Row>
                          <Col md={2}>
                              <FormGroup className="form-group">
                                  <label>ID</label>
                                  <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      {...register('id')}
                                  />
                              </FormGroup>
                          </Col>
                          <Col md={10}>
                              <FormGroup className="form-group">
                                  <label>NOME *</label>
                                  <input
                                      placeholder="Ex: Cadastros"
                                      className={`form-control ${onInvalid(errors?.name)}`}
                                      type="text"
                                      {...register('name', { required: true, minLength: 3, maxLength: 50 })}
                                      onChange={(e: any) => setName(e.target.value)}
                                  />
                                  <ErrorMessage name={'Nome'} type={errors?.name?.type} min={3} max={50} />
                              </FormGroup>
                          </Col>
                      </Row>
                      <Row>
                          <Col md={5}>
                              <FormGroup className="form-group">
                                  <label>URL *</label>
                                  <div className="input-group">
                                      <span
                                          style={{ paddingRight: 7 }}
                                          className={`input-group-text ${onInvalid(errors?.path)}`}
                                      >
                                          /
                                      </span>
                                      <input
                                          placeholder=" Ex: /registrations"
                                          className={`form-control ${onInvalid(errors?.path)}`}
                                          type="text"
                                          {...register('path', { required: isRequiredUrl, minLength: isRequiredUrl ? 3 : 0, maxLength: 50 })}
                                      />
                                  </div>
                                  <ErrorMessage name={'URL'} type={errors?.path?.type} min={3} max={50} />
                              </FormGroup>
                          </Col>
                          <Col md={4}>
                              <FormGroup className="form-group">
                                  <label>ÍCONE *</label>
                                  <input
                                      placeholder=""
                                      className={`form-control ${onInvalid(errors?.icon)}`}
                                      type="text"
                                      {...register('icon', { required: true, minLength: 3, maxLength: 50 })}
                                      onChange={(e: any) => setIcon(e.target.value)}
                                  />
                                  <ErrorMessage name={'Ícone'} type={errors?.icon?.type} min={3} max={50} />
                              </FormGroup>
                          </Col>
                          <Col md={3}>
                              <FormGroup className="form-group">
                                  <label>COLLAPSE *</label>
                                  <br />
                                  <FormCheck className="form-check" inline>
                                      <input
                                          className={`form-check-input ${onInvalid(errors?.collapse)}`}
                                          type='radio'
                                          id="cS"
                                          value="S"
                                          {...register('collapse')}
                                          onChange={(e) => {
                                              changeIsCollapse(true);
                                              setIsRequiredUrl(false);
                                          }}
                                      />
                                      <label className="form-check-label" htmlFor="cS">Sim</label>
                                  </FormCheck>
                                  <FormCheck className="form-check" inline>
                                      <input
                                          className={`form-check-input ${onInvalid(errors?.collapse)}`}
                                          type='radio'
                                          id="cN"
                                          value="N"
                                          {...register('collapse', { required: true })}
                                          onChange={(e) => {
                                              changeIsCollapse(false);
                                              setIsRequiredUrl(true);
                                          }}
                                      />
                                      <label className="form-check-label" htmlFor="cN">Não</label>
                                  </FormCheck>
                                  <ErrorMessage name={'Collapse'} type={errors?.collapse?.type} />
                              </FormGroup>
                          </Col>

                          <Col md={3}>
                            <FormGroup className="form-group">
                                <label>MOSTRAR NO MENU *</label>
                                <br />
                                <FormCheck className="form-check" inline>
                                    <input
                                        className={`form-check-input ${onInvalid(errors?.showOnMenu)}`}
                                        type='radio'
                                        id="cvS"
                                        value="S"
                                        {...register('showOnMenu', { required: true })}
                                    />
                                    <label className="form-check-label" htmlFor="cvS">Sim</label>
                                </FormCheck>
                                <FormCheck className="form-check" inline>
                                    <input
                                        className={`form-check-input ${onInvalid(errors?.showOnMenu)}`}
                                        type='radio'
                                        id="cvN"
                                        value="N"
                                        {...register('showOnMenu', { required: true })}
                                    />
                                    <label className="form-check-label" htmlFor="cvN">Não</label>
                                </FormCheck>
                                <ErrorMessage name={'Mostrar no menu'} type={errors?.showOnMenu?.type} />
                            </FormGroup>
                          </Col>
                      </Row>

                      {!isCollapse ? (
                          <Row>
                              <Col md={12} className="text-end">
                                  <button
                                      type="button"
                                      className="btn btn-primary btn-sm"
                                      onClick={() => showActions(page, name)}
                                      disabled={!name}
                                  >
                                      Gerenciar ações
                                  </button>
                              </Col>
                          </Row>
                      ) : (
                          <Row className="mt-2">
                              <Col md={12}>
                                  <Card>
                                      <Card.Header>
                                          <div className="d-flex align-items-center justify-content-between">
                                              <span>Páginas</span>

                                              <Button className="btn btn-primary btn-xs m-0"
                                                  onClick={() => showPages(page, true)}>Adicionar Página</Button>
                                          </div>
                                      </Card.Header>
                                      <Card.Body>
                                          <Row>
                                              <div className="table-responsive">
                                                  <table className="table table-flush m-0 w-100">
                                                      <thead className="thead-light">
                                                          <tr>
                                                              <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7 text-center"
                                                                  scope="col" style={{ width: '10%' }}>ORDEM
                                                              </th>
                                                              <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7"
                                                                  scope="col" style={{ width: '30%' }}>NOME
                                                              </th>
                                                              <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7"
                                                                  scope="col" style={{ width: '25%' }}>URL
                                                              </th>
                                                              <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7"
                                                                  scope="col" style={{ width: '25%' }}>MENU
                                                              </th>
                                                              <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7 text-center"
                                                                  scope="col" style={{ width: '25%' }}>AÇÃO
                                                              </th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          {renderViews()}
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </Row>
                                      </Card.Body>
                                  </Card>
                              </Col>
                          </Row>
                      )}

                  </Col>
                  <Col md={4}>
                      <div className="menu-preview">
                          <Card>
                              <Card.Header>
                                  <div>
                                      Prévia do Menu
                                  </div>
                              </Card.Header>
                              <Card.Body className="pt-0">
                                  {name ? (
                                      <div className="navbar-collapse w-auto h-auto" id="sidenav-collapse-main">
                                          <ListGroup className="navbar-nav">
                                              {!isCollapse ? (
                                                  <li className="nav-item">
                                                      <div
                                                          className="nav-link text-wrap d-flex align-items-center py-1">
                                                          {(page?.icon || icon) && (
                                                              <div
                                                                  className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                                                  <i className={`${page?.icon || icon || ''} text-secondary text-sm opacity-10`}></i>
                                                              </div>
                                                          )}
                                                          <span
                                                              className="nav-link-text ms-1">{page?.name || name || ''}</span>
                                                      </div>
                                                  </li>
                                              ) : (
                                                  <DragDropContext onDragEnd={handleOnDragEnd}>
                                                      <Droppable droppableId="pages">
                                                          {(provided) => (
                                                              <div {...provided.droppableProps} ref={provided.innerRef}>
                                                                  <div
                                                                      className="nav-link text-wrap d-flex align-items-center py-1"
                                                                      aria-controls={`${name}Collapse`}
                                                                  >
                                                                      {page?.icon && (
                                                                          <div
                                                                              className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
                                                                          >
                                                                              <i className={`${icon || ''} text-secondary text-sm opacity-10`} />
                                                                          </div>
                                                                      )}
                                                                      <span className="nav-link-text ms-1">{name || ''}</span>
                                                                  </div>

                                                                  {(page?.views && page?.views?.length > 0) && (
                                                                      <>
                                                                          {page?.views?.map((item: AccessPageModel, key: number) => (
                                                                              <Draggable key={item.id || key} draggableId={item.id?.toString() || key?.toString() || ''} index={key}>
                                                                                  {(provided, snapshot) => (
                                                                                      <div className="ps-5" key={key}>
                                                                                          <div
                                                                                              className={`nav-link text-wrap d-flex align-items-center py-2 ${snapshot.isDragging ? 'background' : ''}`}
                                                                                              aria-controls={item?.state || ''}
                                                                                              ref={provided.innerRef}
                                                                                              {...provided.draggableProps}
                                                                                          >
                                                                                              <div
                                                                                                  className='text-center'
                                                                                                  {...provided.dragHandleProps}
                                                                                              >
                                                                                                  <i className='fas fa-grip-vertical'></i>
                                                                                              </div>

                                                                                              {item?.icon && (
                                                                                                  <div
                                                                                                      className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
                                                                                                  >
                                                                                                      <i className={`${item?.icon || ''} text-secondary text-sm opacity-10`} />
                                                                                                  </div>
                                                                                              )}
                                                                                              <span
                                                                                                  className="nav-link-text ms-2">{item?.name || ''}</span>
                                                                                          </div>

                                                                                          <ListGroup className="nav ms-4"
                                                                                              as="ul">
                                                                                              {item?.views?.map((view: any, key2: number) => {
                                                                                                  return (
                                                                                                      <li
                                                                                                          className="nav-item"
                                                                                                          key={key2}
                                                                                                      >
                                                                                                          <div
                                                                                                              className="nav-link text-wrap d-flex align-items-center py-1 px-0">
                                                                                                              {view?.icon && (
                                                                                                                  <div
                                                                                                                      className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
                                                                                                                  >
                                                                                                                      <i className={`${view?.icon || ''} text-secondary text-sm opacity-10`} />
                                                                                                                  </div>
                                                                                                              )}
                                                                                                              <span
                                                                                                                  className="nav-link-text sidenav-normal ms-1"> {view?.name} </span>
                                                                                                          </div>
                                                                                                      </li>
                                                                                                  );
                                                                                              })}
                                                                                          </ListGroup>
                                                                                      </div>
                                                                                  )}
                                                                              </Draggable>
                                                                          ))}
                                                                      </>
                                                                  )}

                                                                  {provided.placeholder}
                                                              </div>
                                                          )}
                                                      </Droppable>
                                                  </DragDropContext>
                                              )}
                                          </ListGroup>
                                      </div>
                                  ) : (
                                      <p className="text-sm text-center">Insira o nome da página principal</p>
                                  )}
                              </Card.Body>
                          </Card>
                      </div>
                  </Col>
              </Row>
          </div>

          <ModalAccessActionInsert
              show={showModalActionInsert}
              onClose={() => setShowModalActionInsert(false)}
              pageView={pageActions || {}}
              pageName={pageName}
          />

          <ModalAccessPageCollapseInsert
              show={showModalPageInsert}
              onClose={() => setShowModalPageInsert(false)}
              pageView={pageActions || {}}
              setPageView={setPageActions}
              isCollapse={isCollapse}
              withCollapse={false}
              isEdit={isEditView}
              withRadioShowOnMenu={true}
          />
      </ModalDefault >
  );
}
export default ModalAccessApplicationInsert;
