import api from "../Api";
import { BaseService } from "../BaseService";

const URL = 'api/terms';

export class TermsTrackingService extends BaseService {

    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listStatus(): Promise<any> {
        try {
            return this.handleResponse(await api.get(`${URL}/get/status`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listDepartments(): Promise<any> {
        try {
           return this.handleResponse(await api.get(`${URL}/departments/list`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    } 

    async export(data:any): Promise<any> {
        try {
           return this.handleResponse(await api.post(`${URL}/export`, data));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    } 

    async listSeeHistory(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/history/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listSignature(termId: number): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/signature/${termId}`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}