export const ProposalIconsCardEnum = [
    { icon: "fa-solid fa-file-arrow-up text-lg opacity-10" },
    { icon: "fa-solid fa-file-arrow-down text-lg opacity-10" },
    { icon: "fa-solid fa-file-arrow-down text-lg opacity-10" }
]

export const ProposalIconsCardStyleEnum = [
    { icon: "icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle" },
    { icon: "icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle" },
    { icon: "icon icon-shape bg-gradient-success shadow-success text-center rounded-circle" }
]


export const ProposalIconsFileEnum = [
    { icon: "fa-solid fa-file-circle-exclamation " },
    { icon: "fa-solid fa-file-circle-xmark " },
    { icon: "fa-solid fa-file-circle-check " }
]

export const ProposalIconsStyleFileEnum = [
    { icon: "icon icon-shape bg-gradient-primary text-center rounded-circle" },
    { icon: "icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle" },
    { icon: "icon icon-shape bg-gradient-success shadow-success text-center rounded-circle" }
]