import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState, useCallback, useEffect, useMemo } from "react";
import { IndicatorManagementQualityModel, IndicatorManagementQualityListModel } from "../../../models/Visits/IndicatorManagement/IndicatorManagementQualityModel";
import { VisitsActionsParams } from "../../../models/Visits/VisitsActions/VisitsActionsParams";
import { IndicatorManagementQualityService } from "../../../services/Visits/IndicatorManagement/IndicatorManagementQualityService";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { useIndicatorManagementWalletVisits } from "../IndicatorManagement/IndicatorManagementWalletProvider";
import { FormataStringCurrencyToNumber } from "../../../utils/FormataStringCurrencyToNumber";
import { useReportRequest } from "../../Reports/ReportRequest/ReportRequestProvider";

interface ContextProps {
    qualityList: IndicatorManagementQualityModel | undefined,
    setQualityList: Dispatch<SetStateAction<IndicatorManagementQualityModel | undefined>>,
    isLoading: boolean,
    error: string,
    isLoadingFile: boolean,
    setError: Dispatch<SetStateAction<string>>,
    params: VisitsActionsParams,
    setParams: Dispatch<SetStateAction<VisitsActionsParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    handleGetQuality: () => Promise<void>
    handleGetExcel: (params: VisitsActionsParams) => Promise<any>
}

export const IndicatorManagementQualityContext = createContext<ContextProps>({} as ContextProps);

interface IndicatorManagementQualityProviderProps {
    children: ReactNode;
}

export const IndicatorManagementQualityProvider = ({ children }: IndicatorManagementQualityProviderProps) => {
    const [qualityList, setQualityList] = useState<IndicatorManagementQualityModel>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [params, setParams] = useState<VisitsActionsParams>({});
    const [error, setError] = useState<string>('');
    const [pages, setPages] = useState<PagesPaginateModel>({});

    const { isLoadingFile, setIsLoadingFile } = useReportRequest();

    const { params: paramsWallet } = useIndicatorManagementWalletVisits();

    const service = new IndicatorManagementQualityService();

    useEffect(() => {
        setParams(paramsWallet);
    }, [paramsWallet]);

    const handleGetQuality = useCallback(async () => {
        try {
            setIsLoading(true);

            const [_Response, _Error] = await service.getQualityList(params);

            if (_Error) {
                setIsLoading(false);
                setError(_Error);
                return;
            }

            setQualityList({
                ..._Response?.data, claims:
                    _Response?.data?.claims?.map((x: IndicatorManagementQualityListModel) => ({
                        ...x,
                        validComplaintPercentage: FormataStringCurrencyToNumber(x.validComplaintPercentage),
                        entryComplaintPercentage: FormataStringCurrencyToNumber(x.entryComplaintPercentage),
                        unansweredComplaintPercentage: FormataStringCurrencyToNumber(x.unansweredComplaintPercentage),
                        complaintUnansweredPercent0: FormataStringCurrencyToNumber(x.complaintUnansweredPercent0),
                        complaintUnansweredPercent1: FormataStringCurrencyToNumber(x.complaintUnansweredPercent1),
                        complaintUnansweredPercent2: FormataStringCurrencyToNumber(x.complaintUnansweredPercent2)
                    }))

            });
            setPages(_Response?.pages);
            setIsLoading(false);
            setError('');

            return;

        } catch (error: any) {
            setIsLoading(false);
            setError(error);
            return;
        }
    }, [params]);

    const handleGetExcel = async (params: VisitsActionsParams) => {
        try {

            setIsLoadingFile(true);

            const [_Response, _Error] = await service.getExcel(params);

            if (_Error) {
                setIsLoadingFile(false);
                setError(_Error);
                return false;
            }

            setIsLoadingFile(false);
            setError('');

            return true;

        } catch (error: any) {
            setIsLoadingFile(false);
            setError(error);
            return false;
        }
    }

    return (
        <IndicatorManagementQualityContext.Provider value={useMemo(() => ({
            qualityList,
            setQualityList,
            isLoading,
            error,
            isLoadingFile,
            setError,
            params,
            setParams,
            pages,
            setPages,
            handleGetQuality,
            handleGetExcel
        }), [
            qualityList,
            setQualityList,
            isLoading,
            error,
            isLoadingFile,
            setError,
            params,
            setParams,
            pages,
            setPages,
            handleGetQuality,
            handleGetExcel
        ])}>
            {children}
        </IndicatorManagementQualityContext.Provider>
    )
}

export const useIndicatorManagementQuality = () => useContext(IndicatorManagementQualityContext);