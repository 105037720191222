import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";

interface ContextProps {
    showInformative: boolean,
    setShowInformative: Dispatch<SetStateAction<boolean>>,
}

export const InformativeContext = createContext<ContextProps>({} as ContextProps);

interface InformativeProviderProps {
    children: ReactNode,
}

export const InformativeProvider = ({ children }: InformativeProviderProps) => {
    const [showInformative, setShowInformative] = useState<boolean>(false);

    return (
        <InformativeContext.Provider value={{
            showInformative,
            setShowInformative
        }}>
            {children}
        </InformativeContext.Provider>
    );
};

export const useInformative = () => useContext(InformativeContext);