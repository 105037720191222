import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import './ModalOpportunityDetails.scss';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import opportunities from "../../../../pages/Marketplace/Opportunities/Opportunities";
import {useOpportunity} from "../../../../providers/Marketplace/Opportunities/OpportunityProvider";
import {toast} from "react-toastify";
import {OpportunityModel} from "../../../../models/Marketplace/Opportunity/OpportunityModel";
import {CampaignModel} from "../../../../models/Marketplace/Campaign/CampaignModel";
import AppPagination from "../../../AppPagination/AppPagination";
import SkeletonTable from "../../../SkeletonTable/SkeletonTable";
import {CampaignStatusEnum} from "../../../../enums/Marktplace/Campaigns/CampaignStatusEnum";
import {dateFormat} from "../../../../utils/FormatDate";
import {Button, Card, Col, Row} from "react-bootstrap";

interface ModalOpportunityDetailsProps {
    show: boolean,
    onClose: any,
    opportunityNumber?: number | null,
    setOpportunityNumber:  Dispatch<SetStateAction<number | null>>
}

function capitalizeString(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const ModalOpportunityDetails: FC<ModalOpportunityDetailsProps> = ({ opportunityNumber, setOpportunityNumber, onClose, show }) => {
    const {
        handleGetOpportunities,
        handleGetOpportunity,
        opportunities,
        opportunity,
        error,
        setError,
        isLoading,
        pages,
        params,
        setParams
    } = useOpportunity();

    useEffect(() => {
        if (!!error) {
            toast.error(error);
            setError('');
        }
    }, [error]);

    const renderContent = (): JSX.Element => {
        return (
            <>
                {!isLoading ? (
                    <div >
                        <Row>
                            <Col md={7}>
                                <Card className="h-100">
                                    <Card.Body className="h-card">
                                        <div className="d-flex flex-column h-100">
                                            <b className="text-uppercase">Cliente</b>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Nome</b>: {opportunity?.client_name?.toString().toLowerCase()}</a>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Cpf</b>: {opportunity?.client_cpf?.toString().toLowerCase()}</a>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Cidade</b>: {opportunity?.client_city?.toString().toLowerCase()} - {opportunity?.client_state}</a>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Data de Nascimento</b>: {dateFormat({ date: opportunity?.client_date_of_birth, datetime: false })?.toString().toLowerCase()}</a>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Telefones vinculados</b>: &nbsp;
                                                {opportunity?.client_phonenumber?.split(",").map((telephone: string) => (
                                                    <div className={`badge badge-sm badge-primary m-1`}>
                                                        {telephone}
                                                    </div>
                                                ))}
                                            </a>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={5}>
                                <Card className="h-100">
                                    <Card.Body className="h-card">
                                        <div className="d-flex flex-column h-100">
                                            <b className="text-uppercase">Oportunidade {opportunity?.opportunity_id}</b>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Campanha</b>: {opportunity?.opportunity_campaign} - {opportunity?.campaign_title?.toString().toLowerCase()}</a>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Observação</b>: {opportunity?.campaign_obs?.toString().toLowerCase()}</a>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Data</b>: {dateFormat({ date: opportunity?.campaign_date, datetime: false })?.toString().toLowerCase()}</a>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Duração</b>: {opportunity?.campaign_deadline} dias</a>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Proposta higienizada</b>: {opportunity?.opportunity_sanitize ? 'Sim' : 'Não'?.toString().toLowerCase()}</a>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Status</b>: &nbsp;
                                                <div className={`badge badge-sm ${opportunity?.campaign_status_id === CampaignStatusEnum.ATIVA ? 'badge-success' : 'badge-danger'}`}>
                                                    {opportunity?.campaign_status_id === CampaignStatusEnum.ATIVA ? 'Ativa' : 'Inativa'}
                                                </div>
                                            </a>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={opportunity?.opportunity_sanitize ? 7: 12}>
                                <Card className="h-100">
                                    <Card.Body className="h-card">
                                        <div className="d-flex flex-column h-100">
                                            <b className="text-uppercase">Contratação Anterior</b>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Proposta</b>: {opportunity?.typed_proposal_number?.toString().toLowerCase()}</a> 
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Financeira</b>: {opportunity?.financial_name?.toString().toLowerCase()}</a>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Produto</b>: {opportunity?.productgroup_name?.toString().toLowerCase()}</a>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Forma contrato</b>: {opportunity?.formcontract_name?.toString().toLowerCase()}</a>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Convênio</b>: {opportunity?.agreement_name?.toString().toLowerCase()}</a>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Data da Digitação</b>: {opportunity?.typed_proposal_date?.toString().toLowerCase()}</a>
                                            <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Valor</b>: {opportunity?.typed_proposal_gross_value?.toString().toLowerCase()}</a>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            {opportunity?.opportunity_sanitize ? (
                                <Col md={5}>
                                    <Card className="h-100">
                                        <Card.Body className="h-card">
                                            <div className="d-flex flex-column h-100">
                                                <b className="text-uppercase">Higienização</b>
                                                <a className="opacity-7" style={{textTransform: 'capitalize'}}><b className="opacity-7">Tipo de higienização</b>: {opportunity?.sanitize_type_name?.toString().toLowerCase()}</a>
                                                <a className="opacity-7" style={{textTransform: 'capitalize', whiteSpace: "pre-wrap"}}><b className="opacity-7">Resultado</b>: {opportunity?.sanitized_results?.toString().toLowerCase().replaceAll(";", "\n")}</a> 
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ):(<div></div>)}
                            
                        </Row>
                    </div>
                ) : (
                    <SkeletonTable />
                )}
            </>
        );
    }

    useEffect(() => {
        if (show) {
            handleGetOpportunity(opportunityNumber || 0);
        }
    }, [show]);


    return (
        <ModalDefault
            title={`# ${opportunity?.opportunity_id}`}
            sizeModal = 'xl'
            show={show}
            onClose={onClose}
        >
            <div className="ModalOpportunityDetails" data-testid="ModalOpportunityDetails">
                {renderContent()}
            </div>
        </ModalDefault>
    )
};

export default ModalOpportunityDetails;
