import React, { FC, useEffect, useState } from 'react';
import './AuthenticatorDomus.scss';
import Spinner from '../../../components/Spinner/Spinner';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../providers/AuthProvider';
import { useStyle } from '../../../providers/Style/StyleProvider';
import { useFirebaseMessaging } from '../../../providers/FirebaseMessaging';

interface AuthenticatorDomusProps { }

const AuthenticatorDomus: FC<AuthenticatorDomusProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  const urlParams = new URLSearchParams(window.location.search);
  const { handleMe, accessToken, setOrigin } = useAuth();
  const { urlDomus } = useStyle();
  const navigate = useNavigate();
  const { handleTokenFcm } = useFirebaseMessaging();

  const handleToken = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    let logged = false;
    let originAccess = urlParams.get('origin') || '';

    setIsLoading(true);
    setOrigin(originAccess);

    if (urlParams.get('token')) {
      logged = await handleMe(urlParams.get('token') || '');

      if (!logged) {
        setIsLoading(false);
        return setError('Você não tem permissão a essa tela');
      }
    } else {
      setIsLoading(false);
      return setError('Você não tem permissão a essa tela');
    }

    await handleTokenFcm();

    let redirectUrl = urlParams.get('redirect') || '';
    if (redirectUrl) {
      redirectUrl = new URL(redirectUrl).pathname;
    }

    logged && navigate(redirectUrl ? redirectUrl : '/home');
  }

  useEffect(() => {
    !!urlParams && handleToken();
  }, []);

  return (
    <div className="AuthenticatorDomus" data-testid="AuthenticatorDomus">
      <div className="page-header min-vh-100">

        <Container>
          {error && (
            <>
              <Row className="mt-5 justify-content-center align-items-center text-center">
                <Col md={6} className="mx-auto text-center">
                  <h1 className="display-1 text-bolder text-primary">Error 401</h1>
                  <h2>Página não autorizada</h2>
                  <p className="lead">{error}</p>

                  <div className="viewButtons">
                    <a href={`${urlDomus}/app/api/v1/auth/authTokenSB?token=${accessToken}`}>
                      <Button className="mt-4 fadeIn2 fadeInBottom">Ir para a página inicial</Button>
                    </a>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Container>

        {isLoading && (
          <div className='mt-12'>
            <Spinner backgroundColor='white' opacity='1' />
          </div>
        )}
      </div>
    </div>
  );
}

export default AuthenticatorDomus;
