import React, { FC, useState } from 'react';
import './FinancialProduction.scss';
import { Col, Row } from 'react-bootstrap';
import NavPills from '../../../../NavPills/NavPills';
import PageBase from '../../../../PageBase/PageBase';
import TableMonthlyFinancialProduction from './TableMonthlyFinancialProduction/TableMonthlyFinancialProduction';
import TableAnnualFinancialProduction from './TableAnnualFinancialProduction/TableAnnualFinancialProduction';
import ChartFinancialParticipation from './ChartFinancialParticipation/ChartFinancialParticipation';

interface FinancialProductionProps { }

export enum TabEnumFinancialProduction {
  MONTHLY_PRODUCTION = 'monthly_production',
  ANNUAL_PRODUCTION = 'annual_production',
  CHART_PARTICIPATION = 'chart_participation'
}

const FinancialProduction: FC<FinancialProductionProps> = () => {

  const [currentActiveTab, setCurrentActiveTab] = useState<TabEnumFinancialProduction | string>(TabEnumFinancialProduction.MONTHLY_PRODUCTION);

  const renderContent = (): JSX.Element => {
    return (
      <>
        <Row>
          <Col md={12}>
            <NavPills mdMenu={12}
              options={[
                {
                  acronym: TabEnumFinancialProduction.MONTHLY_PRODUCTION,
                  name: 'Produção Mensal',
                  icon: <i className="fa-solid fa-table"></i>,
                  show: true,
                },
                {
                  acronym: TabEnumFinancialProduction.ANNUAL_PRODUCTION,
                  name: 'Produção Anual',
                  icon: <i className="fa-solid fa-table"></i>,
                  show: true,
                },
                {
                  acronym: TabEnumFinancialProduction.CHART_PARTICIPATION,
                  name: 'Gráfico de Participação',
                  icon: <i className="fa-solid fa-chart-pie"></i>,
                  show: true,
                }
              ]}
              current={currentActiveTab}
              setCurrent={setCurrentActiveTab}
            />
          </Col>
        </Row>

        {currentActiveTab === TabEnumFinancialProduction.MONTHLY_PRODUCTION &&
          <TableMonthlyFinancialProduction currentActiveTab={currentActiveTab as TabEnumFinancialProduction} />
        }
        {currentActiveTab === TabEnumFinancialProduction.ANNUAL_PRODUCTION &&
          <TableAnnualFinancialProduction currentActiveTab={currentActiveTab as TabEnumFinancialProduction} />
        }
        {currentActiveTab === TabEnumFinancialProduction.CHART_PARTICIPATION &&
          <ChartFinancialParticipation currentActiveTab={currentActiveTab as TabEnumFinancialProduction} />
        }
      </>
    );
  }

  return (
    <div className="FinancialProduction" data-testid="FinancialProduction">
      <PageBase
        content={renderContent()}
        showCardHeader={false}
      />
    </div>
  );
};

export default FinancialProduction;
