import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/services/proposals/config/headers-groups';

export class ProposalConfigDetailService extends BaseService {
    async list(params:any) : Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
    async update(params:any) : Promise<any[]> {
        try {
            return this.handleResponse(await api.put(`${URL}/`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}