import { FC } from "react";
import DataTable from "react-data-table-component";

interface AppDataTableProps {
  rows?: any;
  columns?: any;
  subHeader?: any;
  pagination?: boolean;
  conditionalRowStyles?: any;
  title?: any;
  handleOrder?: any;
}

const AppDataTable: FC<AppDataTableProps> = ({
  rows,
  columns,
  subHeader,
  pagination = true,
  conditionalRowStyles,
  title,
  handleOrder
}) => {
  const customStyles = {
    header: {
      style: {
        color: "#67748E",
        fontHeigth: "16px",
      },
    },
    rows: {
      style: {
        paddingLeft: "16px",
        paddingRight: "24px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        maxWidth: "150px",
        padding: "0.75rem 1.5rem",
        fontWeight: "bolder",
        opacity: "0.7",
        fontSize: "0.7rem",
        lineHeight: "0.775rem",
        verticalAlign: "middle",
        color: "#67748E",
        fontHeigth: "13px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        color: "#67748E",
        fontHeigth: "16px",
      },
    },
    footerRow: {
      style: {
        fontWeight: "bold",
        backgroundColor: "#e0e0e0", // Estilo da linha de totais
      },
    },
  };

  const paginationOptions = {
    rowsPerPageText: "ITENS POR PÁGINA",
    rangeSeparatorText: " Exibido de um total de ",
    selectAllRowsItemText: "false",
  };

  const paginationServerOptions = {
    persistSelectedOnSort: true,
  };

  return (
    <span className="AppDataTable" data-testid="AppDataTable">
      <DataTable
        columns={columns || []}
        data={rows}
        fixedHeader={true}
        defaultSortFieldId="title"
        pagination={pagination}
        paginationComponentOptions={paginationOptions}
        paginationServerOptions={paginationServerOptions}
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
        striped={true}
        noDataComponent="Não há registros"
        persistTableHead
        selectableRowsComponent
        subHeader={subHeader}
        subHeaderComponent={subHeader || []}
        dense
        sortServer={!!handleOrder}
        onSort={handleOrder}
        highlightOnHover
        title={title || ""}
      />
    </span>
  );
};

export default AppDataTable;
