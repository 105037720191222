import { FC, useEffect, useState } from 'react';
import './FinancialUpdateFrequency.scss';
import { usePaginate } from '../../../providers/PaginateProvider';
import AppPagination from '../../../components/AppPagination/AppPagination';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import PageBase from '../../../components/PageBase/PageBase';
import MasterPage from '../../../components/MasterPage/MasterPage';
import { useBiFinancialUpdateFrequency } from '../../../providers/BI/BiFinancialUpdateFrequencyProvider';
import { BiFinancialUpdateFrequencyModel } from '../../../models/BI/BiFinancialUpdateFrequencyModel';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import { toast } from 'react-toastify';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { customStyles } from '../../../models/SelectCustomStyles';

interface FinancialUpdateFrequencyProps { }

const FinancialUpdateFrequency: FC<FinancialUpdateFrequencyProps> = () => {

  const { handlePaginate } = usePaginate();
  const {
    handleListFinancials,
    handleListFrequencyOptions,
    handleSaveConfig,
    financials,
    frequencyOptions,
    isLoading,
    params,
    setParams,
    error,
    setError,
    pages
  } = useBiFinancialUpdateFrequency();

  const animatedComponents = makeAnimated();

  useEffect(() => {
    handleListFinancials();
    handleListFrequencyOptions();
  }, [params]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError('');
    }
  }, [error]);

  const handleChangeFrequency = async (item: BiFinancialUpdateFrequencyModel, frequency: number) => {
    const result = await handleSaveConfig(item.id ?? 0, frequency);
    if (result) {
      toast.success('Frequência vinculada a financeira!');
      handleListFinancials();
    }
  }

  const renderList = (): JSX.Element => {
    return (
      <>
        {financials?.length > 0 ? (
          <>
            {financials.map((x: BiFinancialUpdateFrequencyModel) => (
              <tr key={x.id} className="text-sm">
                <td>{x?.name}</td>
                <td>
                  <ReactSelect
                    name={`frequency${x.id}`}
                    options={frequencyOptions}
                    placeholder="Selecione..."
                    className={`form-control p-0`}
                    defaultValue={frequencyOptions?.find((frequency: any) => frequency?.value === x.frequency)}
                    components={animatedComponents}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={(val: any) => {
                      handleChangeFrequency(x, val?.value);
                    }}
                    styles={customStyles}
                  />
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={2} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <>
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7" scope="col">Financeira</th>
                  <th className="text-uppercase opacity-7" scope="col">Frequência</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
            {financials?.length > 0 && (
              <div>
                <nav aria-label="...">
                  <AppPagination
                    pages={pages}
                    handlePaginate={handlePaginate}
                    params={params}
                    setParams={setParams}
                    style={{ backgroundColor: '#fff' }}
                  />
                </nav>
              </div>
            )}
          </>
        ) : (
          <SkeletonTable />
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="Configuração de frequência de atualização das financeiras"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="FinancialUpdateFrequency" data-testid="FinancialUpdateFrequency">
        <PageBase
          title="Frequência de Atualização de Financeiras"
          subtitle="Configuração da frequência de atualização das financeiras"
          content={renderContent()}
        // hasFilter
        />
      </div>

    </MasterPage>
  );
}

export default FinancialUpdateFrequency;
