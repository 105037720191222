import React, { Dispatch, FC, SetStateAction } from 'react';
import './TreeView.scss';
import { TreeViewModel } from '../../models/TreeViewModel';
import { Tree } from 'react-arborist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faXmark } from '@fortawesome/free-solid-svg-icons';

interface TreeViewProps {
  data: TreeViewModel[];
  setData: Dispatch<SetStateAction<TreeViewModel[]>>;
  setRemovedNode?: Dispatch<SetStateAction<TreeViewModel | undefined>>;
  folderIcon?: any;
  itemIcon?: any;
  rowHeight?: number;
  height?: number;
  width?: string;
  indent?: number;
  fontSize?: number;
  showDeleteButton?: boolean;
  disableDragDrop?: boolean
}

const TreeView: FC<TreeViewProps> = ({
  data,
  setData,
  setRemovedNode,
  folderIcon = '',
  itemIcon = '',
  rowHeight = 30,
  height = 250,
  width = 250,
  indent = 30,
  fontSize = 14,
  showDeleteButton = true,
  disableDragDrop = false
}) => {

  const moveNode = (nodes: any, movedNode: any, newParent: any, newIndex: number): any[] => {

    let newNodes = removeMovedNode(nodes, movedNode?.id);

    movedNode.parent = newParent ? newParent.id : null;

    if (newParent) {
      const parentNode = findNode(newNodes, newParent.id);
      if (parentNode) {
        if (!parentNode.children) parentNode.children = [];
        parentNode.children.splice(newIndex, 0, movedNode);
      }
    } else {
      newNodes.splice(newIndex, 0, movedNode);
    }

    return newNodes;
  };

  const removeMovedNode = (nodes: any[], nodeId: string): any[] => {
    let result: any[] = [];

    for (const node of nodes) {
      if (node.children) {
        node.children = removeMovedNode(node.children, nodeId);
      }
      if (node.id !== nodeId) {
        result.push(node);
      }
    }

    return result;
  };

  const findNode = (nodes: any[], nodeId: string): any => {
    for (const node of nodes) {
      if (node.id === nodeId) return node;
      if (node.children) {
        const foundNode = findNode(node.children, nodeId);
        if (foundNode) return foundNode;
      }
    }
    return undefined;
  };

  const handleMove = (any: any) => {
    const updatedTree = moveNode(data, any?.dragNodes[0]?.data, any?.parentNode?.data, any?.index);
    setData(updatedTree);
  };

  const handleRemove = (nodeToRemove: any) => {
    const updatedTree = removeNode(data, nodeToRemove.id);
    setData(updatedTree);
    if (setRemovedNode) {
      setRemovedNode(nodeToRemove);
    }
  };

  const removeNode = (nodes: TreeViewModel[], nodeId: string): TreeViewModel[] => {
    return nodes.reduce<TreeViewModel[]>((acc, node) => {
      if (node.id === nodeId) {
        return acc;
      }

      if (node.children) {
        node.children = removeNode(node.children, nodeId);
      }
      acc.push(node);
      return acc;
    }, []);
  };

  const renderTreeItem = ({ node, style, dragHandle }: any) => {
    return (
      <div style={{ ...style, fontSize: `${fontSize}px` }} className='node-container' ref={dragHandle}>
        {node.isLeaf ? itemIcon : folderIcon}
        <span className='ms-2'>{node.data.name}</span>
        {showDeleteButton &&
          <FontAwesomeIcon
            className='ms-3 cursor-pointer remove-icon'
            icon={faCircleXmark}
            onClick={() => handleRemove(node?.data)} />}
      </div>
    );
  }

  return (
    <div className="TreeView" data-testid="TreeView">
      <Tree
        data={data}
        rowHeight={rowHeight}
        height={height}
        width={width}
        indent={indent}
        disableDrag={disableDragDrop}
        disableDrop={disableDragDrop}
        onMove={handleMove}
        onDelete={handleRemove}
      >
        {renderTreeItem}
      </Tree>
    </div>
  )
};

export default TreeView;
