import { BaseService } from "../../BaseService";
import api from "../../Api";

const URL = 'api/domus/pgm/metric/management';

export class MetricManagementService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}