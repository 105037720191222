import * as yup from "yup";

export const schema = yup.object().shape({
  name: yup.string().required(),
  kindOfPerson: yup.mixed().required(),
  termDocumentCategoryId: yup.mixed().required(),
  htmlContent: yup.mixed().required(),
  hasExclusive: yup.mixed(),
  termsTagsColumnsTablesIds: yup.mixed(),
});
