import { FC, useEffect, useState } from 'react';
import './CampaignAutoGen.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../providers/PaginateProvider';
import { useExample } from '../../../providers/ExampleProvider';
import { toast } from 'react-toastify';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faEdit, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { useCampaign } from '../../../providers/Marketplace/Campaign/CampaignProvider';
import { CampaignAutoGenModel } from '../../../models/Marketplace/Campaign/CampaignAutoGenModel';
import { dateFormat } from '../../../utils/FormatDate';
import ModalCampaignFilters from '../../../components/Marketplace/Campaign/ModalCampaignFilters/ModalCampaignFilters';

interface CampaignAutoGenProps { }

const CampaignAutoGen: FC<CampaignAutoGenProps> = () => {
  const [showModalFilters, setShowModalFilters] = useState<boolean>(false);
  const [campaignView, setCampaignView] = useState<number | null>(null);

  const { setShowSidebarFilter } = useSidebarFilter();
  const { handlePaginate } = usePaginate();

  const {
    campaignsAutoGen, setCampaigns,
    campaignFilter, setCampaignFilter,
    campaignAutoGenPages, setCampaignAutoGenPages,
    error, setError,
    handleCampaignAutoGen,
    handleDisableCampaignAutoGen,
    loading, isLoadingFile
  } = useCampaign();

  const onSubmitDisableCampaignAutoGen= (line: CampaignAutoGenModel) => {
    Swal.fire({
      icon: 'question',
      title: 'Atenção!',
      html: `Deseja realmente desabilitar a Geração Automatica da Campanha <b>${line.campaign_id} - ${line.campaign_title}</b>`,
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        handleDisableCampaignAutoGen(line.campaign_gen_id || 0).then();
        handleCampaignAutoGen();
      }
    })
  }

  const renderList = () => {
    return (
      <>
        {campaignsAutoGen?.length > 0 ? (
          <>
            {campaignsAutoGen.map((line: CampaignAutoGenModel) => (
              <tr key={line.campaign_gen_id} className="text-sm">
                <td>{line.campaign_id}</td>
                <td>
                  <div className={`badge badge-sm ${line.campaign_gen_active ? 'badge-success' : 'badge-danger'}`}>
                    {line.campaign_gen_active ? 'Ativo' : 'Inativo'}
                  </div>
                </td>
                <td>{line.campaign_title}</td>
                <td>{line.campaign_obs}</td>
                <td>{dateFormat({ date: line.campaign_date, datetime: false })}</td>
                <td>Após {line.campaign_gen_days} dias</td>
                <td>{line.campaign_deadline}</td>
                <td>{line.campaign_origin}</td>
                <td className='text-center'>
                  <DropdownMenuTable key={line.campaign_id || 0}>
                    <Dropdown.Item className="border-radius-md py-2" 
                    onClick={() => onSubmitDisableCampaignAutoGen(line)}
                    >
                      <FontAwesomeIcon icon={faBan} className="me-2" /> Desabilitar Geração
                    </Dropdown.Item>

                    <Dropdown.Item className="border-radius-md py-2" 
                    onClick={() => {setShowModalFilters(true); setCampaignView(line?.campaign_gen_campaign || 0); }} 
                    >
                      <FontAwesomeIcon icon={faSearch} className="me-2" /> Ver filtro
                    </Dropdown.Item>
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={6} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!loading?.listCampaignAutoGen ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7" scope="col">Campanha</th>
                  <th className="text-uppercase opacity-7" scope="col">campaign_gen_active</th>
                  <th className="text-uppercase opacity-7" scope="col">Titulo</th>
                  <th className="text-uppercase opacity-7" scope="col">Observação</th>
                  <th className="text-uppercase opacity-7" scope="col">Data Criação</th>
                  <th className="text-uppercase opacity-7" scope="col">Regerar (após criação)</th>
                  <th className="text-uppercase opacity-7" scope="col">Deadline</th>
                  <th className="text-uppercase opacity-7" scope="col">Origem</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}

        {campaignsAutoGen?.length > 0 && (
              <div>
                <nav aria-label="...">
                  <AppPagination
                    pages={campaignAutoGenPages}
                    handlePaginate={handleCampaignAutoGen}
                    params={campaignFilter}
                    setParams={setCampaignFilter}
                    style={{ backgroundColor: '#fff' }}
                  />
                </nav>
              </div>
            )}
      </>
    );
  }

  useEffect(() => {
    handleCampaignAutoGen();
  }, []);
  
  return (
    <MasterPage
      title="CampaignAutoGen"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="CampaignAutoGen" data-testid="CampaignAutoGen">
        <PageBase
          title="Campanhas Automaticas"
          subtitle="Listagem das campanhas que serão regeradas automaticamente."
          error={error}
          content={renderContent()}
        />

        <ModalCampaignFilters
            show={showModalFilters}
            onClose={setShowModalFilters}
            campaignNumber={campaignView}
            setCampaignNumber={setCampaignView}
        />
      </div>

    </MasterPage>
  );
}

export default CampaignAutoGen;
