import { FC, useEffect, useState } from 'react';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useFinancialProcess } from '../../../../providers/Proposals/Process/FinancialProcessProvider';
import { ReactSelectOptions } from '../../../../models/ReactSelectOptions';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validate';
import { useProposalRule } from '../../../../providers/Proposals/Rule/ProposalRuleProvider';
import { Card, Col, FormGroup, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { customStyles } from '../../../../models/SelectCustomStyles';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useAuth } from '../../../../providers/AuthProvider';
import { useProposalRuleCondition } from '../../../../providers/Proposals/Rule/ProposalRuleConditionProvider';
import { useProposalConfig } from '../../../../providers/Proposals/Config/ProposalConfigProvider';
import FormCondition from './FormCondition/FormCondition';
import PopoverHelp from '../../../Home/PopoverHelp/PopoverHelp';
import { BvIcon } from 'bevi-icon';
import FormOperation from './FormOperation/FormOperation';
import { ToastSettings } from '../../../../utils/ToastSettings';
import { useProposalRuleOperation } from '../../../../providers/Proposals/Rule/ProposalRuleOperationProvider';
import { ProposalRuleOperationEnum } from '../../../../enums/Proposals/Rule/Operation/ProposalRuleOperationEnum';
import { ProposalRuleFetchParams } from '../../../../models/Proposals/Rule/ProposalRuleFetchParams';
import './ModalProposalRuleInsert.scss';
import { TablesColumnsNamesProposalsDataTypesEnum } from '../../../../enums/Proposals/Config/TablesColumnsNamesProposalsDataTypesEnum';
import moment from 'moment';
import { FormataStringCurrencyToNumber } from '../../../../utils/FormataStringCurrencyToNumber';

interface IForm {
  id?: number;
  name: string;
  description: string;
  order?: number;
  financialId: number;
  conditions?: any[];
  operations?: any[];
}

interface ModalProposalRuleInsertProps {
  show: boolean;
  onClose: () => void;
}

const ModalProposalRuleInsert: FC<ModalProposalRuleInsertProps> = ({ show, onClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFinancial, setSelectedFinancial] = useState<ReactSelectOptions | null>(null);
  const [selectedType, setSelectedType] = useState<any | null>(null);
  const [conditionsQuery, setConditionsQuery] = useState<any[]>([]);
  const [operationsQuery, setOperationsQuery] = useState<any[]>([]);

  const { rule, setRule, handleFetch } = useProposalRule();
  const { onInvalid } = useAuth();
  const { handleList: handleListFinancials, financialsOptions } = useFinancialProcess();
  const { handleList: handleListConditions } = useProposalRuleCondition();
  const { handleList: handleListOperations } = useProposalRuleOperation();
  const { handleListTablesColumnsNames, handleListFields } = useProposalConfig();
  const animatedComponents = makeAnimated();

  const { handleSubmit, setValue, control, reset, register, formState: { errors } } = useForm<ProposalRuleFetchParams>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleClose = async () => {
    setRule(null);
    onClose();
  }

  const onSubmit = (data: IForm) => {
    if (conditionsQuery?.length === 0 && operationsQuery.find((item: any) => item?.operationId?.value !== ProposalRuleOperationEnum.DO_NOT_PROCESS)) {
      return ToastSettings("É necessário ao menos uma condição para a regra informada.", "bottom-center", "warning");
    }

    if (operationsQuery?.length === 0) {
      return ToastSettings("É necessário ao menos uma ação para a regra informada.", "bottom-center", "warning");
    }

    setIsLoading(true);

    handleFetch({
      ...data,
      id: rule?.id ?? undefined,
      order: 1,
      conditions: conditionsQuery?.map((item: any) => {

        if (item?.headerFieldId?.dataType === TablesColumnsNamesProposalsDataTypesEnum.DATE) {
          item.value1 = item?.value1 ? moment(item?.value1, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
          item.value2 = item?.value2 ? moment(item?.value2, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
        }

        if (item?.headerFieldId?.dataType === TablesColumnsNamesProposalsDataTypesEnum.DOUBLE) {
          item.value1 = item?.value1 ? FormataStringCurrencyToNumber(item?.value1) : '';
          item.value2 = item?.value2 ? FormataStringCurrencyToNumber(item?.value2) : '';
        }

        return {
          headerFieldId: item?.headerFieldId?.value,
          conditionId: item?.conditionId?.value,
          value1: item?.value1,
          value2: item?.value2,
          type: selectedType?.value
        };
      }),
      operations: operationsQuery?.map((item: any) => {
        return {
          operationId: item?.operationId?.value,
          value: (item?.typeInsertId?.value === 1) ? item?.headerFieldId?.map((item2: any) => item2) : [],
          headerFieldId: (item?.typeInsertId?.value === 2) ? item?.headerFieldId?.map((item2: any) => item2?.value) : [],
          tableColumnsNameId: item?.tableColumnsNameId?.value,
        };
      })
    }).then((ret: boolean) => {
      setIsLoading(false);

      ret && handleClose();
    });
  }

  useEffect(() => {
    if (show) {
      handleListFinancials();
      handleListConditions();
      handleListOperations();
      handleListTablesColumnsNames();

      setIsLoading(false);

      if (!rule) {
        setSelectedType(null);
        setSelectedFinancial(null);
        setConditionsQuery([]);
        setOperationsQuery([]);
        reset();
      } else {
        setValue('name', rule?.name || '');
        setValue('description', rule?.description || '');
      }
    }
  }, [show]);

  useEffect(() => {
    rule && setSelectedFinancial(financialsOptions?.find((item: ReactSelectOptions) => item?.value === rule?.financial?.id) || null);
  }, [financialsOptions]);

  useEffect(() => {
    setValue('financialId', selectedFinancial?.value ?? 0);

    if (selectedFinancial?.value) {
      handleListFields({ financialId: selectedFinancial?.value });
    }
  }, [selectedFinancial]);

  return (
    <ModalDefault
      title={'Inserir Regra de Processamento de Produção'}
      show={show}
      onClose={handleClose}
      sizeModal={'xl'}
      buttonText={isLoading ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoading}
      disabledSubmit={isLoading}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f5f5f5"
    >
      <div className="ModalProposalRuleInsert" data-testid="ModalProposalRuleInsert">
        <Row>
          <Col md={8}>
            <FormGroup className="form-group">
              <label htmlFor="name">Nome *</label>
              <input
                className={`form-control ${onInvalid(errors?.name)}`}
                type='text'
                {...register('name')}
              />
              <ErrorMessage name={'Nome'} type={errors?.name?.type} min={3} max={100} />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className="form-group">
              <label htmlFor="financialId">Financeira *</label>
              <Controller
                name="financialId"
                control={control}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isClearable
                    isSearchable
                    options={financialsOptions}
                    placeholder="Selecione..."
                    className={`form-control p-0 ${onInvalid(errors?.financialId)}`}
                    value={selectedFinancial ?? ''}
                    defaultValue={selectedFinancial}
                    components={animatedComponents}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={(val: any) => {
                      onChange(val?.value ?? null);
                      setSelectedFinancial(val);
                    }}
                    styles={customStyles}
                  />
                )}
              />
              <ErrorMessage name="Financeira"
                type={errors?.financialId?.type} />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="description">Descrição *</label>
              <textarea
                className={`form-control ${onInvalid(errors?.description)}`}
                rows={3}
                {...register('description')}
              ></textarea>
              <ErrorMessage name={'description'} type={errors?.description?.type} min={3} max={255} />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Card className={`${!selectedFinancial ? 'disabled' : ''}`}>
              <Card.Header className="pt-3 pb-0">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center text-wrap gap-1">
                    Condições da Regra
                    <PopoverHelp
                      placement="bottom"
                      header={<>Condições da Regra</>}
                      body={<>Aqui você pode informar um ou mais critérios para que seja executado uma ou mais ações na etapa de processamento de produção.</>}
                    >
                      <button type="button" id="btn-help"
                        className="btn btn-sm text-violet-60 text-sm m-0 px-2">
                        <BvIcon name='help' className='text-violet-60' width={16} height={16} />
                      </button>
                    </PopoverHelp>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="py-3">
                <FormCondition
                  financialId={selectedFinancial?.value ?? 0}
                  type={selectedType?.value ?? 0}
                  conditionsQuery={conditionsQuery}
                  setConditionsQuery={setConditionsQuery}
                  selectedType={selectedType}
                  setSelectedType={setSelectedType}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Card className={`${!selectedFinancial ? 'disabled' : ''}`}>
              <Card.Header className="pt-3 pb-0">
                <div className="d-flex align-items-center text-wrap gap-1">
                  Ações da Regra
                  <PopoverHelp
                    placement="bottom"
                    header={<>Ações da Regra</>}
                    body={<>Aqui você pode informar mais de uma ação para a etapa de processamento de produção.</>}
                  >
                    <button type="button" id="btn-help"
                      className="btn btn-sm text-violet-60 text-sm m-0 px-2">
                      <BvIcon name='help' className='text-violet-60' width={16} height={16} />
                    </button>
                  </PopoverHelp>
                </div>
              </Card.Header>
              <Card.Body className="py-3">
                <FormOperation
                  operationsQuery={operationsQuery}
                  setOperationsQuery={setOperationsQuery}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
}

export default ModalProposalRuleInsert;
