import { FC, useEffect, useState } from 'react';
import './Company.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import AppPagination from '../../../components/AppPagination/AppPagination';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import { useCompany } from '../../../providers/Registrations/Company/CompanyProvider';
import { usePaginate } from '../../../providers/PaginateProvider';
import { CompanyModel } from '../../../models/Registrations/Company/CompanyModel';
import moment from 'moment';
import ButtonEdit from '../../../components/ButtonEdit/ButtonEdit';
import ButtonDelete from '../../../components/ButtonDelete/ButtonDelete';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import ModalCompanyInsert from '../../../components/Registrations/Company/ModalCompanyInsert/ModalCompanyInsert';
import { CompanyEnum } from '../../../enums/Company/CompanyEnum';
import SidebarFilter from '../../../components/SidebarFilter/SidebarFilter'
import SidebarCompanyFilter from '../../../components/Registrations/Company/SidebarCompanyFilter/SidebarCompanyFilter';

interface CompanyProps { }

const Company: FC<CompanyProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();

  const { params, setParams, pages, companys, setCompany, handleList, handleDelete, error, isLoading } = useCompany();
  const { handlePaginate } = usePaginate();

  useEffect(() => {
    handleList();
  }, []);

  const updateCompany = (item: CompanyModel) => {
    setCompany(item);
    setShowModalInsert(true);
  }

  const deleteCompany = (item: CompanyModel) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja excluir a promotora ${item.name}?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleDelete(item.id || 0).then();
        if (ret) {
          toast.success('Promotora inativada com sucesso!');
          handleList();
        } else {
          await Swal.fire('Ops!', `Não foi possível inativar a promotora.<br>${error}`, 'error');
        }
      }
    });
  }

  const renderList = () => {
    return (
      <>
        {companys?.length > 0 ? (
          <>
            {companys.map((x: CompanyModel) => (
              <tr key={x.id} className="text-sm">
                <td className="text-center"><img src={x.logoDefault} className="logo" /></td>
                <td>{x.name}</td>
                <td>{x.corporateName}</td>
                <td className="text-uppercase">{x.acronym}</td>
                <td>{x.hostname}</td>
                <td className="text-center">
                  <span
                    className={`badge ${!!x.deletedAt ? 'badge-danger' : 'badge-success'}`}>{!!x.deletedAt ? 'Inativo' : 'Ativo'}</span>
                </td>
                <td className="text-center">{moment(x.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td>
                  {!x?.deletedAt && (
                    <span className="d-flex justify-content-center gap-3">
                      <ButtonEdit
                        onClick={() => updateCompany(x)}
                      />

                      {x.id !== CompanyEnum.BEVI && (
                        <ButtonDelete
                          onClick={() => deleteCompany(x)}
                        />
                      )}
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={8} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase text-xs font-weight-bolder opacity-7 text-center" scope="col"></th>
                  <th className="text-uppercase text-xs font-weight-bolder opacity-7" scope="col">Nome</th>
                  <th className="text-uppercase text-xs font-weight-bolder opacity-7" scope="col">Razão Social</th>
                  <th className="text-uppercase text-xs font-weight-bolder opacity-7" scope="col">Sigla</th>
                  <th className="text-uppercase text-xs font-weight-bolder opacity-7" scope="col">Domínio</th>
                  <th className="text-uppercase text-xs font-weight-bolder opacity-7 text-center" scope="col">Situação</th>
                  <th className="text-uppercase text-xs font-weight-bolder opacity-7 text-center" scope="col">Data de Cadastro</th>
                  <th className="text-uppercase text-xs font-weight-bolder opacity-7 text-center" scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}

        {companys?.length > 0 && (
          <div>
            <nav aria-label="...">
              <AppPagination
                pages={pages}
                handlePaginate={handlePaginate}
                params={params}
                setParams={setParams}
              />
            </nav>
          </div>
        )}
      </>
    );
  }

  useEffect(() => {
    handleList();
  }, [handleList]);

  return (
    <MasterPage
      title="Promotoras"
      icon="fas fa-industry"
      footerFixed
      footerInverse
    >
      <div className="Company" data-testid="Company">
        <PageBase
          title="Promotoras"
          subtitle="Gerenciamento de promotoras que acessaram o sistema."
          handleInsert={() => setShowModalInsert(true)}
          handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          error={error}
          content={renderContent()}
          hasFilter
        />
      </div>

      <ModalCompanyInsert
        show={showModalInsert}
        onClose={() => setShowModalInsert(false)}
      />
      <SidebarFilter>
        <SidebarCompanyFilter />
      </SidebarFilter>
    </MasterPage>
  );
}

export default Company;
