import { FC, useEffect, useState } from 'react';
import './FormAgentsLifeVisits.scss';
import { TabEnumIndicatorManagement } from '../../../../pages/Visits/IndicatorManagement/IndicatorManagement';
import PageBase from '../../../PageBase/PageBase';
import { useVisitsAgentsLife } from '../../../../providers/Visits/VisitsAgentsLife/VisitsAgentsLifeProvider';
import { usePaginate } from '../../../../providers/PaginateProvider';
import { VisitsAgentsLifeModel } from '../../../../models/Visits/VisitsAgentsLife/VisitsAgentsLifeModel';
import AppPagination from '../../../AppPagination/AppPagination';
import { useStyle } from '../../../../providers/Style/StyleProvider';
import { useAuth } from '../../../../providers/AuthProvider';
import { toast } from 'react-toastify';
import { useIndicatorManagementWalletVisits } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementWalletProvider';
import { FormGroup } from 'react-bootstrap';

interface FormAgentsLifeVisitsProps {
  currentActiveTab?: TabEnumIndicatorManagement;
}

const FormAgentsLifeVisits: FC<FormAgentsLifeVisitsProps> = ({ currentActiveTab }) => {
  const { urlDomus } = useStyle();
  const { accessToken } = useAuth();

  const { handlePaginate } = usePaginate();
  const { agentsLifes, handleList, pages, isLoading } = useVisitsAgentsLife();

  const { params, setParams } = useIndicatorManagementWalletVisits();

  const [agentsLifeFilter, setAgentsLifeFilter] = useState<VisitsAgentsLifeModel[]>();

  useEffect(() => {
    if (currentActiveTab === TabEnumIndicatorManagement.AGENTS_LIFE) {
      if (params?.commercialId) {
        handleList(params);
      } else {
        toast.warning('Faça um filtro para listar a vida do agente!');
      }
    }
  }, [currentActiveTab, params]);

  useEffect(() => {
    setAgentsLifeFilter(agentsLifes);
  }, [agentsLifes]);

  const filterAgentsLife = (property: string) => {
    const result = agentsLifes?.filter((item: VisitsAgentsLifeModel) => (item?.partnerCode?.toUpperCase().includes(property?.toUpperCase()) || item?.name?.toUpperCase().includes(property?.toUpperCase())));

    setAgentsLifeFilter(result)
  }

  const renderList = (): JSX.Element => {
    return (
      <>
        {agentsLifeFilter?.map((x: VisitsAgentsLifeModel) => (
          <tr key={x?.id} className="text-sm">
            <td >
              <a
                href={`${urlDomus}/app/api/v1/auth/authTokenSB?token=${accessToken}&redirect=${urlDomus}/vidadoagente.php?codigo=${x?.partnerId}`}
                className="link-primary"
                style={{ cursor: 'pointer' }}
                target='_blank'
              >
                <b>{x?.partnerCode}</b>
              </a>
            </td>
            <td>{x?.name}</td>
          </tr >

        ))}
      </>

    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {agentsLifeFilter && !isLoading ? (

          <>
            <div className="p-1 d-flex gap-2 justify-content-end align-items-center ">
              <FormGroup className="form-group">
                <strong>Pesquisar </strong>
                <input
                  type='text'
                  className="form-control"
                  onChange={(e: any) => filterAgentsLife(e.target.value)}
                />
              </FormGroup>
            </div>
            <div className='row mt-0'>
              <div className="table-responsive">
                <table className="table table-flush m-0 w-100 mt-0">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-uppercase opacity-7" scope="col" style={{ maxWidth: '150px' }}>COD PARCEIRO</th>
                      <th className="text-uppercase opacity-7" scope="col">NOME</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderList()}
                  </tbody>
                </table>
              </div >
              {agentsLifeFilter && (
                <div>
                  <nav aria-label="...">
                    <AppPagination
                      pages={pages}
                      handlePaginate={handlePaginate}
                      params={params}
                      setParams={setParams}
                      style={{ backgroundColor: '#fff' }}
                    />
                  </nav>
                </div>
              )}
            </div >
          </ >
        ) : (
          <>
            <h4>VIDA DO AGENTE</h4>
            <div className='text-center font-weight-bold'>
              Sem dados de agente!
            </div>
          </>
        )
        }
      </>

    );
  }

  return (
    <div className="FormAgentsLifeVisits" data-testid="FormAgentsLifeVisits">
      <PageBase
        content={renderContent()}
        showCardHeader={false}
      />

    </div>
  );
}

export default FormAgentsLifeVisits;