import { FC, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { PaginateProvider } from "../providers/PaginateProvider";
import { CompanyProvider } from "../providers/Registrations/Company/CompanyProvider";
import { MultiStepsProvider } from "../providers/MultiStepsProvider";
import { SubsidiaryProvider } from "../providers/Registrations/Company/SubsidiaryProvider";
import { useLog } from "../providers/Logs/LogProvider";
import { ReportRequestProvider } from "../providers/Reports/ReportRequest/ReportRequestProvider";
import { ApiKeyProvider } from "../providers/ApiKey/ApiKeyProvider";

interface Props {
  element?: any;
  path?: string;
}

export const AuthenticationGate: FC<Props> = () => {
  const [isLoading, setIsLoading] = useState(true);

  const { userLogged } = useAuth();
  const { handleFetch } = useLog();

  const location = useLocation();

  useEffect(() => {
    userLogged &&
      location.pathname &&
      handleFetch({ route: location.pathname }).then();
  }, [userLogged, location.pathname]);

  useEffect(() => {
    const disposer = () => setTimeout(() => setIsLoading(false), 0);
    disposer();
  }, []);

  if (isLoading) {
    return <></>;
  }

  // If the user is not authenticated, redirect them to a logon page
  // if (!userLogged) {
  //     return <Navigate to="/" />
  // }

  // If they're a user, go ahead and continue to what they wanted to see
  return (
    <PaginateProvider>
      <MultiStepsProvider>
        <CompanyProvider>
          <SubsidiaryProvider>
            <ReportRequestProvider>
              <ApiKeyProvider>
                <Outlet />
              </ApiKeyProvider>
            </ReportRequestProvider>
          </SubsidiaryProvider>
        </CompanyProvider>
      </MultiStepsProvider>
    </PaginateProvider>
  );
};
