import * as yup from "yup";

export const schema = yup.object().shape({
    file: yup
        .string()
        .required(),
    closingDate: yup
        .object()
        .required(),
    goal: yup
        .object()
        .notRequired(),
    accessProfileId: yup
        .object()
        .required()
});