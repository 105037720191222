import React, { FC, useState } from 'react';
import './Partner.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import { usePartner } from '../../../providers/Registrations/Partner/PartnerProvider';
import { usePaginate } from '../../../providers/PaginateProvider';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import { PartnerModel } from '../../../models/Registrations/Partner/PartnerModel';
import moment from 'moment';
import ModalPartnerInsert from '../../../components/Registrations/Partner/ModalPartnerInsert/ModalPartnerInsert';
import SidebarFilter from '../../../components/SidebarFilter/SidebarFilter'
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import SidebarPartnerFilter from '../../../components/Registrations/Partner/SidebarPartnerFilter/SidebarPartnerFilter';

interface PartnerProps { }

const Partner: FC<PartnerProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
  const [showModalFilter, setShowModalFilter] = useState<boolean>(false);

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();

  const { params, setParams, pages, partners, handleList, handleDelete, error, isLoading } = usePartner();
  const { handlePaginate } = usePaginate();

  const renderList = (): JSX.Element => {
    return (
      <>
        {partners?.length > 0 ? (
          <>
            {partners.map((x: PartnerModel) => (
              <tr key={x.id} className="text-sm">
                {/* <td className="text-center"></td> */}
                <td className="text-center">{x.partnerCode}</td>
                <td>
                  <p className="text-sm"><b>Nome:</b> {x.name}</p>
                  {x.corporateName && (
                    <p className="text-sm"><b>Razão Social:</b> {x.corporateName}</p>
                  )}
                  {x.storeName && (
                    <p className="text-sm"><b>Nome Fantasia:</b> {x.storeName}</p>
                  )}
                  {/* <p className="text-sm"><b>Porte Empresa:</b> -</p> */}
                  <p className="text-sm"><b>Tipo Agente:</b> {x.userType?.name}</p>
                  <p className="text-sm"><b>Filial:</b> {x.subsidiary?.name || '-'}</p>
                  <p className="text-sm"><b>Nível:</b> {x.accessProfile?.name}</p>
                </td>
                <td className="text-center">
                  <img src={x?.company?.logoDefault} className="logo" />
                  <p className="text-sm">{x.company?.name}</p>
                </td>
                <td className="text-center">
                  <span className={`badge ${!!x.deletedAt ? 'badge-danger' : 'badge-success'}`}>{!!x.deletedAt ? 'Inativo' : 'Ativo'}</span>
                </td>
                <td className="text-center">{moment(x.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td>

                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={6} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  {/* <th className="text-uppercase opacity-7 text-center" scope="col"></th> */}
                  <th className="text-uppercase opacity-7 text-center" scope="col">Código<br />Parceiro</th>
                  <th className="text-uppercase opacity-7" scope="col">Agente</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Promotora</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Status</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Data de Cadastro</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}

        {partners?.length > 0 && (
          <div>
            <nav aria-label="...">
              <AppPagination
                pages={pages}
                handlePaginate={handlePaginate}
                params={params}
                setParams={setParams}
                style={{ backgroundColor: '#fff' }}
              />
            </nav>
          </div>
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="Agentes"
      icon="fas fa-users"
      footerFixed
      footerInverse
    >
      <div className="Partner" data-testid="Partner">
        <PageBase
          title="Agentes"
          subtitle="Gerenciamento de agentes que acessaram o sistema."
          handleInsert={() => setShowModalInsert(true)}
          handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          error={error}
          content={renderContent()}
          hasFilter
        />

        <ModalPartnerInsert
          show={showModalInsert}
          onClose={() => setShowModalInsert(false)}
        />
      </div>

      <SidebarFilter>
        <SidebarPartnerFilter />
      </SidebarFilter>
    </MasterPage>
  );
}

export default Partner;
