import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { VisitsDivergencesAssessmentsService } from "../../../services/Visits/VisitsDivergencesAssessments/VisitsDivergencesAssessmentsService";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { VisitsDivergenceAssessmentsModel } from "../../../models/Visits/VisitsDivergenceAssessments/VisitsDivergenceAssessmentsModel";
import moment from "moment";

interface ContextProps {
    isLoading: boolean,
    visits: any[],
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: (data: any) => void,
    handleStatusList: () => Promise<any>,
    statusDivergence: any[],
    handleSave: (data: VisitsDivergenceAssessmentsModel) => Promise<any>
}

export const VisitsDivergencesAssessmentsContext = createContext<ContextProps>({} as ContextProps);

interface VisitsDivergencesAssessmentsProviderProps {
    children: ReactNode
}

export const VisitsDivergencesAssessmentsProvider = ({ children }: VisitsDivergencesAssessmentsProviderProps) => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [visits, setVisits] = useState<any[]>([]);
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [error, setError] = useState<string>('');
    const [params, setParams] = useState<any | null>(null);
    const [statusDivergence, setStatusDivergence] = useState<any[]>([]);

    const service = new VisitsDivergencesAssessmentsService();

    const handleList = async (data: any) => {
        try {
            setIsLoading(true);

            data = { ...data, orderBy: [{ created_at: 'desc', was_divergence: 'asc' }] };

            const [_Response, _Error] = await service.list(data);
            setIsLoading(false);

            if (!!_Error) {
                setVisits([]);
                return setError(_Response?.message || _Error);
            }

            setVisits(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setVisits([]);
            setIsLoading(false);
            setError('Não foi possível carregar as visitas.');
        }
    };

    const handleStatusList = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.statusList();
            setIsLoading(false);

            if (!!_Error) {
                setStatusDivergence([]);
                return setError(_Response?.message || _Error);
            }

            setStatusDivergence(_Response.data);
            return setError('');
        } catch (e) {
            setStatusDivergence([]);
            setIsLoading(false);
            setError('Não foi possível carregar os status de divergência.');
        }
    };

    const handleSave = async (data: VisitsDivergenceAssessmentsModel) => {
        try {

            const [_Response, _Error] = await service.save(data);

            if (!!_Error) {
                return false;
            }

            return true;

        } catch (e) {
            return false;
        }
    }

    useEffect(() => {
        handleStatusList();
        setParams({ startDate: moment(new Date()).format('YYYY-MM-01'), endDate: moment(new Date()).format('YYYY-MM-DD') });
    }, []);

    useEffect(() => {
        if (params?.startDate && params?.endDate) {
            handleList(params);
        }
        else {
            setError('Informe ao menos a data inicial e data final.');
        }
    }, [params])

    return (
        <VisitsDivergencesAssessmentsContext.Provider value={{
            isLoading,
            visits,
            pages,
            setPages,
            params,
            setParams,
            error,
            setError,
            statusDivergence,
            handleList,
            handleStatusList,
            handleSave
        }}>
            {children}
        </VisitsDivergencesAssessmentsContext.Provider>
    );
}

export const useVisitsDivergencesAssessments = () => useContext(VisitsDivergencesAssessmentsContext);