import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { BiDashboardService } from "../../../services/BI/Dashboard/BiDashboardService";
import { BiReportGroupModel } from "../../../models/BI/Dashboard/BiReportGroupModel";
import GeneralProductionByUf from "../../../components/Bi/Dashboard/DashboardProduction/GeneralProductionByUf/GeneralProductionByUf";
import ProductionByCommercial from "../../../components/Bi/Dashboard/Commercial/ProductionByCommercial/ProductionByCommercial";
import ProductionMainFinancialsByCommercial from "../../../components/Bi/Dashboard/Commercial/ProductionMainFinancialsByCommercial/ProductionMainFinancialsByCommercial";
import ProductionCommercialBySupervisor from "../../../components/Bi/Dashboard/Commercial/ProductionCommercialBySupervisor/ProductionCommercialBySupervisor";
import GeneralCommercialProductionBySupervisor from "../../../components/Bi/Dashboard/Commercial/GeneralCommercialProductionBySupervisor/GeneralCommercialProductionBySupervisor";
import ProductionByExecutive from "../../../components/Bi/Dashboard/Executive/ProductionByExecutive/ProductionByExecutive/ProductionByExecutive";
import ProductionFinancialByExecutive from "../../../components/Bi/Dashboard/Executive/ProductionFinancialByExecutive/ProductionFinancialByExecutive";
import ProductionRangeValueExecutive from "../../../components/Bi/Dashboard/Executive/ProductionRangeValueExecutive/ProductionRangeValueExecutive";
import DebtorByExecutive from "../../../components/Bi/Dashboard/Executive/DebtorByExecutive/DebtorByExecutive";
import PartnerSituationExecutive from "../../../components/Bi/Dashboard/Executive/PartnerSituationExecutive/PartnerSituationExecutive";
import FinancialProduction from "../../../components/Bi/Dashboard/DashboardFinancial/FinancialProduction/FinancialProduction";
import FinancialByCard from "../../../components/Bi/Dashboard/DashboardFinancial/FinancialByCard/FinancialByCard";
import FinancialByUf from "../../../components/Bi/Dashboard/DashboardFinancial/FinancialByUf/FinancialByUf";
import ProductionByPartner from "../../../components/Bi/Dashboard/DashboardPartner/ProductionByPartner/ProductionByPartner";
import ProductionByContractForm from "../../../components/Bi/Dashboard/ContractForm/ProductionByContractForm/ProductionByContractForm";
import ContractFormProductionByCovenant from "../../../components/Bi/Dashboard/ContractForm/ContractFormProductionByCovenant/ContractFormProductionByCovenant";
import ContractFormProductionByFinancial from "../../../components/Bi/Dashboard/ContractForm/ContractFormProductionByFinancial/ContractFormProductionByFinancial";
import PartnerProductionByBranch from "../../../components/Bi/Dashboard/DashboardPartner/PartnerProductionByBranch/PartnerProductionByBranch";
import DebtorsReportByBranch from "../../../components/Bi/Dashboard/DashboardPartner/DebtorsReportByBranch/DebtorsReportByBranch";
import ContractFormProductionByCard from "../../../components/Bi/Dashboard/ContractForm/ContractFormProductionByCard/ContractFormProductionByCard";
import PartnerStatusReportByBranch from "../../../components/Bi/Dashboard/DashboardPartner/PartnerStatusReportByBranch/PartnerStatusReportByBranch";
import ContractFormProductionByCommercial from "../../../components/Bi/Dashboard/ContractForm/ContractFormProductionByCommercial/ContractFormProductionByCommercial";
import ContractFormProductionBySupervisor from "../../../components/Bi/Dashboard/ContractForm/ContractFormProductionBySupervisor/ContractFormProductionBySupervisor";
import ProductionByCovenant from "../../../components/Bi/Dashboard/Covenant/ProductionByExecutive/ProductionByCovenant/ProductionByCovenant";
import ProductionAgreementFinancial from "../../../components/Bi/Dashboard/Covenant/ProductionAgreementFinancial/ProductionAgreementFinancial";
import ProductionBranchSupervisor from "../../../components/Bi/Dashboard/Group/ProductionBranchSupervisor/ProductionBranchSupervisor";
import { BiProductionParamsModel } from "../../../models/BI/Dashboard/BiProductionParamsModel";
import { toast } from "react-toastify";
import moment from "moment";
import ProductionBySupervisor from "../../../components/Bi/Dashboard/Supervisor/ProductionBySupervisor/ProductionBySupervisor/ProductionBySupervisor";
import ProductionFinancialBySupervisor from "../../../components/Bi/Dashboard/Supervisor/ProductionFinancialBySupervisor/ProductionFinancialBySupervisor";
import ProductionGroupFinanceSupervisor from "../../../components/Bi/Dashboard/Group/ProductionFinanceSupervisor/ProductionGroupFinanceSupervisor";
import GroupProduction from "../../../components/Bi/Dashboard/Group/GroupProduction/GroupProduction";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ProductionbyGroupAgreements from "../../../components/Bi/Dashboard/Covenant/ProductionbyGroupAgreements/ProductionbyGroupAgreements";
import { useAuth } from "../../AuthProvider";
import { BiReportModel } from "../../../models/BI/Dashboard/BiReportModel";

interface ContextProps {
  reportGroups: BiReportGroupModel[];
  biComponents: any;
  tabs: any[];
  setTabs: Dispatch<any>;
  currentTab: any;
  setCurrentTab: Dispatch<SetStateAction<any>>;
  params: BiProductionParamsModel;
  isParamsUpdated: any;
  setIsParamsUpdated: Dispatch<SetStateAction<any>>;
  setParams: Dispatch<SetStateAction<BiProductionParamsModel>>;
  handleListOnFilter: any;
  setHandleListOnFilter: Dispatch<SetStateAction<any>>;
  resultOnFilter: any;
  setResultOnFilter: Dispatch<SetStateAction<any>>;
  pages: PagesPaginateModel;
  setPages: Dispatch<SetStateAction<PagesPaginateModel>>;
  isLoading: boolean;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  generalErrorMsg: string;
  handleListGroupReport: () => void;
  handleListMonthlyProduction: (
    params: BiProductionParamsModel
  ) => Promise<any>;
  handleListAnnualProduction: (params: BiProductionParamsModel) => Promise<any>;
  handleListDebtor: (params: BiProductionParamsModel) => Promise<any>;
  handleListStatusProduction: (params: BiProductionParamsModel) => Promise<any>;
  handleListBroadProduction: (params: BiProductionParamsModel) => Promise<any>;
  handleListRangeProduction: (params: BiProductionParamsModel) => Promise<any>;
  handleExcel: (data: any[], fileName: string) => void;
  handleSort: (column: any, sortDirection: any, rows: any) => any[];
}

export const BiDashboardContext = createContext<ContextProps>(
  {} as ContextProps
);

interface BiDashboardProviderProps {
  children: ReactNode;
}

const componentsBiMenu = {
  "production-general-uf": <GeneralProductionByUf />,
  "production-financial": <FinancialProduction />,
  "production-financial-card": <FinancialByCard />,
  "production-financial-uf": <FinancialByUf />,
  "production-by-commercial": <ProductionByCommercial />,
  "production-main-financials-by-commercial": (
    <ProductionMainFinancialsByCommercial />
  ),
  "production-commercial-by-supervisor": <ProductionCommercialBySupervisor />,
  "general-commercial-production-by-supervisor": (
    <GeneralCommercialProductionBySupervisor />
  ),
  "production-executive": <ProductionByExecutive />,
  "production-main-financial-executive": <ProductionFinancialByExecutive />,
  "production-range-values-executive": <ProductionRangeValueExecutive />,
  "report-debtors-executive": <DebtorByExecutive />,
  "report-situation-partner-executive": <PartnerSituationExecutive />,
  "production-by-partner": <ProductionByPartner />,
  "partner-production-by-branch": <PartnerProductionByBranch />,
  "debtors-report-by-branch": <DebtorsReportByBranch />,
  "production-by-contract-form": <ProductionByContractForm />,
  "contract-form-production-by-covenant": <ContractFormProductionByCovenant />,
  "contract-form-production-by-financial": (
    <ContractFormProductionByFinancial />
  ),
  "contract-form-production-by-card": <ContractFormProductionByCard />,
  "partner-status-report-by-branch": <PartnerStatusReportByBranch />,
  "contract-form-production-by-commercial": (
    <ContractFormProductionByCommercial />
  ),
  "contract-form-production-by-supervisor": (
    <ContractFormProductionBySupervisor />
  ),
  "production-by-agreement": <ProductionByCovenant />,
  "production-of-agreement-by-financial": <ProductionAgreementFinancial />,
  "group-production-by-branch-and-supervisor": <ProductionBranchSupervisor />,
  "production-by-supervisor": <ProductionBySupervisor />,
  "production-main-financials-by-supervisor": (
    <ProductionFinancialBySupervisor />
  ),
  "group-production-by-finance-and-supervisor": (
    <ProductionGroupFinanceSupervisor />
  ),
  "group-production": <GroupProduction />,
  "production-by-group-agreements": <ProductionbyGroupAgreements />,
};

export const BiDashboardProvider = ({ children }: BiDashboardProviderProps) => {
  const [reportGroups, setReportGroups] = useState<BiReportGroupModel[]>([]);
  const [biComponents, setBiComponents] = useState<any>(componentsBiMenu);
  const [tabs, setTabs] = useState<any[]>([]);
  const [currentTab, setCurrentTab] = useState<any>();
  const [handleListOnFilter, setHandleListOnFilter] = useState<any>();
  const [resultOnFilter, setResultOnFilter] = useState<any>();
  const [params, setParams] = useState<BiProductionParamsModel>({
    filter: {
      startDate: moment().startOf("month").format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
    },
  });
  const { userLogged } = useAuth();
  const [isParamsUpdated, setIsParamsUpdated] = useState(false);
  const [pages, setPages] = useState<PagesPaginateModel>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [generalErrorMsg, setGeneralErrorMsg] = useState<string>(
    "Não foi possível carregar os dados!"
  );

  const rules = [
    {
      rule: "dashboard.producao_geral_ufregra",
      component: "production-general-uf",
    },
    {
      rule: "dashboard.producao_financeira_cartaoregra",
      component: "production-financial-card",
    },
    {
      rule: "dashboard.producao_por_financeiraregra",
      component: "production-financial",
    },
    {
      rule: "dashboard.producao_por_financeira_por_ufregra",
      component: "production-financial-uf",
    },
    {
      rule: "dashboard.producao_por_comercialregra",
      component: "production-by-commercial",
    },
    {
      rule: "dashboard.producao_financeira_comercialregra",
      component: "production-main-financials-by-commercial",
    },
    {
      rule: "dashboard.producao_comercial_supervisorregra",
      component: "production-commercial-by-supervisor",
    },
    {
      rule: "dashboard.producao_geral_comercial_supervisorregra",
      component: "general-commercial-production-by-supervisor",
    },
    {
      rule: "dashboard.producao_executivoregra",
      component: "production-executive",
    },
    {
      rule: "dashboard.producao_financeira_executivoregra",
      component: "production-main-financial-executive",
    },
    {
      rule: "dashboard.producao_faixa_valoresregra",
      component: "production-range-values-executive",
    },
    {
      rule: "dashboard.devedores_por_executivoregra",
      component: "report-debtors-executive",
    },
    {
      rule: "dashboard.situacao_parceiros_executivoregra",
      component: "report-situation-partner-executive",
    },
    {
      rule: "dashboard.producao_supervisorregra",
      component: "production-by-supervisor",
    },
    {
      rule: "dashboard.producao_financeira_supervisorregra",
      component: "production-main-financials-by-supervisor",
    },
    {
      rule: "dashboard.producao_parceiroregra",
      component: "production-by-partner",
    },
    {
      rule: "dashboard.producao_parceiro_filialregra",
      component: "partner-production-by-branch",
    },
    {
      rule: "dashboard.devedores_filialregra",
      component: "debtors-report-by-branch",
    },
    {
      rule: "dashboard.situacao_parceiro_filialregra",
      component: "partner-status-report-by-branch",
    },
    {
      rule: "dashboard.producao_convenioregra",
      component: "production-by-agreement",
    },
    {
      rule: "dashboard.producao_convenio_agrupadoregra",
      component: "production-by-group-agreements",
    },
    {
      rule: "dashboard.producao_convenio_financeiraregra",
      component: "production-of-agreement-by-financial",
    },
    {
      rule: "dashboard.producao_gruporegra",
      component: "group-production",
    },
    {
      rule: "dashboard.producao_grupo_filial_supervisorregra",
      component: "group-production-by-branch-and-supervisor",
    },
    {
      rule: "dashboard.producao_financeira_supervisorregra",
      component: "group-production-by-finance-and-supervisor",
    },
    {
      rule: "dashboard.forma_contrato_cartaoregra",
      component: "contract-form-production-by-card",
    },
    {
      rule: "dashboard.forma_contrato_comercialregra",
      component: "contract-form-production-by-commercial",
    },
    {
      rule: "dashboard.forma_contrato_supervisorregra",
      component: "contract-form-production-by-supervisor",
    },
    {
      rule: "dashboard.forma_contrato_financeiraregra",
      component: "contract-form-production-by-financial",
    },
    {
      rule: "dashboard.forma_contrato_convenioregra",
      component: "contract-form-production-by-covenant",
    },
    {
      rule: "dashboard.forma_contratoregra",
      component: "production-by-contract-form",
    },
  ];

  const service = new BiDashboardService();

  const { CanAccess } = useAuth();

  const handleRules = async (rule: string) => {
    return await CanAccess(rule).then((x: boolean) => {
      return x;
    });
  };

  const handleListGroupReport = async () => {
    try {
      setIsLoading(true);
      const [_Response, _Error] = await service.listGroupReport(params);
      setIsLoading(false);

      _Response?.data?.forEach((group: BiReportGroupModel) => {
        const filteredReports: BiReportModel[] = [];
        group?.report?.forEach((report: BiReportModel) => {
          const rule = rules?.find(
            (x: any) => report.componentName === x.component
          );
          if (rule) {
            CanAccess(rule?.rule).then((can: boolean) => {
              if (can) {
                filteredReports?.push(report);
              }
            });
          }
        });
        group.report = filteredReports;
      });

      if (_Error) {
        setReportGroups([]);
        setPages({});
        setError(_Error);
      }

      setReportGroups(_Response.data);
      setPages(_Response?.pages);
      setError("");
    } catch (e) {
      setIsLoading(false);
      setReportGroups([]);
      setPages({});
      setError("Não foi possível carregar os exemplos do sistema.");
    }
  };

  const handleListMonthlyProduction = async (
    params: BiProductionParamsModel
  ): Promise<any> => {
    try {
      setIsLoading(true);
      const [_Response, _Error] = await service.listMonthlyProduction(params);
      setIsLoading(false);

      if (_Error) {
        setError(_Error);
        return false;
      }

      setError("");
      return _Response.data;
    } catch (e) {
      setIsLoading(false);
      setError("Não foi possível carregar os exemplos do sistema.");
      return false;
    }
  };

  const handleListAnnualProduction = async (
    params: BiProductionParamsModel
  ): Promise<any> => {
    try {
      setIsLoading(true);
      const [_Response, _Error] = await service.listAnnualProduction(params);
      setIsLoading(false);

      if (_Error) {
        setError(_Error);
        return false;
      }

      setError("");
      return _Response.data;
    } catch (e) {
      setIsLoading(false);
      setError("Não foi possível carregar os exemplos do sistema.");
      return false;
    }
  };

  const handleListDebtor = async (
    params: BiProductionParamsModel
  ): Promise<any> => {
    try {
      setIsLoading(true);
      const [_Response, _Error] = await service.listDebtor(params);
      setIsLoading(false);

      if (_Error) {
        setError(_Error);
        return false;
      }

      setError("");
      return _Response.data;
    } catch (e) {
      setIsLoading(false);
      setError("Não foi possível carregar os devedores do sistema.");
      return false;
    }
  };

  const handleListStatusProduction = async (
    params: BiProductionParamsModel
  ): Promise<any> => {
    try {
      setIsLoading(true);
      const [_Response, _Error] = await service.listStatusProduction(params);
      setIsLoading(false);

      if (_Error) {
        setError(_Error);
        return false;
      }

      setError("");
      return _Response.data;
    } catch (e) {
      setIsLoading(false);
      setError("Não foi possível carregar os exemplos do sistema.");
      return false;
    }
  };

  const handleListBroadProduction = async (
    params: BiProductionParamsModel
  ): Promise<any> => {
    try {
      setIsLoading(true);
      const [_Response, _Error] = await service.listBroadProduction(params);
      setIsLoading(false);

      if (_Error) {
        setError(_Error);
        return false;
      }

      setError("");
      return _Response.data;
    } catch (e) {
      setIsLoading(false);
      setError("Não foi possível carregar os exemplos do sistema.");
      return false;
    }
  };

  const handleListRangeProduction = async (
    params: BiProductionParamsModel
  ): Promise<any> => {
    try {
      setIsLoading(true);
      const [_Response, _Error] = await service.listRangeProduction(params);
      setIsLoading(false);

      if (_Error) {
        setError(_Error);
        return false;
      }

      setError("");
      return _Response.data;
    } catch (e) {
      setIsLoading(false);
      setError("Não foi possível carregar os faixas de valores do sistema.");
      return false;
    }
  };

  const handleExcel = async (data: any[], fileName: string): Promise<any> => {
    try {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(blob, `${fileName}.xlsx`);
    } catch (e) {
      setIsLoading(false);
      setError("Não foi possível gerar o excel.");
    }
  };

  const handleSort  = (column: any, sortDirection: any, rows: any) => {    
    const totals = rows?.find((a: any) => a.name === 'TOTAL');
    const dataWithoutTotal = Array.from(rows).filter((a:any) => a.name !== 'TOTAL');

    const sortedData = Array.from(dataWithoutTotal).sort((a: any,b: any) => {
      const aValue = typeof column.selector === 'function' ? column.selector(a) : a[column.selector];
      const bValue = typeof column.selector === 'function' ? column.selector(b) : b[column.selector];

      if(sortDirection === 'asc'){
        return aValue > bValue ? 1 : -1;
      }else{
        return aValue < bValue ? 1 : -1;
      }
    });
    return [...sortedData, totals];
  }

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError("");
    }
  }, [error]);

  useEffect(() => {

    if (currentTab) {
      const _params = JSON.parse(JSON.stringify(params));

      if (currentTab && !["production-financial-card", "contract-form-production-by-card",].includes(currentTab?.name)) {
        if (_params?.filter?.covenantgroup) {
          delete _params?.filter?.covenantgroup;
        }
      }

      if (currentTab && !["production-by-group-agreements"].includes(currentTab?.name)) {
        if (_params?.sundrycovenant) {
          delete _params?.sundrycovenant;
        }
      }

      setParams(_params);
      setIsParamsUpdated(true);
    }

  }, [currentTab]);

  return (
    <BiDashboardContext.Provider
      value={useMemo(
        () => ({
          reportGroups,
          biComponents,
          tabs,
          setTabs,
          currentTab,
          setCurrentTab,
          params,
          isParamsUpdated,
          setParams,
          setIsParamsUpdated,
          handleListOnFilter,
          setHandleListOnFilter,
          resultOnFilter,
          setResultOnFilter,
          pages,
          setPages,
          isLoading,
          error,
          setError,
          generalErrorMsg,
          handleListGroupReport,
          handleListMonthlyProduction,
          handleListAnnualProduction,
          handleListDebtor,
          handleListStatusProduction,
          handleListBroadProduction,
          handleListRangeProduction,
          handleExcel,
          handleSort
        }),
        [
          reportGroups,
          biComponents,
          tabs,
          setTabs,
          currentTab,
          setCurrentTab,
          params,
          setParams,
          handleListOnFilter,
          setHandleListOnFilter,
          resultOnFilter,
          setResultOnFilter,
          pages,
          setPages,
          isLoading,
          error,
          setError,
          generalErrorMsg,
          handleListGroupReport,
          handleListMonthlyProduction,
          handleListAnnualProduction,
          handleListDebtor,
          handleListStatusProduction,
          handleListBroadProduction,
          handleListRangeProduction,
          handleExcel,
          handleSort
        ]
      )}
    >
      {children}
    </BiDashboardContext.Provider>
  );
};

export const useBiDashboard = () => useContext(BiDashboardContext);
