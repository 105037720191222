import { FC, useEffect, useState } from 'react';
import './ContractFormConfig.scss';
import MasterPage from '../../../../components/MasterPage/MasterPage';
import PageBase from '../../../../components/PageBase/PageBase';
import SkeletonTable from '../../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../../components/AppPagination/AppPagination';
import { useSidebarFilter } from '../../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../../providers/PaginateProvider';
import { toast } from 'react-toastify';
import DropdownMenuTable from '../../../../components/DropdownMenuTable/DropdownMenuTable';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faRemove, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

import { ContractFormConfigListModel } from '../../../../models/Tools/Configurations/ContractForm/ContractFormConfigListModel';
import moment from 'moment';
import makeAnimated from 'react-select/animated';
import SidebarContractFormConfigFilter from '../../../../components/Tools/Configurations/ContractForm/SidebarContractFormConfigFilter/SidebarContractFormConfigFilter';
import { useContractForm } from '../../../../providers/Proposals/ContractForm/ContractFormProvider';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import TooltipItem from '../../../../components/TooltipItem/TooltipItem';
import { ReactSelectOptions } from '../../../../models/ReactSelectOptions';
import { useContractFormConfig } from '../../../../providers/Tools/Configurations/ContractFormConfigProvider';

interface ContractFormConfigProps { }

const ContractFormConfig: FC<ContractFormConfigProps> = () => {
  const [itemEdit, setItemEdit] = useState<ContractFormConfigListModel | null>(null);
  const [selectedContractForm, setSelectedContractForm] = useState<any>();

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { handleList: handleListContractForm, contractFormsOptions } = useContractForm();
  const {
    pages,
    params,
    setParams,
    isLoading,
    error,
    setError,
    handleList,
    handleDelete,
    handleSave,
    contractFormsConfig
  } = useContractFormConfig();
  const { handlePaginate } = usePaginate();
  const animatedComponents = makeAnimated();

  useEffect(() => {
    handleList().then();
    handleListContractForm().then();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError('');
    }
  }, [error]);

  const handleDeleteContractFormConfig = (item: ContractFormConfigListModel) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja excluir o item ${item?.financial?.name} - ${item?.name}?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleDelete(item.id ?? 0).then();
        if (ret) {
          toast.success('Item excluído com sucesso!');
          handleList();
        } else {
          await Swal.fire('Ops!', `Não foi possível excluir o item.<br>${error}`, 'error');
        }
      }
    });
  }

  const handleEdit = (item: ContractFormConfigListModel) => {
    setItemEdit(item);
    if (item.contractForm) {
      setSelectedContractForm(contractFormsOptions?.find((x: ReactSelectOptions) => x.value === item.contractForm?.id));
    } else {
      setSelectedContractForm(null);
    }
  }

  const handleSaveContractFormConfig = (item: ContractFormConfigListModel) => {
    if (selectedContractForm) {
      Swal.fire({
        title: '<strong>Atenção?</strong>',
        html: `<span>Deseja salvar o item ${item?.financial?.name} - ${item?.name}?</span>`,
        icon: 'question',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        confirmButtonColor: 'var(--primary)',
      }).then(async (x) => {
        if (x.isConfirmed) {
          const ret = await handleSave({ ...item, contractFormId: selectedContractForm?.value }).then();
          if (ret) {
            toast.success('Item salvo com sucesso!');
            setItemEdit(null);
            handleList();
          } else {
            await Swal.fire('Ops!', `Não foi possível salvar o item.<br>${error}`, 'error');
          }
        }
      });
    } else {
      toast.warning('Selecione uma Forma de Contrato');
    }
  }

  const renderContractForm = (item: ContractFormConfigListModel) => {
    return (
      item?.contractForm?.name
        ?
        <span className='badge bg-success'>
          {item?.contractForm?.name}
        </span>
        :
        <span className='badge bg-danger'>
          * NÃO DEFINIDO *
        </span>
    )
  }

  const renderList = (): JSX.Element => {
    return (
      <>
        {contractFormsConfig?.length > 0 ? (
          <>
            {contractFormsConfig.map((x: ContractFormConfigListModel) => (
              <tr key={x.id} className="text-sm">
                <td>{x.id}</td>
                <td>{moment(x.createdAt).format('DD/MM/YYYY')}</td>
                <td>
                  <img src={`/assets/img/financials/${x?.financial?.acronym}.svg`} />
                </td>
                <td>{x?.name}</td>
                <td>
                  {itemEdit && itemEdit?.id === x.id
                    ?
                    <div className='d-flex gap-2'>
                      <div className='w-100'>
                        <ReactSelect
                          isSearchable
                          options={contractFormsOptions}
                          placeholder="Selecione..."
                          className={`form-control p-0`}
                          components={animatedComponents}
                          value={selectedContractForm}
                          noOptionsMessage={() => 'Não há registros'}
                          onChange={(val: any) => setSelectedContractForm(val)}
                          styles={customStyles}
                        />
                      </div>
                      <TooltipItem position='top' text='Salvar'>
                        <button
                          className='btn btn-primary'
                          onClick={() => handleSaveContractFormConfig(x)}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </button>
                      </TooltipItem>
                      <TooltipItem position='top' text='Cancelar'>
                        <button
                          className='btn btn-secondary'
                          onClick={() => setItemEdit(null)}
                        >
                          <FontAwesomeIcon icon={faRemove} />
                        </button>
                      </TooltipItem>
                    </div>
                    :
                    renderContractForm(x)}
                </td>
                <td className="text-center">
                  <DropdownMenuTable key={x.id ?? 0}>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleEdit(x)}>
                      <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
                    </Dropdown.Item>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleDeleteContractFormConfig(x)}>
                      <FontAwesomeIcon icon={faTrash} className="me-2" /> Remover
                    </Dropdown.Item>
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={5} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div>
            <div className={`${!itemEdit && 'table-responsive'}`} style={{ minHeight: '200px' }}>
              <table className="table table-flush m-0 w-100">
                <thead className="thead-light">
                  <tr>
                    <th className="text-uppercase opacity-7" scope="col">Id</th>
                    <th className="text-uppercase opacity-7" scope="col">Data</th>
                    <th className="text-uppercase opacity-7" scope="col">Financeira</th>
                    <th className="text-uppercase opacity-7" scope="col">Descrição</th>
                    <th className="text-uppercase opacity-7" scope="col">Forma</th>
                    <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {renderList()}
                </tbody>
              </table>
            </div>
            {contractFormsConfig?.length > 0 && (
              <div>
                <nav aria-label="...">
                  <AppPagination
                    pages={pages}
                    handlePaginate={handlePaginate}
                    params={params}
                    setParams={setParams}
                    style={{ backgroundColor: '#fff' }}
                  />
                </nav>
              </div>
            )}
          </div>
        ) : (
          <SkeletonTable />
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="ContractFormConfig"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="ContractFormConfig" data-testid="ContractFormConfig">
        <PageBase
          title="Configuração de Forma de Contrato"
          subtitle="Ferramenta para fazer a configuração das formas de contrato de acordo com a importação da Produção."
          handleFilter={() => showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          content={renderContent()}
          hasFilter
        />
      </div>

      <SidebarContractFormConfigFilter />
    </MasterPage>
  );
}

export default ContractFormConfig;
