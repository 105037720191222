import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ProposalSituationService } from "../../../services/Proposals/Config/ProposalSituationService";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";
import { ProposalSituationModel } from "../../../models/Proposals/Import/ProposalSituationModel";
import { ProposalSituationQtyImportedModel } from "../../../models/Proposals/Import/ProposalSituationQtyImportedModel";
import { ProposalSituationQtyProcessedModel } from "../../../models/Proposals/Import/ProposalSituationQtyProcessedModel";
import { ToastSettings } from "../../../utils/ToastSettings";
import { ProposalPanelParams } from "../../../models/Proposals/Panel/ProposalPanelParams";
import { timeValues } from "../../../enums/Proposals/Import/ProposalTimeEnum";

interface ContextProps {
    params: ProposalPanelParams,
    setParams: Dispatch<SetStateAction<ProposalPanelParams>>,
    timeValueSelected: ReactSelectOptions | undefined,
    setTimeValueSelected: Dispatch<SetStateAction<ReactSelectOptions | undefined>>,
    situations: ProposalSituationModel | null,
    situationsOptions: ReactSelectOptions[],
    handleList: (data: any) => Promise<boolean>,
    situationsProcessed: ProposalSituationModel | null,
    situationsProcessedOptions: ReactSelectOptions[],
}

export const ProposalSituationContext = createContext<ContextProps>({} as ContextProps);

interface ProposalSituationProviderProps {
    children: ReactNode;
}

export const ProposalSituationProvider = ({ children }: ProposalSituationProviderProps) => {
    const [situations, setSituations] = useState<ProposalSituationModel | null>(null);
    const [error, setError] = useState<string>('');
    const [params, setParams] = useState<ProposalPanelParams>({ timePeriod: 5 } as ProposalPanelParams);
    const [situationsOptions, setSituationsOptions] = useState<ReactSelectOptions[]>([]);
    const [situationsProcessed, setSituationsProcessed] = useState<ProposalSituationModel | null>(null);
    const [situationsProcessedOptions, setSituationsProcessedOptions] = useState<ReactSelectOptions[]>([]);
    const [timeValueSelected, setTimeValueSelected] = useState<ReactSelectOptions | undefined>(undefined);

    const service = new ProposalSituationService();

    const handleList = async (data: any) => {
        try {
            const [_Response, _Error] = await service.list(data);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Error);
                return false;
            }

            setError('');
            setSituations(_Response.data);
            setSituationsProcessed(_Response.data);
            return true;
        } catch (e) {
            setError('Não foi possível carregar os tipos');
            return false;
        }
    };

    useEffect(() => {

        if (situations && situations.qtyImported) {
            setSituationsOptions(
                situations?.qtyImported.map((item: ProposalSituationQtyImportedModel) => {
                    return { label: item.importStatusName, value: item.importStatusId, qty: item.qty, color: item.bgColor };
                })
            )
        }

        if (situationsProcessed && situationsProcessed.qtyProcessed) {
            setSituationsProcessedOptions(
                situationsProcessed?.qtyProcessed.map((item: ProposalSituationQtyProcessedModel) => {
                    return { label: item.financialName, value: item.financialId, qty: item.qty };
                })
            )
        }

    }, [situations, situationsProcessed]);

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        setTimeValueSelected(timeValues.find(item => item?.value === 5));
        setError('');
    }, []);

    useEffect(() => {
        params && handleList(params);
    }, [params]);

    return (
        <ProposalSituationContext.Provider value={{
            params,
            setParams,
            timeValueSelected,
            setTimeValueSelected,
            situations,
            situationsOptions,
            handleList,
            situationsProcessed,
            situationsProcessedOptions
        }}>
            {children}
        </ProposalSituationContext.Provider>
    );
}

export const useProposalSituation = () => useContext(ProposalSituationContext);