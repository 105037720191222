import { FC, useEffect, useState } from "react";
import "./Navbar.scss";
import { useAuth } from "../../providers/AuthProvider";
import {
  Container,
  Nav,
  Image,
  Row,
  Card,
  Col,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import { useStyle } from "../../providers/Style/StyleProvider";
import { useInformative } from "../../providers/InformativeProvider";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faFileExport } from "@fortawesome/free-solid-svg-icons";
import { useReportRequest } from "../../providers/Reports/ReportRequest/ReportRequestProvider";
import { useApiKey } from "../../providers/ApiKey/ApiKeyProvider";
import { isDesktop } from "react-device-detect";
import PopoverHelp from "../Home/PopoverHelp/PopoverHelp";
import { toast } from "react-toastify";
import moment from "moment";
interface NavbarProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  subtitle: string;
  icon: string;
  thumb?: boolean;
  logoInverse?: string;
  colorPrimary?: string;
  colorSecondary?: string;
  colorText?: string;
}

const Navbar: FC<NavbarProps> = ({
  thumb = false,
  logoInverse = "",
  colorPrimary = "",
  colorSecondary = "",
  colorText = "",
  ...rest
}) => {
  const { userLogged, logout } = useAuth();
  const { style, urlDomus } = useStyle();

  const [apiKeySelected, setApiKeySelected] = useState<boolean>(false);
  const [valueApiKey, setValueApiKey] = useState<any>();
  const { apiKey, createApiKey, listApiKey } = useApiKey();

  const { showInformative, setShowInformative } = useInformative();
  const { showSidebar, setShowSidebar } = useReportRequest();

  const navigate = useNavigate();

  useEffect(() => {
    setValueApiKey(apiKey?.key);
  }, [apiKey]);

  const handleLogout = async () => {
    const _logout = await logout();
    if (_logout) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        navigate("/login");
      } else {
        window.location.href = urlDomus;
      }
    }
  };

  const toggleSidebar = () => {
    // click to minimize the sidebar or reverse to normal
    if (document.querySelector(".sidenav-toggler")) {
      let sidenavShow = document.getElementsByClassName("g-sidenav-pinned")[0];
      let toggleNavbarMinimize = document.getElementById("navbarMinimize");

      if (sidenavShow) {
        if (!sidenavShow.classList.contains("g-sidenav-hidden")) {
          sidenavShow.classList.remove("g-sidenav-show");
          sidenavShow.classList.add("g-sidenav-hidden");
          if (toggleNavbarMinimize) {
            toggleNavbarMinimize.click();
            toggleNavbarMinimize.setAttribute("checked", "true");
          }
        } else {
          sidenavShow.classList.remove("g-sidenav-hidden");
          sidenavShow.classList.add("g-sidenav-show");

          if (toggleNavbarMinimize) {
            toggleNavbarMinimize.click();
            toggleNavbarMinimize.removeAttribute("checked");
          }
        }
      }
    }
  };

  const handleCopy = (text: any) => {
    navigator.clipboard
      .writeText(text)
      .then(() => toast.success("Api key copiada com sucesso!"))
      .catch((err) => toast.error("Erro ao copiar!"));
  };

  const handleCreateApi = async () => {
    createApiKey();
    setApiKeySelected((current) => !current);
  };

  const handleListApi = async () => {
    const listKey = await listApiKey();

    const currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    if (listKey.expiresAt > currentDate) {
      setValueApiKey(listKey.key);
      setApiKeySelected(true);
    }
  };

  return (
    <nav
      id="navbarBlur"
      className={`navbar navbar-main navbar-expand-lg px-0 mx-0 shadow-none border-radius-xl z-index-sticky Navbar ${
        rest?.className
      } ${showSidebar ? "navbar-zindex" : ""}`}
      data-scroll="false"
      data-testid="Navbar"
      style={{ backgroundColor: colorPrimary }}
    >
      <Container fluid className="py-1 px-5">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div className="sidenav-toggler sidenav-toggler-inner">
              <div className="nav-link p-0" onClick={() => toggleSidebar()}>
                <div
                  className="sidenav-toggler-inner"
                  style={{ borderColor: colorSecondary }}
                >
                  <i
                    className="sidenav-toggler-line bg-violet-60"
                    style={{ backgroundColor: colorText }}
                  ></i>
                  <i
                    className="sidenav-toggler-line bg-violet-60"
                    style={{ backgroundColor: colorText }}
                  ></i>
                  <i
                    className="sidenav-toggler-line bg-violet-60"
                    style={{ backgroundColor: colorText }}
                  ></i>
                </div>
              </div>
            </div>

            <nav className="breadcrumb-ind mx-4" aria-label="breadcrumb">
              {!thumb ? (
                <img alt={style?.name} src={style?.logoInverse} />
              ) : (
                <>
                  {logoInverse ? (
                    <img
                      alt={"Logo"}
                      src={logoInverse}
                      onLoad={() => {
                        URL.revokeObjectURL(logoInverse);
                      }}
                    />
                  ) : (
                    <p className="m-0 text-primary bg-logo-dest">
                      Logo Secundária
                    </p>
                  )}
                </>
              )}
            </nav>

            {/* <div className="ms-md-auto pe-md-3 d-flex align-items-center">
              <NavbarSearch />
            </div> */}
          </div>

          <div className="d-flex align-items-center gap-3">
            <div className="d-flex align-items-center gap-3">
              {/* <button type="button" className="btn btn-md btn-icon bg-violet-40 text-white m-0">
                <i className="fa-solid fa-bell"></i>
              </button>

              <button
                type="button"
                className={`btn btn-md bg-violet-40 text-white btn-action m-0 ${showInformative ? 'active' : ''} `}
                onClick={() => setShowInformative(true)}
              >
                <i className="fa-solid fa-circle-info"></i> Informativos <div className="dest">99+</div>
              </button> */}

              <button
                type="button"
                className={`btn btn-md bg-violet-40 text-white btn-action m-0 ${
                  showSidebar ? "active" : ""
                } `}
                onClick={() => setShowSidebar(!showSidebar)}
              >
                <FontAwesomeIcon icon={faFileExport} />
                {isDesktop ? " Relatórios" : ""}
              </button>
            </div>

            <div className="collapse navbar-collapse" id="navbar">
              <div className="ms-md-auto d-flex align-items-center" />

              <Nav className="navbar-nav justify-content-end">
                <div
                  id="menu-user"
                  onClick={() => {
                    handleListApi();
                  }}
                >
                  <PopoverHelp
                    placement="bottom"
                    body={
                    <div className="pt-3">
                      <div className="text-center">
                        <Image
                          src="https://via.placeholder.com/70"
                          roundedCircle
                        />
                      </div>
                      <div className="text-center pt-1">
                        <b>
                          {userLogged?.partnerCode || ""} -{" "}
                          {userLogged?.name || ""}
                        </b>
                      </div>
                      <div className="text-center pt-2">
                        {!apiKeySelected ? (
                          <button
                            type="submit"
                            className={`btn ${"btn-primary"} text-white w-100 my-4 mb-2`}
                            style={{ backgroundColor: colorPrimary }}
                            onClick={() => handleCreateApi()}
                          >
                            Solicitar API KEY
                          </button>
                        ) : (
                          <>
                            <span className="d-flex align-items-center ps-1">
                              <b>API KEY</b>
                            </span>
                            <InputGroup className="mb-3">
                              <FormControl value={valueApiKey} />
                              <button
                                className={`btn btn-primary text-white m-0`}
                                onClick={() => handleCopy(valueApiKey)}
                              >
                                <FontAwesomeIcon
                                  icon={faCopy}
                                  className="me-2"
                                />
                              </button>
                            </InputGroup>
                          </>
                        )}
                      </div>
                      <div className="d-flex justify-content-center">
                        <a
                          href={`https://documenter.getpostman.com/view/36628793/2sA3sAhnNL`}
                          className="link-primary"
                          style={{ cursor: "pointer" }}
                          target="_blank"
                        >
                          <b>Documentação</b>
                        </a>
                      </div>
                    </div>
                    }
                  >
                    <span className="d-sm-inline d-none cursor-pointer">
                      <b>
                        {userLogged?.partnerCode || ""} -{" "}
                        {userLogged?.name || ""}
                      </b>
                    </span>
                  </PopoverHelp>
                </div>
                {/* <NavDropdown
                  id="menu-user"
                  title={
                    <span
                      className="nav-link font-bold p-0 text-user"
                      style={{ color: colorText ? colorText : 'var(--violet-60)' }}
                    >
                      <span className="d-sm-inline d-none"><b>{userLogged?.partnerCode || ''} - {userLogged?.name || ''}</b></span>
                    </span>
                  }
                  menuVariant="white"
                  className="d-flex align-items-center"
                >
                  <><NavDropdown.Item onClick={handleLogout}>
                    <i className="fa-solid fa-right-from-bracket me-1"></i> Sair
                  </NavDropdown.Item></>
                </NavDropdown> */}
              </Nav>
            </div>

            {userLogged?.subsidiary && (
              <div>
                <p
                  className="m-0 text-sm text-violet-60"
                  style={{ color: colorText }}
                >
                  <i className="fa-solid fa-globe"></i>{" "}
                  <b>{userLogged.subsidiary?.name}</b>
                </p>
              </div>
            )}

            <div>
              <button
                type="button"
                className="btn btn-sm text-violet-60 text-sm m-0 px-2"
                onClick={handleLogout}
                style={{ color: colorText }}
              >
                <i className="fa-solid fa-right-from-bracket"></i> Sair
              </button>
            </div>
          </div>
        </div>
      </Container>
    </nav>
  );
};

export default Navbar;
