import React, { FC, useEffect, useRef, useState } from 'react';
import './DataTable.scss';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Pagination } from 'react-bootstrap';

interface DataTableProps {
  columns: any[],
  data: any[],
  noRowsMessage?: string,
  rowHeight?: number,
  pagination?: boolean,
  rowPerPage?: number,
  stripped?: boolean,
  disableMoveColumn?: boolean
}

const DataTable: FC<DataTableProps> = ({
  columns,
  data,
  rowHeight = 30,
  stripped = false,
  noRowsMessage = 'Sem dados para exibir!',
  disableMoveColumn = true,
  pagination = true,
  rowPerPage = 15
}) => {
  const [apiIsReady, setApiIsReady] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<any>();
  const [totalRows, setTotalRows] = useState<any>();
  const [rowsPerPage, setRowsPerPage] = useState<any>();
  const [current, setCurrent] = useState<any>();
  const [next, setNext] = useState<any>();
  const [previous, setPrevious] = useState<any>();
  const [first, setFirst] = useState<any>();
  const [last, setLast] = useState<any>();
  const [firstOfPage, setFirstOfPage] = useState<any>();
  const [lastOfPage, setLastOfPage] = useState<any>();

  const gridRef = useRef<any>(null);

  useEffect(() => {
    if (gridRef?.current) {
      gridRef?.current?.api?.autoSizeAllColumns();
    }
  }, []);

  const onGridReady = (params: any) => {
    gridRef.current = params?.api;
    params?.api?.autoSizeAllColumns();
    setApiIsReady(true);
  };

  const handlePaginate = (params: any) => {
    setTotalPages(params?.api?.paginationGetTotalPages());
    setTotalRows(params?.api?.paginationGetRowCount());
    setRowsPerPage(params?.api?.paginationGetPageSize());
    setCurrent(params?.api?.paginationGetCurrentPage() + 1);
    setNext((params?.api?.paginationGetCurrentPage() + 1) === params?.api?.paginationGetTotalPages() ? null : params?.api?.paginationGetCurrentPage() + 2);
    setPrevious((params?.api?.paginationGetCurrentPage() + 1) === 1 ? null : (params?.api?.paginationGetCurrentPage() + 1) - 1);
    setFirst(1);
    setLast(params?.api?.paginationGetTotalPages());
    setFirstOfPage(params?.api?.paginationGetCurrentPage() * params?.api?.paginationGetPageSize() + 1);
    setLastOfPage(Math.min((params?.api?.paginationGetCurrentPage() + 1) * params?.api?.paginationGetPageSize(), params?.api?.paginationGetRowCount()))
  }

  const onNextPage = () => {
    gridRef?.current?.paginationGoToNextPage();
  };

  const onPreviousPage = () => {
    gridRef?.current?.paginationGoToPreviousPage();
  };

  const onFirstPage = () => {
    gridRef?.current?.paginationGoToFirstPage();
  };

  const onLastPage = () => {
    gridRef?.current?.paginationGoToLastPage();
  };

  return (
    <div className="DataTable" data-testid="DataTable">
      <div className='ag-theme-quartz'>
        <AgGridReact
          ref={gridRef}
          columnDefs={columns}
          rowData={data}
          defaultColDef={{
            resizable: true,
            sortable: true,
          }}
          gridOptions={{
            headerHeight: 55
          }}
          overlayNoRowsTemplate={`<span>${noRowsMessage}</span>`}
          rowClass={'dt-rows'}
          rowHeight={rowHeight}
          getRowClass={(params) => stripped ? (params.rowIndex % 2 ? 'odd' : 'even') : ''}
          pagination={pagination}
          paginationPageSize={rowPerPage}
          onPaginationChanged={handlePaginate}
          suppressMovableColumns={disableMoveColumn}
          onGridReady={onGridReady}
          // domLayout='autoHeight'
          suppressHorizontalScroll={false}
          suppressPaginationPanel={true}
          enableCellTextSelection
          ensureDomOrder
        />
      </div>


      {(apiIsReady && totalRows !== undefined && totalRows > 0) &&
        <div
          className={`d-flex justify-content-between align-items-center mb-0 AppPagination pt-0 pb-0`}
        >
          <div>
            {totalPages && (
              <p className='text-sm text-span mb-0'>
                Exibindo {firstOfPage || 0} à {lastOfPage || 0} de um total de <span className='text-primary'><b>{totalRows || 0}</b></span> registros
              </p>
            )}
          </div>

          <div className="d-flex align-items-center">
            <Pagination className="pagination my-2">
              {(current !== first) && (
                <Pagination.First onClick={onFirstPage} />
              )}

              {previous > 0 && (
                <Pagination.Item onClick={onPreviousPage}>
                  {previous}
                </Pagination.Item>
              )}

              <Pagination.Item className="active">
                {current}
              </Pagination.Item>

              {next !== null && next !== current && (
                <Pagination.Item onClick={onNextPage}>
                  {next}
                </Pagination.Item>
              )}

              {current !== last && (
                <Pagination.Last onClick={onLastPage} />
              )}
            </Pagination>
          </div>
        </div>}

    </div>
  )
};

export default DataTable;
