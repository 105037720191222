import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/services/commissioning/receipt';
const URL_TOTALS = 'api/services/commissioning/receipt/totals';

export class ReceiptService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async fetch(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.put(`${URL}`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async update(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.put(`${URL}/save`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async delete(id: number): Promise<any> {
        try {
            return this.handleResponse(await api.delete(`${URL}`, { data: { id } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async addEnterprise(data:any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/enterprise/add`, data));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async get(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/edit`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async export(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`api/report/commissioning/receipts/today`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async totals(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL_TOTALS}`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async perType(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL_TOTALS}/perType`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async financialEnterprise(id: number): Promise<any> {
        try {
            return this.handleResponse(await api.get(`${URL}/financial-enterprise/${id}`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async detailedTotals(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/totals/perFinancials`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}

