import * as yup from "yup";

export const schema = yup.object().shape({
    financialId: yup
        .number()
        .required(),
    selectedTypePeriod: yup
        .array()
        .required()
        .min(1),
    receiptTypes: yup
        .array()
        .test('typeError', 'Selecione pelo menos 1(um) ou mais periodos', (value, context) => {
            let isValid = true;

            context?.parent?.selectedTypePeriod?.forEach((item: any) => {
                const typeRecieve = value?.find((x) => x?.key === item?.key);

                if (item?.value === 2 || item?.value === 3 || item?.value === 4) {
                    if (typeRecieve?.receipt?.periods?.length == 0) {
                        isValid = false;
                    }
                }
            });
            return isValid;
        }),
    checkboxEnterprise: yup
        .boolean()
        .nullable()
        .test('typeError', 'Seleciona pelo menos 1(uma) ou mais empresas', (item: any) => {
            return !!item;
        }),

});

