import { FC, useEffect, useState } from 'react';
import './EasyPort.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../providers/PaginateProvider';
import { useExample } from '../../../providers/ExampleProvider';
import { toast } from 'react-toastify';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import { Card, Col, Dropdown, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import Spinner from "../../../components/Spinner/Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEdit, faTrash, faBan, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import {EasyPortModel} from "../../../models/Marketplace/EasyPort/EasyPortModel";
import { useEasyPort } from '../../../providers/Marketplace/EasyPort/EasyPortProvider';
import ModalEasyPortInsert from '../../../components/Marketplace/EasyPort/ModalEasyPortInsert/ModalEasyPortInsert';
import FormatCpfCnpj from '../../../utils/FormatCpfCnpj';

interface EasyPortProps { }

const EasyPort: FC<EasyPortProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);

  const { pages, params, setParams, isLoading, error, easyPorts, handleGetEasyPorts, handleDownloadEasyPortFile, handleDisableEasyPort, isLoadingFile} = useEasyPort();
  const { handlePaginate } = usePaginate();

  const renderList = (): JSX.Element => {
    return (
      <>
        {easyPorts?.length > 0 ? (
          <>
            {easyPorts.map((line: EasyPortModel) => (
              <tr key={line.id} className="text-sm">
                <td className="text-center">{line.id}</td>
                <td>{FormatCpfCnpj(line.cpf)}</td>
                <td>
                  <div className={`badge badge-sm ${line?.result ? 'badge-success' : 'badge-warning'}`}>
                    {line?.result ? 'Resultado disponivel para Download': 'Aguardando retorno do banco'}
                  </div>
                </td>
                <td className="text-center">
                  <DropdownMenuTable key={line.id || 0}>
                    {line?.result ?
                      (
                      <Dropdown.Item className="border-radius-md py-2"  onClick={() => { handleDownloadEasyPortFile(line?.result || ''); }} >
                        <FontAwesomeIcon icon={faFileDownload} className="me-2" /> Baixar Resultado
                      </Dropdown.Item>)
                      :
                      ('')
                    }
                  

                    <Dropdown.Item className="border-radius-md py-2"  onClick={() => { handleDownloadEasyPortFile(line?.paycheck_file || ''); }} >
                      <FontAwesomeIcon icon={faDownload} className="me-2" /> Baixar Contracheque
                    </Dropdown.Item>

                    <Dropdown.Item className="border-radius-md py-2" onClick={() => { handleDisableEasyPort(line?.id || 0); }} >
                      <FontAwesomeIcon icon={faBan} className="me-2" /> Remover
                    </Dropdown.Item>
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={5} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7 text-center" scope="col">#</th>
                  <th className="text-uppercase opacity-7" scope="col">CPF</th>
                  <th className="text-uppercase opacity-7" scope="col">Resultado</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>

            {easyPorts?.length > 0 && (
              <div>
                <nav aria-label="...">
                  <AppPagination
                    pages={pages}
                    handlePaginate={handlePaginate}
                    params={params}
                    setParams={setParams}
                    style={{ backgroundColor: '#fff' }}
                  />
                </nav>
              </div>
            )}
          </div>
        ) : (
          <SkeletonTable />
        )}
      </>
    );
  }


  useEffect(() => {
      handleGetEasyPorts();
  }, []);

  return (
    <MasterPage
      title="EasyPort"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="EasyPort" data-testid="EasyPort">
        <PageBase
          title="Port. Fácil Daycoval"
          handleInsert={() => setShowModalInsert(true)}
          textButtonAdd="Simule"
          error={error}
          content={renderContent()}
        />
        <ModalEasyPortInsert
          show={showModalInsert}
          onClose={() => setShowModalInsert(false)}
        />
      </div>
      {(isLoading || isLoadingFile) && <Spinner />}

      {/* <SidebarEasyPortFilter /> */}
    </MasterPage>
  );
}

export default EasyPort;
