import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { ProposalConfigService } from "../../../services/Proposals/Config/ProposalConfigService";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { ToastSettings } from "../../../utils/ToastSettings";
import { ProposalConfigFetchParams } from "../../../models/Proposals/Config/ProposalConfigFetchParams";
import { ProposalConfigModel } from "../../../models/Proposals/Config/ProposalConfigModel";
import { ProposalTablesColumnsNames } from "../../../models/Proposals/Config/ProposalHeaderFieldsModel";
import { ProposalConfigListParams } from "../../../models/Proposals/Config/ProposalConfigListParams";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";
import { ProposalConfigFieldsListParams } from "../../../models/Proposals/Config/ProposalConfigFieldsListParams";
import { ProposalHeaderFieldsFinancialModel } from "../../../models/Proposals/Config/ProposalHeaderFieldsFinancialModel";
import { ProposalExportHeaderConfigurationModel, ProposalExportHeaderFieldsConfigurationModel } from "../../../models/Proposals/Config/ProposalExportHeaderConfigurationModel";

interface ContextProps {
    config: ProposalConfigModel | null,
    setConfig: Dispatch<SetStateAction<ProposalConfigModel | null>>,
    configs: ProposalConfigModel[],
    fields: ProposalTablesColumnsNames[],
    headerFinancial: ProposalHeaderFieldsFinancialModel | null,
    fieldsOptions: ReactSelectOptions[],
    fieldsTablesColumnsNamesOptions: ReactSelectOptions[],
    exportHeaderConfigurations: ProposalExportHeaderConfigurationModel[],
    params: ProposalConfigListParams,
    setParams: Dispatch<SetStateAction<ProposalConfigListParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleListTablesColumnsNames: () => Promise<boolean>,
    handleListHeaderFinancial: (financialId: number) => Promise<boolean>,
    handleListFields: (data: any) => Promise<boolean>,
    handleFetch: (data: ProposalConfigFetchParams) => Promise<boolean>,
    handleListExportHeaderConfiguration: () => Promise<void>,
    handleSaveExportHeaderConfiguration: (id: string, config: ProposalExportHeaderFieldsConfigurationModel) => Promise<boolean>,
}

export const ProposalConfigContext = createContext<ContextProps>({} as ContextProps);

interface ProposalConfigProviderProps {
    children: ReactNode;
}

export const ProposalConfigProvider = ({ children }: ProposalConfigProviderProps) => {
    const [config, setConfig] = useState<ProposalConfigModel | null>(null);
    const [configs, setConfigs] = useState<ProposalConfigModel[]>([]);
    const [fields, setFields] = useState<ProposalTablesColumnsNames[]>([]);
    const [headerFinancial, setHeaderFinancial] = useState<ProposalHeaderFieldsFinancialModel | null>(null);
    const [fieldsTablesColumnsNames, setFieldsTablesColumnsNames] = useState<any[]>([]);
    const [fieldsOptions, setFieldsOptions] = useState<ReactSelectOptions[]>([]);
    const [fieldsTablesColumnsNamesOptions, setFieldsTablesColumnsNamesOptions] = useState<ReactSelectOptions[]>([]);
    const [exportHeaderConfigurations, setExportHeaderConfigurations] = useState<ProposalExportHeaderConfigurationModel[]>([]);
    const [params, setParams] = useState<ProposalConfigListParams>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new ProposalConfigService();
    const location = useLocation();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/proposal/config/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setConfigs(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar os cabeçalhos');
        }
    }, [params, location.pathname]);

    const handleFetch = async (data: ProposalConfigFetchParams) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.fetchConfig(data);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');

            ToastSettings('Cabeçalho configurado com sucesso!', 'bottom-center', 'success');

            return true;
        } catch (e) {
            setError('Houve um erro ao salvar a configuração do cabeçalho');
            return false;
        }
    }

    const handleListTablesColumnsNames = async () => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.listTablesColumnsNames();

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');

            setFields(_Response.data);

            return true;
        } catch (e) {
            setError('Não foi possível carregar os campos internos');
            return false;
        }
    }

    const handleListHeaderFinancial = async (financialId: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error, _Code, _Errors] = await service.listTablesColumnsNamesNew(financialId, 1);
            setIsLoading(false);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');

            setHeaderFinancial(_Response.data);

            return true;
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar os campos internos');
            return false;
        }
    }

    const handleListFields = async (data: ProposalConfigFieldsListParams) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.listFields(data);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');

            setFieldsTablesColumnsNames(_Response.data);

            return true;
        } catch (e) {
            setError('Não foi possível carregar os campos das planilhas');
            return false;
        }
    }

    const handleListExportHeaderConfiguration = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error, _Code, _Errors] = await service.listExportHeaderConfiguration(params);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                setExportHeaderConfigurations([]);
                setPages({});
                return;
            }

            setError('');
            setExportHeaderConfigurations(_Response.data);
            setPages(_Response.pages);
        } catch (e) {
            setIsLoading(false);
            setExportHeaderConfigurations([]);
            setPages({});
            setError('Não foi possível carregar os campos internos');
        }
    }

    const handleSaveExportHeaderConfiguration = async (id: string, config: ProposalExportHeaderFieldsConfigurationModel): Promise<boolean> => {
        try {
            setIsLoading(true);
            const [_Response, _Error, _Code, _Errors] = await service.saveExportHeaderConfiguration(id, config);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar os campos internos');
            return false;
        }
    }

    useEffect(() => {
        if (error) {
            toast.error(error);
            setError('');
        }
    }, [error]);

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    useEffect(() => {
        setError('');
    }, []);

    useEffect(() => {
        setFieldsOptions(
            fields
                ?.map((item: ProposalTablesColumnsNames) => {
                    return { label: item.description, value: item.id };
                })
        )
    }, [fields]);

    useEffect(() => {
        setFieldsTablesColumnsNamesOptions(
            fieldsTablesColumnsNames
                ?.map((item: any) => {
                    return { label: item.key, value: item.id, dataType: item?.headersFieldsTablesColumnsNamesProposals?.length > 0 ? item?.headersFieldsTablesColumnsNamesProposals[0]?.tablesColumnsNamesProposals?.dataType : null };
                })
        )
    }, [fieldsTablesColumnsNames]);

    return (
        <ProposalConfigContext.Provider value={{
            config,
            setConfig,
            configs,
            fields,
            headerFinancial,
            fieldsOptions,
            fieldsTablesColumnsNamesOptions,
            exportHeaderConfigurations,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleListTablesColumnsNames,
            handleListHeaderFinancial,
            handleListFields,
            handleFetch,
            handleListExportHeaderConfiguration,
            handleSaveExportHeaderConfiguration
        }}>
            {children}
        </ProposalConfigContext.Provider>
    );
}

export const useProposalConfig = () => useContext(ProposalConfigContext);