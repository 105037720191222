import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { VisitsAnalysisDetailsService } from "../../../services/Visits/VisitsAnalysis/VisitsAnalysisDetailsService";
import moment from "moment";
import { useReportRequest } from "../../Reports/ReportRequest/ReportRequestProvider";
import { ToastSettings } from "../../../utils/ToastSettings";

interface ContextProps {
    details: any,
    visitsChartByDay: any[],
    visitsChartByTypeContact: any,
    params: any | null,
    setParams: Dispatch<SetStateAction<any | null>>,
    paramsChartByDay: any | null,
    setParamsChartByDay: Dispatch<SetStateAction<any | null>>,
    paramsChartByTypeContact: any | null,
    setParamsChartByTypeContact: Dispatch<SetStateAction<any | null>>,
    isLoading: boolean,
    isLoadingChartByDay: boolean,
    isLoadingChartByTypeContact: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleListChartByDay: () => void,
    handleListChartByTypeContact: () => void,
    handleExcelByDay: () => void,
    handleExcelByTypeContact: () => void,
}

export const VisitsAnalysisDetailsContext = createContext<ContextProps>({} as ContextProps);

interface VisitsAnalysisDetailsProviderProps {
    children: ReactNode;
}

export const VisitsAnalysisDetailsProvider = ({ children }: VisitsAnalysisDetailsProviderProps) => {
    const [details, setDetails] = useState<any>(null);
    const [visitsChartByDay, setVisitsChartByDay] = useState<any[]>([]);
    const [visitsChartByTypeContact, setVisitsChartByTypeContact] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingChartByDay, setIsLoadingChartByDay] = useState<boolean>(true);
    const [isLoadingChartByTypeContact, setIsLoadingChartByTypeContact] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [params, setParams] = useState<any | null>(null);
    const [paramsChartByDay, setParamsChartByDay] = useState<any | null>(null);
    const [paramsChartByTypeContact, setParamsChartByTypeContact] = useState<any | null>(null);

    const { setIsLoadingFile } = useReportRequest();

    const service = new VisitsAnalysisDetailsService();

    const handleList = useCallback(async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response?.message || _Error);
            }

            setDetails(_Response.data);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar as visitas.');
        }
    }, [params]);

    const handleListChartByDay = useCallback(async () => {
        try {
            setIsLoadingChartByDay(true);
            const [_Response, _Error] = await service.listChartByDay({ startDate: params?.startDate, endDate: params?.endDate, commercial: params?.userVisitorId });

            if (!!_Error) {
                setIsLoadingChartByDay(false);
                return setError(_Response?.message || _Error);
            }

            setVisitsChartByDay(_Response.data);
            setIsLoadingChartByDay(false);
            return setError('');
        } catch (e) {
            setIsLoadingChartByDay(false);
            setError('Não foi possível carregar o gráfico por dia.');
        }
    }, [paramsChartByDay]);

    const handleExcelByDay = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.exportByDay({ startDate: params?.startDate, endDate: params?.endDate, commercial: params?.userVisitorId });

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    const handleListChartByTypeContact = useCallback(async () => {
        try {
            setIsLoadingChartByTypeContact(true);
            const [_Response, _Error] = await service.listChartByTypeContact({ startDate: params?.startDate, endDate: params?.endDate, commercial: params?.userVisitorId });

            if (!!_Error) {
                setIsLoadingChartByTypeContact(false);
                return setError(_Response?.message || _Error);
            }

            setVisitsChartByTypeContact(_Response.data);
            setIsLoadingChartByTypeContact(false);
            return setError('');
        } catch (e) {
            setIsLoadingChartByTypeContact(false);
            setError('Não foi possível carregar o gráfico por Tipo de Contato.');
        }
    }, [paramsChartByTypeContact]);

    const handleExcelByTypeContact = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.exportTypeContact({ startDate: params?.startDate, endDate: params?.endDate, commercial: params?.userVisitorId });

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => { !!params && handleList(); }, [params]);
    useEffect(() => { !!paramsChartByDay && handleListChartByDay(); }, [paramsChartByDay]);
    useEffect(() => { !!paramsChartByTypeContact && handleListChartByTypeContact(); }, [paramsChartByTypeContact]);

    return (
        <VisitsAnalysisDetailsContext.Provider value={{
            details,
            visitsChartByDay,
            visitsChartByTypeContact,
            params,
            setParams,
            paramsChartByDay,
            setParamsChartByDay,
            paramsChartByTypeContact,
            setParamsChartByTypeContact,
            isLoading,
            isLoadingChartByDay,
            isLoadingChartByTypeContact,
            error,
            setError,
            handleList,
            handleListChartByDay,
            handleListChartByTypeContact,
            handleExcelByDay,
            handleExcelByTypeContact
        }}>
            {children}
        </VisitsAnalysisDetailsContext.Provider>
    );
}

export const useVisitsAnalysisDetails = () => useContext(VisitsAnalysisDetailsContext);