import { FC, useEffect, useState } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import InputDateRange from '../../../../InputDateRange/InputDateRange';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { AccesLevelParams } from '../../../../../models/Registrations/Access/AccesLevelParams';
import SidebarFilter from '../../../../SidebarFilter/SidebarFilter';
import { useAccessProfile } from '../../../../../providers/Registrations/Access/AccessProfileProvider';
import ReactSelect from 'react-select';
import { accessLevelType } from '../../../../../enums/Registrations/AccessLevel/EnumAccessLevelType';
import { customStyles } from '../../../../../models/SelectCustomStyles';
import makeAnimated from 'react-select/animated';

interface SidebarAccessLevelFilterProps { }

export const defaultValues = {
  name: '',
  startDate: '',
  endDate: '',
  isLevelDependent: null
} as AccesLevelParams;

const SidebarAccessLevelFilter: FC<SidebarAccessLevelFilterProps> = () => {
  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));

  const { handleSubmit, register, setValue, control } = useForm<any>({ defaultValues });

  const { params, setParams, handleList } = useAccessProfile();

  const animatedComponents = makeAnimated();

  useEffect(() => {
    handleList()
  }, [params]);

  const onSubmit = async (data: AccesLevelParams) => {
    data.startDate = data?.startDate ? moment(data?.startDate).format('YYYY-MM-DD') : '';
    data.endDate = data?.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : '';

    setParams({ ...params, ...data, ...{ page: 1 } });
  }

  const handleOnChangeAccessLevelType = (value: number) => {
    if (value !== null) {
      setValue("isLevelDependent", value);
    }
  };

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarAccessLevelFilter" data-testid="SidebarAccessLevelFilter">
        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="neighborhood">NOME</label>
              <input
                className={`form-control`}
                {...register('name')}
                type='text'
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              startLabelText={'DATA INICIAL'}
              endLabelText={'DATA FINAL'}
            />
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="isLevelDependent">TIPO ACESSO</label>
              <Controller
                name="isLevelDependent"
                control={control}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isClearable
                    options={accessLevelType}
                    placeholder="Selecione..."
                    className={`form-control p-0`}
                    components={animatedComponents}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={(val: any) => {
                      onChange(val?.value ?? null);
                      handleOnChangeAccessLevelType(val?.value ?? null);
                    }}
                    styles={customStyles}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

      </div>
    </SidebarFilter>
  )
}

export default SidebarAccessLevelFilter;
