import React, { FC, useState } from 'react';
import './VisitsTableList.scss';
import DropdownMenuTable from '../../../DropdownMenuTable/DropdownMenuTable';
import { Card, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { VisitsReportsModel } from '../../../../models/Visits/VisitsReports/VisitsReportsModel';
import { useVisits } from '../../../../providers/Visits/VisitsProvider';
import { usePaginate } from '../../../../providers/PaginateProvider';
import AppPagination from '../../../AppPagination/AppPagination';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import moment from 'moment';
import ExportButtons from '../../../ExportButtons/ExportButtons';
import ModalVisits from '../../IndicatorManagement/ModalVisits/ModalVisits';
import { VisitModel } from '../../../../models/Visits/IndicatorManagement/VisitModel';
import Gallery from '../../../Gallery/Gallery';

interface VisitsTableListProps {
  resume?: boolean;
  showExport?: boolean;
}

const VisitsTableList: FC<VisitsTableListProps> = ({ resume = false, showExport = false }) => {
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [itemEdit, setItemEdit] = useState<VisitModel>();

  const { visits, pages, params, isLoading, setParams, handleExcel } = useVisits();
  const { handlePaginate } = usePaginate();

  const handleEdit = (item: VisitsReportsModel) => {
    const _item: VisitModel = {};
    _item.id = item.id;
    _item.observation = item.observation;
    _item.userVisitorId = item.userVisitor?.id;
    _item.userVisitedId = item.userVisited?.partnerId;
    _item.visitDate = item.visitDate;
    _item.city = item.address?.city;
    _item.visitTypeContactId = item.visitTypeContact?.id;
    _item.visitsAttendance = item.visitsAttendances?.map((x: any) => {
      return {
        value: x.id,
        label: x.name
      }
    });
    _item.visitsActions = item.visitsActions?.map((x: any) => x.id);
    _item.visitsTypesActivities = item.visitsTypesActivities?.map((x: any) => x.id);
    _item.partnerRegistrationProspecting = item.partnerRegistrationProspecting;

    setItemEdit(_item);
    setShowEdit(true);
  }

  const canEdit = (item: VisitsReportsModel): boolean => {
    if (item?.wasQuickVisit) {
      return moment(moment(new Date()).format("YYYY-MM-DD"), 'YYYY-MM-DD')?.diff(item?.visitDate, 'days') < 7;
    }
    return false;
  }

  const renderList = (): JSX.Element => {
    return (
      <>
        {visits?.length > 0 ? (
          <>
            {visits.map((item: VisitsReportsModel, key: number) => (
              <tr key={item?.id ?? key} className="text-sm">
                {!resume && (
                  <td className="text-center">
                    <div className="avatar">
                      {item?.visitsImages && item?.visitsImages?.length > 0 ? (
                        <div>
                          <Gallery images={item?.visitsImages?.map((x: any) => { return { original: x.file } })} />
                        </div>
                      ) : (<></>)}
                    </div>
                  </td>
                )}
                <td className="text-center">{moment(item?.visitDate).format('DD/MM/YYYY')}</td>
                <td className="text-wrap">
                  {item?.userVisited ?
                    <>{item?.userVisited?.name || ''} - {item?.userVisited?.partnerCode || ''}</> :
                    <>
                      {item?.partnerRegistrationProspecting?.name} - {item?.partnerRegistrationProspecting?.partnerCode}<br />
                      <span className='text-xs text-primary bg-secondary p-1 rounded-2'>Prospecção</span>
                    </>}
                </td>
                <td className="text-wrap">{item?.userVisitor?.name || ''} - {item?.userVisitor?.partnerCode || ''}</td>
                <td className="text-center">{item?.visitTypeContact?.name}</td>
                <td>
                  <div className="text-start mt-2" >

                    <div>
                      {item?.wasFinished ? <span className='badge bg-success text-wrap text-justify'>Finalizada</span> : <span className='badge bg-primary text-justify'>Em Andamento</span>}
                    </div>

                    <div>
                      <div className='text-info text-wrap text-justify'>AÇÃO</div>
                      {item?.visitsActions && item?.visitsActions?.length > 0 ? (
                        <div className="d-flex align-items-start flex-column gap-1">
                          {item?.visitsActions?.map((item: any) => (
                            <div className="badge badge-primary" key={item?.id}>
                              {item?.name}
                            </div>
                          ))}
                        </div>
                      ) : (<></>)}
                    </div>

                    <div>
                      <div className='text-info'>FOCO</div>
                      {item?.visitsTypesActivities && item?.visitsTypesActivities?.length > 0 ? (
                        <div className="d-flex align-items-start flex-column gap-1">
                          {item?.visitsTypesActivities?.map((item: any) => (
                            <div className="badge badge-info" key={item?.id}>
                              {item?.name}
                            </div>
                          ))}
                        </div>
                      ) : (<></>)}
                    </div>
                  </div>

                </td>
                {/* <td className='text-wrap'>
                  {item?.visitsTypesActivities && item?.visitsTypesActivities?.length > 0 ? (
                    <div className="d-flex align-items-start flex-column gap-1">
                      {item?.visitsTypesActivities?.map((item: any, index: number) => (
                        <div className="badge badge-info" key={index}>
                          {item?.name}
                        </div>
                      ))}
                    </div>
                  ) : (<></>)}
                </td>
                <td>
                  {item?.visitsActions && item?.visitsActions?.length > 0 ? (
                    <div className="d-flex align-items-start flex-column gap-1">
                      {item?.visitsActions?.map((item: any, index: number) => (
                        <div className="badge badge-primary" key={index}>
                          {item?.name}
                        </div>
                      ))}
                    </div>
                  ) : (<></>)}
                </td> */}
                {!resume && (
                  <td className="text-center">{item?.address?.city}</td>
                )}
                <td className="text-wrap">{item?.observation}</td>
                {!resume && (
                  <td className="text-center">
                    {canEdit(item) &&
                      <DropdownMenuTable key={item?.id ?? 0}>
                        <Dropdown.Item
                          className="border-radius-md py-2"
                          onClick={() => handleEdit(item)}
                        >
                          <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
                        </Dropdown.Item>
                      </DropdownMenuTable>}
                  </td>
                )}
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={!resume ? 10 : 7} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  return (
    <div className="VisitsTableList h-100" data-testid="VisitsTableList">
      <Card className='h-100'>
        {showExport && (
          <Card.Header className="pb-0">
            <div className="d-flex align-items-center justify-content-between">
              <div></div>
              <div>
                <ExportButtons
                  getExcel={handleExcel}
                />
              </div>
            </div>
          </Card.Header>
        )}
        <Card.Body>
          {!isLoading ? (
            <>
              <div className="table-responsive">
                <table className="table table-flush m-0 w-100">
                  <thead className="thead-light">
                    <tr>
                      {!resume && (<th className="text-center text-uppercase opacity-7 text-wrap" scope="col">FOTOS DA VISITA</th>)}
                      <th className="text-center text-uppercase opacity-7 text-wrap" scope="col">DATA DA VISITA</th>
                      <th className="text-uppercase opacity-7" scope="col">PARCEIRO</th>
                      <th className="text-uppercase opacity-7" scope="col">RESPONSÁVEL</th>
                      <th className="text-center text-uppercase opacity-7" scope="col">CONTATO</th>
                      <th className="text-uppercase opacity-7" scope="col">INFORMAÇÕES</th>
                      {/* <th className="text-uppercase opacity-7 text-wrap" scope="col">DEMAIS AÇÕES</th>
                      <th className="text-uppercase opacity-7 text-wrap" scope="col">FOCO</th> */}
                      {!resume && (<th className="text-uppercase opacity-7 text-wrap" scope="col">LOCAL DA VISITA</th>)}
                      <th className="text-uppercase opacity-7 text-wrap" scope="col">OBSERVAÇÃO</th>
                      {!resume && (<th className="text-uppercase opacity-7" scope="col">AÇÕES</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {renderList()}
                  </tbody>
                </table>
              </div>

              {(visits?.length > 0) && (
                <div>
                  <nav aria-label="...">
                    <AppPagination
                      pages={pages}
                      handlePaginate={handlePaginate}
                      params={params}
                      setParams={setParams}
                      style={{ backgroundColor: '#fff' }}
                    />
                  </nav>
                </div>
              )}
            </>
          ) : (
            <SkeletonTable />
          )}
        </Card.Body>
      </Card>
      <ModalVisits show={showEdit} onClose={setShowEdit} visitEdit={itemEdit} setVisitEdit={setItemEdit} />
    </div>
  );
}

export default VisitsTableList;
