import { FC } from 'react';
import { Bar } from 'react-chartjs-2';

interface ChartBarPanelProps {
  data: any;
  options: any;
}

const ChartBarPanel: FC<ChartBarPanelProps> = ({ data, options }) => {
  return (
    <div className="ChartBarPanel" data-testid="ChartBarPanel">
      {!!data && !!options && (
        <Bar data={data} options={options} height={90} />
      )}
    </div>
  );
}

export default ChartBarPanel;
