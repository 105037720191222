import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './DataModalVisits.scss';
import { useVisitsActions } from '../../../../providers/Visits/VisitsActions/VisitsActionsProvider';
import { useVisitsTypesActivities } from '../../../../providers/Visits/VisitsTypesActivities/VisitsTypesActivitiesProvider';
import { VisitsActionsModel } from '../../../../models/Visits/VisitsActions/VisitsActionsModel';
import { Col, FormCheck, FormGroup, Row } from 'react-bootstrap';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { VisitsTypesActivitiesModel } from '../../../../models/Visits/VisitsTypesActivities/VisitsTypesActivitiesModel';
import { PreVisitsParams } from '../../../../models/Visits/IndicatorManagement/PreVisitsParams';

interface DataModalVisitsProps {
  action: 'add' | 'edit' | 'view' | 'analysis',
  typeAction: any[],
  setTypeAction: Dispatch<SetStateAction<any[]>>,
  typeActionName?: string,
  typeActivitie: any[],
  setTypeActivitie: Dispatch<SetStateAction<any[]>>,
  typeActivitieName?: string,
  observation: string,
  setObservation: Dispatch<SetStateAction<string>>,
  observationName?: string,
  errors?: any,
}

export const defaultValues = {
  startDate: ''
} as PreVisitsParams;


const DataModalVisits: FC<DataModalVisitsProps> = ({
  action,
  typeAction,
  setTypeAction,
  typeActionName,
  typeActivitie,
  setTypeActivitie,
  typeActivitieName,
  observation,
  setObservation,
  observationName,
  errors }) => {

  const { actions, handleList: handleListActions } = useVisitsActions();
  const { typesActivities, handleList: handleListActivities } = useVisitsTypesActivities();

  useEffect(() => {
    handleListActions()
    handleListActivities()
  }, [])

  const onChangeTypeAction = (e: any) => {
    e?.target?.checked ? setTypeAction([...typeAction, Number(e?.target?.value)]) :
      setTypeAction([...typeAction?.filter((item: any) => item !== Number(e?.target?.value))]);
  }

  const onChangeTypeActivitie = (e: any) => {
    e?.target?.checked ? setTypeActivitie([...typeActivitie, Number(e?.target?.value)]) :
      setTypeActivitie([...typeActivitie?.filter((item: any) => item !== Number(e?.target?.value))]);
  }

  return (
    <div className="DataModalVisits" data-testid="DataModalVisits">
      <Row>
        <Col md={4}>
          <FormGroup className="form-group">
            <label htmlFor="foco">AÇÃO *</label>
            <>
              {actions?.length > 0 ? (
                <>
                  {actions.map((x: VisitsActionsModel) => (
                    <table key={x.id}>
                      <tbody>
                        <tr className='bottom'>
                          <td className='checkbox-position'>
                            <FormCheck className="form-check">
                              <input
                                value={x.id}
                                type='checkbox'
                                className='form-check-input'
                                checked={typeAction?.find((y: any) => y === x.id)}
                                onChange={onChangeTypeAction}
                                disabled={action === 'view' || action === 'analysis'}
                              />
                            </FormCheck>
                          </td>
                          <td className='mt-1'>
                            {x.name}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={5} className="text-center text-sm">Não há registros</td>
                </tr>
              )}
            </>
            <ErrorMessage name="Foco" isArray min={1} type={errors?.[typeActionName || '']?.type} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup className="form-group">
            <label htmlFor="description">FOCO *</label>
            <>
              {typesActivities?.length > 0 ? (
                <>
                  {typesActivities.map((x: VisitsTypesActivitiesModel) => (
                    <table key={x.id}>
                      <tbody>
                        <tr className='bottom'>
                          <td>
                            <FormCheck className="form-check">
                              <input
                                value={x.id}
                                type='checkbox'
                                checked={typeActivitie?.find((y: any) => y === x.id)}
                                className='form-check-input'
                                onChange={onChangeTypeActivitie}
                                disabled={action === 'view' || action === 'analysis'}
                              />
                            </FormCheck>
                            <span className='checkmark bottom'></span>
                          </td>
                          <td className="text-left mt-1">
                            {x.name}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={5} className="text-center text-sm">Não há registros</td>
                </tr>
              )}
            </>
            <ErrorMessage name="Foco" isArray min={1} type={errors?.[typeActivitieName || '']?.type} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup className="form-group">
            <label htmlFor="observation">OBSERVAÇÕES *</label>
            <textarea rows={12} className='form-control'
              onChange={(e: any) => setObservation(e?.target?.value)}
              placeholder='Insira observações ou informe pontos importantes da visita aqui'
              disabled={action === 'view' || action === 'analysis'}
              maxLength={500}
              value={observation}
            />
            <div className='text-end text-xs mt-1'>{observation?.length || 0} de 500 caracteres</div>
            <ErrorMessage name="Observações" max={500} type={errors?.[observationName || '']?.type} />
          </FormGroup>
        </Col>
      </Row >
    </div >
  );

}

export default DataModalVisits;
