import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { VisitsTypesContactsModel } from "../../../models/Visits/VisitsTypesContacts/VisitsTypesContactsModel";
import { VisitsTypesContactsParams } from "../../../models/Visits/VisitsTypesContacts/VisitsTypesContactsParams";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { VisitsTypesContactsService } from "../../../services/Visits/VisitsTypesContacts/VisitsTypesContactsService";
import { toast } from "react-toastify";
import { ToastSettings } from "../../../utils/ToastSettings";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";

interface ContextProps {
    typesContact: VisitsTypesContactsModel,
    setTypesContact: Dispatch<SetStateAction<VisitsTypesContactsModel>>,
    typesContacts: VisitsTypesContactsModel[],
    typesContactsOptions: ReactSelectOptions[],
    params: VisitsTypesContactsParams,
    setParams: Dispatch<SetStateAction<VisitsTypesContactsParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleFetch: (data: VisitsTypesContactsModel) => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>,
}

export const VisitsTypesContactsContext = createContext<ContextProps>({} as ContextProps);

interface VisitsTypesContactsProviderProps {
    children: ReactNode;
}

export const VisitsTypesContactsProvider = ({ children }: VisitsTypesContactsProviderProps) => {
    const [typesContact, setTypesContact] = useState<VisitsTypesContactsModel>({} as VisitsTypesContactsModel);
    const [typesContacts, setTypesContacts] = useState<VisitsTypesContactsModel[]>([]);
    const [typesContactsOptions, setTypesContactsOptions] = useState<ReactSelectOptions[]>([]);
    const [params, setParams] = useState<VisitsTypesContactsParams>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new VisitsTypesContactsService();

    const handleList = useCallback(async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response?.message || _Error);
            }

            setTypesContacts(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar os tipos de contatos');
        }
    }, [params]);

    const handleFetch = async (data: VisitsTypesContactsModel) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.fetch(data);
            if (!!_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setError('Houve um erro ao salvar o tipo de contato');
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar o tipo de contato');
            return false;
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        handleList();
    }, [params]);

    useEffect(() => {
        setTypesContactsOptions(
            typesContacts
                ?.map((item: VisitsTypesContactsModel) => {
                    return {
                        label: item?.name,
                        value: item?.id
                    };
                })
        )
    }, [typesContacts]);

    return (
        <VisitsTypesContactsContext.Provider value={{
            typesContact,
            setTypesContact,
            typesContacts,
            typesContactsOptions,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleFetch,
            handleDelete,
        }}>
            {children}
        </VisitsTypesContactsContext.Provider>
    );
}

export const useVisitsTypesContacts = () => useContext(VisitsTypesContactsContext);