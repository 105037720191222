import { FC, useState } from 'react';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import SidebarMetricFilter from '../../../components/Pgm/MetricManagement/SidebarMetricFilter/SidebarMetricFilter';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import { Col, Row } from 'react-bootstrap';
import NavPills from '../../../components/NavPills/NavPills';
import CommercialMetric from '../../../components/Pgm/MetricManagement/CommercialMetric/CommercialMetric';
import CommercialScore from '../../../components/Pgm/MetricManagement/CommercialScore/CommercialScore';
import CommercialScoreGeneral from '../../../components/Pgm/MetricManagement/CommercialScoreGeneral/CommercialScoreGeneral';

interface MetricManagementPropos { };

export enum TabEnumMetricManagement {
    COMMERCIAL_METRIC = 'metric_commercial',
    SCORE_COMMERCIAL = 'metric_score_commercial',
    SCORE_COMMERCIAL_GENERAL = 'metric_score_commercial_general',
    NONE = 'none'
}

const MetricManagement: FC<MetricManagementPropos> = () => {

    const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
    const [currentActiveTab, setCurrentActiveTab] = useState<TabEnumMetricManagement | string>(TabEnumMetricManagement.SCORE_COMMERCIAL);

    const renderContent = (): JSX.Element => {
        return (
            <>
                <Row>
                    <Col md={12} xs={12}>
                        <NavPills
                            options={[
                                {
                                    acronym: TabEnumMetricManagement.SCORE_COMMERCIAL,
                                    name: 'PONTUAÇÃO COMERCIAL',
                                    element: <CommercialScore />,
                                    show: true
                                },
                                {
                                    acronym: TabEnumMetricManagement.COMMERCIAL_METRIC,
                                    name: 'MÉTRICA COMERCIAL',
                                    element: <CommercialMetric />,
                                    show: true
                                },
                                {
                                    acronym: TabEnumMetricManagement.SCORE_COMMERCIAL_GENERAL,
                                    name: 'PONTUAÇÃO COMERCIAL GERAL',
                                    element: <CommercialScoreGeneral />,
                                    show: true
                                }
                            ]}
                            current={currentActiveTab}
                            setCurrent={setCurrentActiveTab}
                            mdMenu={12}
                        />
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <MasterPage
            title={'PANIEL CONECTA (PGM)'}
            icon={'fas fa-list'}
            heightClass="min-height-470"
            footerFixed
        >
            <div className="MetricManagement" data-testid="MetricManagement">
                <PageBase
                    title={'Painel Conecta'}
                    subtitle={'Painel Gerenciamento de gestão de métricas'}
                    content={renderContent()}
                    handleFilter={() => showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
                    hasFilter
                />
            </div>

            <SidebarMetricFilter />
        </MasterPage>
    );
}

export default MetricManagement;