import { AccessPageModel } from "./AccessPageModel";

export class AccessProfileModel {
    id?: number;
    externalId?: number;
    name?: string;
    companyId?: string;
    company?: any;
    createdAt?: string;
    updatedAt?: string;
    active?: boolean | string;
    pages?: AccessPageModel[] = [];
    userActions?: any;
    type?: string;
    isLevelDependent?: boolean | number;
}