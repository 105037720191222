import { FC } from 'react';
import { Outlet } from 'react-router-dom';

interface Props {
    element?: any;
    path?: string;
}

export const LogRoute: FC<Props> = () => {
    return (
        <Outlet />
    );
}
