import { FC, useEffect, useState } from 'react';
import './SidebarVisitsAnalysisFilter.scss';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import { useForm } from 'react-hook-form';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { useCommercial } from '../../../../providers/Registrations/User/UserCommercialProvider';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import { useVisitsAnalysis } from '../../../../providers/Visits/VisitsAnalysis/VisitsAnalysisProvider';

const moment = require('moment');

interface SidebarVisitsAnalysisFilterProps { }

export const defaultValues = {
  commercial: '',
  regional: '',
  executive: '',
  startDate: '',
  endDate: '',
} as any;

const SidebarVisitsAnalysisFilter: FC<SidebarVisitsAnalysisFilterProps> = () => {
  const [startDate, setStartDate] = useState<any>(moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
  const [selectedCommercial, setSelectedCommercial] = useState<any | null>(null);
  const [selectedRegional, setSelectedRegional] = useState<any | null>(null);
  const [selectedExecutive, setSelectedExecutive] = useState<any | null>(null);

  const { handleSubmit, setValue } = useForm<any>({ defaultValues });
  const { params, setParams } = useVisitsAnalysis();
  const { commercialsOptions, regionalsOptions, executivesOptions, handleList, handleListRegional, handleListExecutive } = useCommercial();

  const onSubmit = async (data: any) => {
    data.startDate = data?.startDate ? moment(data?.startDate).format('YYYY-MM-DD') : '';
    data.endDate = data?.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : '';

    setParams({ ...params, ...data, ...{ page: 1 } });
  }

  useEffect(() => {
    setValue('commercial', selectedCommercial?.value ?? null)
  }, [selectedCommercial]);

  useEffect(() => {
    setValue('regional', selectedRegional?.value ?? null)
  }, [selectedRegional]);

  useEffect(() => {
    setValue('executive', selectedExecutive?.value ?? null)
  }, [selectedExecutive]);

  useEffect(() => {
    handleList();
    handleListRegional({});
    handleListExecutive();
  }, []);

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarVisitsAnalysisFilter" data-testid="SidebarVisitsAnalysisFilter">
        <Row className="mt-1">
          <Col>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              startLabelText={'DATA INICIAL'}
              endLabelText={'DATA FINAL'}
            />
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="commercial">COMERCIAL</label>
              <ReactSelect
                isSearchable
                isClearable
                options={commercialsOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                value={selectedCommercial ?? ''}
                defaultValue={selectedCommercial}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedCommercial(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="neighborhood">REGIONAL</label>
              <ReactSelect
                isSearchable
                isClearable
                options={regionalsOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                value={selectedRegional ?? ''}
                defaultValue={selectedRegional}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedRegional(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="neighborhood">EXECUTIVO</label>
              <ReactSelect
                isSearchable
                isClearable
                options={executivesOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => 'Não há registros'}
                value={selectedExecutive ?? ''}
                defaultValue={selectedExecutive}
                onChange={(val: any) => {
                  setSelectedExecutive(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  );
}

export default SidebarVisitsAnalysisFilter;
