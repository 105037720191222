import axios from 'axios';
import { BaseService } from './BaseService';

export class ViaCepService extends BaseService {
    async get(cep: string) {
        try {
            const _cep = cep?.replace(/[^\w\s]/gi, '');
            return this.handleResponse(await axios.get(`https://viacep.com.br/ws/${_cep}/json`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}