import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

interface SkeletonBarProps {
  numberOfBars?: number;
  minHeight?: number;
  maxHeight?: number;
}

const SkeletonBar: FC<SkeletonBarProps> = ({ numberOfBars = 5, minHeight = 50, maxHeight = 300 }) => {

  return (
    <div className="SkeletonBar" data-testid="SkeletonBar">
      <div className='row align-items-end'>
        {
          [...Array(numberOfBars)].map((x: any, key: number) =>
            <div className='col' key={Number(key)}>
              <Skeleton height={Math.random() * (maxHeight - minHeight) + minHeight} />
            </div>)
        }
      </div>
    </div>
  )
};

export default SkeletonBar;
