import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useContext, useState } from "react";

interface ContextProps {
    showSidebarFilter: boolean,
    setShowSidebarFilter: Dispatch<SetStateAction<boolean>>
}

export const SidebarFilterContext = createContext<ContextProps>({} as ContextProps);

interface SidebarFilterProviderProps {
    children: ReactNode;
}

export const SidebarFilterProvider = ({ children }: SidebarFilterProviderProps) => {
    const [showSidebarFilter, setShowSidebarFilter] = useState<boolean>(true);

    useEffect(() => {
        setShowSidebarFilter(true);
    }, []);

    return (
        <SidebarFilterContext.Provider value={{
            showSidebarFilter,
            setShowSidebarFilter
        }}>
            {children}
        </SidebarFilterContext.Provider>
    );
};

export const useSidebarFilter = () => useContext(SidebarFilterContext);