import React, { FC, useEffect, useState } from 'react';
import './ChartPartnerParticipation.scss';
import { useBiPartner } from '../../../../../../providers/BI/Dashboard/BiPartnerProvider';
import { Card, Col, Row } from 'react-bootstrap';
import PieChart from '../../../PieChart/PieChart';
import { nameAbbreviation } from '../../../../../../utils/NameAbbreviation';
import { useBiDashboard } from '../../../../../../providers/BI/Dashboard/BiDashboardProvider';
import { BI_VIEWS_ENUM } from '../../../../../../models/BI/Dashboard/BiProductionParamsModel';
import SkeletonCircle from '../../../../../SkeletonCircle/SkeletonCircle';

interface ChartPartnerParticipationProps { }

const ChartPartnerParticipation: FC<ChartPartnerParticipationProps> = () => {

  const [monthData, setMonthData] = useState<any[]>([]);
  const [annualData, setAnnualData] = useState<any[]>([]);
  const [isMonthLoading, setIsMonthLoading] = useState<boolean>(false);
  const [isAnnualLoading, setIsAnnualLoading] = useState<boolean>(false);

  const {
    monthlyProductionPartners,
    annualProductionPartners,
    setMonthlyProductionPartners,
    setAnnualProductionPartners
  } = useBiPartner();

  const {
    handleListMonthlyProduction,
    handleListAnnualProduction,
    params } = useBiDashboard();

  useEffect(() => {
    setIsMonthLoading(true);
    setIsAnnualLoading(true);
    handleListMonthlyProduction({
      ...params,
      view: BI_VIEWS_ENUM.AGENT,
      group: {}
    }).then((x: any) => {
      if (x) {
        setMonthlyProductionPartners(x);
      }
    }).finally(() => setIsMonthLoading(false));

    handleListAnnualProduction({
      ...params,
      view: BI_VIEWS_ENUM.AGENT,
      group: {}
    }).then((x: any) => {
      if (x) {
        setAnnualProductionPartners(x);
      }
    }).finally(() => setIsAnnualLoading(false));
  }, [params]);

  useEffect(() => {
    if (monthlyProductionPartners?.items &&
      monthlyProductionPartners?.items?.length > 0 &&
      monthlyProductionPartners?.items[0]?.periods &&
      monthlyProductionPartners?.items[0]?.periods?.length > 0
    ) {
      let _data = monthlyProductionPartners?.items?.map((item) => item?.periods ?
        { value: Number(item?.periods[item?.periods?.length - 1].totalProductionPercentage), label: nameAbbreviation(item?.name ?? '', 13) } :
        {}) ?? [];
      _data = _data?.sort((a: any, b: any) => a?.value > b?.value ? -1 : 1)?.filter((x: any, index: number) => index <= 9);
      setMonthData(_data);
    }
  }, [monthlyProductionPartners]);

  useEffect(() => {
    if (annualProductionPartners?.items &&
      annualProductionPartners?.items?.length > 0 &&
      annualProductionPartners?.items[0]?.periods &&
      annualProductionPartners?.items[0]?.periods?.length > 0
    ) {
      let _data = annualProductionPartners?.items?.map((item) => item?.periods ?
        { value: Number(item?.periods[item?.periods?.length - 1].totalProductionPercentage), label: nameAbbreviation(item?.name ?? '', 13) } :
        {}) ?? [];
      _data = _data?.sort((a: any, b: any) => a?.value > b?.value ? -1 : 1)?.filter((x: any, index: number) => index <= 9);
      setAnnualData(_data);
    }
  }, [annualProductionPartners]);


  return (
    <div className="ChartPartnerParticipation" data-testid="ChartPartnerParticipation">
      <Row>
        <Col xl="6" className="mb-4">
          <Card className="h-100 shandow-xs-none">
            <Card.Header className="pb-0">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h6 className="surtitle text-gray m-0 text-start">PARTICIPAÇÃO MENSAL</h6>
                </div>
                <div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              {!isMonthLoading ?
                <PieChart
                  labels={monthData?.map((item) => item?.label)}
                  data={monthData?.map((item) => item?.value?.toFixed(2))}
                  // max={Math.trunc(Math.max.apply(Math, mock?.producoes?.map(o => o?.crescimento)))}
                  height={450}
                /> :
                <SkeletonCircle width={300} />}
            </Card.Body>
          </Card>
        </Col>

        <Col xl="6" className="mb-4">
          <Card className="h-100 shandow-xs-none">
            <Card.Header className="pb-0">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h6 className="surtitle text-gray m-0 text-start">PARTICIPAÇÃO ANUAL</h6>
                </div>
                <div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              {!isAnnualLoading ?
                <PieChart
                  labels={annualData?.map((item) => item?.label)}
                  data={annualData?.map((item) => item?.value?.toFixed(2))}
                  // max={Math.trunc(Math.max.apply(Math, mock?.producoes?.map(o => o?.crescimento)))}
                  height={450}
                /> :
                <SkeletonCircle width={300} />}
            </Card.Body>
          </Card>
        </Col>


      </Row>
    </div>
  );
};

export default ChartPartnerParticipation;
