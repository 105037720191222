import axios from "axios";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/app/visit/analysis';

export class VisitsAnalysisDetailsService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await axios.get(`/mock/detail.json`));
        } catch (e: any) {
            return this.catchHandler(e);
        }

        // try {
        //     return this.handleResponse(await api.post(`${URL}/list`, params));
        // } catch (e: any) {
        //     return this.catchHandler(e);
        // }
    }

    async listChartByDay(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/quantityPerDay`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async exportByDay(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/export/quantityPerDay`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listChartByTypeContact(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/quantityPerTypeContact`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async exportTypeContact(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/export/quantityPerTypeContact`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}