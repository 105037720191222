import { FC, useEffect, useState } from 'react';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { Button, Col, FormGroup, Row } from 'react-bootstrap';
import { useMonthlyGoal } from '../../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalProvider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validate';
import moment from 'moment';
import InputDropzone from '../../../InputDropzone/InputDropzone';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/locale/pt';
import { customStyles } from '../../../../models/SelectCustomStyles';
import ReactSelect from 'react-select';
import './ModalMonthlyGoalImport.scss'
import { useMonthlyGoalPeriodConfiguration } from '../../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalPeriodConfigurationProvider';
import { monthlyGoalAccessProfileOptions } from '../../../../models/PanelConnects/MonthlyGoalAccessProfileOptions';
registerLocale('pt', pt);

interface ModalMonthlyGoalImportProps {
  show: boolean;
  onClose: () => void;
}

const ModalMonthlyGoalImport: FC<ModalMonthlyGoalImportProps> = ({ show, onClose }) => {
  const [selectedPeriod, setSelectedPeriod] = useState<any>();
  const [selectedAccessProfileId, setSelectedAccessProfileId] = useState<any>();
  const [selectedGoal, setSelectedGoal] = useState<any>();
  const [periodOptions, setPeriodOptions] = useState<any[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [fileTypes] = useState<any>({
    'application/vnd.ms-excel': ['.xls', '.xlsx', '.csv'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
  });

  const { handleImport, isLoadingImport } = useMonthlyGoal();
  const { listGoals, setGoals, goalsOptions } = useMonthlyGoalPeriodConfiguration();


  const { handleSubmit, setValue, formState: { errors } } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleClose = async () => {
    onClose();
  }

  useEffect(() => {
    if (periodOptions.length === 0) {
      getPeriodOptions();
    }
  }, []);

  useEffect(() => {
    if (files?.length > 0) {
      setValue('file', files[0]?.path);
    } else {
      setValue('file', '');
    }
  }, [files]);

  useEffect(() => {
    setValue('closingDate', selectedPeriod);
    setValue('accessProfileId', selectedAccessProfileId);
    setSelectedGoal(null);
    if (selectedPeriod && selectedAccessProfileId) {
      listGoals(moment(selectedPeriod?.value, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'), selectedAccessProfileId?.value, null, true);
    } else {
      setGoals([]);
    }
  }, [selectedPeriod, selectedAccessProfileId]);

  useEffect(() => {
    setValue('goal', selectedGoal);
  }, [selectedGoal]);

  useEffect(() => {
    if (show) {
      setValue('closingDate', '');
      setSelectedPeriod(null);
      setSelectedAccessProfileId(null);
      setSelectedGoal(null);
    }
  }, [show]);

  const getPeriodOptions = () => {
    Array.from(Array(6)).forEach((x, i) => {
      periodOptions?.push({
        label: moment(new Date()).add(i - 1, 'M').format('MMMM/YY')?.toUpperCase(),
        value: moment(new Date()).add(i - 1, 'M').format('YYYY-MM')
      });
    });
  }

  const onSubmit = async (data: any) => {
    const _data: any = {};

    _data.closingDate = data.closingDate?.value;
    _data.accessProfileId = data.accessProfileId?.value;
    _data.connectGoalId = data.goal?.value;
    _data.file = data.file;

    const result = await handleImport(_data, files);

    if (result) {
      handleClose();
    }
  }

  return (
    <ModalDefault
      title={'Importar Meta'}
      show={show}
      onClose={handleClose}
      sizeModal={'lg'}
      showFooter={true}
      buttonText={isLoadingImport ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoadingImport}
      disabledSubmit={isLoadingImport}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
    >
      <div className="ModalMonthlyGoalImport" data-testid="ModalMonthlyGoalImport">
        <Row>
          <Col md={6}>
            <FormGroup className="form-group">
              <label htmlFor='period'>PERÍODO *</label>
              <ReactSelect
                name='period'
                value={selectedPeriod}
                isSearchable
                isClearable={false}
                options={periodOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedPeriod(val);
                }}
                styles={customStyles}
              />
              <ErrorMessage name="Período" type={errors?.closingDate?.type?.toString()} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="form-group">
              <label>TIPO COMERCIAL *</label>
              <ReactSelect
                name='commercialType'
                value={selectedAccessProfileId}
                isClearable={false}
                options={monthlyGoalAccessProfileOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedAccessProfileId(val);
                }}
                styles={customStyles}
              />
              <ErrorMessage name='Tipo Comercial' type={errors?.accessProfileId?.type?.toString()} />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor='goal'>META</label>
              <ReactSelect
                name='goal'
                value={selectedGoal}
                isSearchable
                isClearable={true}
                options={goalsOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedGoal(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup className="form-group mb-1">
              <InputDropzone
                label="Arquivo"
                name="file"
                files={files}
                setFiles={setFiles}
                types={fileTypes}
                maxSize={157286400}
                error={!!errors?.file}
              />
              <ErrorMessage name="Arquivo" type={errors?.file?.type?.toString()} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col className='text-end'>
            <a
              className='model-file'
              href={`${process.env.PUBLIC_URL}/model/PanelConnects/MonthlyGoal/modeloImportarMetaGlobal.csv`}
              download
            >
              Arquivo Modelo
            </a>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
}

export default ModalMonthlyGoalImport;