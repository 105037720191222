import { FC } from 'react';
import './Unauthorized.scss';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useStyle } from '../../providers/Style/StyleProvider';

interface UnauthorizedProps { }

const Unauthorized: FC<UnauthorizedProps> = () => {
  const { urlDomus } = useStyle();

  return (
    <main className="Unauthorized main-content mt-0" data-testid="Unauthorized">
      <div className="page-header min-vh-100">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={7} className="mx-auto text-center">
              <h1 className="display-1 text-bolder text-primary"><i className='fas fa-lock'></i></h1>

              <h2>Acesso não autorizado</h2>
              <p className="lead">Para acessar a página você deve fazer a validação de 2 fatores.</p>

              <a href={`${urlDomus}`}>
                <Button className="btn btn-primary text-white mt-4 fadeIn2 fadeInBottom">Voltar para o login</Button>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </main>
  );
}

export default Unauthorized;
