import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { PagesPaginateModel } from "../models/Paginate/PagesPaginate";
import { ToastSettings } from "../utils/ToastSettings";
import { ExampleService } from "../services/ExampleService";

interface ContextProps {
    example: any,
    setExample: Dispatch<SetStateAction<any>>,
    examples: any[],
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleFetch: (data: any) => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>,
}

export const ExampleContext = createContext<ContextProps>({} as ContextProps);

interface ExampleProviderProps {
    children: ReactNode;
}

export const ExampleProvider = ({ children }: ExampleProviderProps) => {
    const [example, setExample] = useState<any>({} as any);
    const [examples, setExamples] = useState<any[]>([]);
    const [params, setParams] = useState<any>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const service = new ExampleService();
    const location = useLocation();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/example/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response?.message || _Error);
            }

            setExamples(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar os exemplos do sistema.');
        }
    }, [params, location.pathname]);

    const handleFetch = async (data: any) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.fetch(data);
            if (!!_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setError('Houve um erro ao salvar o exemplo');
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar o exemplo');
            return false;
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <ExampleContext.Provider value={{
            example,
            setExample,
            examples,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleFetch,
            handleDelete
        }}>
            {children}
        </ExampleContext.Provider>
    );
}

export const useExample = () => useContext(ExampleContext);