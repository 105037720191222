import FileSaver from "file-saver";
import api from "../../Api";
import { BaseService } from "../../BaseService";
import { ProductionRangeExcelParams } from "../../../models/Visits/IndicatorManagement/ProductionRangeExcelParams";

const URL = 'api/domus/pgi/rangeProduction';
export class IndicatorManagementProductionRangeService extends BaseService {

    async getProductionRange(commercialId: string, userId: string = ''): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, { userId, commercialId }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getExcel(params: ProductionRangeExcelParams): Promise<any> {
        try {
            const response = await api.post(`${URL}/${params.type}`, params, { responseType: 'blob' });
            const filename = response.headers['content-disposition'].split('filename=')[1];
            FileSaver.saveAs(response.data, filename, { autoBom: false });
            return [true, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}