import { Dispatch, ReactNode, SetStateAction, createContext, useCallback, useContext, useEffect, useState } from "react";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";
import { CampaignParams } from "../../../models/Commissioning/Campaign/CampaignParams";
import { CampaignModel } from "../../../models/Commissioning/Campaign/CampaignModel";
import { CampaignService } from "../../../services/Commissioning/Campaign/CampaignService";

interface ContextProps {
    campaign: CampaignModel,
    setCampaign: Dispatch<SetStateAction<CampaignModel>>,
    campaigns: CampaignModel[],
    params: CampaignParams,
    setParams: Dispatch<SetStateAction<CampaignParams>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleListCampaignsOptions: () => void,
    campaignsOptions: ReactSelectOptions[],
}

export const CampaignContext = createContext<ContextProps>({} as ContextProps);

interface CampaignProviderProps {
    children: ReactNode;
}

export const CampaignProvider = ({ children }: CampaignProviderProps) => {
    const [campaign, setCampaign] = useState<CampaignModel>({} as CampaignModel);
    const [campaigns, setCampaigns] = useState<CampaignModel[]>([]);
    const [params, setParams] = useState<CampaignParams>({});
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [campaignsOptions, setCampaignsOptions] = useState<any[]>([]);

    const service = new CampaignService();
    const location = useLocation();


    const handleList = useCallback(async () => {

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);

            setIsLoading(false);

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setCampaigns(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar as campanhas');
        }
    }, [params, location.pathname]);

    const handleListCampaignsOptions = async () => {

        try {
            setIsLoading(true);

            const [_Response, _Error] = await service.listAll();

            setIsLoading(false);

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setCampaigns(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        }
        catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar as campanhas');
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        !!params?.financialId && handleList().then();
    }, [handleList, params]);

    useEffect(() => {
        setCampaignsOptions(
            campaigns?.map((item: CampaignModel) => {
                return { label: item.description, value: item.id };
            })
        )
    }, [campaigns]);

    return (
        <CampaignContext.Provider value={{
            campaign,
            setCampaign,
            campaigns,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleListCampaignsOptions,
            campaignsOptions
        }}>
            {children}
        </CampaignContext.Provider>
    );
}

export const useCampaign = () => useContext(CampaignContext);