import React, { FC } from 'react';
import './VisitsRegisterTwoBarChart.scss';
import { Bar } from 'react-chartjs-2';
import FormatMoney from '../../../../../utils/FormatMoney';

interface VisitsRegisterTwoBarChartProps {
  labels: string[],
  data: any,
  maxY1: number,
  maxY2: number,
  height?: number,
}

const VisitsRegisterTwoBarChart: FC<VisitsRegisterTwoBarChartProps> = ({ data, labels, maxY1, maxY2, height }) => (
  <div className="VisitsRegisterTwoBarChart" data-testid="VisitsRegisterTwoBarChart">
    <Bar
      data={{
        labels: labels,
        datasets: data,
      }}
      options={{
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          datalabels: {
            color: '#333',
            backgroundColor: '#fff',
            font: {
              weight: 'bold',
              size: 10,
            },
            borderRadius: 3,
            anchor: 'end',
            align: 'top',
            clamp: true,
            formatter: (value, context) => {
              switch (context.datasetIndex) {
                case 0:
                  return value;
                case 1:
                  return FormatMoney(value);
              }
            }
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: (data) => {
                switch (data.datasetIndex) {
                  case 0:
                    return 'Quantidade: ' + data.raw;
                  case 1:
                    return 'Produção: ' + FormatMoney(Number(data.raw) || 0);
                }
              }
            }
          }
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              font: {
                weight: 'bold'
              }
            }
          },
          y: {
            grid: {
              display: false,
            },
            min: 0,
            max: maxY1,
            border: {
              display: true
            }
          },
          secondYAxis: {
            axis: 'y',
            position: 'right',
            max: maxY2,
            grid: {
              display: false
            }
          },
        },
        layout: {
          padding: {
            top: 25
          }
        }
      }}
      height={height}
    />
  </div>
);

export default VisitsRegisterTwoBarChart;
