import { FC, useEffect, useRef, useState } from 'react';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { Col, FormGroup, Row } from 'react-bootstrap';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import InputSearchPartner from '../../Partner/InputSearchPartner/InputSearchPartner';
import { usePartnerRegistrationUpdate } from '../../../../providers/Registrations/PartnerRegistrationUpdate/PartnerRegistrationUpdateProvider';
import { PartnerRegistrationUpdateFilterParams } from '../../../../models/Registrations/PartnerRegistrationUpdate/PartnerRegistrationUpdateFilterParams';
import makeAnimated from 'react-select/animated';
import { useCommercial } from '../../../../providers/Registrations/User/UserCommercialProvider';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';

interface SidebarPartnerRegistrationUpdateFilterProps { }

// @ts-ignore
export const defaultValues = {
  userId: '',
  executiveId: null,
  regionalId: null,
  commercialId: null,
  startDate: '',
  endDate: '',
} as PartnerRegistrationUpdateFilterParams;

const SidebarPartnerRegistrationUpdateFilter: FC<SidebarPartnerRegistrationUpdateFilterProps> = () => {
  const [startDate, setStartDate] = useState<any>(moment(new Date()).format('YYYY-MM-01 HH:mm:ss'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
  const [userId, setUserId] = useState<string>('');
  const [selectedCommercial, setSelectedCommercial] = useState<any>(null);
  const [selectedRegional, setSelectedRegional] = useState<any>(null);
  const [selectedExecutive, setSelectedExecutive] = useState<any>(null);

  const { params, setParams } = usePartnerRegistrationUpdate();
  const { commercialsOptions, handleList: handleListCommercial, regionalsOptions, executivesOptions, handleListRegional, handleListExecutive, setParams: setParamsCommercial } = useCommercial();

  const animatedComponents = makeAnimated();
  const refSubmitButtom = useRef<HTMLButtonElement>(null);

  const { handleSubmit, setValue, control } = useForm<any>({ defaultValues });

  useEffect(() => {
    if (commercialsOptions && commercialsOptions?.length > 1) {
      refSubmitButtom?.current?.click();
    }
    if (commercialsOptions && commercialsOptions?.length === 1) {
      setSelectedCommercial(commercialsOptions[0]);
    }
  }, [commercialsOptions]);

  useEffect(() => {
    if (regionalsOptions && regionalsOptions?.length > 1) {
      refSubmitButtom?.current?.click();
    }
    if (regionalsOptions && regionalsOptions?.length === 1) {
      setSelectedRegional(regionalsOptions[0]);
    }
  }, [regionalsOptions]);

  useEffect(() => {
    if (executivesOptions && executivesOptions?.length > 1) {
      refSubmitButtom?.current?.click();
    }
    if (executivesOptions && executivesOptions?.length === 1) {
      setSelectedExecutive(executivesOptions[0]);
    }
  }, [executivesOptions]);

  useEffect(() => {
    setValue('commercialId', selectedCommercial)
  }, [selectedCommercial]);

  useEffect(() => {
    if (selectedRegional) {
      setValue('regionalId', selectedRegional);
      setParamsCommercial({regionalId: selectedRegional?.value});
    }
    else {
      setParamsCommercial({});
    }

    handleListCommercial();
  }, [selectedRegional]);

  useEffect(() => {
    if (selectedExecutive) {
      setValue('executiveId', selectedExecutive);
      handleListRegional({executiveId: selectedExecutive?.value});
    }
    else {
      handleListRegional({});
    }
  }, [selectedExecutive]);

  useEffect(() => {
    handleListExecutive();
    handleListRegional({});
    handleListCommercial().then();
  }, [])

  const onSubmit = async (data: PartnerRegistrationUpdateFilterParams) => {
    data.userId = data?.userId ? data?.userId?.value : '';
    data.regionalId = data?.regionalId ? data?.regionalId?.value : '';
    data.executiveId = data?.executiveId ? data?.executiveId?.value : '';
    data.commercialId = data?.commercialId ? data?.commercialId?.value : '';
    data.startDate = data?.startDate ? moment(data?.startDate).format('YYYY-MM-DD') : '';
    data.endDate = data?.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : '';

    setParams({ ...params, ...data, ...{ page: 1 } });
  }

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarPartnerRegistrationUpdateFilter" data-testid="SidebarPartnerRegistrationUpdateFilter">
        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="userId">PARCEIRO</label>
              <InputSearchPartner
                name="userId"
                control={control}
                setValue={setValue}
                valuePartner={userId}
                setValuePartner={setUserId}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-1">
        <Col xl={12}>
            <FormGroup className="form-group">
              <label htmlFor="executiveId">EXECUTIVO</label>
              {control && (
                  <Controller
                    name="executiveId"
                    control={control}
                    render={({ field: { onChange, name, ref } }) => (
                        <ReactSelect
                          ref={ref}
                          name={name}
                          value={selectedExecutive}
                          isClearable={executivesOptions?.length > 1}
                          isSearchable
                          options={executivesOptions}
                          components={animatedComponents}
                          noOptionsMessage={() => 'Não há registros'}
                          placeholder="Selecione..."
                          className={`form-control p-0`}
                          onChange={(val: any) => {
                            onChange(val?.value ?? null);
                            setSelectedExecutive(val);
                          }}
                          styles={customStyles}
                        />
                    )}
                  />
              )}
            </FormGroup>
          </Col>
          <Col xl={12}>
            <FormGroup className="form-group">
              <label htmlFor="regionalId">REGIONAL</label>
              {control && (
                  <Controller
                    name="regionalId"
                    control={control}
                    render={({ field: { onChange, name, ref } }) => (
                        <ReactSelect
                          ref={ref}
                          name={name}
                          value={selectedRegional}
                          isClearable={regionalsOptions?.length > 1}
                          isSearchable
                          options={regionalsOptions}
                          components={animatedComponents}
                          noOptionsMessage={() => 'Não há registros'}
                          placeholder="Selecione..."
                          className={`form-control p-0`}
                          onChange={(val: any) => {
                            onChange(val?.value ?? null);
                            setSelectedRegional(val);
                          }}
                          styles={customStyles}
                        />
                    )}
                  />
              )}
            </FormGroup>
          </Col>
          <Col xl={12}>
            <FormGroup className="form-group">
              <label htmlFor="commercialId">COMERCIAL</label>
              <Controller
                name="commercialId"
                control={control}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    value={selectedCommercial}
                    isSearchable
                    isClearable={commercialsOptions?.length > 1}
                    options={commercialsOptions}
                    placeholder="Selecione..."
                    className={`form-control p-0`}
                    components={animatedComponents}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={(val: any) => {
                      onChange(val?.value ?? null);
                      setSelectedCommercial(val);
                    }}
                    styles={customStyles}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              startLabelText={'DATA INICIAL'}
              endLabelText={'DATA FINAL'}
            />
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  );
}

export default SidebarPartnerRegistrationUpdateFilter;
