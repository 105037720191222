import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import './InputDateRange.scss';
import { useAuth } from '../../providers/AuthProvider';
import moment from 'moment';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import 'moment/locale/pt-br';
import DatePicker from 'react-date-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';

interface InputDateRangeProps {
  startDate: any;
  setStartDate: Dispatch<SetStateAction<any>>;
  endDate?: any;
  setEndDate?: Dispatch<SetStateAction<any>>;
  startLabelText?: string;
  endLabelText?: string;
  maxEndDate?: string;
  errors?: any;
  disableStartDate?: boolean;
  disableEndDate?: boolean;
  setValue?: any;
  viewEndDate?: boolean;
  nameStartDate?: string;
  nameEndDate?: string;
  showStartDateClearButton?: boolean;
}

const InputDateRange: FC<InputDateRangeProps> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  startLabelText = 'Período Inicial',
  endLabelText = 'Período Final',
  maxEndDate,
  errors,
  disableEndDate = false,
  disableStartDate = false,
  viewEndDate = true,
  nameStartDate = 'startDate',
  nameEndDate = 'endDate',
  setValue,
  showStartDateClearButton = true
}) => {
  const { onInvalid } = useAuth();

  moment.locale('pt-br');

  useEffect(() => {
    nameStartDate && setValue(nameStartDate, startDate);
    nameEndDate && setValue(nameEndDate, endDate);
  }, [startDate, endDate]);

  return (
    <Row className="InputDateRange position-relative input-daterange datepicker gap-2" data-testid="InputDateRange">
      <Col className={`${viewEndDate ? 'pe-0' : ''}`}>
        <FormGroup className="form-group mb-0">
          {startLabelText && (
            <label className="form-control-label" htmlFor={nameStartDate}>
              {startLabelText}
            </label>
          )}
          <DatePicker
            onChange={setStartDate}
            value={startDate ? new Date(startDate) : null}
            defaultValue={null}
            format="dd/MM/y"
            locale="pt-BR"
            minDate={undefined}
            maxDate={endDate ? new Date(endDate) : new Date()}
            className={`form-control left ${onInvalid(errors?.[nameStartDate])}`}
            clearIcon={showStartDateClearButton ? <FontAwesomeIcon icon={faRemove} /> : null}
          />
          <ErrorMessage name="Período Inicial" type={errors?.[nameStartDate]?.type} />
        </FormGroup>
      </Col>

      {viewEndDate && (
        <Col className="ps-0">
          <FormGroup className="form-group">
            {endLabelText && (
              <label className="form-control-label" htmlFor={nameEndDate}>
                {endLabelText}
              </label>
            )}
            <DatePicker
              onChange={setEndDate}
              value={endDate ? new Date(endDate) : null}
              defaultValue={null}
              format="dd/MM/y"
              locale="pt-BR"
              minDate={new Date(startDate) || undefined}
              maxDate={maxEndDate ? new Date(maxEndDate) : undefined}
              className={`form-control right ${onInvalid(errors?.[nameEndDate])}`}
            />
            <ErrorMessage name="Período Final" type={errors?.[nameEndDate]?.type} />
          </FormGroup>
        </Col>
      )}
    </Row>
  );
}

export default InputDateRange;
