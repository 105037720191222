import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AccessPageModel } from "../../../models/Registrations/Access/AccessPageModel";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { useLocation } from "react-router-dom";
import { AccessPageParams } from "../../../models/Registrations/Access/AccessPageParams";
import { AccessPageService } from "../../../services/Registrations/Access/AccessPageService";
import { Generics } from "../../../models/Generics";
import _ from "underscore";

export const AccessPageContext = createContext<AccessPageModel[] | any>({});

export const AccessPageProvider = (props: any) => {
    const [params, setParams] = useState<AccessPageParams>({ pageNumber: 1 });
    const [pages, setPages] = useState<AccessPageModel[]>([]);
    const [pagesViews, setPagesViews] = useState<AccessPageModel[]>([]);
    const [page, setPage] = useState<AccessPageModel>();
    const [error, setError] = useState<string>('');
    const [errorOrder, setErrorOrder] = useState<string>('');
    const [pagesPaginate, setPagesPaginate] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const service = useMemo(() => new AccessPageService(), []);
    const location = useLocation();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/registrations/access-level/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.getAll(params);
            setIsLoading(false);
            if (!!_Error) {
                return setError(_Response || _Error);
            }

            setError('');
            setPages(Generics.toArray<AccessPageModel>(_Response.data).filter((x: AccessPageModel) => !x.parent)
                ?.sort((a, b) => (a.order || 0) < (b.order || 0) ? -1 : 1));
            setPagesViews(Generics.toArray<AccessPageModel>(_Response.data)
                ?.sort((a, b) => (a.order || 0) < (b.order || 0) ? -1 : 1));
            setPagesPaginate(_Response.pages);

            return true;

        } catch (err) {
        }
    }, [location.pathname, params]);

    const handleDetails = async (pageId: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.details(pageId);
            setIsLoading(false);
            if (!!_Error) {
                return setError(_Response || _Error);
            }

            setError('');
            setPage(_Response.data);

            return true;

        } catch (err) {
        }
    };

    const handleSave = async (payload: AccessPageModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.create(payload);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }

            setError('');
            handleList().then();

            return [true, null];

        } catch (err) {
            return [false, null];
        }
    };

    const handleDelete = async (pageId: number, deleteViews: boolean, deleteActions: boolean) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(pageId, deleteViews, deleteActions);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }
            setPages(
                _.reject(pages ?? [], d => d.id === pageId),
            );
            setError('');
            return [true, null];

        } catch (err) {
            return [false, null];
        }
    };

    const updateOrders = async (items: AccessPageModel[], result: any) => {
        try {
            setIsLoading(true);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            items.map((x: AccessPageModel, index: number) => x.order = ++index);

            const payload: { id: number, order: number }[] = [];
            items.forEach((x: AccessPageModel) => payload.filter((item: AccessPageModel) => !!item?.id && item?.id > 0).push({ id: x.id || 0, order: x.order || 0 }));

            if (payload?.length > 0) {
                const [_Response, _Error] = await service.updateOrderPages(payload);
                setIsLoading(false);

                if (!!_Error) {
                    setErrorOrder(_Response || _Error);
                    return [false, (_Response || _Error)]
                }

                setErrorOrder('');
            }

            setIsLoading(false);
            return [true, null];
        } catch (err) {
            return [false, null];
        }
    }

    return (
        <AccessPageContext.Provider value={{
            pages,
            setPages,
            pagesViews,
            setPagesViews,
            page,
            setPage,
            error,
            setError,
            errorOrder,
            setErrorOrder,
            pagesPaginate,
            setPagesPaginate,
            params,
            setParams,
            handleList,
            handleDetails,
            handleDelete,
            handleSave,
            updateOrders,
            isLoading
        }}>
            {props.children}
        </AccessPageContext.Provider>
    )
}

export const useAccessPage = () => useContext(AccessPageContext);