import React, { FC, useEffect, useState } from 'react';
import './ModalCampaignImport.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import FormCampaign from '../ModalCampaignInsert/FormCampaign/FormCampaign';
import { useForm } from 'react-hook-form';
import { CampaignCreateModel } from '../../../../models/Marketplace/Campaign/CampaignCreateModel';
import { Button, Card, Row, Spinner } from 'react-bootstrap';
import InputDropzone from '../../../InputDropzone/InputDropzone';
import { useCampaign } from '../../../../providers/Marketplace/Campaign/CampaignProvider';

interface ModalCampaignImportProps {
  show: boolean,
  onClose: any,
}

const DefaultFormValuesCreate: CampaignCreateModel = {
  campaign_name: '',
  campaign_obs: '',
  campaign_origin: '',
  campaign_sanitize: null,
  campaign_deadline: 0,
  campaign_gen_id: null,
  campaign_generate_again_in_x_days: null
}

const ModalCampaignImport: FC<ModalCampaignImportProps> = ({show, onClose}) => {
  const {
    handleCreateCampaignExcel,
    loading,
    options
} = useCampaign();

  const {
      reset: resetCreate,
      register: registerCreate,
      setValue: setValueCreate,
      handleSubmit: handleSubmitCreate,
      control: controlCreate,
      formState: { errors: errorsCreate }
  } = useForm<CampaignCreateModel>({
      defaultValues: DefaultFormValuesCreate
  });
  const [files, setFiles] = useState<any[]>([]);
  const [fileTypes, setFileTypes] = useState<any>({
    'application/vnd.ms-excel': ['.xlsx'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  });

  const handleCloseCreate = () => {
      resetCreate();
      onClose();
  }

  const {
    handleDownloadModel
  } = useCampaign();

  const onCreateSubmit = async (data: CampaignCreateModel) => {
      const _data = JSON.parse(JSON.stringify(data));

      _data.campaign_sanitize = _data.campaign_sanitize === 1;
      _data.campaign_deadline = Number(_data.campaign_deadline);
      _data.campaign_generate_again_in_x_days = !!_data.campaign_generate_again_in_x_days ? Number(_data.campaign_generate_again_in_x_days) : null;

      const response = await handleCreateCampaignExcel(_data, files[0]).then();
      response && resetCreate(); onClose();
  }

return (
  <>
      <ModalDefault
          title={"Importar Campanha"}
          show={show}
          onClose={handleCloseCreate}
          sizeModal={'xl'}
          showFooter={true}
          handleAdditionalSubmit={handleDownloadModel}
          buttonText='Importar campanha'
          buttonTextAdditional='Baixar Modelo'
          buttonAdditionalBackgroundColor='btn-secondary'
          handleSubmit={handleSubmitCreate(onCreateSubmit)}
          backdrop="static"
          backgroundColor="#f8f9fa"
      >   
          <Row className="ImportCampaign mb-2" data-testid="ImportCampaign">
            <Card>
              <Card.Header className="pt-3 pb-0 px-2">
                Planilha de Importação
              </Card.Header>
              <Card.Body className="py-1 mb-3">
                <InputDropzone 
                  label="Arquivo de Importação"
                  name="file"
                  files={files}
                  setFiles={setFiles}
                  types={fileTypes}
                  maxSize={157286400}
                />
              </Card.Body>
            </Card>
          </Row>
          <FormCampaign
              show={show}
              register={registerCreate}
              setValue={setValueCreate}
              errors={errorsCreate}
              control={controlCreate}
              showGenerateAgain={false}
          ></FormCampaign>
      </ModalDefault>
  </>
)
};

export default ModalCampaignImport;
