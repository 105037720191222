import * as yup from "yup";
import { ProposalRuleOperationEnum } from "../../../../../enums/Proposals/Rule/Operation/ProposalRuleOperationEnum";

export const schema = yup.object().shape({
    operationId: yup
        .object()
        .required()
        .test('typeError', 'Ação é obrigatório', (item: any) => item?.value > 0),
    typeInsertId: yup
        .object()
        .when('operationId', {
            is: (item: any) => item?.value > 0 && item?.value !== ProposalRuleOperationEnum.DO_NOT_PROCESS,
            then: (schema) => schema.test('typeError', 'Tipo de Inserção é obrigatório', (item: any) => item?.value > 0).required(),
            otherwise: (schema) => schema.notRequired(),
        }),
    headerFieldId: yup
        .array()
        .when('operationId', {
            is: (item: any) => item?.value > 0 && item?.value !== ProposalRuleOperationEnum.DO_NOT_PROCESS,
            then: (schema) => schema.when('typeInsertId', {
                is: (item: any) => item?.value > 0 && item?.value === 2,
                then: (schema) => schema.test('typeError', 'Campo da Planilha é obrigatório', (item: any) => item?.length > 0).of(yup.object()),
                otherwise: (schema) => schema.test('typeError', 'Valores é obrigatório', (item: any) => item?.length > 0).of(yup.string()),
            }).required(),
            otherwise: (schema) => schema.notRequired(),
        })
    ,
    tableColumnsNameId: yup
        .object()
        .when('operationId', {
            is: (item: any) => item?.value > 0 && item?.value !== ProposalRuleOperationEnum.DO_NOT_PROCESS,
            then: (schema) => schema.test('typeError', 'Campo Interno é obrigatório', (item: any) => !!item?.value).required(),
            otherwise: (schema) => schema.notRequired(),
        }),
});