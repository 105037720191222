import { FC, useEffect, useState } from 'react';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../../../../providers/AuthProvider';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Col, FormCheck, FormGroup, Row } from 'react-bootstrap';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { schema } from './Validate';
import { useReport } from '../../../../providers/Reports/Report/ReportProvider';
import { ReportModel } from '../../../../models/Reports/Report/ReportModel';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import makeAnimated from 'react-select/animated';
import { extensions } from '../../../../enums/Reports/Report/Extensions';

interface ModalReportInsertProps {
  show: boolean;
  onClose: () => void;
}

type FormValues = {
  id?: number;
  name?: string;
  description?: string;
  identifier?: string;
  prefixFilename?: string;
  extension?: string;
  classJob?: string;
}

const ModalReportInsert: FC<ModalReportInsertProps> = ({ show, onClose }) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [selectedExtension, setSelectedExtension] = useState<any>(null);
  const [selectedMicroservice, setSelectedMicroservice] = useState<any>(null);

  const { report, setReport, setError, handleList, handleFetch } = useReport();
  const { onInvalid } = useAuth();
  const animatedComponents = makeAnimated();

  const { handleSubmit, register, setValue, reset, control, formState: { errors } } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setError('');
    reset({});
    setReport({} as ReportModel);
    onClose();
  }

  const onSubmit = async (data: any) => {
    setIsLoadingSubmit(true);
    setError('');

    const ret = await handleFetch(data as ReportModel);

    if (ret) {
      toast.success('Relatório salvo com sucesso!');
      handleList();
      onClose();
    }

    setIsLoadingSubmit(false);
  }

  useEffect(() => {
    if (show) {
      if (report?.id) {
        reset(report as FormValues);
        setSelectedExtension(extensions?.find((item: any) => item?.value === report?.extension));
      } else {
        reset({});
        setReport({} as any);
        setSelectedExtension(null);
        setSelectedMicroservice(null);
      }
    }
  }, [show]);

  useEffect(() => {
    setValue('extension', selectedExtension?.value || '');
    setValue('microservice', selectedMicroservice?.value || '');
  }, [selectedExtension, selectedMicroservice]);

  return (
    <ModalDefault
      title={report?.id ? 'Editar Relatório' : 'Adicionar Relatório'}
      show={show}
      onClose={handleClose}
      sizeModal={'lg'}
      showFooter={true}
      buttonText={isLoadingSubmit ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoadingSubmit}
      disabledSubmit={isLoadingSubmit}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalReportInsert" data-testid="ModalReportInsert">
        <Row>
          <Col md={8}>
            <FormGroup className="form-group">
              <label htmlFor="name">NOME *</label>
              <input
                className={`form-control ${onInvalid(errors?.name)}`}
                type='text'
                {...register('name')}
                onChange={(e: any) => setReport({ ...report, name: e?.target?.value })}
              />
              <ErrorMessage name={'Nome'} type={errors?.name?.type} min={3} max={100} />
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup className="form-group">
              <label htmlFor="identifier">IDENTIFICADOR *</label>
              <input
                className={`form-control ${onInvalid(errors?.identifier)}`}
                type='text'
                {...register('identifier')}
                onChange={(e: any) => setReport({ ...report, identifier: e?.target?.value })}
              />
              <ErrorMessage name={'Identificador'} type={errors?.identifier?.type} min={3} max={100} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="description">DESCRIÇÃO *</label>
              <textarea
                className={`form-control ${onInvalid(errors?.description)}`}
                {...register('description')}
                onChange={(e: any) => setReport({ ...report, description: e?.target?.value })}
              ></textarea>
              <ErrorMessage name={'Descrição'} type={errors?.description?.type} min={3} max={255} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup className="form-group">
              <label htmlFor="prefixFilename">PREFIXO *</label>
              <input
                className={`form-control ${onInvalid(errors?.prefixFilename)}`}
                type='text'
                {...register('prefixFilename')}
                onChange={(e: any) => setReport({ ...report, prefixFilename: e?.target?.value })}
              />
              <ErrorMessage name={'Prefixo'} type={errors?.prefixFilename?.type} min={3} max={255} />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup className="form-group">
              <label htmlFor="extension">EXTENSÃO *</label>
              <Controller
                name="extension"
                control={control}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isClearable
                    isSearchable
                    options={extensions}
                    placeholder="Selecione..."
                    className={`form-control p-0 ${onInvalid(errors?.extension)}`}
                    value={selectedExtension ?? ''}
                    defaultValue={selectedExtension}
                    components={animatedComponents}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={(val: any) => {
                      onChange(val?.value ?? null);
                      setSelectedExtension(val);
                    }}
                    styles={customStyles}
                  />
                )}
              />
              <ErrorMessage name={'Extensão'} type={errors?.extension?.type} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="classJob">CAMINHO ABSOLUTO DA CLASSE *</label>
              <input
                className={`form-control ${onInvalid(errors?.classJob)}`}
                placeholder='Ex: App\Jobs\NameClass'
                type='text'
                {...register('classJob')}
                onChange={(e: any) => setReport({ ...report, classJob: e?.target?.value })}
              />
              <ErrorMessage name={'Caminho Absoluto da Classe'} type={errors?.classJob?.type} min={3} max={255} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup className="form-group">
              <label htmlFor="microservice">SERVIÇO API</label>
              <Controller
                name="microservice"
                control={control}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isClearable
                    isSearchable
                    options={[
                      { value: 'proposals', label: "Proposals" },
                    ]}
                    placeholder="Selecione..."
                    className={`form-control p-0 ${onInvalid(errors?.microservice)}`}
                    value={selectedMicroservice ?? ''}
                    defaultValue={selectedMicroservice}
                    components={animatedComponents}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={(val: any) => {
                      onChange(val?.value ?? null);
                      setSelectedMicroservice(val);
                    }}
                    styles={customStyles}
                  />
                )}
              />
              <ErrorMessage name={'Serviço Api'} type={errors?.microservice?.type} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="form-group">
              <label htmlFor="endpoint">ROTA API {!!selectedMicroservice && (<>*</>)}</label>
              <div className="input-group">
                <span
                  className={`input-group-text ${onInvalid(errors?.endpoint)}`}
                  style={{ paddingRight: 7 }}
                >
                  /api/
                </span>
                <input
                  className={`form-control ${onInvalid(errors?.endpoint)}`}
                  placeholder='Ex: import/list'
                  type='text'
                  {...register('endpoint')}
                  onChange={(e: any) => setReport({ ...report, endpoint: e?.target?.value })}
                />
              </div>
              <ErrorMessage name={'Rota Api'} type={errors?.endpoint?.type} min={3} max={255} />
            </FormGroup>
          </Col>
        </Row>

        {report?.id && (
          <Row className="mt-1">
            <Col>
              <FormGroup className="form-group">
                <div className="d-flex wrapper">
                  <label htmlFor="active" className="mb-0">ATIVO *</label>
                  <FormCheck className="form-check form-switch p-0 m-0 form-input-check">
                    <input
                      name="active"
                      type="checkbox"
                      className='form-check-input'
                      defaultChecked={!report?.deletedAt || false}
                      onChange={(e: any) => {
                        setValue('active', e?.target?.checked)
                      }}
                    />
                  </FormCheck>
                </div>
              </FormGroup>
            </Col>
          </Row>
        )}
      </div>
    </ModalDefault>
  );
}

export default ModalReportInsert;
