import { FC, useEffect, useState } from 'react';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useFinancial } from '../../../../providers/Registrations/Financial/FinancialProvider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validate';
import { FinancialModel } from '../../../../models/Registrations/Financial/FinancialModel';
import FormatCnpj from '../../../../utils/FormatCnpj';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useAuth } from '../../../../providers/AuthProvider';
import ReactInputMask from 'react-input-mask';
import { toast } from 'react-toastify';

interface ModalFinancialInsertProps {
  show: boolean;
  onClose: () => void;
}

type FormValues = {
  id?: number;
  externalId?: number;
  febrabanCode?: number;
  name?: string;
  cnpj?: string;
  url?: string;
}

const ModalFinancialInsert: FC<ModalFinancialInsertProps> = ({ show, onClose }) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const { financial, setFinancial, setError, handleList, handleFetch } = useFinancial();
  const { onInvalid } = useAuth();

  const { handleSubmit, register, setValue, reset, formState: { errors } } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setError('');
    setFinancial({} as FinancialModel);
    onClose();
  }

  const onSubmit = async (data: any) => {
    setIsLoadingSubmit(true);
    setError('');

    data.cnpj = data?.cnpj?.replace(/[^\w\s]/gi, '');

    const ret = await handleFetch(data as FinancialModel);

    if (ret) {
      toast.success('Financeira salva com sucesso!');
      handleList();
      onClose();
    }

    setIsLoadingSubmit(false);
  }

  useEffect(() => {
    if (show) {
      if (financial?.id) {
        reset(financial as FormValues);
        setValue('cnpj', FormatCnpj(financial?.cnpj) || '');
      } else {
        reset(new FinancialModel());
        setFinancial({} as FinancialModel);
      }
    }
  }, [show]);

  return (
    <ModalDefault
      title={financial?.id ? 'Editar Financeira' : 'Adicionar Financeira'}
      show={show}
      onClose={handleClose}
      sizeModal={'lg'}
      showFooter={true}
      buttonText={isLoadingSubmit ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoadingSubmit}
      disabledSubmit={isLoadingSubmit}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalFinancialInsert" data-testid="ModalFinancialInsert">
        <Row>
          <Col md={3}>
            <FormGroup className="form-group">
              <label htmlFor="externalId">CÓDIGO DOMUS *</label>
              <input
                className={`form-control ${onInvalid(errors?.externalId)}`}
                type='text'
                {...register('externalId')}
                onChange={(e: any) => setFinancial({ ...financial, externalId: e?.target?.value })}
                disabled={!!financial?.id}
              />
              <ErrorMessage name={'Código Domus'} type={errors?.externalId?.type} />
            </FormGroup>
          </Col>
          <Col md={9}>
            <FormGroup className="form-group">
              <label htmlFor="name">NOME *</label>
              <input
                className={`form-control ${onInvalid(errors?.name)}`}
                type='text'
                {...register('name')}
                onChange={(e: any) => setFinancial({ ...financial, name: e?.target?.value })}
              />
              <ErrorMessage name={'Nome'} type={errors?.name?.type} min={3} max={100} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <FormGroup className="form-group">
              <label htmlFor="febrabanCode">CÓDIGO FEBRABAN</label>
              <input
                className={`form-control ${onInvalid(errors?.febrabanCode)}`}
                type='text'
                {...register('febrabanCode')}
                onChange={(e: any) => setFinancial({ ...financial, febrabanCode: e?.target?.value })}
              />
              <ErrorMessage name={'Código Febraban'} type={errors?.febrabanCode?.type} />
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup className="form-group">
              <label htmlFor="cnpj">CNPJ *</label>
              <ReactInputMask
                className={`form-control ${onInvalid(errors?.cnpj)}`}
                mask='99.999.999/9999-99'
                {...register('cnpj')}
                defaultValue={financial?.cnpj}
                onChange={(e: any) => setFinancial({ ...financial, cnpj: e?.target?.value })}
              />
              <ErrorMessage name={'CNPJ'} type={errors?.cnpj?.type} />
            </FormGroup>
          </Col>

          <Col md={5}>
            <FormGroup className="form-group">
              <label htmlFor="url">URL</label>
              <input
                className={`form-control ${onInvalid(errors?.url)}`}
                type='text'
                {...register('url')}
              />
              <ErrorMessage name={'URL'} type={errors?.url?.type} min={3} max={255} />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
}

export default ModalFinancialInsert;
