import React, { FC, useEffect, useState } from 'react';
import './ModalCovenantTableConfigInsert.scss';
import ModalDefault from '../../../../ModalDefault/ModalDefault';
import { CovenantTableConfigListModel } from '../../../../../models/Tools/Configurations/CovenantTable/CovenantTableConfigListModel';
import { useCovenantTableConfig } from '../../../../../providers/Tools/Configurations/CovenantTableConfigProvider';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validate';
import { useCovenantGroup } from '../../../../../providers/Registrations/Covenant/CovenantGroupProvider';
import { useContractForm } from '../../../../../providers/Proposals/ContractForm/ContractFormProvider';
import { useCovenantTable } from '../../../../../providers/Tools/Configurations/CovenantTableProvider';
import { Col, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { useAuth } from '../../../../../providers/AuthProvider';
import { customStyles } from '../../../../../models/SelectCustomStyles';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import { ReactSelectOptions } from '../../../../../models/ReactSelectOptions';
import { toast } from 'react-toastify';
import { useCovenant } from '../../../../../providers/Registrations/Covenant/CovenantProvider';

interface ModalCovenantTableConfigInsertProps {
  show: boolean,
  onClose: any,
  covenantTableConfig: CovenantTableConfigListModel
}

const ModalCovenantTableConfigInsert: FC<ModalCovenantTableConfigInsertProps> = ({ show, onClose, covenantTableConfig }) => {
  const [selectedCovenantGroup, setSelectedCovenantGroup] = useState<ReactSelectOptions>();
  const [selectedConvenant, setSelectedConvenant] = useState<ReactSelectOptions>();
  const [selectedContractForm, setSelectedContractForm] = useState<ReactSelectOptions>();
  const [selectedCovenantTable, setSelectedCovenantTable] = useState<ReactSelectOptions>();

  const animatedComponents = makeAnimated();
  const { onInvalid } = useAuth();

  const {
    handleList: handleListCovenantTableConfigs,
    handleSave,
    isLoading
  } = useCovenantTableConfig();

  const {
    handleList: handleListCovenantGroup,
    covenantGroupsOptions,
    setParams: setParamsCovenantGroup,
    params: paramsCovenantGroup
  } = useCovenantGroup();

  const {
    handleList: handleListConvenant,
    covenantsOptions,
    setParams: setParamsConvenant,
    params: paramsConvenant
  } = useCovenant();

  const {
    handleList: handleListContractForm,
    contractFormsOptions,
    setParams: setParamsContractForm,
    params: paramsContractForm
  } = useContractForm();

  const {
    handleList: handleListCovenantTable,
    covenantTablesOptions,
    setParams: setParamsCovenantTable,
    params: paramsCovenantTable
  } = useCovenantTable();

  const {
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors }
  } = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (show) {
      setParamsCovenantGroup({ withPaginate: false });
      setParamsContractForm({ withPaginate: false });
    } else {
      setSelectedCovenantGroup(undefined);
      setSelectedContractForm(undefined);
      setSelectedConvenant(undefined);
      setSelectedCovenantTable(undefined);
      setValue('tableDescription', '');
      reset();
    }
  }, [show]);

  useEffect(() => {
    handleListCovenantGroup().then();
  }, [paramsCovenantGroup]);

  useEffect(() => {
    handleListConvenant().then();
  }, [paramsConvenant]);

  useEffect(() => {
    handleListContractForm().then();
  }, [paramsContractForm]);

  useEffect(() => {
    handleListCovenantTable().then();
  }, [paramsCovenantTable]);

  useEffect(() => {
    setValue('covenantGroup', selectedCovenantGroup ?? null);
    setSelectedConvenant(undefined);
    setSelectedContractForm(undefined);
    setSelectedCovenantTable(undefined);
    if (selectedCovenantGroup) {
      setParamsConvenant({
        proposalsGroupId: [selectedCovenantGroup?.value],
        active: 'S',
        withPaginate: false
      });
    }
  }, [selectedCovenantGroup]);

  useEffect(() => {
    setValue('covenant', selectedConvenant ?? null);
    setSelectedContractForm(undefined);
    setSelectedCovenantTable(undefined);
  }, [selectedConvenant]);

  useEffect(() => {
    setValue('contractForm', selectedContractForm ?? null);
    setSelectedCovenantTable(undefined);
    if (selectedContractForm) {
      setParamsCovenantTable({
        financialId: covenantTableConfig?.financial?.id,
        proposalGroupId: selectedCovenantGroup?.value,
        contractTypeId: selectedConvenant?.value,
        contractFormId: selectedContractForm?.value,
        active: 'S',
        withPaginate: false
      });
    }
  }, [selectedContractForm]);

  useEffect(() => {
    setValue('covenantTable', selectedCovenantTable ?? null);
  }, [selectedCovenantTable]);

  const onSubmit = async (data: any) => {
    const _data: any = {};
    _data.covenantTable = data.covenantTable?.value;

    const result = await handleSave({
      id: covenantTableConfig?.id,
      proposalTableId: _data.covenantTable
    }).then();

    if (result) {
      toast.success('Configuração salva com sucesso!');
      handleListCovenantTableConfigs().then();
      onClose();
    }
  }

  return (
    <ModalDefault
      title={`Configurar ${covenantTableConfig.financial?.name} - ${covenantTableConfig.product}`}
      show={show}
      onClose={onClose}
      sizeModal={'lg'}
      showFooter={true}
      buttonText={isLoading ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoading}
      disabledSubmit={isLoading}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalCovenantTableConfigInsert" data-testid="ModalCovenantTableConfigInsert">
        <Row>
          <Col className='form-group' md={6} sm={12}>
            <label>Grupo de Convênio *</label>
            <Controller
              control={control}
              name={'covenantGroup'}
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isSearchable
                  placeholder="Selecione..."
                  options={covenantGroupsOptions}
                  className={`form-control p-0 ${onInvalid(errors?.covenantGroup)}`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  value={selectedCovenantGroup || ''}
                  defaultValue={selectedCovenantGroup}
                  styles={customStyles}
                  onChange={(val: any) => {
                    setSelectedCovenantGroup(val);
                  }}
                />
              )}
            />
            <ErrorMessage name={'Grupo de Convênio'} type={errors?.covenantGroup?.type?.toString()} />
          </Col>

          <Col className='form-group' md={6} sm={12}>
            <label>Convênio *</label>
            <Controller
              control={control}
              name={'covenant'}
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isSearchable
                  placeholder="Selecione..."
                  options={covenantsOptions}
                  className={`form-control p-0 ${onInvalid(errors?.covenant)}`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  value={selectedConvenant || ''}
                  defaultValue={selectedConvenant}
                  styles={customStyles}
                  onChange={(val: any) => {
                    setSelectedConvenant(val);
                  }}
                  isDisabled={!selectedCovenantGroup}
                />
              )}
            />
            <ErrorMessage name={'Tipo de Contrato'} type={errors?.covenant?.type?.toString()} />
          </Col>

          <Col className='form-group' md={6} sm={12}>
            <label>Forma de Contrato *</label>
            <Controller
              control={control}
              name={'contractForm'}
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isSearchable
                  placeholder="Selecione..."
                  options={contractFormsOptions}
                  className={`form-control p-0 ${onInvalid(errors?.contractForm)}`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  value={selectedContractForm || ''}
                  defaultValue={selectedContractForm}
                  styles={customStyles}
                  onChange={(val: any) => {
                    setSelectedContractForm(val);
                  }}
                  isDisabled={!selectedCovenantGroup || !selectedConvenant}
                />
              )}
            />
            <ErrorMessage name={'Forma de Contrato'} type={errors?.contractForm?.type?.toString()} />
          </Col>

          <Col className='form-group' md={6} sm={12}>
            <label>Tabela de Convênio *</label>
            <Controller
              control={control}
              name={'covenantTable'}
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isSearchable
                  placeholder="Selecione..."
                  options={covenantTablesOptions}
                  className={`form-control p-0 ${onInvalid(errors?.covenantTable)}`}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  value={selectedCovenantTable || ''}
                  defaultValue={selectedCovenantTable}
                  styles={customStyles}
                  onChange={(val: any) => {
                    setSelectedCovenantTable(val);
                  }}
                  isDisabled={!selectedCovenantGroup || !selectedConvenant || !selectedContractForm}
                />
              )}
            />
            <ErrorMessage name={'Tabela de Convênio'} type={errors?.covenantTable?.type?.toString()} />
          </Col>
        </Row>
      </div>
    </ModalDefault>
  )
};

export default ModalCovenantTableConfigInsert;
