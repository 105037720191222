import api from "../Api";
import { BaseService } from "../BaseService";

const URL = 'api/services/bi/bi';

export class BiFinancialUpdateFrequencyService extends BaseService {

    async listFrequency(): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/frequency/options`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listFinancialsConfig(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/frequency/financial?page=${params.page}`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async saveConfig(payload: { financial_id: number, frequency: number }): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/frequency/financial`, payload));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

}