import { FC } from 'react';
import './HomeThumb.scss';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Navbar from '../../../../Navbar/Navbar';
import MainFooter from '../../../../MainFooter/MainFooter';

interface HomeThumbProps {
  name?: string;
  corporateName?: string;
  cnpj?: string;
  logoInverse?: string;
  colorPrimary?: string;
  colorSecondary?: string;
  colorText?: string;
}

const HomeThumb: FC<HomeThumbProps> = ({
  name = '',
  corporateName = '',
  cnpj = '',
  logoInverse = '',
  colorPrimary = '',
  colorSecondary = '',
  colorText = '',
}) => {
  return (
    <div className="HomeThumb" data-testid="HomeThumb">
      <div className={`position-absolute w-100 min-height-200 top-0 MasterPage`}>
        <div
          className="mask bg-degrade opacity-9"
          style={{ backgroundImage: `linear-gradient(to bottom, #111222, ${colorPrimary})` }}
        />
      </div>

      <main className="main-content position-relative border-radius-lg MasterPage" data-testid="MasterPage">
        <Navbar
          title="Business Intelligence Produção"
          subtitle=""
          icon="fas fa-home"
          className="position-absolute"
          logoInverse={logoInverse}
          colorPrimary={colorPrimary}
          colorSecondary={colorSecondary}
          colorText={colorText}
          thumb
        />

        <Container fluid className="px-3 main-wrapper">
          <Row>
            <Col>
              <Card>
                <Card.Body>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body>

                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body>

                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body>

                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <MainFooter
          name={name}
          corporateName={corporateName}
          cnpj={cnpj}
          logoInverse={logoInverse}
          inverse={false}
          colorPrimary={colorPrimary}
          colorText={colorText}
          className="position-absolute bottom-0"
          thumb
        />
      </main>

    </div>
  );
}

export default HomeThumb;
