import { FC, useEffect } from 'react';
import './MainFooter.scss';
import { useStyle } from '../../providers/Style/StyleProvider';
import FormatCnpj from '../../utils/FormatCnpj';
import { Col, Container, Row } from 'react-bootstrap';
import moment from 'moment';

interface MainFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  fixed?: boolean;
  inverse?: boolean;
  thumb?: boolean;
  name?: string;
  corporateName?: string;
  cnpj?: string;
  logoInverse?: string;
  colorPrimary?: string;
  colorText?: string;
}

const MainFooter: FC<MainFooterProps> = ({
  fixed = false,
  inverse = false,
  thumb = false,
  name = '',
  corporateName = '',
  cnpj = '',
  logoInverse = '',
  colorPrimary = '',
  colorText = '',
  ...rest
}) => {
  const { style } = useStyle();

  const postionFooterPage = () => {

    let body = document.body, html = document.documentElement;
    let documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    let windowHeight   = window.innerHeight;
    let elementFooter  = document.querySelector('footer');

    if (elementFooter) {
      elementFooter.style.removeProperty('position');

      if (documentHeight > windowHeight) {
        elementFooter.style.cssText = "position:relative";
      }
    }
  };

  useEffect(() => {
    postionFooterPage();
  });

  return (
    <footer
      className={`footer MainFooter ${fixed ? 'fixed' : ''} ${inverse ? 'inverse' : ''} ${thumb ? 'thumb' : ''} px-5 ${rest?.className}`}
      data-testid="MainFooter"
      style={{ backgroundColor: colorPrimary }}
    >
      <Container fluid>
        <Row className="row align-items-center justify-content-between">
          <Col md={6} className="mb-lg-0 mb-4 text-start">
            {!thumb ? (
              <img
                alt={style?.name}
                src={!inverse ? style?.logoInverse : style?.logoDefault}
              />
            ) : (
              <>
                {logoInverse ? (
                  <img
                    alt={name}
                    src={logoInverse}
                    onLoad={() => {
                      URL.revokeObjectURL(logoInverse)
                    }}
                  />
                ) : (
                  <p className="m-0 text-primary bg-logo-dest">Logo Secundária</p>
                )}
              </>
            )}
          </Col>
          <Col md={6} className="mb-lg-0 mb-4 text-end">
            {!thumb ? (
              <div className="copyright text-sm text-end">
                © {moment(new Date()).format('YYYY')} {style?.name} - Todos os direitos reservados. {style?.corporateName} - CNPJ: {FormatCnpj(style?.cnpj)}
              </div>
            ) : (
              <div
                className="copyright text-sm text-end"
                style={{ color: colorText }}
              >
                © {moment(new Date()).format('YYYY')} {name || 'Nome da Empresa'} - Todos os direitos reservados. {corporateName || 'Razão Social da Empresa'} - CNPJ: {cnpj ? FormatCnpj(cnpj) : 'CNPJ da Empresa'}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default MainFooter;
