import React, { FC, useEffect, useState } from 'react';
import './Opportunities.scss';
import { useOpportunity } from "../../../providers/Marketplace/Opportunities/OpportunityProvider";
import MasterPage from "../../../components/MasterPage/MasterPage";
import PageBase from "../../../components/PageBase/PageBase";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";
import { OpportunityModel } from "../../../models/Marketplace/Opportunity/OpportunityModel";
import { dateFormat } from "../../../utils/FormatDate";
import { CampaignStatusEnum } from "../../../enums/Marktplace/Campaigns/CampaignStatusEnum";
import DropdownMenuTable from "../../../components/DropdownMenuTable/DropdownMenuTable";
import { Dropdown, OverlayTrigger, Tooltip, Card, Col, Row, Accordion, FormCheck } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEye, faQuestionCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import Swal, { SweetAlertResult } from "sweetalert2";
import { toast } from "react-toastify";
import ModalOpportunityDetails from "../../../components/Marketplace/Opportunity/ModalOpportunityDetails/ModalOpportunityDetails";
import Spinner from "../../../components/Spinner/Spinner";
import AppPagination from "../../../components/AppPagination/AppPagination";
import ModalRemoveOpportunity from '../../../components/Marketplace/Opportunity/ModalRemoveOpportunity/ModalRemoveOpportunity';
import { OpportunityPartnerStatusEnum } from '../../../enums/Marktplace/Opportunity/OpportunityPartnerStatusEnum';
import Skeleton from 'react-loading-skeleton';
import ModalChangeOpportunitiesPartner from '../../../components/Marketplace/Opportunity/ModalChangeOpportunitiesPartner/ModalChangeOpportunitiesPartner';
import TooltipItem from '../../../components/TooltipItem/TooltipItem';
import { useAuth } from '../../../providers/AuthProvider';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import SidebarFilter from '../../../components/SidebarFilter/SidebarFilter';

interface OpportunitiesProps { }

const Opportunities: FC<OpportunitiesProps> = () => {
    const {
        opportunities, setOpportunities,
        opportunitiesPages, setOpportunitiesPages,
        marketplacePerformance,
        params, setParams,
        handleGetMarketplacePerformance,
        isLoading,
        isLoadingFile,
        handleGetOpportunities,
        handleExcel,
        handleExcelEmail,
        handleFinalizeOpportunity,
        handleGetFilters,
        loading,
        opportunityFilter
    } = useOpportunity();
    const { CanAccess } = useAuth();

    const [showOpportunities, setShowOpportunities] = useState<boolean>(false);
    const [opportunityView, setOpportunityView] = useState<number | null>(null);
    const [opportunityRemove, setOpportunityRemove] = useState<number | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [showChangePartnerModal, setShowChangePartnerModal] = useState<boolean>(false);
    const [showFilter, setShowFilter] = useState<string>('0');
    const [canChangePartner, setCanChangePartner] = useState<boolean>(false);
    const [opportunitiesChangePartner, setOpportunitiesChangePartner] = useState<OpportunityModel[]>([]);
    const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();

    useEffect(() => {
        if (showSidebarFilter) {
            setShowSidebarFilter(false)
        }
    }, [showSidebarFilter]);

    useEffect(() => {
        handleGetMarketplacePerformance();
        handleGetFilters().then();
        CanAccess('oportunidades.alterar_agenteregra').then((x: boolean) => setCanChangePartner(x));

        return () => {
            setOpportunities([]);
            setOpportunitiesPages({});
        }
    }, []);

    useEffect(() => {
        console.log(opportunitiesChangePartner);
    }, [opportunitiesChangePartner]);

    const onSubmitFinalizeOpportunity = (opportunities: OpportunityModel[], line: OpportunityModel) => {
        Swal.fire({
            title: `# ${line.opportunity_id}: Já digitou ?`,
            html: `
            <div class="form-group">
              <input class="form-control text-default" type="number" placeholder="Numero da nova Proposta" id="input-field">
            </div>`,
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim'
        }).then((result: SweetAlertResult) => {
            if (result.isConfirmed) {
                const new_proposal = (document.getElementById('input-field') as HTMLInputElement)?.value;
                if (new_proposal) {
                    toast.success('Proposta apontada com sucesso! Aguarde a confirmação');
                    handleFinalizeOpportunity(line.opportunity_id ?? 0, Number(new_proposal)).then();
                    setOpportunities(opportunities.filter((item: OpportunityModel) => item.opportunity_id !== line.opportunity_id));
                } else {
                    toast.error(`O número da nova proposta é obrigatório!`);
                }
            }
        })
    }

    async function handleList(type: 'agent_activity_enum' | 'campaign_id' | 'financial_id', data: number | OpportunityPartnerStatusEnum) {
        setShowFilter('');
        setOpportunitiesChangePartner([]);
        setParams({ page: 1, [type]: type === 'agent_activity_enum' ? data : [data] });
        handleGetOpportunities(1, { [type]: type === 'agent_activity_enum' ? data : [data] });
    }

    const onCheck = (opportunity: OpportunityModel, event: any) => {
        event?.target?.checked ?
            setOpportunitiesChangePartner((current: OpportunityModel[]) => [...current, opportunity]) :
            setOpportunitiesChangePartner((current: OpportunityModel[]) => current?.filter((x: OpportunityModel) => x.opportunity_id !== opportunity.opportunity_id));
    }

    const renderFilters = () => {
        return (
            <Accordion defaultActiveKey='0' activeKey={showFilter} className='mt-3'>
                <Accordion.Item eventKey='0'>
                    <Accordion.Header
                        className={`${showFilter === '' ? 'accordion-header-close' : 'accordion-header-open'}`}
                        onClick={() => showFilter === '0' ? setShowFilter('') : setShowFilter('0')}
                    >
                        Filtros
                    </Accordion.Header>
                    <Accordion.Body className={`${showFilter === '0' && 'accordion-body-open'}`}>
                        <Row className='filters'>
                            <Col>
                                <Card>
                                    <Card.Header>Agentes</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <div className='d-flex gap-2'>
                                                    <button
                                                        className='btn btn-primary'
                                                        onClick={() => {
                                                            handleList('agent_activity_enum', OpportunityPartnerStatusEnum.ALL)
                                                        }}
                                                    >
                                                        Todos
                                                    </button>
                                                    <button
                                                        className='btn btn-primary'
                                                        onClick={() => handleList('agent_activity_enum', OpportunityPartnerStatusEnum.ACTIVE)}
                                                    >
                                                        Ativos
                                                    </button>
                                                    <button
                                                        className='btn btn-primary'
                                                        onClick={() => handleList('agent_activity_enum', OpportunityPartnerStatusEnum.INACTIVE)}
                                                    >
                                                        Inativos
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Header>Campanhas</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <div className='d-flex gap-2'>
                                                    {opportunityFilter?.campaign?.map((item: any) => (
                                                        <button
                                                            key={item?.campaign_id}
                                                            className='btn btn-primary'
                                                            onClick={() => handleList('campaign_id', item?.campaign_id)}
                                                        >
                                                            {`${item?.campaign_title}  [ ${item?.quantity} ]`}
                                                        </button>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        )
    }

    const renderList = () => {
        return (
            <>
                {opportunities?.length > 0 ? (
                    <>
                        {opportunities.map((line: OpportunityModel) => (
                            <tr key={line.opportunity_id} className="text-sm">
                                {canChangePartner &&
                                    <td className='text-center'>
                                        <FormCheck className="form-check">
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value={line.opportunity_id}
                                                onChange={(e: any) => onCheck(line, e)}
                                                checked={!!opportunitiesChangePartner?.find((x: OpportunityModel) => x.opportunity_id === line?.opportunity_id)}
                                            />
                                        </FormCheck>
                                    </td>}
                                <td># {line.opportunity_id}</td>
                                <td>{line.opportunity_agent}</td>
                                <td>{line.campaign_title}</td>
                                <td>{line.campaign_obs}</td>
                                <td className='text-center'>{dateFormat({ date: line.campaign_date, datetime: false })}</td>
                                <td className='text-center'>{line.campaign_deadline} dias</td>
                                <td className='text-center'>
                                    <div className={`badge badge-sm ${line.campaign_status_id === CampaignStatusEnum.ATIVA ? 'badge-success' : 'badge-danger'}`}>
                                        {line.campaign_status_id === CampaignStatusEnum.ATIVA ? 'Ativa' : 'Inativa'}
                                    </div>
                                </td>
                                <td className='text-center'>
                                    <DropdownMenuTable key={line.campaign_id ?? 0}>

                                        <Dropdown.Item className="border-radius-md py-2" onClick={() => { setShowOpportunities(true); setOpportunityView(line?.opportunity_id ?? 0); }} >
                                            <FontAwesomeIcon icon={faEye} className="me-2" /> Ver mais detalhes
                                        </Dropdown.Item>

                                        <Dropdown.Item className="border-radius-md py-2" onClick={() => { setOpportunityRemove(line?.opportunity_id ?? 0); setShowRemoveModal(true); }} >
                                            <FontAwesomeIcon icon={faXmarkCircle} className="me-2" /> Não foi possivel digitar
                                        </Dropdown.Item>

                                        <Dropdown.Item className="border-radius-md py-2" onClick={() => onSubmitFinalizeOpportunity(opportunities, line)} >
                                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" /> Já digitei essa oportunidade
                                        </Dropdown.Item>

                                    </DropdownMenuTable>
                                </td>
                            </tr>
                        ))}
                    </>
                ) : (
                    <tr>
                        <td colSpan={9} className="text-center text-sm">Não há registros</td>
                    </tr>
                )}
            </>
        );
    }

    const renderCards = (): JSX.Element => {
        return (
            <>
                {!isLoading ? (
                    <div >
                        <Row>
                            <Col md={12}>
                                <Card className="h-100 bg-secondary" >
                                    <Card.Body className="h-card">
                                        <Row>
                                            <Col md={4}>
                                                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                                    <img
                                                        alt={'Marketplace'}
                                                        className="w-75"
                                                        src="/assets/img/seu-marketplace-horizontal.svg"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <Card className="h-100 bg-white">
                                                    <Card.Body className="h-card p-3">
                                                        <div className="d-flex flex-column h-auto">
                                                            <small><b className="text-violet-40">
                                                                Resumo Oportunidades &nbsp;
                                                                <img alt={'Oportunidades'} className="w-5" src="/assets/img/hand-money-light.svg" />
                                                            </b></small>
                                                            <hr style={{ color: 'grey', backgroundColor: 'grey', height: 1, marginTop: 3, marginBottom: 6 }} />
                                                            <small className="text-violet-40"><b className="text-violet-40">Quantidade Oportunidades</b>: {marketplacePerformance?.opportunities_number}</small>
                                                            <small className="text-violet-40"><b className="text-violet-40">Quantidade de Parceiros</b>: {marketplacePerformance?.partner_number}</small>
                                                            <small className="text-violet-40"><b className="text-violet-40">Valor Total das Oportunidades</b>: R$ {marketplacePerformance?.opportunities_value}</small>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col md={4}>
                                                <Card className="h-100 bg-white">
                                                    <Card.Body className="h-card p-3">
                                                        <div className="d-flex flex-column h-auto">
                                                            <small><b className="text-violet-40">
                                                                Meu aproveitamento &nbsp;
                                                                <img alt={'Comissão'} className="w-5" src="/assets/img/groups-light.svg" />
                                                            </b></small>
                                                            <hr style={{ color: 'grey', backgroundColor: 'grey', height: 1, marginTop: 3, marginBottom: 6 }} />
                                                            <small className="text-violet-40"><b className="text-violet-40">Propostas digitadas</b>: {marketplacePerformance?.opportunities_typed}</small>
                                                            <small className="text-violet-40"><b className="text-violet-40">Comissão recebida</b>: R$ {marketplacePerformance?.opportunities_commissions}</small>
                                                            <small className="text-violet-40"><b className="text-violet-40">Conversão de oportunidades</b>: {marketplacePerformance?.opportunities_conversion} %</small>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <Skeleton width={'100%'} height={150} />
                )}
            </>
        );
    }

    const renderContent = (): JSX.Element => {
        return (
            <>
                {renderCards()}

                {renderFilters()}

                {!loading?.listOpportunities ? (
                    <>
                        {canChangePartner &&
                            <Row className='mt-3'>
                                <Col sm={12} className='text-center text-md-end'>
                                    <TooltipItem position='top' text='Alterar o agente das oportunidades selecionadas.'>
                                        <button
                                            className='btn btn-primary mb-0'
                                            onClick={() => setShowChangePartnerModal(true)}
                                            disabled={opportunitiesChangePartner?.length === 0}
                                        >
                                            Alterar Agente das Oportunidades
                                        </button>
                                    </TooltipItem>
                                </Col>
                            </Row>}
                        <div className="table-responsive mt-4">
                            <table className="table table-flush m-0 w-100">
                                <thead className="thead-light">
                                    <tr>
                                        {canChangePartner &&
                                            <th className="text-uppercase opacity-7" scope="col">#</th>
                                        }
                                        <th className="text-uppercase opacity-7" scope="col">Oportunidade</th>
                                        <th className="text-uppercase opacity-7" scope="col">Agente</th>
                                        <th className="text-uppercase opacity-7" scope="col">Campanha</th>
                                        <th className="text-uppercase opacity-7" scope="col">Obs. Campanha</th>
                                        <th className="text-uppercase opacity-7 text-center" scope="col">Cadastro da Campanha</th>
                                        <th className="text-uppercase opacity-7 text-center" scope="col">Duração</th>
                                        <th className="text-uppercase opacity-7 text-center" scope="col">Status Campanha</th>
                                        <th className="text-uppercase opacity-7 text-center" scope="col">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderList()}
                                </tbody>
                            </table>
                        </div>
                        {opportunities?.length > 0 && (
                            <AppPagination
                                pages={opportunitiesPages}
                                handlePaginate={handleGetOpportunities}
                                params={params}
                                setParams={setParams}
                                style={{ backgroundColor: '#fff' }}
                            />
                        )}
                    </>
                ) : (
                    <SkeletonTable />
                )}
            </>
        );
    }


    return (
        <MasterPage
            title="Oportunidades"
            icon="fa-solid fa-cake-candles"
            footerFixed
            footerInverse
        >
            <div className="Opportunities" data-testid="Opportunities">
                <PageBase
                    title="Oportunidades"
                    subtitle="Gerenciamento das oportunidades de negócios."
                    hasFilter={false}
                    handleAddtional={handleExcel}
                    handleInsert={handleExcelEmail}
                    textButtonAdd="Receber por email"
                    textButtonAddtional="Baixar agora"
                    showAddtionalButton={true}
                    content={renderContent()}
                />
            </div>

            {(isLoading || isLoadingFile) && <Spinner />}

            <ModalOpportunityDetails
                show={showOpportunities}
                onClose={setShowOpportunities}
                opportunityNumber={opportunityView}
                setOpportunityNumber={setOpportunityView}
            />

            <ModalRemoveOpportunity
                opportunityNumber={opportunityRemove}
                show={showRemoveModal}
                onClose={setShowRemoveModal}
            />

            <ModalChangeOpportunitiesPartner
                show={showChangePartnerModal}
                onClose={setShowChangePartnerModal}
                opportunities={opportunitiesChangePartner}
                setOpportunities={setOpportunitiesChangePartner}
            />

            <SidebarFilter />
        </MasterPage>
    );
};

export default Opportunities;
