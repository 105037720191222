import api from "../Api";
import { BaseService } from "../BaseService";

const URL = 'api/user/dependent';

export class UserDependentService extends BaseService {

    async perPartner(userId: number): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/perPartner/${userId}`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}