import { FC, useEffect, useRef } from 'react';
import './SidebarReportRequest.scss';
import { useReportRequest } from '../../../../providers/Reports/ReportRequest/ReportRequestProvider';
import { Card, Row } from 'react-bootstrap';
import { ReportRequestModel } from '../../../../models/Reports/ReportRequest/ReportRequestModel';
import CardReportRequest from '../CardReportRequest/CardReportRequest';
import Spinner from '../../../Spinner/Spinner';
import AppPagination from '../../../AppPagination/AppPagination';
import { usePaginate } from '../../../../providers/PaginateProvider';
import { useReportRequestListHook } from '../../../../hooks/Reports/ReportRequest/ReportRequestListHook';

interface SidebarReportRequestProps { }

const SidebarReportRequest: FC<SidebarReportRequestProps> = () => {
  const { showSidebar, setShowSidebar, pages, params, setParams, reportRequests, setReportRequests, setPages } = useReportRequest();
  const { isLoading, data, isFetching } = useReportRequestListHook(params, showSidebar);

  const { handlePaginate } = usePaginate();
  const wrapperRef = useRef<HTMLElement>(null);

  useEffect(() => {
    // Add event listener to the document object
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      if (!['svg', 'path'].includes(event.target.tagName) && !event?.target?.className?.includes('btn-action')) {
        setShowSidebar(false);
      }
    }
  }

  useEffect(() => {
    let backdrop = document.getElementsByClassName('backdrop-violet-80')[0];

    if (backdrop) {
      if (showSidebar) {
        backdrop.classList.remove('hide');
        setTimeout(() => { backdrop.classList.remove('hide-opacity') }, 20);
      } else {
        backdrop.classList.add('hide-opacity');
      }
    }
  }, [showSidebar]);

  useEffect(() => {
    if (!!data) {
      const [_Response] = data;

      if (_Response?.data) {
        setReportRequests(_Response.data);
        setPages(_Response?.pages);
      }
    }
  }, [data]);

  return (
    <aside
      ref={wrapperRef}
      className={`SidebarReportRequest fixed-plugin ${showSidebar ? 'ps show' : ''}`}
      data-testid="SidebarReportRequest"
    >
      <Row>
        <Card className="shadow-lg px-1">
          <Card.Header className="card-header py-3 pb-1 bg-transparent d-flex align-items-center justify-content-between">
            <div>
              <h5 className="m-0">Solicitações de Relatórios</h5>
            </div>

            <div className="d-flex align-items-center text-loading gap-1">
              {isFetching && (
                <>
                  <div className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></div>
                  Atualizando Relatórios
                </>
              )}
            </div>

            <div>
              <button className="btn btn-link text-violet-40 p-0 m-0 fixed-plugin-close-button" onClick={() => setShowSidebar(false)}>
                <i className="fa fa-close"></i>
              </button>
            </div>
          </Card.Header>

          <Card.Body className="pt-sm-3 pt-0 pb-2 px-4 overflow-y-auto overflow-x-hidden">
            {!isLoading ? (
              <>
                {reportRequests?.length > 0 ? (
                  <>
                    {reportRequests.map((item: ReportRequestModel, key: number) => (
                      <CardReportRequest item={item} key={key} />
                    ))}
                  </>
                ) : (
                  <p className="mb-0 text-center">Não há registros</p>
                )}
              </>
            ) : (
              <Spinner backgroundColor='transparent' opacity='1' position='position-absolute' color='#fff' />
            )}
          </Card.Body>

          <Card.Footer className="px-3 pb-2 pt-1">
            {!isLoading && (
              <>
                {reportRequests?.length > 0 && (
                  <div>
                    <nav aria-label="...">
                      <AppPagination
                        pages={pages}
                        handlePaginate={handlePaginate}
                        params={params}
                        setParams={setParams}
                        colorTotal="text-violet-60"
                        style={{ backgroundColor: 'transparent', color: 'var(--violet-60)' }}
                      />
                    </nav>
                  </div>
                )}
              </>
            )}
          </Card.Footer>
        </Card>
      </Row>
    </aside>
  );
}

export default SidebarReportRequest;
