import { FC } from 'react';
import './CardInformative.scss';
import { Card } from 'react-bootstrap';
import { BvIcon } from 'bevi-icon';
import moment from 'moment';

interface CardInformativeProps {
  message: string;
  readDate: string;
  sendDate: string;
  identify: string;
}

const CardInformative: FC<CardInformativeProps> = ({ message, readDate, sendDate, identify }) => {
  return (
    <Card className="CardInformative mb-3" data-testid="CardInformative">
      <Card.Header className="bg-transparent">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div>
            <p className={`m-0 ${readDate ? 'text-violet-60' : ''}`}>
              {readDate ? (
                <BvIcon name="emailContent" variant="light" width={16} height={16} className="me-1 text-violet-60" />
              ) : (
                <BvIcon name="mail" variant="light" width={16} height={16} className="me-1 text-secondary" />
              )}
              <span className={`${!readDate ? 'text-secondary' : ''}`}>{readDate ? (`Lido: ${moment(readDate).format('DD/MM/YYYY')}`) : 'Não Lido'}</span>
            </p>
          </div>
          <div>
            <p className="m-0 text-violet-40">
              Enviado: {moment(sendDate).format('DD/MM/YYYY')}
              <span className='ms-2'>{identify}</span>
            </p>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <p className={`m-0 ${readDate ? 'text-violet-60' : 'text-white'}`}>
          {message}
        </p>
      </Card.Body>
    </Card>
  );
}

export default CardInformative;
