import { FC, useEffect } from 'react';
import './SidebarAccessLevelApplicationFilter.scss';
import { Col, FormGroup, Row } from 'react-bootstrap';
import SidebarFilter from '../../../../SidebarFilter/SidebarFilter';
import { AccessApplicationParams } from '../../../../../models/Registrations/Access/AccessApplicationParams';
import { useForm } from 'react-hook-form';
import { useAccessApplication } from '../../../../../providers/Registrations/Access/AccessApplicationProvider';

interface SidebarAccessLevelApplicationFilterProps {}

export const defaultValues = {
  name: ''
} as AccessApplicationParams;

const SidebarAccessLevelApplicationFilter: FC<SidebarAccessLevelApplicationFilterProps> = () => {
  
  const { handleSubmit, register } = useForm<any>({ defaultValues });

  const { params, setParams, handleList } = useAccessApplication();

  useEffect(() => {
    handleList()
  }, [params]);

  const onSubmit = async (data: AccessApplicationParams) => {
    setParams({ ...params, ...data, ...{ page: 1 } });
  }

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarAccessLevelApplicationFilter" data-testid="SidebarAccessLevelApplicationFilter">
        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="name">NOME</label>
              <input
                className={`form-control`}
                {...register('name')}
                type='text'
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  )
}

export default SidebarAccessLevelApplicationFilter;
