import { FC, useEffect, useRef, useState } from 'react';
import './SidebarIndicatorManagementFilter.scss';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import { Controller, useForm } from 'react-hook-form';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { status } from '../../../../enums/Visits/IndicatorManagement/EnumIndicatorManagementAgentStatus';
import { useCommercial } from '../../../../providers/Registrations/User/UserCommercialProvider';
import InputSearchPartner from '../../../Registrations/Partner/InputSearchPartner/InputSearchPartner';
import { VisitsActionsParams } from '../../../../models/Visits/VisitsActions/VisitsActionsParams';
import { useIndicatorManagementWalletVisits } from '../../../../providers/Visits/IndicatorManagement/IndicatorManagementWalletProvider';
import moment from 'moment';
import { usePartner } from '../../../../providers/Registrations/Partner/PartnerProvider';
import { TabEnumIndicatorManagement } from '../../../../pages/Visits/IndicatorManagement/IndicatorManagement';
import makeAnimated from 'react-select/animated';

interface SidebarIndicatorManagementFilterProps {
  activeTab: TabEnumIndicatorManagement
}

export const defaultValues: VisitsActionsParams = {
  commercialId: '',
  userId: '',
  productionMonth: '',
  userStatus: ''
};

const SidebarIndicatorManagementFilter: FC<SidebarIndicatorManagementFilterProps> = ({ activeTab }) => {

  const { handleSubmit, control, setValue } = useForm<VisitsActionsParams>({ defaultValues });
  const { params, setParams } = useIndicatorManagementWalletVisits();
  const { commercialsOptions, handleList } = useCommercial();
  const { handleListProspecting, prospectingOptions } = usePartner();

  const [valuePartner, setValuePartner] = useState<string>('');
  const [selectedCommercial, setSelectedCommercial] = useState<any>();
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [optionsMonths, setOptionsMonths] = useState<any>([]);
  const [selectedMonth, setSelectedMonth] = useState<any>();
  const [selectedProspecting, setSelectedProspecting] = useState<any>(null);

  const refSubmitButtom = useRef<HTMLButtonElement>(null);
  const animatedComponents = makeAnimated();

  useEffect(() => {
    return () => {
      setIsFirstRender(true);
      setSelectedCommercial(undefined);
    }
  }, []);

  const onSubmit = async (data: any) => {
    const _data = JSON.parse(JSON.stringify(data));

    // Cada dev usou um nome de propriedade diferente para a mesma informação nas diversas abas do PGI
    // Aqui incluímos todas as possibilidades de nomes para não haver problema.
    _data.commercialId = _data.commercialId?.value?.toString();
    // _data.commercial = _data.commercialId;
    // _data.userCommercialId = _data.commercialId;
    // _data.userVisitorId = _data.commercialId;

    _data.userId = _data.userId?.value?.toString();
    // _data.agentId = _data.agentId?.value?.toString();
    // _data.partner = _data.agentId;
    // _data.userVisitedId = _data.agentId;

    _data.productionMonth = _data.productionMonth?.value;
    _data.month = moment(_data.productionMonth).format('YYYY-MM');
    // _data.monthProduction = _data.monthProduction?.value;

    _data.startDate = _data.productionMonth;
    _data.endDate = moment(_data.productionMonth).endOf('month').format('YYYY-MM-DD');

    _data.userStatus = _data.userStatus?.value;
    _data.partnerRegistrationProspectingId = _data.partnerRegistrationProspectingId;

    setParams({ ...params, ..._data, ...{ page: 1 } });
  }

  useEffect(() => {
    handleList()
    const _months = [];
    let currentDate = moment();
    for (let i = 0; i < 12; i++) {
      _months.unshift({ label: currentDate.format('MMMM/YYYY'), value: currentDate.format('YYYY-MM-01') });
      currentDate = currentDate.subtract(1, 'months');
    }
    setOptionsMonths(_months);
    setSelectedMonth(_months.slice(-1)[0]);
  }, []);

  useEffect(() => {
    setValue('userId', valuePartner);
  }, [valuePartner]);

  useEffect(() => {
    setValue('commercialId', selectedCommercial);
    if (activeTab === TabEnumIndicatorManagement.VISIT && !!selectedCommercial) {
      handleListProspecting(selectedCommercial?.value).then();
    }
    if (isFirstRender && commercialsOptions?.length === 1 && selectedCommercial) {
      refSubmitButtom?.current?.click();
      setIsFirstRender(false);
    }
  }, [selectedCommercial, isFirstRender, activeTab]);

  useEffect(() => {
    setValue('productionMonth', selectedMonth);
  }, [selectedMonth]);

  useEffect(() => {
    if (commercialsOptions && commercialsOptions?.length > 0) {
      setSelectedCommercial(commercialsOptions[0]);
    }
  }, [commercialsOptions]);

  useEffect(() => {
    setValue('partnerRegistrationProspectingId', selectedProspecting?.value);
  }, [selectedProspecting]);

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)} buttonRef={refSubmitButtom} >
      <div className="SidebarIndicatorManagementFilter" data-testid="SidebarIndicatorManagementFilter">

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="commercialId">COMERCIAL</label>
              <Controller
                name="commercialId"
                control={control}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isSearchable
                    options={commercialsOptions}
                    value={selectedCommercial}
                    placeholder="Selecione..."
                    className={`form-control p-0`}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={(val) => {
                      onChange(val ? val : null);
                      setSelectedCommercial(val);
                    }}
                    styles={customStyles}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="agente">AGENTE</label>
              <InputSearchPartner
                name="userId"
                control={control}
                setValue={setValue}
                valuePartner={valuePartner}
                setValuePartner={setValuePartner}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="monthProduction">MÊS PRODUÇÃO</label>
              <Controller
                name="productionMonth"
                control={control}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isClearable
                    isSearchable
                    options={optionsMonths}
                    placeholder="Selecione..."
                    className={`form-control p-0`}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={(val) => {
                      onChange(val ? val : null);
                      setSelectedMonth(val);
                    }}
                    styles={customStyles}
                    value={selectedMonth}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        {/* <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="neighborhood">GRUPO DE PRODUÇÃO</label>
              <ReactSelect
                isSearchable
                // options={}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => 'Não há registros'}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row> */}

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="agentStatus">STATUS PARCEIRO</label>
              <Controller
                name="userStatus"
                control={control}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isClearable
                    isSearchable
                    options={status}
                    placeholder="Selecione..."
                    className={`form-control p-0`}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={(val) => onChange(val ? val : null)}
                    styles={customStyles}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        {activeTab === TabEnumIndicatorManagement.VISIT &&
          <Row className="mt-1">
            <Col>
              <FormGroup className="form-group">
                <label htmlFor="partnerRegistrationProspectingId">PROSPECÇÃO</label>
                <ReactSelect
                  name="partnerRegistrationProspectingId"
                  isSearchable
                  isClearable
                  options={prospectingOptions}
                  placeholder="Selecione..."
                  className={`form-control p-0`}
                  value={selectedProspecting ?? ''}
                  defaultValue={selectedProspecting}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  onChange={(val: any) => {
                    setSelectedProspecting(val);
                  }}
                  styles={customStyles}
                  isDisabled={!selectedCommercial}
                />
              </FormGroup>
            </Col>
          </Row>
        }

      </div>
    </SidebarFilter>
  );
}

export default SidebarIndicatorManagementFilter;
