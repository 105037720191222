import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { BiExecutiveConditionModel } from "../../../../models/BI/Dashboard/Executive/BiExecutiveModel";
import { BiExecutiveParams } from "../../../../models/BI/Dashboard/Executive/BiExecutiveParams";
import { BiExecutiveAnnualModel } from "../../../../models/BI/Dashboard/Executive/BiExecutiveAnnualModel";
import { BiExecutiveChartParticipationModel } from "../../../../models/BI/Dashboard/Executive/BiExecutiveChartParticipationModel";
import { BiAnnualProductionModel } from "../../../../models/BI/Dashboard/BiAnnualProductionModel";
import { BiMonthlyProductionModel } from "../../../../models/BI/Dashboard/BiMonthlyProductionModel";
import { BiDebtorsModel } from "../../../../models/BI/Dashboard/BiDebtorsModel";
import { BiStatusExecutiveModel } from "../../../../models/BI/Dashboard/BiStatusExecutiveModel";
import { BiRangeProductionModel } from "../../../../models/BI/Dashboard/BiRangeProductionModel";

interface ContextProps {
  executiveProductions: BiExecutiveConditionModel | undefined;
  executives: BiExecutiveAnnualModel | undefined;
  monthlyParticipation: BiExecutiveChartParticipationModel[];
  annualParticipation: BiExecutiveChartParticipationModel[];
  executivesFinancial: BiMonthlyProductionModel | undefined;
  setExecutivesFinancial: Dispatch<
    SetStateAction<BiMonthlyProductionModel | undefined>
  >;
  executivesDebtor: BiDebtorsModel | undefined;
  setExecutivesDebtor: Dispatch<SetStateAction<BiDebtorsModel | undefined>>;
  executivesPartner: BiStatusExecutiveModel[] | [];
  setExecutivesPartner: Dispatch<SetStateAction<BiStatusExecutiveModel[] | []>>;
  executivesRangeValue: BiRangeProductionModel | undefined;
  setExecutivesRangeValue: Dispatch<
    SetStateAction<BiRangeProductionModel | undefined>
  >;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  params: BiExecutiveParams;
  setParams: Dispatch<SetStateAction<BiExecutiveParams>>;
  annualExecutives: BiAnnualProductionModel | undefined;
  setAnnualExecutives: Dispatch<
    SetStateAction<BiAnnualProductionModel | undefined>
  >;
  monthlyExecutives: BiMonthlyProductionModel | undefined;
  setMonthlyExecutives: Dispatch<
    SetStateAction<BiMonthlyProductionModel | undefined>
  >;
}

export const BiExecutiveContext = createContext<ContextProps>(
  {} as ContextProps
);

interface BiExecutiveProviderProps {
  children: ReactNode;
}

export const BiExecutiveProvider = ({ children }: BiExecutiveProviderProps) => {
  const [executiveProductions, setExecutiveProductions] =
    useState<BiExecutiveConditionModel>();
  const [executives, setExecutives] = useState<BiExecutiveAnnualModel>();
  const [executivesFinancial, setExecutivesFinancial] =
    useState<BiMonthlyProductionModel>();
  const [monthlyParticipation, setMonthlyParticipation] = useState<
    BiExecutiveChartParticipationModel[]
  >([]);
  const [annualParticipation, setAnnualParticipation] = useState<
    BiExecutiveChartParticipationModel[]
  >([]);
  const [executivesRangeValue, setExecutivesRangeValue] =
    useState<BiRangeProductionModel>();
  const [executivesDebtor, setExecutivesDebtor] = useState<any>();
  const [executivesPartner, setExecutivesPartner] = useState<
    BiStatusExecutiveModel[]
  >([]);
  const [error, setError] = useState<string>("");
  const [params, setParams] = useState<BiExecutiveParams>({});
  const [annualExecutives, setAnnualExecutives] =
    useState<BiAnnualProductionModel>();
  const [monthlyExecutives, setMonthlyExecutives] =
    useState<BiMonthlyProductionModel>();

  return (
    <BiExecutiveContext.Provider
      value={useMemo(
        () => ({
          executiveProductions,
          executives,
          monthlyParticipation,
          annualParticipation,
          executivesFinancial,
          setExecutivesFinancial,
          executivesRangeValue,
          setExecutivesRangeValue,
          executivesDebtor,
          setExecutivesDebtor,
          executivesPartner,
          setExecutivesPartner,
          error,
          setError,
          params,
          setParams,
          annualExecutives,
          setAnnualExecutives,
          monthlyExecutives,
          setMonthlyExecutives,
        }),
        [
          executiveProductions,
          executives,
          monthlyParticipation,
          annualParticipation,
          executivesFinancial,
          setExecutivesFinancial,
          executivesRangeValue,
          setExecutivesRangeValue,
          executivesDebtor,
          setExecutivesDebtor,
          executivesPartner,
          setExecutivesPartner,
          error,
          setError,
          params,
          setParams,
          annualExecutives,
          setAnnualExecutives,
          monthlyExecutives,
          setMonthlyExecutives,
        ]
      )}
    >
      {children}
    </BiExecutiveContext.Provider>
  );
};

export const useBiExecutive = () => useContext(BiExecutiveContext);
