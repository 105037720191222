import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react";
import { CovenantGroupModel } from "../../../models/Registrations/Covenant/CovenantGroupModel";
import { CovenantGroupService } from "../../../services/Registrations/Covenant/CovenantGroupService";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";

interface ContextProps {
    covenantGroups: CovenantGroupModel[],
    covenantGroupsOptions: ReactSelectOptions[],
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => Promise<void>,
    handleSave: (payload: CovenantGroupModel) => Promise<boolean>
}

export const CovenantGroupContext = createContext<ContextProps>({} as ContextProps);

interface CovenantGroupProviderProps {
    children: ReactNode;
}

export const CovenantGroupProvider = ({ children }: CovenantGroupProviderProps) => {
    const [covenantGroups, setCovenantGroups] = useState<CovenantGroupModel[]>([]);
    const [covenantGroupsOptions, setCovenantGroupsOptions] = useState<ReactSelectOptions[]>([]);
    const [params, setParams] = useState<any>({ withPaginate: false });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const service = new CovenantGroupService();

    const handleList = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                setCovenantGroups([]);
                setError(_Error);
                return;
            }

            setCovenantGroups(_Response?.data);
            setCovenantGroupsOptions(_Response?.data?.map((x: CovenantGroupModel) => ({
                value: x.id,
                label: x.name,
                externalId: x.externalId
            })));
            setPages(_Response?.pages);
            setError('');
        } catch (e: any) {
            setIsLoading(false);
            setCovenantGroups([]);
            setError(e);
        }
    }

    const handleSave = async (payload: any) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.save(payload);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e);
            return false;
        }
    }

    return (
        <CovenantGroupContext.Provider value={useMemo(() => ({
            covenantGroups,
            covenantGroupsOptions,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleSave,
        }), [
            covenantGroups,
            covenantGroupsOptions,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleSave,
        ])}>
            {children}
        </CovenantGroupContext.Provider>
    );
}

export const useCovenantGroup = () => useContext(CovenantGroupContext);