import { FC, useEffect, useState } from 'react';
import './FormCampaign.scss';
import { Card, Col, FormGroup, Row } from "react-bootstrap";
import { useAuth } from "../../../../../providers/AuthProvider";
import ErrorMessage from "../../../../ErrorMessage/ErrorMessage";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { useCampaign } from '../../../../../providers/Marketplace/Campaign/CampaignProvider';
import TooltipItem from '../../../../TooltipItem/TooltipItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

interface FormCampaignProps {
  register: any,
  setValue: any,
  errors: any,
  control: any,
  show: boolean,
  showGenerateAgain: boolean
}

const FormCampaign: FC<FormCampaignProps> = ({ register, control, setValue, errors, showGenerateAgain }) => {
  const [selectedSanitize, setSelectedSanitize] = useState<number>(0);

  const { onInvalid } = useAuth();
  const { options } = useCampaign();

  useEffect(() => {
    setValue('campaign_sanitize', selectedSanitize);
  }, [selectedSanitize]);

  return (
    <Row className="FormCampaign" data-testid="FormCampaign">
      <Card>
        <Card.Header className="pt-3 pb-0 px-2">
          Dados da Campanha
        </Card.Header>
        <Card.Body className="py-1">
          <Row>
            <Col md={3}>
              <FormGroup className="form-group">
                <label htmlFor="campaign_name">Nome *</label>
                <input
                  name={"campaign_name"}
                  className={`form-control ${onInvalid(errors?.campaign_name)}`}
                  type="text"
                  {...register('campaign_name', { required: true, maxLength: 50 })}
                />
                <ErrorMessage name={'Nome'} type={errors?.campaign_name?.type} max={50} />
              </FormGroup>
            </Col>
            {/* <Col md={3}>
              <FormGroup className="form-group">
                <label htmlFor="campaign_origin">Origem da Campanha *</label>
                <input
                  name={"campaign_origin"}
                  className={`form-control ${onInvalid(errors?.campaign_origin)}`}
                  type="text"
                  {...register('campaign_origin', { required: true, maxLength: 50 })}
                />
                <ErrorMessage name={'Origem da Campanha'} type={errors?.campaign_origin?.type} max={50} />
              </FormGroup>
            </Col> */}
            <Col md={3}>
              <FormGroup className="form-group">
                <label htmlFor="campaign_obs">Observação *</label>
                <input
                  className={`form-control ${onInvalid(errors?.campaign_obs)}`}
                  type="text"
                  {...register('campaign_obs', { required: true, maxLength: 200 })}
                />
                <ErrorMessage name={'Observação'} type={errors?.campaign_obs?.type} max={200} />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="form-group">
                <label htmlFor="campaign_deadline">Duração em Dias *</label>
                <input
                  className={`form-control ${onInvalid(errors?.campaign_deadline)}`}
                  type="number"
                  {...register('campaign_deadline', { required: true, min: 1 })}
                  onChange={(event: any) => event.target.value = event.target.value.replace('\W', '')}
                />
                <ErrorMessage name={'Duração'} min={1} isNumber type={errors?.campaign_deadline?.type} />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="form-group">
                <label htmlFor="campaign_sanitize">Higienizar a Base *</label>
                {control && (
                  <Controller
                    name="campaign_sanitize"
                    control={control}
                    render={({ field: { name, ref } }) => (
                      <ReactSelect
                        ref={ref}
                        name={name}
                        options={[{ label: 'Não', value: 0 }, { label: 'Sim', value: 1 }]}
                        defaultValue={{ label: 'Não', value: 0 }}
                        noOptionsMessage={() => 'Não há registros'}
                        placeholder="Selecione..."
                        className={`form-control p-0 ${onInvalid(errors?.campaign_sanitize)}`}
                        {...register('campaign_sanitize', { required: true })}
                        onChange={(val: any) => {
                          setSelectedSanitize(val?.value);
                        }}
                      />
                    )}
                  />
                )}
                <ErrorMessage name="Higienizar" type={errors?.campaign_sanitize?.type} />
              </FormGroup>
            </Col>
            {selectedSanitize === 1 &&
              <Col md={3}>
                <FormGroup className="form-group">
                  <label htmlFor="campaign_sanitize_type">Tipo de Higienização *</label>
                  {control && (
                    <Controller
                      name="campaign_sanitize_type"
                      control={control}
                      render={({ field: { name, ref } }) => (
                        <ReactSelect
                          ref={ref}
                          name={name}
                          options={options?.sanitizeTypes}
                          noOptionsMessage={() => 'Não há registros'}
                          placeholder="Selecione..."
                          className={`form-control p-0 ${onInvalid(errors?.campaign_sanitize_type)}`}
                          {...register('campaign_sanitize_type', { required: true, })}
                          onChange={(val: any) => setValue('campaign_sanitize_type', val?.value)}
                        />
                      )}
                    />
                  )}
                  <ErrorMessage name="Tipo de Higienização" type={errors?.campaign_sanitize_type?.type} />
                </FormGroup>
              </Col>}
            
              {showGenerateAgain && (
                <Col md={3}>
                  <FormGroup className="form-group">
                    {<TooltipItem position='top' text='Gerar uma nova campanha com os mesmos parâmetros após a quantidade de dias informados.'>
                      <label htmlFor="campaign_generate_again_in_x_days">Gerar Novamente Após X Dias <FontAwesomeIcon icon={faCircleQuestion} /></label>
                    </TooltipItem>}
                    <input
                      className={`form-control ${onInvalid(errors?.campaign_generate_again_in_x_days)}`}
                      type="number"
                      {...register('campaign_generate_again_in_x_days')}
                      onChange={(event: any) => event.target.value = event.target.value.replace('\W', '')}
                    />
                    <ErrorMessage name={'Gerar Novamente'} type={errors?.campaign_generate_again_in_x_days?.type} />
                  </FormGroup>
                </Col>
              )}

          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default FormCampaign;
