import { FC, useEffect, useState } from 'react';
import { AccessPageModel } from '../../../../models/Registrations/Access/AccessPageModel';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { AccessActionModel } from '../../../../models/Registrations/Access/AccessActionModel';
import { Controller, useForm } from 'react-hook-form';
import { useAccessAction } from '../../../../providers/Registrations/Access/AccessActionProvider';
import { AccessActionService } from '../../../../services/Registrations/Access/AccessActionService';
import { RemoveAccent } from '../../../../utils/RemoveAccent';
import ButtonEdit from '../../../ButtonEdit/ButtonEdit';
import ButtonDelete from '../../../ButtonDelete/ButtonDelete';
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useAuth } from '../../../../providers/AuthProvider';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import makeAnimated from "react-select/animated";

interface ModalAccessActionInsertProps {
  show: boolean;
  onClose?: any;
  pageView: AccessPageModel;
  pageName: string;
}

interface Inputs {
  id?: string;
  name?: string;
  description?: string;
  accessActionTypeId?: string;
  alias?: string;
}

const ModalAccessActionInsert: FC<ModalAccessActionInsertProps> = ({ show, onClose, pageView, pageName }) => {
  const [pageViewEdit, setPageViewEdit] = useState<AccessPageModel>(pageView);
  const [actionTypes, setActionTypes] = useState<any[]>([]);
  const [indexEditAction, setIndexEditAction] = useState<number>(-1);
  const [alias, setAlias] = useState<string>('');
  const [selectedActionType, setSelectedActionType] = useState<any>(null);

  const { handleDelete, error } = useAccessAction();
  const { onInvalid } = useAuth();

  const service = new AccessActionService();
  const animatedComponents = makeAnimated();

  const { reset, setValue, getValues, handleSubmit, register, control, formState: { errors } } = useForm<Inputs>({});

  const clearActionForm = () => {
    setValue('id', '');
    setValue('name', '');
    setValue('description', '');
    setValue('accessActionTypeId', '');
    setValue('alias', '');
    setIndexEditAction(-1);
    setSelectedActionType(null);
  }

  const aliasSuggestion = () => {
    if (getValues('name') && selectedActionType?.value) {
      let suggest = RemoveAccent(`${pageViewEdit?.id ? pageViewEdit?.name?.toLowerCase() : pageName?.toLowerCase()}.`, ' ', '_');
      suggest += RemoveAccent(`${getValues('name')?.toLowerCase()}` || '', ' ', '_');
      suggest += RemoveAccent(actionTypes?.find((x: any) => x?.value === selectedActionType?.value)?.label?.toLowerCase() || '', ' ', '_');

      setAlias(suggest);
    }
  }

  const getTypes = async () => {
    const [response,] = await service.getTypes();
    setActionTypes(response?.data?.map((item: any) => { return { label: item?.name, value: item?.id }; }));
  }

  const delAction = (action: AccessActionModel, index: number) => {
    Swal.fire({
      title: 'Atenção',
      html: `Deseja realmente excluir a ação ${action.description}?`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (action.id) {
          await handleDelete(action.id);
        }
        if (!error) {
          const actionsAux = pageViewEdit.actionsPage;
          actionsAux?.splice(index, 1);
          setPageViewEdit({ ...pageViewEdit, actionsPage: actionsAux });
          toast.success('Registro removido com sucesso!');
        } else {
          toast.error(`Não foi possível remover a ação<br/>${error}`);
        }
      }
    });
  }

  const editAction = (action: any, index: number) => {
    setIndexEditAction(index);
    setSelectedActionType(actionTypes.find((item: any) => item?.value === action?.accessActionTypeId))
    reset(action);
  }

  const addAction = (data: Inputs) => {
    const actionsAux = pageViewEdit.actionsPage;
    const action: AccessActionModel = {
      id: data.id ? Number(data.id) : undefined,
      name: data.name,
      description: data.description,
      accessActionTypeId: Number(data.accessActionTypeId),
      alias: data.alias,
      accessPageId: pageView.id ?? 0,
      accessPage: {
        id: pageView.id,
        name: pageView.name
      }
    }
    if (actionsAux) {
      if (indexEditAction >= 0) {
        actionsAux[indexEditAction] = action;
      } else {
        actionsAux.push(action);
      }
      clearActionForm();
      setPageViewEdit({ ...pageViewEdit, actionsPage: actionsAux });
      pageViewEdit.actions = actionsAux;
      toast.success(indexEditAction >= 0 ? 'Ação atualizada com sucesso!' : 'Ação inserida com sucesso!');
      setIndexEditAction(-1);
    }
  }

  const renderList = () => {
    return (
      <>
        {(pageViewEdit?.actionsPage && pageViewEdit?.actionsPage?.length > 0) ? (
          <>
            {pageViewEdit?.actionsPage?.map((a: AccessActionModel, index: number) => (
              <tr key={a?.id}>
                <td>{a.id}</td>
                <td>{a.name}</td>
                <td>{a.description}</td>
                <td>{a.accessPage?.name ?? pageName}</td>
                <td>{a.alias}</td>
                <td className='d-flex justify-content-center gap-3'>
                  <ButtonEdit
                    tooltipText='Editar ação'
                    onClick={() => {
                      editAction(a, index);
                    }}
                  />
                  <ButtonDelete
                    tooltipText='Deletar ação'
                    onClick={() => delAction(a, index)}
                  />
                </td>
              </tr>
            ))}
          </>
        ) : (
          <>
            <tr>
              <td colSpan={6} className='text-center text-sm'>
                Não há registros
              </td>
            </tr>
          </>
        )}
      </>
    )
  }

  useEffect(() => {
    if (show) {
      setAlias('');
      getTypes().then();
      reset();
      clearActionForm();
      pageView ? setPageViewEdit(pageView) : setPageViewEdit(new AccessPageModel());
    }
  }, [show]);

  useEffect(() => {
    setValue('alias', alias || '');
  }, [alias]);

  useEffect(() => {
    if (selectedActionType) {
      aliasSuggestion();
    }

    setValue('accessActionTypeId', selectedActionType?.value || '');
  }, [selectedActionType]);

  return (
    <ModalDefault
      title={pageView?.name ? `Ações da página ${pageView.name}` : 'Ações da nova página'}
      show={show}
      onClose={() => {
        onClose();
      }}
      sizeModal="xl"
      showFooter={true}
      handleSubmit={handleSubmit(addAction)}
    >
      <div className="ModalAccessActionInsert" data-testid="ModalAccessActionInsert">
        <Row className="g-2">
          <Col md={1}>
            <FormGroup className="form-group">
              <label>ID</label>
              <input
                type='text'
                className="form-control"
                disabled
                {...register('id')}
              />
            </FormGroup>
          </Col>
          <Col md={5}>
            <FormGroup className="form-group">
              <label>NOME *</label>
              <input
                placeholder="Ex: Acessar"
                className={`form-control ${onInvalid(errors?.name)}`}
                type='text'
                {...register('name', { required: true, minLength: 3, maxLength: 100 })}
                onBlur={aliasSuggestion}
              />
              <ErrorMessage name={'Nome'} type={errors?.name?.type} min={3} max={100} />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup className="form-group">
              <label>TIPO *</label>
              <Controller
                name='accessActionTypeId'
                control={control}
                render={({ field: { onChange, value, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isClearable
                    isSearchable
                    options={actionTypes}
                    placeholder='Selecione...'
                    className={`form-control p-0 ${onInvalid(errors?.accessActionTypeId)}`}
                    value={selectedActionType}
                    defaultValue={selectedActionType}
                    components={animatedComponents}
                    onChange={val => {
                      onChange(val?.value || null);
                      setSelectedActionType(val);
                    }}
                    styles={customStyles}
                  />
                )}
              />
              <ErrorMessage name={'Tipo'} type={errors?.accessActionTypeId?.type} />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup className="form-group">
              <label>APELIDO *</label>
              <input
                type='text'
                className={`form-control ${onInvalid(errors?.alias)}`}
                defaultValue={alias || ''}
                disabled
                {...register('alias', { required: true, minLength: 3, maxLength: 50 })}
              />
              <ErrorMessage name={'Apelido'} type={errors?.alias?.type} min={3} max={50} />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup className="form-group">
              <label>DESCRIÇÃO *</label>
              <textarea
                placeholder={`Ex: Permissão de acesso na página de ${pageViewEdit?.name ?? pageName}`}
                className={`form-control ${onInvalid(errors?.description)}`}
                rows={4}
                {...register('description', { required: true, minLength: 3, maxLength: 255 })}
              ></textarea>
              <ErrorMessage name={'Descrição'} type={errors?.description?.type} min={3} max={255} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="table-responsive">
              <table className="table table-flush m-0 w-100">
                <thead>
                  <tr>
                    <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">#ID</th>
                    <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">NOME</th>
                    <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">DESCRIÇÃO</th>
                    <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">PÁGINA</th>
                    <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">APELIDO</th>
                    <th className="text-uppercase text-gray text-xs font-weight-bolder opacity-7" scope="col">AÇÃO</th>
                  </tr>
                </thead>
                <tbody className="text-sm">
                  {renderList()}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
}

export default ModalAccessActionInsert;
