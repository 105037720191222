import React, { FC, useEffect, useState } from 'react';
import './ChartGroupAgreements.scss';
import { useBiCovenant } from '../../../../../../providers/BI/Dashboard/Covenant/BiCovenantProvider';
import { useBiDashboard } from '../../../../../../providers/BI/Dashboard/BiDashboardProvider';
import { BI_VIEWS_ENUM } from '../../../../../../models/BI/Dashboard/BiProductionParamsModel';
import { nameAbbreviation } from '../../../../../../utils/NameAbbreviation';
import { Card, Col, Row } from 'react-bootstrap';
import PieChart from '../../../PieChart/PieChart';
import SkeletonCircle from '../../../../../SkeletonCircle/SkeletonCircle';

interface ChartGroupAgreementsProps { }

const ChartGroupAgreements: FC<ChartGroupAgreementsProps> = () => {

  const [monthData, setMonthData] = useState<any[]>([]);
  const [annualData, setAnnualData] = useState<any[]>([]);
  const [isMonthLoading, setIsMonthLoading] = useState<boolean>(false);
  const [isAnnualLoading, setIsAnnualLoading] = useState<boolean>(false);

  const {
    annualGroupAgreements,
    setAnnualGroupAgreements,
    monthlyGroupAgreements,
    setMonthlyGroupAgreements
  } = useBiCovenant();

  const {
    handleListMonthlyProduction,
    handleListAnnualProduction,
    params } = useBiDashboard();

  useEffect(() => {
    setIsMonthLoading(true);
    setIsAnnualLoading(true);
    handleListMonthlyProduction({
      ...params,
      view: BI_VIEWS_ENUM.COVENANT_GROUP,
      group: {},
      sundrycovenant: true
    }).then((x: any) => {
      if (x) {
        setMonthlyGroupAgreements(x);
      }
    }).finally(() => setIsMonthLoading(false));

    handleListAnnualProduction({
      ...params,
      view: BI_VIEWS_ENUM.COVENANT_GROUP,
      group: {},
      sundrycovenant: true
    }).then((x: any) => {
      if (x) {
        setAnnualGroupAgreements(x);
      }
    }).finally(() => setIsAnnualLoading(false));
  }, [params]);

  useEffect(() => {
    if (monthlyGroupAgreements?.items &&
      monthlyGroupAgreements?.items?.length > 0 &&
      monthlyGroupAgreements?.items[0]?.periods &&
      monthlyGroupAgreements?.items[0]?.periods?.length > 0
    ) {
      let _data = monthlyGroupAgreements?.items?.map((item) => item?.periods ?
        { value: Number(item?.periods[item?.periods?.length - 1].totalProductionPercentage), label: nameAbbreviation(item?.name ?? '', 13) }
        : {}) ?? [];
      _data = _data?.sort((a: any, b: any) => a?.value > b?.value ? -1 : 1)?.filter((x: any, index: number) => index <= 9);
      setMonthData(_data);
    }
  }, [monthlyGroupAgreements]);

  useEffect(() => {
    if (annualGroupAgreements?.items &&
      annualGroupAgreements?.items?.length > 0 &&
      annualGroupAgreements?.items[0]?.periods &&
      annualGroupAgreements?.items[0]?.periods?.length > 0
    ) {
      let _data = annualGroupAgreements?.items?.map((item) => item?.periods ?
        { value: Number(item?.periods[item?.periods?.length - 1].totalProductionPercentage), label: nameAbbreviation(item?.name ?? '', 13) }
        : {}) ?? [];
      _data = _data?.sort((a: any, b: any) => a?.value > b?.value ? -1 : 1)?.filter((x: any, index: number) => index <= 9);
      setAnnualData(_data);
    }
  }, [annualGroupAgreements]);

  return (
    <div className="ChartGroupAgreements" data-testid="ChartGroupAgreements">
      <Row>
        <Col xl="6" className="mb-4">
          <Card className="h-100 shandow-xs-none">
            <Card.Header className="pb-0">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h6 className="surtitle text-gray m-0 text-start">PARTICIPAÇÃO MENSAL</h6>
                </div>
                <div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              {!isMonthLoading ?
                <PieChart
                  labels={monthData?.map((item) => item?.label)}
                  data={monthData?.map((item) => item?.value?.toFixed(2))}
                  // max={Math.trunc(Math.max.apply(Math, mock?.producoes?.map(o => o?.crescimento)))}
                  height={450}
                /> :
                <SkeletonCircle width={300} />}
            </Card.Body>
          </Card>
        </Col>

        <Col xl="6" className="mb-4">
          <Card className="h-100 shandow-xs-none">
            <Card.Header className="pb-0">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h6 className="surtitle text-gray m-0 text-start">PARTICIPAÇÃO ANUAL</h6>
                </div>
                <div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              {!isAnnualLoading ?
                <PieChart
                  labels={annualData?.map((item) => item?.label)}
                  data={annualData?.map((item) => item?.value?.toFixed(2))}
                  // max={Math.trunc(Math.max.apply(Math, mock?.producoes?.map(o => o?.crescimento)))}
                  height={450}
                /> :
                <SkeletonCircle width={300} />}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ChartGroupAgreements;
