import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './FormOperation.scss';
import { Button, Card, Col, FormGroup, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import makeAnimated from 'react-select/animated';
import { schema } from './Validate';
import { useAuth } from '../../../../../providers/AuthProvider';
import { customStyles } from '../../../../../models/SelectCustomStyles';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import { useProposalRuleOperation } from '../../../../../providers/Proposals/Rule/ProposalRuleOperationProvider';
import ProposalRuleChooseOperation from '../../ProposalRuleChooseOperation/ProposalRuleChooseOperation';
import { ProposalRuleOperationEnum } from '../../../../../enums/Proposals/Rule/Operation/ProposalRuleOperationEnum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { useProposalRule } from '../../../../../providers/Proposals/Rule/ProposalRuleProvider';
import { ProposalRuleOperationItemsModel } from '../../../../../models/Proposals/Rule/ProposalRuleModel';
import { ReactSelectOptions } from '../../../../../models/ReactSelectOptions';
import { useProposalConfig } from '../../../../../providers/Proposals/Config/ProposalConfigProvider';
import { typeInsert } from '../../../../../enums/Proposals/Rule/Operation/TypeInsertEnum';

interface IForm {
  operationId: any;
  typeInsertId: any;
  headerFieldId?: any[];
  tableColumnsNameId: any;
}

interface FormOperationProps {
  operationsQuery: IForm[],
  setOperationsQuery: Dispatch<SetStateAction<IForm[]>>,
}

const FormOperation: FC<FormOperationProps> = ({ operationsQuery, setOperationsQuery }) => {
  const [selectedOperation, setSelectedOperation] = useState<any | null>(null);

  const { onInvalid } = useAuth();
  const { rule } = useProposalRule();
  const { operationsOptions } = useProposalRuleOperation();
  const { fieldsTablesColumnsNamesOptions, fieldsOptions } = useProposalConfig();

  const animatedComponents = makeAnimated();

  const {
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<IForm>({
    mode: "onChange",
    resolver: yupResolver(schema)
  });

  const onSubmit = (data: any) => {
    setOperationsQuery((current: any[]) => [...current, ...[{ ...data }]]);

    setSelectedOperation(null);
    reset();
  }

  const handleRemove = (index: number) => {
    const array = [...operationsQuery];

    if (index !== -1) {
      array.splice(index, 1);
      setOperationsQuery(array);
    }
  }

  const renderQueryValues = (operation: any, headerField: any[]): JSX.Element => {
    return (
      <p className="d-flex m-0 text-sm">
        {headerField?.length > 0 && (
          <>
            {headerField?.map((item: any, key: number) => (
              <div key={key}>
                <span>{item?.label || item}</span>

                {![ProposalRuleOperationEnum.DO_NOT_PROCESS].includes(operation?.value) && (
                  <>
                    {key < (headerField?.length - 1) && (
                      <>
                        {([ProposalRuleOperationEnum.CONCAT, ProposalRuleOperationEnum.SUM, ProposalRuleOperationEnum.ASSIGN].includes(operation?.value)) && (<>&nbsp;+&nbsp;</>)}
                        {operation?.value === ProposalRuleOperationEnum.SUBTRACT && (<>&nbsp;-&nbsp;</>)}
                      </>
                    )}
                  </>
                )}
              </div>
            ))}
          </>
        )}
      </p>
    );
  }

  const renderOperations = (items: any[]): JSX.Element => {
    return (
      <>
        {items?.length > 0 && (
          <div className="badge badge-success w-100 my-3 p-3 text-start">
            {items?.map((item: any, key: number) => (
              <div className="item" key={key}>
                <Card>
                  <Card.Header className="position-relative py-3">
                    {item?.operationId?.label}

                    <button className="btn btn-icon btn-remove" onClick={() => handleRemove(key)}></button>
                  </Card.Header>
                  <Card.Body className={`${item?.headerFieldId?.length > 0 ? 'pt-0' : 'p-2'}`}>
                    {item?.headerFieldId?.length > 0 && (
                      <Card>
                        <Card.Body>
                          <div className="d-flex align-items-center justify-content-center gap-3">
                            <div>
                              {renderQueryValues(item?.operationId, item?.headerFieldId)}
                            </div>
                            <div><FontAwesomeIcon icon={faAnglesRight} /></div>
                            <div className="badge badge-primary text-wrap">{item?.tableColumnsNameId?.label}</div>
                          </div>
                        </Card.Body>
                      </Card>
                    )}

                    {item?.operationId?.value === ProposalRuleOperationEnum.DO_NOT_PROCESS && (
                      <p className="mb-3 text-center text-sm">O registro não será processado</p>
                    )}
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        )}
      </>
    );
  }

  useEffect(() => {
    if (rule?.operations) {
      setOperationsQuery(
        rule?.operations?.map((item: ProposalRuleOperationItemsModel) => {
          let _fields = item?.rulesOperationsResults?.map((x: any) => x?.headersFieldsId).filter((x: any) => !!x);
          let isFields = true;

          if (_fields?.length === 0) {
            _fields = item?.rulesOperationsResults?.map((x: any) => x?.value);
            isFields = false;
          }

          return {
            operationId: operationsOptions?.find((x: any) => x?.value === item?.rulesOperationsId),
            typeInsertId: typeInsert?.find((x: any) => x?.value === (!isFields ? 1 : 2)),
            headerFieldId: isFields ? fieldsTablesColumnsNamesOptions?.filter((x: any) => _fields?.indexOf(x?.value) !== -1) : _fields,
            tableColumnsNameId: fieldsOptions?.find((x: ReactSelectOptions) => x?.value === item?.tablesColumnsNamesProposalsId),
          };
        })
      );
    }
  }, [rule, fieldsTablesColumnsNamesOptions]);

  return (
    <div className="FormOperation" data-testid="FormOperation">
      <Row>
        <Col sm={12} md={12} lg={12} xl={3}>
          <FormGroup className="form-group m-0">
            <label htmlFor="operationId">Ação *</label>
            <Controller
              name="operationId"
              control={control}
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  isSearchable
                  options={operationsOptions}
                  placeholder="Selecione..."
                  className={`form-control p-0 ${onInvalid(errors?.operationId)}`}
                  value={selectedOperation ?? ''}
                  defaultValue={selectedOperation}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  onChange={(val: any) => {
                    onChange(val ?? null);
                    setSelectedOperation(val);
                  }}
                  styles={customStyles}
                />
              )}
            />
            <ErrorMessage name={'Ação'} type={errors?.operationId?.type?.toString()} />
          </FormGroup>
        </Col>
        <Col sm={12} md={12} lg={12} xl={9}>
          {selectedOperation?.value !== ProposalRuleOperationEnum.DO_NOT_PROCESS && (
            <FormGroup className="form-group m-0">
              {(selectedOperation?.value && selectedOperation?.value > 0) ? (
                <ProposalRuleChooseOperation
                  operationId={selectedOperation?.value ?? 0}
                  register={register}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                />
              ) : (
                <p className="message-info m-0 text-sm text-gray text-center mb-5">Selecione a ação</p>
              )}
            </FormGroup>
          )}
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          {renderOperations([...operationsQuery])}
        </Col>
      </Row>

      <Row>
        <Col md={12} className="text-end position-relative">
          <Button type="button" className={`btn-sm m-0`} onClick={handleSubmit(onSubmit)}>Adicionar</Button>
        </Col>
      </Row>
    </div>
  );
}

export default FormOperation;
