import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, isSupported } from "firebase/messaging";
import { getDatabase } from "firebase/database";
import { toast } from "react-toastify";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY ?? '',
    authDomain: "lacqua-app.firebaseapp.com",
    databaseURL: "https://lacqua-app-default-rtdb.firebaseio.com",
    projectId: "lacqua-app",
    storageBucket: "lacqua-app.appspot.com",
    messagingSenderId: "416870578300",
    appId: "1:416870578300:web:b56f9a989606a0537631f9",
    measurementId: "G-LBQK68VFNV"
};

const app = initializeApp(firebaseConfig);

export const messaging = (async () => await isSupported() ? getMessaging(app) : null);
export const realtimedb = getDatabase(app);

messaging && onMessage(messaging, (payload) => {
    const notificationTitle = payload.notification.title;
    const notificationOptions = { body: payload.notification.body, icon: payload.notification.image };

    toast(
        <div className="d-flex align-items-center gap-2">
            <FontAwesomeIcon icon={faInfoCircle} />
            <div>
                {notificationTitle && (
                    <h5>{notificationTitle}</h5>
                )}
                {payload?.notification?.body && (
                    <p className="mb-0">{payload.notification.body}</p>
                )}
            </div>
        </div>,
        {
            position: "top-right",
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    new Notification(notificationTitle, notificationOptions);
});
