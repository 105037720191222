import { FC } from 'react';
import './ImportToolsProcessed.scss';
import { useProposalImportMonitor } from '../../../../../providers/Proposals/Import/Monitor/ProposalImportMonitorProvider';

interface ImportToolsProcessedProps { }

const ImportToolsProcessed: FC<ImportToolsProcessedProps> = () => {

  const { imports, importsOptions } = useProposalImportMonitor();

  return (
    <>
      <div className="row" data-testid="ImportToolsProcessed">
        <div className="col-lg-12 ">

          <div className="row">

            {imports ? (
              <>
                <div className="col-xl-4 col-lg-6 col-md-12 col-12">
                  <div className="card  mb-4 h-100">
                    <div className="card-body p-3">
                      <div className="row ">
                        <div className="col-8">
                          <div className="numbers">
                            <p className="text-sm mb-0 text-uppercase font-weight-bold">TOTAL IMPORTADO</p>
                            <h5 className="align-postition-number" >
                              {(importsOptions?.map((item: any) => item?.totalQuantity))?.reduce((acc: any, cur: any) => acc + cur, 0)}
                            </h5>
                          </div>
                        </div>
                        <div className="col-4 text-end">
                          <div className='icon icon-shape bg-gradient-warning shadow-warning text-center rounded-circle'>
                            <i className="fa-solid fa-file-arrow-up text-lg opacity-10" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-12 col-12">
                  <div className="card  mb-4 h-100 ">
                    <div className="card-body p-3">
                      <div className="row">
                        <div className="col-8">
                          <div className="numbers">
                            <p className="text-sm mb-0 text-uppercase font-weight-bold">TOTAL PROCESSADO</p>
                            <h5 className="align-postition-number-processed">
                              {(importsOptions?.map((item: any) => item?.totalProcessed))?.reduce((acc: any, cur: any) => acc + cur, 0)}
                            </h5>
                          </div>
                        </div>
                        <div className="col-4 text-end">
                          <div className='icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle'>
                            <i className="fa-solid fa-file-arrow-down  text-lg opacity-10" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-12 col-12 ">
                  <div className="card  mb-4 h-100 ">
                    <div className="card-body p-3 ">
                      <div className="row">
                        <div className="col-8">
                          <div className="numbers">
                            <p className="text-sm mb-0 text-uppercase font-weight-bold">TOTAL PROCESSADO NOVO</p>
                            <h5 className="align-postition-number">
                              {(importsOptions?.map((item: any) => item?.totalProcessedNew))?.reduce((acc: any, cur: any) => acc + cur, 0)}
                            </h5>
                          </div>
                        </div>
                        <div className="col-4 text-end">
                          <div className='icon icon-shape bg-gradient-success shadow-success text-center rounded-circle'>
                            <i className="fa-solid fa-file-circle-plus text-lg opacity-10" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <tr>
                <td colSpan={9} className="text-center text-sm">Não há registros</td>
              </tr>
            )}
          </div>

        </div>
      </div>
    </>
  );

}

export default ImportToolsProcessed;