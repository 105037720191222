import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react";
import { ContractFormModel } from "../../../models/Proposals/ContractForm/ContractFormModel";
import { ContractFormService } from "../../../services/Proposals/ContractForm/ContractFormService";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";

interface ContextProps {
    contractForms: ContractFormModel[],
    contractFormsOptions: ReactSelectOptions[],
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>
    handleList: () => Promise<any>,
    handleSave: (payload: ContractFormModel) => Promise<ContractFormModel | null>
}

export const ContractFormContext = createContext<ContextProps>({} as ContextProps);

interface ContractFormProviderProps {
    children: ReactNode;
}

export const ContractFormProvider = ({ children }: ContractFormProviderProps) => {
    const [contractForms, setContractForms] = useState<ContractFormModel[]>([]);
    const [contractFormsOptions, setContractFormsOptions] = useState<ReactSelectOptions[]>([]);
    const [params, setParams] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const service = new ContractFormService();

    const handleList = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                setContractForms([]);
                setContractFormsOptions([]);
                return setError(_Error);
            }

            setContractForms(_Response?.data);
            setContractFormsOptions(_Response?.data?.map((x: ContractFormModel) => (
                {
                    value: x.id,
                    label: x.name,
                    externalId: x.externalId
                }
            )));
            return setError('');

        } catch (e: any) {
            setContractForms([]);
            setContractFormsOptions([]);
            setIsLoading(false);
            return setError(e);
        }
    }

    const handleSave = async (payload: ContractFormModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.save(payload);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return null
            }

            setError('');
            return _Response?.data;

        } catch (e: any) {
            setIsLoading(false);
            setError(e);
            return null
        }
    }

    return (
        <ContractFormContext.Provider value={useMemo(() => ({
            contractForms,
            contractFormsOptions,
            params,
            setParams,
            isLoading,
            error,
            setError,
            handleList,
            handleSave
        }), [
            contractForms,
            contractFormsOptions,
            params,
            setParams,
            isLoading,
            error,
            setError,
            handleList,
            handleSave
        ])}>
            {children}
        </ContractFormContext.Provider>
    );
}

export const useContractForm = () => useContext(ContractFormContext);