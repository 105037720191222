import { FC } from 'react';
import './VisitsQuantityList.scss';
import { Card } from 'react-bootstrap';
import { VisitQuantityModel } from '../../../../models/Visits/IndicatorManagement/VisitModel';
import { useVisits } from '../../../../providers/Visits/VisitsProvider';
import { usePaginate } from '../../../../providers/PaginateProvider';
import AppPagination from '../../../AppPagination/AppPagination';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';

interface VisitsQuantityListProps {
}

const VisitsQuantityList: FC<VisitsQuantityListProps> = () => {
  const { visitsQtd, pagesQuantity, params, isLoading, setParams } = useVisits();
  const { handlePaginate } = usePaginate();

  const renderList = (): JSX.Element => {
    return (
      <>
        {visitsQtd?.length > 0 ? (
          <>
            {visitsQtd.map((item: VisitQuantityModel, key: number) => (
              <tr key={item.partnerCode} className="text-sm">
                <td>{item?.partnerCode}</td>
                <td>{item?.name}</td>
                <td className="text-center">{item?.total}</td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={3} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  return (
    <div className="VisitsQuantityList h-100" data-testid="VisitsQuantityList">
      <Card className='h-100'>
        <Card.Body>
          {!isLoading ? (
            <>
              <div className="table-responsive">
                <table className="table table-flush m-0">
                  <thead className="thead-light">
                    <tr>
                      <th className="opacity-7" scope="col" style={{ maxWidth: '150px' }}>AGENTE</th>
                      <th className="opacity-7" scope="col" style={{ maxWidth: '150px' }}>NOME</th>
                      <th className="opacity-7 text-wrap" scope="col">ÚLTIMA VISITA HÁ (DIAS)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderList()}
                  </tbody>
                </table>
              </div>

              {(visitsQtd?.length > 0) && (
                <div>
                  <nav aria-label="...">
                    <AppPagination
                      pages={pagesQuantity}
                      handlePaginate={handlePaginate}
                      params={params}
                      setParams={setParams}
                      style={{ backgroundColor: '#fff' }}
                    />
                  </nav>
                </div>
              )}
            </>
          ) : (
            <SkeletonTable />
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default VisitsQuantityList;
