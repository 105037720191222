import React, {FC, useEffect, useState} from 'react';
import './Endpoints.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../providers/PaginateProvider';
import { useExample } from '../../../providers/ExampleProvider';
import { toast } from 'react-toastify';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBan, faCheckCircle, faXmark, faPencil, faCloudDownload, faEye} from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2';
import Spinner from "../../../components/Spinner/Spinner";
import { useEndpoint } from '../../../providers/Marketplace/Endpoint/EndpointProvider';
import { EndpointModel } from '../../../models/Marketplace/Endpoint/EndpointModel';
import ModalEndpointUsers from '../../../components/Marketplace/Endpoint/ModalEndpointUsers/ModalEndpointUsers';

interface EndpointsProps { }

const Endpoints: FC<EndpointsProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);

  const { setShowSidebarFilter } = useSidebarFilter();
  const { pages, params, setParams, updatedEndpoint, isLoading, error, endpoints, handleGetEndpoints, onClickChangeEndpointStatus} = useEndpoint();
  const { handlePaginate } = usePaginate();
  const [showEndpoint, setShowEndpoint] = useState<boolean>(false);
  const [endpointView, setEndpointView] = useState<EndpointModel | null>(null);


  const renderList = (): JSX.Element => {
    return (
      <>
        {endpoints?.length > 0 ? (
          <>
            {endpoints.map((x: EndpointModel) => (
              <tr key={x.sanitize_type_id} className="text-sm">
                <td># {x.sanitize_type_id}</td>
                <td>{x.sanitize_type_name}</td>
                <td>{x?.sanitize_type_endpoint}</td>
                <td>
                  <div className={`badge badge-sm ${x.sanitize_type_active ? 'badge-success' : 'badge-danger'}`}>
                      {x.sanitize_type_active ? 'Ativo' : 'Inativo'}
                  </div>
                </td>
                <td className='text-center'>
                    <DropdownMenuTable key={x.sanitize_type_id || 0}>
                        <Dropdown.Item className="border-radius-md py-2" onClick={() => {setShowEndpoint(true); setEndpointView(x); }}>
                            <FontAwesomeIcon icon={faPencil} className="me-2" /> Usuário
                        </Dropdown.Item>
                        <Dropdown.Item className="border-radius-md py-2" onClick={() => onClickChangeEndpointStatus(x?.sanitize_type_id || 0)} >
                            <FontAwesomeIcon icon={x.sanitize_type_active ? faXmark : faCheckCircle} className="me-2" /> {x.sanitize_type_active ? 'Desativar' : 'Ativar'}
                        </Dropdown.Item>
                    </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={5} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7" scope="col">#</th>
                  <th className="text-uppercase opacity-7" scope="col">Nome</th>
                  <th className="text-uppercase opacity-7" scope="col">Endpoint</th>
                  <th className="text-uppercase opacity-7" scope="col">Atividade</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}
      </>
    );
  }

  useEffect(() => {
      handleGetEndpoints();
  }, []);

  useEffect(() => {
    if (updatedEndpoint) {
      handleGetEndpoints();
    }
  }, [updatedEndpoint]);


  return (
    <MasterPage
      title="Endpoints"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="Endpoints" data-testid="Endpoints">
        <PageBase
          title="Endpoints"
          subtitle="Endpoints usados para higienização das propostas."
          error={error}
          content={renderContent()}
        />
      </div>

      {(isLoading) && <Spinner />}

      <ModalEndpointUsers
            show={showEndpoint}
            onClose={setShowEndpoint}
            endpoint={endpointView}
            setEndpoint={setEndpointView}
        />
    </MasterPage>
  );
}

export default Endpoints;
