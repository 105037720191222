import React, { FC, useState } from 'react';
import './GroupProduction.scss';
import { Col, Row } from 'react-bootstrap';
import NavPills from '../../../../NavPills/NavPills';
import PageBase from '../../../../PageBase/PageBase';
import TableMonthlyProduction from './TableMonthlyProduction/TableMonthlyProduction';
import TableAnnualProduction from './TableAnnualProduction/TableAnnualProduction';
import ChartGroupParticipation from './ChartGroupParticipation/ChartGroupParticipation';

interface GroupProductionProps { }

export enum TabEnumGroupProduction {
  MONTHLY_PRODUCTION = 'monthly_production',
  ANNUAL_PRODUCTION = 'annual_production',
  CHART_PARTICIPATION = 'chart_participation'
}

const GroupProduction: FC<GroupProductionProps> = () => {

  const [currentActiveTab, setCurrentActiveTab] = useState<TabEnumGroupProduction | string>(TabEnumGroupProduction.MONTHLY_PRODUCTION);

  const renderContent = (): JSX.Element => {
    return (
      <>
        <Row>
          <Col md={12}>
            <NavPills mdMenu={12}
              options={[
                {
                  acronym: TabEnumGroupProduction.MONTHLY_PRODUCTION,
                  name: 'Produção Mensal',
                  icon: <i className="fa-solid fa-table"></i>,
                  show: true,
                },
                {
                  acronym: TabEnumGroupProduction.ANNUAL_PRODUCTION,
                  name: 'Produção Anual',
                  icon: <i className="fa-solid fa-table"></i>,
                  show: true,
                },
                {
                  acronym: TabEnumGroupProduction.CHART_PARTICIPATION,
                  name: 'Gráfico de Participação',
                  icon: <i className="fa-solid fa-chart-pie"></i>,
                  show: true,
                }
              ]}
              current={currentActiveTab}
              setCurrent={setCurrentActiveTab}
            />
          </Col>
        </Row>

        {currentActiveTab === TabEnumGroupProduction.MONTHLY_PRODUCTION &&
          <TableMonthlyProduction />
        }
        {currentActiveTab === TabEnumGroupProduction.ANNUAL_PRODUCTION &&
          <TableAnnualProduction />
        }
        {currentActiveTab === TabEnumGroupProduction.CHART_PARTICIPATION &&
          <ChartGroupParticipation />
        }

      </>
    );
  }

  return (
    <div className="GroupProduction" data-testid="GroupProduction">
      <PageBase
        content={renderContent()}
        showCardHeader={false}
      />
    </div>
  );
};

export default GroupProduction;
