import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BiMonthlyProductionModel } from "../../../models/BI/Dashboard/BiMonthlyProductionModel";
import { BiAnnualProductionModel } from "../../../models/BI/Dashboard/BiAnnualProductionModel";


interface ContextProps {
    productionByUfs: BiMonthlyProductionModel | undefined,
    annualProductionByUfs: BiAnnualProductionModel | undefined,
    setProductionByUfs: Dispatch<SetStateAction<BiMonthlyProductionModel | undefined>>,
    setAnnualProductionByUfs: Dispatch<SetStateAction<BiAnnualProductionModel | undefined>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
}

export const BiGeneralProductionContext = createContext<ContextProps>({} as ContextProps);

interface BiGeneralProductionProviderProps {
    children: ReactNode;
}

export const BiGeneralProductionProvider = ({ children }: BiGeneralProductionProviderProps) => {
    const [productionByUfs, setProductionByUfs] = useState<BiMonthlyProductionModel>();
    const [annualProductionByUfs, setAnnualProductionByUfs] = useState<BiAnnualProductionModel>();
    const [params, setParams] = useState<any>({ withPaginate: false });
    const [error, setError] = useState<string>('');

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    return (
        <BiGeneralProductionContext.Provider value={{
            productionByUfs,
            annualProductionByUfs,
            setProductionByUfs,
            setAnnualProductionByUfs,
            params,
            setParams,
            error,
            setError,
        }}>
            {children}
        </BiGeneralProductionContext.Provider>
    );
}

export const useBiGeneralProduction = () => useContext(BiGeneralProductionContext);