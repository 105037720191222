import { MonthlyGoalCategoryModel } from "../../../models/PanelConnects/MonthlyGoalCategoryModel";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/connect';

export class MonthlyGoalConditionService extends BaseService {

    async getTag(): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/goals/tags`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getScoreTag(isCalculable: boolean | null, isObservable: boolean | null): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/goals/scores/tags`, { isCalculable, isObservable }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getCondition(): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/goals/conditions`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getOperation(): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/goals/scores/operations`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getCategories(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/categories`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async saveCategory(payload: MonthlyGoalCategoryModel): Promise<any[]> {
        try {
            return this.handleResponse(await api.put(`${URL}/categories`, payload));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async deleteCategory(id: number): Promise<any[]> {
        try {
            return this.handleResponse(await api.delete(`${URL}/categories`, { data: { id } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

}