import api from "../../Api";
import { BaseService } from "../../BaseService";



const URL = 'api/services/marketplace';
export class AccessControlService extends BaseService {

    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/admin/access/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async fetch(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/admin/access/add`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async delete(uid: number): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/admin/access/remove?uid=${uid}`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}