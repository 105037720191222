import { FC, useEffect, useState } from 'react';
import './CovenantTableConfig.scss';
import MasterPage from '../../../../components/MasterPage/MasterPage';
import PageBase from '../../../../components/PageBase/PageBase';
import SkeletonTable from '../../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../../components/AppPagination/AppPagination';
import { useSidebarFilter } from '../../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../../providers/PaginateProvider';
import { toast } from 'react-toastify';
import DropdownMenuTable from '../../../../components/DropdownMenuTable/DropdownMenuTable';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useCovenantTableConfig } from '../../../../providers/Tools/Configurations/CovenantTableConfigProvider';
import SidebarCovenantTableConfigFilter from '../../../../components/Tools/Configurations/CovenantTable/SidebarCovenantTableConfigFilter/SidebarCovenantTableConfigFilter';
import ModalCovenantTableConfigInsert from '../../../../components/Tools/Configurations/CovenantTable/ModalCovenantTableConfigInsert/ModalCovenantTableConfigInsert';
import { CovenantTableConfigListModel } from '../../../../models/Tools/Configurations/CovenantTable/CovenantTableConfigListModel';
import moment from 'moment';

interface CovenantTableConfigProps { }

const CovenantTableConfig: FC<CovenantTableConfigProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
  const [itemConfig, setItemConfig] = useState<CovenantTableConfigListModel>({});

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { handleList, handleDelete, covenantTableConfigs, isLoading, params, setParams, pages, error, setError } = useCovenantTableConfig();
  const { handlePaginate } = usePaginate();

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError('');
    }
  }, [error]);

  const handleConfig = (item: CovenantTableConfigListModel) => {
    setItemConfig(item);
    setShowModalInsert(true);
  }

  const handleDeleteCovenantTableConfig = (item: CovenantTableConfigListModel) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja excluir ${item?.financial?.name} - ${item.product}?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleDelete(item.id ?? 0).then();
        if (ret) {
          toast.success('Item excluído com sucesso!');
          handleList().then();
        }
      }
    });
  }

  const renderList = (): JSX.Element => {
    return (
      <>
        {covenantTableConfigs?.length > 0 ? (
          <>
            {covenantTableConfigs.map((x: CovenantTableConfigListModel) => (
              <tr key={x.id} className="text-sm">
                <td>{x.id}</td>
                <td>
                  {x?.origin === 'P'
                    ?
                    <span className='badge rounded-pill bg-gradient-primary'>Produção</span>
                    :
                    <span className='badge rounded-pill bg-gradient-secondary'>Comissão</span>
                  }
                </td>
                <td>{moment(x?.createdAt).format('DD/MM/YYYY')}</td>
                <td className='text-wrap'>{x?.adeNumber}</td>
                <td>{x?.financial?.name}</td>
                <td className='text-wrap'>{x?.product}</td>
                <td className='text-wrap'>{x?.operation}</td>
                <td>{x?.form}</td>
                <td className='text-wrap'>
                  {x?.proposalTable
                    ?
                    <span className='badge bg-success text-wrap'>{x?.proposalTable?.name}</span>
                    :
                    <span className='badge bg-danger'>NÃO CONFIGURADA</span>
                  }
                </td>
                <td className="text-center">
                  <DropdownMenuTable key={x.id ?? 0}>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleConfig(x)}>
                      <FontAwesomeIcon icon={faEdit} className="me-2" /> Configurar
                    </Dropdown.Item>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleDeleteCovenantTableConfig(x)}>
                      <FontAwesomeIcon icon={faTrash} className="me-2" /> Remover
                    </Dropdown.Item>
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={10} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <>
            <div className="table-responsive">
              <table className="table table-flush m-0 w-100">
                <thead className="thead-light">
                  <tr>
                    <th className="text-uppercase opacity-7" scope="col">Código</th>
                    <th className="text-uppercase opacity-7" scope="col">Origem</th>
                    <th className="text-uppercase opacity-7" scope="col">Data</th>
                    <th className="text-uppercase opacity-7" scope="col">ADE</th>
                    <th className="text-uppercase opacity-7" scope="col">Financeira</th>
                    <th className="text-uppercase opacity-7" scope="col">Convênio</th>
                    <th className="text-uppercase opacity-7" scope="col">Tabela</th>
                    <th className="text-uppercase opacity-7" scope="col">Forma</th>
                    <th className="text-uppercase opacity-7" scope="col">Tabela Configurada</th>
                    <th className="text-uppercase opacity-7 text-center" scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {renderList()}
                </tbody>
              </table>
            </div>
            {pages?.current && (
              <div>
                <nav aria-label="...">
                  <AppPagination
                    pages={pages}
                    handlePaginate={handlePaginate}
                    params={params}
                    setParams={setParams}
                    style={{ backgroundColor: '#fff' }}
                  />
                </nav>
              </div>
            )}
          </>
        ) : (
          <SkeletonTable />
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="CovenantTableConfig"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="CovenantTableConfig" data-testid="CovenantTableConfig">
        <PageBase
          title="Configuração de Tabelas de Convênio"
          subtitle="Nessa página é feita a configuração das tabelas de convênio para importação da Produção."
          handleFilter={() => showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          content={renderContent()}
          hasFilter
        />

        <ModalCovenantTableConfigInsert
          show={showModalInsert}
          onClose={() => setShowModalInsert(false)}
          covenantTableConfig={itemConfig}
        />
      </div>

      <SidebarCovenantTableConfigFilter />
    </MasterPage>
  );
}

export default CovenantTableConfig;
