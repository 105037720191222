import { FC } from 'react';
import './LoginThumb.scss';
import Login from '../../../../../pages/Login/Login';

interface LoginThumbProps {
  backgroundLogin?: string;
  logoDefault?: string;
  colorPrimary?: string;
}

const LoginThumb: FC<LoginThumbProps> = ({
  backgroundLogin = '',
  logoDefault = '',
  colorPrimary = '',
}) => {
  return (
    <div className="LoginThumb" data-testid="LoginThumb">
      <Login
        backgroundLogin={backgroundLogin}
        logoDefault={logoDefault}
        colorPrimary={colorPrimary}
        thumb
      />
    </div>
  );
}

export default LoginThumb;
