import { FC, useEffect, useState } from 'react';
import './AccessControl.scss';
import { toast } from 'react-toastify';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../providers/PaginateProvider';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import { useAccessControl } from '../../../providers/Marketplace/AccessControl/AccessControlProvider';
import { AccessControlModel } from '../../../models/Marketplace/AccessControl/AccessControlModel';
import ModalAccessControlInsert from '../../../components/Marketplace/AccessControl/ModalAccessControlInsert/ModalAccessControlInsert';
import SidebarFilter from '../../../components/SidebarFilter/SidebarFilter';

interface AccessControlProps { }

const AccessControl: FC<AccessControlProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();

  const {
    accessControls,
    isLoading,
    params,
    pages,
    error,
    setParams,
    handleList,
    handleDelete
  } = useAccessControl();
  const { handlePaginate } = usePaginate();

  useEffect(() => {
    if (showSidebarFilter) {
      setShowSidebarFilter(false)
    }
  }, [showSidebarFilter]);

  useEffect(() => {
    handleList();
  }, [params]);

  const handleDeleteAccessControl = (item: any) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja deletar o acesso ?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleDelete(item.uid || 0).then();
        if (ret) {
          toast.success('Acesso de controle removido com sucesso!');
          handleList();
        } else {
          await Swal.fire('Ops!', `Não foi possível remover o Acesso de controle .<br>${error}`, 'error');
        }
      }
    });
  }

  const renderList = (): JSX.Element => {
    return (
      <>
        {accessControls?.length > 0 ? (
          <>
            {accessControls.map((x: AccessControlModel, key: number) => (
              <tr key={x.uid} className="text-sm">
                <td >{x?.promoter_name ?? '-'}</td>
                <td>{x?.level_name ?? '-'}</td>
                <td>{x?.agent_type_name ?? '-'}</td>
                <td>{x?.user_partner_name ?? '-'}</td>
                <td className="text-center">
                  <span
                    className={`badge ${x?.positive ? 'badge-success' : 'badge-danger'}`}>{x?.positive ? 'POSITIVA' : 'NEGATIVA'}
                  </span>
                </td>
                <td className="text-center">
                  <DropdownMenuTable key={key}>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleDeleteAccessControl(x)}>
                      <FontAwesomeIcon icon={faTrash} className="me-2" /> Remover
                    </Dropdown.Item>
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={5} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7" scope="col">Promotora</th>
                  <th className="text-uppercase opacity-7" scope="col">Nível</th>
                  <th className="text-uppercase opacity-7" scope="col">Tipo Agente</th>
                  <th className="text-uppercase opacity-7" scope="col">Código Parceiro</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Excessão</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}

        {accessControls?.length > 0 && (
          <div>
            <nav aria-label="...">
              <AppPagination
                pages={pages}
                handlePaginate={handlePaginate}
                params={params}
                setParams={setParams}
                style={{ backgroundColor: '#fff' }}
              />
            </nav>
          </div>
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="Controle de Acesso"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="AccessControl" data-testid="AccessControl">
        <PageBase
          title="Controle de Acesso"
          subtitle="Gerenciamento de controle de acesso Marketplace"
          handleInsert={() => setShowModalInsert(true)}
          // handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          error={error}
          content={renderContent()}
        // hasFilter
        />

        <ModalAccessControlInsert
          show={showModalInsert}
          onClose={() => setShowModalInsert(false)}
        />
      </div>

      <SidebarFilter />
      {/* <SidebarAccessControlFilter /> */}
    </MasterPage>
  );
}

export default AccessControl;
