import { FC } from 'react';
import './HomeNotice.scss';
import { useStyle } from '../../providers/Style/StyleProvider';
import { useAuth } from '../../providers/AuthProvider';

interface HomeNoticeProps { }

const HomeNotice: FC<HomeNoticeProps> = () => {
  const { style, urlDomus } = useStyle();
  const { userLogged, accessToken } = useAuth();

  return (
    <main className="HomeNotice main-content mt-0" data-testid="HomeNotice">
      <section>
        <div className="page-header min-vh-100">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                <div className="card card-plain">
                  <div className="card-body px-lg-5 py-lg-5 text-center">
                    <div className="text-center text-muted mb-4">
                      <h2>Acesso sistema</h2>
                    </div>
                    <div className="row gx-2 gx-sm-3">
                      <p className="text-muted">Para logar no sistema, você precisa acessar o link desejado através do sistema domus.</p>
                    </div>
                    <div className="text-center">
                      {urlDomus && (
                        <a href={userLogged ? `${urlDomus}/app/api/v1/auth/authTokenSB?token=${accessToken}` : `${urlDomus}`}>
                          <button type="button" className="btn btn-primary w-100">Voltar para a página inicial</button>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                <div className="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden" style={{ backgroundImage: `url('${style?.backgroundLogin}')`, backgroundSize: 'cover' }}>
                  <span className="mask bg-primary opacity-6"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default HomeNotice;
