import React, { FC } from 'react';
import './BeviUniversity.scss';

interface BeviUniversityProps {}

const BeviUniversity: FC<BeviUniversityProps> = () => (
  <div className="BeviUniversity" data-testid="BeviUniversity">
    BeviUniversity Component
  </div>
);

export default BeviUniversity;
