import { ProposalConfigListParams } from "../../../models/Proposals/Config/ProposalConfigListParams";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/services/proposals/rule';

export class ProposalRuleService extends BaseService {
    async list(params: ProposalConfigListParams): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async fetch(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/save`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async delete(id: number): Promise<any> {
        try {
            return this.handleResponse(await api.delete(`${URL}`, { data: { id } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}