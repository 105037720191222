import { FC, useEffect, useState } from 'react';
import { ProposalRuleFormFilterParams } from '../../../../models/Proposals/Rule/ProposalRuleFormFilterParams';
import { ReactSelectOptions } from '../../../../models/ReactSelectOptions';
import moment from 'moment';
import { useProposalRule } from '../../../../providers/Proposals/Rule/ProposalRuleProvider';
import { useFinancialProcess } from '../../../../providers/Proposals/Process/FinancialProcessProvider';
import { useSidebarFilter } from '../../../../providers/SidebarFilterProvider';
import makeAnimated from 'react-select/animated';
import { useForm } from 'react-hook-form';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import { Col, FormGroup, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import InputSearchPartner from '../../../Registrations/Partner/InputSearchPartner/InputSearchPartner';
import InputDateRange from '../../../InputDateRange/InputDateRange';

interface SidebarProposalRuleFilterProps { }

// @ts-ignore
export const defaultValues = {
  user: null,
  financials: [],
  startDate: '',
  endDate: '',
} as ProposalRuleFormFilterParams;

const SidebarProposalRuleFilter: FC<SidebarProposalRuleFilterProps> = () => {
  const [selectedFinancial, setSelectedFinancial] = useState<ReactSelectOptions[]>([]);
  const [startDate, setStartDate] = useState<any>(moment(new Date()).format('YYYY-MM-01 HH:mm:ss'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
  const [valuePartner, setValuePartner] = useState<string>('');

  const { params, setParams } = useProposalRule();
  const { handleList: handleListFinancials, financialsOptions } = useFinancialProcess();
  const { showSidebarFilter } = useSidebarFilter();

  const animatedComponents = makeAnimated();

  const { handleSubmit, control, setValue } = useForm<any>({ defaultValues });

  const onSubmit = async (data: ProposalRuleFormFilterParams) => {
    data.startDate = data?.startDate ? moment(data?.startDate).format('YYYY-MM-DD') : '';
    data.endDate = data?.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : '';
    data.user = data?.user ? parseInt(data?.user?.value) : null;
    data.financials = data?.financials?.map((item: any) => item.value) ?? [];

    setParams({ ...params, ...data, ...{ page: 1 } });
  }

  useEffect(() => {
    if (showSidebarFilter) {
      handleListFinancials();
    }
  }, [showSidebarFilter]);

  useEffect(() => {
    setValue('financials', selectedFinancial);
  }, [selectedFinancial]);

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarProposalRuleFilter" data-testid="SidebarProposalRuleFilter">
        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="financials">FINANCEIRA</label>
              <ReactSelect
                name="financials"
                isMulti
                isClearable
                isSearchable
                options={financialsOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                value={selectedFinancial ?? ''}
                defaultValue={selectedFinancial}
                components={animatedComponents}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedFinancial(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="user">USUÁRIO CADASTRO</label>
              <InputSearchPartner
                name="user"
                control={control}
                setValue={setValue}
                valuePartner={valuePartner}
                setValuePartner={setValuePartner}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              startLabelText={'DATA INICIAL'}
              endLabelText={'DATA FINAL'}
            />
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  );
}

export default SidebarProposalRuleFilter;
