import { FC, useEffect, useState } from 'react';
import './ModalSeeHistoryInsert.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { schema } from './Validate';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useAuth } from '../../../../providers/AuthProvider';
import { useExample } from '../../../../providers/ExampleProvider';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import AppPagination from '../../../AppPagination/AppPagination';
import { useTermsTracking } from '../../../../providers/Terms/TermsTrackingProvider';
import { usePaginate } from '../../../../providers/PaginateProvider';
import moment from 'moment';
import FormatCpfCnpj from '../../../../utils/FormatCpfCnpj';
import returnBadge from '../../../../utils/BadgeTermStatus';

interface ModalSeeHistoryInsertProps {
  show: boolean;
  onClose: () => void;
}

const ModalSeeHistoryInsert: FC<ModalSeeHistoryInsertProps> = ({ show, onClose }) => {

  const {
    termsTrackingHistory,
    isLoadingSeeHistory,
    pagesSeeHistory
  } = useTermsTracking()

  const { handlePaginate } = usePaginate();

  const handleClose = () => {
    onClose();
  }

  const renderList = (): JSX.Element => {
    return (
      <>
        {termsTrackingHistory?.length > 0 ? (
          <>
            {termsTrackingHistory?.map((x: any, key: number) => (
              <>
                <tr>
                  <td><small> <b> {x.id} </b> </small></td>
                  <td>
                    {x?.user?.partnerCode} - {x?.user?.name?.toUpperCase()}
                  </td>
                  <td>
                    <span className={`badge ${returnBadge(x?.termStatus?.id)}`}>
                      {x?.termStatus.name}
                    </span>
                  </td>
                  <td>{moment(x?.createdAt).format("DD/MM/YYYY HH:mm:ss")}</td>
                </tr>
              </>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={9} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  };

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoadingSeeHistory ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7" scope="col">#</th>
                  <th className="text-uppercase opacity-7" scope="col">Responsável</th>
                  <th className="text-uppercase opacity-7" scope="col">Status</th>
                  <th className="text-uppercase opacity-7" scope="col">Data</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}

        {termsTrackingHistory?.length > 0 && (
          <div>
            <nav aria-label="...">
              <AppPagination
                pages={pagesSeeHistory}
                handlePaginate={handlePaginate}
                style={{ backgroundColor: '#f8f9fa' }}
              />
            </nav>
          </div>
        )}
      </>
    );
  }

  return (
    <ModalDefault
      title={"Ver Histórico"}
      show={show}
      onClose={handleClose}
      sizeModal={'lg'}
      showFooter={false}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalSeeHistoryInsert" data-testid="ModalSeeHistoryInsert">
        {renderContent()}
      </div>
    </ModalDefault>
  );
}

export default ModalSeeHistoryInsert;
