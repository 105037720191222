import React, { FC } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Placement } from "react-bootstrap/types";

interface TooltipItemProps extends React.HTMLAttributes<HTMLDivElement> {
    id?: string,
    text?: string | JSX.Element,
    position: Placement;
    backgroundColor?: string
}

const TooltipItem: FC<TooltipItemProps> = ({ id, text, position, children, backgroundColor = '#000' }) => {
    const entering = (e: any) => {
        e?.children[0]?.style?.setProperty('--bs-tooltip-bg', backgroundColor);
        e.children[1].style.backgroundColor = backgroundColor;
    }

    const renderTooltip = (props: any) => (
        <Tooltip id={'tooltip-' + id} {...props}>
            <>{text}</>
        </Tooltip>
    );

    return (
        <OverlayTrigger
            key={'top'}
            placement={position}
            delay={{ show: 150, hide: 400 }}
            overlay={renderTooltip}
            onEntering={entering}
        >
            <span data-testid="TooltipItem">{children}</span>
        </OverlayTrigger>
    );
};

export default TooltipItem;
