import { FC, useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'react-bootstrap';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import AppPagination from '../../../AppPagination/AppPagination';
import { usePaginate } from '../../../../providers/PaginateProvider';
import './CommercialScoreGeneral.scss';
import { useMetricManagementProvider } from '../../../../providers/pgm/MetricManagement/MetricManagementProvider';

interface CommercialScoreGeneralProps { };

const CommercialScoreGeneral: FC<CommercialScoreGeneralProps> = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { handlePaginate }        = usePaginate();
    const { metric }                = useMetricManagementProvider();

    const tableList = () => {
        return (
            <>
                {
                    metric?.pontuacaoComercialGeral && metric?.pontuacaoComercialGeral?.length > 0 ? (
                        <>
                            {
                                metric.pontuacaoComercialGeral.map((item:any, index:number) => (
                                    <tr className={`tr-${index}`}>
                                        <td> {item?.nomeComercial} </td>
                                        <td className="text-center"> {item['2024-01-01']} </td>
                                        <td className="text-center"> {item['2024-02-01']} </td>
                                        <td className="text-center"> {item['2024-03-01']} </td>
                                        <td className="text-center"> {item['2024-04-01']} </td>
                                        <td className="text-center"> {item['2024-05-01']} </td>
                                        <td className="text-center"> {item['2024-06-01']} </td>
                                        <td className="text-center"> {item.totalPontuacaoGeral} </td>
                                        <td className="text-center"> {item.rankingPeriodo} </td>
                                        <td className="text-center"> {item.rankingAno} </td>
                                    </tr>
                                ))
                            }
                        </>
                    ) : (
                        <tr>
                          <td colSpan={24} className="text-center text-sm">Não há registros</td>
                        </tr>
                    )
                }
            </>
        );
    }

    useEffect(() => {
        setIsLoading(false);
    })

    return(
        <>
            { !isLoading ? (
                <div className="CommercialScoreGeneral" data-testid="CommercialScoreGeneral">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={12} xs={12}>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-flush m-0 w-100">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col" className="text-left">COMERCIAL</th>
                                                    <th scope="col" className="text-left">JANEIRO 2024</th>
                                                    <th scope="col" className="text-left">FEVEREIRO 2024</th>
                                                    <th scope="col" className="text-left">MARÇO 2024</th>
                                                    <th scope="col" className="text-left">ABRIL 2024</th>
                                                    <th scope="col" className="text-left">MAIO 2024</th>
                                                    <th scope="col" className="text-left">JUNHO 2024</th>
                                                    <th scope="col" className="text-left">PONTUAÇÃO TOTAL</th>
                                                    <th scope="col" className="text-left">RANKING PERÍODO</th>
                                                    <th scope="col" className="text-left">RANKING ANO 2024</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                               {tableList()}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>

                            {/* {true && (
                                <div>
                                    <nav aria-label="...">
                                        <AppPagination
                                            pages={pages}
                                            handlePaginate={handlePaginate}
                                            params={params}
                                            setParams={setParams}
                                            style={{ backgroundColor: '#fff' }}
                                        />
                                    </nav>
                                </div>
                            )} */}
                        </CardBody>
                    </Card>
                </div>     
              ) : (<SkeletonTable />)
            }
        </>
    );
}

export default CommercialScoreGeneral;