import React, { FC } from 'react';
import './ReceiptTypeUndefined.scss';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

interface ReceiptTypeUndefinedProps {}

const ReceiptTypeUndefined: FC<ReceiptTypeUndefinedProps> = () => (
  <div className="ReceiptTypeUndefined" data-testid="ReceiptTypeUndefined">
    <Card>
      <Card.Body>
      <span>
        <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
          Indefinido - Verificado por tempo indefinido
      </span>
      </Card.Body>
    </Card>
  </div>
);

export default ReceiptTypeUndefined;
