import React, { FC, useState } from 'react';
import './ProductionByCommercial.scss';
import PageBase from '../../../../PageBase/PageBase';
import { Col, Row } from 'react-bootstrap';
import NavPills from '../../../../NavPills/NavPills';
import MonthlyProduction from './MonthlyProduction/MonthlyProduction';
import AnnualProduction from './AnnualProduction/AnnualProduction';
import ChartsCommercialParticipation from './ChartsCommercialParticipation/ChartsCommercialParticipation';

interface ProductionByCommercialProps { }

const TabEnum = {
  MONTHLY_PRODUCTION: 'monthlyProduction',
  ANNUAL_PRODUCTION: 'annualProduction',
  CHART_PARTICIPATION: 'chartParticipation'
}

const ProductionByCommercial: FC<ProductionByCommercialProps> = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState<string>(TabEnum.MONTHLY_PRODUCTION);

  const renderContent = (): JSX.Element => {
    return (
      <>
        <Row>
          <Col md={12}>
            <NavPills mdMenu={12}
              options={[
                {
                  acronym: TabEnum.MONTHLY_PRODUCTION,
                  name: 'Produção Mensal',
                  icon: <i className="fa-solid fa-table"></i>,
                  show: true,
                },
                {
                  acronym: TabEnum.ANNUAL_PRODUCTION,
                  name: 'Produção Anual',
                  icon: <i className="fa-solid fa-table"></i>,
                  show: true,
                },
                {
                  acronym: TabEnum.CHART_PARTICIPATION,
                  name: 'Gráfico de Participação',
                  icon: <i className="fa-solid fa-chart-pie"></i>,
                  show: true,
                }
              ]}
              current={currentActiveTab}
              setCurrent={setCurrentActiveTab}
            />
          </Col>
        </Row>
        {currentActiveTab === TabEnum.MONTHLY_PRODUCTION && <MonthlyProduction />}
        {currentActiveTab === TabEnum.ANNUAL_PRODUCTION && <AnnualProduction />}
        {currentActiveTab === TabEnum.CHART_PARTICIPATION && <ChartsCommercialParticipation />}
      </>
    );
  }

  return (
    <div className="ProductionByCommercial" data-testid="ProductionByCommercial">
      <PageBase
        content={renderContent()}
        showCardHeader={false}
      />
    </div>
  )
};

export default ProductionByCommercial;
