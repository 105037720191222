import { VisitsDivergenceAssessmentsModel } from "../../../models/Visits/VisitsDivergenceAssessments/VisitsDivergenceAssessmentsModel";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/app/visit/divergence';

export class VisitsDivergencesAssessmentsService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/list`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async statusList(): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/status/list`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async save(params: VisitsDivergenceAssessmentsModel): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/save`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}