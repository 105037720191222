import React, { FC, useEffect, useMemo, useState } from "react";
import "./PartnerProductionByBranch.scss";
import { useBiPartner } from "../../../../../providers/BI/Dashboard/BiPartnerProvider";
import moment from "moment";
import FormatMoney from "../../../../../utils/FormatMoney";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row } from "react-bootstrap";
import AppDataTable from "../../../../AppDataTable/AppDataTable";
import { useBiDashboard } from "../../../../../providers/BI/Dashboard/BiDashboardProvider";
import { BI_VIEWS_ENUM } from "../../../../../models/BI/Dashboard/BiProductionParamsModel";
import SkeletonTable from "../../../../SkeletonTable/SkeletonTable";

interface PartnerProductionByBranchProps { }

const PartnerProductionByBranch: FC<PartnerProductionByBranchProps> = () => {
  const [columns, setColumns] = useState<any[]>([]);
  const [hideButtons, setHideButtons] = useState<any[]>([]);
  const [currentMonth, setCurrentMonth] = useState<boolean>(false);

  const { partnerProductionByBranch, setPartnerProductionByBranch } =
    useBiPartner();
  const {
    handleListMonthlyProduction,
    isLoading,
    params,
    setParams,
    setHandleListOnFilter,
    resultOnFilter,
    setResultOnFilter,
    generalErrorMsg,
    isParamsUpdated,
    setIsParamsUpdated,
    handleExcel,
  } = useBiDashboard();

  useEffect(() => {
    if (isParamsUpdated) {
      setHandleListOnFilter(() => handleListMonthlyProduction);
      setParams({
        ...params,
        view: BI_VIEWS_ENUM.BRANCH,
        group: {
          agent: true,
        },
      });
      if (!partnerProductionByBranch) {
        handleFetch().then();
      }
      setIsParamsUpdated(false);
    }
  }, [isParamsUpdated]);

  useEffect(() => {
    if (resultOnFilter) {
      setPartnerProductionByBranch(resultOnFilter);
      setResultOnFilter(undefined);
    }
  }, [resultOnFilter]);

  const handleFetch = async () => {
    const result = await handleListMonthlyProduction({
      ...params,
      view: BI_VIEWS_ENUM.BRANCH,
      group: {
        agent: true,
      },
    }).then();

    if (result) {
      setPartnerProductionByBranch(result);
    }
  };

  useEffect(() => {
    mountHideButtons();
  }, [partnerProductionByBranch]);

  const handleExport = (rows: any) => {
    let periods: string[] = [];
    if (
      partnerProductionByBranch?.items &&
      partnerProductionByBranch?.items?.length > 0
    ) {
      periods =
        partnerProductionByBranch?.items[0]?.group![0]?.periods?.map(
          (x) => x.month ?? ""
        ) ?? [];
    }
    const _data: any[] = [];
    rows.forEach((item: any) => {
      const _row: any = {
        Parceiros: `${item?.name} - ${item?.partnerCode}`,
      };

      periods?.forEach((period) => {
        const month = moment(period, "YYYY-MM").format("MMMM")?.toUpperCase();
        _row[month] = item[period];
        _row["C/Q " + month] = item[period + "cq"];
        _row["Participação " + month] = item[period + "participation"];
      });
      if (currentMonth) {
        _row["Projeção"] = item?.projection;
        _row["Projeção C/Q"] = item?.projectionGrowth;
      }
      _data.push(_row);
    });
    handleExcel(_data, "Producao de parceiro por filial");
  };

  const hasData = (): boolean => {
    if (
      partnerProductionByBranch?.items &&
      partnerProductionByBranch?.items?.length > 0 &&
      partnerProductionByBranch?.items[0]?.group &&
      partnerProductionByBranch?.items[0]?.group?.length > 0 &&
      partnerProductionByBranch?.items[0]?.group[0]?.periods &&
      partnerProductionByBranch?.items[0]?.group[0]?.periods?.length > 0
    ) {
      return true;
    }
    return false;
  };

  const mountColumns = useMemo(() => {
    if (hasData()) {
      setCurrentMonth(false);

      const currentMonthFilter = new Date().getMonth() + 1;

      partnerProductionByBranch?.months?.forEach((item) => {
        const result = item.split("-") || [0, 0];
        const isMonth = { month: Number(result[1]), year: Number(result[0]) };
        if (isMonth.month === currentMonthFilter) {
          setCurrentMonth(true);
        }
      });

      const _columns: any = [
        {
          id: "colIndex",
          name: (
            <div className="bg-info text-table text-uppercase">Parceiros</div>
          ),
          selector: (data: any) => data?.name,
          cell: (data: any) => (
            <div className="text-wrap text-bold">
              {`${data?.name} - ${data?.partnerCode}`}
            </div>
          ),
          width: "250px",
          sortable: true,
        },
      ];

      partnerProductionByBranch?.months?.forEach((item: any) => {
        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">
              {moment(item, "YYYY-MM").format("MMMM")}
            </div>
          ),
          selector: (data: any) => data[item] ?? 0,
          right: true,
          cell: (data: any) => <div>{FormatMoney(data[item ?? ""])}</div>,
          sortable: true,
          omit: !hideButtons?.find((x: any) => x?.alias === item)?.show,
        });

        _columns.push({
          name: <div className="bg-info text-table text-uppercase">C/Q</div>,
          selector: (data: any) => data[`${item}cq`] ?? 0,
          right: true,
          cell: (data: any) => (
            <div
              className={`${data[`${item}cq`] < 0 ? "bg-negative" : "bg-positive"
                }`}
            >
              {FormatMoney(data[`${item}cq`], false)}%
              <FontAwesomeIcon
                icon={data[`${item}cq`] < 0 ? faArrowDown : faArrowUp}
                className="ms-2"
              />
            </div>
          ),
          sortable: true,
          omit:
            !hideButtons?.find((x: any) => x?.alias === "growth")?.show ||
            !hideButtons?.find((x: any) => x?.alias === item)?.show,
        });

        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">
              Participação
            </div>
          ),
          selector: (data: any) => data[`${item?.month}participation`] ?? 0,
          right: true,
          cell: (data: any) => (
            <div>
              {FormatMoney(data[`${item?.month}participation`], false)}%
            </div>
          ),
          sortable: true,
          compact: true,
          omit:
            !hideButtons?.find((x: any) => x.alias === "participation")?.show ||
            !hideButtons?.find((x: any) => x?.alias === item?.month)?.show,
        });
      });
      if (currentMonth) {
        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">PROJEÇÃO</div>
          ),
          selector: (data: any) => data?.projection ?? 0,
          right: true,
          cell: (data: any) => (
            <div className="text-nowrap">{FormatMoney(data?.projection)}</div>
          ),
          sortable: true,
          omit: !hideButtons?.find((x: any) => x.alias === "projection")?.show,
        });

        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">
              PROJEÇÃO C/Q
            </div>
          ),
          selector: (data: any) => data?.projectionGrowth ?? 0,
          right: true,
          cell: (data: any) => (
            <div
              className={`${data?.projectionGrowth < 0 ? "bg-negative" : "bg-positive"
                }`}
            >
              {FormatMoney(data?.projectionGrowth, false)}%
              <FontAwesomeIcon
                icon={data?.projectionGrowth < 0 ? faArrowDown : faArrowUp}
                className="ms-2"
              />
            </div>
          ),
          sortable: true,
          omit: !hideButtons?.find((x: any) => x.alias === "projectionGrowth")
            ?.show,
        });
      }

      setColumns(_columns);
    }
  }, [partnerProductionByBranch, hideButtons]);

  const mountRows = (items: any[]) => {
    const _rows: any = [];

    items.forEach((item: any) => {
      const _row: any = {
        name: item?.name,
        partnerCode: item?.partnerCode,
      };

      item.periods.forEach((period: any) => {
        _row[period.month ?? ""] = period.totalProduction;
        _row[`${period.month}cq`] = period.totalProductionGrowth;
        _row[`${period.month}participation`] = period.totalProductionPercentage;
      });

      if (item?.periods && item?.periods?.length > 0) {
        _row.projection =
          item?.periods[item?.periods?.length - 1]?.totalProductionProjection;
        _row.projectionGrowth =
          item?.periods[
            item?.periods?.length - 1
          ].totalProductionProjectionGrowth;
      }

      _rows.push(_row);
    });

    return _rows;
  };

  const mountHideButtons = () => {
    if (hasData()) {
      const _buttons: any = [];
      partnerProductionByBranch?.items![0]?.group![0]?.periods?.forEach(
        (item) => {
          _buttons.push({
            alias: item?.month,
            label: moment(item?.month, "YYYY-MM").format("MMMM"),
            show: true,
          });
        }
      );

      _buttons.push({
        alias: `growth`,
        label: "Crescimento/Queda",
        show: true,
      });

      _buttons.push({
        alias: `participation`,
        label: "Participação",
        show: true,
      });
      if (currentMonth) {
        _buttons.push({
          alias: `projection`,
          label: "Projeção",
          show: true,
        });

        _buttons.push({
          alias: `projectionGrowth`,
          label: "Projeção C/Q",
          show: true,
        });
      }
      setHideButtons(_buttons);
    }
  };

  const onClickHideButton = (button: any, index: number) => {
    const _hideButtons = [...hideButtons];
    button.show = !button.show;
    _hideButtons[index] = button;
    setHideButtons(_hideButtons);
  };

  const renderTable = (x: any) => {
    const rows = mountRows(x?.group);

    return (
      <Card className="mt-3" key={x?.name}>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center w-100 mb-2">
            <span className="text-start text-primary pb-1">{x?.name}</span>
            <button
              className="btn bg-success text-white rounded m-0 ms-5"
              onClick={() => {
                handleExport(rows);
              }}
            >
              <i className="fas fa-file-excel" /> Exportar
            </button>
          </div>
          <AppDataTable columns={columns} rows={rows} pagination={false} />
        </Card.Body>
      </Card>
    );
  };

  return (
    <div
      className="PartnerProductionByBranch"
      data-testid="PartnerProductionByBranch"
    >
      {!isLoading ? (
        <>
          <Col md={12} className="d-flex justify-content-end">
            <Row className="display-button mb-3">
              <div className="d-flex justify-content-between align-items-center flex-md-row">
                <div className="d-flex gap-1 justify-content-center align-items-center flex-md-row">
                  {hideButtons?.map((x: any, index: number) => (
                    <button
                      type="button"
                      className="btn bg-primary text-white mb-0 ms-2 min-width-btn text-capitalize"
                      onClick={() => onClickHideButton(x, index)}
                    >
                      <FontAwesomeIcon
                        icon={x.show ? faEye : faEyeSlash}
                        className="me-1"
                      />
                      {x.label}
                    </button>
                  ))}
                </div>
              </div>
            </Row>
          </Col>

          <Col md={12} className="text-start text-muted small mb-1">
            <b>O resultado está limitado a 300 linhas</b>
          </Col>

          {partnerProductionByBranch?.items ? (
            partnerProductionByBranch?.items?.map((item: any) =>
              renderTable(item)
            )
          ) : (
            <div className="d-flex mt-9 justify-content-center general-error-msg">
              {generalErrorMsg}
            </div>
          )}
        </>
      ) : (
        <SkeletonTable />
      )}
    </div>
  );
};

export default PartnerProductionByBranch;
