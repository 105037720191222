import { FC } from 'react';
import './SkeletonBadges.scss';
import Skeleton from 'react-loading-skeleton';

interface SkeletonBadgesProps {
  items?: number;
}

const SkeletonBadges: FC<SkeletonBadgesProps> = ({ items = 5 }) => {
  const randomIntFromInterval = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const render = () => {
    const list = [];
    for (let i = 0; i <= items - 1; i++) {
      list.push(
        <div key={i}>
          <Skeleton width={randomIntFromInterval(30, 100)} height={22} />
        </div>
      );
    }
    return list;
  }

  return (
    <div className="SkeletonBadges" data-testid="SkeletonBadges">
      {render()}
    </div>
  );
}

export default SkeletonBadges;
