import { EasyPortModel } from "../../../models/Marketplace/EasyPort/EasyPortModel";
import api from "../../Api";
import { BaseService } from "../../BaseService";
import FileSaver from 'file-saver';

const URL = "api/services/marketplace";

export class EasyPortService extends BaseService {

    async getEasyPorts(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/public/easyport/list`));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async handleDownloadEasyPortFile(filePath: string): Promise<any[]> {
        try {
            const formdata = new FormData();
            formdata.append("pathfile", filePath);
            const response = await api.post(`${URL}/public/easyport/download`, formdata, { headers: { 'Content-Type': 'multipart/form-data'}, responseType: 'blob' });
            if (response.status === 200) {
                const filename = filePath.split('/').pop();
                FileSaver.saveAs(response.data, filename, { autoBom: false });
                return [true, undefined];
            } else {
                console.error('Erro na solicitação:', response.status, response.statusText);
                return [false, undefined];
            }
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async save(params: EasyPortModel, file: File): Promise<any[]> {
        try {

            const formdata = new FormData();
            formdata.append("cpf", params?.cpf?.toString() ?? '');
            formdata.append("file", file);

            return this.handleResponse(await api.post(`${URL}/public/easyport/save`, formdata, { headers: { 'Content-Type': 'multipart/form-data' } }));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async disable(params: number[]): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/public/easyport/disable`, params));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

}