import React, { FC, useEffect } from 'react';
import './FileDownloadButton.scss';
import Swal from 'sweetalert2';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

interface FileDownloadButtonProps {
  textButton?: string;
  handleDownload?: () => void;
  isLoading?: boolean;
  error?: string;
  typeButton?: 'dropdown' | 'button'
  isDisabled?: boolean;
}

const FileDownloadButton: FC<FileDownloadButtonProps> = ({ textButton, isLoading, error, typeButton, isDisabled = false, handleDownload }) => {
  useEffect(() => {
    if (isLoading) {
      Swal.fire({
        title: "<p class='title-swal m-0'>Aguarde, gerando o arquivo para download.</p>",
        allowOutsideClick: false
      });
      Swal.showLoading();
    } else {
      if (error) {
        Swal.fire(`<p class='title-swal m-0'>${error}</p>`);
        Swal.hideLoading();
      } else {
        Swal.close();
      }
    }
  }, [isLoading]);

  return (
    <div className="FileDownloadButton" data-testid="FileDownloadButton">
      {typeButton === 'dropdown' && (
        <Dropdown.Item className="border-radius-md py-2" onClick={handleDownload} disabled={isLoading}>
          <FontAwesomeIcon icon={faDownload} className="me-2" /> {textButton}
        </Dropdown.Item>
      )}

      {typeButton === 'button' && (
        <button type='button' className='btn btn-white m-0 d-flex gap-2 align-items-center' onClick={handleDownload} disabled={isLoading || isDisabled}>
          <FontAwesomeIcon icon={faDownload} /> {textButton}
        </button>
      )}
    </div>
  );
}

export default FileDownloadButton;
